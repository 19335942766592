import Checkbox from 'antd/es/checkbox/Checkbox';
import styled from '../../../../../plugins/styled';
export const GrantStepContainer = styled.div `
  align-items: center;
  background: ${({ theme, isCompleted, isActive }) => isCompleted
    ? theme.colors.grey_5
    : isActive
        ? theme.colors.beige_2
        : theme.colors.beige_3};
  border: ${({ theme, isActive }) => isActive
    ? `2px solid ${theme.colors.purple_2}`
    : `2px solid ${theme.colors.primary}`};
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 32px;
  position: relative;
  overflow: hidden;
  padding: ${({ canCollapse }) => (canCollapse ? '16px 24px' : '24px 24px')};
  width: 100%;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: ${({ theme, canHover }) => canHover ? theme.colors.purple_5 : theme.colors.beige_3};
  }
`;
export const SquareCheckbox = styled(Checkbox) `
  .ant-checkbox-inner {
    border-radius: 2px;
    border-color: ${({ theme, isActive }) => isActive ? `${theme.colors.purple_2}` : `${theme.colors.primary}`};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => `${theme.colors.purple_2}`};
    border-color: ${({ theme }) => `${theme.colors.purple_2}`};
  }
`;
