import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import useDebounceFunction from '../../../../features/hooks/use-debounce-function';
import { usePagination } from '../../../../features/providers/pagination-provider';
import { usePlatform } from '../../../../features/providers/platform-provider';
import { default as checkCompanyIsInFilter } from './check-company-values';
import usePrepareFundingExplorerFilters from './prepare-filters';
import usePrepareFundingExplorerURL from './prepare-url';
const FILTER_DELAY_MS = 500;
const useApplyFundingExplorerFilter = (props) => {
    const { fetch, filterform, company, showCompanyFilterCardState, filterValues, } = props;
    const { app } = usePlatform();
    const { params, goToPage, paramsIsLoading } = usePagination();
    const [filtersState, setFiltersState] = useState('need-init');
    const [prevCompany, setPrevCompany] = useState(undefined);
    const [pageChanged, setPageChanged] = useState(false);
    const [prevData, setPrevData] = useState(filterValues);
    const prepareFundingExplorerURL = usePrepareFundingExplorerURL(props);
    const prepareFundingExplorerFilters = usePrepareFundingExplorerFilters(props);
    const prepareFundingExplorerCompanyFilters = () => {
        var _a, _b, _c, _d, _e, _f;
        if (!company)
            return false;
        const formMapping = {
            industry: (_a = company.industry_sector) !== null && _a !== void 0 ? _a : [],
            subindustry: (_b = company.industry_subsector) !== null && _b !== void 0 ? _b : [],
            region: (_d = (_c = company.province) === null || _c === void 0 ? void 0 : _c.split(',')) !== null && _d !== void 0 ? _d : [],
            companySize: (_e = company.size) !== null && _e !== void 0 ? _e : '',
            annualRevenue: (_f = company.annual_revenue) !== null && _f !== void 0 ? _f : '',
        };
        filterform.setFieldsValue(formMapping);
    };
    const resetFiltersState = () => {
        filterform.resetFields();
    };
    const setShowCompanyFilterCard = useMemo(() => !!showCompanyFilterCardState ? showCompanyFilterCardState[1] : () => { }, [showCompanyFilterCardState]);
    const handleState = (state) => {
        setTimeout(() => setFiltersState(state), 200);
    };
    const handleFetch = async (isInit) => {
        const values = filterform.getFieldsValue();
        setPrevData(values);
        if (isInit && !!company) {
            const companyIsInFilter = checkCompanyIsInFilter({
                company,
                filterValues: values,
            });
            setShowCompanyFilterCard(companyIsInFilter);
        }
        else {
            setShowCompanyFilterCard(false);
        }
        prepareFundingExplorerURL();
        await fetch(values);
        handleState('idle');
    };
    const debouncedActions = {
        companyReset: async () => {
            prepareFundingExplorerCompanyFilters();
            handleFetch(true);
        },
        reset: async () => {
            resetFiltersState();
            handleFetch(true);
        },
        init: async () => {
            prepareFundingExplorerFilters();
            handleFetch(true);
        },
        filterChanged: useDebounceFunction(async () => {
            if (!pageChanged) {
                goToPage(1);
            }
            handleFetch(false);
        }, FILTER_DELAY_MS, true),
    };
    const resetFilters = () => {
        applyFilter({ type: 'reset' });
    };
    const resetFiltersWithCompany = () => {
        applyFilter({ type: 'companyReset' });
    };
    useEffect(() => {
        if (company === undefined && !['client'].includes(app)) {
            applyFilter({ type: 'reset' });
        }
        else if (!!(company === null || company === void 0 ? void 0 : company.company_id.length) && !paramsIsLoading) {
            applyFilter({ type: 'init' });
        }
    }, []);
    useEffect(() => {
        if (filtersState !== 'idle' || !pageChanged)
            return;
        applyFilter({ type: 'default' });
        setPageChanged(false);
    }, [pageChanged]);
    const applyFilter = useDebounceFunction(async (props) => {
        var _a;
        if (filtersState === 'loading')
            return;
        const applyType = (_a = props === null || props === void 0 ? void 0 : props.type) !== null && _a !== void 0 ? _a : 'default';
        if (['default', 'filterChanged'].includes(applyType) &&
            filtersState === 'need-init')
            return;
        switch (applyType) {
            case 'companyReset':
                setFiltersState('need-init');
                debouncedActions.companyReset();
                break;
            case 'reset':
                setFiltersState('need-init');
                debouncedActions.reset();
                break;
            case 'init':
                setFiltersState('need-init');
                debouncedActions.init();
                break;
            default:
                setFiltersState('loading');
                debouncedActions.filterChanged();
                break;
        }
    }, 1000);
    const isInitialised = useMemo(() => ['idle', 'loading'].includes(filtersState), [filtersState]);
    const filtersLoading = useMemo(() => filtersState !== 'idle', [filtersState]);
    useEffect(() => {
        if (!_.isEqual(filterValues, prevData)) {
            applyFilter({ type: 'filterChanged' });
        }
    }, [filterValues]);
    const handlePageChange = async (page, pageSize) => {
        goToPage(page, pageSize);
        setPageChanged(true);
    };
    return {
        filtersLoading,
        resetFilters,
        resetFiltersWithCompany,
        isInitialised,
        handlePageChange,
    };
};
export default useApplyFundingExplorerFilter;
