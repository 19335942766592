import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Bookmark from '@hellodarwin/icons/dist/icons/Bookmark';
import { useMemo, useState } from 'react';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Typography from '../../../../common/typography';
import GrantNotificationModal from './grant-notification-modal';
import useGrantNotificationCache from './hooks/use-notification-cache';
import { GrantNotificationButtonContainer } from './styles';
const GrantNotification = ({ grant, animate, }) => {
    const theme = useTheme();
    const { t } = useTranslations();
    const [hovered, setHovered] = useState(false);
    const { selected, handleSelected } = useGrantNotificationCache(grant.grant_id);
    const [notificationModalOpen, setNotificationModalOpen] = useState(false);
    const [unsubscribing, setUnsubscribing] = useState(false);
    const [unsubscribed, setUnsubscribed] = useState(false);
    const handleNotification = () => setNotificationModalOpen(!notificationModalOpen);
    const wantNotification = useMemo(() => !selected && hovered && !unsubscribed, [hovered, selected, unsubscribed]);
    const wantUnsubscribe = useMemo(() => !!selected && !unsubscribing && hovered, [hovered, selected, unsubscribing]);
    const handleSelect = () => {
        if (wantNotification) {
            handleSelected(true);
            handleNotification();
        }
        else {
            if (wantUnsubscribe) {
                setUnsubscribing(true);
            }
            else {
                setUnsubscribing(false);
                setUnsubscribed(true);
                setTimeout(() => setUnsubscribed(false), 2000);
                handleSelected(false);
            }
        }
    };
    const handleMouseEnter = () => setHovered(true);
    const handleMouseLeave = () => {
        setHovered(false);
        setUnsubscribing(false);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(GrantNotificationButtonContainer, { "$active": hovered || unsubscribed, "$animate": animate, children: [_jsx(Typography, { children: wantNotification
                            ? t('grant_notification|grant_notification_subscribe')
                            : wantUnsubscribe
                                ? t('grant_notification|grant_notification_unsubscribe')
                                : unsubscribing
                                    ? t('grant_notification|grant_notification_unsubscribe_verification')
                                    : unsubscribed
                                        ? t('grant_notification|grant_notification_unsubscribe_confirmed')
                                        : '' }), _jsx(Button, { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, defaultStyle: theme.colors.purple_2, headingIcon: _jsx(Bookmark, { size: 32, filled: selected, fillingColor: theme.colors.purple_2 }), size: "square", transparent: true, onClick: handleSelect })] }), _jsx(GrantNotificationModal, { open: notificationModalOpen, handleClose: handleNotification, grantId: grant.grant_id })] }));
};
export default GrantNotification;
