'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Form from 'antd/es/form/Form';
import { useMemo } from 'react';
import { Tablet } from '../../common/Media';
import FundingExplorerContent from './content';
import FundingExplorerFilter from './filter';
import FilterProjects from './filter/filter-projects';
import { InitialFundingExplorerFilterValues, } from './filter/types';
import useApplyFundingExplorerFilter from './hooks/apply-filters';
const FundingExplorer = (props) => {
    const { filterform, isLoading, company, type = 'all', filterValues, styles, withMissingFieldsLock, } = props;
    const initialValues = Object.assign({}, InitialFundingExplorerFilterValues);
    const { filtersLoading, isInitialised, resetFilters, resetFiltersWithCompany, handlePageChange, } = useApplyFundingExplorerFilter(props);
    const missingDefaultFields = useMemo(() => !(company === null || company === void 0 ? void 0 : company.company_id) ||
        !company.name ||
        !company.annual_revenue ||
        !company.industry_sector ||
        !company.size ||
        !company.province, [company]);
    const isLocked = useMemo(() => withMissingFieldsLock && missingDefaultFields, [missingDefaultFields, withMissingFieldsLock]);
    const stateIsLoading = useMemo(() => isLoading || filtersLoading, [filtersLoading, isLoading]);
    const filterProps = Object.assign(Object.assign({}, props), { resetFilters,
        resetFiltersWithCompany, isLoading: stateIsLoading, filterValues });
    const contentProps = Object.assign(Object.assign({}, props), { isLocked,
        handlePageChange,
        isInitialised,
        stateIsLoading });
    return (_jsxs(Form, { form: filterform, layout: "vertical", initialValues: initialValues, children: [!['only-projects', 'only-grants-list'].includes(type) && (_jsx(FundingExplorerFilter, Object.assign({}, filterProps, { style: styles === null || styles === void 0 ? void 0 : styles.header }))), !['only-projects', 'only-header', 'only-header-and-projects'].includes(type) && _jsx(FundingExplorerContent, Object.assign({}, contentProps)), !isLocked &&
                ![
                    'only-header',
                    'only-projects',
                    'only-header-and-grants-list',
                ].includes(type) && (_jsx(Tablet, { direction: "up", children: _jsx(FilterProjects, Object.assign({}, filterProps, { style: styles === null || styles === void 0 ? void 0 : styles.projects })) }))] }));
};
export default FundingExplorer;
