var RfpDescriptionTypeInbox;
(function (RfpDescriptionTypeInbox) {
    RfpDescriptionTypeInbox["Client_Description"] = "client description";
    RfpDescriptionTypeInbox["Description_du_client"] = "description du client";
    RfpDescriptionTypeInbox["Project_Description"] = "project description";
    RfpDescriptionTypeInbox["Description_du_projet"] = "description du projet";
    RfpDescriptionTypeInbox["Context"] = "project context";
    RfpDescriptionTypeInbox["Contexte"] = "contexte du projet";
    RfpDescriptionTypeInbox["Goals"] = "project goal &amp; objective";
    RfpDescriptionTypeInbox["Objectifs"] = "but &amp; objectifs du projet";
    RfpDescriptionTypeInbox["Details"] = "project description &amp; details";
    RfpDescriptionTypeInbox["Detailes"] = "description &amp; d\u00E9tails du projet";
    RfpDescriptionTypeInbox["Provider_Requirement"] = "service provider requirements";
    RfpDescriptionTypeInbox["Demande_du_fournisseur"] = "exigences du fournisseur";
    RfpDescriptionTypeInbox["Budget"] = "budget";
    RfpDescriptionTypeInbox["Contact"] = "budget &amp; contact";
    RfpDescriptionTypeInbox["Other"] = "other";
    RfpDescriptionTypeInbox["Autre"] = "autre";
})(RfpDescriptionTypeInbox || (RfpDescriptionTypeInbox = {}));
export default RfpDescriptionTypeInbox;
