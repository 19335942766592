import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
const ReviewConfirm = ({ handleClose, isActive }) => {
    const theme = useTheme();
    if (!isActive)
        return _jsx(_Fragment, {});
    const { t } = useTranslations();
    return (_jsxs(Div, { flex: "column", align: "center", gap: 24, className: "fade-in", children: [_jsxs(Div, { flex: "column", gap: 12, align: "center", children: [_jsx(Typography.Title, { level: 2, elementTheme: "h3", textAlign: "center", children: t('reviews|newReviewThankYouScreenHeader') }), _jsx(Typography, { textAlign: "center", elementTheme: "body1", children: t('reviews|newReviewThankYouScreenDescription') })] }), _jsx(Button, { defaultStyle: theme.colors.grey_1, size: 'medium', onClick: handleClose, children: t('reviews|newReviewThankYouScreenClose') })] }));
};
export default ReviewConfirm;
