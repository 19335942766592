var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import useLocale from '../../../features/providers/locale-provider';
import ModalLayoutWithHdLogo from '../../common/hd-modal/layouts/modal-with-logo';
import HubspotForm from '../../hubspot/hubspot-form';
const ContactAdvisorModal = (_a) => {
    var modalProps = __rest(_a, []);
    const { selectedLocale } = useLocale();
    return (_jsx(ModalLayoutWithHdLogo, Object.assign({}, modalProps, { children: _jsx(HubspotForm, { containerTag: "book-a-call-modal", hubspotId: selectedLocale === 'en'
                ? 'b50e2456-1d66-4e5c-9099-071683514c27'
                : '0b706767-afec-4eed-a2a6-daca16f9ecf5', assignedTheme: "Full Width" }) })));
};
export default ContactAdvisorModal;
