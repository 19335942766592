import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HdLogo from '@hellodarwin/icons/dist/icons/HdLogo';
import React, { useEffect, useState } from 'react';
import { useAppData } from '../../../features/providers/app-data-provider';
import { useNavigate } from '../../../features/providers/location-provider';
import { usePlatform } from '../../../features/providers/platform-provider';
import Div from '../../common/div';
import LanguageSwitcher from './language-switcher';
import NavigationMenuItem from './menu-item';
import Socials from './socials';
import { DesktopNavigationHeaderContainer, Navigation } from './styles';
const DesktopNavigation = ({ links, handleLanguageChange, defaultOpen, }) => {
    const { app } = usePlatform();
    const { navCollapsed } = useAppData();
    const navigate = useNavigate();
    const [selectedKeys, setSelectedKeys] = useState([]);
    useEffect(() => {
        setSelectedKeys([]);
    }, [navCollapsed]);
    useEffect(() => {
        if (!!defaultOpen) {
            setSelectedKeys(links.filter((i) => { var _a; return !!((_a = i.subLinks) === null || _a === void 0 ? void 0 : _a.length); }).map((itm) => itm.pathname));
        }
    }, [defaultOpen, links]);
    const goToHome = () => {
        navigate('/');
    };
    return (_jsxs(Navigation, { "$app": app, "$isOpen": !navCollapsed, children: [_jsxs(Div, { flex: "column", gap: 36, align: "center", children: [_jsx(DesktopNavigationHeaderContainer, { "$navCollapsed": navCollapsed, onClick: goToHome, children: _jsx(HdLogo, { backgroundType: app === 'provider' ? 'dark' : 'light', type: navCollapsed ? 'tertiary' : 'primary', color: "b/w", width: navCollapsed ? 48 : 178, height: 48 }) }), _jsx(Div, { flex: "column", gap: 12, align: "center", children: React.Children.toArray(links
                            .filter((link) => !link.hide)
                            .map((link) => (_jsx(NavigationMenuItem, Object.assign({}, link, { app: app, selectedKeys: selectedKeys, setSelectedKeys: setSelectedKeys }))))) })] }), app === 'admin' ? (_jsx(LanguageSwitcher, { app: app, handleLanguageChange: handleLanguageChange })) : (_jsx(Socials, { minimize: navCollapsed, app: app }))] }));
};
export default DesktopNavigation;
