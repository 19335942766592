'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useMemo, useState } from 'react';
const initialLogos = {
    client: '',
    provider: '',
    admin: '',
    'grant-provider': '',
    portfolio: '',
    default: '',
    compass: '',
};
export const PlatformContext = React.createContext({
    app: 'client',
    contentfulClient: undefined,
    fallbackLogos: initialLogos,
    addFallbackLogo(logo, type) { },
    isDev: false,
});
const PlatformProvider = ({ children, app, contentfulClient, defaultFallbackLogos, }) => {
    const [fallbackLogos, setFallbackLogos] = useState(defaultFallbackLogos !== null && defaultFallbackLogos !== void 0 ? defaultFallbackLogos : initialLogos);
    const addFallbackLogo = (logo, type) => setFallbackLogos((prev) => (Object.assign(Object.assign({}, prev), { [type]: logo })));
    const environment = process.env.NODE_ENV;
    const isDev = useMemo(() => environment === 'development', [environment]);
    return (_jsx(PlatformContext.Provider, { value: {
            app,
            contentfulClient,
            fallbackLogos,
            addFallbackLogo,
            isDev,
        }, children: children }));
};
export const usePlatform = () => useContext(PlatformContext);
export default PlatformProvider;
