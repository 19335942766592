import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Form from 'antd/es/form';
import { useForm } from 'antd/es/form/Form';
import { useEffect } from 'react';
import addLineBreak from '../../../../features/helpers/add-line-break';
import handleDispatchResponse from '../../../../features/helpers/handle-dispatch-response';
import { useCompanyInfos } from '../../../../features/providers/company-infos-provider';
import { useProfileEdition } from '../../../../features/providers/profile-edition-provider';
import { useTranslation } from '../../../../plugins/i18n';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
import ModalWithHeaderFooter from '../../../common/hd-modal/layouts/modal-with-header-footer';
import RichTextEditor from '../../../common/rich-editor';
import Loading from '../../../loading';
import FormItem from '../../form-layouts/form-item';
const AboutForm = ({ handleCancel }) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const theme = useTheme();
    const { provider } = useCompanyInfos();
    const { functions, handleFooter } = useProfileEdition();
    const { isLoading } = functions;
    const initialValues = {
        description: provider.description || '',
    };
    const handleSave = async () => {
        const values = form.getFieldsValue();
        const { company_id } = provider;
        const profileRequest = {
            company_description: addLineBreak(values.description),
            company_id,
        };
        const data = new FormData();
        data.append('request', JSON.stringify(profileRequest));
        !!functions.updateProfile &&
            functions
                .updateProfile({ data })
                .then((res) => handleDispatchResponse(res, t('notification.saved'), t('notification.saving_error')));
        handleCancel();
    };
    useEffect(() => {
        handleFooter(_jsx(ModalWithHeaderFooter.Footer, { actions: _jsxs(_Fragment, { children: [_jsx(Button, { defaultStyle: theme.colors.white_1, onClick: handleCancel, size: "small", loading: functions.isLoading, children: t('button.cancel') }), _jsx(Button, { onClick: handleSave, size: "small", loading: functions.isLoading, children: t('button.save') })] }) }));
    }, []);
    return (_jsx(Div, { flex: "column", children: isLoading ? (_jsx(Loading, {})) : (_jsx(Form, { form: form, initialValues: initialValues, onFinish: handleSave, layout: 'vertical', className: 'description-form', style: { width: '100%' }, children: _jsx(FormItem, { name: 'description', className: "form-item-description", children: _jsx(RichTextEditor, { placeholder: t('provider-profile|about.placeholder.description') }) }) })) }));
};
export default AboutForm;
