import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Download from '@hellodarwin/icons/dist/icons/Download';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { getStringDate } from '../../../../../features/helpers/get-formatted-date';
import useLocale from '../../../../../features/providers/locale-provider';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import AssetViewerInfoRow from './asset-viewer-info-row';
const AssetViewerDrawerInfos = ({ name, type, size, created_at, updated_at, isFolder, description, source, opened_at, actions: { onDoubleClick, onAction }, }) => {
    const theme = useTheme();
    const { selectedLocale } = useLocale();
    const { t } = useTranslation();
    return (_jsxs(Div, { flex: "column", gap: 16, children: [_jsx(AssetViewerInfoRow, { label: t('assets|infosDrawer.labels.name'), value: name }), _jsx(AssetViewerInfoRow, { label: t('assets|infosDrawer.labels.description'), value: description }), _jsx(AssetViewerInfoRow, { label: isFolder
                    ? t('assets|infosDrawer.labels.type')
                    : t('assets|infosDrawer.labels.ext'), value: type }), _jsx(AssetViewerInfoRow, { label: isFolder
                    ? t('assets|infosDrawer.labels.nbFiles')
                    : t('assets|infosDrawer.labels.fileSize'), value: size }), _jsx(AssetViewerInfoRow, { label: t('assets|infosDrawer.labels.createdAt'), value: getStringDate(created_at, selectedLocale) }), _jsx(AssetViewerInfoRow, { label: t('assets|infosDrawer.labels.uploadedAt'), value: getStringDate(updated_at, selectedLocale) }), _jsxs(Div, { flex: "column", gap: 16, children: [_jsx(AssetViewerInfoRow, { label: t('assets|infosDrawer.labels.source'), value: source }), _jsx(AssetViewerInfoRow, { label: t('assets|infosDrawer.labels.createdAt'), value: getStringDate(created_at, selectedLocale) }), _jsx(AssetViewerInfoRow, { label: t('assets|infosDrawer.labels.openedAt'), value: opened_at ? getStringDate(opened_at, selectedLocale) : '-' })] }), !isFolder && (_jsxs(_Fragment, { children: [_jsx("div", { style: { borderTop: `1px solid ${theme.colors.grey_4}` } }), _jsx(Button, { trailingIcon: _jsx(Download, { size: 24 }), style: { width: '100%' }, defaultStyle: theme.colors.white_1, onClick: onAction, children: t('button.download') })] }))] }));
};
export default AssetViewerDrawerInfos;
