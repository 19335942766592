import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import { useCallback, useMemo } from 'react';
import { useTranslations } from '../../../features/providers/translations-provider';
import { useTheme } from '../../../plugins/styled';
import Collapse from '../Collapse';
import List from '../List';
import Typography from '../typography';
const SeeMoreList = (props) => {
    const { content, limit, grid } = props;
    const theme = useTheme();
    const { t } = useTranslations();
    const contentLength = content.length;
    const remainingCount = contentLength - limit;
    const showMore = useMemo(() => remainingCount <= 1
        ? t('actions|seeCountMoreSingular', {
            count: remainingCount,
        })
        : t('actions|seeCountMore', { count: remainingCount }), [remainingCount, t]);
    const showLess = t('actions|seeLess');
    const listProps = {
        styles: { container: { width: '100%' } },
        grid,
        renderItem: useCallback((child) => {
            var _a;
            return (_jsx(List.Item, { style: {
                    paddingBottom: `${(_a = grid === null || grid === void 0 ? void 0 : grid.gutter) !== null && _a !== void 0 ? _a : 0}px`,
                    marginBottom: 0,
                }, children: child }));
        }, [grid === null || grid === void 0 ? void 0 : grid.gutter]),
    };
    const expandIcon = ({ isActive }) => (_jsxs(Typography, { nowrap: true, elementTheme: "body2", color: theme.colors.grey_3, children: [_jsx("span", { style: { marginRight: 8 }, children: isActive ? showLess : showMore }), _jsx(TabArrow, { width: 8, height: 8, down: !isActive })] }));
    const collapseItems = useMemo(() => [
        {
            handleClickButtonOnly: true,
            id: 'see-more-header',
            title: _jsx(List, Object.assign({ dataSource: content.slice(0, limit) }, listProps)),
            children: contentLength > limit ? (_jsx(List, Object.assign({ dataSource: content.slice(limit) }, listProps))) : undefined,
        },
    ], [content, limit, contentLength, listProps]);
    return (_jsx(Collapse, { expandIconPosition: "bottom", ExpandIcon: expandIcon, ghost: true, noPadding: true, items: collapseItems }));
};
export default SeeMoreList;
