import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LinkedinLogo from '@hellodarwin/icons/dist/icons/LinkedinLogo';
import prefixHTTPS from '../../../../../features/helpers/prefix-https';
import { useTheme } from '../../../../../plugins/styled';
import Avatar from '../../../../common/avatar';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import useDarwinLogoFallback from '../../hooks/use-darwin-picture-fallback';
const TeamMemberPreview = ({ teamMember, name, provider, }) => {
    var _a;
    const theme = useTheme();
    const fallback = useDarwinLogoFallback({ type: 'provider', size: 200 });
    return (!!teamMember && (_jsx(Div, { className: "team-member-preview", children: _jsxs(Div, { flex: 'column', justify: 'center', align: "center", gap: 16, children: [_jsx(Avatar, { alt: [teamMember.name, teamMember.role]
                        .filter((itm) => !!itm)
                        .join(' - '), src: `${teamMember.profile_picture}?${new Date().toString()}`, size: 200, shape: "square", loading: "eager", style: { borderRadius: 4 }, fallback: provider.logo, additional_fallback: fallback, children: (_a = teamMember.name) === null || _a === void 0 ? void 0 : _a[0] }), _jsxs(Div, { flex: "column", justify: "center", align: "center", children: [_jsx(Typography, { elementTheme: "body2", bold: true, children: teamMember.name }), _jsxs(Typography, { elementTheme: "caption", color: theme.colors.grey_4, children: [teamMember.role, " at ", name] })] }), _jsx(Div, { fitContent: true, children: !!teamMember.linked_in && (_jsx("a", { href: prefixHTTPS(teamMember.linked_in, 'team_member_preview'), target: "__blank", children: _jsx(LinkedinLogo, { width: 24, height: 24, style: {
                                color: '#007BB5',
                                cursor: 'pointer',
                            } }) })) })] }) })));
};
export default TeamMemberPreview;
