import { IconProps } from '../features/entities/general';

const Hello = ({
  width = 82,
  height = 27,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      className={className}
      onClick={onClick}
      viewBox="0 0 82 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.1939 24.8286C48.5642 25.0713 47.897 25.1951 47.2098 25.1951C46.1503 25.1951 45.3281 24.8509 44.7659 24.1673C44.2211 23.511 43.9562 22.4683 43.9562 20.9798V2.41722H39.5057V3.52924H42.4369V20.9823C42.4369 22.0473 42.5418 22.9463 42.7492 23.6497C42.9467 24.3233 43.2365 24.8632 43.6064 25.2595C43.9787 25.6558 44.4335 25.9381 44.9933 26.1189C45.5805 26.3096 46.2652 26.4062 47.0274 26.4062C47.3647 26.4062 47.7446 26.3666 48.1569 26.2848C48.5717 26.2031 48.994 26.0818 49.4063 25.9282C49.8237 25.7746 50.2335 25.5815 50.6283 25.3561C50.8757 25.2149 51.1131 25.0589 51.3355 24.893L50.7333 23.9791C50.186 24.358 49.6712 24.6428 49.1964 24.8261L49.1939 24.8286Z"
        fill="#89178F"
      />
      <path
        d="M60.0291 24.8286C59.3994 25.0713 58.7322 25.1951 58.045 25.1951C56.9855 25.1951 56.1633 24.8509 55.6011 24.1673C55.0563 23.511 54.7914 22.4683 54.7914 20.9798V2.41722H50.3409V3.52924H53.2721V20.9823C53.2721 22.0473 53.3771 22.9463 53.5845 23.6497C53.7819 24.3233 54.0718 24.8632 54.4416 25.2595C54.8139 25.6558 55.2687 25.9381 55.8285 26.1189C56.4157 26.3096 57.1004 26.4062 57.8626 26.4062C58.1999 26.4062 58.5798 26.3666 58.9921 26.2848C59.4069 26.2031 59.8292 26.0818 60.2416 25.9282C60.6589 25.7746 61.0687 25.5815 61.4635 25.3561C61.7109 25.2149 61.9483 25.0589 62.1707 24.893L61.5685 23.9791C61.0212 24.358 60.5064 24.6428 60.0316 24.8261L60.0291 24.8286Z"
        fill="#89178F"
      />
      <path
        d="M1 0H2.53183V11.9821H2.5993C4.06366 8.94571 6.46261 7.72472 9.19392 7.72472C12.39 7.72472 15.9884 9.37418 15.9884 15.0532V26.4087H14.4566V15.7144C14.4566 10.5333 11.5929 9.07945 8.96152 9.07945C4.69838 9.07945 2.53433 12.9406 2.53433 16.0438V26.4087H1V0Z"
        fill="#89178F"
      />
      <path
        d="M20.3091 16.8017C20.3091 11.1252 24.0399 7.16251 29.2352 7.16251C32.3663 7.16251 37.4616 8.61632 37.4616 16.076V16.9676H21.9758C21.9758 21.8194 25.007 25.0539 29.3026 25.0539C32.9335 25.0539 35.2975 22.6788 36.397 19.905L37.7614 20.3334C36.3296 24.2936 33.0335 26.4062 29.2027 26.4062C24.0724 26.4062 20.3116 22.5129 20.3116 16.8017H20.3091ZM35.7623 15.4494C35.7623 10.2682 32.1989 8.51725 29.1677 8.51725C25.3044 8.51725 22.5731 11.3555 22.0408 15.4494H35.7623Z"
        fill="#89178F"
      />
      <path
        d="M62.8504 16.8017C62.8504 11.1252 66.5813 7.16251 71.9414 7.16251C77.3016 7.16251 81 11.1227 81 16.8017C81 22.4807 77.2691 26.4062 71.9414 26.4062C66.6138 26.4062 62.8504 22.446 62.8504 16.8017ZM71.9414 25.0539C76.337 25.0539 79.3357 21.6535 79.3357 16.8017C79.3357 11.9499 76.3395 8.51725 71.9414 8.51725C67.5434 8.51725 64.5147 11.9499 64.5147 16.8017C64.5147 21.6535 67.5459 25.0539 71.9414 25.0539Z"
        fill="#89178F"
      />
      <path
        d="M49.1939 24.8286C48.5642 25.0713 47.897 25.1951 47.2098 25.1951C46.1503 25.1951 45.3281 24.8509 44.7659 24.1673C44.2211 23.511 43.9562 22.4683 43.9562 20.9798V2.41722H39.5057V3.52924H42.4369V20.9823C42.4369 22.0473 42.5418 22.9463 42.7492 23.6497C42.9467 24.3233 43.2365 24.8632 43.6064 25.2595C43.9787 25.6558 44.4335 25.9381 44.9933 26.1189C45.5805 26.3096 46.2652 26.4062 47.0274 26.4062C47.3647 26.4062 47.7446 26.3666 48.1569 26.2848C48.5717 26.2031 48.994 26.0818 49.4063 25.9282C49.8237 25.7746 50.2335 25.5815 50.6283 25.3561C50.8757 25.2149 51.1131 25.0589 51.3355 24.893L50.7333 23.9791C50.186 24.358 49.6712 24.6428 49.1964 24.8261L49.1939 24.8286Z"
        stroke="#89178F"
        stroke-width="0.5"
      />
      <path
        d="M60.0291 24.8286C59.3994 25.0713 58.7322 25.1951 58.045 25.1951C56.9855 25.1951 56.1633 24.8509 55.6011 24.1673C55.0563 23.511 54.7914 22.4683 54.7914 20.9798V2.41722H50.3409V3.52924H53.2721V20.9823C53.2721 22.0473 53.3771 22.9463 53.5845 23.6497C53.7819 24.3233 54.0718 24.8632 54.4416 25.2595C54.8139 25.6558 55.2687 25.9381 55.8285 26.1189C56.4157 26.3096 57.1004 26.4062 57.8626 26.4062C58.1999 26.4062 58.5798 26.3666 58.9921 26.2848C59.4069 26.2031 59.8292 26.0818 60.2416 25.9282C60.6589 25.7746 61.0687 25.5815 61.4635 25.3561C61.7109 25.2149 61.9483 25.0589 62.1707 24.893L61.5685 23.9791C61.0212 24.358 60.5064 24.6428 60.0316 24.8261L60.0291 24.8286Z"
        stroke="#89178F"
        stroke-width="0.5"
      />
      <path
        d="M1 0H2.53183V11.9821H2.5993C4.06366 8.94571 6.46261 7.72472 9.19392 7.72472C12.39 7.72472 15.9884 9.37418 15.9884 15.0532V26.4087H14.4566V15.7144C14.4566 10.5333 11.5929 9.07945 8.96152 9.07945C4.69838 9.07945 2.53433 12.9406 2.53433 16.0438V26.4087H1V0Z"
        stroke="#89178F"
        stroke-width="0.5"
      />
      <path
        d="M20.3091 16.8017C20.3091 11.1252 24.0399 7.16251 29.2352 7.16251C32.3663 7.16251 37.4616 8.61632 37.4616 16.076V16.9676H21.9758C21.9758 21.8194 25.007 25.0539 29.3026 25.0539C32.9335 25.0539 35.2975 22.6788 36.397 19.905L37.7614 20.3334C36.3296 24.2936 33.0335 26.4062 29.2027 26.4062C24.0724 26.4062 20.3116 22.5129 20.3116 16.8017H20.3091ZM35.7623 15.4494C35.7623 10.2682 32.1989 8.51725 29.1677 8.51725C25.3044 8.51725 22.5731 11.3555 22.0408 15.4494H35.7623Z"
        stroke="#89178F"
        stroke-width="0.5"
      />
      <path
        d="M62.8504 16.8017C62.8504 11.1252 66.5813 7.16251 71.9414 7.16251C77.3016 7.16251 81 11.1227 81 16.8017C81 22.4807 77.2691 26.4062 71.9414 26.4062C66.6138 26.4062 62.8504 22.446 62.8504 16.8017ZM71.9414 25.0539C76.337 25.0539 79.3357 21.6535 79.3357 16.8017C79.3357 11.9499 76.3395 8.51725 71.9414 8.51725C67.5434 8.51725 64.5147 11.9499 64.5147 16.8017C64.5147 21.6535 67.5459 25.0539 71.9414 25.0539Z"
        stroke="#89178F"
        stroke-width="0.5"
      />
    </svg>
  );
};

export default Hello;
