import { jsx as _jsx } from "react/jsx-runtime";
import Layout from 'antd/es/layout';
import useMediaQuery from '../../../../features/helpers/use-media-query';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Typography from '../../typography';
const { Footer } = Layout;
const AppFooter = () => {
    const { t } = useTranslations();
    const theme = useTheme();
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    return (_jsx(Footer, { style: {
            padding: isTablet ? '0px 96px 72px' : '25px 96px',
            textAlign: 'center',
            display: 'block',
            position: 'relative',
            background: 'transparent',
            marginTop: isTablet ? '36px' : '54px',
            height: theme.appearance.pageFooterHeight,
        }, children: _jsx(Typography, { elementTheme: "overline", bold: true, letterSpacing: 0.27, textTransform: "uppercase", color: theme.colors.grey_4, children: t('footer|allRightsReserved') }) }));
};
export default AppFooter;
