import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import Modal from 'antd/es/modal';
import Tooltip from 'antd/es/tooltip';
import { useMemo, useState } from 'react';
import { ContactInitialState } from '../../../features/entities';
import getFormattedDate from '../../../features/helpers/get-formatted-date';
import getShortId from '../../../features/helpers/get-short-id';
import handleDispatchResponse from '../../../features/helpers/handle-dispatch-response';
import useMediaQuery from '../../../features/helpers/use-media-query';
import { useTranslation } from '../../../plugins/i18n';
import { useTheme } from '../../../plugins/styled';
import Avatar from '../../common/avatar';
import Button from '../../common/button';
import Div from '../../common/div';
import Dropdown from '../../common/dropdown';
import Table from '../../common/table';
import Typography from '../../common/typography';
import { ProfileDropdown } from './styles';
import UserFormModal from './user-form-modal';
const ProfileUsersList = ({ users, contactId = '', companyId, handleContactDelete, handleContactAdd, handleContactEdit, handleContactAssign, handleEmailExists, isAdmin, isAccountManager, Auth0Buttons, destination, ClipboardComponent, isLoading, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [activeContact, setActiveContact] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const handleDelete = (contact) => {
        Modal.confirm({
            title: t('profile|profileForm.users.delete.title', {
                name: contact.name,
            }),
            content: t('profile|profileForm.users.delete.subtitle'),
            okText: t('button.confirm'),
            cancelText: t('button.cancel'),
            okButtonProps: {
                style: {
                    backgroundColor: theme.colors.purple_1,
                    color: theme.colors.white_1,
                    borderRadius: '24px',
                },
            },
            cancelButtonProps: {
                style: {
                    backgroundColor: theme.colors.white_1,
                    borderColor: theme.colors.grey_1,
                    color: theme.colors.grey_1,
                    borderRadius: '24px',
                },
            },
            onOk() {
                if (contact.contact_id)
                    handleContactDelete(contact.contact_id).then((res) => {
                        handleDispatchResponse(res, t('notification.saved'), t('notification.saving_error'));
                    });
            },
        });
    };
    const isTablet = useMediaQuery({ maxWidth: 1000 });
    const DropdownLabel = ({ label, Icon, }) => {
        return (_jsxs(Div, { flex: "row", gap: 11, children: [_jsx(Icon, { size: 14 }), _jsx(Typography, { elementTheme: "body3", children: label })] }));
    };
    const Label = ({ v, medium }) => {
        if (!v)
            return v;
        return (_jsx(Tooltip, { title: v, children: _jsx("div", { children: _jsx(Typography, { nowrap: true, ellipsis: true, medium: medium, children: v }) }) }));
    };
    const columns = useMemo(() => {
        const columns = [];
        if (isAdmin && !!ClipboardComponent) {
            columns.push({
                width: 100,
                title: 'Contact ID',
                dataIndex: 'contact_id',
                key: 'contact_id',
                render: (contactId) => ClipboardComponent({ copy: contactId, title: getShortId(contactId) }),
            });
        }
        columns.push(...[
            {
                width: 300,
                title: t('profile|profileForm.users.list.username'),
                dataIndex: 'name',
                key: 'name',
                render: (v) => (_jsxs("div", { style: { display: 'flex', gap: '16px', alignItems: 'center' }, children: [_jsx(Avatar, { shape: "round", size: 50, children: v[0] }), _jsx("div", { style: { flex: 1, overflow: 'hidden' }, children: _jsx(Label, { v: v, medium: true }) })] })),
                sorter: {
                    compare: (a, b) => a.name.localeCompare(b.name),
                    multiple: 4,
                },
            },
            {
                title: t('profile|profileForm.users.list.email'),
                dataIndex: 'email',
                key: 'email',
                render: (v) => _jsx(Label, { v: v }),
                sorter: {
                    compare: (a, b) => ((a === null || a === void 0 ? void 0 : a.email) || '').localeCompare((b === null || b === void 0 ? void 0 : b.email) || ''),
                    multiple: 1,
                },
            },
            {
                title: t('profile|profileForm.users.list.phone'),
                dataIndex: 'phone',
                key: 'phone',
                render: (v) => _jsx(Label, { v: v }),
                sorter: {
                    compare: (a, b) => ((a === null || a === void 0 ? void 0 : a.phone) || '').localeCompare((b === null || b === void 0 ? void 0 : b.phone) || ''),
                    multiple: 1,
                },
            },
        ]);
        if (isAdmin || isAccountManager) {
            columns.push({
                title: 'Last login',
                key: 'last_login_at',
                render: (v) => {
                    return v.last_login_at ? (_jsx(Typography, { elementTheme: "caption", nowrap: true, children: getFormattedDate(v.last_login_at) })) : (_jsx(Typography, { elementTheme: "caption", nowrap: true, children: "--/--/--" }));
                },
                sorter: {
                    compare: (a, b) => ((a === null || a === void 0 ? void 0 : a.last_login_at) || '').localeCompare((b === null || b === void 0 ? void 0 : b.last_login_at) || ''),
                    multiple: 1,
                },
            });
        }
        columns.push({
            title: t('profile|profileForm.users.list.role'),
            dataIndex: 'role',
            key: 'role',
            render: (_, record) => {
                return _jsx(Label, { v: record.roles ? record.roles : 'User' });
            },
        });
        columns.push({
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 60,
            fixed: 'right',
            className: 'actions',
            render: (_, record) => {
                const actionMenuItems = [
                    {
                        label: (_jsx(DropdownLabel, { label: t('profile|profileForm.users.actions.edit', {
                                name: record === null || record === void 0 ? void 0 : record.name,
                            }), Icon: Edit })),
                        key: 2,
                        onClick: () => {
                            handleUserEdit(record);
                        },
                        disabled: !handleContactEdit || (!isAdmin && !isAccountManager),
                    },
                    {
                        label: (_jsx(DropdownLabel, { label: t('profile|profileForm.users.actions.delete', {
                                name: record === null || record === void 0 ? void 0 : record.name,
                            }), Icon: Delete })),
                        key: 1,
                        onClick: () => {
                            handleDelete(record);
                        },
                        disabled: record.contact_id === contactId ||
                            (!isAdmin && !isAccountManager),
                    },
                ].filter(Boolean);
                return (_jsx(Dropdown, { items: actionMenuItems, menuStyle: {
                        borderRadius: 0,
                        padding: 0,
                        boxShadow: 'none',
                    }, cta: { size: 30, color: theme.colors.primary }, dropdownRender: (originalNode) => (_jsx(ProfileDropdown, { children: originalNode })) }));
            },
        });
        if (isAdmin && !!Auth0Buttons && destination) {
            columns.push({
                title: 'Auth0 Actions',
                dataIndex: 'actions',
                key: 'auth_actions',
                render: (_, record) => (_jsx(Auth0Buttons, { contact: record, destination: destination, compactView: true })),
            });
        }
        return columns;
    }, [users]);
    const onClick = (c) => {
        setActiveContact(c);
    };
    const handleUserEdit = (contact) => {
        setActiveContact(contact !== null && contact !== void 0 ? contact : ContactInitialState);
        setEditModalOpen(true);
    };
    const tablePros = {
        dataSource: users,
        sticky: true,
        columns,
        rowKey: (record) => record.contact_id,
        scroll: { x: isAdmin ? 1800 : 1200 },
        onRow: (record) => {
            return {
                onClick: () => {
                    onClick(record);
                },
            };
        },
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Div, { flex: "column", gap: 34, children: [_jsxs(Div, { flex: "row", justify: "space-between", align: "center", children: [_jsx(Typography.Title, { level: 2, elementTheme: "h6", bold: true, nowrap: true, children: t('profile|profileForm.users.title') }), _jsx(Div, { flex: "row", gap: 10, fitContent: true, children: _jsx(Button, { headingIcon: _jsx(AddNew, { width: 14, height: 14 }), size: "small", style: { minWidth: 177 }, tablet: { style: { width: 32, minWidth: 'auto' } }, defaultStyle: theme.colors.primary, onClick: () => handleUserEdit(), children: !isTablet ? t('profile|profileForm.users.addUser') : undefined }) })] }), _jsx(Div, { children: _jsx(Table, Object.assign({}, tablePros)) })] }), _jsx(UserFormModal, { open: editModalOpen, companyId: companyId, handleClose: () => setEditModalOpen(false), handleEditUser: handleContactEdit, handleContactAdd: handleContactAdd, handleContactAssign: handleContactAssign, handleEmailExists: handleEmailExists, contact: activeContact, showPhone: destination === 'Partner' || isAdmin, isLoading: isLoading })] }));
};
export default ProfileUsersList;
