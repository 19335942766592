import styled from '../../plugins/styled';
export const DrawerEditRowContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  background-color: ${({ theme, $hidden }) => $hidden ? theme.colors.grey_5 : theme.colors.beige_3};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 2px;
  padding: 8px 12px;
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};
  &:hover {
    transform: translateY(-2px);
    cursor: pointer;
    box-shadow: ${({ theme }) => theme.appearance.box_shadow_hover};
    backface-visibility: hidden;
  }
`;
