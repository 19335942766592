import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { ItemsTypes, } from '../../../features/entities/form-entities';
import Div from '../../common/div';
import FormContent from './controllers/form';
import FormItemsList from './controllers/list';
import { handleDeleteFormGroup, handleNewGroup_DropInPage_FromControls, handleReorderFormGroups, } from './dnd-functions/group';
import { handleNewItem_DropInGroup_FromControls, handleReorderFormItems, } from './dnd-functions/item';
import { handleDeleteFormPage, handleNewPageDrop_FromControls, handleReorderFormPages, } from './dnd-functions/page';
import { FormContentStyled } from './styles';
const FormBuilder = ({ form_id, functions, formResponse, locale, openGrant, }) => {
    const formPages = useMemo(() => {
        var _a, _b;
        const newFormPages = {};
        if (!((_a = formResponse.pages) === null || _a === void 0 ? void 0 : _a.length))
            return newFormPages;
        for (const page of formResponse.pages) {
            if (!page)
                continue;
            newFormPages[page.form_page_id] = {
                id: page.form_page_id,
                entity: page,
                groups: (_b = page === null || page === void 0 ? void 0 : page.groups) === null || _b === void 0 ? void 0 : _b.reduce((o1, group) => {
                    var _a;
                    return !!group
                        ? Object.assign(Object.assign({}, o1), { [group.form_group_id]: {
                                id: group.form_group_id,
                                entity: group,
                                items: (_a = group === null || group === void 0 ? void 0 : group.items) === null || _a === void 0 ? void 0 : _a.reduce((o2, itm) => !!itm
                                    ? Object.assign(Object.assign({}, o2), { [itm.form_item_id]: {
                                            type: itm.type,
                                            fieldType: itm === null || itm === void 0 ? void 0 : itm.field_type,
                                            entity: itm,
                                            category: ItemsTypes.items,
                                            id: itm.forms_groups_items_id,
                                        } }) : o2, {}),
                            } }) : o1;
                }, {}),
            };
        }
        return newFormPages;
    }, [formResponse]);
    const onDragEnd = async (data) => {
        const { draggableId, source, destination } = data;
        if (source && destination) {
            if (destination.droppableId.includes('controls_droppable')) {
                if (draggableId.includes('page_draggable_')) {
                    const form_page_id = draggableId.replace('page_draggable_', '');
                    handleDeleteFormPage({
                        form_page_id,
                        form_id,
                        formResponse,
                        formPages,
                        functions,
                    });
                }
                else if (draggableId.includes('group_draggable_')) {
                    const form_page_id = source.droppableId.replace('page_droppable_', '');
                    const form_group_id = draggableId.replace('group_draggable_', '');
                    handleDeleteFormGroup({
                        form_group_id,
                        form_id,
                        form_page_id,
                        formPages,
                        functions,
                    });
                }
                else if (draggableId.includes('form_item_draggable_')) {
                    const form_item_id = draggableId.replace('form_item_draggable_', '');
                    functions.deleteFormItem(form_item_id);
                }
                return;
            }
            else if (source.droppableId.includes('controls_droppable')) {
                if (destination.droppableId.includes('group_droppable')) {
                    const form_group_id = destination.droppableId.replace('group_droppable_', '');
                    const field_type = draggableId.replace('controls_droppable_', '');
                    handleNewItem_DropInGroup_FromControls({
                        form_group_id,
                        field_type,
                        form_id,
                        item_order: destination.index,
                        functions,
                        formPages,
                    });
                }
                else if (destination.droppableId.includes('page_droppable')) {
                    if (draggableId.includes('group')) {
                        const form_page_id = destination.droppableId.replace('page_droppable_', '');
                        handleNewGroup_DropInPage_FromControls({
                            form_page_id,
                            form_id,
                            group_order: destination.index,
                            functions,
                        });
                    }
                }
                else if (destination.droppableId.includes('form_droppable')) {
                    if (draggableId.includes('page')) {
                        handleNewPageDrop_FromControls({
                            form_id,
                            functions,
                            page_number: destination.index,
                        });
                    }
                }
            }
            else if (source.droppableId.includes('form_droppable')) {
                if (destination.droppableId.includes('form_droppable')) {
                    if (draggableId.includes('page_draggable_')) {
                        const formPageId = draggableId.replace('page_draggable_', '');
                        const page_order = destination.index;
                        handleReorderFormPages({
                            formPageId,
                            page_order,
                            functions,
                            formResponse,
                        });
                    }
                }
            }
            else if (source.droppableId.includes('page_droppable')) {
                if (destination.droppableId.includes('page_droppable')) {
                    if (draggableId.includes('group_draggable_')) {
                        const form_group_id = draggableId.replace('group_draggable_', '');
                        const group_order = destination.index;
                        if (source.droppableId === destination.droppableId) {
                            const form_page_id = destination.droppableId.replace('page_droppable_', '');
                            handleReorderFormGroups({
                                form_group_id,
                                form_page_id,
                                functions,
                                group_order,
                                formPages,
                            });
                        }
                        else {
                            const form_page_source_id = source.droppableId.replace('page_droppable_', '');
                            const form_page_destination_id = destination.droppableId.replace('page_droppable_', '');
                            functions.transferFormGroup({
                                form_group_id,
                                form_page_source_id,
                                form_page_destination_id,
                                group_order,
                            });
                        }
                    }
                }
            }
            else if (source.droppableId.includes('group_droppable')) {
                if (destination.droppableId.includes('group_droppable')) {
                    if (draggableId.includes('form_item_draggable')) {
                        const form_item_id = draggableId.replace('form_item_draggable_', '');
                        if (source.droppableId === destination.droppableId) {
                            const item_order = destination.index;
                            const form_group_id = destination.droppableId.replace('group_droppable_', '');
                            handleReorderFormItems({
                                form_group_id,
                                form_item_id,
                                functions,
                                item_order,
                                formPages,
                            });
                        }
                        else {
                        }
                    }
                }
            }
        }
    };
    return (_jsx(DragDropContext, { onDragEnd: onDragEnd, children: _jsxs(Div, { flex: "row", tablet: { flex: 'column' }, children: [_jsx(FormItemsList, { locale: locale }), _jsx(FormContentStyled, { children: _jsx(FormContent, { formResponse: formResponse, pages: !!formPages ? Object.values(formPages) : [], functions: functions, locale: locale, openGrant: openGrant }) })] }) }));
};
export default FormBuilder;
