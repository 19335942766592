var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useForm from 'antd/es/form/hooks/useForm';
import Select from 'antd/es/select';
import { useState } from 'react';
import { useTranslation } from '../../../../plugins/i18n';
import FormItem from '../../form-layouts/form-item';
import FormLabel from '../../form-layouts/form-label';
const SelectCountry = (_a) => {
    var { setCanada, form } = _a, selectProps = __rest(_a, ["setCanada", "form"]);
    const { t } = useTranslation();
    const handleSelectChange = (value) => {
        setCanada(value);
        if (value) {
            form.setFieldValue('country', 'Canada');
        }
        else {
            form.setFieldValue('country', '');
        }
    };
    return (_jsx(FormItem, { label: _jsx(FormLabel, { label: t('profile|profileForm.address.labels.country') }), name: "isCanada", style: { minWidth: 175 }, children: _jsxs(Select, Object.assign({}, selectProps, { onChange: handleSelectChange, size: "large", placeholder: t('profile|profileForm.address.extra.selectCountry'), children: [_jsx(Select.Option, { value: true, children: "Canada" }), _jsx(Select.Option, { value: false, children: t('profile|profileForm.address.labels.other') })] })) }));
};
export default SelectCountry;
SelectCountry.Demo = () => {
    const [form] = useForm();
    const [isCanada, setCanada] = useState(false);
    return _jsx(SelectCountry, { form: form, setCanada: setCanada, disabled: true });
};
