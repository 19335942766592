import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from '../../../../plugins/styled';
import List from '../../../common/List';
import Div from '../../../common/div';
import TeamMemberCard from './team-member-card';
const TeamList = ({ teamMembers, selectedMember, setSelectedMember, isEditable, setMembers, functions, provider, }) => {
    const theme = useTheme();
    return (_jsx(Div, { bordersSide: "left", borderColor: theme.colors.primary, style: {
            paddingInline: 24,
            paddingBlock: 16,
        }, children: _jsx(List, { grid: { column: 1, gutter: 20 }, dataSource: isEditable ? [{}, ...teamMembers] : teamMembers, renderItem: (m) => selectedMember ? (_jsx(List.Item, { style: { paddingBottom: 24, marginBottom: 0, height: 'auto' }, children: _jsx(TeamMemberCard, { selected: m.provider_team_member_id ===
                        selectedMember.provider_team_member_id, member: m, handleClick: () => setSelectedMember(m), isEditable: isEditable, teamMembers: teamMembers, setTeamMembers: setMembers, functions: functions, provider: provider }, m.provider_team_member_id) })) : null }) }));
};
export default TeamList;
