import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
const ProgramEmptyStateHeader = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (_jsxs(Div, { flex: "column", gap: 24, style: { maxWidth: 900 }, children: [_jsxs(Typography.Title, { level: 1, elementTheme: "h2", textAlign: "center", noMargin: true, children: [t('propulsion|empty.title'), _jsx(Typography.Highlight, { color: theme.colors.green_2, children: t('propulsion|empty.programName') })] }), _jsx(Typography, { color: theme.colors.purple_1, elementTheme: "subtitle2", textAlign: "center", children: t('propulsion|empty.subtitle') })] }));
};
export default ProgramEmptyStateHeader;
