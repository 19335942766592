import FilesList from '@hellodarwin/core/lib/components/files/files-list';
import {
  AssetEntity,
  AssetHiddenType,
  AssetOwnerInformation,
  AssetRecordInformation,
} from '@hellodarwin/core/lib/features/entities';
import { useEffect, useMemo } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../../app';
import {
  deleteAsset,
  downloadAsset,
  fetchFoldersFromOwner,
  fetchGameplansFromCompany,
  hideShowAsset,
  selectAllFolders,
  selectAllGamePlans,
  selectFolderById,
  uploadAsset,
} from '../../../features/api/slices/assets-slice';
import { useNewAdminApi } from '../../../features/api/use-admin-api';

interface AdminFilesListProps {
  owner: AssetOwnerInformation;
  vaultPath: string;
  handleNavigateToFolder?: (folderId: string) => void;
  folderId?: string;
  isGameplanFileList?: boolean;
}

const AdminFilesList = ({
  owner,
  vaultPath,
  handleNavigateToFolder,
  folderId,
  isGameplanFileList,
}: AdminFilesListProps) => {
  const api = useNewAdminApi();
  const dispatch = useAppDispatch();

  const folders = useAppSelector(selectAllFolders);
  const folder = useAppSelector((state: RootState) =>
    selectFolderById(state, folderId ?? ''),
  );
  const gameplans = useAppSelector(selectAllGamePlans);

  useEffect(() => {
    if (isGameplanFileList) {
      dispatch(fetchGameplansFromCompany({ api, companyId: owner.owner_id }));
    } else {
      dispatch(fetchFoldersFromOwner({ api, owner }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owner.owner_id]);

  const handleDownload = async (asset: AssetEntity) => {
    try {
      const url = (
        await dispatch(
          downloadAsset({ api, asset_id: asset.asset_id }),
        ).unwrap()
      ).link;
      window.open(url, 'download');
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveAsset = async (
    formData: FormData,
  ): Promise<'rejected' | 'fulfilled'> =>
    (
      await dispatch(
        uploadAsset({
          api,
          formData,
        }),
      )
    ).meta.requestStatus;

  const rootRecord: AssetRecordInformation = useMemo(
    () => ({
      record_id: owner.owner_id,
      record_name: owner.owner_name,
      record_type: owner.owner_type,
    }),
    [owner],
  );

  const handleHideShowAssets = (asset_id: string, hide: AssetHiddenType) => {
    dispatch(hideShowAsset({ api, asset_id, hide }));
  };

  const handleDeleteAsset = (assetId: string) => {
    dispatch(deleteAsset({ api, assetId }));
  };

  const activeRecord: AssetRecordInformation | undefined = useMemo(
    () => (!!folder.record_id?.length ? folder : undefined),
    [folder],
  );

  return (
    <div>
      <FilesList
        handleNavigateToFolder={handleNavigateToFolder}
        handleDownload={handleDownload}
        handleHideShowAsset={handleHideShowAssets}
        handleDeleteAsset={handleDeleteAsset}
        items={
          isGameplanFileList
            ? gameplans
            : !!folder && folder.asset_details.length > 0
              ? folder.asset_details
              : folders
        }
        vaultPath={vaultPath}
        handleSaveAsset={handleSaveAsset}
        activeRecord={activeRecord}
        rootRecord={rootRecord}
        additionalOwners={[owner]}
        allowUpload={!isGameplanFileList}
      />
    </div>
  );
};

export default AdminFilesList;
