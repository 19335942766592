import { DocumentProps, PDFViewer, usePDF } from '@react-pdf/renderer';
import { ReactNode } from 'react';
import Loading from './components/loading';
import DarwinPdfDownload from './download';
import theme from './theme';
import DarwinPdfUpload from './upload';

export interface DarwinPdfViewerProps {
  document: React.ReactElement<DocumentProps>;
  fileName: string;
  downloadCta?: ReactNode;
  uploadCta?: ReactNode;
  handleUpload?: (file: File) => Promise<boolean>;
  handleDownload?: () => void;
}

const DarwinPdfViewer: React.FC<DarwinPdfViewerProps> = ({
  document,
  fileName,
  downloadCta,
  uploadCta,
  handleUpload,
  handleDownload,
}) => {
  const [instance, updateInstance] = usePDF({ document });

  if (instance.loading) return <Loading size="extra-large"></Loading>;

  return (
    <>
      {(!!downloadCta || (!!uploadCta && !!handleUpload)) && (
        <div
          style={{
            background: theme.colors.beige_1,
            width: '100%',
            border: `1px solid ${theme.colors.grey_1}`,
            display: 'flex',
            padding: 4,
            alignItems: 'center',
            justifyContent: 'flex-end',
            height: 52,
            gap: 8,
          }}
        >
          {!!uploadCta && !!handleUpload && (
            <DarwinPdfUpload
              handleUpload={handleUpload}
              document={document}
              fileName={fileName}
            >
              {uploadCta}
            </DarwinPdfUpload>
          )}
          {!!downloadCta && (
            <DarwinPdfDownload
              fileName={fileName}
              document={document}
              onDownload={handleDownload}
            >
              {downloadCta}
            </DarwinPdfDownload>
          )}
        </div>
      )}
      <div
        style={{
          height: 'calc(100% - 52px)',
          border: `1px solid ${theme.colors.grey_1}`,
        }}
      >
        <PDFViewer width={'100%'} height={'100%'} showToolbar={false}>
          {document}
        </PDFViewer>
      </div>
    </>
  );
};

export default DarwinPdfViewer;
