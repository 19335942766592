export var CancelRfpFlowSteps;
(function (CancelRfpFlowSteps) {
    CancelRfpFlowSteps["cancelFormStep"] = "Cancel Form step";
    CancelRfpFlowSteps["cancelConfirmation"] = "Cancel Confirmation step";
})(CancelRfpFlowSteps || (CancelRfpFlowSteps = {}));
export var NoPartnerSelectedFlowSteps;
(function (NoPartnerSelectedFlowSteps) {
    NoPartnerSelectedFlowSteps["optionsStep"] = "Options step";
    NoPartnerSelectedFlowSteps["bookConsultStep"] = "Book Consultation step";
    NoPartnerSelectedFlowSteps["needMoreTimeStep"] = "Need More Time step";
})(NoPartnerSelectedFlowSteps || (NoPartnerSelectedFlowSteps = {}));
export var ChoosePartnerFlowSteps;
(function (ChoosePartnerFlowSteps) {
    ChoosePartnerFlowSteps["chosenSpStep"] = "Chosen SP";
    ChoosePartnerFlowSteps["progressStep"] = "Progress of Project";
    ChoosePartnerFlowSteps["noReviewStep"] = "No review";
    ChoosePartnerFlowSteps["QuickReviewStep"] = "Quick review";
})(ChoosePartnerFlowSteps || (ChoosePartnerFlowSteps = {}));
export var PossibleCancelAnswers;
(function (PossibleCancelAnswers) {
    PossibleCancelAnswers["Budget"] = "budget";
    PossibleCancelAnswers["Rescoping"] = "rescoping";
    PossibleCancelAnswers["PartnerSelection"] = "partnerSelection";
    PossibleCancelAnswers["OutsideHD"] = "outside";
    PossibleCancelAnswers["Other"] = "other";
})(PossibleCancelAnswers || (PossibleCancelAnswers = {}));
export var RfpProgress;
(function (RfpProgress) {
    RfpProgress[RfpProgress["NotStarted"] = 0] = "NotStarted";
    RfpProgress[RfpProgress["InProgress"] = 50] = "InProgress";
    RfpProgress[RfpProgress["Completed"] = 100] = "Completed";
})(RfpProgress || (RfpProgress = {}));
export var ReviewFormSteps;
(function (ReviewFormSteps) {
    ReviewFormSteps["Experience"] = "Experience";
    ReviewFormSteps["Contact"] = "Contact";
    ReviewFormSteps["Confirm"] = "Confirm";
    ReviewFormSteps["End"] = "End";
})(ReviewFormSteps || (ReviewFormSteps = {}));
