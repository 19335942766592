import Container from '@hellodarwin/core/lib/components/common/container';
import Div from '@hellodarwin/core/lib/components/common/div';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import { GrantRfpTag } from '@hellodarwin/core/lib/features/entities';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import Search from '@hellodarwin/icons/dist/icons/Search';
import { Button, Input, message } from 'antd';
import { ChangeEventHandler, useState } from 'react';
import { useAppDispatch } from '../../app';
import { updateGrantRfpTags } from '../../features/api/slices/new-tags-slice';
import { useNewAdminApi } from '../../features/api/use-admin-api';
import MatchmakingList from './matchmaking-list';

const RfpTagsMatchmakingPage = () => {
  const newApi = useNewAdminApi();
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [addedGrantRfpTags, setAddedGrantRfpTags] = useState<GrantRfpTag[]>([]);
  const [deletedGrantRfpTags, setDeletedGrantRfpTags] = useState<GrantRfpTag[]>(
    [],
  );
  const { selectedLocale } = useLocale();

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchTerm(e.target.value);
  };

  const onSave = async () => {
    try {
      setLoadingSave(true);

      await dispatch(
        updateGrantRfpTags({
          api: newApi,
          locale: selectedLocale,
          added_tags: addedGrantRfpTags,
          deleted_tags: deletedGrantRfpTags,
        }),
      );

      setAddedGrantRfpTags([]);
      setDeletedGrantRfpTags([]);

      message.success('Saved!');
      setLoadingSave(false);
    } catch (e: any) {
      message.error('Something went wrong, try again later!');
      console.error(e);
      setLoadingSave(false);
    }
  };

  return (
    <PageLayout
      app="admin"
      title="RFP tags matchmaking"
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: 'RFP tags matchmaking',
        },
      ]}
      actions={[
        <Div flex="row" gap={8} align="center">
          <Button
            onClick={onSave}
            size="large"
            type="primary"
            color={'primary'}
            loading={loadingSave}
            disabled={loadingSave}
          >
            Save
          </Button>
          <Input
            prefix={<Search size={20} />}
            placeholder="Search expenses (exact name)"
            size="large"
            style={{ width: 500, maxWidth: '100%' }}
            value={searchTerm}
            onChange={handleSearch}
          />
        </Div>,
      ]}
    >
      <Container>
        <Div flex="column">
          <MatchmakingList
            searchTerm={searchTerm}
            setAddedGrantRfpTags={setAddedGrantRfpTags}
            setDeletedGrantRfpTags={setDeletedGrantRfpTags}
          />
        </Div>
      </Container>
    </PageLayout>
  );
};

export default RfpTagsMatchmakingPage;
