import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { usePlatform } from '../../../../features/providers/platform-provider';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../div';
import HdTag from '../../hd-tag';
import { Tablet } from '../../Media';
import Typography from '../../typography';
const PageLayoutTitle = ({ title, status }) => {
    const theme = useTheme();
    const { app } = usePlatform();
    return (_jsxs(Div, { flex: "row", align: "center", gap: 24, tablet: {
            flex: 'column-reverse',
            align: 'flex-start',
            gap: 14,
        }, style: { maxWidth: '100%' }, fitContent: true, children: [_jsx(Typography.Title, { level: 1, elementTheme: app === 'admin' ? 'h6' : 'h5', noMargin: true, color: theme.colors.primary, fitContent: true, nowrap: true, style: { maxWidth: '100%' }, children: title }), status && (_jsxs(_Fragment, { children: [_jsx(Tablet, { direction: "up", children: _jsx("div", { style: {
                                height: 'auto',
                                alignSelf: 'stretch',
                                borderLeft: `1px solid ${theme.colors.primary}`,
                            } }) }), _jsx(HdTag, Object.assign({}, status))] }))] }));
};
export default PageLayoutTitle;
