'use client';
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Close from '@hellodarwin/icons/dist/icons/Close';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { ModalBody, ModalCloseButton, ModalContainer, ModalContent, ModalFooter, ModalHeader, ModalWrapper, } from './styles';
import useModalOpenFunctions from './use-modal-open-functions';
const Modal = ({ children, open, handleCancel, size = 'medium', noPadding, className, styles, style, header, footer, afterClose, }) => {
    const { onCancel, fadeOut, isOpen } = useModalOpenFunctions({
        handleCancel,
        open,
        afterClose,
    });
    const classes = classNames('hd-modal', className, { [`fade-out`]: fadeOut });
    if (!isOpen && !fadeOut)
        return _jsx(_Fragment, {});
    return (_jsx(_Fragment, { children: createPortal(_jsx(ModalWrapper, { onClick: onCancel, className: classes, style: Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.wrapper), children: _jsxs(ModalContainer, { "$size": size, style: Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.container), "$noPadding": noPadding, children: [!!header && (_jsx(ModalHeader, { onClick: (e) => e.stopPropagation(), children: header })), _jsx(ModalContent, { onClick: (e) => e.stopPropagation(), style: Object.assign(Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.content), style), "$withHeader": !!header, "$withFooter": !!footer, children: _jsxs(ModalBody, { style: Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.body), children: [_jsx(ModalCloseButton, { onClick: handleCancel, transparent: true, size: "small", headingIcon: _jsx(Close, { size: 16 }) }), children] }) }), !!footer && (_jsx(ModalFooter, { onClick: (e) => e.stopPropagation(), children: footer }))] }) }), document.getElementById('root')) }));
};
export default Modal;
