import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import useLocale from '../../../features/providers/locale-provider';
import { useTheme } from '../../../plugins/styled';
import Div from '../../common/div';
import { default as ContactForm } from './contact-form';
import FormCurrentPage from './form-current-page';
import FormCurrentPageEndFooter from './form-current-page/form-current-page-footer';
import FormHeader from './form-header';
import FormLoading from './form-loading';
var FormStates;
(function (FormStates) {
    FormStates["ContactForm"] = "Contact Form";
    FormStates["InquiryForm"] = "Inquiry Form";
    FormStates["Loading"] = "Loading";
})(FormStates || (FormStates = {}));
const FormViewer = ({ formResponse, handleSave, handleClose, type, contact, setHasData, source, page_name, withLoading, }) => {
    var _a, _b, _c;
    const theme = useTheme();
    const { selectedLocale } = useLocale();
    const [state, setState] = useState(FormStates.InquiryForm);
    const [forms, setForms] = useState({
        contactForm: undefined,
        dataForm: undefined,
    });
    const [formAssetList, setFormAssetList] = useState([]);
    const contactInitialValues = useMemo(() => {
        var _a, _b;
        return ({
            first_name: '',
            last_name: '',
            company_name: '',
            email: (_a = contact === null || contact === void 0 ? void 0 : contact.email) !== null && _a !== void 0 ? _a : '',
            phone: (_b = contact === null || contact === void 0 ? void 0 : contact.phone) !== null && _b !== void 0 ? _b : '',
            province: 'QC',
            preferred_language: selectedLocale,
        });
    }, [contact]);
    const [formData, setFormData] = useState({});
    const [contactData, setContactData] = useState(contactInitialValues);
    useEffect(() => {
        setContactData(contactInitialValues);
    }, [contactInitialValues]);
    const [history, setHistory] = useState([]);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [hasFinished, setHasFinished] = useState(false);
    const currentPage = useMemo(() => { var _a; return (_a = formResponse.pages) === null || _a === void 0 ? void 0 : _a[currentPageIndex]; }, [currentPageIndex, formResponse.pages]);
    const [mappedItems, setMappedItems] = useState({});
    useEffect(() => {
        var _a;
        const newMappedItems = Object.assign({}, mappedItems);
        (_a = currentPage === null || currentPage === void 0 ? void 0 : currentPage.groups) === null || _a === void 0 ? void 0 : _a.forEach((group) => {
            var _a;
            (_a = group.items) === null || _a === void 0 ? void 0 : _a.forEach((item) => {
                newMappedItems[item.form_item_id] = item;
            });
        });
        setMappedItems(newMappedItems);
    }, [currentPage]);
    const handleGoToContactForm = () => {
        if (!contact) {
            setState(FormStates.ContactForm);
        }
        else {
            handleLoadingState();
        }
    };
    const handleLoadingState = () => {
        if (state === FormStates.Loading || !withLoading) {
            setHasFinished(true);
            setState(FormStates.InquiryForm);
        }
        else {
            setState(FormStates.Loading);
        }
    };
    const handleSaveContact = (values) => {
        setContactData((prevData) => (Object.assign(Object.assign({}, prevData), values)));
        handleLoadingState();
    };
    const onContactChange = (key, value) => {
        setContactData((prevData) => (Object.assign(Object.assign({}, prevData), { [key]: value })));
    };
    const handleReset = () => {
        var _a, _b;
        (_a = forms.dataForm) === null || _a === void 0 ? void 0 : _a.resetFields();
        (_b = forms.contactForm) === null || _b === void 0 ? void 0 : _b.resetFields();
        setContactData(contactInitialValues);
        setFormData({});
        setMappedItems({});
        setHistory([]);
        setCurrentPageIndex(0);
        setProgressValue(0);
        setHasFinished(false);
        setState(FormStates.InquiryForm);
        !!setHasData && setHasData(false);
    };
    const isEndPage = !!((_a = currentPage === null || currentPage === void 0 ? void 0 : currentPage.page_type) === null || _a === void 0 ? void 0 : _a.includes('end')) &&
        state === FormStates.InquiryForm;
    const isOpening = !!(currentPage === null || currentPage === void 0 ? void 0 : currentPage.page_type) &&
        (currentPage === null || currentPage === void 0 ? void 0 : currentPage.page_type.includes('opening')) &&
        state === FormStates.InquiryForm;
    const isContact = state === FormStates.ContactForm;
    useEffect(() => {
        return handleReset;
    }, []);
    useEffect(() => {
        if (hasFinished) {
            handleFinish();
        }
        if (!!setHasData) {
            if (!!Object.keys(formData).length && !isEndPage) {
                setHasData(true);
            }
            else {
                setHasData(false);
            }
        }
    }, [formData, isEndPage, hasFinished]);
    const handleSubmit = async (data) => {
        var _a;
        setFormData((prevData) => (Object.assign(Object.assign({}, prevData), data)));
        let nextPageNumber = currentPageIndex + 1;
        let errorFound = false;
        const keyToCheck = determinedKeysToCheck(currentPage);
        (_a = Object.entries(data)) === null || _a === void 0 ? void 0 : _a.filter(([key, _]) => keyToCheck.includes(key)).some(([key, value]) => {
            const nextPage = determinedFormItemCondition(key, value);
            if (nextPage !== currentPageIndex + 1) {
                nextPageNumber = nextPage;
            }
            return false;
        });
        if (!errorFound) {
            history.push(nextPageNumber);
            setHistory(history);
            setProgressValue(progress + 100 / formResponse.number_pages);
            if (isEnding(nextPageNumber)) {
                handleGoToContactForm();
                setProgressValue(100 - 100 / formResponse.number_pages);
            }
            setCurrentPageIndex(nextPageNumber);
        }
    };
    const isEnding = (nextPageNumber) => {
        return (!!formResponse.pages[nextPageNumber].page_type &&
            formResponse.pages[nextPageNumber].page_type.includes('end'));
    };
    const [progress, setProgressValue] = useState(0);
    const handleNext = () => {
        var _a, _b;
        if (state === FormStates.ContactForm) {
            (_a = forms.contactForm) === null || _a === void 0 ? void 0 : _a.validateFields().then(handleSaveContact).catch((e) => console.error(e));
        }
        else {
            try {
                (_b = forms.dataForm) === null || _b === void 0 ? void 0 : _b.validateFields().then(handleSubmit).catch((e) => console.error(e));
            }
            catch (error) {
                console.error(error);
            }
        }
    };
    const handlePrevious = () => {
        if (state === FormStates.ContactForm) {
            setState(FormStates.InquiryForm);
            setProgressValue(((history.length - 1) * 100) / formResponse.number_pages);
        }
        else {
            setProgressValue(progress - 100 / formResponse.number_pages);
        }
        if (history.length > 1) {
            const previousPageNumber = history[history.length - 2];
            setHistory(history.slice(0, -1));
            setCurrentPageIndex(previousPageNumber);
        }
        else {
            setHistory([]);
            setCurrentPageIndex(0);
        }
    };
    const determinedKeysToCheck = (page) => {
        var _a;
        let keys = [];
        (_a = page.groups) === null || _a === void 0 ? void 0 : _a.forEach((group) => {
            var _a;
            (_a = group.items) === null || _a === void 0 ? void 0 : _a.forEach((item) => {
                keys.push(item.form_item_id);
            });
        });
        return keys;
    };
    const determinedFormItemCondition = (key, value) => {
        const item = mappedItems[key];
        if (item && item.form_item_conditions) {
            for (const condition of item.form_item_conditions) {
                if (condition.condition_type === 'equals' ||
                    condition.condition_type === 'contains') {
                    if (!!condition.trigger_values.find(({ trigger_value }) => trigger_value === value)) {
                        if (!!condition.action_page) {
                            return condition.action_page;
                        }
                    }
                }
            }
        }
        return currentPageIndex + 1;
    };
    const determinedEligibilityState = () => {
        const pageType = currentPage === null || currentPage === void 0 ? void 0 : currentPage.page_type;
        if (pageType && pageType.includes('end')) {
            if (pageType === 'elligible-end-icp' || pageType === 'elligible-end') {
                return 'eligible';
            }
            else if (pageType === 'maybe-elligible-end') {
                return 'maybe-eligible';
            }
            else {
                return 'not-eligible';
            }
        }
        return 'not-eligible';
    };
    useEffect(() => {
        var _a;
        if (((_a = formResponse.pages) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            setCurrentPageIndex(0);
            setState(FormStates.InquiryForm);
        }
    }, [formResponse]);
    const handleFinish = async () => {
        var _a;
        try {
            const submission = {
                form_submission_id: '',
                form_crm_id: formResponse.crm_id,
                form_id: formResponse.form_id,
                source,
                page_name,
                eligibility_state: determinedEligibilityState(),
            };
            const submissionItems = [];
            (_a = Object.entries(formData)) === null || _a === void 0 ? void 0 : _a.map(([key, value]) => {
                var _a;
                const item = mappedItems[key];
                value = (item === null || item === void 0 ? void 0 : item.field_type) === 'checkbox' ? value.join(',') : value;
                if (value != undefined) {
                    submissionItems.push({
                        form_submission_item_id: '',
                        form_submission_id: '',
                        form_item_id: key,
                        value,
                        form_item_title: (_a = item.content) !== null && _a !== void 0 ? _a : '',
                    });
                }
            });
            const assetSubmissions = [];
            if (formAssetList.length > 0) {
                formAssetList.forEach((formAsset) => {
                    formAsset.assets.forEach((file) => {
                        submissionItems.push({
                            form_submission_item_id: '',
                            form_submission_id: '',
                            form_item_id: formAsset.form_item_id,
                            value: formAsset.form_item_label,
                            form_item_title: formAsset.form_item_label,
                        });
                        assetSubmissions.push({
                            values: {
                                file_name: file.name,
                                file_description: formAsset.form_item_label,
                                record_id: formAsset.form_item_id,
                                asset_id: '',
                                version: '',
                                record_type: 'form_submission_item',
                            },
                            asset: file,
                        });
                    });
                });
            }
            const { email, phone, first_name, last_name, province, company_name } = contactData;
            const editedContact = {
                email: email !== null && email !== void 0 ? email : contact === null || contact === void 0 ? void 0 : contact.email,
                phone: phone !== null && phone !== void 0 ? phone : contact === null || contact === void 0 ? void 0 : contact.phone,
                company_name: company_name !== null && company_name !== void 0 ? company_name : '',
                first_name: first_name !== null && first_name !== void 0 ? first_name : contact === null || contact === void 0 ? void 0 : contact.name,
                last_name: last_name !== null && last_name !== void 0 ? last_name : '',
                province: province !== null && province !== void 0 ? province : '',
                preferred_language: selectedLocale,
            };
            await handleSave({
                submission,
                submissionItems,
                contact: editedContact,
                uploadAssets: assetSubmissions,
            });
            setHasFinished(false);
        }
        catch (error) {
            console.error(error);
        }
    };
    if (!((_b = formResponse.pages) === null || _b === void 0 ? void 0 : _b.length))
        return _jsx(_Fragment, {});
    const lastPageIndex = ((_c = formResponse === null || formResponse === void 0 ? void 0 : formResponse.pages) === null || _c === void 0 ? void 0 : _c.length) - 1;
    const firstPageIndex = useMemo(() => {
        return formResponse.pages.findIndex((page) => { var _a; return !((_a = page.page_type) === null || _a === void 0 ? void 0 : _a.includes('opening')); });
    }, [formResponse.pages]);
    return (_jsxs(Div, { flex: "column", gap: 24, align: "center", style: {
            maxWidth: !['modal'].includes(type) ? theme.caps.normal : undefined,
            width: '100%',
        }, tablet: {
            style: {
                maxWidth: 'auto',
            },
        }, children: [_jsx(FormHeader, { formResponse: formResponse, type: type, isEndPage: isEndPage, isOpening: isOpening, progress: progress }), _jsx(Div, { flex: "column", gap: 32, backgroundColor: ['modal'].includes(type)
                    ? theme.colors.beige_3
                    : !isOpening && !isEndPage
                        ? theme.colors.beige_3
                        : undefined, borderColor: ['page', 'preview'].includes(type) && !isOpening && !isEndPage
                    ? theme.colors.primary
                    : undefined, borderRadius: 4, style: {
                    padding: ['modal'].includes(type) ? '54px 128px' : 24,
                    position: 'relative',
                    overflow: 'hidden',
                }, tablet: {
                    style: {
                        padding: isEndPage || isOpening ? '0 24px' : 24,
                        paddingTop: isEndPage || isOpening ? 0 : 24,
                        overflow: 'visible',
                    },
                    bordersSide: 'all',
                    borderRadius: 0,
                    borderColor: (isEndPage || isOpening) && !['modal'].includes(type)
                        ? 'transparent'
                        : theme.colors.grey_1,
                }, children: state === FormStates.Loading ? (_jsx(FormLoading, { onLoadingFinished: handleLoadingState })) : (_jsx(_Fragment, { children: _jsxs(Div, { flex: "column", gap: 32, children: [state === FormStates.ContactForm ? (_jsx(ContactForm, { onContactChange: onContactChange, handleNext: handleNext, initialValues: contactData, setForms: setForms })) : (_jsx(FormCurrentPage, { formData: formData, formResponse: formResponse, currentPage: currentPage, handleClose: handleClose, handleNext: handleNext, setForms: setForms, isEndPage: isEndPage, isOpening: isOpening, viewerType: type, formAssetList: formAssetList, setFormAssetList: setFormAssetList })), _jsx(FormCurrentPageEndFooter, { isEndPage: isEndPage, isOpening: isOpening, isContact: isContact, handlePrevious: handlePrevious, currentPageIndex: currentPageIndex, lastPageIndex: lastPageIndex, firstPageIndex: firstPageIndex, handleNext: handleNext })] }) })) })] }));
};
export default FormViewer;
