import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import Div from '../../common/div';
import FormSubmissionEditor from './form-submission-editor';
import getMappedSubmissionItems from './form-submission-editor/utils/get-mapped-submission-items';
import FormSubmissionFieldsList from './form-submission-fields-list';
import FormSubmissionHeader from './form-submission-header';
const FormSumbissionViewer = (props) => {
    const [form] = useForm();
    const [mode, setMode] = useState('table');
    const handleMode = () => {
        setMode((prevMode) => (prevMode === 'table' ? 'edit' : 'table'));
    };
    const { formSubmission, handleSave, source } = props;
    const mappedSubmissionItems = getMappedSubmissionItems({ formSubmission });
    const [hasChanged, setHasChanged] = useState(false);
    const handleChange = () => {
        setHasChanged(true);
    };
    const onFinish = () => {
        const { submission } = formSubmission;
        try {
            form.validateFields().then(async (values) => {
                const submissionItems = Object.keys(values).map((form_item_id) => {
                    const submissionItem = Object.assign(Object.assign({}, mappedSubmissionItems[form_item_id].forms_submissions_item), { form_item_id, form_submission_id: submission === null || submission === void 0 ? void 0 : submission.form_submission_id, value: values[form_item_id] });
                    return submissionItem;
                });
                await handleSave(submissionItems);
                setHasChanged(false);
            });
        }
        catch (error) {
            console.error(error);
        }
    };
    return (_jsxs(Div, { flex: "column", gap: 24, children: [_jsx(FormSubmissionHeader, { formSubmission: props.formSubmission, mode: mode, handleMode: handleMode, hasChanged: hasChanged, onFinish: onFinish }), mode === 'table' ? (_jsx(FormSubmissionFieldsList, { formSubmission: formSubmission })) : (_jsx(FormSubmissionEditor, { formSubmission: formSubmission, handleChange: handleChange, mappedSubmissionItems: mappedSubmissionItems, form: form, source: source }))] }));
};
export default FormSumbissionViewer;
