import Button from '@hellodarwin/core/lib/components/common/button';
import Loading from '@hellodarwin/core/lib/components/loading';
import useGamePlanSections from '@hellodarwin/core/lib/components/projects/game-plan-pdf/hooks/use-game-plan-sections';
import useGamePlanTranslations from '@hellodarwin/core/lib/components/projects/game-plan-pdf/hooks/use-game-plan-translations';
import {
  Company,
  CompanyGamePlanResponse,
} from '@hellodarwin/core/lib/features/entities/companies-entities';
import { getStringDate } from '@hellodarwin/core/lib/features/helpers/get-formatted-date';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Download from '@hellodarwin/icons/dist/icons/Download';
import Upload from '@hellodarwin/icons/dist/icons/Upload';
import GamePlanPdf from '@hellodarwin/pdf/lib/builders/game-plan';
import DarwinPdfViewer from '@hellodarwin/pdf/lib/viewer';
import { notification } from 'antd';
import Tooltip from 'antd/es/tooltip';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/app-hooks';
import {
  fetchAdminById,
  selectAdminById,
} from '../../../features/api/slices/admins-slice';
import {
  fetchCompanyGamePlanSummary,
  generateGamePlan,
  selectCompanyGamePlanSummary,
} from '../../../features/api/slices/gameplan-slice';
import {
  fetchAllCompanyProjects,
  selectAllProjects,
} from '../../../features/api/slices/projects-slice';
import { selectNaicsCodes } from '../../../features/api/slices/tags-slice';
import {
  useAdminApi,
  useNewAdminApi,
} from '../../../features/api/use-admin-api';
import GamePlanMissingDataWrapper from './missing-data-wrapper';

export interface GamePlanViewerProps {
  company: Company;
}

const GamePlanViewer: React.FC<GamePlanViewerProps> = ({ company }) => {
  const theme = useTheme();
  const { t } = useTranslations();

  const { selectedLocale: locale } = useLocale();
  const oldApi = useAdminApi();
  const api = useNewAdminApi();
  const dispatch = useAppDispatch();

  const projects = useAppSelector(selectAllProjects);
  const naicsCodes = useAppSelector(selectNaicsCodes);
  const admin = useAppSelector((state) =>
    selectAdminById(state, company?.account_manager ?? ''),
  );
  const summary = useAppSelector(selectCompanyGamePlanSummary);
  const companiesStateStatus = useAppSelector(
    (state) => state.companies.status,
  );
  const adminsStateStatus = useAppSelector((state) => state.admins.status);

  useEffect(() => {
    dispatch(
      fetchAllCompanyProjects({
        api,
        companyId: company.company_id,
        locale,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.company_id, locale]);

  useEffect(() => {
    if (!!company.account_manager) {
      dispatch(
        fetchAdminById({ adminId: company.account_manager, api: oldApi }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.account_manager]);

  useEffect(() => {
    dispatch(
      fetchCompanyGamePlanSummary({
        api: api,
        companyId: company.company_id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.company_id]);

  const mappedNaicsCodes = useMemo(
    () =>
      naicsCodes
        ? naicsCodes.reduce(
            (o, i) => ({ ...o, [i.value]: i.label }),
            {} as { [value: string]: string },
          )
        : {},
    [naicsCodes],
  );

  const translations = useGamePlanTranslations();
  const sections = useGamePlanSections({
    projects: projects ?? [],
    company,
    translations,
    grants: company.grants ?? [],
    ...(summary != null && { summary }),
  });

  const creationDate = useMemo(() => new Date(), []);
  const fileName = useMemo(
    () =>
      `${translations.introTitle} - ${company.name} - ${getStringDate(new Date(), locale)}`,
    [translations, company, locale],
  );
  const handleDownload = async () => {
    oldApi.downloadGamePlan(company.company_id);
  };
  const handleUpload = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    const gamePlan: CompanyGamePlanResponse = {
      game_plan_id: '',
      company_id: company.company_id,
      account_manager: company.account_manager ?? admin.admin_id,
      file_name: `${translations.introTitle} - ${company.name}`,
      file_description: t('gamePlanPdf|gamePlanFileDescription'),
      locale: locale,
    };

    formData.append('request', JSON.stringify(gamePlan));

    const isSuccess =
      (
        await dispatch(
          generateGamePlan({
            api,
            formData,
          }),
        )
      ).meta.requestStatus === 'fulfilled';

    if (isSuccess) {
      notification.success({
        message: t('gamePlanPdf|successGenerateGamePlan'),
        placement: 'topLeft',
        duration: 0,
      });
    }
    return isSuccess;
  };

  if (companiesStateStatus === 'pending' || adminsStateStatus === 'pending')
    return <Loading size="extra-large"></Loading>;
  return (
    <GamePlanMissingDataWrapper
      admin={admin}
      translations={translations}
      sections={sections}
      company={company}
      projects={projects}
    >
      <div
        style={{
          height: `calc(100vh - ${theme.appearance.appHeaderHeight}px - 16px - 16px - 32px )`,
          width: '100%',
        }}
      >
        <DarwinPdfViewer
          fileName={fileName}
          document={
            <GamePlanPdf
              locale={locale}
              company={{
                ...company,
                naics_code: company.naics_code?.map(
                  (code) => mappedNaicsCodes[code],
                ),
              }}
              projects={projects}
              sections={sections}
              translations={translations}
              admin={admin}
              title={`${translations.introTitle} - ${company.name}`}
              creationDate={creationDate}
              warning={{
                label: t('gamePlanPdf|helloDarwinWarningTitle'),
                children: [
                  t('gamePlanPdf|helloDarwinWarningDescription1'),
                  t('gamePlanPdf|helloDarwinWarningDescription2'),
                  t('gamePlanPdf|helloDarwinWarningDescription3'),
                  t('gamePlanPdf|helloDarwinWarningDescription4'),
                ],
              }}
            />
          }
          downloadCta={
            <Tooltip title={t('gamePlanPdf|download')}>
              <Button
                defaultStyle={theme.colors.grey_1}
                headingIcon={<Download size={24} />}
                size="square"
              />
            </Tooltip>
          }
          handleDownload={handleDownload}
          uploadCta={
            <Tooltip title={t('gamePlanPdf|uploadToClient')}>
              <Button
                defaultStyle={theme.colors.grey_1}
                headingIcon={<Upload size={24} />}
                size="square"
              />
            </Tooltip>
          }
          handleUpload={handleUpload}
        />
      </div>
    </GamePlanMissingDataWrapper>
  );
};

export default GamePlanViewer;
