var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Divider from 'antd/es/divider';
import { Collapse as ReactCollapse } from 'react-collapse';
import { useTheme } from 'styled-components';
import NavigationMenuLabel from './menu-item-label';
import { SubitemsDropdownStyled, SubitemsDropdownWrapperStyled, } from './styles';
import SubNavigationMenuItemsList from './subitems-list';
const SubitemsListDropdown = (_a) => {
    var { isActive, app, handleDropdownMouseLeave, isDropdown, isCollapsed, position = 'right', size = 'medium', style, parentItem } = _a, props = __rest(_a, ["isActive", "app", "handleDropdownMouseLeave", "isDropdown", "isCollapsed", "position", "size", "style", "parentItem"]);
    const theme = useTheme();
    return isDropdown ? (_jsxs(SubitemsDropdownWrapperStyled, { "$isActive": isActive, "$position": position, onMouseLeave: handleDropdownMouseLeave, children: [_jsxs(SubitemsDropdownStyled, { "$app": app, "$size": size, style: style, children: [!!parentItem && (_jsxs("div", { style: { marginTop: 4 }, children: [_jsx(NavigationMenuLabel, Object.assign({}, parentItem, { app: app, noStyle: true })), _jsx(Divider, { style: { margin: '4px 0', borderColor: theme.colors.primary } })] })), _jsx(SubNavigationMenuItemsList, Object.assign({}, props, { app: app, size: size, isCollapsed: isCollapsed }))] }), ' '] })) : (_jsx(ReactCollapse, { isOpened: !!isActive, children: _jsx(SubNavigationMenuItemsList, Object.assign({}, props, { app: app, size: size, isCollapsed: isCollapsed })) }));
};
export default SubitemsListDropdown;
