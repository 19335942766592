import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Empty } from 'antd';
import Progress from 'antd/es/progress/progress';
import { useEffect, useState } from 'react';
import useMediaQuery from '../../../../features/helpers/use-media-query';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import Loading from '../../../loading';
import { GrantSingleContainer } from '../styles';
import GrantStepSingle from './single';
const GrantSteps = ({ grantSteps, onChangeStepCheckBox, canStepsCollapse, }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    const [activeStepId, setActiveStepId] = useState('');
    const [previousStepId, setPreviousStepId] = useState('');
    const [progression, setProgression] = useState(0);
    const isMobile = useMediaQuery({ maxWidth: theme.breakpoints.mobile });
    useEffect(() => {
        if (!!grantSteps) {
            var isActiveStepFound = false;
            for (let i = 0; i < grantSteps.length; i++) {
                if (!!!grantSteps[i].validated_at) {
                    setActiveStepId(grantSteps[i].grant_step_id);
                    isActiveStepFound = true;
                    var progress = Math.round((i / grantSteps.length) * 100);
                    setProgression(progress);
                    if (i > 0) {
                        setPreviousStepId(grantSteps[i - 1].grant_step_id);
                    }
                    break;
                }
            }
            if (!isActiveStepFound) {
                setActiveStepId('');
                setProgression(100);
                !!grantSteps[grantSteps.length - 1] &&
                    setPreviousStepId(grantSteps[grantSteps.length - 1].grant_step_id);
            }
        }
    }, [grantSteps]);
    if (!grantSteps) {
        return _jsx(Loading, {});
    }
    if (grantSteps.length == 0) {
        return _jsx(Empty, { description: t('singleCompanyPage|noStepsYet') });
    }
    return (_jsxs(GrantSingleContainer, { flex: "column", gap: 40, style: { padding: isMobile ? '8px' : '24px' }, children: [!canStepsCollapse && !!onChangeStepCheckBox && (_jsxs(Div, { flex: "row", justify: "space-between", align: "center", children: [_jsx(Typography.Title, { level: 5, children: t('gin_section|steps') }), _jsx(Progress, { percent: progression, showInfo: true, strokeColor: theme.colors.green_high_contrast, trailColor: theme.colors.green_3, style: { width: isMobile ? '200px' : '300px' } })] })), _jsx(Div, { flex: "column", gap: canStepsCollapse ? 8 : 24, style: canStepsCollapse
                    ? { maxWidth: '100%' }
                    : {
                        maxWidth: '100%',
                        padding: '24px',
                        backgroundColor: theme.colors.white_1,
                        border: `2px solid ${theme.colors.primary}`,
                        borderRadius: '4px',
                    }, children: grantSteps.map((step, index) => (_jsx(GrantStepSingle, { step: step, isCompleted: !!step.validated_at, isActive: !!onChangeStepCheckBox
                        ? step.grant_step_id === activeStepId
                        : false, isMobile: isMobile, isImmediatePreviousStep: step.grant_step_id === previousStepId, onChangeStepCheckBox: onChangeStepCheckBox, canStepsCollapse: canStepsCollapse }, index))) })] }));
};
export default GrantSteps;
