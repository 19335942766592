import FormItemEditList from '@hellodarwin/core/lib/components/forms/form-builder/controllers/group/form-edit-list';
import { GrantStep } from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Drawer from 'antd/es/drawer';
import Typography from 'antd/es/typography/Typography';
import { useState } from 'react';

export interface GrantFormStepsDrawerProps {
  open: boolean;
  handleCancel: () => void;
  grantSteps: GrantStep[];
  onDeleteGrantStep: (grantStepId: string) => void;
  onReorderGrantSteps: (reorderedItems: GrantStep[]) => Promise<void>;
}

const GrantFormStepsDrawer = ({
  open,
  handleCancel,
  grantSteps,
  onDeleteGrantStep,
  onReorderGrantSteps,
}: GrantFormStepsDrawerProps) => {
  const { t } = useTranslations();
  const [isReorderingLoading, setIsReorderingLoading] = useState(false);

  const handleDeleteGrantStep = (grantStep: GrantStep) => {
    onDeleteGrantStep(grantStep.grant_step_id);
  };

  const handleReorderCompanyGrants = async (reorderedItems: GrantStep[]) => {
    setIsReorderingLoading(true);
    await onReorderGrantSteps(reorderedItems);
    setIsReorderingLoading(false);
  };

  const drawerHeader = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography>{t('grant_form|reorder_grant_steps')}</Typography>
    </div>
  );

  return (
    <>
      <Drawer
        open={open}
        onClose={handleCancel}
        size="large"
        width={500}
        title={drawerHeader}
        styles={{
          body: {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            alignItems: 'stretch',
          },
        }}
      >
        <FormItemEditList<GrantStep>
          options={grantSteps}
          optionLabel="title_fr"
          deleteItem={handleDeleteGrantStep}
          reorder={handleReorderCompanyGrants}
          inputs={[]}
          isLoading={isReorderingLoading}
        />
      </Drawer>
    </>
  );
};

export default GrantFormStepsDrawer;
