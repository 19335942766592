import parse from '../../../../features/helpers/parse';
const getItemTranslatedLabel = ({ label_en, label_fr, locale, fallback, parseOptions, }) => {
    if (locale === 'en' && !!(label_en === null || label_en === void 0 ? void 0 : label_en.length)) {
        return !!parseOptions ? parse(label_en, parseOptions) : label_en;
    }
    else if (locale === 'fr' && !!(label_fr === null || label_fr === void 0 ? void 0 : label_fr.length)) {
        return !!parseOptions ? parse(label_fr, parseOptions) : label_fr;
    }
    else {
        return fallback;
    }
};
export default getItemTranslatedLabel;
