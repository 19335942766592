import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import Drawer from 'antd/es/drawer';
import Typography from '../../../common/typography';
import AssetDrawerInfos from './asset-viewer-infos';
const AssetViewerDrawer = ({ open, onClose, meta, }) => {
    if (!meta)
        return _jsx(_Fragment, {});
    const { name } = meta;
    return (_jsx(Drawer, { open: open, onClose: onClose, styles: {
            header: { overflow: 'hidden', flex: '0 0 auto', padding: '8px 24px' },
        }, title: _jsx(Typography, { lineClamp: 1, elementTheme: "subtitle1", style: { width: '100%' }, children: name }), children: _jsx(AssetDrawerInfos, Object.assign({}, meta)) }));
};
export default AssetViewerDrawer;
