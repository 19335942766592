export const flattenTranslations = (arr) => {
    let flatObject = {};
    for (const key in arr) {
        if (Object.prototype.hasOwnProperty.call(arr, key)) {
            const obj = arr[key];
            for (const sectionName in obj) {
                const section = obj[sectionName];
                for (const translationKey in section) {
                    const translation = section[translationKey];
                    if (Array.isArray(translation)) {
                        flatObject = Object.assign(Object.assign({}, flatObject), flattenTranslations(translation));
                    }
                    else {
                        flatObject = Object.assign(Object.assign({}, flatObject), { [`${sectionName}|${translationKey}`]: translation });
                    }
                }
            }
        }
    }
    return flatObject;
};
export const addParamToTranslation = (str, params) => {
    if (!params || Object.keys(params).length === 0)
        return str;
    const regex = new RegExp(`{{(${Object.keys(params).join('|')})}}`, 'g');
    return str.replace(regex, (_, key) => `${params[key]}` || '');
};
export const formatKey = (key) => key.replaceAll('-', '_');
export const processTranslationFiles = (files) => files.reduce((acc, file) => {
    var _a, _b, _c, _d;
    const formattedKey = formatKey(file.key);
    const subFiles = (_b = (_a = file.translationFileCollection) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : [];
    const fields = (_c = file.translationsFieldsCollection) === null || _c === void 0 ? void 0 : _c.items.reduce((fieldsAcc, { key, value }) => (Object.assign(Object.assign({}, fieldsAcc), { [formatKey(key)]: value })), {});
    return Object.assign(Object.assign({}, acc), { [formattedKey]: Object.assign(Object.assign(Object.assign({}, ((_d = acc[formattedKey]) !== null && _d !== void 0 ? _d : {})), processTranslationFiles(subFiles)), fields) });
}, {});
