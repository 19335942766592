const insideClientTranslations = [
    'business_priority',
    'clientDashboard',
    'common',
    'emptyProject',
    'faq',
    'grants',
    'profile',
    'program_consulting',
    'program_preview_card',
    'programs',
    'project',
    'project-single',
    'propulsion',
    'provider-profile',
    'recent_roadmap_program',
    'resource',
    'rfp',
    'projectStatus',
    'programs_labels',
    'article',
    'funding_explorer',
    'singleCompanyPage',
];
const insidePartnerTranslations = [
    'rfp',
    'common',
    'modal',
    'payment',
    'button',
    'profile',
    'navigation',
    'notification',
    'provider-profile',
    'expertise',
    'onboarding',
    'referral',
    'faq',
    'reviews',
];
const insideAdminTranslations = [
    'companyForm',
    'createApplication',
    'project',
    'companyAdminPage',
    'singleCompanyPage',
    'company',
    'forms_list',
    'formSubmission',
    'button',
    'application_single',
    'grant_single',
    'programs_admin',
    'createCompany',
    'rfp',
    'provider_details',
    'form',
    'gamePlanPdf',
    'create_program',
    'grant_validation',
    'provider_form',
    'providerStatus',
    'provider_page',
    'createRfp',
    'rfps_admin',
    'headerBar',
    'searchBarAdmin',
    'reviewsAdmin',
    'application_list',
    'admin_settings',
    'gin_history',
    'files_gin',
    'gin_section',
    'gin_steps',
    'assets',
    'gin_program',
    'mandated_agents',
    'available_rfps',
    'referred_rfps',
    'refused_rfps',
    'reviews',
    'programs_labels',
    'grant_form',
];
export const CoreTranslations = [
    'assets',
    'profile',
    'button',
    'company',
    'review_card',
    'grant_card',
    'gamePlanPdf',
    'actions',
    'grant_single',
    'grant_single_labels',
    'files_gin',
    'assets_upload',
    'gin_section',
    'provider-profile',
    'navigation',
    'rfp',
    'language',
    'portfolio',
    'reviews',
    'adminCard',
    'common',
    'footer',
    'commonComponents',
    'errorsPage',
    'admin_settings',
    'appsNames',
    'funding_explorer',
    'grant_card_status',
    'projectStatus',
    'programs',
    'companyForm',
    'form_viewer',
    'province',
    'portfolioAssetsModal',
    'profile_portfolio',
    'grants',
    'grant_eligibility_criteria',
    'programs_label',
    'application_single',
    'application',
    'project',
    'form',
    'profile_sections',
    'profile_header',
    'profile_about',
    'profile_video',
    'profile_contact',
    'profile_banners',
    'profile_expertise',
    'profile_overview',
    'profile_reviews',
    'profile_team',
    'profile_projects',
    'review_contact',
    'adminCard',
    'projectStatusFlow',
    'grant_notification',
    'singleCompanyPage',
    'annualRevenue',
    'companySize',
    'grantsFundingExplorer',
];
export const PartnerTranslations = Array.from(new Set([...insidePartnerTranslations, ...CoreTranslations]));
export const AdminTranslations = Array.from(new Set([...insideAdminTranslations, ...CoreTranslations]));
export const ClientTranslations = Array.from(new Set([...insideClientTranslations, ...CoreTranslations]));
