import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import {
  AdminGrantResult,
  GrantProject,
} from '@hellodarwin/core/lib/features/entities';
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import InputNumber from 'antd/es/input-number';
import message from 'antd/es/message';
import Popconfirm from 'antd/es/popconfirm';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../../app';
import {
  createGrantProject,
  deleteAllGrantProjects,
  deleteGrantProject,
  generateGrantProjects,
  selectIsAllDirty,
} from '../../../features/api/slices/grant-projects-slice';
import {
  fetchAllPromptsForGrants,
  selectAllPrompts,
} from '../../../features/api/slices/hd-chat-prompts-slice';
import {
  useAdminApi,
  useNewAdminApi,
} from '../../../features/api/use-admin-api';
import CreateUpdatePromptDrawer from '../../hd-chat/create-update-prompt-drawer';
import GrantFormProject from './grant-form-project';

type GrantFormSectionsProps = {
  grant?: AdminGrantResult;
  form: any;
  isSavingProjects?: boolean;
  setIsSavingProjects?: Dispatch<SetStateAction<boolean>>;
  isGeneratingAll?: boolean;
};

const GrantFormProjects = ({
  grant,
  form,
  isSavingProjects,
  setIsSavingProjects,
  isGeneratingAll,
}: GrantFormSectionsProps) => {
  const newApi = useNewAdminApi();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const [isGrantProjectsGenerating, setIsGrantProjectsGenerating] =
    useState(false);
  const [grantProjectsEn, setGrantProjectsEn] = useState<GrantProject[]>(
    grant?.grant_projects_en ?? [],
  );
  const [grantProjectsFr, setGrantProjectsFr] = useState<GrantProject[]>(
    grant?.grant_projects_fr ?? [],
  );
  const prompts = useAppSelector(selectAllPrompts);
  const [saveAllFlag, setSaveAllFlag] = useState(false);
  const [isDeletingAll, setIsDeletingAll] = useState(false);
  const [promptDrawerVisible, setPromptDrawerVisible] = useState(false);
  const isAllDirty = useAppSelector((state: RootState) =>
    selectIsAllDirty(state),
  );

  useEffect(() => {
    form.setFieldValue('project-count', 6);
    dispatch(fetchAllPromptsForGrants({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSavingProjects && isAllDirty) {
      setSaveAllFlag(true);
    }
    setIsSavingProjects && setIsSavingProjects(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSavingProjects]);

  useEffect(() => {
    if (
      isGeneratingAll &&
      grantProjectsEn.length === 0 &&
      grantProjectsFr.length === 0
    ) {
      generateProjects();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGeneratingAll]);

  useEffect(
    () => {
      if (!isAllDirty && saveAllFlag) {
        setSaveAllFlag(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAllDirty],
  );

  const generateProjects = async () => {
    setIsGrantProjectsGenerating(true);
    const count = form.getFieldValue('project-count');
    const grantId = grant?.grant_id!;
    try {
      const grantProjects = await dispatch(
        generateGrantProjects({
          api: newApi,
          grantId,
          count,
        }),
      ).unwrap();
      const enProjects = grantProjects.filter(
        (project) => project.locale === 'en',
      );
      const frProjects = grantProjects.filter(
        (project) => project.locale === 'fr',
      );
      setGrantProjectsEn([...enProjects, ...grantProjectsEn]);
      setGrantProjectsFr([...frProjects, ...grantProjectsFr]);

      message.success("Projects generated successfully. Don't forget to save!");
    } catch (e: any) {
      console.error(e);
      message.error('Failed to generate projects');
    } finally {
      setIsGrantProjectsGenerating(false);
    }
  };

  const saveAllProjects = async () => {
    setSaveAllFlag(!saveAllFlag);
  };

  const deleteAllProjects = async () => {
    try {
      if (
        (!grant || !grant.grant_projects) &&
        grantProjectsEn.length === 0 &&
        grantProjectsFr.length === 0
      ) {
        message.error('No projects to delete');
        return;
      }
      const grantProjectIds = [
        ...grantProjectsEn
          .filter((project) => project.grant_project_id !== '')
          .map((project) => project.grant_project_id),
        ...grantProjectsFr
          .filter((project) => project.grant_project_id !== '')
          .map((project) => project.grant_project_id),
      ];
      setIsDeletingAll(true);
      if (grantProjectIds.length > 0) {
        await dispatch(
          deleteAllGrantProjects({ api: newApi, grantProjectIds }),
        );
      }
      setGrantProjectsEn([]);
      setGrantProjectsFr([]);
      // dispatch(resetGrantProjectsDirtyMap());
      message.success('Projects deleted successfully');
    } catch (e: any) {
      console.error(e);
      message.error('Failed to delete projects');
    }
    setIsDeletingAll(false);
  };

  const handleUpdateGrantProject = (
    locale: string,
    index: number,
    newGrantProject: GrantProject,
  ) => {
    let projects: GrantProject[];
    let setGrantProjects: Dispatch<SetStateAction<GrantProject[]>>;

    if (locale === 'en') {
      projects = grantProjectsEn;
      setGrantProjects = setGrantProjectsEn;
    } else {
      projects = grantProjectsFr;
      setGrantProjects = setGrantProjectsFr;
    }

    const newGrantProjects = projects.map((grantProject, i) => {
      if (i === index) {
        return newGrantProject;
      }
      return grantProject;
    });

    setGrantProjects(newGrantProjects);
  };

  const handleDeleteGrantProject = async (
    grant_project_id: string,
    grant_locale: string,
  ) => {
    if (grant_locale === 'fr') {
      const newGrantsProjectsFr = grantProjectsFr.filter(
        (project, _) => project.grant_project_id !== grant_project_id,
      );
      setGrantProjectsFr(newGrantsProjectsFr);
    } else {
      const newGrantsProjectsEn = grantProjectsEn.filter(
        (project, _) => project.grant_project_id !== grant_project_id,
      );
      setGrantProjectsEn(newGrantsProjectsEn);
    }

    if (grant_project_id !== '') {
      try {
        await dispatch(
          deleteGrantProject({
            api: newApi,
            grantProjectId: grant_project_id,
            grantProjectLocale: grant_locale,
          }),
        );
        message.success('Project deleted successfully');
      } catch (e: any) {
        console.error(e);
        message.error('Failed to delete project');
      }
    }
  };

  const displayGrantProject = (projects: GrantProject[], locale: string) => {
    return projects.map((grantProject, index) => (
      <GrantFormProject
        key={index}
        grantProject={grantProject}
        index={index}
        locale={locale}
        handleUpdateGrantProject={handleUpdateGrantProject}
        handleDeleteGrantProject={() =>
          handleDeleteGrantProject(
            grantProject.grant_project_id,
            grantProject.locale,
          )
        }
        saveFlag={saveAllFlag}
      />
    ));
  };

  const handleAddGrantProject = async (locale: string) => {
    try {
      var newGrantProject: GrantProject = {
        grant_project_id: '',
        grant_id: grant?.grant_id!,
        locale: locale,
        short_description: '',
        long_description: '',
        location: '',
        project_budget: 0,
        amount_funded: 0,
        grant_project_tags: [],
      };
      const newReceivedGrant = await dispatch(
        createGrantProject({ api: newApi, grantProject: newGrantProject }),
      ).unwrap();
      if (locale === 'en') {
        const newGrantProjects = [...grantProjectsEn, newReceivedGrant];
        setGrantProjectsEn(newGrantProjects);
      } else {
        const newGrantProjects = [...grantProjectsFr, newReceivedGrant];
        setGrantProjectsFr(newGrantProjects);
      }
      message.success('Project added successfully');
    } catch (e: any) {
      console.error(e);
      message.error('Failed to add project');
    }
  };

  return (
    <>
      <Div flex="column" gap={16}>
        <Div flex="row" gap={8}>
          <Div flex="row" justify={'between'} gap={8}>
            <Typography.Paragraph>Generate</Typography.Paragraph>
            <Form.Item name="project-count" style={{ margin: 0 }}>
              <InputNumber
                defaultValue={6}
                min={1}
                style={{ width: 50 }}
                onChange={(value) => form.setFieldValue('project-count', value)}
              />
            </Form.Item>
            <Typography.Paragraph>Project(s)</Typography.Paragraph>
            <Button
              type="dashed"
              loading={isGrantProjectsGenerating}
              disabled={isGrantProjectsGenerating}
              onClick={generateProjects}
            >
              Generate
            </Button>
            <Button
              loading={saveAllFlag}
              disabled={saveAllFlag || !isAllDirty}
              onClick={saveAllProjects}
            >
              Save all
            </Button>
            <Popconfirm
              title="Delete this grant project"
              description="Are you sure to delete this grant project ?"
              onConfirm={deleteAllProjects}
              okText="Delete"
              cancelText="No"
            >
              <Button
                type="primary"
                loading={isDeletingAll}
                disabled={isDeletingAll || grantProjectsEn.length === 0}
                danger={true}
                icon={<Delete width={14} height={14} />}
              >
                Delete all
              </Button>
            </Popconfirm>
          </Div>
          <Button onClick={() => setPromptDrawerVisible(true)}>
            Edit prompt
          </Button>
        </Div>
        <Div flex="row" align="space-between">
          <Div flex="column" gap={16} style={{ maxWidth: '50%' }}>
            <Typography.Title level={5}>Grant Project EN</Typography.Title>
            <Form.Item name={'grant_projects_en'}>
              {displayGrantProject(grantProjectsEn, 'en')}
              <Button
                onClick={() => handleAddGrantProject('en')}
                type="text"
                size="middle"
                icon={<AddNew size={24} />}
              />
            </Form.Item>
          </Div>
          <Div
            flex="column"
            gap={16}
            style={{ marginLeft: 16, maxWidth: '50%' }}
          >
            <Typography.Title level={5}>Grant Project FR</Typography.Title>
            <Form.Item name={'grant_projects_fr'}>
              {displayGrantProject(grantProjectsFr, 'fr')}
              <Button
                onClick={() => handleAddGrantProject('fr')}
                type="text"
                size="middle"
                icon={<AddNew size={24} />}
              />
            </Form.Item>
          </Div>
        </Div>
      </Div>
      {prompts && (
        <CreateUpdatePromptDrawer
          visible={promptDrawerVisible}
          onClose={() => setPromptDrawerVisible(false)}
          prompt={prompts.find((prompt) => prompt.name === 'project')!}
        />
      )}
    </>
  );
};

export default GrantFormProjects;
