import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Divider from 'antd/es/divider';
import Form from 'antd/es/form';
import React, { useEffect, useState } from 'react';
import theme from '../../../../theme';
import Container from '../../../common/container';
import Div from '../../../common/div';
import FormSubmissionEditorPage from './layouts/form-submission-page';
import getFormSubmissionData from './utils/get-form-submission-data';
import getSubmissionActivePages from './utils/get-submission-active-pages';
const FormSubmissionEditor = ({ formSubmission, handleChange, mappedSubmissionItems, form, source, }) => {
    const initialFormData = getFormSubmissionData({ formSubmission });
    const [formData, setFormData] = useState(initialFormData);
    useEffect(() => {
        setFormData(initialFormData);
        form.setFieldsValue(initialFormData);
    }, [initialFormData, form]);
    const activePages = getSubmissionActivePages({
        formSubmission,
        formData,
        mappedSubmissionItems,
    });
    const onFormChange = (changedFields) => {
        if (source === 'admin') {
            const newValues = changedFields.reduce((o, itm) => (Object.assign(Object.assign({}, o), { [itm.name]: itm.value })), {});
            setFormData((prevData) => (Object.assign(Object.assign({}, prevData), newValues)));
        }
        handleChange();
    };
    return (_jsx(Div, { flex: "column", gap: 24, children: _jsx(Container, { children: _jsx(Form, { initialValues: formData, form: form, onFieldsChange: onFormChange, children: React.Children.toArray(activePages === null || activePages === void 0 ? void 0 : activePages.map((page, i) => (_jsxs(_Fragment, { children: [_jsx(FormSubmissionEditorPage, { page: page }), i < activePages.length - 1 && (_jsx(Divider, { style: {
                                borderColor: theme.colors.grey_1,
                                margin: '24px 0',
                            } }))] })))) }) }) }));
};
export default FormSubmissionEditor;
