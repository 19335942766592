var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from 'styled-components';
import { useTranslations } from '../../../features/providers/translations-provider';
import Avatar from '../../common/avatar';
import Button from '../../common/button';
import Div from '../../common/div';
import { Tablet } from '../../common/Media';
import Typography from '../../common/typography';
import useDarwinLogoFallback from '../../provider/profile/hooks/use-darwin-picture-fallback';
import GrantNotification from '../single/grant-header/grant-notification';
import GrantCardStatusList from './grant-card-status-list';
import { GrantCardHeaderContainer, GrantCardHeaderInfos, GrantCardHeading, } from './styles';
export const grantLogoWidth = 112;
const GrantCardHeader = (_a) => {
    var _b;
    var { locationString, featuredTags, isAdmin, search_score, hideNotifications } = _a, grant = __rest(_a, ["locationString", "featuredTags", "isAdmin", "search_score", "hideNotifications"]);
    const { grant_display_title, grant_description_short, grant_logo } = grant;
    const theme = useTheme();
    const { t } = useTranslations();
    const fallback = useDarwinLogoFallback({
        type: 'grant-provider',
        size: grantLogoWidth,
    });
    return (_jsxs(GrantCardHeaderContainer, { children: [_jsx(Avatar, { src: grant_logo, size: grantLogoWidth, alt: `${grant_display_title}`, fallback: fallback, shape: "rounded-corner", children: (grant_display_title === null || grant_display_title === void 0 ? void 0 : grant_display_title[0]) || '' }), _jsxs(GrantCardHeaderInfos, { children: [_jsxs("div", { style: {
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }, children: [_jsx(GrantCardStatusList, { featuredTags: featuredTags !== null && featuredTags !== void 0 ? featuredTags : [], application_status: (_b = grant.application_status) !== null && _b !== void 0 ? _b : 'closed' }), _jsx(Tablet, { direction: "up", children: _jsx("div", { style: {
                                        height: 35,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingRight: '24px',
                                        textAlign: 'right',
                                        color: theme.colors.grey_2,
                                    }, className: "body2", children: locationString }) })] }), _jsx(Div, { flex: "column", gap: 8, children: _jsxs(GrantCardHeading, { children: [_jsx(Typography.Title, { level: 3, elementTheme: "h5", children: grant_display_title }), isAdmin && !!(search_score === null || search_score === void 0 ? void 0 : search_score.length) && (_jsx(Div, { flex: "column", align: "flex-end", children: _jsxs(Typography, { elementTheme: "body3", color: theme.colors.grey_2, ellipsis: true, children: ["Search score: ", search_score] }) })), _jsx(Tablet, { direction: "up", children: _jsx(Button, { withArrowRight: true, defaultStyle: theme.colors.purple_2, style: { marginRight: 24 }, children: t('grant_card|learnMore') }) })] }) }), !hideNotifications && _jsx(GrantNotification, { grant: grant }), _jsx("div", { className: "body2", style: { color: theme.colors.grey_2 }, children: grant_description_short })] })] }));
};
export default GrantCardHeader;
