import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Checkbox from 'antd/es/checkbox';
import DatePicker from 'antd/es/date-picker';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import TextArea from 'antd/es/input/TextArea';
import Radio from 'antd/es/radio';
import Select from 'antd/es/select';
import Space from 'antd/es/space';
import React, { useMemo } from 'react';
import useLocale from '../../../../../../features/providers/locale-provider';
import Div from '../../../../../common/div';
import { itemDescriptionOptions } from '../../../../form-builder/utils/form-parse-options';
import getItemTranslatedLabel from '../../../../form-builder/utils/get-item-translated-label';
import { getSelectOptions } from '../../../../form-builder/utils/get-select-options';
import FormLabel from '../../../../form-layouts/form-label';
const FormSubmissionInput = ({ editSubmissionItem: { form_item }, }) => {
    const { selectedLocale } = useLocale();
    const options = useMemo(() => form_item.form_select_options
        ? getSelectOptions(form_item.form_select_options, selectedLocale)
        : [], [form_item.form_select_options, selectedLocale]);
    return (_jsxs(Div, { flex: "row", justify: "space-between", children: [_jsx(FormLabel, { label: getItemTranslatedLabel({
                    label_en: form_item.content_en,
                    label_fr: form_item.content_fr,
                    fallback: '',
                    locale: selectedLocale,
                }), extra: getItemTranslatedLabel({
                    label_en: form_item.heading_en,
                    label_fr: form_item.heading_fr,
                    fallback: '',
                    parseOptions: itemDescriptionOptions,
                    locale: selectedLocale,
                }), style: { maxWidth: 600 } }), _jsx(Form.Item, { noStyle: true, name: form_item.form_item_id, rules: [
                    {
                        required: form_item.required === 'required',
                        message: 'This field is required',
                    },
                ], children: form_item.type === 'string' ? (form_item.field_type === 'text' ? (_jsx(TextArea, {})) : (_jsx(Input, {}))) : form_item.type === 'datetime' ? (form_item.field_type === 'date' ? (_jsx(DatePicker, { picker: "date" })) : (_jsx(DatePicker, { picker: "time" }))) : form_item.type === 'enum' ? (form_item.field_type === 'select' ? (_jsx(Select, { style: { width: 400, maxWidth: '100%' }, options: options })) : form_item.field_type === 'radio' ? (_jsx(Radio.Group, { children: _jsx(Space, { direction: form_item.disposition === 'vertical'
                            ? 'vertical'
                            : 'horizontal', children: React.Children.toArray(options.map((option) => (_jsx(Radio, { value: option.value, children: option.label })))) }) })) : form_item.field_type === 'checkbox' ? (_jsx(Checkbox.Group, { children: _jsx(Space, { direction: form_item.disposition === 'vertical'
                            ? 'vertical'
                            : 'horizontal', children: React.Children.toArray(options.map((option) => (_jsx(Checkbox, { value: option.value, children: option.label })))) }) })) : (_jsx(_Fragment, {}))) : form_item.type === 'file' ? (form_item.field_type === 'attachment' ? (_jsx(Input, { type: "file" })) : (_jsx(_Fragment, {}))) : (_jsx(_Fragment, {})) })] }));
};
export default FormSubmissionInput;
