import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import UploadIcon from '@hellodarwin/icons/dist/icons/Upload';
import Form from 'antd/es/form';
import notification from 'antd/es/notification';
import { useState } from 'react';
import { useTranslation } from '../../../../../plugins/i18n';
import { useTheme } from '../../../../../plugins/styled';
import Typography from '../../../../common/typography';
import StepContainer from '../StepContainer';
import { UploadStyled } from '../styles';
const FileUploader = ({ fileList, name, handleChange }) => {
    const [isValidFile, setIsValidFile] = useState(true);
    const { t } = useTranslation();
    const theme = useTheme();
    const rejectedFileTypes = [
        'exe',
        'bat',
        'cmd',
        'sh',
        'msi',
        'com',
        'vbs',
        'js',
        'jar',
        'app',
        'scr',
        'gadget',
        'pif',
    ];
    const validateFile = (file) => {
        const isFileSizeOk = file.size / 1024 / 1024 < 100;
        const isFileTypeOk = !rejectedFileTypes.includes(file.type.split('/')[1].toLowerCase());
        if (!isFileSizeOk || !isFileTypeOk) {
            return false;
        }
        return true;
    };
    const MainStep = () => {
        const beforeUpload = (file) => {
            const isValid = validateFile(file);
            if (isValid) {
                if (isValidFile) {
                    handleChange([file]);
                }
                else {
                    notification.error({ type: 'error', message: 'Upload failed' });
                }
            }
            setIsValidFile(isValid);
            return file;
        };
        const uploadProps = {
            className: 'assets-uploader',
            name,
            fileList,
            beforeUpload: beforeUpload,
            maxCount: 1,
            multiple: false,
            onChange: (info) => {
                console.info(info);
            },
            customRequest(options) {
                setTimeout(() => {
                    if (options.onSuccess) {
                        options.onSuccess('ok', new XMLHttpRequest()), 0;
                    }
                }, 500);
            },
        };
        return (_jsx(StepContainer, { label: '', description: '', children: _jsxs(UploadStyled, Object.assign({}, uploadProps, { children: [_jsx(UploadIcon, { width: 100, height: 100 }), _jsx("div", { style: { display: 'flex', flexDirection: 'column', gap: 6 }, children: _jsxs(Typography, { elementTheme: "body3", textAlign: "center", color: theme.colors.grey_2, children: [' ', t('upload.fileSize')] }) })] })) }));
    };
    return (_jsx("div", { style: {
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        }, children: _jsx(MainStep, {}) }));
};
export default FileUploader;
FileUploader.Demo = () => {
    const [fileList, setFileList] = useState([]);
    const handleUploadChange = (newFileList) => {
        setFileList(newFileList);
    };
    return (_jsx(Form, { layout: "vertical", children: _jsx(FileUploader, { fileList: fileList, name: 'file', handleChange: handleUploadChange }) }));
};
