import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { getShortId } from '../../../../features/helpers';
import BreadcrumbsContent from '../../../layout/header/breadcrumbs/breadcrumbs-content';
const FilesListBreadcrumb = ({ vaultPath, records, handleNavigateToFolder, }) => {
    const breadcrumbs = useMemo(() => {
        var _a;
        if (!records.length)
            return [];
        const breadcrumbs = [];
        let path = vaultPath;
        for (let index = 0; index < records.length; index++) {
            const record = records[index];
            if (record.record_id) {
                if (index > 0)
                    path += `/${record.record_id}`;
                breadcrumbs.push({
                    breadcrumbName: (_a = record.record_name) !== null && _a !== void 0 ? _a : getShortId(record.record_id),
                    path,
                    onClick: !!handleNavigateToFolder
                        ? () => handleNavigateToFolder(record.record_id)
                        : undefined,
                });
            }
        }
        return breadcrumbs;
    }, [records]);
    return _jsx(BreadcrumbsContent, { breadcrumbs: breadcrumbs });
};
export default FilesListBreadcrumb;
