export var ReviewStatus;
(function (ReviewStatus) {
    ReviewStatus["Hidden"] = "hidden";
    ReviewStatus["Public"] = "public";
})(ReviewStatus || (ReviewStatus = {}));
export var ReviewVerified;
(function (ReviewVerified) {
    ReviewVerified["Unverified"] = "Unverified";
    ReviewVerified["Verified"] = "Verified";
    ReviewVerified["Rejected"] = "Rejected";
})(ReviewVerified || (ReviewVerified = {}));
