import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from './location-provider';
export const RouterContext = React.createContext({
    current: '',
    previous: null,
    allPaths: [],
});
const AppRouterProvider = ({ children }) => {
    const { pathname: activePathname } = useLocation();
    const [route, setRoute] = useState({
        current: activePathname,
        previous: null,
        allPaths: [activePathname],
    });
    useEffect(() => {
        setRoute((prev) => ({
            current: activePathname,
            previous: prev.current,
            allPaths: [...prev.allPaths, activePathname],
        }));
    }, [activePathname]);
    return (_jsx(RouterContext.Provider, { value: route, children: children }));
};
export const useAppRouter = () => useContext(RouterContext);
export default AppRouterProvider;
