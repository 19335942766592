import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import Divider from 'antd/es/divider';
import React from 'react';
import { useTheme } from '../../plugins/styled';
import Avatar from '../common/avatar';
import Button from '../common/button';
import Div from '../common/div';
import Modal from '../common/hd-modal';
import Typography from '../common/typography';
import Loading from '../loading';
const ContactModal = ({ open, handleClose, picture, name, description, infos, cta, loading, }) => {
    const theme = useTheme();
    const InfoLine = ({ label, value, type }) => !!(value === null || value === void 0 ? void 0 : value.length) && (_jsxs("div", { children: [_jsx(Typography, { elementTheme: "caption", color: theme.colors.grey_2, children: label }), type === 'phone' ? (_jsx("a", { rel: "nofollow", href: `tel:${value.split(' ')[0]}`, children: _jsx(Typography, { elementTheme: "body2", children: value }) })) : type === 'email' ? (_jsx("a", { rel: "nofollow", href: `mailto:${value}`, children: _jsx(Typography, { elementTheme: "body2", children: value }) })) : (_jsx(Typography, { elementTheme: "body2", children: value }))] }));
    return (_jsx(Modal, { open: open, size: "small", handleCancel: handleClose, styles: { content: { padding: 54 } }, children: _jsx(Div, { flex: 'column', gap: 32, justify: 'center', children: loading ? (_jsx(Loading, {})) : (_jsxs(_Fragment, { children: [_jsxs(Div, { flex: 'column', justify: 'center', align: 'center', gap: 16, children: [_jsx(Avatar, { size: 128, shape: "square", src: picture, children: name === null || name === void 0 ? void 0 : name[0] }), _jsx(Typography, { elementTheme: "subtitle1", children: name }), !!description && (_jsx(Typography, { elementTheme: "caption", color: theme.colors.grey_2, children: description }))] }), _jsxs(Div, { flex: 'column', gap: 16, children: [_jsx(Divider, { style: { borderColor: theme.colors.grey_2, borderWidth: 1 } }), React.Children.toArray(infos === null || infos === void 0 ? void 0 : infos.map((info) => _jsx(InfoLine, Object.assign({}, info)))), _jsx(Divider, { style: { borderColor: theme.colors.grey_2, borderWidth: 1 } })] }), !!cta && (_jsx(Button, { defaultStyle: cta.color || theme.colors.yellow_1, headingIcon: _jsx(Timeline, { size: 24 }), onClick: cta.action, size: "small", style: { margin: 'auto' }, children: cta.text }))] })) }) }));
};
export default ContactModal;
