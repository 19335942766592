import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState, } from 'react';
import { useTheme } from 'styled-components';
import { AvatarContainer, StyledAvatar } from './styles';
const Avatar = (props) => {
    var _a;
    const theme = useTheme();
    const { children, src, alt, loading, size, lineHeight, shape, background, style, fallback, additional_fallback, } = useMemo(() => {
        var _a, _b, _c, _d;
        return (Object.assign(Object.assign({}, props), { loading: (_a = props.loading) !== null && _a !== void 0 ? _a : 'lazy', size: (_b = props.size) !== null && _b !== void 0 ? _b : 128, shape: (_c = props.shape) !== null && _c !== void 0 ? _c : 'square', background: (_d = props.background) !== null && _d !== void 0 ? _d : theme.colors.grey_1 }));
    }, [props, theme]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [imgSrc, setImgSrc] = useState((_a = src !== null && src !== void 0 ? src : fallback) !== null && _a !== void 0 ? _a : additional_fallback);
    const imgRef = useRef(null);
    useEffect(() => {
        var _a;
        const newSrc = (_a = src !== null && src !== void 0 ? src : fallback) !== null && _a !== void 0 ? _a : additional_fallback;
        setImgSrc((oldSrc) => {
            if (oldSrc !== newSrc) {
                setIsLoaded(false);
                return newSrc;
            }
            return oldSrc;
        });
    }, [src, fallback, additional_fallback]);
    const handleLoad = () => setIsLoaded(true);
    const handleError = () => {
        if (imgSrc === fallback && additional_fallback) {
            setImgSrc(additional_fallback);
        }
        else if (imgSrc !== fallback && fallback) {
            setImgSrc(fallback);
        }
        else {
            setImgSrc(undefined);
        }
        setIsLoaded(false);
    };
    return (_jsx(AvatarContainer, { "$shape": shape, "$lineHeight": lineHeight, "$size": size, "$background": background, style: style, children: !!imgSrc ? (_jsx(StyledAvatar, { ref: imgRef, src: imgSrc, alt: alt, loading: loading, width: size, height: size, onLoad: handleLoad, onError: handleError, style: {
                opacity: isLoaded ? 1 : 0,
                transition: 'opacity 0.3s ease',
            } })) : (children) }));
};
export default Avatar;
