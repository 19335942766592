import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import notification from 'antd/es/notification';
import { useTheme } from 'styled-components';
import handleDispatchResponse from '../../../../../features/helpers/handle-dispatch-response';
import { useProfileEdition } from '../../../../../features/providers/profile-edition-provider';
import { useTranslation } from '../../../../../plugins/i18n';
import Button from '../../../../common/button';
import ModalConfirm from '../../../../common/hd-modal/confirm';
import ModalWithHeaderFooter from '../../../../common/hd-modal/layouts/modal-with-header-footer';
import { handleReorderTeamMembers } from '../../../../provider/profile/profile-team/order-function/team-order-list';
const ProfileEditModalTeamFooter = ({ selectedMember, handleSubmit, handleCancel, handleSelectMember, setTeamMembers, teamMembers, }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { functions } = useProfileEdition();
    const handleDelete = () => {
        ModalConfirm({
            title: t('profile|assetsModal.confirm'),
            content: t('profile|assetsModal.delete.team'),
            okText: t('profile|assetsModal.confirm'),
            cancelText: t('profile|assetsModal.cancel'),
            okButtonProps: {
                style: {
                    backgroundColor: theme.colors.purple_1,
                    color: theme.colors.white_1,
                    borderRadius: '24px',
                },
            },
            cancelButtonProps: {
                style: {
                    backgroundColor: theme.colors.white_1,
                    borderColor: theme.colors.grey_1,
                    color: theme.colors.grey_1,
                    borderRadius: '24px',
                },
            },
            onOk() {
                if (!selectedMember.provider_team_member_id) {
                    notification.error({
                        message: t('provider-profile|team.empty.error'),
                        placement: 'top',
                    });
                    return;
                }
                return new Promise((resolve, reject) => {
                    !!functions.removeTeamMember &&
                        functions
                            .removeTeamMember({
                            teamMemberID: selectedMember.provider_team_member_id,
                        })
                            .then((res) => {
                            handleDispatchResponse(res, t('notification.saved'), t('notification.saving_error'));
                            handleSelectMember();
                            resolve();
                            handleReorderTeamMembers({
                                team_member_id: selectedMember.provider_team_member_id,
                                members: teamMembers,
                                functions,
                                setTeamMembers,
                            });
                        })
                            .catch((error) => notification.error({
                            message: error.errorFields[0].errors[0],
                            placement: 'top',
                        }));
                }).catch(() => console.error('Oops errors!'));
            },
        });
    };
    return (_jsx(ModalWithHeaderFooter.Footer, { actions: _jsxs(_Fragment, { children: [selectedMember.provider_team_member_id ? (_jsx(Button, { defaultStyle: theme.colors.red_1, onClick: handleDelete, size: "small", loading: functions.isLoading, children: t('button.delete') })) : (_jsx(Button, { defaultStyle: theme.colors.transparent, onClick: handleCancel, size: "small", loading: functions.isLoading, children: t('button.cancel') })), _jsx(Button, { onClick: handleSubmit, size: "small", loading: functions.isLoading, children: t('button.save') })] }) }));
};
export default ProfileEditModalTeamFooter;
