import styled from '../../../../plugins/styled';
export const GrantHeaderContent = styled.div `
  display: flex;
  flex-direction: row;
  gap: 54px;
  align-items: center;
  padding: 24px 48px;

  border-radius: 16px 16px 0px 0px;

  overflow: hidden;

  @media (max-width: ${(props) => { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.breakpoints) === null || _b === void 0 ? void 0 : _b.tablet; }}px) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-bottom: 40px;
  }
`;
export const GrantHeaderContainer = styled.div `
  background: ${({ theme }) => theme.colors.white_1};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 16px 16px 0px 0px;
  position:relative; 
  overflow: hidden;
  a {
    color: inherit;
  }
  @media (max-width: ${(props) => { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.breakpoints) === null || _b === void 0 ? void 0 : _b.tablet; }}px) {

    gap: 0;x
  }
`;
export const GrantUpdatedContainer = styled.div `
  width: 270px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  padding-inline-start: 16px;
  position: absolute;
  top: 0;
  right: 0;
`;
