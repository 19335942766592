import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Divider from 'antd/es/divider';
import React from 'react';
import useLocale from '../../../../../../features/providers/locale-provider';
import { useTheme } from '../../../../../../plugins/styled';
import Div from '../../../../../common/div';
import Typography from '../../../../../common/typography';
import getItemTranslatedLabel from '../../../../form-builder/utils/get-item-translated-label';
import FormSubmissionInput from '../form-submission-input';
const FormSubmisionEditorGroup = ({ group }) => {
    const { selectedLocale } = useLocale();
    const theme = useTheme();
    return (_jsxs(Div, { flex: 'column', children: [!!group.entity.group_title_en && (_jsx(Typography, { children: getItemTranslatedLabel({
                    label_en: group.entity.group_title_en,
                    label_fr: group.entity.group_title_fr,
                    fallback: '',
                    parseOptions: {},
                    locale: selectedLocale,
                }) })), _jsx(Div, { flex: "column", children: React.Children.toArray(group.items.map((itm, k) => (_jsxs(_Fragment, { children: [_jsx(FormSubmissionInput, { editSubmissionItem: itm }), k < group.items.length - 1 && (_jsx(Divider, { style: {
                                borderColor: theme.colors.purple_3,
                                margin: '5px 0',
                            }, variant: "dotted" }))] })))) })] }));
};
export default FormSubmisionEditorGroup;
