import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useTranslations } from '../../../features/providers/translations-provider';
import { useTheme } from '../../../plugins/styled';
import Div from '../../common/div';
import SeeMoreList from '../../common/see-more/see-more-list';
import Typography from '../../common/typography';
import ReviewRatings from '../ratings';
import ReviewCard from './review-card';
const ReviewList = ({ reviews, providerName, average, withInfos = false, locale, }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    const renderReviewCards = () => {
        return React.Children.toArray(reviews.map((review, index) => (_jsx(ReviewCard, { review: review, index: index, locale: locale }, review.review_id))));
    };
    const renderReviewInfo = () => {
        return (_jsx(Div, { style: { paddingBottom: '2rem' }, children: _jsxs(Div, { flex: "row", justify: "space-between", tablet: { flex: 'column' }, children: [_jsx(Typography, { elementTheme: "subtitle1", bold: true, children: t('reviews|providerReviewsListCount', {
                            count: reviews.length,
                            providerName: providerName !== null && providerName !== void 0 ? providerName : '',
                        }) }), _jsx(Div, { align: "flex-end", flex: "column", fitContent: true, gap: 8, children: _jsx(ReviewRatings, { defaultValue: average || 0, size: "large", showValue: true, biggerText: true, showOne: true, showTotal: true, textPlacement: "end", allowHalf: true }) })] }) }));
    };
    return (_jsxs(_Fragment, { children: [withInfos && renderReviewInfo(), _jsx(Div, { flex: "column", gap: 16, children: _jsx(SeeMoreList, { limit: 3, grid: { sm: 1, md: 1, lg: 1, column: 1, gutter: 24 }, content: renderReviewCards() }) })] }));
};
export default ReviewList;
