import { View } from '@react-pdf/renderer';
import React from 'react';
import { CompanyGrant } from 'src/@types/company-types';
import Group4Outlined from '../../../components/background/group4-outlined';
import CardWithIcon from '../../../components/cards/tile-with-description-and-icon';
import GrantsIcon from '../../../components/common/icons/grants';
import InvoicesIcon from '../../../components/common/icons/invoices';
import TimelineIcon from '../../../components/common/icons/timeline';
import VerifiedIcon from '../../../components/common/icons/verified';
import PageHeader from '../../../components/header';
import { createTemplateStyle } from '../../../styles/templates';
import theme from '../../../theme';
import { GamePlanTranslations } from '../types';
import getCompanyGrantFundingAmount from '../utils/get-company-grant-funding-amount';
import getGrantTimeline from '../utils/get-grant-timeline';

export interface GrantApplicationTemplateProps {
  grant: CompanyGrant;
  translations: GamePlanTranslations;
  locale: string;
}

const CARD_SPACING = 8;

const PotentialGrantTemplate: React.FC<GrantApplicationTemplateProps> = ({
  grant,
  translations,
  locale,
}) => {
  return (
    <View id={grant.grant_id}>
      <Group4Outlined
        width={679}
        height={948}
        color={theme.colors.beige_1}
        top={-68}
        margin={-354}
        align="left"
      />
      <PageHeader
        title={grant.company_grant_title}
        textAlign="center"
        elementTheme="h6"
      />
      <View
        style={createTemplateStyle({
          type: 'center',
          withFooter: false,
          withHeader: { headerElementTheme: 'h6' },
        })}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          <CardWithIcon
            icon={{ Icon: InvoicesIcon, backgroundColor: theme.colors.blue_2 }}
            size="medium"
            direction="horizontal"
            label={grant.grant_display_title}
            content={grant.grant_description_long}
            href={`https://app.hellodarwin.com/funding-explorer/${grant.grant_id}`}
            textAlign="left"
          />
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <CardWithIcon
              icon={{
                Icon: VerifiedIcon,
                backgroundColor: theme.colors.yellow_4,
              }}
              size="small"
              direction="horizontal"
              label={translations.eligibilityCriteria}
              content={grant.eligibility_criteria_short}
              withLink
              spacing={CARD_SPACING}
            />
            <View
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                paddingLeft: 24,
              }}
            >
              <CardWithIcon
                icon={{
                  Icon: GrantsIcon,
                  backgroundColor: theme.colors.green_4,
                }}
                size="small"
                direction="vertical"
                label={translations.targetAmountToObtain}
                content={getCompanyGrantFundingAmount(
                  grant,
                  locale,
                  translations,
                )}
                withLink
                spacing={CARD_SPACING}
              />
              <CardWithIcon
                icon={{
                  Icon: TimelineIcon,
                  backgroundColor: theme.colors.purple_4,
                }}
                size="small"
                direction="vertical"
                label={translations.keyDates}
                content={getGrantTimeline(
                  locale,
                  translations,
                  grant.grant_timeline,
                )}
                withLink
                spacing={CARD_SPACING}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default PotentialGrantTemplate;
