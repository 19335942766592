export const InitialAdminSelectedTags = {
    categories: [],
    industries: [],
    selectedCategories: [],
    selectedIndustry: '',
    selectedServices: [],
    selectedSpecialties: [],
};
export const InitialStateMappedTags = {
    categories: [],
    selectedServices: [],
    selectedSpecialties: [],
    selectedCategories: [],
};
export const InitialIndustryState = {
    id: '',
};
export var TagType;
(function (TagType) {
    TagType["Goal"] = "goal";
    TagType["ExpenseCategory"] = "expense_category";
    TagType["Expense"] = "expense";
    TagType["None"] = "";
})(TagType || (TagType = {}));
