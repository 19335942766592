import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
const ProgramEmptyStateStepCard = ({ Icon, title, text, }) => {
    const theme = useTheme();
    return (_jsxs(Div, { flex: "column", gap: 17, align: "center", style: {
            padding: 20,
            flexShrink: 0,
            flexGrow: 0,
            flex: 1,
            width: 320,
            height: '100%',
        }, borderRadius: 4, backgroundColor: theme.colors.white_1, borderColor: theme.colors.grey_4, children: [_jsx(Icon, {}), _jsx(Typography.Title, { level: 4, elementTheme: "h6", textAlign: "center", children: title }), _jsx(Typography, { elementTheme: "body2", textAlign: "center", children: text })] }));
};
export default ProgramEmptyStateStepCard;
