import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import { ProfileSectionContainer } from '../styles';
const ProfileSection = ({ title, children, isEditable, handleEdit, emptyState = _jsx(_Fragment, {}), id, Actions, level = 2, hide, }) => {
    const theme = useTheme();
    if (!!hide && !isEditable)
        return _jsx(_Fragment, {});
    return (_jsx(_Fragment, { children: _jsxs(ProfileSectionContainer, { id: id, children: [(!!title || !!Actions || isEditable) && (_jsxs(Div, { flex: "row", tablet: { flex: 'column', gap: 8 }, position: "relative", justify: title ? 'space-between' : 'flex-end', align: "center", children: [!!title && (_jsx(Typography.Title, { level: level, elementTheme: "h5", tablet: { textAlign: 'center', style: { margin: '0px 24px' } }, children: title })), _jsxs(Div, { flex: "row", gap: 10, fitContent: true, align: "center", children: [!!Actions && (_jsx(Div, { fitContent: true, flex: "row", gap: 12, children: Actions })), isEditable && !!handleEdit && (_jsx(Button, { tablet: {
                                        style: {
                                            position: 'absolute',
                                            right: 0,
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                        },
                                    }, onClick: handleEdit, transparent: true, size: "auto", defaultStyle: theme.colors.primary, style: { width: 'fit-content' }, headingIcon: _jsx(Edit, { size: 18 }) }))] })] })), _jsx(Div, { children: isEditable && !!hide ? emptyState : children })] }) }));
};
export default ProfileSection;
