import { GinApplication } from '@hellodarwin/core/lib/features/entities';
import Form, { FormInstance } from 'antd/es/form';
import RenderInputNumber from './application-form-input-number';
import RenderSelectDate from './application-form-select-date';

const OngoingWorkByHelloDarwinForm = ({
  form,
  application,
}: {
  form: FormInstance<GinApplication>;
  application: GinApplication;
}) => {
  return (
    <Form form={form} layout="vertical" initialValues={application}>
      <RenderInputNumber
        label="targeted_amount"
        name="application_targeted_amount"
      />
      <RenderSelectDate
        label="application_closed_at"
        name="application_closed_at"
        defaultToday={false}
      />
      <RenderSelectDate
        label="application_ongoing_work_at"
        name="application_requested_at"
        defaultToday={true}
      />
    </Form>
  );
};

export default OngoingWorkByHelloDarwinForm;
