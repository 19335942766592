import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment, useState } from 'react';
import ProfileBlocks from '../../../features/enums/profile-blocks';
import { useTranslations } from '../../../features/providers/translations-provider';
import ProfileRfps from '../profile-rfp';
import ProfileAbout from './profile-about';
import ProfileVideo from './profile-assets/profile-video';
import ProfileContact from './profile-contact';
import ContactPreview from './profile-contact/contact-preview';
import ProfileExpertise from './profile-expertise';
import ProfileFastInformations from './profile-fast-informations';
import ProfileHeader from './profile-header';
import ProviderMessage from './profile-message';
import ProfilePortfolio from './profile-portfolio';
import ProfileReviews from './profile-reviews';
import ProfileTeam from './profile-team';
import { ProviderProfileContainer } from './styles';
const ProviderProfile = ({ match, rfp, provider, providerActions, providerPrimaryActions, state = 'website', reviews, portfolios, rfps, teamMembers, bannerCTA, handleLeaveAReview, handleRedirectToSettings, handleRedirectToExpertise, sections, locale = 'en', toggleEditModal, contact, banners, setTeamMembers, }) => {
    var _a, _b, _c, _d, _e;
    const [showContact, setShowContact] = useState(false);
    const { t } = useTranslations();
    if (provider === undefined) {
        return _jsx(_Fragment, {});
    }
    const getAllIndexes = (arr, val) => {
        const indexes = arr
            .map((element, index) => (element === val ? index : -1))
            .filter((i) => i !== -1);
        return indexes;
    };
    const blocks = {
        [ProfileBlocks.FastInfos]: {
            label: '',
            content: (_jsx(ProfileFastInformations, { provider: provider, isEditable: state === 'edit', id: 'infos', toggleEditModal: toggleEditModal, locale: locale })),
        },
        [ProfileBlocks.About]: {
            label: t('profile_sections|about'),
            content: (_jsx(ProfileAbout, { provider: provider, isEditable: state === 'edit', toggleEditModal: toggleEditModal, id: 'about' })),
            hide: !((_a = provider === null || provider === void 0 ? void 0 : provider.description) === null || _a === void 0 ? void 0 : _a.length),
        },
        [ProfileBlocks.Portfolios]: {
            label: t('profile_sections|portfolio'),
            content: (_jsx(ProfilePortfolio, { portfolios: portfolios, isEditable: state === 'edit', toggleEditModal: toggleEditModal, id: 'portfolios', providerName: provider.name })),
            hide: !(portfolios === null || portfolios === void 0 ? void 0 : portfolios.length),
        },
        [ProfileBlocks.Presentation]: {
            label: t('profile_sections|video'),
            content: (_jsx(ProfileVideo, { provider: provider, isEditable: state === 'edit', isWebsite: state === 'website', toggleEditModal: toggleEditModal, id: 'video' })),
            hide: !((_b = provider.assets) === null || _b === void 0 ? void 0 : _b.length),
        },
        [ProfileBlocks.Team]: {
            label: t('profile_sections|team'),
            content: (_jsx(ProfileTeam, { provider: provider, teamMembers: teamMembers, setTeamMembers: setTeamMembers, isEditable: state === 'edit', toggleEditModal: toggleEditModal, id: 'team' })),
            hide: !(teamMembers === null || teamMembers === void 0 ? void 0 : teamMembers.length),
        },
        [ProfileBlocks.Reviews]: {
            label: t('profile_sections|reviews'),
            content: (_jsx(ProfileReviews, { provider: provider, reviews: reviews, state: state, id: 'reviews', handleLeaveAReview: handleLeaveAReview, locale: locale })),
        },
        [ProfileBlocks.Expertise]: {
            label: t('profile_sections|expertise'),
            content: (_jsx(ProfileExpertise, { provider: provider, isEditable: state === 'edit', handleRedirectToExpertise: handleRedirectToExpertise, id: 'expertise' })),
            hide: !provider.tags,
        },
        [ProfileBlocks.Rfp]: {
            label: t('profile_sections|rfp'),
            content: (_jsx(ProfileRfps, { isLoading: rfps === null || rfps === void 0 ? void 0 : rfps.isLoading, id: 'rfp', rfps: (_c = rfps === null || rfps === void 0 ? void 0 : rfps.rfps) !== null && _c !== void 0 ? _c : [] })),
            hide: !((_d = rfps === null || rfps === void 0 ? void 0 : rfps.rfps) === null || _d === void 0 ? void 0 : _d.length),
        },
        [ProfileBlocks.Contact]: {
            label: t('profile_sections|contact'),
            content: (_jsx(ProfileContact, { provider: provider, isEditable: state === 'edit', isRfp: !!rfp, providerPrimaryActions: providerPrimaryActions, handleRedirectToSettings: handleRedirectToSettings, handleShowContact: !!bannerCTA ? bannerCTA : () => setShowContact(true), isWebsite: state === 'website', id: 'contact' })),
        },
        [ProfileBlocks.AdBanner]: {
            label: '',
            content: '',
            hide: false,
        },
    };
    const sectionsVisible = sections.filter((s) => { var _a; return state === 'edit' || !((_a = blocks[s]) === null || _a === void 0 ? void 0 : _a.hide); });
    const bannerIndexes = getAllIndexes(sectionsVisible, ProfileBlocks.AdBanner);
    return (_jsxs(ProviderProfileContainer, { children: [_jsx(ProfileHeader, { provider: provider, isEditable: state === 'edit', sections: sections, blocks: blocks, providerActions: providerActions, toggleEditModal: toggleEditModal, websiteCTA: state === 'website' ? bannerCTA : undefined, handleLeaveAReview: handleLeaveAReview, handleRedirectToSettings: handleRedirectToSettings }), (match === null || match === void 0 ? void 0 : match.hello_message) && (_jsx(ProviderMessage, { message: (match === null || match === void 0 ? void 0 : match.hello_message) || '' })), React.Children.toArray(sectionsVisible.map((s, i) => {
                var _a;
                return (_jsx(Fragment, { children: s === ProfileBlocks.AdBanner
                        ? banners === null || banners === void 0 ? void 0 : banners[bannerIndexes.findIndex((index) => index === i)]
                        : (_a = blocks[s]) === null || _a === void 0 ? void 0 : _a.content }));
            })), !!contact && (_jsx(ContactPreview, { isVisible: showContact, onCancel: () => setShowContact(false), logo: provider.logo || '', name: contact.name, preferred_language: provider.rfp_language, phone: contact.phone || '', email: contact.email || '', ext: contact.ext || '', title: (_e = contact.title) !== null && _e !== void 0 ? _e : '' }))] }));
};
export default ProviderProfile;
