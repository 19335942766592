import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Doc from '@hellodarwin/icons/dist/icons/Doc';
import Download from '@hellodarwin/icons/dist/icons/Download';
import Link from '@hellodarwin/icons/dist/icons/Link';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import Card from 'antd/es/card/Card';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
const DocumentTile = ({ label, link, type }) => {
    const theme = useTheme();
    const Icon = type === 'link' ? Link : Doc;
    const ButtonIcon = type === 'link' ? NewWindow : Download;
    const { t } = useTranslations();
    return (_jsx(Card, { style: { padding: '38px', borderColor: theme.colors.primary }, children: _jsxs(Div, { flex: "row", gap: 24, align: "center", tablet: { flex: 'column' }, children: [_jsx("div", { style: {
                        width: 77,
                        height: 77,
                        flexShrink: 0,
                        background: theme.colors.purple_4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }, children: _jsx(Icon, { size: 24 }) }), _jsx(Div, { style: { width: '100%', padding: '14px 0' }, children: _jsx(Typography.Title, { level: 3, elementTheme: "subtitle2", noMargin: true, tablet: { textAlign: 'center' }, children: label }) }), _jsx("div", { children: _jsx("a", { href: link, download: type !== 'link', target: "_blank", rel: "nofollow", children: _jsx(Button, { fitContent: true, size: "small", defaultStyle: theme.colors.transparent, trailingIcon: _jsx(ButtonIcon, { width: 13, height: 13 }), children: type === 'link'
                                ? t('grant_single|openLink')
                                : t('grant_single|download') }) }) })] }) }));
};
export default DocumentTile;
