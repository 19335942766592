import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Divider from 'antd/es/divider';
import theme from '../../../../../theme';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
const FormGroupLayoutContent = ({ type }) => {
    switch (type) {
        case 'group':
            return (_jsx(Divider, { dashed: true, style: { borderColor: theme.colors.grey_2, minWidth: 'auto' }, children: "GROUP" }));
        case 'page':
            return (_jsx(Divider, { dashed: true, style: { borderColor: theme.colors.grey_2, minWidth: 'auto' }, children: "New Page" }));
        default:
            return _jsx(_Fragment, {});
    }
};
const FormGroupLayout = ({ type, isSimple }) => {
    return isSimple ? (_jsx(FormGroupLayoutContent, { type: type })) : (_jsxs(Div, { flex: "column", gap: 8, overflow: "hidden", children: [_jsx(Typography, { textTransform: "capitalize", elementTheme: "body3", style: { fontWeight: 600 }, children: type }), _jsx(FormGroupLayoutContent, { type: type })] }));
};
export default FormGroupLayout;
