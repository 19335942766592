import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Tooltip from 'antd/es/tooltip';
import { getStringDate } from '../../../../../features/helpers/get-formatted-date';
import useLocale from '../../../../../features/providers/locale-provider';
import { useTheme } from '../../../../../plugins/styled';
import Typography from '../../../../common/typography';
const AssetMetaContent = ({ name, size, version, description, created_at, }) => {
    const theme = useTheme();
    const { selectedLocale } = useLocale();
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: name, children: _jsx(Typography, { elementTheme: "subtitle1", lineClamp: 2, textAlign: "center", children: name }) }), _jsx(Typography, { elementTheme: "body2", color: theme.colors.grey_2, textAlign: "center", children: description }), _jsxs(Typography, { elementTheme: "overline", color: theme.colors.grey_2, textAlign: "center", children: [size, " |", ' ', !!version
                        ? `V. ${version}`
                        : getStringDate(created_at, selectedLocale)] })] }));
};
export default AssetMetaContent;
