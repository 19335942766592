import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Progress from 'antd/es/progress';
import { useMediaQuery } from 'react-responsive';
import theme from '../../../theme';
import Div from '../../common/div';
import Typography from '../../common/typography';
const FormHeader = ({ formResponse, isEndPage, isOpening, type, progress, }) => {
    if (isEndPage || isOpening)
        return _jsx(_Fragment, {});
    if (type === 'modal') {
        return (_jsx(Progress, { percent: progress, type: "circle", strokeColor: theme.colors.green_1, size: "small", strokeWidth: 12, trailColor: theme.colors.grey_4, style: {
                position: 'absolute',
                zIndex: 10,
                right: 128 - 20,
                top: 54 - 30 + parseInt(theme.fontsLineHeights.h6.replace('px', '')) / 2,
            } }));
    }
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    return (_jsxs(Div, { style: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        }, tablet: { style: { flexDirection: 'column' } }, children: [_jsxs(Div, { flex: "column", gap: 8, style: {
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    textAlign: 'center',
                }, tablet: { style: { padding: '0 24px', position: 'static' } }, children: [_jsx(Typography.Title, { level: 1, elementTheme: 'h4', textAlign: "center", children: formResponse.title }), _jsx(Typography, { elementTheme: 'body1', textAlign: "center", children: formResponse.subtitle })] }), _jsx(Progress, { style: { padding: isTablet ? '0px 24px' : 0 }, strokeWidth: 14, percent: progress, type: isTablet ? 'line' : 'circle', percentPosition: isTablet ? { align: 'end', type: 'inner' } : {}, strokeColor: theme.colors.green_1, trailColor: theme.colors.grey_4 })] }));
};
export default FormHeader;
