import Select from 'antd/es/select';
import { hexToRgba } from '../../../../features/helpers/hexToRgba';
import styled, { css, keyframes } from '../../../../plugins/styled';
import { fadeIn } from '../../../../theme/keyframes';
import Collapse from '../../../common/Collapse';
import Grid from '../../../common/hd-grid';
export const FilterSelect = styled(Select) `
  position: relative;
 
  &.ant-select-multiple,
  &.ant-select-multiple.ant-select-lg {
  
    .ant-select-selection-overflow-item {
      display: none;
      &.ant-select-selection-overflow-item-suffix {
        display: inline-flex;
        width: 100%;
        .ant-select-selection-search {
          width: 100% !important;
          input {
            font-size: 16px;
            width: 100%;
          }
        }
      }
    }
  }
  &.ant-select-multiple.ant-select-lg {
    .ant-select-selection-overflow-item {
      &.ant-select-selection-overflow-item-suffix {
        .ant-select-selection-search {
          input {
            font-size: 20px;
          }
        }
      }
    }
  }
    &.ant-select-multiple
    .ant-select-arrow {
      position: absolute;
      top: 0;
      left: 12px;
      margin: 0;
      height: 100%;
      width: calc(100% - 24px);
      }
      &.ant-select-open {
        .ant-select-arrow {
          opacity: 0;
          }
          }
          }

          @media screen and (max-width:${({ theme }) => theme.breakpoints.l}px){
          .ant-select {
          width:100% !important; }
          }
          `;
export const FilterSelectDropdown = styled.div `
  padding: 0;
  .ant-select-item {
    padding: 0;
  }
`;
export const FiltersGrid = styled(Grid) `
  ${({ $needSaving }) => $needSaving
    ? `
& > .ant-col:last-child {
  max-width: calc((100% / 3) * 2);
  flex: 0 0 calc((100% / 3) * 2);
  padding: 12px 6px 0 54px !important;
  &..ant-col-24 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
`
    : undefined}
`;
const scroll = keyframes `
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;
export const FundingExplorerTagBarContainer = styled.div `
  ${({ $isOpen, $withDrawer, theme }) => !$isOpen && $withDrawer
    ? `

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: calc(16px + 12px);
    width: 100%;
    z-index: 1;
    pointer-events: none;
    
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      ${hexToRgba(theme.colors.white_1)}
      );
      }
      `
    : undefined}
  max-height: ${({ $isOpen, $withDrawer }) => $isOpen || !$withDrawer ? 'auto' : '70px'};
`;
export const FundingExplorerTagBarStyled = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  height: 100%;

  position: relative;
`;
export const GrantProjectsRow = styled.div `
  display: flex;
  gap: 10px;
  width: 100%;
  white-space: nowrap;
`;
export const FilterMultipleSelectStyled = styled(Select) `
  ${({ $isMultiple }) => $isMultiple
    ? css `
          .ant-select-selection-overflow,
          .ant-select-selection-overflow-item span {
            overflow: hidden;
          }
        `
    : undefined}
`;
export const FilterSectionContainterStyled = styled.div `
  z-index: 500;

  animation: ${fadeIn} 200ms linear;

  ${({ $position, theme, $navCollapsed }) => $position === 'top'
    ? `
    position: sticky;
    top: 0;
    @media only screen and (max-width: ${theme.breakpoints.tablet}px) {
      position: relative;
    }
    `
    : `
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100vw - ${$navCollapsed ? theme.appearance.sidebarWidthCollapsed : theme.appearance.sidebarWidth}px);
    transition: width 180ms linear;
     @media only screen and (max-width: ${theme.breakpoints.tablet}px) {
      position: relative;
      width:100%;
    }
      `}
`;
export const FilterSectionStyled = styled.div `
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.grey_4};
  background-color: ${({ theme }) => theme.colors.white_2};
  padding: 12px 24px;
  border-radius: ${({ $position }) => ($position === 'top' ? '4px' : '0px')};
  box-shadow: ${({ theme, $position }) => $position === 'top'
    ? theme.appearance.box_shadow
    : theme.appearance.box_shadow_hover};
  backdrop-filter: blur(20px);

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    backdrop-filter: none;
    padding: 32px 24px;
  }
`;
export const FilterDropdownDesktopDrawerStyled = styled.div `
  display: ${({ $open }) => ($open ? 'block' : 'none')};
  position: absolute;
  top: calc(100% + 12px);
  backdrop-filter: blur(20px);
  width: 100%;
  left: 0;
  padding: 0;
  z-index: 200;
  border: 1px solid ${({ theme }) => theme.colors.grey_4};
  background-color: ${({ theme }) => theme.colors.white_2};
  border-radius: 4px;
  overflow: hidden;
`;
export const CompanyCollapse = styled(Collapse) ``;
