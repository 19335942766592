import { useMemo } from 'react';
const useGrantsRegion = (grant) => {
    return useMemo(() => {
        const { city, province, country } = grant;
        return [city, province, country]
            .filter((itm) => !!itm)
            .slice(0, 2)
            .join(', ');
    }, [grant]);
};
export default useGrantsRegion;
