import { useMemo, useReducer, useState } from 'react';
import { InitialProjectState, } from '../../../features/entities/projects-entities';
import useCompanyProjectsColumns from './use-company-projects-columns';
const InitialState = {
    activeProject: InitialProjectState,
    activeModal: undefined,
    activeGrant: undefined,
    activeParentProject: undefined,
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_STATE':
            return Object.assign(Object.assign({}, state), { editState: action.payload });
        case 'EDIT_PROJECT':
            return Object.assign(Object.assign({}, state), { activeModal: 'edit-project', activeProject: action.payload, activeParentProject: undefined });
        case 'ADD_NEW_PROJECT':
            return Object.assign(Object.assign({}, state), { activeModal: 'add-project', activeProject: InitialProjectState, activeParentProject: action.payload, activeGrant: undefined });
        case 'ADD_PROJECT_SUBITEM':
            return Object.assign(Object.assign({}, state), { activeModal: 'add-new-project-item', activeProject: action.payload, activeParentProject: undefined, activeGrant: undefined });
        case 'ADD_PROJECT_GRANT_SUBITEM':
            return Object.assign(Object.assign({}, state), { activeModal: 'add-new-project-item', activeProject: undefined, activeGrant: action.payload, activeParentProject: undefined });
        case 'CLOSE_MODAL':
            return Object.assign(Object.assign({}, state), InitialState);
        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
};
const useProjectsListState = ({ defaultState = 'editable', projects, defaultClosed, projectsOnly, applicationsPath, grantsPath, projectPath, }) => {
    const [state, dispatch] = useReducer(reducer, Object.assign(Object.assign({}, InitialState), { editState: defaultState }));
    const [selectedRowKeys, setClosedRowKeys] = useState([]);
    const expandedRowKeys = useMemo(() => !!defaultClosed
        ? selectedRowKeys
        : projects.flatMap((p1) => {
            var _a;
            return (_a = p1.projects) === null || _a === void 0 ? void 0 : _a.filter((p) => !selectedRowKeys.includes(p.project_id)).flatMap((p) => {
                var _a;
                return [
                    p.project_id,
                    ...((_a = p.grants) !== null && _a !== void 0 ? _a : [])
                        .filter((g) => !selectedRowKeys.includes(g.grant_id))
                        .map((g) => g.grant_id),
                ];
            });
        }), [selectedRowKeys, projects, defaultClosed]);
    const onExpand = (record_id) => {
        const isSelected = selectedRowKeys.includes(record_id);
        if (isSelected) {
            setClosedRowKeys((prevKeys) => prevKeys.filter((key) => record_id !== key));
        }
        else {
            setClosedRowKeys((prevKeys) => [...prevKeys, record_id]);
        }
    };
    const handleEditProject = (project) => dispatch({ type: 'EDIT_PROJECT', payload: project });
    const handleAddProject = (project) => dispatch({ type: 'ADD_NEW_PROJECT', payload: project });
    const handleAddProjectSubitem = (project) => {
        dispatch({ type: 'ADD_PROJECT_SUBITEM', payload: project });
    };
    const handleAddGrantSubitem = (grant) => dispatch({ type: 'ADD_PROJECT_GRANT_SUBITEM', payload: grant });
    const handleCloseModal = () => dispatch({ type: 'CLOSE_MODAL' });
    const projectsColumns = useCompanyProjectsColumns({
        handleEditProject,
        handleAddProjectSubitem,
        handleAddGrantSubitem,
        expandedRowKeys,
        onExpand,
        projectsOnly,
        applicationsPath,
        grantsPath,
        projectPath,
        isEditable: state.editState === 'editable',
    });
    return useMemo(() => (Object.assign(Object.assign({}, state), { handleEditProject,
        handleAddProject,
        handleAddProjectSubitem,
        handleAddGrantSubitem,
        handleCloseModal,
        projectsColumns,
        projects,
        onExpand,
        expandedRowKeys,
        projectsOnly,
        applicationsPath,
        projectPath,
        grantsPath })), [
        state,
        projects,
        projectsColumns,
        expandedRowKeys,
        projectsOnly,
        applicationsPath,
        projectPath,
        grantsPath,
    ]);
};
export default useProjectsListState;
