import { jsx as _jsx } from "react/jsx-runtime";
import { attributesToProps, domToReact, } from '../../../features/helpers/parse';
import styled from '../../../plugins/styled';
import Div from '../../common/div';
import Typography from '../../common/typography';
export const GrantSingleContainer = styled(Div) ``;
export const GrantSingleSectionContent = styled(Div) `
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    ul {
      margin-left: 16px;
      padding-left: 16px;
    }
  }
`;
export const grantSingleParseOptions = {
    replace: (domNode) => {
        const props = !!domNode.attribs ? attributesToProps(domNode.attribs) : {};
        switch (domNode.name) {
            case 'a':
                return (_jsx("a", Object.assign({}, props, { rel: "nofollow", target: "_blank", children: domToReact(domNode.children, grantSingleParseOptions) })));
            case 'p':
                return (_jsx(Typography, { children: domToReact(domNode.children, grantSingleParseOptions) }));
            case 'li':
                return (_jsx("li", Object.assign({}, props, { children: domToReact(domNode.children, grantSingleParseOptions) })));
            case 'ul':
                return _jsx("ul", { children: domToReact(domNode.children, grantSingleParseOptions) });
            case 'h1':
                return (_jsx(Typography.Title, { level: 1, elementTheme: "h6", children: domToReact(domNode.children, grantSingleParseOptions) }));
            case 'h2':
                return (_jsx(Typography.Title, { level: 2, elementTheme: "h6", children: domToReact(domNode.children, grantSingleParseOptions) }));
            case 'h3':
                return (_jsx(Typography.Title, { level: 3, elementTheme: "h6", children: domToReact(domNode.children, grantSingleParseOptions) }));
            case 'h4':
                return (_jsx(Typography.Title, { level: 4, elementTheme: "subtitle1", children: domToReact(domNode.children, grantSingleParseOptions) }));
            case 'h5':
                return (_jsx(Typography.Title, { level: 5, elementTheme: "subtitle2", children: domToReact(domNode.children, grantSingleParseOptions) }));
            case 'h6':
                return (_jsx(Typography.Title, { level: 6, elementTheme: "body2", medium: true, children: domToReact(domNode.children, grantSingleParseOptions) }));
        }
    },
};
