export var TagSubType;
(function (TagSubType) {
    TagSubType["Service"] = "services";
    TagSubType["Specialty"] = "specialties";
})(TagSubType || (TagSubType = {}));
export var TagType;
(function (TagType) {
    TagType["Category"] = "categories";
})(TagType || (TagType = {}));
const getTagCategory = (service) => {
    var _a;
    return (_a = service === null || service === void 0 ? void 0 : service.split(':')) === null || _a === void 0 ? void 0 : _a[1];
};
export default class TagAdapter {
    constructor() {
        this.setAll = (tags) => {
            const mappedTags = {};
            for (let tag of tags.Tags) {
                const [type, category, subType, value] = tag.tag.split(':');
                if (type !== TagType.Category) {
                    continue;
                }
                if (mappedTags[category] === undefined) {
                    mappedTags[category] = {};
                    mappedTags[category].tag = category;
                    mappedTags[category].label = category;
                    mappedTags[category].isSelected = false;
                    mappedTags[category].services = {};
                    mappedTags[category].specialties = {};
                }
                if (subType === TagSubType.Service) {
                    const service = {
                        category: category,
                        tag: tag.tag,
                        label: value,
                        isSelected: false,
                        min_budget: 0,
                        max_budget: 0,
                        description: '',
                    };
                    mappedTags[category].services[tag.tag] = service;
                }
                if (subType === TagSubType.Specialty) {
                    const speciality = {
                        tag: tag.tag,
                        label: value,
                        isSelected: false,
                    };
                    mappedTags[category].specialties[tag.tag] = speciality;
                }
            }
            for (let tag of Object.values(tags.PartnerTags)) {
                mappedTags[tag.category].isSelected = true;
                if (tag.services) {
                    for (let service of tag.services) {
                        mappedTags[tag.category].services[service.tag].min_budget =
                            service.min_budget;
                        mappedTags[tag.category].services[service.tag].max_budget =
                            service.max_budget;
                        mappedTags[tag.category].services[service.tag].description =
                            service.description;
                        mappedTags[tag.category].services[service.tag].isSelected = true;
                    }
                }
                if (tag.specialties) {
                    for (let speciality of tag.specialties) {
                        mappedTags[tag.category].specialties[speciality].isSelected = true;
                    }
                }
            }
            return mappedTags;
        };
        this.toggleCategoryTag = (categoryTagEntities, category) => {
            Object.values(categoryTagEntities[category].services).forEach((service) => {
                service.isSelected = false;
            });
            Object.values(categoryTagEntities[category].specialties).forEach((specialty) => {
                specialty.isSelected = false;
            });
            categoryTagEntities[category].isSelected =
                !categoryTagEntities[category].isSelected;
            return categoryTagEntities;
        };
        this.toggleServiceTag = (categoryTagEntities, service) => {
            const category = getTagCategory(service);
            categoryTagEntities[category].services[service].isSelected =
                !categoryTagEntities[category].services[service].isSelected;
            categoryTagEntities[category].services[service].min_budget = 0;
            categoryTagEntities[category].services[service].max_budget = 0;
            return categoryTagEntities;
        };
        this.handleServiceChange = (categoryTagEntities, service, minBudget, maxBudget, description) => {
            const category = getTagCategory(service);
            categoryTagEntities[category].services[service].min_budget = minBudget;
            categoryTagEntities[category].services[service].max_budget = maxBudget;
            categoryTagEntities[category].services[service].description = description;
            return categoryTagEntities;
        };
        this.toggleSpecialtyTag = (categoryTagEntities, specialty) => {
            const category = getTagCategory(specialty);
            categoryTagEntities[category].specialties[specialty].isSelected =
                !categoryTagEntities[category].specialties[specialty].isSelected;
            return categoryTagEntities;
        };
        this.selectTags = (categoryTagEntities) => {
            const categories = [];
            const selectedCategories = [];
            Object.values(categoryTagEntities).forEach((category) => {
                if (category.isSelected) {
                    selectedCategories.push({
                        label: category.label,
                        tag: category.tag,
                        isSelected: category.isSelected,
                        services: Object.values(category.services),
                        specialties: Object.values(category.specialties),
                    });
                }
                categories.push({
                    label: category.label,
                    isSelected: category.isSelected,
                });
            });
            if (categories.length === 0) {
                return {
                    categories: [],
                    selectedServices: [],
                    selectedSpecialties: [],
                    selectedCategories: [],
                };
            }
            const selectedServices = [];
            const selectedSpecialties = [];
            for (const category of categories) {
                Object.values(categoryTagEntities[category.label].services).forEach((tag) => {
                    const categorizedTag = {
                        category: category.label,
                        tag: tag.tag,
                        label: tag.label,
                        isSelected: tag.isSelected,
                        min_budget: tag.min_budget,
                        max_budget: tag.max_budget,
                        description: tag.description,
                    };
                    if (categorizedTag.isSelected) {
                        selectedServices.push(categorizedTag);
                    }
                });
                Object.values(categoryTagEntities[category.label].specialties).forEach((tag) => {
                    const categorizedTag = {
                        category: category.label,
                        tag: tag.tag,
                        label: tag.label,
                        isSelected: tag.isSelected,
                    };
                    if (categorizedTag.isSelected) {
                        selectedSpecialties.push(categorizedTag);
                    }
                });
            }
            return {
                categories,
                selectedServices,
                selectedSpecialties,
                selectedCategories,
            };
        };
        this.selectPortfolioTags = (portfolioTags) => {
            const categories = [];
            const services = [];
            const specialties = [];
            if (portfolioTags) {
                const splited_tags = portfolioTags.map((tag) => tag === null || tag === void 0 ? void 0 : tag.split(':'));
                splited_tags.forEach((tag) => {
                    if (tag[0] === 'categories') {
                        categories.push(tag.join(':'));
                    }
                    if (tag[2] === 'specialties') {
                        specialties.push(tag.join(':'));
                    }
                    if (tag[2] === 'services') {
                        services.push(tag.join(':'));
                    }
                });
            }
            return {
                categories,
                services,
                specialties,
            };
        };
        this.getInitialState = () => {
            return {};
        };
    }
}
export const createTagAdapter = () => {
    return new TagAdapter();
};
