import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Industry from '@hellodarwin/icons/dist/icons/Industry';
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import Checkbox from 'antd/es/checkbox';
import { useCallback, useMemo } from 'react';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import FormItem from '../../../../forms/form-layouts/form-item';
import { FilterSelect, FilterSelectDropdown } from '../styles';
const IndustriesSelector = ({ industries, filterValues, }) => {
    const theme = useTheme();
    const { t } = useTranslations();
    const dropdownStyle = useMemo(() => ({
        padding: 0,
        borderRadius: 0,
        border: `1px solid ${theme.colors.primary}`,
    }), [theme]);
    const dropdownRender = useCallback((el) => (_jsx(FilterSelectDropdown, { children: el })), []);
    const optionRender = useCallback((option, info) => {
        var _a, _b, _c;
        return (_jsxs(Div, { flex: "row", gap: 8, align: "center", borderColor: theme.colors.grey_4, bordersSide: "bottom", justify: "space-between", style: { padding: '12px 16px' }, children: [_jsx(Typography, { elementTheme: "body3", color: theme.colors.primary, ellipsis: true, overflow: true, nowrap: true, children: option.label }), _jsx(Checkbox, { type: "square", checked: (_a = filterValues === null || filterValues === void 0 ? void 0 : filterValues.industry) === null || _a === void 0 ? void 0 : _a.includes((_c = (_b = option.value) === null || _b === void 0 ? void 0 : _b.toString()) !== null && _c !== void 0 ? _c : '') })] }));
    }, [filterValues]);
    return (_jsx(FormItem, { name: "industry", style: { flex: 1, margin: 0 }, children: _jsx(FilterSelect, { mode: "multiple", dropdownRender: dropdownRender, style: {
                width: 340,
                maxWidth: '100%',
                height: 45,
            }, filterOption: (input, option) => {
                var _a;
                return typeof (option === null || option === void 0 ? void 0 : option.label) === 'string'
                    ? ((_a = option === null || option === void 0 ? void 0 : option.label) === null || _a === void 0 ? void 0 : _a.toLowerCase().indexOf(input === null || input === void 0 ? void 0 : input.toLowerCase())) !== -1
                    : false;
            }, suffixIcon: _jsxs(Div, { flex: "row", gap: 32, align: "center", justify: "space-between", xl: { gap: 8 }, overflow: "hidden", children: [_jsxs(Div, { flex: "row", gap: 8, align: "center", style: { maxWidth: '100%' }, children: [_jsx(Industry, { size: 20, color: theme.colors.grey_3, style: { flexShrink: 0 } }), _jsx(Typography, { style: { fontSize: 15 }, nowrap: true, ellipsis: true, overflow: true, children: t(`grantsFundingExplorer|grantsfundingExplorerFilterIndustryMainPlaceholder`) })] }), _jsx(TabArrow, { size: 16 })] }), size: "large", options: industries, dropdownStyle: dropdownStyle, optionRender: optionRender, menuItemSelectedIcon: _jsx(_Fragment, {}) }) }));
};
export default IndustriesSelector;
