import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo } from 'react';
import { usePlatform } from './platform-provider';
const FeatureFlagContext = createContext({});
export const FeatureFlagProvider = ({ children, flags, role, }) => {
    const { isDev } = usePlatform();
    const enabledFlags = useMemo(() => isDev || role === 'SuperAdmin'
        ? Object.keys(flags).reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key]: true })), {})
        : flags, [isDev, role, flags]);
    return (_jsx(FeatureFlagContext.Provider, { value: enabledFlags, children: children }));
};
export const useFeatureFlags = () => useContext(FeatureFlagContext);
