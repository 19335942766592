import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Close from '@hellodarwin/icons/dist/icons/Close';
import { StyledTag } from './styles';
import TooltipWrapper from './tooltip-wrapper';
const HdTag = ({ text, icon, color, size, shape, style, onClick, onClose, hoverable, iconPosition, onMouseEnter, onMouseLeave, onPointerEnter, onPointerLeave, highContrastText, withBorder, tooltip, }) => {
    return (_jsx(TooltipWrapper, { tooltip: tooltip, children: _jsxs(StyledTag, { onClick: !!onClose && !onClick ? onClose : onClick, "$size": size, "$color": color, "$shape": shape, style: style, "$hoverable": hoverable, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onPointerEnter: onPointerEnter, onPointerLeave: onPointerLeave, "$withBorder": withBorder, "$highContrastText": highContrastText, children: [iconPosition !== 'end' && icon, _jsx("span", { children: text }), iconPosition === 'end' && icon, !!onClose && (_jsx(Close, { style: { flexShrink: 0 }, onClick: (e) => {
                        e.stopPropagation();
                        onClose();
                    }, size: 12 }))] }) }));
};
export default HdTag;
