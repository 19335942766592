import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import React, { useMemo, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ItemsTypes } from '../../../../features/entities/form-entities';
import { FormListStyled } from '../styles';
import getTypeComponent from '../utils/get-type-component';
import getTypeItems from '../utils/get-type-items';
const FormItemsList = ({ locale }) => {
    const [activeType, setActiveType] = useState(ItemsTypes.main);
    const options = useMemo(() => Object.keys(ItemsTypes).map((key) => ({
        label: ItemsTypes[key],
        value: ItemsTypes[key],
    })), [ItemsTypes]);
    const droppableId = useMemo(() => `controls_droppable_${activeType}`, [activeType]);
    const activeType_items = useMemo(() => {
        return getTypeItems(activeType);
    }, [activeType]);
    const ActiveType_Component = useMemo(() => {
        return getTypeComponent(activeType);
    }, [activeType]);
    return (_jsxs(FormListStyled, { children: [_jsx(Select, { options: options, value: activeType, onChange: setActiveType }), _jsx(Form, { layout: "vertical", children: _jsx("div", { children: _jsx(Droppable, { droppableId: droppableId, type: activeType, children: (provided) => (_jsxs("div", Object.assign({}, provided.droppableProps, { ref: provided.innerRef, children: [React.Children.toArray(activeType_items.map(({ key }, index) => (_jsx(Draggable, { draggableId: key, index: index, children: (provided) => (_jsx("div", Object.assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { children: _jsx(ActiveType_Component, { type: key, locale: locale }) }))) }, `control_draggable_${key}`)))), provided.placeholder] }))) }) }) })] }));
};
export default FormItemsList;
