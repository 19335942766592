var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useTranslations } from '../../../features/providers/translations-provider';
import HdTag from '../../common/hd-tag';
import getGrantStatusTagTheme from '../utils/get-grant-status-color';
const GrantStatusTag = (props) => {
    const { status, text } = props, eventHandlers = __rest(props, ["status", "text"]);
    const theme = useTheme();
    const { t } = useTranslations();
    const tagTheme = useMemo(() => getGrantStatusTagTheme(theme, status), [status, theme]);
    return (_jsx(HdTag, Object.assign({ icon: _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "12", height: "12", viewBox: "0 0 12 12", fill: "none", children: _jsx("circle", { cx: "6", cy: "6", r: "5.25", fill: tagTheme.svgBackground, stroke: tagTheme.svgBorder, strokeWidth: "1.5" }) }), highContrastText: true, color: tagTheme.tagColor, iconPosition: "start", text: text || t(`grant_card_status|${status}`) }, eventHandlers)));
};
export default GrantStatusTag;
