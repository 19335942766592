var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { LoaderService } from './gmaps-loader-service';
export var Status;
(function (Status) {
    Status["LOADING"] = "LOADING";
    Status["FAILURE"] = "FAILURE";
    Status["SUCCESS"] = "SUCCESS";
})(Status || (Status = {}));
export const Wrapper = (_a) => {
    var { children, render, callback, library } = _a, options = __rest(_a, ["children", "render", "callback", "library"]);
    const [status, setStatus] = useState(Status.LOADING);
    const loaderService = useMemo(() => LoaderService.getInstance(options), [options]);
    const prepareScript = () => {
        const loader = loaderService.getLoader();
        const setStatusAndExecuteCallback = (status) => {
            if (callback)
                callback(status, loader);
            setStatus(status);
        };
        setStatusAndExecuteCallback(Status.LOADING);
        loader.importLibrary(library).then(() => setStatusAndExecuteCallback(Status.SUCCESS), () => setStatusAndExecuteCallback(Status.FAILURE));
    };
    useEffect(() => {
        prepareScript();
    }, []);
    if (status === Status.SUCCESS && children)
        return _jsx(_Fragment, { children: children });
    if (render)
        return render(status);
    return _jsx(_Fragment, {});
};
