import { jsx as _jsx } from "react/jsx-runtime";
import notification from 'antd/es/notification';
import React, { useCallback, useContext, useEffect, useState, } from 'react';
import LoadingScreen from '../../../components/loading/screen';
import useApolloClient from '../../hooks/use-appollo-client';
import useDebounceFunction from '../../hooks/use-debounce-function';
import useLocale from '../locale-provider';
import TranslationsProvider from '../translations-provider';
import { flattenTranslations, processTranslationFiles, } from '../translations-provider/translations-utils';
import { TRANSLATIONS_QUERY_BY_FILES } from './queries';
export const AppTranslationsContext = React.createContext({
    translationsData: {},
    isLoading: false,
});
const AppTranslationsProvider = ({ children, translationsFiles, }) => {
    const { selectedLocale } = useLocale();
    const [translationsData, setTranslationsData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const client = useApolloClient('modxx330knqj', 'P8xozOvf7K4HtnL7xpr7Sp5aO1FV4WLyJlCquu9tPz8');
    const fetchFiles = useDebounceFunction(useCallback(async () => {
        var _a;
        if (!translationsFiles.length)
            return;
        setIsLoading(true);
        try {
            const { data } = await client.query({
                query: TRANSLATIONS_QUERY_BY_FILES,
                variables: { locale: selectedLocale, files: translationsFiles },
            });
            if ((_a = data === null || data === void 0 ? void 0 : data.translationFileCollection) === null || _a === void 0 ? void 0 : _a.items.length) {
                const mappedTranslations = processTranslationFiles(data.translationFileCollection.items);
                const flatTranslations = flattenTranslations([mappedTranslations]);
                setTranslationsData((prev) => (Object.assign(Object.assign({}, prev), flatTranslations)));
            }
        }
        catch (error) {
            notification.error({
                message: 'Translations failed',
                description: error.message,
                placement: 'bottomLeft',
            });
        }
        finally {
            setIsLoading(false);
        }
    }, [client, selectedLocale, translationsFiles]), 500, true);
    useEffect(() => {
        fetchFiles();
    }, [fetchFiles]);
    if (isLoading)
        return _jsx(LoadingScreen, {});
    return (_jsx(AppTranslationsContext.Provider, { value: { translationsData, isLoading }, children: _jsx(TranslationsProvider, { children: children }) }));
};
export const useAppTranslations = () => useContext(AppTranslationsContext);
export default AppTranslationsProvider;
