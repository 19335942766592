import { jsx as _jsx } from "react/jsx-runtime";
import Group1Filled from '@hellodarwin/icons/dist/backgrounds/Group1Filled';
import { Tablet } from '../../Media';
import { IconContent } from './styles';
const BackgroundIcon = ({ iconParams }) => {
    const iconProps = {
        width: iconParams === null || iconParams === void 0 ? void 0 : iconParams.width,
        height: iconParams === null || iconParams === void 0 ? void 0 : iconParams.height,
    };
    return (_jsx(Tablet, { direction: "up", inactive: !(iconParams === null || iconParams === void 0 ? void 0 : iconParams.desktopOnly), children: _jsx(IconContent, { "$iconHeight": iconParams === null || iconParams === void 0 ? void 0 : iconParams.height, "$iconWidth": iconParams === null || iconParams === void 0 ? void 0 : iconParams.width, "$iconRotate": iconParams === null || iconParams === void 0 ? void 0 : iconParams.rotate, "$iconTop": iconParams === null || iconParams === void 0 ? void 0 : iconParams.top, "$iconColor": iconParams === null || iconParams === void 0 ? void 0 : iconParams.color, "$iconMargin": iconParams === null || iconParams === void 0 ? void 0 : iconParams.margin, "$side": iconParams === null || iconParams === void 0 ? void 0 : iconParams.side, "$inversed": iconParams === null || iconParams === void 0 ? void 0 : iconParams.inversed, children: _jsx(Group1Filled, Object.assign({}, iconProps)) }) }));
};
export default BackgroundIcon;
