import Dropdown from 'antd/es/dropdown';
import styled from 'styled-components';
import { createFontSizeCSS } from '../../../theme/global-style';
export const DropdownStyled = styled(Dropdown) `
  width: fit-content;
  padding: 0;
`;
export const DropdownContainer = styled.div `
  width: fit-content;
  padding: 0;
  background: ${({ theme }) => theme.colors.white_1};
  border-radius: 4px;
  overflow:hidden;
  border: 1px solid ${({ theme }) => theme.colors.grey_3};
  box-shadow: ${({ theme }) => theme.colors.box_shadow_hover};
  .ant-dropdown-menu {
    box-shadow: none;
    padding: 0;
    border-radius: 0;
    .ant-dropdown-menu-item {
      background: transparent;
      border-radius: 0;
      padding:8px 16px;
      ${createFontSizeCSS('body3')}
      &.ant-dropdown-menu-item-active {
        background: transparent;
        &:hover{
          background: ${({ theme }) => theme.colors.purple_5};
          
          }
          }
  }
`;
