'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import FiltersDropdown from './filter-dropdown';
import FilterHeader from './filter-header';
import { FilterSectionContainterStyled, FilterSectionStyled } from './styles';
const FundingExplorerFilter = (props) => {
    const [advancedFiltersOpened, setAdvancedFiltersOpen] = useState(false);
    const handleAdvancedFilters = () => setAdvancedFiltersOpen(!advancedFiltersOpened);
    const { beforeFilters, afterFilters, style } = props;
    const dropdownProps = Object.assign({ isOpen: advancedFiltersOpened, handleClose: handleAdvancedFilters }, props);
    return (_jsxs(_Fragment, { children: [!!beforeFilters && (_jsx("div", { style: { marginBottom: 12 }, children: beforeFilters })), _jsxs(FilterSectionContainterStyled, { "$position": "top", style: Object.assign({}, style), children: [_jsx(FilterSectionStyled, { "$position": "top", children: _jsx(FilterHeader, Object.assign({}, props, { advancedFiltersOpened: advancedFiltersOpened, handleAdvancedFilters: handleAdvancedFilters })) }), _jsx(FiltersDropdown, Object.assign({}, dropdownProps))] }), !!afterFilters && _jsx("div", { style: { marginTop: 12 }, children: afterFilters })] }));
};
export default FundingExplorerFilter;
