import notification from 'antd/es/notification';
const handleDispatchResponse = (res, saved, error) => {
    var _a;
    if ((_a = res === null || res === void 0 ? void 0 : res.type) === null || _a === void 0 ? void 0 : _a.includes('fulfilled')) {
        notification.success({
            message: saved,
            placement: 'top',
        });
    }
    else {
        notification.error({
            message: error,
            placement: 'top',
        });
    }
};
export default handleDispatchResponse;
