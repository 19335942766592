export var ProgramStatus;
(function (ProgramStatus) {
    ProgramStatus["Canceled"] = "Canceled";
    ProgramStatus["Rejected"] = "Rejected";
    ProgramStatus["Active"] = "Active";
    ProgramStatus["Completed"] = "Completed";
})(ProgramStatus || (ProgramStatus = {}));
export var MilestoneStatus;
(function (MilestoneStatus) {
    MilestoneStatus["ToSchedule"] = "ToSchedule";
    MilestoneStatus["Scheduled"] = "Scheduled";
    MilestoneStatus["Canceled"] = "Canceled";
    MilestoneStatus["Completed"] = "Completed";
})(MilestoneStatus || (MilestoneStatus = {}));
export var MilestoneTypes;
(function (MilestoneTypes) {
    MilestoneTypes["Internal"] = "Internal";
    MilestoneTypes["Meeting"] = "Meeting";
})(MilestoneTypes || (MilestoneTypes = {}));
export const ProgramInitialState = {
    program_id: '',
};
export var ProgramGrantStatus;
(function (ProgramGrantStatus) {
    ProgramGrantStatus["IdentifiedOpportunities"] = "identified_opportunitites";
    ProgramGrantStatus["ConfirmedOpportunities"] = "confirmed_opportunities";
    ProgramGrantStatus["OngoingInformationRequired"] = "ongoing_information_required";
    ProgramGrantStatus["OngoingWorkByHelloDarwin"] = "ongoing_work_hellodarwin";
    ProgramGrantStatus["ValidationRequired"] = "validation_required";
    ProgramGrantStatus["CompletedToSubmit"] = "completed_to_submit";
    ProgramGrantStatus["SubmittedWaitingForResults"] = "submitted_waiting_for_results";
    ProgramGrantStatus["Accepted"] = "accepted";
    ProgramGrantStatus["Refused"] = "refused";
    ProgramGrantStatus["NotSubmited"] = "not_submited";
    ProgramGrantStatus["OnHoldNeedAssistance"] = "on_hold_need_assistance";
    ProgramGrantStatus["OnHoldPaused"] = "on_hold_paused";
    ProgramGrantStatus["ClosedLost"] = "closed_lost";
})(ProgramGrantStatus || (ProgramGrantStatus = {}));
export var ApplicationResult;
(function (ApplicationResult) {
    ApplicationResult["Pending"] = "pending";
    ApplicationResult["Accepted"] = "accepted";
    ApplicationResult["Refused"] = "refused";
})(ApplicationResult || (ApplicationResult = {}));
export var GrantsRefusalReason;
(function (GrantsRefusalReason) {
    GrantsRefusalReason["NonEligibleApplicant"] = "non-eligible applicant";
    GrantsRefusalReason["NonEligibleProject"] = "non-eligible project";
    GrantsRefusalReason["MissingDocumentation"] = "missing documentation";
    GrantsRefusalReason["AfterDeadline"] = "after_deadline";
    GrantsRefusalReason["NoReasonProvided"] = "no_reason_provided";
})(GrantsRefusalReason || (GrantsRefusalReason = {}));
export var ProgramType;
(function (ProgramType) {
    ProgramType["Consulting"] = "consulting";
    ProgramType["GrantsRoadmap"] = "grants_roadmap";
})(ProgramType || (ProgramType = {}));
export var ProgramSource;
(function (ProgramSource) {
    ProgramSource["AM"] = "AM";
    ProgramSource["Recherchiste"] = "Recherchiste";
    ProgramSource["Client"] = "Client";
})(ProgramSource || (ProgramSource = {}));
