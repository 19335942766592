const formatPhoneNumber = (input) => {
    const [phonePart, ...extensionPart] = input.split(/poste|ext|extension/i);
    const cleaned = ('' + phonePart).replace(/\D/g, '');
    const normalized = cleaned.startsWith('1') && cleaned.length === 11
        ? cleaned.slice(1)
        : cleaned;
    if (normalized.length !== 10) {
        return 'Invalid phone number';
    }
    const match = normalized.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (!match) {
        return 'Invalid phone number';
    }
    const formattedPhone = `(${match[1]}) ${match[2]}-${match[3]}`;
    const extension = extensionPart.join('').trim();
    if (extension) {
        return `${formattedPhone} ext. ${extension}`;
    }
    return formattedPhone;
};
export default formatPhoneNumber;
