import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import ProfileBlocks from '../../../../../features/enums/profile-blocks';
import Avatar from '../../../../common/avatar';
import Div from '../../../../common/div';
import useDarwinLogoFallback from '../../hooks/use-darwin-picture-fallback';
import { AssetOverlay, EditButton } from '../styles';
const DEFAULT_SIZE = 116;
const ProfileLogo = ({ name, isEditable = false, logo, toggleEditModal, style, size = DEFAULT_SIZE, type, }) => {
    const handleEdit = () => !!toggleEditModal &&
        toggleEditModal({ open: true, type: ProfileBlocks.Logo });
    const fallback = useDarwinLogoFallback({ type, size });
    return (_jsxs(Div, { style: Object.assign(Object.assign({}, style), { position: 'relative' }), fitContent: true, children: [_jsx(Avatar, { alt: name, src: `${logo}`, size: size, shape: "square", loading: "eager", style: { borderRadius: 4 }, fallback: fallback, children: name === null || name === void 0 ? void 0 : name[0] }), isEditable && (_jsx(AssetOverlay, { onClick: handleEdit, "$type": "logo", children: _jsx(EditButton, { children: _jsx(Edit, { size: 18 }) }) }))] }));
};
export default ProfileLogo;
