var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Droppable } from 'react-beautiful-dnd';
import styled from '../../../plugins/styled';
const Content = styled.div `
  border: 2px dashed
    ${({ $isDraggingOver, theme }) => $isDraggingOver ? theme.colors.purple_1 : theme.colors.transparent};
`;
const Drop = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (_jsx(Droppable, Object.assign({}, props, { children: (provided, snapshot) => (_jsxs(Content, { ref: provided.innerRef, className: className, "$isDraggingOver": snapshot.isDraggingOver, children: [children, provided.placeholder] })) })));
};
export default Drop;
