import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useProfileEdition } from '../../../../../features/providers/profile-edition-provider';
import { useTranslation } from '../../../../../plugins/i18n';
import Div from '../../../../common/div';
import Uploader from '../../../form-groups/uploader';
import FormItem from '../../../form-layouts/form-item';
import FormLabel from '../../../form-layouts/form-label';
import ProfileAssetsFormFooter from './footer';
import useAssetInfos from './hooks/use-asset-infos';
import usePreviewInfos from './hooks/use-preview-infos';
import ProfileAssetsFormPreview from './preview';
const ProfileAssetsForm = ({ handleCancel, type, }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const { handleFooter } = useProfileEdition();
    const { asset_url, asset_title } = useAssetInfos({ type });
    const { previewTitle, previewUrl } = usePreviewInfos({
        asset_title,
        asset_url,
        fileList,
        form,
    });
    useEffect(() => {
        form.setFieldsValue({
            asset_title: asset_title || '',
            asset_url: asset_url || '',
        });
    }, [asset_title, asset_url, form]);
    useEffect(() => {
        if (['banner', 'logo'].includes(type) && asset_url) {
            const defaultFile = {
                uid: '-1',
                name: asset_url.split('/').pop() || `${type}.file`,
                status: 'done',
                url: asset_url,
            };
            setFileList([defaultFile]);
        }
        else {
            setFileList([]);
        }
    }, [type, asset_url]);
    useEffect(() => {
        handleFooter(_jsx(ProfileAssetsFormFooter, { handleCancel: handleCancel, type: type, fileList: fileList, form: form, asset_url: asset_url }));
    }, [handleCancel, type, fileList, form, asset_url]);
    const handleUploadChange = useCallback((newFileList) => setFileList(newFileList), []);
    const renderFormContent = useMemo(() => {
        if (type === 'video') {
            return (_jsx(Form, { form: form, layout: "vertical", children: _jsxs(Div, { flex: "column", gap: 16, children: [_jsx(FormItem, { name: "asset_title", label: _jsx(FormLabel, { label: t('provider-profile|assets.title') }), children: _jsx(Input, { size: "large", placeholder: t('provider-profile|assets.title') }) }), _jsx(FormItem, { name: "asset_url", label: _jsx(FormLabel, { label: t('provider-profile|assets.url') }), children: _jsx(Input, { size: "large", placeholder: t('provider-profile|assets.url') }) })] }) }));
        }
        return (_jsx(Uploader, { fileList: fileList, name: type, width: type === 'banner' ? 1200 : 200, height: type === 'banner' ? 300 : 200, handleChange: handleUploadChange }));
    }, [type, form, t, fileList, handleUploadChange]);
    return (_jsxs(Div, { flex: "row", justify: "stretch", tablet: { flex: 'column-reverse' }, children: [_jsx(Div, { style: { padding: '15px 20px', flex: 4 }, tablet: { style: { padding: 0 } }, flex: "column", gap: 16, fullHeight: true, children: renderFormContent }), _jsx(ProfileAssetsFormPreview, { previewTitle: previewTitle, previewUrl: previewUrl, type: type })] }));
};
export default ProfileAssetsForm;
