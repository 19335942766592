import { View } from '@react-pdf/renderer';
import { ProjectResponse } from '../../../@types/projects-types';
import Group4Outlined from '../../../components/background/group4-outlined';
import CardWithIcon from '../../../components/cards/tile-with-description-and-icon';
import BudgetIcon from '../../../components/common/icons/budget';
import NotificationsIcon from '../../../components/common/icons/notifications';
import ProjectIcon from '../../../components/common/icons/project';
import TimelineIcon from '../../../components/common/icons/timeline';
import PageHeader from '../../../components/header';
import PrioritiesBoxes from '../../../components/priorities-boxes';
import { createTemplateStyle } from '../../../styles/templates';
import theme from '../../../theme';
import formatDollarValue from '../../../utils/format-dollar-value';
import { GamePlanTranslations } from '../types';
import formatTimeline from '../utils/format-timeline';

export interface ProjectInfosTemplateProps {
  project: ProjectResponse;
  priority: ProjectResponse;
  translations: GamePlanTranslations;
  locale: string;
}

const ProjectInfosTemplate: React.FC<ProjectInfosTemplateProps> = ({
  project,
  priority,
  translations,
  locale,
}) => {
  return (
    <View id={project.project_id}>
      <Group4Outlined
        width={679}
        height={948}
        color={theme.colors.beige_1}
        top={-68}
        margin={-354}
        align="left"
      />
      <PageHeader title={priority.title} elementTheme="h5" textAlign="center" />
      <View
        style={createTemplateStyle({
          type: 'center',
          withFooter: false,
          withHeader: { headerElementTheme: 'h5' },
        })}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',

            height: '100%',
            width: '100%',
          }}
        >
          <CardWithIcon
            icon={{
              Icon: ProjectIcon,
              backgroundColor: theme.colors.green_1,
              color: theme.colors.grey_1,
            }}
            size={'large'}
            direction={'horizontal'}
            label={project.title}
            content={`
              <p>${project.description}</p>
              <br/>
              <br/>
              <p style="text-align:left;padding-bottom:4px;"><b>${translations.associatedGamePlanGrants}</b></p>
              <ul>
              ${project.grants.map((g) => `<li data-href="#${g.programs?.[0].program_id}" data-color="${theme.colors.purple_2}">${g.programs?.[0].is_subsidiary_program ? `${g.grant_display_title} (${g.programs?.[0].parent_company_name || ''})` : g.grant_display_title}</li>`).join('')}
              </ul>`}
            bgColor={theme.colors.beige_2}
            textAlign="left"
          />
          <View style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
            <CardWithIcon
              icon={{
                Icon: NotificationsIcon,
                backgroundColor: theme.colors.yellow_4,
              }}
              size={'small'}
              direction={'horizontal'}
              label={'priority'}
              content={<PrioritiesBoxes currentIndex={project.priority} />}
              spacing={8}
              withLink
            />
            <CardWithIcon
              icon={{
                Icon: TimelineIcon,
                backgroundColor: theme.colors.purple_4,
              }}
              size={'small'}
              direction={'horizontal'}
              label={'timeline'}
              content={formatTimeline(
                project.started_date,
                project.end_date,
                locale,
                translations,
              )}
              spacing={8}
              withLink
            />
            <CardWithIcon
              icon={{
                Icon: BudgetIcon,
                backgroundColor: theme.colors.green_3,
              }}
              size={'small'}
              direction={'horizontal'}
              label={'budget'}
              content={
                project.estimated_budget
                  ? formatDollarValue(
                      project.estimated_budget,
                      locale,
                      true,
                      true,
                    )
                  : ''
              }
              spacing={8}
              withLink
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default ProjectInfosTemplate;
