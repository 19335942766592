import { useMemo } from 'react';
import { usePagination } from '../../../../features/providers/pagination-provider';
const usePrepareFundingExplorerURL = ({ filterform, }) => {
    const { addMultipleParams } = usePagination();
    return useMemo(() => () => {
        const filterValues = filterform.getFieldsValue();
        if (filterValues) {
            const { textQuery, filterBy, industry, subindustry, financingType, status, closingDate, region, service, companySize, annualRevenue, selectedProjects, } = filterValues;
            const prepareParam = (value) => {
                if (value && value.length > 0) {
                    return Array.isArray(value) ? value.join(',') : value;
                }
                else {
                    return undefined;
                }
            };
            const paramsMapping = {
                textQuery: prepareParam(textQuery),
                filterBy: prepareParam(filterBy),
                industry: prepareParam(industry),
                subindustry: prepareParam(subindustry),
                financingType: prepareParam(financingType),
                status: prepareParam(status),
                closingDate: prepareParam(closingDate),
                region: prepareParam(region),
                service: prepareParam(service),
                companySize: prepareParam(companySize),
                annualRevenue: prepareParam(annualRevenue),
                selectedProjects: prepareParam(selectedProjects),
            };
            addMultipleParams(paramsMapping);
        }
    }, [filterform]);
};
export default usePrepareFundingExplorerURL;
