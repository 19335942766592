import { useMemo } from 'react';
import { useTranslations } from '../../../../../features/providers/translations-provider';
const useGetOptions = () => {
    const { t } = useTranslations();
    return useMemo(() => {
        const statusOptions = [
            {
                label: t(`grantsFundingExplorer|grantsgrantCardStatusNew`),
                value: 'new',
            },
            {
                label: t(`grantsFundingExplorer|grantsgrantCardStatusOpeningSoon`),
                value: 'openingSoon',
            },
            {
                label: t(`grantsFundingExplorer|grantsgrantCardStatusOpen`),
                value: 'open',
            },
            {
                label: t(`grantsFundingExplorer|grantsgrantCardStatusClosingSoon`),
                value: 'closingSoon',
            },
            {
                label: t(`grantsFundingExplorer|grantsgrantCardStatusClosed`),
                value: 'closed',
            },
        ];
        const filterOptions = [
            {
                label: t(`grantsFundingExplorer|grantsfundingExplorerFilterFilterByOptionRecommended`),
                value: '',
            },
            {
                label: t(`grantsFundingExplorer|grantsfundingExplorerFilterFilterByOptionLatest`),
                value: 'latest',
            },
            {
                label: t(`grantsFundingExplorer|grantsfundingExplorerFilterFilterByOptionOldest`),
                value: 'oldest',
            },
        ];
        return { statusOptions, filterOptions };
    }, [t]);
};
export default useGetOptions;
