import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import GrantSteps from '@hellodarwin/core/lib/components/grants/single/grant-steps';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Empty from 'antd/es/empty';
import { useEffect } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../../../app';
import { useNewAdminApi } from '../../../../features/api/use-admin-api';
import { GinBlockComponentProps } from '../gin-block';
import {
  fetchGrantStepsForProgram,
  selectAllGrantSteps,
  toggleGrantStepValidation,
} from '../../../../features/api/slices/grant-steps-slice';
import message from 'antd/es/message';

const GrantStepsComponent = ({ entityId, grantId }: GinBlockComponentProps) => {
  const newApi = useNewAdminApi();
  const dispatch = useAppDispatch();
  const grantSteps = useAppSelector((state: RootState) =>
    selectAllGrantSteps(state),
  );
  const { t } = useTranslations();

  useEffect(() => {
    if (!!entityId) {
      !!grantId
        ? dispatch(
            fetchGrantStepsForProgram({
              api: newApi,
              grantId: grantId,
              programId: entityId,
            }),
          )
        : dispatch(
            fetchGrantStepsForProgram({ api: newApi, grantId: entityId }),
          );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, dispatch]);

  const handleChangeStepCheckBox = async (grantStepId: string) => {
    try {
      if (!!entityId && !!grantId) {
        await dispatch(
          toggleGrantStepValidation({
            api: newApi,
            grantId: grantId,
            programId: entityId!,
            grantStepId,
          }),
        );
      }
    } catch (error) {
      message.error(t('singleCompanyPage|grantStepValidationFailed'));
    }
  };

  return (
    <Div flex="column" gap={10}>
      {!!grantSteps.length ? (
        <GrantSteps
          grantSteps={grantSteps}
          onChangeStepCheckBox={handleChangeStepCheckBox}
          canStepsCollapse
        ></GrantSteps>
      ) : (
        <Empty
          description={
            <Typography elementTheme="subtitle1">
              {t('gin_section|empty_step')}
            </Typography>
          }
        />
      )}
    </Div>
  );
};

export default GrantStepsComponent;
