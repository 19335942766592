import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import CancelProject from '@hellodarwin/icons/dist/icons/CancelProject';
import Checkmark from '@hellodarwin/icons/dist/icons/Checkmark';
import Tooltip from 'antd/es/tooltip';

export interface HubspotUserButtonCheckProps {
  crmId: string | undefined;
  type: 'Owner' | 'Account';
}

const HubspotUserButtonCheck: React.FC<HubspotUserButtonCheckProps> = ({
  crmId,
  type,
}) => {
  const theme = useTheme();
  const { t } = useTranslations();
  if (!crmId?.length) {
    return (
      <Div flex="row" fitContent align={'center'}>
        <Tooltip
          title={t(`adminHubspot|adminHubspot${type}NotFound`)}
          color={theme.colors.error}
        >
          <Button
            size="square"
            defaultStyle={theme.colors.red_5}
            headingIcon={<CancelProject size={20} />}
          />
        </Tooltip>
      </Div>
    );
  }

  return (
    <Div flex="row" fitContent align={'center'}>
      <Tooltip
        title={t(`adminHubspot|adminHubspot${type}Exist`)}
        color={theme.colors.green_1}
      >
        <Button
          defaultStyle={theme.colors.green_1}
          size="square"
          headingIcon={<Checkmark size={20} />}
        />
      </Tooltip>
    </Div>
  );
};

export default HubspotUserButtonCheck;
