import message from 'antd/es/message';
import { useMemo } from 'react';
const useOnMultipleFilesUpload = ({ handleSaveAsset, successMessage, errorMessage, }) => {
    return useMemo(() => async (assets, records) => {
        try {
            if (!assets.length)
                return;
            let successed = true;
            for (const assetData of assets) {
                const formData = new FormData();
                formData.append('file', assetData.asset);
                if (assetData.values.record_id) {
                    records.push({
                        record_id: assetData.values.record_id,
                        record_type: assetData.values.record_type,
                    });
                }
                const fileRequest = {
                    file_name: assetData.values.file_name,
                    file_description: assetData.values.file_description,
                    records: records,
                    version: assetData.values.version,
                    asset_id: assetData.values.asset_id,
                };
                formData.append('request', JSON.stringify(fileRequest));
                const resultRequest = await handleSaveAsset(formData);
                if (resultRequest === 'rejected') {
                    successed = false;
                }
            }
            if (successed) {
                message.success(successMessage);
            }
            else {
                message.error(errorMessage);
            }
        }
        catch (e) {
            message.error(errorMessage);
            console.error(e);
        }
    }, [handleSaveAsset, successMessage, errorMessage]);
};
export default useOnMultipleFilesUpload;
