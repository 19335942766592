var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Budget from '@hellodarwin/icons/dist/icons/Budget';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import { useTheme } from 'styled-components';
import dollarFormat from '../../../features/helpers/dollar-format';
import { getStringDateFromString } from '../../../features/helpers/get-formatted-date';
import { getProgramGrantStatusColor } from '../../../features/helpers/get-status-color';
import useLocale from '../../../features/providers/locale-provider';
import { useTranslations } from '../../../features/providers/translations-provider';
import Div from '../../common/div';
import HdTag from '../../common/hd-tag';
import InfoField from '../../common/infoField';
import Typography from '../../common/typography';
import User from '@hellodarwin/icons/dist/icons/User';
import { useEffect, useState } from 'react';
const ProgramSingleHeader = (_a) => {
    var { grant, admin, isLoading } = _a, program = __rest(_a, ["grant", "admin", "isLoading"]);
    const theme = useTheme();
    const { t } = useTranslations();
    const { selectedLocale } = useLocale();
    const [contactNames, setContactNames] = useState('');
    const [contactLabel, setContactLabel] = useState('Contact');
    useEffect(() => {
        var names = '';
        if (!!program.contacts) {
            names = program.contacts.map((contact) => contact.name).join(', ');
            if (program.contacts.length > 1) {
                setContactLabel('Contacts');
            }
            else {
                setContactLabel('Contact');
            }
        }
        setContactNames(names);
    }, [program.contacts]);
    return (_jsxs(Div, { flex: "column", gap: 12, backgroundColor: theme.colors.white_1, borderColor: theme.colors.grey_4, borderRadius: 4, style: { padding: '24px 32px' }, children: [_jsx(HdTag, { text: t(`programs_labels|${program.program_grant_status}`), color: getProgramGrantStatusColor(program.program_grant_status, theme), withBorder: true }), _jsxs(Div, { flex: "column", gap: 6, children: [_jsx(Typography, { elementTheme: "subtitle1", tablet: { textAlign: 'center' }, color: theme.colors.grey_2, children: t('application_single|companyApplication', {
                            company: program.company.name,
                        }) }), _jsx(Typography.Title, { level: 1, elementTheme: "h4", noMargin: true, tablet: { textAlign: 'center' }, children: program.program_name })] }), _jsxs(Div, { flex: "row", gap: 24, align: "center", style: { color: theme.colors.grey_2 }, children: [_jsx(InfoField, { Icon: Timeline, label: "", value: getStringDateFromString(program.program_updated_at, selectedLocale) }), _jsx(Typography, { color: theme.colors.grey_3, children: "|" }), _jsx(InfoField, { Icon: Budget, label: "", value: !!program.program_accepted_amount
                            ? `${dollarFormat(program.program_accepted_amount, selectedLocale, true)}`
                            : !!program.program_application_amount
                                ? `${dollarFormat(program.program_application_amount, selectedLocale, true)}`
                                : !!program.program_targeted_amount
                                    ? `${dollarFormat(program.program_targeted_amount, selectedLocale, true)}`
                                    : !!(grant === null || grant === void 0 ? void 0 : grant.funding_max_amount)
                                        ? `${dollarFormat(grant.funding_max_amount, selectedLocale, true)}`
                                        : dollarFormat(0, selectedLocale, true) }), contactNames !== '' && (_jsxs(_Fragment, { children: [_jsx(Typography, { color: theme.colors.grey_3, children: "|" }), _jsx(InfoField, { Icon: User, label: contactLabel, value: contactNames })] }))] })] }));
};
export default ProgramSingleHeader;
