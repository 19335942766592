import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useTranslations } from '../../../../features/providers/translations-provider';
import SeeMoreList from '../../../common/see-more/see-more-list';
import ProfileSection from '../profile-section';
import ExpertiseCard from './ExpertiseCard';
const ProfileExpertise = ({ provider, isEditable = false, handleRedirectToExpertise, id, }) => {
    const { t } = useTranslations();
    return (_jsx(ProfileSection, { title: t('profile_expertise|expertiseAlternativeTitle', {
            companyName: provider.name,
        }), isEditable: isEditable, handleEdit: handleRedirectToExpertise, id: id, children: (provider === null || provider === void 0 ? void 0 : provider.tags) && (_jsx(SeeMoreList, { content: React.Children.toArray(Object.values(provider === null || provider === void 0 ? void 0 : provider.tags).map((tag) => (_jsx(ExpertiseCard, Object.assign({}, tag), `${tag.category}`)))), limit: 3, grid: { sm: 1, md: 1, lg: 1, column: 1, gutter: 24 } })) }));
};
export default ProfileExpertise;
