import Typography from '@hellodarwin/core/lib/components/common/typography';
import Loading from '@hellodarwin/core/lib/components/loading';
import { Grant } from '@hellodarwin/core/lib/features/entities';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import theme from '@hellodarwin/core/lib/theme';
import { Empty, message } from 'antd';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app';
import {
  assignGrantToCompany,
  fetchCompany,
  removeGrantFromCompany,
  reorderCompanyGrants,
  selectCompanyById,
  updateCompanyGrantTitle,
} from '../../../../features/api/slices/companies-slice';
import { useAdminApi } from '../../../../features/api/use-admin-api';
import CompanyGrantsDrawer from './company-grants-drawer';
import SimpleGrantCard from './simple-grant-card';

export interface CompanyGrantsBlockProps {
  entityId: string;
  isEditing: boolean;
  handleEditing: () => void;
}

const CompanyGrantsBlock = ({
  entityId: companyId,
  isEditing: isDrawerOpen,
  handleEditing: toggleIsDrawerOpen,
}: CompanyGrantsBlockProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { selectedLocale } = useLocale();
  const company = useAppSelector((state) =>
    selectCompanyById(state, companyId),
  );
  const { t } = useTranslations();

  useEffect(() => {
    if (company.grants === undefined) {
      dispatch(
        fetchCompany({
          api,
          companyId,
          locale: selectedLocale,
          withGrants: true,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddGrant = async (grant: Grant) => {
    try {
      await dispatch(
        assignGrantToCompany({
          api,
          company_id: companyId,
          grant_id: grant.grant_id,
          locale: selectedLocale,
        }),
      ).unwrap();
      message.success(t('companyAdminPage|grant_add_success'));
    } catch (error) {
      message.error(t('companyAdminPage|grant_add_fail'));
    }
  };

  const handleRemoveGrant = async (grant: Grant) => {
    try {
      await dispatch(
        removeGrantFromCompany({
          api,
          company_id: companyId,
          grant_id: grant.grant_id,
        }),
      ).unwrap();
      message.success(t('companyAdminPage|grant_remove_success'));
    } catch (error) {
      message.error(t('companyAdminPage|grant_remove_fail'));
    }
  };

  const handleReorderCompanyGrants = async (reorderedItems: Grant[]) => {
    try {
      await dispatch(
        reorderCompanyGrants({
          api,
          companyId,
          reorderedCompanyGrants: reorderedItems.map((grant) => grant.grant_id),
        }),
      ).unwrap();
      message.success(t('companyAdminPage|grant_reorder_success'));
    } catch (error) {
      message.error(t('companyAdminPage|grant_reorder_fail'));
    }
  };

  const handleSaveCompanyGrantTitle = async (
    grantId: string,
    newTitle: string,
  ) => {
    try {
      await dispatch(
        updateCompanyGrantTitle({
          api,
          company_id: companyId,
          grant_id: grantId,
          title: newTitle,
        }),
      ).unwrap();
      message.success(
        t('companyAdminPage|potential_project_title_update_success'),
      );
    } catch (error) {
      message.error(t('companyAdminPage|potential_project_title_update_fail'));
    }
  };

  if (!company || !company.grants) {
    return <Loading />;
  }

  const grants = company.grants;

  return (
    <>
      {grants.length > 0 ? (
        grants.map((grant, index) => (
          <div
            key={grant.grant_id}
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr auto',
              gap: '10px',
              alignItems: 'center',
              borderBottom:
                index !== grants.length - 1
                  ? '1px solid rgba(194, 194, 194, 0.5)'
                  : 'none',
              paddingBottom: '12px',
              marginBottom: index !== grants.length - 1 ? '12px' : '0px',
            }}
          >
            <div>
              <SimpleGrantCard grant={grant} />
            </div>
          </div>
        ))
      ) : (
        <Empty
          description={
            <Typography elementTheme="subtitle2" color={theme.colors.grey_2}>
              {t(`companyAdminPage|no_grant`)}
            </Typography>
          }
        />
      )}

      <CompanyGrantsDrawer
        open={isDrawerOpen}
        handleCancel={toggleIsDrawerOpen}
        companyGrants={grants}
        onAddGrantToCompany={handleAddGrant}
        onRemoveGrantFromCompany={handleRemoveGrant}
        onReorderCompanyGrants={handleReorderCompanyGrants}
        onSaveCompanyGrantTitle={handleSaveCompanyGrantTitle}
      />
    </>
  );
};

export default CompanyGrantsBlock;
