import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

interface NotificationsProps extends IconProps {
  disabled?: boolean;
}
const Notifications: React.FC<NotificationsProps> = ({
  disabled = false,
  ...props
}) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {disabled ? (
        <path
          d="M11.5558 23.8627C13.5397 24.0971 15.5392 24.1739 17.5353 24.0924M11.5558 23.8627C8.65696 23.5203 5.80875 22.8379 3.07022 21.8295C5.21774 19.4576 6.48115 16.4202 6.648 13.2281M11.5558 23.8627C11.1648 25.0251 11.2492 26.2945 11.7907 27.3953C12.3321 28.496 13.2869 29.3392 14.4477 29.7418C15.6085 30.1445 16.8816 30.074 17.9906 29.5457C19.0997 29.0174 19.9551 28.074 20.3711 26.9202M23.4278 23.3862L30 29.9436M23.4278 23.3862C25.2987 23.0095 27.1377 22.489 28.9282 21.8295C26.6095 19.2668 25.3282 15.9356 25.3333 12.4831V11.3191C25.3349 9.18868 24.6043 7.12212 23.2633 5.46419C21.9224 3.80625 20.0522 2.6572 17.9648 2.2087C15.8774 1.76021 13.699 2.03939 11.793 2.99969C9.88694 3.95999 8.36855 5.54333 7.49111 7.48557M23.4278 23.3862L7.49111 7.48557M7.49111 7.48557L2 2.00687"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ) : (
        <path
          d="M21.2079 24.0343C24.1527 23.6856 27.046 22.9907 29.8279 21.9639C27.472 19.3541 26.1702 15.9619 26.1754 12.4461V11.3398V11.2607C26.1754 8.7457 25.1763 6.33367 23.3979 4.55527C21.6195 2.77687 19.2075 1.77777 16.6924 1.77777C14.1774 1.77777 11.7654 2.77687 9.98696 4.55527C8.20856 6.33367 7.20946 8.7457 7.20946 11.2607V12.4461C7.21423 15.9621 5.91186 19.3544 3.55536 21.9639C6.29436 22.9754 9.18192 23.6787 12.177 24.0343M21.2079 24.0343C18.2081 24.3901 15.1768 24.3901 12.177 24.0343M21.2079 24.0343C21.4357 24.7453 21.4923 25.5 21.3732 26.2371C21.2541 26.9741 20.9627 27.6726 20.5226 28.2757C20.0825 28.8788 19.5062 29.3694 18.8406 29.7077C18.1751 30.0459 17.439 30.2222 16.6924 30.2222C15.9458 30.2222 15.2098 30.0459 14.5442 29.7077C13.8787 29.3694 13.3024 28.8788 12.8623 28.2757C12.4222 27.6726 12.1308 26.9741 12.0117 26.2371C11.8926 25.5 11.9492 24.7453 12.177 24.0343"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
};

export default Notifications;
