import SeeMoreSidebarBlock from '@hellodarwin/core/lib/components/common/layout/sidebar-page-layout.tsx/see-more-sidebar-block';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import Loading from '@hellodarwin/core/lib/components/loading';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Empty from 'antd/es/empty';
import { useAppSelector } from '../../../app';
import {
  selectGinApplicationCompany,
  selectGinsIsLoading,
} from '../../../features/api/slices/gins-slice';
import { RelatedLink } from './cards/related-link';

interface RelatedApplicationsListProps {
  applicationId: string;
}

const RelatedApplicationsList = ({
  applicationId,
}: RelatedApplicationsListProps) => {
  const company = useAppSelector(selectGinApplicationCompany);
  const isLoading = useAppSelector((state) =>
    selectGinsIsLoading(state, 'applicationCompany'),
  );
  const filteredApplications = company.company_applications?.filter(
    (application) => application.application_id !== applicationId,
  );

  const { t } = useTranslations();

  if (isLoading) return <Loading />;
  if (!company.company_applications.length)
    return (
      <Empty
        imageStyle={{ width: 100, height: 100, marginInline: 'auto' }}
        style={{ marginInline: 'auto' }}
        description={
          <Typography elementTheme="body3" medium>
            {t('application_single|no_other_company_applications')}
          </Typography>
        }
      />
    );

  return (
    <SeeMoreSidebarBlock
      items={filteredApplications}
      isLoading={isLoading}
      title={t('application_single|other_company_applications')}
      render={(application, index) => (
        <RelatedLink
          key={index}
          label={application.application_name ?? ''}
          to={`/programs/${application.application_id}`}
        />
      )}
      searchableFields={[
        'application_contact_name',
        'application_name',
        'application_program_name',
      ]}
      fallback={
        <Empty
          description={
            <Typography elementTheme="body2" medium>
              {t('application_single|no_other_company_applications')}
            </Typography>
          }
        ></Empty>
      }
    />
  );
};

export default RelatedApplicationsList;
