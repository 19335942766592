import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import VideoPlayer from '../../../../common/VideoPlayer';
import ProfileBanner from '../../../../provider/profile/profile-assets/profile-banner';
import ProfileLogo from '../../../../provider/profile/profile-assets/profile-logo';
import { VideoWrapper } from '../../../../provider/profile/styles';
const ProfileAssetsFormPreview = ({ previewUrl, type, previewTitle, }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (_jsxs(Div, { flex: "column", gap: 8, tablet: { position: 'unset', bordersSide: 'bottom' }, style: {
            position: 'sticky',
            top: 0,
            padding: '15px 20px',
            flex: 2,
        }, bordersSide: "left", borderColor: theme.colors.primary, align: "center", children: [_jsx(Typography, { textAlign: "center", elementTheme: "overline", children: t('provider-profile|assets.preview') }), ['logo'].includes(type) ? (_jsx(ProfileLogo, { logo: previewUrl, size: 200, name: previewTitle, type: "provider" })) : ['banner'].includes(type) ? (_jsx(ProfileBanner, { banner: previewUrl, name: previewTitle })) : (['video'].includes(type) && (_jsxs(VideoWrapper, { children: [_jsx(Div, { className: "video", children: typeof window !== 'undefined' && (_jsx(VideoPlayer, { url: previewUrl, width: "100%", height: '100%', controls: true, light: true, playing: true })) }), _jsx(Typography, { bold: true, className: "video-title", children: previewTitle })] })))] }));
};
export default ProfileAssetsFormPreview;
