import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import isBrowser from '../helpers/is-browser';
export const AppDataContext = React.createContext({
    header: _jsx(_Fragment, {}),
    setHeader: (props) => { },
    navCollapsed: false,
    handleNavCollapse: () => { },
    nav: _jsx(_Fragment, {}),
    setNav: (props) => { },
    bottomNav: _jsx(_Fragment, {}),
    setBottomNav: (props) => { },
    setNavCollapsed: () => { },
    location: undefined,
});
const AppDataProvider = ({ children, defaultNavCollapsed = false, }) => {
    const [header, setHeader] = React.useState([]);
    const [navCollapsed, setNavCollapsed] = React.useState(defaultNavCollapsed);
    const [nav, setNav] = React.useState([]);
    const [bottomNav, setBottomNav] = React.useState([]);
    const location = isBrowser ? window.location : undefined;
    const handleNavCollapse = () => setNavCollapsed(!navCollapsed);
    return (_jsxs(AppDataContext.Provider, { value: {
            header,
            setHeader,
            navCollapsed,
            handleNavCollapse,
            nav,
            setNav,
            bottomNav,
            setBottomNav,
            setNavCollapsed,
            location,
        }, children: [children, _jsx("div", { className: "modal-root" })] }));
};
export const useAppData = () => useContext(AppDataContext);
export default AppDataProvider;
