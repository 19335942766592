import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Empty from 'antd/es/empty';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslations } from '../../../features/providers/translations-provider';
import Pagination from '../pagination';
import { ListItem } from './list-item';
import { ListContainer, ListContent } from './styles';
const List = ({ bordered = false, divider, className, rootClassName, id, layout, styles, dataSource, grid, loading, renderItem, header, footer, extra, renderEmpty, pagination, }) => {
    const { t } = useTranslations();
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState(dataSource);
    const defaultRenderEmpty = () => (_jsx(Empty, { description: t('commonComponents|emptyList') }));
    const ListEmpty = !!renderEmpty ? renderEmpty : defaultRenderEmpty;
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setCurrentPageSize(pageSize);
    };
    const paginationConfig = useMemo(() => {
        const config = {
            total: (pagination === null || pagination === void 0 ? void 0 : pagination.total) || (items === null || items === void 0 ? void 0 : items.length) || 0,
            currentPage: (pagination === null || pagination === void 0 ? void 0 : pagination.currentPage) || currentPage,
            onPageChange: (pagination === null || pagination === void 0 ? void 0 : pagination.onPageChange) || handlePaginationChange,
            pageSize: (pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) || currentPageSize,
            align: (pagination === null || pagination === void 0 ? void 0 : pagination.align) || 'center',
        };
        return config;
    }, [pagination, items, currentPage, currentPageSize]);
    const withPagination = useMemo(() => !!pagination, [pagination, paginationConfig]);
    const slicedDataSource = useMemo(() => {
        var _a, _b, _c, _d, _e;
        return !!withPagination && (items === null || items === void 0 ? void 0 : items.length) > ((_a = paginationConfig.pageSize) !== null && _a !== void 0 ? _a : 0)
            ? items.slice(((_b = paginationConfig.currentPage) !== null && _b !== void 0 ? _b : 0) *
                ((_c = paginationConfig.pageSize) !== null && _c !== void 0 ? _c : 0), (((_d = paginationConfig.currentPage) !== null && _d !== void 0 ? _d : 0) + 1) *
                ((_e = paginationConfig.pageSize) !== null && _e !== void 0 ? _e : 0))
            : items;
    }, [withPagination, paginationConfig, items]);
    useEffect(() => {
        if (!isEqual(items, dataSource)) {
            setIsLoading(true);
            setTimeout(() => {
                setItems(dataSource);
                setTimeout(() => {
                    setIsLoading(false);
                }, 200);
            }, 200);
        }
    }, [dataSource]);
    const classes = classNames(rootClassName, {
        [`fade-out`]: isLoading,
    });
    if (loading)
        return _jsx(_Fragment, {});
    if (isLoading && !(items === null || items === void 0 ? void 0 : items.length))
        return _jsx(_Fragment, {});
    return (_jsxs(ListContainer, { "$isLoading": isLoading, className: classes, style: Object.assign({ width: '100%' }, styles === null || styles === void 0 ? void 0 : styles.container), children: [header, (items === null || items === void 0 ? void 0 : items.length) ? (_jsxs(_Fragment, { children: [_jsx(ListContent, { id: id, className: className, "$layout": layout, "$bordered": bordered, "$divider": divider, style: Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.content), "$grid": grid, children: React.Children.toArray(slicedDataSource === null || slicedDataSource === void 0 ? void 0 : slicedDataSource.map((item, index) => (_jsx(React.Fragment, { children: renderItem ? renderItem(item, index) : JSON.stringify(item) }, `list-item-${index}`)))) }), extra] })) : (_jsx(ListEmpty, {})), withPagination && _jsx(Pagination, Object.assign({}, paginationConfig)), footer] }));
};
List.Item = ListItem;
export default List;
