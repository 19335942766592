import Typography from '@hellodarwin/core/lib/components/common/typography';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  fetchCompanyGamePlanSummary,
  saveCompanyGamePlanSummary,
  selectCompanyGamePlanSummary,
} from '../../../features/api/slices/gameplan-slice';
import { useNewAdminApi } from '../../../features/api/use-admin-api';
import { GinBlockComponentProps } from '../../gins/gin-single/gin-block';
import TextEditor from '../../gins/text-editor';
import { SectionInnerCardDiv } from './section-inner-card';

interface GamePlanSummarySectionProps {
  title?: React.ReactNode;
  isEditing: boolean;
  value: string;
  onChange: (newValue: string) => void;
}

const GamePlanSummarySection = ({
  title,
  isEditing,
  value,
  onChange,
}: GamePlanSummarySectionProps) => {
  const theme = useTheme();
  const { t } = useTranslations();

  const placeholderText = t('companyAdminPage|no_summary_content');

  return (
    <SectionInnerCardDiv
      style={
        isEditing
          ? { minHeight: 152, border: '0px', padding: '0px' }
          : { minHeight: 152 }
      }
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1,
        }}
      >
        {!!title && title !== '' && (
          <Typography
            elementTheme="h6"
            bold
            color={theme.colors.primary}
            style={isEditing ? { marginBottom: 8 } : { marginBottom: 24 }}
          >
            {title}
          </Typography>
        )}
      </div>
      <TextEditor
        content={value}
        onContentChange={onChange}
        isEditMode={isEditing}
        placeholderText={placeholderText}
      />
    </SectionInnerCardDiv>
  );
};

const CompanyGamePlanSummaryBlock = ({
  entityId: companyId,
  isEditing,
  prepareHandleSave,
}: GinBlockComponentProps) => {
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();
  const api = useNewAdminApi();
  const dispatch = useAppDispatch();
  const summary = useAppSelector(selectCompanyGamePlanSummary);

  const [mainValue, setMainValue] = useState('');
  const [todoClientValue, setTodoClientValue] = useState('');
  const [todoHdValue, setTodoHdValue] = useState('');

  useEffect(() => {
    if (summary != null) {
      setMainValue(summary.summary);
      setTodoClientValue(summary.todo_client);
      setTodoHdValue(summary.todo_hd);
    }
  }, [summary, isEditing]);

  useEffect(() => {
    dispatch(fetchCompanyGamePlanSummary({ api, companyId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, selectedLocale]);

  useEffect(() => {
    prepareHandleSave(() => {
      return async () => {
        try {
          await dispatch(
            saveCompanyGamePlanSummary({
              api,
              summary: {
                summary: mainValue,
                todo_client: todoClientValue,
                todo_hd: todoHdValue,
              },
              companyId,
            }),
          );
          message.success(t('companyAdminPage|saved'));
        } catch (e: any) {
          console.error(e);
        }
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    summary,
    companyId,
    prepareHandleSave,
    mainValue,
    todoClientValue,
    todoHdValue,
  ]);

  return (
    <div>
      <GamePlanSummarySection
        isEditing={isEditing}
        value={mainValue}
        onChange={(newValue) => setMainValue(newValue)}
      />
      <div style={{ display: 'flex', gap: 16, marginTop: isEditing ? 24 : 16 }}>
        <div style={{ flex: 1 }}>
          <GamePlanSummarySection
            title={t('companyAdminPage|todo_client')}
            isEditing={isEditing}
            value={todoClientValue}
            onChange={(newValue) => setTodoClientValue(newValue)}
          />
        </div>
        <div style={{ flex: 1 }}>
          <GamePlanSummarySection
            title={t('companyAdminPage|todo_hd')}
            isEditing={isEditing}
            value={todoHdValue}
            onChange={(newValue) => setTodoHdValue(newValue)}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyGamePlanSummaryBlock;
