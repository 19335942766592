import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
import AppFooter from '../app-layout/app-footer';
import BackgroundIcon from './BackgroundIcon';
import { PageBackground, PageContainerStyled, PageContentStyled, } from './styles';
const PageContainer = ({ styles, overflowHidden, children, backgroundColor, containerBackgroundColor, className, noPadding, icons, withoutPaddingRight, withoutPaddingHorizontal, app, noFooter, noGap, }) => {
    const stylingBasics = Object.assign({ width: '100%', background: containerBackgroundColor, position: 'relative', overflow: overflowHidden ? 'hidden' : 'initial', zIndex: 1, height: 'fit-content', margin: '0 auto' }, styles === null || styles === void 0 ? void 0 : styles.container);
    const classes = classNames('PageContainer', {
        [`${className}`]: className,
    });
    return (_jsx("div", { style: stylingBasics, children: _jsxs(PageContainerStyled, { className: classes, style: styles === null || styles === void 0 ? void 0 : styles.content, "$isAdmin": app === 'admin', "$withoutPaddingRight": withoutPaddingRight, "$withoutPaddingHorizontal": withoutPaddingHorizontal, "$withoutPadding": noPadding, "$backgroundColor": backgroundColor, children: [_jsx(PageBackground, { children: React.Children.toArray(icons === null || icons === void 0 ? void 0 : icons.map((iconParams, i) => (_jsx(BackgroundIcon, { iconParams: iconParams }, `icon-${i}-${iconParams.side}`)))) }), _jsx(PageContentStyled, { "$isAdmin": app === 'admin', "$noGap": noGap, style: Object.assign({ position: 'relative', zIndex: 1, width: '100%' }, styles === null || styles === void 0 ? void 0 : styles.content), children: children }), !noFooter && _jsx(AppFooter, {})] }) }));
};
export default PageContainer;
