import {
  CompanyGamePlan,
  CompanyGamePlanSummary,
} from '@hellodarwin/core/lib/features/entities';
import {
  ApiError,
  ApiErrorInitialState,
} from '@hellodarwin/core/lib/features/entities/api-entitites';
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app/app-store';
import AdminApi from '../admin-api';
import SliceRequest from '../slice-request';

type StatusType = 'generic' | 'generateGamePlan' | 'fetchCompanyGamePlan';
type Status = {
  [key in StatusType]: 'idle' | 'pending';
};

const companiesGamePlansAdapter = createEntityAdapter({
  selectId: (model: CompanyGamePlan) => model.company_id,
});

export interface CompaniesGamePlansState {
  status: Status;
  error: ApiError;
  companiesGamePlans: EntityState<CompanyGamePlan, string>;
  summary: CompanyGamePlanSummary | undefined;
}

const initialState: CompaniesGamePlansState = {
  status: {
    generic: 'idle',
    generateGamePlan: 'idle',
    fetchCompanyGamePlan: 'idle',
  },
  error: ApiErrorInitialState,
  companiesGamePlans: companiesGamePlansAdapter.getInitialState(),
  summary: undefined,
};

export const generateGamePlan = SliceRequest<
  CompanyGamePlan,
  { api: AdminApi; formData: FormData }
>('uploadAsset', async ({ api, formData }) => {
  const response = await api.post<CompanyGamePlan>(
    `/gameplan`,
    formData,
    undefined,
    'multipart/form-data',
  );
  return response.data;
});

export const saveCompanyGamePlanSummary = SliceRequest<
  { summary: CompanyGamePlanSummary },
  { api: AdminApi; summary: CompanyGamePlanSummary; companyId: string }
>('saveCompanySummary', async ({ api, summary, companyId }) => {
  const response = await api.put<CompanyGamePlanSummary>(
    `/gameplan/${companyId}/summary`,
    summary,
  );
  return { summary: response.data };
});

export const fetchCompanyGamePlanSummary = SliceRequest<
  CompanyGamePlanSummary,
  { api: AdminApi; companyId: string }
>('fetchCompanyGamePlanSummary', async ({ api, companyId }) => {
  const response = await api.get<CompanyGamePlanSummary>(
    `/gameplan/${companyId}/summary`,
  );
  return response.data;
});

const companiesGamePlansSlice = createSlice({
  name: 'companies-game-plans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(generateGamePlan.pending, (state) => {
      state.status.generateGamePlan = 'pending';
    });
    builder.addCase(generateGamePlan.fulfilled, (state, { payload }) => {
      companiesGamePlansAdapter.upsertOne(state.companiesGamePlans, payload);
      state.status.generateGamePlan = 'idle';
    });
    builder.addCase(generateGamePlan.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;

      state.status.generateGamePlan = 'idle';
    });
    builder.addCase(fetchCompanyGamePlanSummary.pending, (state) => {
      state.status.fetchCompanyGamePlan = 'pending';
    });
    builder.addCase(
      fetchCompanyGamePlanSummary.fulfilled,
      (state, { payload }) => {
        state.summary = payload;
        state.status.fetchCompanyGamePlan = 'idle';
      },
    );
    builder.addCase(
      fetchCompanyGamePlanSummary.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status.fetchCompanyGamePlan = 'idle';
      },
    );

    builder.addCase(saveCompanyGamePlanSummary.pending, (state) => {
      state.status.fetchCompanyGamePlan = 'pending';
    });
    builder.addCase(saveCompanyGamePlanSummary.fulfilled, (state, { meta }) => {
      state.summary = meta.arg.summary;
      state.status.fetchCompanyGamePlan = 'idle';
    });
    builder.addCase(
      saveCompanyGamePlanSummary.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status.fetchCompanyGamePlan = 'idle';
      },
    );
  },
});

export const selectGamePlansIsLoading = createSelector(
  [
    (state: RootState, _?: StatusType) => state.gamePlans.status,
    (_, type?: StatusType) => type,
  ],
  (status, type) => {
    if (!type) {
      return !!Object.keys(status).find((state) => state === 'pending');
    } else {
      return status[type] === 'pending';
    }
  },
);

export const companiesGamePlansReducer = companiesGamePlansSlice.reducer;
export const selectCompanyGamePlanSummary = (state: RootState) =>
  state.gamePlans.summary;
