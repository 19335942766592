const validateAnyType = ({ message, isRequired }) => (rules, value) => {
    const required = isRequired !== null && isRequired !== void 0 ? isRequired : rules.required;
    const activeMessage = message !== null && message !== void 0 ? message : `${rules.message}`;
    if (!required)
        return Promise.resolve();
    switch (typeof value) {
        case 'undefined':
            return Promise.reject(new Error(activeMessage));
        case 'boolean':
        case 'number':
        case 'bigint':
            return Promise.resolve();
        case 'string':
            return !!(value === null || value === void 0 ? void 0 : value.length)
                ? Promise.resolve()
                : Promise.reject(new Error(activeMessage));
        case 'symbol':
        case 'function':
        case 'object':
        default:
            return Promise.reject(new Error(activeMessage));
    }
};
export default validateAnyType;
