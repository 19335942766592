'use client';
import styled, { css } from 'styled-components';
import adjustColor from '../../../features/helpers/adjust-color';
import isColorDark from '../../../features/helpers/is-color-dark';
const getTagTheme = (theme, color) => {
    var _a;
    color = color !== null && color !== void 0 ? color : theme.colors.purple_5;
    const allTagsThemes = {
        [theme.colors.purple_1]: {
            backgroundColor: theme.colors.purple_1,
            borderColor: theme.colors.purple_5,
            textColor: theme.colors.purple_5,
            backgroundColorHover: theme.colors.purple_2,
            borderColorHover: theme.colors.purple_5,
            textColorHover: theme.colors.purple_5,
        },
        [theme.colors.purple_2]: {
            backgroundColor: theme.colors.purple_2,
            borderColor: theme.colors.purple_1,
            textColor: theme.colors.white_1,
            backgroundColorHover: theme.colors.purple_3,
            borderColorHover: theme.colors.purple_1,
            textColorHover: theme.colors.white_1,
        },
        [theme.colors.purple_3]: {
            backgroundColor: theme.colors.purple_3,
            borderColor: theme.colors.purple_2,
            textColor: theme.colors.white_1,
            backgroundColorHover: theme.colors.purple_4,
            borderColorHover: theme.colors.purple_2,
            textColorHover: theme.colors.purple_1,
        },
        [theme.colors.purple_4]: {
            backgroundColor: theme.colors.purple_4,
            borderColor: theme.colors.purple_3,
            textColor: theme.colors.purple_2,
            backgroundColorHover: theme.colors.purple_2,
            borderColorHover: theme.colors.purple_3,
            textColorHover: theme.colors.white_1,
        },
        [theme.colors.purple_5]: {
            backgroundColor: theme.colors.purple_5,
            borderColor: theme.colors.purple_4,
            textColor: theme.colors.purple_1,
            backgroundColorHover: theme.colors.purple_3,
            borderColorHover: theme.colors.purple_4,
            textColorHover: theme.colors.purple_5,
        },
        [theme.colors.yellow_1]: {
            backgroundColor: theme.colors.yellow_1,
            borderColor: theme.colors.yellow_3,
            textColor: theme.colors.primary,
            backgroundColorHover: theme.colors.yellow_2,
            borderColorHover: theme.colors.yellow_3,
            textColorHover: theme.colors.primary,
        },
        [theme.colors.yellow_2]: {
            backgroundColor: theme.colors.yellow_2,
            borderColor: theme.colors.yellow_1,
            textColor: theme.colors.primary,
            backgroundColorHover: theme.colors.yellow_3,
            borderColorHover: theme.colors.yellow_1,
            textColorHover: theme.colors.primary,
        },
        [theme.colors.yellow_3]: {
            backgroundColor: theme.colors.yellow_3,
            borderColor: theme.colors.yellow_2,
            textColor: theme.colors.primary,
            backgroundColorHover: theme.colors.yellow_1,
            borderColorHover: theme.colors.yellow_2,
            textColorHover: theme.colors.primary,
        },
        [theme.colors.yellow_4]: {
            backgroundColor: theme.colors.yellow_4,
            borderColor: theme.colors.yellow_1,
            textColor: theme.colors.grey_2,
            backgroundColorHover: theme.colors.yellow_2,
            borderColorHover: theme.colors.yellow_3,
            textColorHover: theme.colors.primary,
        },
        [theme.colors.grey_1]: {
            backgroundColor: theme.colors.grey_1,
            borderColor: theme.colors.grey_4,
            textColor: theme.colors.white_1,
            backgroundColorHover: theme.colors.grey_2,
            borderColorHover: theme.colors.grey_4,
            textColorHover: theme.colors.white_1,
        },
        [theme.colors.grey_2]: {
            backgroundColor: theme.colors.grey_2,
            borderColor: theme.colors.grey_4,
            textColor: theme.colors.white_1,
            backgroundColorHover: theme.colors.grey_3,
            borderColorHover: theme.colors.grey_4,
            textColorHover: theme.colors.white_1,
        },
        [theme.colors.grey_3]: {
            backgroundColor: theme.colors.grey_3,
            borderColor: theme.colors.grey_1,
            textColor: theme.colors.white_1,
            backgroundColorHover: theme.colors.grey_2,
            borderColorHover: theme.colors.grey_1,
            textColorHover: theme.colors.white_1,
        },
        [theme.colors.grey_4]: {
            backgroundColor: theme.colors.grey_4,
            borderColor: theme.colors.grey_2,
            textColor: theme.colors.grey_1,
            backgroundColorHover: theme.colors.grey_3,
            borderColorHover: theme.colors.grey_2,
            textColorHover: theme.colors.white_1,
        },
        [theme.colors.grey_5]: {
            backgroundColor: theme.colors.grey_5,
            borderColor: theme.colors.grey_3,
            textColor: theme.colors.grey_1,
            backgroundColorHover: theme.colors.grey_4,
            borderColorHover: theme.colors.grey_3,
            textColorHover: theme.colors.grey_1,
        },
        [theme.colors.green_1]: {
            backgroundColor: theme.colors.green_1,
            borderColor: theme.colors.green_4,
            textColor: theme.colors.grey_2,
            backgroundColorHover: theme.colors.green_2,
            borderColorHover: theme.colors.green_4,
            textColorHover: theme.colors.grey_1,
        },
        [theme.colors.green_2]: {
            backgroundColor: theme.colors.green_2,
            borderColor: theme.colors.green_4,
            textColor: theme.colors.grey_2,
            backgroundColorHover: theme.colors.green_1,
            borderColorHover: theme.colors.green_4,
            textColorHover: theme.colors.grey_1,
        },
        [theme.colors.green_3]: {
            backgroundColor: theme.colors.green_3,
            borderColor: theme.colors.green_1,
            textColor: theme.colors.grey_2,
            backgroundColorHover: theme.colors.green_2,
            borderColorHover: theme.colors.green_4,
            textColorHover: theme.colors.grey_1,
        },
        [theme.colors.green_4]: {
            backgroundColor: theme.colors.green_4,
            borderColor: theme.colors.green_1,
            textColor: theme.colors.grey_2,
            backgroundColorHover: theme.colors.green_1,
            borderColorHover: theme.colors.green_4,
            textColorHover: theme.colors.grey_1,
        },
        [theme.colors.blue_1]: {
            backgroundColor: theme.colors.blue_1,
            borderColor: theme.colors.blue_4,
            textColor: theme.colors.blue_4,
            backgroundColorHover: theme.colors.blue_4,
            borderColorHover: theme.colors.blue_1,
            textColorHover: theme.colors.blue_1,
        },
        [theme.colors.blue_2]: {
            backgroundColor: theme.colors.blue_2,
            borderColor: theme.colors.blue_4,
            textColor: theme.colors.blue_4,
            backgroundColorHover: theme.colors.blue_2,
            borderColorHover: theme.colors.blue_4,
            textColorHover: theme.colors.blue_4,
        },
        [theme.colors.blue_3]: {
            backgroundColor: theme.colors.blue_3,
            borderColor: theme.colors.blue_1,
            textColor: theme.colors.blue_1,
            backgroundColorHover: theme.colors.blue_1,
            borderColorHover: theme.colors.blue_4,
            textColorHover: theme.colors.blue_4,
        },
        [theme.colors.blue_4]: {
            backgroundColor: theme.colors.blue_4,
            borderColor: theme.colors.blue_1,
            textColor: theme.colors.blue_1,
            backgroundColorHover: theme.colors.blue_1,
            borderColorHover: theme.colors.blue_4,
            textColorHover: theme.colors.blue_4,
        },
        [theme.colors.orange_1]: {
            backgroundColor: theme.colors.orange_1,
            borderColor: theme.colors.orange_4,
            textColor: theme.colors.orange_4,
            backgroundColorHover: theme.colors.orange_4,
            borderColorHover: theme.colors.orange_1,
            textColorHover: theme.colors.orange_1,
        },
        [theme.colors.orange_2]: {
            backgroundColor: theme.colors.orange_2,
            borderColor: theme.colors.orange_4,
            textColor: theme.colors.orange_4,
            backgroundColorHover: theme.colors.orange_4,
            borderColorHover: theme.colors.orange_2,
            textColorHover: theme.colors.orange_2,
        },
        [theme.colors.orange_3]: {
            backgroundColor: theme.colors.orange_3,
            borderColor: theme.colors.orange_1,
            textColor: theme.colors.grey_1,
            backgroundColorHover: theme.colors.orange_1,
            borderColorHover: theme.colors.orange_4,
            textColorHover: theme.colors.orange_4,
        },
        [theme.colors.orange_4]: {
            backgroundColor: theme.colors.orange_4,
            borderColor: theme.colors.orange_1,
            textColor: theme.colors.orange_1,
            backgroundColorHover: theme.colors.orange_1,
            borderColorHover: theme.colors.orange_4,
            textColorHover: theme.colors.orange_4,
        },
        [theme.colors.red_1]: {
            backgroundColor: theme.colors.red_1,
            borderColor: theme.colors.red_4,
            textColor: theme.colors.white_1,
            backgroundColorHover: theme.colors.red_4,
            borderColorHover: theme.colors.red_1,
            textColorHover: theme.colors.grey_1,
        },
        [theme.colors.red_2]: {
            backgroundColor: theme.colors.red_2,
            borderColor: theme.colors.red_4,
            textColor: theme.colors.white_1,
            backgroundColorHover: theme.colors.red_4,
            borderColorHover: theme.colors.red_2,
            textColorHover: theme.colors.grey_1,
        },
        [theme.colors.red_3]: {
            backgroundColor: theme.colors.red_3,
            borderColor: theme.colors.red_1,
            textColor: theme.colors.white_1,
            backgroundColorHover: theme.colors.red_5,
            borderColorHover: theme.colors.red_3,
            textColorHover: theme.colors.grey_1,
        },
        [theme.colors.red_4]: {
            backgroundColor: theme.colors.red_4,
            borderColor: theme.colors.red_1,
            textColor: theme.colors.white_1,
            backgroundColorHover: theme.colors.red_5,
            borderColorHover: theme.colors.red_1,
            textColorHover: theme.colors.grey_1,
        },
        [theme.colors.red_5]: {
            backgroundColor: theme.colors.red_5,
            borderColor: theme.colors.red_1,
            textColor: theme.colors.grey_2,
            backgroundColorHover: theme.colors.red_1,
            borderColorHover: theme.colors.red_5,
            textColorHover: theme.colors.white_1,
        },
        [theme.colors.beige_1]: {
            backgroundColor: theme.colors.beige_1,
            borderColor: theme.colors.beige_3,
            textColor: theme.colors.grey_1,
            backgroundColorHover: theme.colors.beige_2,
            borderColorHover: theme.colors.beige_3,
            textColorHover: theme.colors.grey_1,
        },
        [theme.colors.beige_2]: {
            backgroundColor: theme.colors.beige_2,
            borderColor: theme.colors.beige_1,
            textColor: theme.colors.grey_1,
            backgroundColorHover: theme.colors.beige_3,
            borderColorHover: theme.colors.beige_1,
            textColorHover: theme.colors.grey_1,
        },
        [theme.colors.beige_3]: {
            backgroundColor: theme.colors.beige_3,
            borderColor: theme.colors.beige_2,
            textColor: theme.colors.grey_1,
            backgroundColorHover: theme.colors.beige_4,
            borderColorHover: theme.colors.beige_2,
            textColorHover: theme.colors.grey_1,
        },
        [theme.colors.beige_4]: {
            backgroundColor: theme.colors.beige_4,
            borderColor: theme.colors.beige_3,
            textColor: theme.colors.grey_1,
            backgroundColorHover: theme.colors.beige_1,
            borderColorHover: theme.colors.beige_3,
            textColorHover: theme.colors.grey_1,
        },
        [theme.colors.transparent]: {
            backgroundColor: theme.colors.transparent,
            borderColor: theme.colors.transparent,
            textColor: theme.colors.grey_1,
            backgroundColorHover: theme.colors.transparent,
            borderColorHover: theme.colors.transparent,
            textColorHover: theme.colors.grey_2,
        },
    };
    if (color) {
        return ((_a = allTagsThemes[color]) !== null && _a !== void 0 ? _a : {
            backgroundColor: color,
            borderColor: adjustColor(color, -40),
            textColor: isColorDark(color)
                ? theme.colors.white_1
                : theme.colors.grey_1,
            backgroundColorHover: adjustColor(color, 40),
            borderColorHover: adjustColor(color, -20),
            textColorHover: isColorDark(adjustColor(color, 40))
                ? theme.colors.white_1
                : theme.colors.grey_1,
        });
    }
    return allTagsThemes[theme.colors.purple_1];
};
const getColorStyling = ({ type, theme, $color, $highContrastText, $withBorder, }) => {
    var _a;
    const tagTheme = (_a = getTagTheme(theme, $color)) !== null && _a !== void 0 ? _a : getTagTheme(theme, theme.colors.purple_4);
    const borderColor = $withBorder
        ? type === 'normal'
            ? tagTheme.borderColor
            : tagTheme.borderColorHover
        : type === 'normal'
            ? tagTheme.backgroundColor
            : tagTheme.backgroundColorHover;
    const textColor = $highContrastText
        ? theme.colors.grey_2
        : type === 'normal'
            ? tagTheme.textColor
            : tagTheme.textColorHover;
    return css `
    color: ${textColor};
    background: ${type === 'normal'
        ? tagTheme.backgroundColor
        : tagTheme.backgroundColorHover};
    border: 1px solid ${borderColor};
    svg {
      stroke: ${textColor};
    }
  `;
};
const hoverableStyling = css `
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};
  &:hover {
    cursor: pointer;
    transform: translateY(-2px);
    box-shadow: ${({ theme }) => theme.appearance.box_shadow_hover};
    ${({ theme, $color, $highContrastText, $withBorder }) => getColorStyling({
    type: 'hover',
    theme,
    $color,
    $highContrastText,
    $withBorder,
})};
  }
`;
const smallStyling = css `
  padding: 3px 8px;
  gap: 4px;
  font-size: 10px;
`;
const mediumStyling = css `
  padding: 5px 11px;
  gap: 6px;
  font-size: 11px;
`;
const largeStyling = css `
  padding: 8px 14px;
  gap: 6px;
  font-size: 13px;
`;
export const StyledTag = styled.div `
  border-radius: ${(props) => (props.$shape === 'square' ? '4px' : '24px')};
  display: inline-flex;
  align-items: center;
  gap: 6px;
  max-width: 100%;
  overflow: hidden;
  font-weight: 500;
  letter-spacing: 0.3px;
  width: fit-content;
  max-width: 100%;
  line-height: 1.1;
  text-transform: uppercase;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${({ theme, $color, $highContrastText, $withBorder }) => getColorStyling({
    type: 'normal',
    theme,
    $color,
    $highContrastText,
    $withBorder,
})}
  ${({ $hoverable }) => ($hoverable ? hoverableStyling : undefined)}
  ${(props) => props.$size === 'small'
    ? smallStyling
    : props.$size === 'large'
        ? largeStyling
        : mediumStyling}
`;
export const TagsListContainer = styled.div `
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
`;
