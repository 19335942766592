import Button from '@hellodarwin/core/lib/components/common/button';
import SeeMoreSidebarBlock from '@hellodarwin/core/lib/components/common/layout/sidebar-page-layout.tsx/see-more-sidebar-block';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import FormItemEditList from '@hellodarwin/core/lib/components/forms/form-builder/controllers/group/form-edit-list';
import FormItem from '@hellodarwin/core/lib/components/forms/form-layouts/form-item';
import FormLabel from '@hellodarwin/core/lib/components/forms/form-layouts/form-label';
import {
  GinInterviewGuide,
  InitialGinInterviewGuideValue,
} from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import theme from '@hellodarwin/core/lib/theme';
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Drawer from 'antd/es/drawer';
import Empty from 'antd/es/empty';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  createGinInterviewGuide,
  deleteGinInterviewGuide,
  selectGinsIsLoading,
  updateGinInterviewGuide,
} from '../../../features/api/slices/gins-slice';
import { selectAuthentifiedUser } from '../../../features/api/slices/global-slice';
import { useNewAdminApi } from '../../../features/api/use-admin-api';
import { RelatedLink } from './cards/related-link';

interface GinSidebarInterviewGuideProps {
  grant_id: string;
  open: boolean;
  onClose: (bool: boolean) => void;
  interviewGuides: GinInterviewGuide[];
}
const GinSidebarInterviewGuide = ({
  grant_id,
  open,
  onClose,
  interviewGuides,
}: GinSidebarInterviewGuideProps) => {
  const { t } = useTranslations();
  const dispatch = useAppDispatch();
  const api = useNewAdminApi();
  const [active, setActive] = useState<GinInterviewGuide | undefined>(
    undefined,
  );
  const isLoading = useAppSelector((state) =>
    selectGinsIsLoading(state, 'interviewGuides'),
  );
  const user = useAppSelector(selectAuthentifiedUser);

  const handleSubmit = async (interviewGuide: GinInterviewGuide) => {
    try {
      let response: GinInterviewGuide;
      interviewGuide.grant_id = grant_id;
      interviewGuide.admin_id = user.admin_id;
      if (interviewGuide.gin_interview_guide_id === '') {
        response = await dispatch(
          createGinInterviewGuide({ api, interviewGuide }),
        ).unwrap();
        message.success(t(`gin_program|interview_guide_added`));
      } else {
        response = await dispatch(
          updateGinInterviewGuide({ api, interviewGuide }),
        ).unwrap();
        message.success(t(`gin_program|interview_guide_updated`));
      }
      setActive(undefined);
      return response;
    } catch (err: any) {
      message.error(t('gin_program|interview_guide_adding_error'));
      console.error(err);
    }
    return interviewGuide;
  };

  const handleDelete = async (interviewGuide: GinInterviewGuide) => {
    try {
      const response = await dispatch(
        deleteGinInterviewGuide({
          api,
          gin_interview_guide_id: interviewGuide.gin_interview_guide_id,
        }),
      ).unwrap();
      message.success(t(`gin_program|interview_guide_deleted`));
      return response;
    } catch (error) {
      message.error(t(`gin_program|interview_guide_deleted_error`));
      console.error(error);
    }
    return interviewGuide;
  };

  return (
    <>
      <SeeMoreSidebarBlock
        title={t('gin_program|interview_guides')}
        items={interviewGuides}
        isLoading={isLoading}
        searchableFields={['name']}
        render={(itm, index) => {
          const url =
            itm.link.startsWith('http://') || itm.link.startsWith('https://')
              ? itm.link
              : `https://${itm.link}`;
          return (
            <RelatedLink
              key={index}
              label={itm.name ? itm.name : url}
              to={url}
            />
          );
        }}
        fallback={
          <Empty
            imageStyle={{ width: 100, height: 100, marginInline: 'auto' }}
            style={{ marginInline: 'auto' }}
            description={
              <Typography elementTheme="body3" medium>
                {t('gin_program|noRelatedLinks')}
              </Typography>
            }
          />
        }
      />
      <Drawer
        open={open}
        onClose={() => {
          onClose(!open);
        }}
        title={
          <Typography elementTheme="subtitle2">
            {t(`gin_program|interview_guides`)}
          </Typography>
        }
      >
        <FormItem
          name={`gin_interview_guides`}
          layout="vertical"
          label={
            <FormLabel
              label={t(`gin_program|interview_guides`)}
              actions={
                <Button
                  transparent
                  defaultStyle={theme.colors.primary}
                  onClick={() => {
                    setActive({ ...InitialGinInterviewGuideValue });
                  }}
                >
                  <AddNew size={16} />
                </Button>
              }
            />
          }
        >
          <FormItemEditList<GinInterviewGuide>
            typeLabel={t(`gin_program|interview_guides`)}
            options={interviewGuides ?? []}
            optionLabel="name"
            activeElement={active}
            setActiveElement={setActive}
            update={handleSubmit}
            deleteItem={handleDelete}
            inputs={[
              {
                label: t(`gin_program|interview_guide_name`),
                name: 'name',
                children: <Input />,
                rules: [
                  {
                    required: true,
                    message: t(`gin_program|interview_guide_name_required`),
                  },
                ],
              },
              {
                label: t(`gin_program|interview_guide_link`),
                name: 'link',
                children: <Input />,
                rules: [
                  {
                    required: true,
                    message: t(`gin_program|interview_guide_link_required`),
                  },
                ],
              },
            ]}
          />
        </FormItem>
      </Drawer>
    </>
  );
};

export default GinSidebarInterviewGuide;
