import { ProgramStatus } from '../../../features/entities/programs-entities';
const getGrantStatusTagTheme = (theme, status) => {
    switch (status) {
        case ProgramStatus.Completed:
        case 'open':
            return {
                tagColor: '#e6fff7',
                svgBackground: '#00f0af',
                svgBorder: '#a8ffde',
            };
        case ProgramStatus.Active:
        case 'openingSoon':
            return {
                tagColor: theme.colors.purple_4,
                svgBackground: theme.colors.purple_3,
                svgBorder: theme.colors.purple_2,
            };
        case 'closed':
            return {
                tagColor: theme.colors.grey_5,
                svgBackground: theme.colors.grey_4,
                svgBorder: theme.colors.grey_3,
            };
        case ProgramStatus.Rejected:
        case ProgramStatus.Canceled:
        case 'closingSoon':
            return {
                tagColor: theme.colors.red_5,
                svgBackground: theme.colors.error,
                svgBorder: theme.colors.red_3,
            };
        case 'new':
            return {
                tagColor: theme.colors.transparent,
                svgBackground: theme.colors.red_1,
                svgBorder: theme.colors.transparent,
            };
        case 'suspending':
            return {
                tagColor: theme.colors.yellow_4,
                svgBackground: theme.colors.yellow_2,
                svgBorder: theme.colors.yellow_1,
            };
        case ProgramStatus.Active:
            return {
                tagColor: theme.colors.orange_1,
                svgBackground: theme.colors.red_1,
                svgBorder: theme.colors.transparent,
            };
        case 'featured':
        default:
            return {
                tagColor: theme.colors.purple_5,
                svgBackground: 'transparent',
                svgBorder: 'transparent',
            };
    }
};
export default getGrantStatusTagTheme;
