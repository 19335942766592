import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useNavigate } from '../../../../features/providers/location-provider';
import { BreadcrumbButton, BreadcrumbText } from './styles';
const useBreadcrumbRender = () => {
    const navigate = useNavigate();
    return useMemo(() => (route, params, routes, paths) => {
        const last = routes.indexOf(route) === routes.length - 1;
        return last ? (_jsx(BreadcrumbText, { children: route.breadcrumbName })) : route.onClick ? (_jsx(BreadcrumbButton, { onClick: route.onClick, children: route.breadcrumbName })) : (!!route.path && (_jsx(BreadcrumbButton, { onClick: () => navigate(`${route.path}`), children: route.breadcrumbName })));
    }, [navigate]);
};
export default useBreadcrumbRender;
