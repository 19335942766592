import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import Checkbox from 'antd/es/checkbox';
import DatePicker from 'antd/es/date-picker';
import Input from 'antd/es/input';
import TextArea from 'antd/es/input/TextArea';
import Radio from 'antd/es/radio';
import Select from 'antd/es/select';
import Space from 'antd/es/space';
import React from 'react';
import parse from '../../../features/helpers/parse';
import useLocale from '../../../features/providers/locale-provider';
import { itemDescriptionOptions } from '../form-builder/utils/form-parse-options';
import FileUploader from '../form-groups/uploader/file';
import FormItem from '../form-layouts/form-item';
import FormLabel from '../form-layouts/form-label';
import { getSelectOptions } from './utils';
const FormInput = (props) => {
    var _a, _b, _c;
    const { formItem } = props;
    const { selectedLocale } = useLocale();
    const options = formItem.form_select_options
        ? getSelectOptions(formItem.form_select_options, selectedLocale)
        : [];
    return (_jsx(FormItem, { label: _jsx(FormLabel, { label: formItem.content, extra: !!formItem.heading &&
                parse(formItem.heading, itemDescriptionOptions) }), style: { maxWidth: '100%', flex: 1 }, name: formItem.form_item_id, rules: [
            {
                required: formItem.required === 'required',
                message: 'This field is required',
            },
        ], children: formItem.type === 'string' ? (formItem.field_type === 'text' ? (_jsx(TextArea, {})) : (_jsx(Input, {}))) : formItem.type === 'datetime' ? (formItem.field_type === 'date' ? (_jsx(DatePicker, { picker: "date" })) : (_jsx(DatePicker, { picker: "time" }))) : formItem.type === 'enum' ? (formItem.field_type === 'select' ? (_jsx(Select, { style: { width: 400, maxWidth: '100%' }, options: options })) : formItem.field_type === 'radio' ? (_jsx(Radio.Group, { children: _jsx(Space, { direction: formItem.disposition === 'vertical' ? 'vertical' : 'horizontal', children: React.Children.toArray(options.map((option) => (_jsx(Radio, { value: option.value, children: option.label })))) }) })) : formItem.field_type === 'checkbox' ? (_jsx(Checkbox.Group, { children: _jsx(Space, { direction: formItem.disposition === 'vertical' ? 'vertical' : 'horizontal', children: React.Children.toArray(options.map((option) => (_jsx(Checkbox, { value: option.value, children: option.label })))) }) })) : (_jsx(_Fragment, {}))) : formItem.type === 'file' ? (formItem.field_type === 'attachment' ? (_jsx(FileUploader, { fileList: ((_b = (_a = props.formAssetList) === null || _a === void 0 ? void 0 : _a.find((asset) => asset.form_item_id === formItem.form_item_id)) === null || _b === void 0 ? void 0 : _b.assets) || [], name: (_c = formItem.heading) !== null && _c !== void 0 ? _c : '', handleChange: (newFiles) => {
                if (props.setFormAssetList) {
                    props.setFormAssetList((prev) => {
                        var _a, _b;
                        return [
                            ...prev.filter((asset) => asset.form_item_id !== formItem.form_item_id),
                            {
                                form_item_id: formItem.form_item_id,
                                form_item_label: (_b = (_a = formItem.content) !== null && _a !== void 0 ? _a : formItem.heading) !== null && _b !== void 0 ? _b : formItem.form_item_id,
                                assets: newFiles,
                            },
                        ];
                    });
                }
            } })) : (_jsx(_Fragment, {}))) : (_jsx(_Fragment, {})) }));
};
export default FormInput;
