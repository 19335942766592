import { jsx as _jsx } from "react/jsx-runtime";
import useMediaQuery from '../../../features/helpers/use-media-query';
import { useAppData } from '../../../features/providers/app-data-provider';
import { useLocation } from '../../../features/providers/location-provider';
import theme from '../../../theme';
import DesktopNavigation from './desktop-navigation';
import MobileNavigation from './mobile-navigation';
import { ArrowOutlineStyled, NavSider, TriggerStyled } from './styles';
const NavigationSider = ({ links, app, userLinks, handleLanguageChange, profile, defaultOpen, }) => {
    const { navCollapsed, handleNavCollapse } = useAppData();
    const { pathname } = useLocation();
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    return isTablet ? (_jsx(MobileNavigation, { links: links, pathname: pathname, userLinks: userLinks, profile: profile })) : (_jsx(NavSider, { "$app": app, className: app !== 'provider' ? 'ant-layout-sider-light' : '', collapsible: true, collapsed: navCollapsed, onCollapse: handleNavCollapse, width: theme.appearance.sidebarWidth, collapsedWidth: theme.appearance.sidebarWidthCollapsed, style: { top: 0, position: 'sticky', height: '100vh' }, theme: app === 'provider' ? 'dark' : 'light', trigger: _jsx(TriggerStyled, { children: _jsx(ArrowOutlineStyled, { size: 24, style: {
                    transform: navCollapsed ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 500ms linear',
                } }) }), children: _jsx(DesktopNavigation, { links: links, handleLanguageChange: handleLanguageChange, defaultOpen: defaultOpen }) }));
};
export default NavigationSider;
