export const getShortAddress = ({ street_number, subpremise, route, }) => {
    let newAddressString = `${!!street_number ? `${street_number}${!!route ? ` ${route}` : ''}` : ''}`;
    newAddressString += (!!street_number || !!route) && subpremise ? ', ' : '';
    newAddressString += !!subpremise ? `${subpremise}` : '';
    return newAddressString;
};
export const getLongAddress = (shortAddress, { city, province, postal_code, country }) => {
    let newAddressString = shortAddress;
    newAddressString += city ? `, ${city}` : '';
    newAddressString += province ? `, ${province}` : '';
    newAddressString += postal_code ? `, ${postal_code}` : '';
    newAddressString += country ? `, ${country}` : '';
    return newAddressString;
};
