import { useMemo } from 'react';
import { useCompanyInfos } from '../../../../../../features/providers/company-infos-provider';
const useAssetInfos = ({ type }) => {
    const { company } = useCompanyInfos();
    return useMemo(() => {
        var _a, _b, _c, _d;
        let asset_url = '';
        let asset_title = '';
        switch (type) {
            case 'video':
                asset_url = ((_b = (_a = company === null || company === void 0 ? void 0 : company.assets) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.asset_url) || '';
                asset_title = ((_d = (_c = company === null || company === void 0 ? void 0 : company.assets) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.asset_title) || '';
                break;
            case 'logo':
                asset_url = company.logo;
                break;
            case 'banner':
                asset_url = company.banner;
                break;
        }
        return { asset_url, asset_title };
    }, [company, type]);
};
export default useAssetInfos;
