import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Email from '@hellodarwin/icons/dist/icons/Email';
import Location from '@hellodarwin/icons/dist/icons/Location';
import Phone from '@hellodarwin/icons/dist/icons/Phone';
import Div from '../../../../common/div';
import InfoTile from './info-tile';
const GrantContact = ({ application_email_address, application_phone_number, }) => {
    return (_jsxs(Div, { flex: "column", gap: 24, children: [_jsx(InfoTile, { Icon: Email, value: application_email_address }), _jsx(InfoTile, { Icon: Phone, value: application_phone_number }), _jsx(InfoTile, { Icon: Location })] }));
};
export default GrantContact;
