import styled, { css } from 'styled-components';
import { media } from '../../../theme/mixins/responsive';
export const AdminCardCover = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding-inline: 24px;
  padding-block-start: 24px;
  padding-block-end: 24px;
  gap: 8px;
`;
export const AdminCardBody = styled.div `
  padding-inline: 24px;
  padding-block: 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  justify-content: center;
`;
const AdminCardVerticalLayout = css `
  display: flex;
  flex-direction: column;
  ${AdminCardCover} {
    width: 100%;
    padding-block-end: 0;
  }
  ${AdminCardBody} {
    width: 100%;
    padding-block-start: 16px;
    align-items: center;
    text-align: center;
  }
`;
const AdminCardHorizontalLayout = css `
  display: flex;
  ${AdminCardCover} {
    border-right: 1px solid ${({ theme }) => theme.colors.grey_1};
    width: 250px;
  }
  ${AdminCardBody} {
    text-align: left;
  }
`;
export const AdminCardContainer = styled.div `
  ${({ $layout }) => $layout === 'vertical'
    ? AdminCardVerticalLayout
    : AdminCardHorizontalLayout};

  min-height: 250px;
  max-width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '900px')};
  height: ${({ $fullHeight }) => ($fullHeight ? '100%' : 'auto')};
  align-items: stretch;
  background-color: ${({ theme }) => theme.colors.beige_2};
  border: 1px solid ${({ theme }) => theme.colors.grey_1};
  ${media.tablet `
  ${AdminCardVerticalLayout}`}
`;
