const getItemTypeFromItemFieldType = (field_type) => {
    switch (field_type) {
        case 'select':
        case 'radio':
        case 'checkbox':
            return 'enum';
        case 'attachment':
            return 'file';
        case 'date':
            return 'datetime';
        case 'text':
        case 'shortText':
        default:
            return 'string';
    }
};
export default getItemTypeFromItemFieldType;
