import { jsx as _jsx } from "react/jsx-runtime";
import HdLogo from '@hellodarwin/icons/dist/icons/HdLogo';
import Image from 'antd/es/image';
import { PortfolioAssetsType, } from '../../../../../features/entities/provider-portfolios-entities';
import { useTranslation } from '../../../../../plugins/i18n';
import VideoPlayer from '../../../../common/VideoPlayer';
const PortfolioThumbnail = ({ portfolio, }) => {
    var _a, _b, _c, _d, _e, _f;
    const thumbnailIndex = (_a = portfolio === null || portfolio === void 0 ? void 0 : portfolio.assets) === null || _a === void 0 ? void 0 : _a.findIndex((a) => a.asset_type === PortfolioAssetsType.Image);
    const thumbnail = (_b = portfolio === null || portfolio === void 0 ? void 0 : portfolio.assets) === null || _b === void 0 ? void 0 : _b[thumbnailIndex];
    const { t: translateTag } = useTranslation(['tags'], { useSuspense: false });
    if (!((_c = portfolio === null || portfolio === void 0 ? void 0 : portfolio.assets) === null || _c === void 0 ? void 0 : _c.length) || !!thumbnail) {
        return (_jsx(Image, { src: thumbnail === null || thumbnail === void 0 ? void 0 : thumbnail.asset_url, alt: !!thumbnail
                ? `${translateTag(`tags|${portfolio.service}`)} - ${portfolio.portfolio_title}`
                : '', fallback: "https://images.ctfassets.net/uqnk3k6tiu47/yNnmq3QdK9QayVnVQ401X/a18c2a2766d8063e91fbd62640cdcd87/logo-compact-large.jpg", style: { overflow: 'hidden' }, placeholder: _jsx(HdLogo, { backgroundType: "dark", type: "tertiary" }), preview: false, loading: "lazy" }));
    }
    else if (!!((_d = portfolio === null || portfolio === void 0 ? void 0 : portfolio.assets) === null || _d === void 0 ? void 0 : _d[0]) && typeof window !== 'undefined') {
        return (_jsx(VideoPlayer, { url: (_f = (_e = portfolio === null || portfolio === void 0 ? void 0 : portfolio.assets) === null || _e === void 0 ? void 0 : _e[0].asset_url) !== null && _f !== void 0 ? _f : '', controls: true, light: true }));
    }
    else {
        return;
    }
};
export default PortfolioThumbnail;
