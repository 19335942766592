import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from 'styled-components';
import Div from '../../common/div';
import Typography from '../../common/typography';
const IconSize = 18;
const BusinessProfileInfoRow = ({ Icon, label, value, }) => {
    const theme = useTheme();
    if (!value)
        return _jsx(_Fragment, {});
    return (_jsxs(Div, { flex: "column", gap: 8, children: [_jsxs(Div, { flex: "row", gap: 8, align: "center", children: [_jsx(Icon, { size: IconSize, color: theme.colors.grey_2 }), _jsx(Typography, { elementTheme: "overline", color: theme.colors.grey_2, children: label })] }), _jsx(Typography, { style: { paddingInlineStart: IconSize + 8 }, children: value })] }));
};
export default BusinessProfileInfoRow;
