import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getShortId } from '../../../../../features/helpers';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import { pageDescriptionParseOptions } from '../../utils/form-parse-options';
import useEndPageIcon from '../../utils/get-end-page-icon';
import getItemTranslatedLabel from '../../utils/get-item-translated-label';
import FormPageActions from './form-page-actions';
const FormPageHeader = ({ page, isEndPage, isOpeningPage, formPageId, functions, locale, }) => {
    var _a;
    const [EndIcon, endIconColor] = useEndPageIcon((_a = page.page_type) !== null && _a !== void 0 ? _a : '');
    return (_jsxs(Div, { gap: 16, flex: isEndPage || isOpeningPage ? 'column-reverse' : 'row', align: isEndPage ? 'flex-start' : 'center', justify: "space-between", style: { padding: 24, flex: 1 }, children: [_jsxs(Div, { flex: "column", gap: isOpeningPage ? 32 : isEndPage ? 16 : 8, align: isEndPage || isOpeningPage ? 'center' : 'flex-start', children: [isEndPage && !!EndIcon && (_jsx(EndIcon, { width: 64, height: 64, color: endIconColor })), _jsx(Typography.Title, { level: 3, elementTheme: isOpeningPage ? 'h2' : isEndPage ? 'h4' : 'h6', textAlign: isEndPage || isOpeningPage ? 'center' : 'left', noMargin: true, children: getItemTranslatedLabel({
                            label_fr: page.page_title_fr,
                            label_en: page.page_title_en,
                            fallback: getShortId(page.form_page_id),
                            locale,
                        }) }), ((!!(page === null || page === void 0 ? void 0 : page.page_heading_en) && locale === 'en') ||
                        (!!(page === null || page === void 0 ? void 0 : page.page_heading_fr) && locale === 'fr')) && (_jsx(Typography, { elementTheme: isEndPage || isOpeningPage ? 'body1' : 'body2', textAlign: isEndPage || isOpeningPage ? 'center' : 'left', children: getItemTranslatedLabel({
                            label_fr: page.page_heading_fr,
                            label_en: page.page_heading_en,
                            fallback: getShortId(page.form_page_id),
                            parseOptions: pageDescriptionParseOptions,
                            locale,
                        }) }))] }), _jsx(FormPageActions, { formPageId: formPageId, page: page, functions: functions, isEndPage: isEndPage, isOpeningPage: isOpeningPage })] }));
};
export default FormPageHeader;
