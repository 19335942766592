import Button from '@hellodarwin/core/lib/components/common/button';
import Loading from '@hellodarwin/core/lib/components/loading';
import { GrantResult } from '@hellodarwin/core/lib/features/entities';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import { Drawer, Input, List } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app';
import {
  searchGrants,
  selectAllSearchedGrants,
  selectIsSearchGrantsLoading,
} from '../../../../features/api/slices/grants-slice';
import { useNewAdminApi } from '../../../../features/api/use-admin-api';

interface GrantSearchDrawerProps {
  open: boolean;
  onClose: () => void;
  onAddGrant: (grant: GrantResult) => void;
  companyGrants: GrantResult[];
}

const GrantSearchDrawer: React.FC<GrantSearchDrawerProps> = ({
  open,
  onClose,
  onAddGrant,
  companyGrants,
}) => {
  const dispatch = useAppDispatch();
  const api = useNewAdminApi();
  const searchedGrants = useSelector(selectAllSearchedGrants);
  const isLoading = useSelector(selectIsSearchGrantsLoading);
  const { t } = useTranslations();
  const [searchQuery, setSearchQuery] = useState('');
  const page = 1;
  const limit = 10;
  const { selectedLocale } = useLocale();

  useEffect(() => {
    if (searchQuery.trim() !== '') {
      dispatch(
        searchGrants({
          api,
          page,
          limit,
          query: searchQuery,
          locale: selectedLocale,
        }),
      );
    }
  }, [searchQuery, selectedLocale, api, dispatch]);

  const searchedGrantsExludingAlreadyAdded = () =>
    searchedGrants.filter(
      (grant: GrantResult) =>
        !companyGrants.some(
          (companyGrant) => companyGrant.grant_id === grant.grant_id,
        ),
    );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t('companyAdminPage|search_grants')}
      width={500}
    >
      <Input.Search
        placeholder={t('companyAdminPage|search_for_grants')}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        enterButton
        style={{ marginBottom: 16 }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <List
          dataSource={searchedGrantsExludingAlreadyAdded()}
          renderItem={(grant: GrantResult) => (
            <List.Item
              actions={[
                <Button
                  key="add"
                  size="circle"
                  onClick={() => onAddGrant(grant)}
                >
                  <AddNew size={16} />
                </Button>,
              ]}
            >
              <List.Item.Meta
                title={
                  grant.grant_display_title ||
                  grant.grant_title ||
                  grant.grant_id
                }
                description={grant.grant_description_short}
              />
            </List.Item>
          )}
        />
      )}
    </Drawer>
  );
};

export default GrantSearchDrawer;
