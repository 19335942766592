import { Page } from '@react-pdf/renderer';
import React, { Fragment } from 'react';
import { CompanyGrant } from 'src/@types/company-types';
import { PdfPagesStyles } from '../../../styles/pages';
import PotentialGrantTemplate from '../templates/potential-grants';
import { GamePlanTranslations } from '../types';

export interface GamePlanPotentialProjectsProps {
  grants: CompanyGrant[];
  translations: GamePlanTranslations;
  locale: string;
}

const GamePlanOthers: React.FC<GamePlanPotentialProjectsProps> = ({
  grants,
  translations,
  locale,
}) => {
  return (
    <>
      {grants.map((grant) => (
        <Fragment key={grant.grant_id}>
          <Page
            id={grant.grant_id}
            size="A4"
            orientation="landscape"
            style={PdfPagesStyles.pageLevel2}
          >
            <PotentialGrantTemplate
              grant={grant}
              translations={translations}
              locale={locale}
            />
          </Page>
        </Fragment>
      ))}
    </>
  );
};

export default GamePlanOthers;
