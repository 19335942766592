export const InitialStepValue = {
    step_id: '',
    grant_id: '',
    title_en: '',
    title_fr: '',
    external_content_en: '',
    external_content_fr: '',
    medium: '',
    type: '',
    description: '',
    conditions: '',
    step_order: 0,
    assets: undefined,
};
export const InitialStepAssetValue = {
    step_asset_id: '',
    step_id: '',
    asset_type: '',
    name_en: '',
    name_fr: '',
};
export const InitialGinEmailValue = {
    id: '',
    grant_id: '',
    type: '',
    link: '',
    name: '',
};
export const InitialGinInterviewGuideValue = {
    gin_interview_guide_id: '',
    grant_id: '',
    admin_id: '',
    name: '',
    link: '',
};
export const GinInitialState = {
    grant: undefined,
    ginSections: [],
    templates: [],
    snippets: [],
};
export const InitialStateGinApplication = {
    application_id: '',
    application_name: '',
    application_program_name: '',
    application_status: '',
    application_created_at: '',
    application_company_id: '',
    application_company_name: '',
    application_contact_id: '',
    application_contact_name: '',
    application_account_manager: '',
    application_price: 0,
    application_requested_at: '',
    application_targeted_amount: 0,
    application_submitted_at: '',
    application_submitted_amount: 0,
    application_result_at: '',
    application_completed_at: '',
    application_rejected_at: '',
    application_accepted_amount: 0,
    application_submission_result: '',
    application_refusal_reason: '',
    application_refusal_reason_specified: '',
    application_program_grant_id: '',
    application_program_status: 'open',
    application_hubspot_url: '',
    application_notes: '',
    application_project_end: '',
    application_closed_at: undefined,
};
export const InitialStateGinShortApplication = {
    application_id: '',
    application_name: '',
    application_status: '',
    application_company_id: '',
    application_contact_name: '',
    application_program_grant_id: '',
    application_program_name: '',
};
export const InitialStateGinApplicationCompany = {
    application_id: '',
    company_id: '',
    company_name: '',
    company_applications: [],
};
export const InitialStateGinApplicationProgram = {
    application_id: '',
    program_grant_id: '',
    program_name: '',
    program_applications: [],
};
export const InitialStateFullGinApplication = {
    application: InitialStateGinApplication,
    contacts: [],
    company: InitialStateGinApplicationCompany,
    program: InitialStateGinApplicationProgram,
    interviewGuides: [],
};
export const AdminGinInitialState = {
    gin_contact_id: '',
    grant_ids: [],
};
