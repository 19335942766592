import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Empty from 'antd/es/empty';
import { useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { useTranslations } from '../../../features/providers/translations-provider';
import Div from '../../common/div';
import Typography from '../../common/typography';
import AssetDrawer from '../asset-drawer';
import useGetAssetActions from '../utils/get-asset-actions';
import useGetAssetMeta from '../utils/get-asset-meta';
import isAssetFolder from '../utils/is-asset-folder';
import FilesListCards from './files-cards';
import FilesListFooter from './files-list-footer';
import FilesListHeader from './files-list-header';
import FilesListTable from './files-table';
import { useLocation } from '../../../features/providers/location-provider';
const FilesList = (props) => {
    const { t } = useTranslations();
    const { navigate } = useLocation();
    const theme = useTheme();
    const { items, handleDownload, vaultPath, noHeader, handleSaveAsset, activeRecord, rootRecord, additionalOwners, defaultIsRow = true, isEdit, isMultiFiles, multiDrawerOpen, handleMultiDrawer, handleDeleteAsset, handleHideShowAsset, handleNavigateToFolder, suggestedAssets, handleAddSuggestedAsset, suggestedAssetsSectionTitle, } = props;
    const [sortedItems, setSortedItems] = useState(items);
    const [activeItemId, setActiveItemId] = useState();
    const [drawerOpen, setDrawerOpen] = useState(multiDrawerOpen);
    const [isRow, setIsRow] = useState(defaultIsRow);
    useEffect(() => {
        setDrawerOpen(multiDrawerOpen);
    }, [multiDrawerOpen]);
    const handleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };
    const onItemSelected = (asset_id) => {
        setActiveItemId(asset_id);
        if (handleMultiDrawer) {
            handleMultiDrawer();
        }
        else {
            handleDrawer();
        }
    };
    const onItemAction = (asset) => {
        const isFolder = isAssetFolder(asset);
        if (isFolder) {
            if (!!handleNavigateToFolder) {
                handleNavigateToFolder(asset.record_id);
            }
            else {
                navigate(`${vaultPath}/${asset.record_id}`);
            }
        }
        else {
            handleDownload(asset);
        }
    };
    const getAssetActions = useGetAssetActions({
        onItemAction,
        onItemSelected,
        setActiveItemId,
        handleDeleteAsset,
        handleHideShowAsset,
    });
    const getAssetMeta = useGetAssetMeta({ getAssetActions });
    const metas = useMemo(() => {
        const metas = items.map(getAssetMeta);
        return metas.reduce((o, meta) => (Object.assign(Object.assign({}, o), { [meta.id]: meta })), {});
    }, [items]);
    const activeItem = useMemo(() => metas[activeItemId], [activeItemId]);
    useEffect(() => {
        setSortedItems(items);
    }, [metas]);
    const closeDrawer = () => onItemSelected();
    const Content = useMemo(() => (isRow ? FilesListTable : FilesListCards), [isRow]);
    const records = useMemo(() => {
        if (!!(activeRecord === null || activeRecord === void 0 ? void 0 : activeRecord.record_id)) {
            return [rootRecord, activeRecord];
        }
        else {
            return [rootRecord];
        }
    }, [activeRecord, rootRecord]);
    return (_jsxs(Div, { flex: noHeader ? 'column-reverse' : 'column', gap: 24, children: [!noHeader && (_jsx(FilesListHeader, Object.assign({}, props, { items: sortedItems, setSortedItems: setSortedItems, isRow: isRow, setIsRow: setIsRow, records: records }))), sortedItems.length &&
                Object.values(metas).some((meta) => { var _a; return !((_a = meta.hidden) === null || _a === void 0 ? void 0 : _a.is_admin_hidden); }) ? (_jsx(Content, { activeItem: activeItem, items: Object.values(metas).filter((meta) => !meta.hidden || !meta.hidden.is_admin_hidden) })) : (_jsx(Empty, { description: _jsx(Typography, { elementTheme: "subtitle2", color: theme.colors.grey_2, children: t(`assets|no_files`) }) })), _jsx(FilesListFooter, Object.assign({}, props)), _jsx(AssetDrawer, { metas: metas, activeMetaId: activeItemId, handleSaveAsset: handleSaveAsset, open: drawerOpen, onClose: closeDrawer, records: records, owners: additionalOwners, isMulti: isMultiFiles, isEdit: isEdit, suggestedAssets: suggestedAssets, handleAddSuggestedAsset: handleAddSuggestedAsset, suggestedAssetsSectionTitle: suggestedAssetsSectionTitle })] }));
};
export default FilesList;
