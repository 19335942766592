import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import Grants from '@hellodarwin/icons/dist/icons/Grants';
import Invoices from '@hellodarwin/icons/dist/icons/Invoices';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import ProjectIcon from '@hellodarwin/icons/dist/icons/ProjectIcon';
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import Tooltip from 'antd/es/tooltip';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { dollarFormat, getStringDate } from '../../../features/helpers';
import { getStringDateFromString } from '../../../features/helpers/get-formatted-date';
import { getProgramGrantStatusColor } from '../../../features/helpers/get-status-color';
import useLocale from '../../../features/providers/locale-provider';
import { useTranslations, } from '../../../features/providers/translations-provider';
import Button from '../../common/button';
import Div from '../../common/div';
import HdTag from '../../common/hd-tag';
import TagsList from '../../common/hd-tag/tags-list';
import Link from '../../common/link';
import Typography from '../../common/typography';
import GrantCardStatusList from '../../grants/card/grant-card-status-list';
import { LineClampContent, MainProjectHeaderActions, } from '../main-project-table/styles';
import formatRange from '../utils/format-range';
import grantsRowExpandable from '../utils/grants-row-expandable';
import projectRowExpandable from '../utils/project-row-expandable';
const getApplicationAmount = (record, locale) => {
    var _a, _b;
    const amount = (_b = (_a = record.program_accepted_amount) !== null && _a !== void 0 ? _a : record.program_application_amount) !== null && _b !== void 0 ? _b : record.program_targeted_amount;
    return !!amount ? dollarFormat(amount, locale, true) : ' - ';
};
const getApplicationAmountTooltip = (record, t) => {
    const tooltip = !!record.program_accepted_amount
        ? t('application|application_accepted_amount')
        : !!record.program_application_amount
            ? t('application|application_applied_amount')
            : !!record.program_targeted_amount
                ? t('application|application_targeted_amount')
                : undefined;
    return tooltip;
};
const getContactsString = (contacts) => {
    var names = '';
    if (!!contacts) {
        names = contacts.map((contact) => contact.name).join(', ');
    }
    return names;
};
const isExpanded = (record_id, expandedRowKeys) => {
    return expandedRowKeys.includes(record_id);
};
const RowTitle = ({ record_id, isExpandable, expandedRowKeys, v, parentCompanyName, }) => {
    const theme = useTheme();
    return (_jsxs(Div, { flex: "row", gap: 8, align: "center", children: [isExpandable ? (_jsx(TabArrow, { size: 18, down: isExpanded(record_id, expandedRowKeys) })) : (_jsx("span", { style: {
                    width: 18,
                    flexShrink: 0,
                    display: 'block',
                } })), _jsxs(LineClampContent, { children: [_jsx(Typography, { elementTheme: "body2", bold: true, color: theme.colors.purple_2, children: parentCompanyName }), v] })] }));
};
const ColumnTitleWithIcon = ({ label, Icon, }) => {
    return (_jsxs(Div, { flex: "row", gap: 8, align: "center", children: [_jsx(Icon, { size: 18 }), label] }));
};
const useCompanyProjectsColumns = ({ handleAddProjectSubitem, handleAddGrantSubitem, handleEditProject, expandedRowKeys, projectsOnly, grantsPath, applicationsPath, projectPath, isEditable, }) => {
    const theme = useTheme();
    const { selectedLocale } = useLocale();
    const { t } = useTranslations();
    const projectColumns = useMemo(() => {
        const projectColumns = [
            {
                key: 'title',
                dataIndex: 'title',
                title: (_jsx(ColumnTitleWithIcon, { Icon: ProjectIcon, label: t('project|project_title') })),
                render: (v, record) => (_jsx(RowTitle, { isExpandable: projectRowExpandable(record) && !projectsOnly, record_id: record.project_id, v: v, expandedRowKeys: expandedRowKeys })),
                width: 500,
            },
            {
                key: 'project_goals',
                dataIndex: 'project_goals',
                title: t('project|project_type'),
                render: (_, record) => {
                    var _a;
                    return (_jsx(TagsList, { tags: (_a = record.project_goals) === null || _a === void 0 ? void 0 : _a.map((text) => ({ text })), mainColor: theme.colors.orange_1, limit: 1, collapsedTagsInTooltip: true }));
                },
                width: 300,
            },
            {
                key: 'budget',
                title: t('project|project_estimated_budget'),
                render: (_, record) => (_jsx(Typography, { elementTheme: "body1", bold: true, color: theme.colors.purple_2, children: dollarFormat(record.estimated_budget, selectedLocale, true) })),
                width: 250,
            },
            {
                key: 'timeline',
                title: t('project|project_timeline'),
                render: (_, record) => formatRange({
                    project: record,
                    locale: selectedLocale,
                    separator: selectedLocale === 'en' ? ' to ' : ' au ',
                    formatValue: getStringDate,
                    keys: ['started_date', 'end_date'],
                }),
                width: 400,
            },
            {
                key: 'priority',
                dataIndex: 'priority',
                title: t('project|project_priority'),
                render: (v) => (_jsx(Typography, { elementTheme: "body1", bold: true, color: theme.colors.purple_2, children: v })),
                width: 170,
                align: 'center',
            },
            {
                key: 'actions',
                title: '',
                render: (_, record) => (_jsxs(MainProjectHeaderActions, { children: [isEditable && (_jsxs(_Fragment, { children: [_jsx(Button, { defaultStyle: theme.colors.grey_3, transparent: true, size: "square", headingIcon: _jsx(AddNew, { size: 18 }), onClick: () => handleAddProjectSubitem(record) }), _jsx(Button, { defaultStyle: theme.colors.grey_3, transparent: true, size: "square", headingIcon: _jsx(Edit, { size: 18 }), onClick: () => handleEditProject(record) })] })), !!projectPath && (_jsx(Link, { to: `${projectPath}/${record.project_id}`, children: _jsx(NewWindow, { size: 18 }) }))] })),
                width: 100,
                fixed: 'right',
            },
        ];
        return projectColumns;
    }, [expandedRowKeys, t, theme, handleAddProjectSubitem, isEditable]);
    const expandProgramColumns = useMemo(() => {
        const expandProgramColumns = [
            {
                title: (_jsx(ColumnTitleWithIcon, { Icon: Invoices, label: t('project|associated_financing') })),
                dataIndex: 'program_name',
                key: 'program_name',
                width: 500,
                render: (v, record) => {
                    return (_jsx(RowTitle, { isExpandable: false, record_id: record.program_id, v: v, expandedRowKeys: expandedRowKeys, parentCompanyName: record.is_subsidiary_program ? record.parent_company_name : null }));
                },
            },
            {
                title: t('application|application_status'),
                dataIndex: 'program_grant_status',
                key: 'program_grant_status',
                width: 300,
                render: (v) => (_jsx(HdTag, { color: getProgramGrantStatusColor(v, theme), text: t(`programs_labels|${v}`), withBorder: true })),
            },
            {
                title: t('application|application_amount'),
                dataIndex: 'full_count',
                key: 'full_count',
                width: 250,
                render: (_, record) => (_jsx(Tooltip, { title: getApplicationAmountTooltip(record, t), children: _jsx("span", { style: { color: theme.colors.purple_2 }, children: getApplicationAmount(record, selectedLocale) }) })),
            },
            {
                title: t('application|application_contacts'),
                dataIndex: 'contacts',
                key: 'contacts',
                width: 300,
                render: (_, record) => getContactsString(record.contacts),
            },
            {
                title: t('application|application_created_on'),
                dataIndex: 'program_created_at',
                key: 'program_created_at',
                render: (v) => getStringDateFromString(v, selectedLocale),
                width: 400,
            },
            {
                key: 'actions',
                title: '',
                render: (_, record) => (_jsx(MainProjectHeaderActions, { children: _jsx(Link, { to: `${applicationsPath}/${record.program_id}`, children: _jsx(NewWindow, { size: 18 }) }) })),
                fixed: 'right',
            },
        ];
        return expandProgramColumns;
    }, [selectedLocale, t, projectPath]);
    const expandGrantColumns = useMemo(() => {
        const expandGrantColumns = [
            {
                title: (_jsx(ColumnTitleWithIcon, { Icon: Grants, label: t('grant_single|grant_title') })),
                dataIndex: 'grant_display_title',
                key: 'grant_display_title',
                width: 500,
                render: (v, record) => (_jsx(RowTitle, { isExpandable: grantsRowExpandable(record), record_id: record.grant_id, v: v, expandedRowKeys: expandedRowKeys })),
            },
            {
                title: t('grant_single|grant_status'),
                dataIndex: 'application_status',
                key: 'application_status',
                width: 300,
                render: (v) => (_jsx(GrantCardStatusList, { application_status: v, featuredTags: [] })),
            },
            {
                title: t('grant_single|openingDate'),
                key: 'opening_date',
                width: 200,
                render: (_, record) => {
                    var _a, _b;
                    return !!((_a = record.grant_timeline) === null || _a === void 0 ? void 0 : _a[0].opened_at)
                        ? getStringDate((_b = record.grant_timeline) === null || _b === void 0 ? void 0 : _b[0].opened_at, selectedLocale)
                        : ' - ';
                },
            },
            {
                title: t('grant_single|closingDate'),
                key: 'closing_date',
                width: 200,
                render: (_, record) => {
                    var _a, _b;
                    return !!((_a = record.grant_timeline) === null || _a === void 0 ? void 0 : _a[0].closed_at)
                        ? getStringDate((_b = record.grant_timeline) === null || _b === void 0 ? void 0 : _b[0].closed_at, selectedLocale)
                        : ' - ';
                },
            },
            {
                key: 'actions',
                title: '',
                render: (_, record) => (_jsx(MainProjectHeaderActions, { children: _jsx(Link, { to: `${grantsPath}/${record.grant_id}`, children: _jsx(NewWindow, { size: 18 }) }) })),
                width: 100,
                fixed: 'right',
            },
        ];
        return expandGrantColumns;
    }, [handleAddGrantSubitem, theme, t]);
    return useMemo(() => {
        return { projectColumns, expandGrantColumns, expandProgramColumns };
    }, [
        projectColumns,
        expandProgramColumns,
        expandGrantColumns,
        applicationsPath,
        grantsPath,
    ]);
};
export default useCompanyProjectsColumns;
