import styled from '../../../plugins/styled';
import Div from '../../common/div';
export const ProfileSectionContainer = styled.div `
  padding: 35px 48px;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.white_1};

  display: flex;
  flex-direction: column;
  gap: 28px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    padding: 24px;
    gap: 24px;
  }
`;
export const ProviderProfileContainer = styled.div `
  width: 100%;
  max-width: 1120px;
  gap:20px;
  display:flex;
  flex-direction:column;
  
  @media (max-width: ${(props) => props.theme.breakpoints.xxl}px) { {
    width: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) { {
gap:10px;
  }

  display: flex;
  flex-direction: column;

  ${ProfileSectionContainer}:last-child {
    border-radius: 0 0 11px 11px;
  }

  a {
    color: ${(props) => props.theme.colors.purple_1};
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    width: fit-content;
    font-size: 1rem;
    font-weight: 400;
    border-bottom: 1px solid ${(props) => props.theme.colors.purple_1};

    &::after,
    &::before {
      display: none;
    }
  }
`;
export const VideoWrapperContainer = styled(Div) `
  max-width: 600px;
  margin: auto;
  position: relative;
  overflow: hidden;

  &.full-width {
    max-width: 100%;
  }
`;
export const VideoWrapper = styled(Div) `
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;

  .video {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
  }

  .video-title {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    width: 100%;
    background: linear-gradient(to top, transparent 0%, rgba(0, 0, 0, 0.6) 81%);
    color: ${(props) => props.theme.colors.white_1};

    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    padding: 10px;
    opacity: 0;
    transition: opacity 250ms linear;
  }

  &:hover {
    .video-title {
      opacity: 1;
    }
  }

  .react-player__preview {
    position: relative;
    z-index: 1;
  }

  iframe {
    position: relative;
    z-index: 3;
  }
`;
