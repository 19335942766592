import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslations } from '../../../features/providers/translations-provider';
import Div from '../../common/div';
import SpinProgress from '../../common/spin/progress';
import Typography from '../../common/typography';
const FormLoading = ({ onLoadingFinished }) => {
    const { t } = useTranslations();
    const [loadingProgress, setLoadingProgress] = useState(0);
    const handleLoading = () => {
        if (loadingProgress >= 100) {
            onLoadingFinished();
        }
        else {
            const newProgress = Math.ceil(Math.random() * 10) + 5;
            setLoadingProgress((prevProgress) => prevProgress + newProgress);
        }
    };
    useEffect(() => {
        setTimeout(handleLoading, Math.ceil(Math.random() * 10) * 50);
    }, [loadingProgress]);
    return (_jsxs(Div, { flex: "column", align: "center", justify: "center", style: { minHeight: 400, maxWidth: 450, margin: 'auto' }, gap: 16, children: [_jsx(SpinProgress, { percent: loadingProgress }), _jsx(Typography, { elementTheme: "subtitle2", textAlign: "center", children: t('form_viewer|form_viewer_loading') })] }));
};
export default FormLoading;
