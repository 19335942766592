import Div from '@hellodarwin/core/lib/components/common/div';
import Layout from 'antd/es/layout';
import { MenuProps } from 'antd/es/menu';
import { useAppDispatch, useAppSelector } from '../app';
import { toggleProgramsModal } from '../features/api/slices/programs-slice';
import { toggleProviderModal } from '../features/api/slices/providers-slice';
import { toggleRfpsModal } from '../features/api/slices/rfp-slice';
import HeaderBarUser from './header-bar-user';

import Button from '@hellodarwin/core/lib/components/common/button';
import Dropdown from '@hellodarwin/core/lib/components/common/dropdown';
import { ModalConfirm } from '@hellodarwin/core/lib/components/common/hd-modal/confirm';
import { AdminProfile } from '@hellodarwin/core/lib/features/entities/profile-entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import theme from '@hellodarwin/core/lib/theme';
import Dots from '@hellodarwin/icons/dist/icons/Dots';
import notification from 'antd/es/notification';
import { useLocation } from 'react-router-dom';
import { updateAdmin } from '../features/api/slices/admins-slice';
import { selectAuthentifiedUser } from '../features/api/slices/global-slice';
import { useAdminApi } from '../features/api/use-admin-api';
import SearchBar from './search-bar';
const { Header } = Layout;
const HeaderBar = () => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const authenticatedAdmin = useAppSelector(selectAuthentifiedUser);

  const toggleRfpForm = () => {
    dispatch(toggleRfpsModal({ type: 'create', isVisible: true }));
  };
  const { t } = useTranslations();
  const toggleProviderForm = () => {
    dispatch(toggleProviderModal({ type: 'create', isVisible: true }));
  };

  const toggleProgramForm = () => {
    dispatch(toggleProgramsModal({ type: 'create', isVisible: true }));
  };

  const handleSetHomePage = () => {
    ModalConfirm({
      title: t('headerBar|set_as_home_confirm_title'),

      okButtonProps: {
        style: {
          backgroundColor: theme.colors.purple_1,
          color: theme.colors.white_1,
          borderRadius: '24px',
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: theme.colors.white_1,
          borderColor: theme.colors.grey_1,
          color: theme.colors.grey_1,
          borderRadius: '24px',
        },
      },
      cancelText: t('button|cancel'),
      okText: t('button|confirm'),
      content: t('headerBar|set_as_home_confirm_description', {
        current: authenticatedAdmin.admin_homepage ?? '/projects',
        new_path: pathname,
      }),
      onOk: async () => {
        const newAdmin: AdminProfile = {
          ...authenticatedAdmin,
          admin_homepage: pathname,
        };

        dispatch(updateAdmin({ api, data: newAdmin })).then((payload) =>
          payload.meta.requestStatus === 'fulfilled'
            ? notification.success({
                message: 'Saved!',
                placement: 'bottomLeft',
              })
            : notification.error({
                message: 'Error while saving...',
                placement: 'bottomLeft',
              }),
        );
      },
      onCancel: async () => {},
    });
  };

  const actionMenu: MenuProps['items'] = [
    {
      label: t('headerBar|set_as_home'),
      key: 1,
      onClick: handleSetHomePage,
    },
    {
      label: t('headerBar|create_rfp_title'),
      key: 1,
      onClick: toggleRfpForm,
    },
    {
      label: t('headerBar|create_provider_title'),
      key: 2,
      onClick: toggleProviderForm,
    },
    {
      label: t('headerBar|create_application_title'),
      key: 3,
      onClick: toggleProgramForm,
    },
  ];

  return (
    <Header style={{ padding: 0, height: 'auto' }}>
      <Div
        flex="row"
        style={{
          width: '100%',
          height: 44,
        }}
        backgroundColor={theme.colors.white_1}
      >
        <SearchBar />
        <Div
          flex="row"
          align="center"
          justify="end"
          style={{ padding: '0 32px', height: '100%' }}
          gap={15}
          fitContent
        >
          <Dropdown
            items={actionMenu}
            ctaRender={() => (
              <Button
                size="square"
                style={{ padding: 0 }}
                transparent
                defaultStyle={theme.colors.primary}
              >
                <Dots size={32} />
              </Button>
            )}
          />

          <HeaderBarUser />
        </Div>
      </Div>
    </Header>
  );
};

export default HeaderBar;
