import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ImagePlus from '@hellodarwin/icons/dist/icons/ImagePlus';
import Form from 'antd/es/form';
import { useForm } from 'antd/es/form/Form';
import Input from 'antd/es/input/Input';
import Select from 'antd/es/select';
import Upload from 'antd/es/upload';
import { useEffect, useState } from 'react';
import { PortfolioAssetsType, } from '../../../../../../features/entities';
import { useTranslations } from '../../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../../plugins/styled';
import Button from '../../../../../common/button';
import Div from '../../../../../common/div';
import Modal from '../../../../../common/hd-modal';
import Typography from '../../../../../common/typography';
import FormItem from '../../../../form-layouts/form-item';
import validateFile, { getFileTypes } from '../../../../utils/validate-file';
const Footer = ({ handleCancel, handleVideoSave, }) => {
    const theme = useTheme();
    const { t } = useTranslations();
    return (_jsxs(Div, { flex: "row", justify: "flex-end", gap: 16, tablet: { align: 'center', flex: 'column' }, children: [_jsx(Button, { size: "small", defaultStyle: theme.colors.white_1, onClick: handleCancel, children: t('button|cancel') }), !!handleVideoSave && (_jsx(Button, { size: "small", defaultStyle: theme.colors.purple_1, onClick: handleVideoSave, children: t('button|submit') }))] }));
};
const PortfolioAssetModal = ({ open, handleClose, assets, setAssets, portfolio_id, }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    const [form] = useForm();
    const [type, setType] = useState('image');
    useEffect(() => {
        form.resetFields();
    }, [type]);
    const handleBeforeUpload = (file, fileList) => {
        const temp = [...assets];
        fileList.forEach((file) => {
            var _a;
            const isValid = validateFile(file);
            if (isValid) {
                const asset = {
                    pos: (_a = assets === null || assets === void 0 ? void 0 : assets.length) !== null && _a !== void 0 ? _a : 0,
                    provider_portfolio_id: portfolio_id,
                    asset_type: PortfolioAssetsType.Image,
                    file: file,
                    temp_id: file.uid,
                    filename: file.name,
                };
                temp.push(asset);
            }
        });
        setAssets(temp);
        handleClose();
        return false;
    };
    const uploadProps = {
        className: 'assets-uploader',
        name: 'Asset',
        beforeUpload: handleBeforeUpload,
        multiple: false,
        listType: 'picture-card',
        customRequest: (options) => {
            const { onSuccess, onError, file, onProgress } = options;
            if (!!onSuccess)
                onSuccess('OK');
        },
    };
    const handleVideoSave = () => {
        var _a;
        const { asset_url } = form.getFieldsValue();
        const newAsset = {
            pos: (_a = assets === null || assets === void 0 ? void 0 : assets.length) !== null && _a !== void 0 ? _a : 0,
            provider_portfolio_id: portfolio_id,
            asset_type: PortfolioAssetsType.Video,
            asset_url: asset_url,
            temp_id: `${portfolio_id}_${new Date().getTime()}`,
        };
        setAssets([...assets, newAsset]);
        handleClose();
    };
    const handleCancel = (e) => {
        e.stopPropagation();
        e.preventDefault();
        form.resetFields();
        handleClose();
    };
    return (_jsx(Modal, { open: open, handleCancel: handleClose, children: _jsxs(Div, { flex: "column", gap: 16, style: { paddingRight: '1rem' }, children: [_jsxs(Select, { placeholder: "Select option", value: type, onChange: setType, children: [_jsx(Select.Option, { value: "video", children: t('portfolioAssetsModal|assetTypeVideo') }), _jsx(Select.Option, { value: "image", children: t('portfolioAssetsModal|assetTypeImage') })] }), type === 'image' ? (_jsxs(Div, { flex: "column", gap: 16, children: [_jsxs(Upload.Dragger, Object.assign({}, uploadProps, { className: `assets-uploader`, multiple: true, showUploadList: false, children: [_jsx(ImagePlus, { size: 100 }), _jsxs(Div, { style: { paddingLeft: '1rem', paddingRight: '1rem' }, children: [_jsx(Typography, { elementTheme: "caption", color: theme.colors.grey_3, textAlign: "right", children: t('portfolioAssetsModal|assetSettingResolution', {
                                                width: '600',
                                                height: '600',
                                            }) }), _jsx(Typography, { elementTheme: "caption", color: theme.colors.grey_3, textAlign: "right", children: t('portfolioAssetsModal|assetSettingFileSize') }), _jsx(Typography, { elementTheme: "caption", color: theme.colors.grey_3, textAlign: "right", children: t('portfolioAssetsModal|assetSettingAllowedFile', {
                                                types: getFileTypes().join(', '),
                                            }) })] })] })), _jsx(Footer, { handleCancel: handleCancel, handleVideoSave: handleVideoSave })] })) : (type === 'video' && (_jsx(Div, { children: _jsx(Form, { layout: "vertical", name: "video", onFinish: handleVideoSave, form: form, children: _jsxs(Div, { flex: "column", gap: 16, children: [_jsx(FormItem, { label: "Video url", name: "asset_url", rules: [
                                        {
                                            required: true,
                                            message: 'Please add a valid URL',
                                        },
                                    ], children: _jsx(Input, {}) }), _jsx(Footer, { handleCancel: handleCancel, handleVideoSave: handleVideoSave })] }) }) })))] }) }));
};
export default PortfolioAssetModal;
