import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import LinkedinLogo from '@hellodarwin/icons/dist/icons/LinkedinLogo';
import prefixHTTPS from '../../../../../features/helpers/prefix-https';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../plugins/styled';
import Avatar from '../../../../common/avatar';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import useDarwinLogoFallback from '../../hooks/use-darwin-picture-fallback';
import { handleReorderTeamMembers } from '../order-function/team-order-list';
import OrderButtons from './order-buttons';
import { TeamMemberCardContainer } from './styles';
const TeamMemberCard = ({ member, handleClick, selected, isEditable, teamMembers, setTeamMembers, functions, provider, }) => {
    var _a;
    const { t } = useTranslations();
    const theme = useTheme();
    const changesOrder = (newOrder) => {
        handleReorderTeamMembers({
            members: teamMembers !== null && teamMembers !== void 0 ? teamMembers : [],
            item_order: newOrder,
            functions,
            team_member_id: member.provider_team_member_id,
            setTeamMembers,
        });
    };
    const fallback = useDarwinLogoFallback({ type: 'provider', size: 88 });
    const onClick = () => !!handleClick && handleClick(member);
    return (_jsxs(TeamMemberCardContainer, { onClick: onClick, "$selected": !!selected, children: [!member.name ? (_jsx(_Fragment, { children: _jsx(Typography, { elementTheme: "body2", children: t('profile_team|newMember') }) })) : (_jsxs(_Fragment, { children: [_jsx(Avatar, { alt: [member.name, member.role].filter((itm) => !!itm).join(' - '), src: `${member.profile_picture}?${new Date().toString()}`, size: 88, shape: "square", loading: "eager", style: { borderRadius: 4 }, fallback: provider.logo, additional_fallback: fallback, children: (_a = member.name) === null || _a === void 0 ? void 0 : _a[0] }), _jsxs(Div, { flex: 'column', gap: 6, overflow: "hidden", children: [_jsx(Typography.Title, { level: 3, elementTheme: "h6", nowrap: true, overflow: true, ellipsis: true, noMargin: true, children: member.name }), _jsx(Typography, { color: theme.colors.grey_3, elementTheme: "caption", nowrap: true, overflow: true, ellipsis: true, style: { maxWidth: 320 }, children: member.role }), !!member.linked_in && (_jsx("a", { rel: "nofollow", href: prefixHTTPS(member.linked_in, 'team_member'), target: "_blank", children: _jsx(LinkedinLogo, { size: 16 }) }))] })] })), ' ', isEditable && member.provider_team_member_id ? (_jsx(OrderButtons, { teamMembers: teamMembers, handleClick: handleClick, member: member, changesOrder: changesOrder })) : null] }));
};
export default TeamMemberCard;
