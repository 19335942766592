import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import useMediaQuery from '../../../../../features/helpers/use-media-query';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import GrantProjectCard from './grant-project-card';
const GrantProjectsList = ({ grant_projects, }) => {
    const { t } = useTranslations();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);
    const theme = useTheme();
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    return (_jsxs(Div, { flex: "column", gap: 24, children: [_jsx(Typography, { elementTheme: "h6", children: t('grant_single|admissibleProjectsExample') }), _jsx(Div, { flex: "row", wrap: "wrap", gap: 16, justify: isTablet ? 'center' : 'left', children: React.Children.toArray(grant_projects.map((project) => (_jsx(GrantProjectCard, { project: project, handleOpen: handleOpen, open: open }, project.grant_project_id)))) })] }));
};
export default GrantProjectsList;
