'use client';
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import useMediaQuery from '../../../../features/helpers/use-media-query';
const useDivProps = (props) => {
    const theme = useTheme();
    const isXL = useMediaQuery({ minWidth: theme.breakpoints.xl });
    const isL = useMediaQuery({ maxWidth: theme.breakpoints.l });
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    const isMobile = useMediaQuery({ maxWidth: theme.breakpoints.mobile });
    return useMemo(() => {
        const currentProps = {};
        const currentStyle = {};
        const addProp = (property, value) => {
            if (value !== undefined) {
                currentProps[property] = value;
            }
        };
        const addStyling = (property, value) => {
            if (value !== undefined) {
                currentStyle[property] = value;
            }
        };
        const prepareStyling = (data) => {
            if (data) {
                const { gap, flex, style, justify, align, wrap } = data, rest = __rest(data, ["gap", "flex", "style", "justify", "align", "wrap"]);
                if (gap !== undefined) {
                    addStyling('gap', `${gap}px`);
                }
                if (flex !== undefined) {
                    addStyling('display', 'flex');
                    addStyling('flexDirection', flex);
                }
                if (justify !== undefined) {
                    addStyling('justifyContent', justify);
                }
                if (align !== undefined) {
                    addStyling('alignItems', align);
                }
                if (wrap !== undefined) {
                    addStyling('flexWrap', wrap);
                }
                Object.entries(rest).forEach(([key, value]) => {
                    addStyling(key, value);
                });
                if (style) {
                    Object.entries(style).forEach(([key, value]) => {
                        addStyling(key, value);
                    });
                }
            }
        };
        const prepareProps = (data) => {
            if (data) {
                const { backgroundOpacity, backgroundOpacityHover, borderColor, borderColorHover, hidden, fitContent, fitMaxContent, fitMinContent, fullHeight, bordersSide } = data, rest = __rest(data, ["backgroundOpacity", "backgroundOpacityHover", "borderColor", "borderColorHover", "hidden", "fitContent", "fitMaxContent", "fitMinContent", "fullHeight", "bordersSide"]);
                prepareStyling(rest);
                const propMappings = {
                    $backgroundOpacity: backgroundOpacity,
                    $backgroundOpacityHover: backgroundOpacityHover,
                    $borderColor: borderColor,
                    $borderColorHover: borderColorHover,
                    $hidden: hidden,
                    $fitContent: fitContent,
                    $minContent: fitMinContent,
                    $maxContent: fitMaxContent,
                    $fullHeight: fullHeight,
                    $borderSide: bordersSide,
                };
                Object.entries(propMappings).forEach(([key, value]) => {
                    addProp(key, value);
                });
            }
        };
        const { xl, xxl, tablet, mobile, children, onClick, onDoubleClick, onMouseEnter, onMouseLeave, onPointerEnter, onPointerLeave, id, className } = props, defaultViewProps = __rest(props, ["xl", "xxl", "tablet", "mobile", "children", "onClick", "onDoubleClick", "onMouseEnter", "onMouseLeave", "onPointerEnter", "onPointerLeave", "id", "className"]);
        const generalProps = {
            children,
            onClick,
            onDoubleClick,
            onMouseEnter,
            onMouseLeave,
            onPointerEnter,
            onPointerLeave,
            id,
            className,
        };
        prepareProps(defaultViewProps);
        if (isXL && !!xxl)
            prepareProps(xxl);
        if (isL && !!xl)
            prepareProps(xl);
        if (isTablet && !!tablet)
            prepareProps(tablet);
        if (isMobile && !!mobile)
            prepareProps(mobile);
        return { currentProps, currentStyle, generalProps };
    }, [isTablet, isXL, isL, isMobile, props]);
};
export default useDivProps;
