import AppLayout from '@hellodarwin/core/lib/components/common/layout/app-layout';
import { useAppData } from '@hellodarwin/core/lib/features/providers/app-data-provider';
import AppRoutesRender from '@hellodarwin/core/lib/features/router/app-routes-render';
import { useEffect, useMemo } from 'react';
import { useAppSelector } from './app';
import ProviderActionModal from './components/companies/companies-action-modal';
import MatchesActionModal from './components/matches/matches-action-modal';
import ProgramsActionModal from './components/programs/programs-action-modal';
import RfpActionModal from './components/rfp/rfp-action-modal';
import { useActionBar } from './context/action-bar-provider';
import { selectIsLoading } from './features/api/slices/global-slice';
import { useAdminSettings } from './features/providers/admin-settings-provider';
import { useUser } from './features/providers/user-provider';
import HeaderBar from './layout/header-bar';
import Navigation from './layout/navigation';
import adminAppPages from './pages';

const AdminApp = () => {
  const appData = useAppData();
  const { isLoggedIn, defaultHomepage } = useUser();
  const { getSetting, updateSetting } = useAdminSettings();
  const { actionBar } = useActionBar();
  const defaultNavOpen = getSetting('navOpen');
  const isLoading = useAppSelector(selectIsLoading);

  const isAppLoading = useMemo(
    () => isLoading || !isLoggedIn,
    [isLoading, isLoggedIn],
  );

  useEffect(() => {
    const { setHeader, setNav, setNavCollapsed } = appData;
    setNavCollapsed(defaultNavOpen);
    setHeader(<HeaderBar />);
    setNav(<Navigation />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appData.navCollapsed !== defaultNavOpen) {
      updateSetting('navOpen', appData.navCollapsed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.navCollapsed]);

  return (
    <>
      <AppLayout isLoading={isAppLoading}>
        <AppRoutesRender
          pages={adminAppPages}
          defaultHomepage={defaultHomepage}
        />
        <>{actionBar}</>
      </AppLayout>
      <RfpActionModal />
      <ProviderActionModal />
      <MatchesActionModal />
      <ProgramsActionModal />
    </>
  );
};

export default AdminApp;
