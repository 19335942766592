import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import Badge from 'antd/es/badge';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from '../../../../plugins/i18n';
import theme from '../../../../theme';
import Div from '../../../common/div';
import HdTag from '../../../common/hd-tag';
import { MenuItemLabelContainer, MenuItemLabelText } from './styles';
const NavigationMenuLabel = ({ label, isExternal, isNew, Icon, isSublink, isCurrent, app, subLinks, listItemsOpen, withBorderTop, isCollapsed, noStyle, notifs, }) => {
    const { t } = useTranslation();
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    return (_jsx(Badge, { count: notifs, offset: [-10, 10], size: "small", children: _jsxs(MenuItemLabelContainer, { "$isCurrent": isCurrent, "$isSublink": isSublink, "$isCollapsed": isCollapsed, "$withBorderTop": withBorderTop, "$app": app, "$noStyle": noStyle, children: [isCollapsed && !isSublink ? (_jsx("div", { children: !!Icon && _jsx(Icon, { size: isSublink ? 16 : 20 }) })) : (_jsxs(_Fragment, { children: [_jsxs(Div, { flex: "row", gap: isSublink ? 12 : 16, align: "center", children: [!!Icon && (_jsx(Icon, { size: isSublink ? 16 : 20, style: { flexShrink: 0 } })), _jsx(MenuItemLabelText, { "$isSublink": isSublink, children: label })] }), (isExternal || isNew) && (_jsxs(Div, { flex: "row", gap: 8, fitContent: true, children: [isExternal && (_jsx(NewWindow, { size: 14, style: { flexShrink: 0 } })), isNew && (_jsx(HdTag, { color: app === 'provider'
                                        ? theme.colors.purple_3
                                        : theme.colors.purple_2, text: t('navigation|new'), size: "small", withBorder: true }))] }))] })), !!subLinks && !isCollapsed && !isTablet && !noStyle && (_jsx(TabArrow, { size: 16, down: listItemsOpen }))] }) }));
};
export default NavigationMenuLabel;
