import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Check from '@hellodarwin/icons/dist/icons/Check';
import Progress from 'antd/es/progress/progress';
import { useTheme } from '../../../plugins/styled';
import Typography from '../typography';
const SpinProgress = ({ percent }) => {
    const theme = useTheme();
    const spinColors = {
        '0%': theme.colors.red_1,
        '50%': theme.colors.yellow_1,
        '100%': theme.colors.green_1,
    };
    return (_jsx(Progress, { type: "circle", percent: percent, strokeColor: spinColors, trailColor: theme.colors.grey_5, strokeLinecap: "square", strokeWidth: 10, format: (percent) => percent === 100 ? (_jsx(Check, {})) : (_jsxs(Typography, { elementTheme: "subtitle1", children: [percent, "%"] })) }));
};
export default SpinProgress;
