import { Path, Svg } from '@react-pdf/renderer';
import theme from '../../../theme';
import { IconNode } from './types';

const CheckIcon: IconNode = ({
  width = 24,
  height = 24,
  color = theme.colors.grey_1,
}) => {
  return (
    <Svg viewBox="0 0 30 30" fill="none" width={width} height={height}>
      <Path
        d="M1.60001 17.4222L11.392 28.8L30.4 3.20001"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Svg>
  );
};

export default CheckIcon;
