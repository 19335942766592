import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
const OrderButtons = ({ teamMembers, member, handleClick, changesOrder, }) => {
    const theme = useTheme();
    const buttonProps = useMemo(() => ({
        defaultStyle: theme.colors.grey_1,
        size: 'circle',
    }), [theme]);
    const isFirst = useMemo(() => (teamMembers === null || teamMembers === void 0 ? void 0 : teamMembers.indexOf(member)) === 0, [teamMembers, member]);
    const isLast = useMemo(() => { var _a; return (teamMembers === null || teamMembers === void 0 ? void 0 : teamMembers.indexOf(member)) === ((_a = teamMembers === null || teamMembers === void 0 ? void 0 : teamMembers.length) !== null && _a !== void 0 ? _a : 0) - 1; }, [teamMembers, member]);
    const handleUp = () => {
        !!handleClick && handleClick(member);
        !!changesOrder && changesOrder(-1);
    };
    const handleDown = () => {
        !!handleClick && handleClick(member);
        !!changesOrder && changesOrder(1);
    };
    return (_jsxs(Div, { flex: 'column', gap: 4, fitMinContent: true, children: [!isFirst && (_jsx(Button, Object.assign({}, buttonProps, { onClick: handleUp, headingIcon: _jsx(TabArrow, { size: 12 }) }))), !isLast && (_jsx(Button, Object.assign({}, buttonProps, { onClick: handleDown, headingIcon: _jsx(TabArrow, { down: true, size: 12 }) })))] }));
};
export default OrderButtons;
