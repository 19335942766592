import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import { Tablet } from '../../../common/Media';
import Button from '../../../common/button';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import ReviewRatings from '../../../reviews/ratings';
const ReviewsAverageCard = ({ handleLeaveAReview, average, style, mobileStyle, }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    return average !== 0 ? (_jsxs(Div, { flex: "column", gap: 14, style: Object.assign({ padding: '12px 16px', borderRadius: 3, width: 128, height: 92 }, style), backgroundColor: theme.colors.beige_4, borderColor: theme.colors.beige_2, fitContent: true, align: "center", xl: {
            style: Object.assign({ width: 68, height: 30, padding: 0 }, mobileStyle),
        }, children: [_jsx(ReviewRatings, { size: "large", showOne: true, defaultValue: average !== null && average !== void 0 ? average : 0, showValue: true, textPlacement: "end", biggerText: true, allowHalf: true }), _jsx("a", { href: `#reviews`, children: _jsx(Button, { isLink: true, size: "auto", defaultStyle: theme.colors.purple_1, style: {
                        letterSpacing: 0.33,
                        textDecoration: 'underline',
                        textTransform: 'uppercase',
                        fontWeight: 500,
                    }, xl: { style: { display: 'none' } }, children: t('reviews|seeReviews') }) })] })) : (_jsx(Tablet, { children: _jsxs(Div, { flex: "column", gap: 14, style: Object.assign({ padding: '12px 16px', borderRadius: 3, width: !!handleLeaveAReview ? 220 : 160, height: !!handleLeaveAReview ? 145 : 90 }, style), xl: { style: Object.assign({}, mobileStyle) }, backgroundColor: theme.colors.beige_4, borderColor: theme.colors.beige_2, align: "center", children: [_jsx(ReviewRatings, { size: "large", showOne: true, defaultValue: 0, allowHalf: true }), _jsxs(Div, { flex: "column", gap: 12, align: "center", children: [_jsx(Typography, { elementTheme: "body3", bold: true, textAlign: "center", children: t('reviews|noReviewsShort') }), handleLeaveAReview && (_jsx(Button, { isLink: true, size: "auto", defaultStyle: theme.colors.purple_1, style: {
                                letterSpacing: 0.33,
                                textDecoration: 'underline',
                                textUnderlineOffset: 2,
                                fontWeight: 500,
                                textAlign: 'center',
                            }, onClick: handleLeaveAReview, textWrap: true, children: t('reviews|leaveFirstReview') }))] })] }) }));
};
export default ReviewsAverageCard;
