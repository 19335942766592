import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslations } from '../../../features/providers/translations-provider';
import { useTheme } from '../../../plugins/styled';
import Button from '../../common/button';
import Div from '../../common/div';
const ProfileActions = ({ providerActions, websiteCTA, }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    return (_jsxs(Div, { flex: "column", tablet: { flex: 'column', gap: '2' }, fitContent: true, children: [!!providerActions && (_jsx(Div, { flex: "row", justify: 'flex-end', align: "center", tablet: {
                    justify: 'flex-end',
                    style: { paddingLeft: 0, paddingRight: 0 },
                }, gap: 12, fitContent: true, children: providerActions })), !!websiteCTA && (_jsx(Button, { defaultStyle: theme.colors.yellow_1, onClick: websiteCTA, fitContent: true, tablet: {
                    textWrap: true,
                }, size: "small", children: t('profile_header|profileHeaderCta') }))] }));
};
export default ProfileActions;
