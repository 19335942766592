import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Employees from '@hellodarwin/icons/dist/icons/Employees';
import Industry from '@hellodarwin/icons/dist/icons/Industry';
import Location from '@hellodarwin/icons/dist/icons/Location';
import RecommendNo from '@hellodarwin/icons/dist/icons/RecommendNo';
import RecommendYes from '@hellodarwin/icons/dist/icons/RecommendYes';
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import Divider from 'antd/es/divider';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { getStringDate } from '../../../../features/helpers';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTranslation } from '../../../../plugins/i18n';
import { useTheme } from '../../../../plugins/styled';
import Collapse from '../../../common/Collapse';
import Div from '../../../common/div';
import HdTag from '../../../common/hd-tag';
import Typography from '../../../common/typography';
import ReviewRatings from '../../ratings';
import ReviewHeader from './review-header';
import ReviewOverallScore from './review-score';
import { RatingsGridBoxContainer, RatingsGridContainer, ReviewCollapse, } from './styles';
const RatingsBox = ({ label, value }) => {
    const theme = useTheme();
    return (_jsxs(RatingsGridBoxContainer, { children: [_jsx(Typography, { elementTheme: "overline", medium: true, color: theme.colors.grey_3, nowrap: true, children: label }), _jsx(Div, { flex: "row", align: "center", justify: "center", gap: 8, tablet: { flex: 'column' }, children: value })] }));
};
const RatingsGrid = ({ review }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    const ratings = [
        { label: t('review_card|rc_rating_budget'), value: review.rating_budget },
        { label: t('review_card|rc_rating_quality'), value: review.rating_quality },
        {
            label: t('review_card|rc_rating_schedule'),
            value: review.rating_schedule,
        },
        {
            label: t('review_card|rc_rating_collaboration'),
            value: review.rating_collaboration,
        },
    ];
    return (_jsxs(RatingsGridContainer, { children: [_jsx(RatingsBox, { label: t('review_card|rc_serviceReceived'), value: _jsx(HdTag, { text: review.project_service, color: theme.colors.purple_1 }) }), _jsx(RatingsBox, { label: t('review_card|rc_recommend'), value: _jsxs(_Fragment, { children: [review.recommend ? (_jsx(RecommendYes, { width: 18, height: 18, style: {
                                color: theme.colors.green_1,
                            } })) : (_jsx(RecommendNo, { width: 18, height: 18, style: {
                                color: theme.colors.error,
                            } })), _jsx(Typography, { elementTheme: "body2", children: review.recommend ? t('review_card|yes') : t('review_card|no') })] }) }), React.Children.toArray(ratings.map((rate) => {
                var _a;
                return ((_a = rate.value) !== null && _a !== void 0 ? _a : 0 > 0) ? (_jsx(RatingsBox, { label: rate.label, value: _jsx(_Fragment, { children: _jsx(ReviewRatings, { showOne: true, defaultValue: rate.value, showTotal: true, showValue: true, textPlacement: "end", allowHalf: true }) }) }, rate.label)) : (_jsx(React.Fragment, {}, rate.label));
            }))] }));
};
const ReviewBody = ({ review, index, locale, }) => {
    const theme = useTheme();
    const { t } = useTranslations();
    const { t: translateTag } = useTranslation();
    const formatCityTag = () => [review.city, review.province].filter(Boolean).join(', ');
    const renderDescriptionCollapse = (descriptionKey, descriptionTitle, descriptionContent) => {
        return (_jsx(Collapse, { ExpandIcon: ({ isActive }) => _jsx(TabArrow, { width: 14, down: isActive }), items: [
                {
                    id: `${index}-${descriptionKey}`,
                    title: (_jsx(Typography, { elementTheme: "body2", color: theme.colors.grey_2, tablet: { textAlign: 'center' }, children: descriptionTitle })),
                    children: (_jsx(Typography, { style: { margin: '0rem 1rem 1rem 1rem' }, tablet: { textAlign: 'center' }, children: descriptionContent })),
                    styles: {
                        container: { borderColor: theme.colors.grey_5 },
                        header: { borderColor: theme.colors.grey_5 },
                        content: { borderColor: theme.colors.grey_5 },
                    },
                },
            ] }, `review-card-${index}-${descriptionKey}`));
    };
    return (_jsxs(Div, { flex: "column", gap: 18, children: [_jsx(RatingsGrid, { review: review }), _jsxs(Div, { flex: "column", gap: 16, bordersSide: "bottom", borderColor: theme.colors.grey_4, children: [review.description_summary &&
                        renderDescriptionCollapse('2', t('reviews|newReviewDescribeLabel', {
                            providerName: review.company_name,
                        }), review.description_summary), review.description_pros &&
                        renderDescriptionCollapse('3', t('review_card|rc_experience_pros'), review.description_pros), review.description_cons &&
                        renderDescriptionCollapse('4', t('review_card|rc_experience_cons'), review.description_cons)] }), _jsx(Div, { flex: 'row', style: { padding: '1.5rem' }, tablet: { style: { padding: 16 }, flex: 'column' }, wrap: "wrap", gap: 16, children: _jsxs(Div, { flex: 'row', style: { padding: '1.5rem' }, tablet: {
                        style: { padding: '1rem' },
                        flex: 'column',
                        align: 'center',
                    }, wrap: "wrap", gap: 24, children: [_jsxs(Div, { flex: "column", wrap: "nowrap", fitContent: true, children: [_jsx(Typography, { elementTheme: "overline", medium: true, color: theme.colors.grey_3, style: { paddingBottom: '0.5rem' }, tablet: { textAlign: 'center' }, children: t('review_card|rc_publishOn') }), _jsx(HdTag, { text: getStringDate(new Date((review === null || review === void 0 ? void 0 : review.reviewed_at) || ''), locale).toString() })] }), (review.size ||
                            review.project_industry ||
                            review.city ||
                            review.province) && (_jsxs(Div, { flex: "column", fitContent: true, children: [_jsx(Typography, { elementTheme: "overline", medium: true, textTransform: "uppercase", color: theme.colors.grey_3, style: {
                                        paddingBottom: '0.5rem',
                                    }, tablet: { textAlign: 'center' }, children: t('review_card|rc_about', {
                                        company_name: review.company_name,
                                    }) }), _jsxs(Div, { flex: "row", align: "center", gap: 16, wrap: "wrap", tablet: { flex: 'column' }, children: [review.size && (_jsx(HdTag, { text: translateTag(`tags|${review.size}`), color: theme.colors.grey_2, icon: _jsx(Employees, { size: 14 }) })), review.project_industry && (_jsx(HdTag, { text: translateTag(`tags|${review.project_industry}`), color: theme.colors.grey_2, icon: _jsx(Industry, { size: 14 }) })), (review.city || review.province) && (_jsx(HdTag, { text: formatCityTag(), color: theme.colors.grey_2, icon: _jsx(Location, { size: 14 }) }))] })] }))] }) })] }));
};
const ExpandIcon = ({ isActive }) => {
    return _jsx(TabArrow, { size: 12, down: isActive });
};
const Extras = ({ review }) => {
    const theme = useTheme();
    const isLarge = useMediaQuery({ minWidth: theme.breakpoints.l });
    const { t } = useTranslations();
    return (_jsxs(Div, { gap: 16, flex: "row", align: "center", justify: "center", tablet: { flex: 'column' }, children: [isLarge && review.project_service && (_jsx(RatingsBox, { label: t('review_card|rc_serviceReceived'), value: _jsx(HdTag, { color: theme.colors.purple_1, text: t(`tags|${review.project_service}`) }) })), isLarge && (_jsx(Divider, { style: {
                    borderColor: theme.colors.grey_4,
                    borderWidth: 1,
                    marginInline: 16,
                    marginBlock: 0,
                    height: 46,
                }, type: "vertical" })), _jsxs(Div, { wrap: "nowrap", flex: "column", justify: "center", children: [_jsx(Typography, { elementTheme: "overline", medium: true, textTransform: "uppercase", color: theme.colors.grey_2, textAlign: "right", children: t('review_card|rc_overallScore') }), _jsx(ReviewOverallScore, { review: review })] })] }));
};
const useReviewCollapseProps = ({ review, index, locale }) => {
    const [isActive, setOpen] = useState(false);
    const handleCollapse = () => setOpen(!isActive);
    const collapseProps = {
        ExpandIcon,
        styles: { container: { overflow: 'hidden', maxWidth: '100vw' } },
        id: `${index}-1`,
        headerIsColumnOnTablet: true,
        title: _jsx(ReviewHeader, { review: review }),
        children: (_jsx(ReviewBody, { review: review, locale: locale !== null && locale !== void 0 ? locale : 'en', index: index })),
        extra: _jsx(Extras, { review: review }),
        onClick: handleCollapse,
        isActive,
    };
    return collapseProps;
};
const ReviewCard = (props) => {
    const collapseProps = useReviewCollapseProps(props);
    return _jsx(ReviewCollapse, Object.assign({}, collapseProps));
};
export default ReviewCard;
