import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import Save from '@hellodarwin/icons/dist/icons/Save';
import Drawer from 'antd/es/drawer';
import Form, { useForm } from 'antd/es/form/Form';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import { useState } from 'react';
import getShortId from '../../../../../features/helpers/get-short-id';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Clipboard from '../../../../common/clipboard';
import Div from '../../../../common/div';
import RichTextEditor from '../../../../common/rich-editor';
import Typography from '../../../../common/typography';
import FormItem from '../../../form-layouts/form-item';
import FormLabel from '../../../form-layouts/form-label';
const quillModules = {
    toolbar: [
        [{ header: [3, 4, false] }],
        ['bold', 'italic', { list: 'bullet' }, { list: 'ordered' }],
    ],
    clipboard: {
        matchVisual: false,
    },
};
const FormPageEditButton = ({ page, functions }) => {
    var _a, _b;
    const [editModalOpen, setEditModalOpen] = useState(false);
    const handleEditModal = () => setEditModalOpen(!editModalOpen);
    const [form] = useForm();
    const theme = useTheme();
    const handleSubmit = () => {
        const values = form.getFieldsValue();
        const editedFormPage = Object.assign(Object.assign({}, page), values);
        functions.updateFormPage(editedFormPage);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { size: "circle", trailingIcon: _jsx(Edit, { width: 20, height: 20 }), onClick: handleEditModal, defaultStyle: theme.colors.white_1 }), _jsx(Drawer, { open: editModalOpen, onClose: handleEditModal, title: _jsxs(Div, { align: "center", flex: "row", style: { height: '100%' }, justify: "space-between", children: [_jsxs(Div, { fitContent: true, flex: "row", gap: 8, align: "center", children: [_jsx(Typography, { elementTheme: "subtitle1", lineClamp: 3, children: "Page :" }), _jsx(Typography, { elementTheme: "subtitle1", color: theme.colors.purple_1, colorHover: theme.colors.purple_2, children: _jsx(Clipboard, { title: getShortId(page.form_page_id), copy: page.form_page_id }) })] }), _jsx(Button, { size: "circle", defaultStyle: theme.colors.white_1, onClick: handleSubmit, children: _jsx(Save, { size: 16 }) })] }), children: _jsx(Form, { layout: "vertical", form: form, initialValues: page, onFinish: handleSubmit, children: _jsxs(Div, { flex: "column", gap: 24, children: [_jsx(FormItem, { name: 'page_number', label: _jsx(FormLabel, { label: "Page Number" }), children: _jsx(Input, { size: "large", disabled: true }) }), _jsx(FormItem, { name: 'page_type', label: _jsx(FormLabel, { label: "Page Type" }), children: _jsx(Select, { options: [
                                        {
                                            label: 'Default',
                                            value: '',
                                        },
                                        {
                                            label: 'Opening',
                                            value: 'opening',
                                        },
                                        {
                                            label: 'Elligible Ending',
                                            value: 'elligible-end',
                                        },
                                        {
                                            label: 'ICP Elligible Ending',
                                            value: 'elligible-end-icp',
                                        },
                                        {
                                            label: 'Maybe Elligible Ending',
                                            value: 'maybe-elligible-end',
                                        },
                                        {
                                            label: 'Non Elligible Ending',
                                            value: 'not-elligible-end',
                                        },
                                    ] }) }), _jsx(FormItem, { name: 'page_title_en', label: _jsx(FormLabel, { label: "Title En" }), children: _jsx(Input, {}) }), _jsx(FormItem, { name: 'page_title_fr', label: _jsx(FormLabel, { label: "Title Fr" }), children: _jsx(Input, {}) }), _jsx(FormItem, { name: 'page_heading_en', label: _jsx(FormLabel, { label: "Heading En" }), children: _jsx(RichTextEditor, { value: (_a = page.page_heading_en) !== null && _a !== void 0 ? _a : '' }) }), _jsx(FormItem, { name: 'page_heading_fr', label: _jsx(FormLabel, { label: "Heading Fr" }), children: _jsx(RichTextEditor, { value: (_b = page.page_heading_fr) !== null && _b !== void 0 ? _b : '' }) })] }) }) })] }));
};
export default FormPageEditButton;
