import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Gsp = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3569_2004)">
        <path
          d="M10.25 26.6C9.72002 27.6 10.2 28.8 11.1 29.13C12.03 29.47 13.15 28.8 13.34 27.83C13.47 27.14 13.11 26.39 12.44 25.99M10.25 26.6C10.34 26.47 10.61 26.1 11.13 25.92C11.77 25.7 12.31 25.93 12.44 25.99M10.25 26.6L4.30002 23.6M12.44 25.99C12.62 25.19 12.8 24.4 12.98 23.6M4.30002 23.6C3.82002 24.27 2.89002 24.46 2.16002 24.1C1.38002 23.71 1.02002 22.77 1.31002 21.98C1.54002 21.36 2.14002 20.9 2.82002 20.87C3.69002 20.84 4.20002 21.51 4.25002 21.57C4.65002 22.12 4.76002 22.96 4.30002 23.59V23.6ZM19.32 18.92C22.75 22.47 26.19 26.02 29.62 29.58M12.28 5.83001V17.93M9.28002 15.09L10.16 15.76C11.33 16.65 13.23 16.65 14.4 15.76C15.57 14.87 15.57 13.44 14.4 12.55C13.82 12.11 13.05 11.88 12.28 11.88C11.55 11.88 10.83 11.66 10.28 11.21C9.18002 10.32 9.18002 8.89001 10.28 8.00001C11.38 7.11001 13.18 7.11001 14.28 8.00001L14.7 8.33001M28.7 11.07C28.57 11.1 28.15 11.23 27.83 11.63C27.49 12.07 27.49 12.55 27.5 12.68M28.7 11.07C29.65 10.85 30.59 11.46 30.76 12.36C30.95 13.32 30.21 14.29 29.24 14.34C28.35 14.38 27.53 13.64 27.51 12.67C25.61 12.44 23.71 12.21 21.81 11.98M28.7 11.07L26.92 5.65001M24.74 4.02001L21.81 4.54001M19.67 18.55C22.04 15.82 21.85 12.41 21.8 11.67C21.67 9.45001 20.84 7.87001 20.56 7.40001C19.1 4.82001 16.86 3.69001 16.1 3.35001C15.05 2.87001 12.02 1.73001 8.62002 3.08001C5.20002 4.44001 3.81002 7.38001 3.48002 8.16001C3.27002 8.65001 1.70002 12.47 3.77002 16.42C5.54002 19.79 8.73002 20.88 9.53002 21.13C12.81 22.14 17.08 21.53 19.66 18.56L19.67 18.55ZM27.82 4.06001C27.82 2.07001 24.79 2.02001 24.75 4.03001C24.71 6.04001 27.82 6.10001 27.82 4.06001Z"
          stroke="currentColor"
          stroke-width={strokeWidth}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3569_2004">
          <rect
            width="31.38"
            height="28.94"
            fill="none"
            transform="translate(0.309998 1.53003)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Gsp;
