var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Upload from '@hellodarwin/icons/dist/icons/Upload';
import Tooltip from 'antd/es/tooltip';
import { useState } from 'react';
import theme from '../../../theme';
import AssetUploadModal from './asset-upload-modal';
import { AssetUploadButton } from './styles';
const AssetUpload = (_a) => {
    var { size, ghost } = _a, props = __rest(_a, ["size", "ghost"]);
    const [modalOpen, setModalOpen] = useState(false);
    const buttonSize = size === 'large' ? 32 : size === 'small' ? 16 : 24;
    const handleModal = () => setModalOpen(!modalOpen);
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: "Upload a new file to your company", children: _jsx(AssetUploadButton, { onClick: handleModal, size: "circle", "$ghost": ghost, transparent: ghost, defaultStyle: theme.colors.grey_1, trailingIcon: _jsx(Upload, { size: buttonSize }) }) }), _jsx(AssetUploadModal, Object.assign({ open: modalOpen, handleCancel: handleModal }, props))] }));
};
export default AssetUpload;
