import Div from '@hellodarwin/core/lib/components/common/div';
import Loading from '@hellodarwin/core/lib/components/loading';
import { Contact } from '@hellodarwin/core/lib/features/entities/core-entities';
import useDebounceFunction from '@hellodarwin/core/lib/features/hooks/use-debounce-function';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Select from 'antd/es/select';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  fetchCompanyContacts,
  selectAllCompanyContacts,
  selectCompanyContacysIsLoading,
} from '../../../features/api/slices/companies-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';

interface SelectCompanyContactProps {
  companyId?: string;
  handleSubmit: (contacts: Contact[]) => void;
}

const SEARCH_DELAY_MS = 500;
const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;

const SelectCompanyContact: React.FC<SelectCompanyContactProps> = ({
  companyId,
  handleSubmit,
}) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const contacts = useAppSelector(selectAllCompanyContacts);
  const searchContactsLoading = useAppSelector(selectCompanyContacysIsLoading);
  const { t } = useTranslations();

  const [contactOptions, setContactOptions] = useState(
    contacts?.map((contact) => ({
      key: contact.contact_id,
      value: contact.contact_id,
      label: `${contact.name} / ${contact.email}`,
    })) || undefined,
  );

  useEffect(() => {
    if (contacts) {
      if (!contacts || contacts.length < 1) {
        setContactOptions([]);
        return;
      }

      setContactOptions(
        contacts.map((contact) => ({
          key: contact.contact_id,
          value: contact.contact_id,
          label: `${contact.name} / ${contact.email}`,
        })),
      );
    }
  }, [contacts]);

  useEffect(() => {
    if (!!companyId) {
      dispatch(
        fetchCompanyContacts({
          api,
          company_id: companyId,
          page: DEFAULT_PAGE,
          limit: DEFAULT_LIMIT,
          query: '',
        }),
      );
    }
  }, [companyId, api, dispatch]);

  const onSearch = useDebounceFunction(
    async (query: string) => {
      if (!!companyId) {
        dispatch(
          fetchCompanyContacts({
            api,
            company_id: companyId,
            page: DEFAULT_PAGE,
            limit: DEFAULT_LIMIT,
            query,
          }),
        );
      }
    },
    SEARCH_DELAY_MS,
    true,
  );

  const onSelectContacts = (contact_ids: string[]) => {
    const selectedContacts = contacts.filter((contact) =>
      contact_ids.includes(contact.contact_id),
    );
    handleSubmit(selectedContacts);
  };

  return (
    <Select
      mode="multiple"
      style={{ width: '100%' }}
      filterOption={false}
      showSearch
      placeholder={t('createApplication|search_company_contacts')}
      defaultActiveFirstOption={false}
      options={contactOptions}
      loading={searchContactsLoading}
      onSearch={onSearch}
      onChange={(contactIds: string[]) => {
        onSelectContacts(contactIds);
      }}
      size="large"
      notFoundContent={
        searchContactsLoading ? (
          <Loading />
        ) : !!!companyId ? (
          <Div>{t('createApplication|select_a_company_first')}</Div>
        ) : !!!contactOptions || contactOptions.length === 0 ? (
          <Div>{t('createApplication|no_contact_found')}</Div>
        ) : null
      }
    />
  );
};

export default SelectCompanyContact;
