import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Popconfirm from 'antd/es/popconfirm';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import FormGroupEditButton from './form-builder-group-edit-button';
const FormBuilderGroupActions = ({ formGroupId, functions, builderGroup, formPageId, }) => {
    const theme = useTheme();
    const handleDeleteFormGroup = () => {
        functions.deleteFormGroup(formGroupId, formPageId);
    };
    return (_jsxs(Div, { flex: "row", gap: 8, justify: "center", align: "flex-end", fitContent: true, children: [_jsx(FormGroupEditButton, { builderGroup: builderGroup, functions: functions }), _jsx(Popconfirm, { title: "Delete the form Page", description: "Are you sure to delete this group ? You will lose all the items in it", onConfirm: handleDeleteFormGroup, onCancel: () => { }, okText: "Yes", cancelText: "No", children: _jsx("div", { children: _jsx(Button, { size: "circle", trailingIcon: _jsx(Delete, { size: 16 }), defaultStyle: theme.colors.purple_2 }) }) })] }));
};
export default FormBuilderGroupActions;
