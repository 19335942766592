import styled from 'styled-components';
import { createFontSizeCSS } from '../../../../../../theme/global-style';
import { HubspotFormStyling } from '../../../../../hubspot/hubspot-form/styles';
export const GrantNotificationContainer = styled.div `
  ${HubspotFormStyling} {
    ${createFontSizeCSS('body1')}
    h2 {
      ${createFontSizeCSS('h4', true, 'medium')}
    }
    p {
      &:first-child {
        margin-block-start: 0;
      }
      &:last-child {
        margin-block-end: 0;
      }
    }

    .field {
      & + .field {
        padding-left: 0;
      }
      label {
        margin-bottom: 8px;
        display: block;
      }
      input {
        min-height: 48px;
      }
    }
  }
`;
