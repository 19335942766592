import { useEffect, useMemo } from 'react';
import useLocale from '../../../../features/providers/locale-provider';
import { usePlatform } from '../../../../features/providers/platform-provider';
const mappedAssets = {
    client: '2MqdoOk3cNBjLYqaq5sN2R',
    provider: 'ruyckBQVPaUeyGf248lZc',
    admin: '7sl7UEf1wtoVpKFD5P3W28',
    'grant-provider': '5BhLwKipU1ucAmf4UKVSVj',
    portfolio: '7BQqtUgiD9iiwEwMXtY7Ix',
    default: 'yNnmq3QdK9QayVnVQ401X',
    compass: '',
};
const useDarwinLogoFallback = ({ type, size = 120, }) => {
    const { selectedLocale } = useLocale();
    const { fallbackLogos, addFallbackLogo } = usePlatform();
    const { contentfulClient } = usePlatform();
    useEffect(() => {
        (async () => {
            if (!fallbackLogos[type] && !!contentfulClient) {
                const asset = await contentfulClient.getAsset(mappedAssets[type], {
                    locale: selectedLocale,
                });
                addFallbackLogo(asset.fields.file.url, type);
            }
        })();
    }, [selectedLocale, type]);
    return useMemo(() => {
        var _a;
        const params = `?w=${size}&h=${size}&fit=thumb&f=center`;
        return `${(_a = fallbackLogos[type]) !== null && _a !== void 0 ? _a : 'https://images.ctfassets.net/uqnk3k6tiu47/yNnmq3QdK9QayVnVQ401X/a18c2a2766d8063e91fbd62640cdcd87/logo-compact-large.jpg'}${params}`;
    }, [fallbackLogos, type, size]);
};
export default useDarwinLogoFallback;
