import { IconProps, theme } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

interface BookmarkProps extends IconProps {
  disabled?: boolean;
  filled?: boolean;
  fillingColor?: string;
}
const Bookmark: React.FC<BookmarkProps> = ({
  disabled = false,
  filled = false,
  fillingColor = theme.purple_2,
  ...props
}) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {disabled ? (
        <path
          d="M1.97369 1.82842L4.46969 4.41665M4.46969 4.41665C4.68385 3.85683 5.03981 3.36738 5.5 2.99997C5.96019 2.63256 6.50752 2.40084 7.08419 2.32927C12.6585 1.65831 18.2889 1.65831 23.8632 2.32927C25.5132 2.52836 27 4.27656 27 5.99997V27.5M4.46969 4.41665L27 27.5M29.5 30L27 27.5M21 27L15.5 24L4 30V10.9336"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ) : (
        <path
          d="M24.0759 2.50322C25.7626 2.70231 27 4.17841 27 5.90182V30L15.5 24.1672L4 30V5.90182C4 4.17841 5.23587 2.70231 6.92407 2.50322C12.6222 1.83226 18.3778 1.83226 24.0759 2.50322Z"
          stroke="currentColor"
          fill={filled ? fillingColor : undefined}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
};

export default Bookmark;
