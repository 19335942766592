import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { ReviewFormSteps } from '../../../features/entities/rfp-status-flow-entities';
import ModalLayoutSimple from '../../common/hd-modal/layouts/modal-simple';
import ReviewForm from './review-form';
const ReviewFormModal = ({ provider, rfp_id, matchId, handleClose, open, editionFunctions, companyTags, }) => {
    const handleReviewProvider = async (reviewRequest) => {
        if (!!editionFunctions.review) {
            editionFunctions.review(rfp_id !== null && rfp_id !== void 0 ? rfp_id : '', provider.provider_id, reviewRequest, matchId);
        }
    };
    const [reviewStep, setReviewStep] = useState(ReviewFormSteps.Experience);
    const handleCancel = () => {
        setReviewStep(ReviewFormSteps.Experience);
        handleClose();
    };
    return (_jsx(ModalLayoutSimple, { open: open, handleCancel: handleCancel, size: reviewStep === ReviewFormSteps.Confirm ? 'medium' : 'large', children: _jsx(ReviewForm, { rfpId: rfp_id, providerId: provider.provider_id, providerName: provider.name, reviewProvider: handleReviewProvider, handleClose: handleCancel, reviewStep: reviewStep, setReviewStep: setReviewStep, tags: provider.tags, companyTags: companyTags !== null && companyTags !== void 0 ? companyTags : [] }) }));
};
export default ReviewFormModal;
