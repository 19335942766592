import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { DragDropContext, Draggable, } from 'react-beautiful-dnd';
import { useTranslations } from '../../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../../plugins/styled';
import Div from '../../../../../common/div';
import Drop from '../../../../../common/drag-drop/drop';
import Typography from '../../../../../common/typography';
import PortfolioAssetCard from './asset-card';
const reorder = (fileList, startIndex, endIndex) => {
    const result = Array.from(fileList);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
const grid = 8;
const getItemStyle = (isDragging, theme, draggableStyle) => (Object.assign({ userSelect: 'none', padding: grid * 2, width: 'fit-content', background: isDragging ? theme.colors.purple_4 : undefined }, draggableStyle));
const getListStyle = (isDraggingOver, theme) => ({
    background: isDraggingOver ? theme.colors.purple_5 : undefined,
    display: 'flex',
    flexWrap: 'wrap',
});
const PortfolioAssetsList = ({ assets, setAssets, }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const newAssets = reorder(assets, result.source.index, result.destination.index);
        setAssets(newAssets);
    };
    const deleteAsset = (asset) => {
        var temp = assets
            .map((el) => !!asset.temp_id
            ? el.temp_id === asset.temp_id
                ? Object.assign(Object.assign({}, el), { deleted: true }) : el
            : el.provider_portfolio_asset_id === asset.provider_portfolio_asset_id
                ? Object.assign(Object.assign({}, el), { deleted: true }) : el)
            .filter((itm) => !(!!itm.temp_id && !!itm.deleted));
        setAssets(temp);
    };
    const DraggableList = () => (_jsx(DragDropContext, { onDragEnd: onDragEnd, children: _jsx(Drop, { droppableId: "droppable", direction: "horizontal", children: React.Children.toArray(assets
                .filter((asset) => !asset.deleted)
                .map((asset, index) => {
                const key = asset.temp_id || asset.provider_portfolio_asset_id || '';
                return (_jsx(Draggable, { draggableId: key, index: index, children: (provided, snapshot) => (_jsx("div", Object.assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { style: getItemStyle(snapshot.isDragging, theme, provided.draggableProps.style), children: _jsx(PortfolioAssetCard, { asset: asset, deleteAsset: deleteAsset }) }))) }, key));
            })) }) }));
    return (_jsxs(Div, { style: { padding: 0 }, flex: "column", gap: 16, children: [_jsx(DraggableList, {}), _jsx(Typography, { color: theme.colors.grey_2, elementTheme: "caption", inline: true, style: { padding: grid * 2 }, children: t('profile_portfolio|formDisclaimerAssets') })] }));
};
export default PortfolioAssetsList;
