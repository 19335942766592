const urlRegexString = '^(https?:\\/\\/)?' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$';
const validateURL = async (url) => {
    try {
        const checked_url = url.includes('youtube.com')
            ? `https://www.youtube.com/oembed?format=json&url=https://www.youtube.com/watch?v=${url}`
            : url.includes('vimeo.com')
                ? `https://vimeo.com/api/oembed.json?url=${url}`
                : url;
        const status = await fetch(url)
            .then((response) => response.status)
            .catch((reason) => console.error(reason));
        const urlPattern = url.includes('localhost')
            ? new RegExp('/(localhost).')
            : new RegExp(urlRegexString, 'i');
        if (status && status <= 400 && !!urlPattern.test(url))
            return true;
        return false;
    }
    catch (e) {
        console.error(e);
        return false;
    }
};
export default validateURL;
