import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Typography from '../../../common/typography';
import ProfileSection from '../profile-section';
const ProviderMessage = ({ message }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    return (_jsx(ProfileSection, { title: `👋🏻 ${t('profile_sections|message')}`, id: "message", hide: !message.length, children: _jsx(Typography, { elementTheme: "body1", color: theme.colors.grey_4, children: message }) }));
};
export default ProviderMessage;
