import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Popconfirm from 'antd/es/popconfirm';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import FormPageEditButton from './form-page-edit-button';
const FormPageActions = ({ formPageId, functions, page, isEndPage, isOpeningPage, }) => {
    const theme = useTheme();
    const handleDelete = () => {
        functions.deleteFormPage(formPageId);
    };
    return (_jsxs(Div, { flex: 'row', gap: 8, justify: isEndPage || isOpeningPage ? 'flex-end' : undefined, fitContent: !(isEndPage || isOpeningPage), children: [_jsx(FormPageEditButton, { page: page, functions: functions }), _jsx(Popconfirm, { title: "Delete the form Page", description: "Are you sure to delete this page ? You will lose all the groups items in it", onConfirm: handleDelete, onCancel: () => { }, okText: "Yes", cancelText: "No", children: _jsx("div", { children: _jsx(Button, { size: "circle", trailingIcon: _jsx(Delete, { width: 20, height: 20 }), defaultStyle: theme.colors.white_1 }) }) })] }));
};
export default FormPageActions;
