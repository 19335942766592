import getItemTypeFromItemFieldType from '../utils/get-item-type-from-item-field-type';
import { findInfoByGroupId } from './group';
export const handleNewItem_DropInGroup_FromControls = async ({ form_id, functions, form_group_id, field_type, item_order, formPages, }) => {
    var _a, _b;
    const [form_group_response, form_page_response] = findInfoByGroupId({
        form_group_id,
        pages: formPages,
    });
    const form_group = {
        group_order: (_a = form_group_response === null || form_group_response === void 0 ? void 0 : form_group_response.group_order) !== null && _a !== void 0 ? _a : 0,
        form_id,
        form_group_id,
        form_page_id: (_b = form_page_response === null || form_page_response === void 0 ? void 0 : form_page_response.form_page_id) !== null && _b !== void 0 ? _b : '',
    };
    const form_item = {
        form_item_id: '',
        key: '',
        form_id: form_id,
        content_en: '',
        content_fr: '',
        type: getItemTypeFromItemFieldType(field_type),
        field_type,
    };
    const form_group_item = {
        forms_groups_items_id: '',
        form_group_id,
        form_item_id: '',
        forms_groups_items_order: item_order,
    };
    const newItem = {
        form_group_item,
        form_item,
        form_group,
    };
    functions.createItem(newItem);
};
export const handleReorderFormItems = ({ form_group_id, form_item_id, functions, formPages, item_order, }) => {
    var _a;
    const [group, _] = findInfoByGroupId({
        form_group_id,
        pages: formPages,
    });
    const items = [...((_a = group === null || group === void 0 ? void 0 : group.items) !== null && _a !== void 0 ? _a : [])];
    items.sort((g1, g2) => g1.forms_groups_items_order - g2.forms_groups_items_order);
    const movingItemId = items.findIndex((item) => item.form_item_id === form_item_id);
    const [removedItem] = items.splice(movingItemId, 1);
    items.splice(item_order, 0, removedItem);
    const formGroupsItems = [];
    items.forEach((item, index) => {
        var _a;
        formGroupsItems.push({
            form_group_id: (_a = group === null || group === void 0 ? void 0 : group.form_group_id) !== null && _a !== void 0 ? _a : '',
            forms_groups_items_id: item.forms_groups_items_id,
            forms_groups_items_order: index,
            form_item_id: item.form_item_id,
        });
    });
    functions.updateFormItemOrder(formGroupsItems);
};
