import {
  AdminProfile,
  AdminProfileInitialState,
} from '@hellodarwin/core/lib/features/entities/profile-entities';
import CoreFeatured from '@hellodarwin/core/lib/features/featured-flags';
import { FeatureFlagProvider } from '@hellodarwin/core/lib/features/providers/featured-flags-provider';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import AdminFeatured from '../../featured';
import UnverifiedAdminPage from '../../pages/unverified-page';
import { me, selectAuthentifiedUser } from '../api/slices/global-slice';
import { useAdminApi } from '../api/use-admin-api';

export interface UserContextType {
  admin: AdminProfile;
  isLoggedIn: boolean;
  defaultHomepage: string;
}

export const UserContext = React.createContext<UserContextType>({
  admin: AdminProfileInitialState,
  isLoggedIn: false,
  defaultHomepage: '/projects',
});

interface UserProviderProps extends PropsWithChildren {}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();

  const admin = useAppSelector(selectAuthentifiedUser);
  const isLoggedIn = useMemo(() => !!admin.admin_id, [admin]);

  const { changeLanguage } = useLocale();

  useEffect(() => {
    if (isLoggedIn) {
      changeLanguage(admin.preferred_language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    dispatch(me({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultHomepage = useMemo(
    () => admin.admin_homepage ?? '/projects',
    [admin],
  );

  if (admin.roles === 'Unverified') return <UnverifiedAdminPage />;

  return (
    <UserContext.Provider value={{ admin, isLoggedIn, defaultHomepage }}>
      <FeatureFlagProvider
        role={admin.roles}
        flags={{ ...CoreFeatured, ...AdminFeatured }}
      >
        {children}
      </FeatureFlagProvider>
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

export default UserProvider;
