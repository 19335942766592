import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Checkbox from 'antd/es/checkbox/Checkbox';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../../../theme';
import Button from '../../../common/button';
import Div from '../../../common/div';
import ModalLayoutSimple from '../../../common/hd-modal/layouts/modal-simple';
import Typography from '../../../common/typography';
const HideAssetModal = ({ open, onClose, meta, }) => {
    const { t } = useTranslation();
    const [hiddenState, setHiddenState] = useState();
    const handleHideShow = (e) => {
        e.preventDefault();
        if (meta.actions.onHideShow)
            meta.actions.onHideShow(hiddenState);
        onClose();
    };
    useEffect(() => {
        setHiddenState(meta === null || meta === void 0 ? void 0 : meta.hidden);
    }, [meta]);
    return (_jsx(ModalLayoutSimple, { styles: { wrapper: { zIndex: 3100, overflow: 'hidden' } }, open: open, noPadding: true, handleCancel: onClose, size: "small", children: _jsxs(Div, { flex: "column", justify: "space-evenly", align: "center", style: { padding: 24 }, children: [_jsx(Typography, { style: { fontSize: 24, fontWeight: 600, lineHeight: '28px' }, children: t('assets|hide.title') }), _jsxs(Div, { flex: "column", gap: 12, align: "flex-start", style: {
                        paddingBottom: 24,
                        marginBottom: 16,
                        marginTop: 16,
                    }, children: [_jsx(Checkbox, { checked: hiddenState === null || hiddenState === void 0 ? void 0 : hiddenState.is_admin_hidden, onChange: (e) => setHiddenState(Object.assign(Object.assign({}, hiddenState), { is_admin_hidden: e.target.checked })), children: _jsx(Typography, { style: {
                                    fontSize: 16,
                                    lineHeight: '24px',
                                }, children: t('assets|hide.admins') }) }), _jsx(Checkbox, { checked: hiddenState === null || hiddenState === void 0 ? void 0 : hiddenState.is_companies_hidden, onChange: (e) => setHiddenState(Object.assign(Object.assign({}, hiddenState), { is_companies_hidden: e.target.checked })), children: _jsx(Typography, { style: {
                                    fontSize: 16,
                                    lineHeight: '24px',
                                }, children: t('assets|hide.companies') }) }), _jsxs(Div, { flex: "row", gap: 8, align: "flex-start", children: [_jsx(Checkbox, { checked: hiddenState === null || hiddenState === void 0 ? void 0 : hiddenState.is_contacts_hidden, onChange: (e) => setHiddenState(Object.assign(Object.assign({}, hiddenState), { is_contacts_hidden: e.target.checked })) }), _jsxs(Div, { flex: "column", children: [_jsx(Typography, { style: {
                                                fontSize: 16,
                                                lineHeight: '24px',
                                            }, children: t('assets|hide.contacts.title') }), _jsx(Typography, { style: {
                                                color: theme.colors.purple_2,
                                                fontSize: 16,
                                                lineHeight: '24px',
                                            }, children: t('assets|hide.contacts.description') })] })] }), _jsxs(Div, { flex: "row", gap: 8, align: "flex-start", children: [_jsx(Checkbox, { checked: hiddenState === null || hiddenState === void 0 ? void 0 : hiddenState.is_providers_hidden, onChange: (e) => setHiddenState(Object.assign(Object.assign({}, hiddenState), { is_providers_hidden: e.target.checked })) }), _jsxs(Div, { flex: "column", children: [_jsx(Typography, { style: {
                                                fontSize: 16,
                                                lineHeight: '24px',
                                            }, children: t('assets|hide.providers.title') }), _jsx(Typography, { style: {
                                                color: theme.colors.purple_2,
                                                fontSize: 16,
                                                lineHeight: '24px',
                                            }, children: t('assets|hide.providers.description') })] })] })] }), _jsx(Button, { size: "small", style: { alignSelf: 'flex-end' }, defaultStyle: theme.colors.black, onClick: handleHideShow, children: t('assets|hide.save') })] }) }));
};
export default HideAssetModal;
