import message from 'antd/es/message';
import { useMemo } from 'react';
import { useTranslations } from '../providers/translations-provider';
const useOnFileFinishUpload = ({ handleSaveAsset, successMessage, errorMessage, }) => {
    const { t } = useTranslations();
    return useMemo(() => async (asset, values, records, owners) => {
        try {
            if (!values.asset_id && !asset) {
                message.error(t('assets|file_asset_required'));
                return new Error(t('assets|file_asset_required'));
            }
            const formData = new FormData();
            formData.append('file', asset);
            const fileRequest = {
                file_name: values.file_name,
                file_description: values.file_description,
                version: values.version,
                asset_id: values.asset_id,
                records,
                owners,
            };
            formData.append('request', JSON.stringify(fileRequest));
            const resultRequest = await handleSaveAsset(formData);
            if (resultRequest === 'fulfilled') {
                message.success(successMessage);
            }
            else {
                message.error(errorMessage);
            }
        }
        catch (e) {
            message.error(errorMessage);
            console.error(e);
        }
    }, [handleSaveAsset, successMessage, errorMessage]);
};
export default useOnFileFinishUpload;
