import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Email from '@hellodarwin/icons/dist/icons/Email';
import Language from '@hellodarwin/icons/dist/icons/Language';
import Location from '@hellodarwin/icons/dist/icons/Location';
import { GMAPS_API_KEY } from '../../../../api';
import { getLongAddress } from '../../../../features/helpers/get-address';
import prefixHTTPS from '../../../../features/helpers/prefix-https';
import useLocale from '../../../../features/providers/locale-provider';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { Status, Wrapper } from '../../../../features/wrappers/gmaps-wrapper';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import Loading from '../../../loading';
import ProfileSection from '../profile-section';
import ContactMap from './contact-map';
import ProfileContactSocials from './contact-socials';
import { InfoLineContainer } from './styles';
const ProfileContact = ({ provider, isEditable, isRfp, isWebsite, providerPrimaryActions, handleShowContact, handleRedirectToSettings, id, }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    const { selectedLocale } = useLocale();
    const { city, province, postal_code, country, address, email, website, name, } = provider;
    const hasAddress = !!address || !!city || !!province || !!postal_code || !!country;
    const completeAddress = getLongAddress(address !== null && address !== void 0 ? address : '', {
        address: address !== null && address !== void 0 ? address : '',
        city: city !== null && city !== void 0 ? city : '',
        province: province !== null && province !== void 0 ? province : '',
        postal_code: postal_code !== null && postal_code !== void 0 ? postal_code : '',
        country: country !== null && country !== void 0 ? country : '',
    });
    const InfoLine = ({ title, Icon, isLink, children, }) => {
        return (_jsxs(InfoLineContainer, { children: [_jsx(Icon, { size: 18, style: { marginTop: 3, flexShrink: 0 } }), _jsx(Typography, { elementTheme: "body2", children: isLink ? (_jsx("a", { href: prefixHTTPS(title.toLowerCase(), 'profile_contact'), target: "_blank", rel: "nofollow", children: title })) : (title) }), children] }));
    };
    const render = (status) => {
        switch (status) {
            case Status.FAILURE:
                return _jsx(_Fragment, {});
            case Status.LOADING:
                return _jsx(Loading, {});
            default:
                return (_jsxs(_Fragment, { children: [' ', !!completeAddress && (_jsx(ContactMap, { address: completeAddress, name: provider.name }))] }));
        }
    };
    return (_jsx(ProfileSection, { title: t('profile_contact|contactTeam', {
            company: provider === null || provider === void 0 ? void 0 : provider.name,
        }), id: id, isEditable: isEditable, handleEdit: handleRedirectToSettings, children: _jsxs(Div, { flex: "row", gap: 71, xl: { flex: 'column-reverse', style: { gap: 0 } }, style: { minHeight: 400 }, children: [_jsxs(Div, { flex: "column", gap: 32, style: { flex: 2 }, xl: { style: { gap: 0 } }, children: [_jsxs("div", { children: [website && _jsx(InfoLine, { title: website, isLink: true, Icon: Language }), !isRfp && (_jsx(_Fragment, { children: !!email && _jsx(InfoLine, { title: email, Icon: Email }) })), hasAddress && _jsx(InfoLine, { title: completeAddress, Icon: Location }), _jsx(ProfileContactSocials, Object.assign({}, provider))] }), !!isWebsite ? (_jsx(Button, { onClick: handleShowContact, textWrap: true, size: "small", fitContent: true, defaultStyle: theme.colors.yellow_1, children: t('profile_banners|bannerServicesTitle', {
                                company: name,
                            }) })) : (!!isRfp && _jsx(_Fragment, { children: providerPrimaryActions }))] }), _jsx(Wrapper, { apiKey: GMAPS_API_KEY, language: `${selectedLocale || 'en'}-ca`, region: "CA", id: "GOOGLE_MAPS", render: render, libraries: ['maps', 'places', 'marker'], library: "maps" })] }) }));
};
export default ProfileContact;
