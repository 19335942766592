import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Email from '@hellodarwin/icons/dist/icons/Email';
import Employees from '@hellodarwin/icons/dist/icons/Employees';
import HubspotLogo from '@hellodarwin/icons/dist/icons/HubspotLogo';
import IdIcon from '@hellodarwin/icons/dist/icons/IdIcon';
import Invoices from '@hellodarwin/icons/dist/icons/Invoices';
import Link from '@hellodarwin/icons/dist/icons/Link';
import Location from '@hellodarwin/icons/dist/icons/Location';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import Phone from '@hellodarwin/icons/dist/icons/Phone';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { dollarFormat } from '../../../features/helpers';
import { getStringDateFromString } from '../../../features/helpers/get-formatted-date';
import parse from '../../../features/helpers/parse';
import prefixHTTPS from '../../../features/helpers/prefix-https';
import useLocale from '../../../features/providers/locale-provider';
import { usePlatform } from '../../../features/providers/platform-provider';
import { useTranslations } from '../../../features/providers/translations-provider';
import Div from '../../common/div';
import HdTag from '../../common/hd-tag';
import formatPhoneNumber from '../../forms/normalizers/format-phone-number';
import BusinessProfileInfoRow from './business-profile-info-row';
import { BusinessProfileList } from './styles';
const BusinessProfile = ({ company, industries, subindustries, naicsCodes, }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    const { selectedLocale } = useLocale();
    const { app } = usePlatform();
    const isAdmin = useMemo(() => app === 'admin', [app]);
    const { program_description } = company;
    const mappedIndustries = useMemo(() => industries.reduce((o, i) => (Object.assign(Object.assign({}, o), { [i.value]: i.label })), {}), [industries]);
    const mappedSubindustries = useMemo(() => subindustries.reduce((o, i) => (Object.assign(Object.assign({}, o), { [i.value]: i.label })), {}), [subindustries]);
    const mappedNaicsCodes = useMemo(() => naicsCodes
        ? naicsCodes.reduce((o, i) => (Object.assign(Object.assign({}, o), { [i.value]: i.label })), {})
        : {}, [naicsCodes]);
    const isRichTextEmpty = (htmlString) => {
        var _a;
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
        tempDiv.querySelectorAll('br').forEach((br) => br.remove());
        return ((_a = tempDiv.textContent) === null || _a === void 0 ? void 0 : _a.trim()) === '';
    };
    const infos = useMemo(() => {
        var _a, _b;
        const { created_at, phone, website, email, size, annual_revenue, exact_annual_revenue, business_number, address, city, province, postal_code, country, hubspot_url, industry_sector, industry_subsector, naics_code, naics_entries, } = company;
        const formattedPhone = !!phone ? formatPhoneNumber(phone) : undefined;
        const formattedWebsite = !!website
            ? prefixHTTPS(website).toLowerCase()
            : undefined;
        return [
            isAdmin && {
                label: t('company|clientSince'),
                Icon: Timeline,
                value: !!created_at
                    ? getStringDateFromString(created_at, selectedLocale)
                    : '',
            },
            isAdmin && {
                label: 'Hubspot',
                Icon: HubspotLogo,
                value: !!hubspot_url ? (_jsxs("a", { href: hubspot_url, target: "_blank", children: [t('company|hubspotLink'), " ", _jsx(NewWindow, { size: 16 })] })) : (''),
            },
            isAdmin && {
                label: t('company|phone'),
                Icon: Phone,
                value: !!formattedPhone ? (_jsx("a", { href: `tel:${formattedPhone}`, children: formattedPhone })) : (''),
            },
            isAdmin && {
                label: t('company|companyEmail'),
                Icon: Email,
                value: !!email ? _jsx("a", { href: `mailto:${email}`, children: email }) : ' - ',
            },
            isAdmin && {
                label: t('company|website'),
                Icon: Link,
                value: !!formattedWebsite ? (_jsx("a", { href: `${formattedWebsite}`, target: "_blank", rel: "noreferrer", children: formattedWebsite })) : (' - '),
            },
            {
                label: t('company|businessNumber'),
                Icon: IdIcon,
                value: business_number !== null && business_number !== void 0 ? business_number : ' - ',
            },
            {
                label: t('company|annualRevenue'),
                Icon: Invoices,
                value: annual_revenue !== null && annual_revenue !== void 0 ? annual_revenue : ' - ',
            },
            {
                label: t('company|exactAnnualRevenue'),
                Icon: Invoices,
                value: exact_annual_revenue
                    ? dollarFormat(exact_annual_revenue, selectedLocale, true)
                    : ' - ',
            },
            {
                label: t('company|companySize'),
                Icon: Employees,
                value: !!(size === null || size === void 0 ? void 0 : size.length) ? t(`company|companySizeValue`, { size }) : ' - ',
            },
            {
                label: t('company|companyAddress'),
                Icon: Location,
                value: (_b = (_a = [address, city, province, country, postal_code]) === null || _a === void 0 ? void 0 : _a.filter((i) => !!i)) === null || _b === void 0 ? void 0 : _b.join(', '),
            },
            isAdmin && {
                label: t('company|industry'),
                Icon: Employees,
                value: (industry_sector === null || industry_sector === void 0 ? void 0 : industry_sector.length) || (industry_subsector === null || industry_subsector === void 0 ? void 0 : industry_subsector.length) ? (_jsxs(Div, { flex: "row", wrap: "wrap", gap: 8, children: [React.Children.toArray(industry_sector === null || industry_sector === void 0 ? void 0 : industry_sector.map((tag) => (_jsx(HdTag, { text: mappedIndustries[tag], color: theme.colors.purple_2 }, tag)))), React.Children.toArray(industry_subsector === null || industry_subsector === void 0 ? void 0 : industry_subsector.map((tag) => (_jsx(HdTag, { text: mappedSubindustries[tag], color: theme.colors.purple_3 }, tag))))] })) : ('-'),
            },
            naics_code &&
                Object.keys(mappedNaicsCodes).length && {
                label: t('company|naicsCode'),
                Icon: IdIcon,
                value: (naics_code === null || naics_code === void 0 ? void 0 : naics_code.length) ? (_jsx(Div, { flex: "row", wrap: "wrap", gap: 8, children: React.Children.toArray(naics_code === null || naics_code === void 0 ? void 0 : naics_code.map((tag) => (_jsx(HdTag, { text: mappedNaicsCodes[tag], color: theme.colors.purple_4 }, tag)))) })) : ('-'),
            },
        ].filter((itm) => !!itm);
    }, [company, mappedIndustries, mappedSubindustries, mappedNaicsCodes]);
    return (_jsxs(Div, { flex: "column", gap: 32, children: [!!program_description && !isRichTextEmpty(program_description) && (_jsx("div", { children: parse(program_description) })), _jsx(BusinessProfileList, { children: infos.map((info) => (_jsx(BusinessProfileInfoRow, Object.assign({}, info)))) })] }));
};
export default BusinessProfile;
