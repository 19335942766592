'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Filter from '@hellodarwin/icons/dist/icons/Filter';
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import { useTheme } from 'styled-components';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
const AdvancedFiltersButton = ({ handleAdvancedFilters, advancedFiltersOpened, }) => {
    const theme = useTheme();
    const { t } = useTranslations();
    return (_jsx(Button, { defaultStyle: theme.colors.purple_3, style: {
            height: 45,
            flex: 1,
            borderRadius: 4,
            gap: 24,
            minWidth: 220,
        }, xl: {
            style: {
                alignItems: 'stretch',
                gap: 0,
                padding: '0 12px',
                flexDirection: 'column',
            },
        }, onClick: handleAdvancedFilters, children: _jsxs(Div, { flex: "row", gap: 24, xl: {
                gap: 12,
                justify: 'space-between',
                fitContent: false,
            }, style: { padding: '0 12px' }, align: "center", children: [_jsxs(Div, { flex: "row", gap: 24, fitContent: true, align: "center", xl: { style: { width: '100% !important' }, gap: 12 }, children: [_jsx(Filter, { size: 20 }), t(`grantsFundingExplorer|grantsfundingExplorerFilterAdvancedFilters`)] }), _jsx(TabArrow, { size: 16, down: !!advancedFiltersOpened })] }) }));
};
export default AdvancedFiltersButton;
