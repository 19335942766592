import { DocumentProps, PDFDownloadLink, usePDF } from '@react-pdf/renderer';
import { PropsWithChildren } from 'react';

export interface DarwinPdfDownloadProps extends PropsWithChildren {
  document: React.ReactElement<DocumentProps>;
  fileName: string;
  onDownload?: () => void;
}

const DarwinPdfDownload: React.FC<DarwinPdfDownloadProps> = ({
  document,
  fileName,
  onDownload,
  children,
}) => {
  const [instance, update] = usePDF({ document });
  const formattedFileName = fileName.replace(/\./g, '');

  instance.blob;
  return (
    <PDFDownloadLink fileName={formattedFileName} document={document}>
      <span onClick={() => onDownload?.()}>{children}</span>
    </PDFDownloadLink>
  );
};

export default DarwinPdfDownload;
