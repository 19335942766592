import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import Logout from '@hellodarwin/icons/dist/icons/Logout';
import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLogin } from '../../../../features/providers/login-provider';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import DesktopUserMenu from './desktop';
import MobileUserMenu from './mobile';
const UserMenu = (props) => {
    const theme = useTheme();
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    const { handleLogout } = useLogin();
    const { t } = useTranslations();
    const { links, user } = props;
    const items = useMemo(() => [
        ...links,
        {
            pathname: '?logout=true',
            onClick: handleLogout,
            label: t('navigation|logout'),
            Icon: Logout,
            withBorderTop: true,
        },
    ], [links]);
    if (!user)
        return _jsx(_Fragment, {});
    const Menu = isTablet ? MobileUserMenu : DesktopUserMenu;
    return _jsx(Menu, Object.assign({}, props, { links: items }));
};
export default UserMenu;
