import FormItem from 'antd/es/form/FormItem';
import styled from '../../../../plugins/styled';
export const StyledFormItem = styled(FormItem) `
  margin-bottom: 0;
  ${({ $hideContent }) => $hideContent
    ? `
      &.ant-form-item:not(.ant-form-item-horizontal){
        .ant-form-item-label{
          padding:0;
          }
          .ant-form-item-control  {
            display:none;
            }
            }
`
    : undefined}
  .ant-form-item-label label {
    width: 100%;
    &:after {
      display: none;
    }
  }
  .ant-form-item-required {
    &:after,
    &:before {
      display: none !important;
    }
    .hd-form-label {
      &:after {
        display: inline-block;
        margin-inline-start: 4px;
        color: #ff4d4f;
        font-size: 16px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
      }
    }
  }
`;
