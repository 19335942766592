export * from './companies-entities';
export * from './contact-entities';
export * from './provider-portfolios-entities';
export * from './providers-entities';
export const InitialLocalisation = {
    lat: 0,
    lng: 0,
};
export const AddressInformationsInitialState = {
    street_number: '',
    subpremise: '',
    route: '',
    administrative_area_level_1: '',
    administrative_area_level_2: '',
    postal_code: '',
    country: '',
    locality: '',
    neighborhood: '',
};
export var AppName;
(function (AppName) {
    AppName["Admin"] = "Admin";
    AppName["Partner"] = "Partner";
    AppName["Client"] = "Client";
    AppName["Website"] = "Website";
})(AppName || (AppName = {}));
