import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Checkbox from 'antd/es/checkbox';
import Input from 'antd/es/input';
import TextArea from 'antd/es/input/TextArea';
import Radio from 'antd/es/radio';
import Select from 'antd/es/select';
import Space from 'antd/es/space';
import React from 'react';
import parse from '../../../../../features/helpers/parse';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import FileUploader from '../../../form-groups/uploader/file';
import FormItem from '../../../form-layouts/form-item';
import FormLabel from '../../../form-layouts/form-label';
import { DragDropContainer } from '../../styles';
import { itemDescriptionOptions } from '../../utils/form-parse-options';
import getItemTranslatedLabel from '../../utils/get-item-translated-label';
import { getSelectOptions } from '../../utils/get-select-options';
const FormItemLayoutContent = ({ type, entity, locale }) => {
    var _a;
    switch (type) {
        case 'datetime':
            return _jsx(Input, { type: "date" });
        case 'input':
        case 'shortText':
            return _jsx(Input, {});
        case 'text':
            return _jsx(TextArea, {});
        case 'select':
            const select_options = !!(entity === null || entity === void 0 ? void 0 : entity.form_select_options)
                ? getSelectOptions(entity.form_select_options, locale)
                : [];
            return (_jsx(Select, { value: (_a = select_options === null || select_options === void 0 ? void 0 : select_options[0]) === null || _a === void 0 ? void 0 : _a.value, options: select_options }));
        case 'radio':
            const radio_options = !!(entity === null || entity === void 0 ? void 0 : entity.form_select_options)
                ? getSelectOptions(entity.form_select_options, locale)
                : [
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' },
                ];
            return (_jsx(Radio.Group, { children: _jsx(Space, { direction: (entity === null || entity === void 0 ? void 0 : entity.disposition) === 'vertical' ? 'vertical' : 'horizontal', children: React.Children.toArray(radio_options.map((option) => (_jsx(Radio, { value: option.value, children: option.label })))) }) }));
        case 'checkbox':
            const checkbox_options = !!(entity === null || entity === void 0 ? void 0 : entity.form_select_options)
                ? getSelectOptions(entity.form_select_options, locale)
                : [
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' },
                ];
            return (_jsx(Checkbox.Group, { children: _jsx(Space, { direction: (entity === null || entity === void 0 ? void 0 : entity.disposition) === 'vertical' ? 'vertical' : 'horizontal', children: React.Children.toArray(checkbox_options.map((option) => (_jsx(Checkbox, { value: option.value, children: option.label })))) }) }));
        case 'attachment':
            return _jsx(FileUploader.Demo, {});
        default:
            return _jsx(_Fragment, {});
    }
};
const FormItemLayout = (props) => {
    var _a;
    const { type, isSimple, entity, locale } = props;
    return isSimple ? (_jsx(FormItem, { label: _jsx(FormLabel, { label: getItemTranslatedLabel({
                label_fr: entity === null || entity === void 0 ? void 0 : entity.content_fr,
                label_en: entity === null || entity === void 0 ? void 0 : entity.content_en,
                fallback: (_a = entity === null || entity === void 0 ? void 0 : entity.field_type) !== null && _a !== void 0 ? _a : '',
                locale,
            }), extra: getItemTranslatedLabel({
                label_fr: entity === null || entity === void 0 ? void 0 : entity.heading_fr,
                label_en: entity === null || entity === void 0 ? void 0 : entity.heading_en,
                fallback: '',
                locale,
                parseOptions: itemDescriptionOptions,
            }) }), style: { width: '100%' }, children: _jsx(FormItemLayoutContent, Object.assign({}, props)) })) : (_jsxs(Div, { flex: "column", gap: 8, children: [_jsx(Typography, { textTransform: "capitalize", elementTheme: "body3", style: { fontWeight: 600 }, children: type }), _jsx(DragDropContainer, { "$isMovable": true, children: _jsx(FormItem, { label: _jsx(FormLabel, { label: !!(entity === null || entity === void 0 ? void 0 : entity.content_fr) && locale === 'fr'
                            ? entity.content_en
                            : !!(entity === null || entity === void 0 ? void 0 : entity.content_en) && locale === 'en'
                                ? entity.content_en
                                : type === null || type === void 0 ? void 0 : type.toUpperCase(), extra: !!(entity === null || entity === void 0 ? void 0 : entity.heading_fr) && locale === 'fr'
                            ? parse(entity.heading_fr, itemDescriptionOptions)
                            : !!(entity === null || entity === void 0 ? void 0 : entity.heading_en) && locale === 'en'
                                ? parse(entity.heading_en, itemDescriptionOptions)
                                : undefined }), style: { width: '100%' }, children: _jsx(FormItemLayoutContent, Object.assign({}, props)) }) })] }));
};
export default FormItemLayout;
