import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import React from 'react';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTranslation } from '../../../../plugins/i18n';
import { useTheme } from '../../../../plugins/styled';
import Collapse from '../../../common/Collapse';
import { Desktop } from '../../../common/Media';
import Div from '../../../common/div';
import HdTag from '../../../common/hd-tag';
import Typography from '../../../common/typography';
const ExpertiseCard = ({ category, services, specialties }) => {
    const { t: translateTag } = useTranslation(['tags'], { useSuspense: false });
    const { t } = useTranslations();
    const theme = useTheme();
    return (_jsx(_Fragment, { children: _jsx(Collapse, { ExpandIcon: ({ isActive }) => (_jsxs(Div, { gap: 14, flex: "row", align: "center", fitContent: true, style: { height: 60 }, tablet: { style: { height: 'auto' } }, children: [_jsx(Desktop, { direction: "up", children: _jsx(Typography, { medium: true, textTransform: "uppercase", letterSpacing: 0.36, nowrap: true, elementTheme: "overline", children: isActive
                                ? t('profile_expertise|seeLessDetails')
                                : t('profile_expertise|seeMoreDetails') }) }), _jsx(TabArrow, { down: isActive, width: 8, height: 4 })] })), items: [
                {
                    id: '1',
                    headerIsColumnOnTablet: true,
                    title: (_jsx(Typography.Title, { level: 3, elementTheme: "h6", noMargin: true, style: { textTransform: 'capitalize' }, children: translateTag(`tags|${category}`) })),
                    children: (_jsxs(Div, { flex: "column", gap: 16, children: [(services === null || services === void 0 ? void 0 : services.length) > 0 && (_jsxs(Div, { flex: "column", gap: 16, children: [_jsx(Typography.Title, { level: 4, color: theme.colors.grey_2, elementTheme: "caption", textTransform: "uppercase", bold: true, nowrap: true, noMargin: true, children: t('profile_expertise|expertisesServiceTitle') }), _jsx(Div, { flex: "column", gap: 16, children: _jsx(Div, { flex: "column", gap: 16, children: React.Children.toArray(services === null || services === void 0 ? void 0 : services.map((service, serviceIndex) => (_jsxs(Div, { flex: "column", gap: 10, children: [_jsx(Typography, { color: theme.colors.grey_2, elementTheme: "overline", textTransform: "uppercase", medium: true, nowrap: true, children: translateTag(`tags|${service === null || service === void 0 ? void 0 : service.tag}`) }), _jsx(Typography, { elementTheme: "body2", children: service === null || service === void 0 ? void 0 : service.description })] }, service === null || service === void 0 ? void 0 : service.tag)))) }) })] })), (specialties === null || specialties === void 0 ? void 0 : specialties.length) > 0 && (_jsxs(Div, { flex: "column", gap: 24, children: [_jsxs(Typography, { color: theme.colors.grey_2, elementTheme: "overline", textTransform: "uppercase", medium: true, nowrap: true, children: [t('profile_expertise|expertisesSpecialtiesTitle'), translateTag(`tags|${category}`)] }), _jsx(Div, { flex: "row", wrap: "wrap", gap: 16, children: React.Children.toArray(specialties === null || specialties === void 0 ? void 0 : specialties.map((specialty, specialtyIndex) => (_jsx(HdTag, { text: translateTag(`tags|${specialty}`) }, specialtyIndex)))) })] }))] })),
                },
            ] }) }));
};
export default ExpertiseCard;
