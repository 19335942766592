import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import getMeetingUrl from '../../../features/helpers/get-meeting-url';
import useLocale from '../../../features/providers/locale-provider';
import { useTranslations } from '../../../features/providers/translations-provider';
import { useTheme } from '../../../plugins/styled';
import createBorderStyle from '../../../theme/utils/create-border-style';
import Avatar from '../../common/avatar';
import Button from '../../common/button';
import Typography from '../../common/typography';
import { AdminCardBody, AdminCardContainer, AdminCardCover } from './styles';
const AdminCard = ({ admin, title, subtitle, fullWidth = false, layout = 'horizontal', fullHeight = false, }) => {
    var _a, _b;
    const { t } = useTranslations();
    const theme = useTheme();
    const { selectedLocale } = useLocale();
    const meetingUrl = useMemo(() => {
        const url = selectedLocale === 'en' ? admin === null || admin === void 0 ? void 0 : admin.meeting_link_en : admin === null || admin === void 0 ? void 0 : admin.meeting_link_fr;
        if (!url) {
            return getMeetingUrl(selectedLocale, 'client', 'projects');
        }
        return url;
    }, [admin, selectedLocale]);
    const handleOpenMeetingLink = () => {
        window.open(meetingUrl, '_blank');
    };
    return (_jsxs(AdminCardContainer, { "$layout": layout, "$fullHeight": fullHeight, "$fullWidth": fullWidth, children: [_jsxs(AdminCardCover, { children: [_jsx(Avatar, { size: 180, shape: "square", style: Object.assign({ borderRadius: 4 }, createBorderStyle({ color: theme.colors.grey_1 })), src: ((_a = admin === null || admin === void 0 ? void 0 : admin.picture) === null || _a === void 0 ? void 0 : _a.replace('s96', 's180')) || '', children: (_b = admin === null || admin === void 0 ? void 0 : admin.first_name) === null || _b === void 0 ? void 0 : _b[0] }), _jsxs("div", { children: [_jsxs(Typography, { textAlign: "center", elementTheme: "subtitle1", nowrap: true, ellipsis: true, color: theme.colors.purple_2, children: [admin.first_name, " ", admin.last_name] }), _jsx(Typography, { textAlign: "center", elementTheme: "body2", nowrap: true, ellipsis: true, color: theme.colors.grey_2, children: title })] })] }), _jsxs(AdminCardBody, { children: [_jsx(Typography, { textAlign: layout === 'vertical' ? 'center' : 'left', tablet: { textAlign: 'center' }, children: subtitle }), _jsx(Button, { onClick: handleOpenMeetingLink, defaultStyle: theme.colors.purple_2, size: "small", children: t('adminCard|adminCta') })] })] }));
};
export default AdminCard;
