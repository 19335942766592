import {
  DollarOutlined,
  ExceptionOutlined,
  ExclamationCircleOutlined,
  PaperClipOutlined,
  ProjectOutlined,
  TeamOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import Container from '@hellodarwin/core/lib/components/common/container';
import Div from '@hellodarwin/core/lib/components/common/div';
import { TagProps } from '@hellodarwin/core/lib/components/common/hd-tag';
import TagsList from '@hellodarwin/core/lib/components/common/hd-tag/tags-list';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import ProfileUsersList from '@hellodarwin/core/lib/components/profile/profile-users-list';
import {
  AdminRfpRequest,
  Company,
  Contact,
  InitialAdminSelectedTags,
  Match,
  Rfp,
} from '@hellodarwin/core/lib/features/entities';
import RfpStatus from '@hellodarwin/core/lib/features/enums/rfp-status';
import RfpType from '@hellodarwin/core/lib/features/enums/rfp-type';
import {
  copyToClipboard,
  getFormattedDate,
  getShortFormattedDate,
  getShortId,
} from '@hellodarwin/core/lib/features/helpers';
import { getRfpStatusColor } from '@hellodarwin/core/lib/features/helpers/get-status-color';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import theme from '@hellodarwin/core/lib/theme';
import Button from 'antd/es/button';
import { FormInstance } from 'antd/es/form';
import { Col, Row } from 'antd/es/grid';
import InputNumber from 'antd/es/input-number';
import message from 'antd/es/message';
import Modal from 'antd/es/modal';
import notification from 'antd/es/notification';
import Select from 'antd/es/select';
import { TabsProps } from 'antd/es/tabs';
import Tooltip from 'antd/es/tooltip';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import Auth0UserButtons from '../../components/auth0/contacts-auth0-buttons';
import BlockButton from '../../components/blocks/block-button';
import BlocksList from '../../components/blocks/blocks-list';
import MatchesBlock from '../../components/blocks/matches-block';
import Clipboard from '../../components/clipboard';
import CompanyCard from '../../components/companies/company-card';
import CompanyForm, {
  CompanyFormValues,
} from '../../components/companies/company-form';
import ContactsForm from '../../components/contacts/contacts-form';
import { CreateFormValues } from '../../components/forms/single-form/create-form-builder';
import { GrantFormValues } from '../../components/grants/grant-form/grant-form';
import { GrantProviderFormValues } from '../../components/grants/grant-provider-form';
import List from '../../components/list/list';
import Manifest from '../../components/manifests/manifest';
import ManualChargeForm from '../../components/matches/manual-charge-form';
import ManualMatchForm from '../../components/matches/manual-match-form';
import ManualReferralCredits from '../../components/matches/manual-referral-credits';
import RaiseHandForm from '../../components/matches/raise-hand-form';
import ShortlistForm from '../../components/matches/shortlist-form';
import ChargeWinFeeForm from '../../components/matches/win-fee-form';
import { showTagsWarning } from '../../components/messages/show-tags-warning';
import { ProgramFormValues } from '../../components/programs/program-form';
import { ProviderFormValues } from '../../components/providers/provider-form';
import QuickAction from '../../components/quick-action';
import ReviewNotificationForm, {
  ReviewNotificationFormValues,
} from '../../components/reviews/review-notification-form';
import BlacklistForm from '../../components/rfp/blacklist-form';
import { EMAIL_ALREADY_EXISTS_ERROR } from '../../components/rfp/rfp-action-modal/create-rfp-form';
import { RfpDescriptionTemplateFormValues } from '../../components/rfp/rfp-description-template-form';
import {
  default as RfpForm,
  RfpFormValues,
} from '../../components/rfp/rfp-form';
import ExternalLink from '../../components/widgets/external-link';
import ReferenceWidget from '../../components/widgets/reference-widget';
import { useActionBar } from '../../context/action-bar-provider';
import {
  fetchAdmins,
  selectAllAdmins,
} from '../../features/api/slices/admins-slice';
import {
  fetchCompany,
  selectCompanyById,
  updateCompany,
} from '../../features/api/slices/companies-slice';
import {
  activateRfp,
  fetchRfp,
  fetchRfpPurchasedMatches,
  fetchRfpRefusedMatches,
  fetchRfpTags,
  selectActiveTab,
  selectManualMatches,
  selectPurchasedMatches,
  selectRefusedMatches,
  selectRfpById,
  setActiveTab,
  setSelectedRfp,
  toggleRfpsModal,
  updateRfp,
  updateRfpTags,
} from '../../features/api/slices/rfp-slice';
import { fetchTags, selectAllTags } from '../../features/api/slices/tags-slice';
import {
  assignContact,
  createContact,
  deleteContact,
  fetchCompanyContacts,
  getContactByEmail,
  selectCompanyContacts,
  selectContactById,
  updateContact,
} from '../../features/api/slices/users-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
import { getStatusTooltip } from '../../features/utils';
import { BlockContent, useBlocks } from '../../hooks/use-blocks';
import { useForceUpdate } from '../../hooks/use-force-update';
import { default as ActionBar } from '../../layout/action-bar';
import { GrantContentFormValues } from '../single-grant-context-page';
import RfpActions from './rfp-actions';
import RfpStatusDropdown from './rfp-status-dropdown';

const { Option } = Select;

const blocksInitialState: BlockContent[] = [
  {
    element: (
      <RfpForm
        admins={[]}
        setForms={() => null}
        tags={InitialAdminSelectedTags}
      />
    ),
    icon: <ProjectOutlined />,
    label: '',
    visible: true,
    unique: false,
  },
  {
    element: (
      <ContactsForm
        contact={{} as Contact}
        setForms={() => null}
        destination={'Client'}
      />
    ),
    icon: <UserOutlined />,
    label: '',
    visible: true,
    unique: false,
  },
  {
    element: <MatchesBlock matches={[]} targetId={''} />,
    icon: <TeamOutlined />,
    label: '',
    visible: false,
    unique: true,
  },
];

export interface AdminPagesForms {
  companyForm?: FormInstance<CompanyFormValues> | undefined;
  rfpForm?: FormInstance<RfpFormValues> | undefined;
  contactForm?: FormInstance<Contact> | undefined;
  providerForm?: FormInstance<ProviderFormValues> | undefined;
  programForm?: FormInstance<ProgramFormValues> | undefined;
  reviewNotificationForm?:
    | FormInstance<ReviewNotificationFormValues>
    | undefined;
  rfpDescriptionTemplate?:
    | FormInstance<RfpDescriptionTemplateFormValues>
    | undefined;
  grantForm?: FormInstance<GrantFormValues> | undefined;
  grantProviderForm?: FormInstance<GrantProviderFormValues> | undefined;
  grantContentForm?: FormInstance<GrantContentFormValues> | undefined;
  createForm?: FormInstance<CreateFormValues> | undefined;
}

enum ModalType {
  DiscountProjectModal = 'DiscountProjectModal',
}

const SingleRfpPage = () => {
  const { setActionBar } = useActionBar();
  const { id } = useParams<{ id: string }>();
  const rfp = useAppSelector((state) => selectRfpById(state, id || ''));
  const company = useAppSelector((state) =>
    selectCompanyById(state, rfp?.company_id || ''),
  );
  const contact = useAppSelector((state) =>
    selectContactById(state, rfp?.contact_id || ''),
  );
  const companyContacts = useAppSelector((state) =>
    selectCompanyContacts(state, company?.company_id || ''),
  );
  const [blocks, setBlock, toggleBlock, span] = useBlocks(blocksInitialState);
  const [isDiscountInputVisible, setIsDiscountInputVisible] = useState(false);
  const [rfpDiscount, setRfpDiscount] = useState<number>(0);
  const [hasAttachments, setHasAttachments] = useState<Boolean>(false);
  const admins = useAppSelector(selectAllAdmins);
  const [forms, setForms] = useState<AdminPagesForms>({
    companyForm: undefined,
    rfpForm: undefined,
    contactForm: undefined,
  });
  const api = useAdminApi();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const refusedMatches = useAppSelector(selectRefusedMatches);
  const purchasedMatches = useAppSelector(selectPurchasedMatches);
  const manualMatches = useAppSelector(selectManualMatches);
  const tags = useAppSelector(selectAllTags);
  const activeTab = useAppSelector(selectActiveTab);
  const { t } = useTranslation();

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(fetchRfp({ api, rfpId: id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rfp && rfp.company_id && rfp.company_id !== '') {
      setRfpDiscount(rfp?.discounted_amount || 0);
      dispatch(fetchCompanyContacts({ api, companyId: rfp.company_id }));
      dispatch(fetchCompany({ api, companyId: rfp.company_id }));
      dispatch(
        fetchAdmins({
          api,
          needsAuth0: false,
          page: 1,
          limit: 100,
          search: '',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfp?.company_id, rfp?.discounted_amount]);

  useEffect(() => {
    if (rfp) {
      setBlock(
        0,
        <Div flex="column" gap="1">
          <RfpForm
            admins={admins}
            rfp={rfp}
            setForms={setForms}
            setHasAttachments={setHasAttachments}
            tags={tags}
          />
          <ReviewNotificationForm rfp={rfp} setForms={setForms} />
        </Div>,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admins, rfp, tags]);

  useEffect(() => {
    if (rfp) {
      setBlock(
        1,
        <Div flex="column" gap="1">
          <ContactsForm
            contact={contact}
            setForms={setForms}
            company={company}
            destination={'Client'}
            rfp={rfp}
          />
          {company && (
            <CompanyForm company={company} setForms={setForms} rfp={rfp} />
          )}
        </Div>,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, company, rfp]);

  const onSave = async () => {
    try {
      const rfpFormValues = forms.rfpForm?.getFieldsValue()!;
      const companyFormValues = forms.companyForm?.getFieldsValue()!;
      const contactFormValues = forms.contactForm?.getFieldsValue()!;
      if (
        rfpFormValues === undefined ||
        companyFormValues === undefined ||
        contactFormValues === undefined
      ) {
        return;
      }

      const updatedcompany: Company = {
        ...company,
        ...companyFormValues,
        company_id: company!.company_id,
      };
      updatedcompany.industry = tags.selectedIndustry;
      await dispatch(updateCompany({ api, company: updatedcompany }));

      if (contact) {
        const updatedContact: Contact = {
          ...contact,
          ...contactFormValues,
        };
        await dispatch(updateContact({ api, contact: updatedContact }));
      }

      const updatedRfpValues: AdminRfpRequest = {
        ...rfp,
        ...rfpFormValues,
      };
      if (updatedRfpValues.description === '<p><br></p>') {
        updatedRfpValues.description = '';
      }
      await dispatch(updateRfp({ api, rfp: updatedRfpValues }));

      await dispatch(
        updateRfpTags({
          api,
          rfpID: rfp!.rfp_id,
          serviceTags: tags.selectedServices,
          specialtyTags: tags.selectedSpecialties,
          industryTag: tags.selectedIndustry,
        }),
      );

      message.success('Saved!');
    } catch (e: any) {
      if (e.response.data.error_code === EMAIL_ALREADY_EXISTS_ERROR) {
        notification.error({
          message: `Email already in use!`,
          description:
            'Please use a new email or search for an existing client.',
          placement: 'bottomLeft',
        });
        return;
      }
      message.error('Something went wrong, try again later!');
      console.error(e);
    }
  };

  const quickActions = [
    <QuickAction
      content={<BlacklistForm rfpId={id || ''} />}
      key={id}
      title="Blacklist"
      icon={<ExceptionOutlined />}
    />,
  ];

  const actionBar = (
    <ActionBar children quickActions={quickActions}></ActionBar>
  );

  const openModal = (modalType: ModalType) => {
    switch (modalType) {
      case 'DiscountProjectModal':
        dispatch(setSelectedRfp(rfp!));
        dispatch(toggleRfpsModal({ type: 'discount', isVisible: true }));
        break;
    }
  };

  const handleChange = (value: string) => {
    const updatedRfp = {
      ...rfp,
      type: value as RfpType,
    } as AdminRfpRequest;

    dispatch(updateRfp({ api, rfp: updatedRfp }));
  };

  const handleActivationVerification = async () => {
    if (
      forms.contactForm === undefined ||
      forms.rfpForm === undefined ||
      forms.companyForm === undefined
    ) {
      return;
    }

    try {
      await forms.contactForm.validateFields();
      await forms.rfpForm.validateFields();
      // await forms.companyForm.validateFields();
      await onSave();
      // TODO find a way to handle known errors APP-295 https://hellodarwin.atlassian.net/browse/APP-295
      if (tags.selectedServices.length === 0) {
        showTagsWarning();
        return new Error('failed confirmation');
      }
      if (rfp?.status === RfpStatus.Active) {
        return;
      }

      if (rfp?.referral_provider_id && rfp?.referral_provider_id !== '') {
        const result = window.confirm(
          `Activating this rfp? will credit ${rfp?.referral_provider_id} for ${rfp.lead_price}.`,
        );

        if (!result) return new Error('failed confirmation');
      }

      if (
        rfp?.type === RfpType.RaiseHand &&
        forms.rfpForm.getFieldValue('raise_hand_max') === 0
      ) {
        Modal.info({
          title: 'Please set a max raised hands',
        });
        return new Error('failed confirmation');
      }

      if (
        rfp?.type === RfpType.RaiseHand &&
        forms.rfpForm.getFieldValue('raise_hand_price') === 0
      ) {
        const result = Modal.confirm({
          title: 'Everybody can Raise Hand for free ?',
          okText: 'Confirm',
          cancelText: 'Cancel',
          okButtonProps: {
            style: {
              backgroundColor: theme.colors.purple_1,
              color: theme.colors.white_1,
              borderRadius: '24px',
            },
          },
          cancelButtonProps: {
            style: {
              backgroundColor: theme.colors.white_1,
              borderColor: theme.colors.grey_1,
              color: theme.colors.grey_1,
              borderRadius: '24px',
            },
          },
          onOk() {
            handleActivation();
          },
          onCancel() {
            return false;
          },
        });
        if (!result) return new Error('failed confirmation');
      } else {
        handleActivation();
      }
    } catch (e: any) {
      message.error('Some required fields are missing!');
      console.error('Validation Failed:', e);
      return new Error('Validation Failed');
    }
  };

  const handleActivation = () => {
    (async () => {
      try {
        await dispatch(activateRfp({ api, rfpId: id || '' }));
        message.success('Project Activated!');
      } catch (e: any) {
        message.error('Activation failed. Try again later!');
        console.error(e);
        return new Error('Activation Failed');
      }
    })();
  };

  const handleDiscount = () => {
    (async () => {
      try {
        await api.discountRfp(id || '', rfpDiscount);
        setIsDiscountInputVisible(false);
        openModal(ModalType.DiscountProjectModal);
        setBlock(
          0,
          <Div flex="column" gap={16}>
            <RfpForm
              admins={admins}
              rfp={rfp}
              setForms={setForms}
              setHasAttachments={setHasAttachments}
              tags={tags}
            />
            <ReviewNotificationForm
              rfp={rfp!}
              setForms={setForms}
            ></ReviewNotificationForm>
          </Div>,
        );
      } catch (e: any) {
        message.error('Something went wrong, try again later!');
        console.error(e);
      }
    })();
  };

  useEffect(() => {
    if (activeTab !== 'projects') {
      dispatch(setActiveTab('projects'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!rfp?.rfp_id) return;
    dispatch(fetchRfpPurchasedMatches({ api, rfpId: id || '' }));

    dispatch(fetchTags({ api })).then((res) => {
      dispatch(fetchRfpTags({ api, rfpId: id || '' }));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfp?.rfp_id]);

  useEffect(() => {
    if (!rfp?.rfp_id) return;
    if (activeTab === 'refusingProviders') {
      dispatch(fetchRfpRefusedMatches({ api, rfpId: id || '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, rfp?.rfp_id]);

  useEffect(() => {
    setBlock(
      2,
      <MatchesBlock matches={purchasedMatches} targetId={id || ''} rfp={rfp} />,
      purchasedMatches.length === 0
        ? undefined
        : { count: purchasedMatches.length },
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, purchasedMatches, rfp]);

  useEffect(() => {
    if (
      !!rfp?.status &&
      [
        RfpStatus.Active,
        RfpStatus.FilterCandidates,
        RfpStatus.Completed,
      ].includes(rfp?.status)
    ) {
      quickActions.push(
        <QuickAction
          key={id}
          content={<ManualMatchForm rfpId={id || ''} />}
          title="Manual Match"
          icon={<UsergroupAddOutlined />}
        />,
      );
      if (rfp?.type === RfpType.RaiseHand) {
        quickActions.push(
          <QuickAction
            key={id}
            content={
              <RaiseHandForm rfpId={id || ''} leadPrice={rfp.lead_price || 0} />
            }
            title="Manual Raise Hand"
            icon={<DollarOutlined />}
          />,
          <QuickAction
            key={id}
            content={
              <ShortlistForm rfpId={id || ''} leadPrice={rfp.lead_price || 0} />
            }
            title="Manual Shortlist"
            icon={<DollarOutlined />}
          />,
          <QuickAction
            key={id}
            content={<ChargeWinFeeForm rfpId={id || ''} />}
            title="Manual Win Fee Charge"
            icon={<DollarOutlined />}
          />,
        );
      } else {
        quickActions.push(
          <QuickAction
            key={id}
            content={<ManualChargeForm rfpId={id || ''} />}
            title="Manual Charge"
            icon={<DollarOutlined />}
          />,
        );
      }
      if (
        !!rfp.referral_provider_id &&
        rfp.referral_credit_applied_at == null
      ) {
        quickActions.push(
          <QuickAction
            key={id}
            content={
              <ManualReferralCredits
                rfpId={id || ''}
                leadPrice={rfp.lead_price || 0}
                onSave={(rfp: Rfp) => dispatch(updateRfp({ api, rfp }))}
              />
            }
            title="Manual Referral credits"
            icon={<DollarOutlined />}
          />,
        );
      }
    }

    setActionBar(actionBar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfp?.status, rfp?.type, rfp]);

  const refusedMatchesHeaders = [
    'Provider ID',
    'Category',
    'Estimated Value',
    'Lead Price',
    'Refused Reason',
    'Refused At',
  ];

  const getRefusedMatchesRows = (matches: Match[]) => {
    if (matches.length === 0) {
      return [[]];
    }

    return matches.map((m, index) => {
      const date = getFormattedDate(m.refused_at || '');
      return [
        <Tooltip key={index} placement="bottom" title="Project page">
          <Link to={'/providers/' + m.provider_id}>
            {getShortId(m.provider_id)}
          </Link>
        </Tooltip>,
        m.rfp.category,
        `$${m.rfp.estimated_value}`,
        `$${m.price}`,
        m.refused_reason,
        date,
      ];
    });
  };

  const getManualMatchesRows = (matches: Match[]) => {
    if (matches.length === 0) {
      return [[]];
    }

    return matches.map((m, index) => {
      return [
        <Tooltip key={index} placement="bottom" title="Project page">
          <Link to={'/providers/' + m.provider_id}>
            {getShortId(m.provider_id)}
          </Link>
        </Tooltip>,
        String(m.score),
        'No drip schedule',
        '___',
        '___',
        m.source,
        'No drip notification',
      ];
    });
  };

  const RfpButtons = (): ReactNode => (
    <Div flex="row" justify="space-between">
      <Div flex="row" align="center" gap={8}>
        <Div flex="row" align="center" gap={8} fitContent>
          <Button onClick={onSave} size="small">
            Save
          </Button>
          {rfp?.status !== RfpStatus.Active &&
            rfp?.status !== RfpStatus.EvaluateFinalists &&
            rfp?.status !== RfpStatus.FilterCandidates && (
              <>
                <Select value={rfp?.type} onChange={handleChange} size="small">
                  <Option value={RfpType.Classic}>Classic</Option>
                  <Option value={RfpType.RaiseHand}>Raise Hand</Option>
                </Select>
              </>
            )}
          {rfp?.status !== RfpStatus.PendingApproval &&
            rfp?.status !== RfpStatus.Completed &&
            rfpDiscount !== -1 && (
              <Div flex="row" justify="space-between" gap={8}>
                <Button
                  onClick={() =>
                    setIsDiscountInputVisible(!isDiscountInputVisible)
                  }
                  size="small"
                >
                  {isDiscountInputVisible ? 'Close discount' : 'Discount'}
                </Button>
                {isDiscountInputVisible && (
                  <>
                    <InputNumber
                      value={rfpDiscount}
                      onChange={(value) => {
                        return setRfpDiscount(Number(value));
                      }}
                      formatter={(value) => `${value || 0} $`}
                      style={{ width: '100px' }}
                      size="small"
                    />

                    <Button onClick={handleDiscount}>Save Discount</Button>
                  </>
                )}
              </Div>
            )}
        </Div>
        <>
          {rfp && (
            <RfpStatusDropdown
              rfp={rfp}
              matches={purchasedMatches}
              setRfp={(rfp: Rfp) => dispatch(updateRfp({ api, rfp }))}
              forceUpdate={forceUpdate}
              handleActivationVerification={handleActivationVerification}
              handleSave={onSave}
            />
          )}
        </>
      </Div>
      <Div flex="row" align="center" gap={16} fitContent>
        {blocks.map((block, i) => (
          <BlockButton
            icon={block.icon}
            key={i}
            onClick={() => toggleBlock(i)}
            tooltip={block.label}
            active={block.visible}
            notification={block.notification}
          />
        ))}
      </Div>
    </Div>
  );

  const handleContactDelete = async (contactId: string) => {
    return await dispatch(deleteContact({ api, contactId }));
  };

  const handleContactEdit = async (contact: Contact) => {
    return await dispatch(updateContact({ api, contact }));
  };

  const handleContactAdd = async (contact: Contact) => {
    const createContactResult = await dispatch(
      createContact({
        api,
        contact: contact,
        destination: 'Client',
      }),
    );
    if (createContact.rejected.match(createContactResult)) {
      const error = createContactResult.payload;
      if (error && error.error_code === EMAIL_ALREADY_EXISTS_ERROR) {
        Modal.confirm({
          title: 'Contact already exists',
          icon: <ExclamationCircleOutlined />,
          okButtonProps: {
            style: {
              backgroundColor: theme.colors.purple_1,
              color: theme.colors.white_1,
              borderRadius: '24px',
            },
          },
          cancelButtonProps: {
            style: {
              backgroundColor: theme.colors.white_1,
              borderColor: theme.colors.grey_1,
              color: theme.colors.grey_1,
              borderRadius: '24px',
            },
          },
          content:
            'A contact with this email already exists. It has either been deleted or assigned to another company. Do you wish to assign it to this company?',
          async onOk() {
            contact.company_id = company?.company_id || '';
            return await dispatch(assignContact({ api, contact: contact }));
          },
          onCancel() {},
        });
      } else {
        message.error('Something went wrong, try again later!');
      }
    }
    return createContactResult;
  };

  const handleContactAssign = async (contact: Contact) => {
    contact.company_id = company?.company_id || '';
    return await dispatch(assignContact({ api, contact: contact }));
  };

  const handleEmailExists = async (email: string) => {
    if (companyContacts.find((c) => c.email === email)) {
      return Promise.reject('Email already exists');
    }
    return await dispatch(getContactByEmail({ api, email }));
  };

  const tabs: TabsProps['items'] = useMemo(() => {
    return [
      {
        key: 'projects',
        label: t(`rfp|rfpTab.rfp`),
        children: (
          <BlocksList blocks={blocks} actions={<RfpButtons />} span={span} />
        ),
      },
      {
        key: 'matchmaking',
        label: t(`rfp|rfpTab.matchmaking`),
        children: (
          <Div flex="column" gap={16}>
            <Manifest
              rfpId={id || ''}
              rfpStatus={rfp?.status ?? RfpStatus.PendingApproval}
              handleActivation={handleActivationVerification}
            />
            <List
              headers={[]}
              rows={getManualMatchesRows(manualMatches || [])}
              onClick={() => null}
            />
          </Div>
        ),
      },
      {
        key: 'refusingProviders',
        label: t(`rfp|rfpTab.refusingProviders`),
        children: (
          <Row gutter={[16, 16]} style={{ width: '100%' }}>
            <Col span={24}>
              <List
                headers={refusedMatchesHeaders}
                rows={getRefusedMatchesRows(refusedMatches || [])}
                onClick={() => null}
              />
            </Col>
          </Row>
        ),
      },
      {
        key: 'companyContacts',
        label: t(`rfp|rfpTab.users`),
        children: (
          <Row gutter={[16, 16]} style={{ width: '100%' }}>
            <Col span={24}>
              {company && company.company_id !== '' && (
                <ProfileUsersList
                  users={companyContacts}
                  contactId={rfp?.contact_id || ''}
                  companyId={company.company_id}
                  handleContactDelete={handleContactDelete}
                  handleContactEdit={handleContactEdit}
                  handleContactAdd={handleContactAdd}
                  handleEmailExists={handleEmailExists}
                  handleContactAssign={handleContactAssign}
                  Auth0Buttons={Auth0UserButtons}
                  isAdmin
                  destination={'Client'}
                  ClipboardComponent={({ copy, title }) => (
                    <Clipboard copy={copy} title={title} />
                  )}
                />
              )}
            </Col>
          </Row>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyContacts, rfp, company, refusedMatches, id, blocks]);

  const infoTags = useMemo(() => {
    if (!rfp) return [];
    const baseTags: TagProps[] = [
      {
        onClick: () => {
          copyToClipboard(getShortId(rfp.rfp_id));
          message.success('Copied!');
        },
        color: theme.colors.purple_1,
        text: 'RFP: ' + getShortId(id || ''),
        tooltip: 'Click to copy to clipboard',
      },
      {
        text: rfp?.type,
        tooltip: 'Project Type',
        color: theme.colors.purple_5,
      },
      {
        text: t(`projectStatus|${rfp.status}`),
        tooltip: getStatusTooltip(rfp.status),
        color: getRfpStatusColor(rfp.status, theme),
      },
    ];

    const conditionalTags: TagProps[] = [
      !!rfp.diy_requested_at && {
        text: 'DIY Requested',
        tooltip: getFormattedDate(rfp.diy_requested_at),
        color: theme.colors.blue_1,
      },
      !!rfp.referral_provider_id && {
        text: 'Partner Referral',
        tooltip: rfp.referral_credit_applied_at
          ? `${rfp.referral_credit_amount}$ credited at ${getFormattedDate(
              rfp.referral_credit_applied_at,
            )}`
          : 'Provider will be credited lead price on activation',
        color: theme.colors.purple_2,
        onClick: () => navigate('/providers/' + rfp.referral_provider_id),
      },
      !!rfp.activated_at && {
        text: 'Activated ' + getShortFormattedDate(rfp.activated_at ?? ''),
        tooltip: getFormattedDate(rfp.activated_at ?? ''),
        color: theme.colors.blue_4,
      },
      !!rfp.canceled_at && {
        text: 'Canceled ' + getShortFormattedDate(rfp.canceled_at || ''),
        tooltip: `${rfp.canceled_source} // ${rfp.canceled_reason} // ${getFormattedDate(
          rfp.canceled_at,
        )}`,
        color: theme.colors.red_4,
      },
      !!rfp.completed_at && {
        text: 'Completed ' + getShortFormattedDate(rfp.completed_at || ''),
        tooltip: `${rfp.completed_source} // ${getFormattedDate(
          rfp.completed_at,
        )}`,
        color: theme.colors.yellow_1,
      },
      !!rfp.requested_more_providers_at && {
        text: 'More Providers Requested',
        tooltip: `${getShortFormattedDate(
          rfp.requested_more_providers_at || '',
        )} // ${rfp.request_more_providers_reason}`,
        color: theme.colors.orange_4,
      },
    ].filter(Boolean) as TagProps[];

    return [...baseTags, ...conditionalTags];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfp, id]);
  return (
    <>
      {rfp && (
        <PageLayout
          app="admin"
          tabTitle={rfp.title ? rfp.title : `RFP ${getShortId(id || '')}`}
          title={
            <Div
              flex="row"
              justify="space-between"
              align="center"
              gap={8}
              fitContent
            >
              <TagsList tags={infoTags} />

              {hasAttachments && (
                <Div fitContent>
                  <Tooltip title={'Project has attachments'}>
                    <PaperClipOutlined style={{ fontSize: '20px' }} />
                  </Tooltip>
                </Div>
              )}

              {rfp?.status === RfpStatus.PendingApproval && (
                <ReferenceWidget id={rfp.rfp_id} />
              )}

              <ExternalLink
                id={rfp.crm_id}
                title="Hubspot ID"
                url={rfp.hubspot_url}
                onEnd={(newValue) => {
                  dispatch(
                    updateRfp({
                      api,
                      rfp: {
                        ...rfp,
                        ...{ crm_id: newValue },
                      },
                    }),
                  );
                }}
              />
            </Div>
          }
          breadcrumbs={[
            {
              breadcrumbName: 'Home',
              path: '/',
            },
            {
              breadcrumbName: 'Projects',
              path: '/projects',
            },
            {
              breadcrumbName:
                rfp?.title ??
                rfp?.service ??
                `Project ${getShortId(rfp?.rfp_id || '')}`,
            },
          ]}
        >
          <CompanyCard
            company={company ? company : ({} as Company)}
            contact={contact ? contact : ({} as Contact)}
          />
          <Container
            className="rfp"
            tabs={tabs}
            tabBarExtraContent={<RfpActions rfp={rfp} />}
            activeTab={activeTab}
            handleTabClick={(key) => dispatch(setActiveTab(key))}
            fullWidth
          />
        </PageLayout>
      )}
    </>
  );
};

export default SingleRfpPage;
