import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../button';
import Div from '../../div';
import Link from '../../link';
import Typography from '../../typography';
import PageLayoutTitle from './page-layout-title';
const PageHeading = ({ handleBack, goBackTo, title, subtitle, footnote, status, actions, withoutPaddingHorizontal, withoutPaddingLeft, withoutPaddingRight, styles, }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    return (_jsxs(Div, { flex: "column", gap: 16, tablet: { style: { paddingInline: 24 } }, style: Object.assign({ position: 'relative', paddingRight: withoutPaddingHorizontal || withoutPaddingRight ? 60 : 0, paddingLeft: withoutPaddingHorizontal || withoutPaddingLeft ? 60 : 0 }, styles === null || styles === void 0 ? void 0 : styles.container), children: [_jsxs(Div, { flex: "row", justify: "space-between", align: "center", xl: {
                    flex: 'column',
                    align: 'flex-start',
                    gap: 16,
                }, style: Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.titleContainer), gap: 24, children: [!!(goBackTo === null || goBackTo === void 0 ? void 0 : goBackTo.length) ? (_jsx(Link, { to: goBackTo, children: _jsx(Button, { isLink: true, withArrowLeft: true, size: "small", defaultStyle: theme.colors.purple_1, children: t('button|back') }) })) : handleBack ? (_jsx(Button, { isLink: true, onClick: handleBack, withArrowLeft: true, size: "small", defaultStyle: theme.colors.purple_1, children: t('button|back') })) : (_jsx(PageLayoutTitle, { title: title, status: status })), !!actions && (_jsx(Div, { fitContent: true, flex: "row", gap: 16, style: Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.actions), children: React.Children.toArray(actions) }))] }), subtitle && (_jsx(Typography, { elementTheme: "body2", style: Object.assign({ maxWidth: 600 }, styles === null || styles === void 0 ? void 0 : styles.subtitle), children: subtitle })), footnote && (_jsx(Typography, { elementTheme: "caption", color: theme.colors.grey_3, style: Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.footnote), children: footnote }))] }));
};
export default PageHeading;
