'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Tooltip from 'antd/es/tooltip';
import { useMemo, useState } from 'react';
import HdTag from '.';
import { TagsListContainer } from './styles';
const TagsList = ({ tags, limit, mainColor, hideShowMore, collapsedTagsInTooltip, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = (e) => {
        e.stopPropagation();
        if (!collapsedTagsInTooltip)
            setIsOpen(!isOpen);
    };
    const hideTags = useMemo(() => !!limit &&
        !!tags &&
        limit < tags.length &&
        (!isOpen || collapsedTagsInTooltip), [isOpen, limit, tags]);
    const [shownTags, collapsedTags] = useMemo(() => (hideTags ? [tags.slice(0, limit), tags.slice(limit)] : [tags, []]), [hideTags, limit, tags]);
    return (_jsxs(TagsListContainer, { children: [shownTags === null || shownTags === void 0 ? void 0 : shownTags.map((tag) => {
                var _a;
                return (_jsx(HdTag, Object.assign({}, tag, { color: (_a = tag.color) !== null && _a !== void 0 ? _a : mainColor })));
            }), hideTags && !hideShowMore && (_jsx(Tooltip, { styles: { body: { background: 'white' } }, title: collapsedTagsInTooltip && (_jsx(TagsListContainer, { children: collapsedTags.map((tag) => {
                        var _a;
                        return (_jsx(HdTag, Object.assign({}, tag, { color: (_a = tag.color) !== null && _a !== void 0 ? _a : mainColor })));
                    }) })), children: _jsx(HdTag, { text: `+${tags.length - limit}`, color: mainColor, hoverable: true, onClick: handleOpen }) }))] }));
};
export default TagsList;
