import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Form from 'antd/es/form';
import { useEffect, useMemo } from 'react';
import { ReviewFormSteps } from '../../../../features/entities/rfp-status-flow-entities';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTranslation } from '../../../../plugins/i18n';
import Div from '../../../common/div';
import ReviewNavigation from './review-navigation';
import ReviewStepCard from './review-step-card';
const ReviewExperience = ({ providerName, experiences, setForms, setFlowStep, isActive, tags, isFromWebsite, handleSubmit, }) => {
    const { t } = useTranslations();
    const { t: translateTag } = useTranslation(['tags'], { useSuspense: false });
    const [form] = Form.useForm();
    useEffect(() => {
        setForms((prevState) => (Object.assign(Object.assign({}, prevState), { experienceForm: form })));
    }, [form, setForms]);
    const tagsOptions = useMemo(() => {
        return Object.values(tags !== null && tags !== void 0 ? tags : {}).map((category) => {
            const group = {
                label: translateTag(`tags|${category.category}`),
                title: category.category,
                options: category.services.map((service) => {
                    const item = {
                        label: translateTag(`tags|${service.tag}`),
                        value: service.tag,
                    };
                    return item;
                }),
            };
            return group;
        });
    }, [tags]);
    const initialValues = {
        service: experiences === null || experiences === void 0 ? void 0 : experiences.service,
        quality: experiences === null || experiences === void 0 ? void 0 : experiences.quality,
        relationship: experiences === null || experiences === void 0 ? void 0 : experiences.relationship,
        summary: experiences === null || experiences === void 0 ? void 0 : experiences.summary,
        recommend: experiences === null || experiences === void 0 ? void 0 : experiences.recommend,
    };
    const handleNextStep = async () => {
        form.validateFields().then((values) => {
            isFromWebsite
                ? setFlowStep(ReviewFormSteps.Contact)
                : setFlowStep(ReviewFormSteps.Confirm);
        });
    };
    return (_jsx(Form, { form: form, layout: 'vertical', initialValues: initialValues, name: 'experience', hidden: !isActive, children: _jsxs(Div, { flex: "column", gap: 16, children: [isFromWebsite && !!tagsOptions.length && (_jsx(ReviewStepCard, { label: t(`reviews|newReviewServiceLabel`, { providerName }), extra: t(`reviews|newReviewServiceQuestion`), error: t(`reviews|newReviewServiceError`, { providerName }), name: 'service', type: "select", options: tagsOptions, placeholder: t(`reviews|selectAService`) }, 'Service')), _jsx(ReviewStepCard, { error: t(`reviews|newReviewQualityError`, { providerName }), label: t(`reviews|newReviewQualityLabel`, { providerName }), name: 'quality', type: 'rating' }, 'Quality'), _jsx(ReviewStepCard, { error: t(`reviews|newReviewRelationshipError`, { providerName }), label: t(`reviews|newReviewRelationshipLabel`, { providerName }), name: 'relationship', type: 'rating' }, 'Relationship'), _jsx(ReviewStepCard, { error: t(`reviews|newReviewDescribeError`, { providerName }), label: t(`reviews|newReviewDescribeLabel`, { providerName }), name: 'summary', type: 'textarea', placeholder: t('reviews|newReviewDescribePlaceholder', {
                        providerName,
                    }) }, 'Summary'), _jsx(ReviewStepCard, { error: t(`reviews|newReviewRecommendError`, { providerName }), label: t(`reviews|newReviewRecommendLabel`, { providerName }), name: 'recommend', type: "radio", options: [
                        { label: t('reviews|yes'), value: true },
                        { label: t('reviews|no'), value: false },
                    ] }, 'Recommend'), _jsx(ReviewNavigation, { handleNext: handleNextStep, handlePrev: undefined, handleSubmit: handleSubmit, isSubmit: !isFromWebsite })] }) }));
};
export default ReviewExperience;
