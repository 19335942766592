import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from 'styled-components';
import { capitalizeFirstLetter } from '../../../../../features/helpers/capitalize';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
const FilterCompanyInfos = ({ label, children, }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    return (_jsxs(Div, { flex: "column", gap: 6, children: [_jsx(Typography, { elementTheme: "overline", color: theme.colors.grey_2, children: t(`grantsFundingExplorer|grantsfundingExplorerFilter${capitalizeFirstLetter(label)}`) }), _jsx(Typography, { elementTheme: "body2", children: children })] }));
};
export default FilterCompanyInfos;
