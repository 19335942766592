import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const GridViewOutline = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5238 3.04761C1.5238 2.20604 2.20604 1.5238 3.04761 1.5238H12.588C13.4296 1.5238 14.1118 2.20604 14.1118 3.04761V12.588C14.1118 13.4296 13.4296 14.1118 12.588 14.1118H3.04761C2.20604 14.1118 1.5238 13.4296 1.5238 12.588V3.04761Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        fill="none"
      />
      <path
        d="M1.5238 19.4121C1.5238 18.5705 2.20604 17.8883 3.04761 17.8883H12.588C13.4296 17.8883 14.1118 18.5705 14.1118 19.4121V28.9525C14.1118 29.794 13.4296 30.4763 12.588 30.4763H3.04761C2.20604 30.4763 1.5238 29.794 1.5238 28.9525V19.4121Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        fill="none"
      />
      <path
        d="M17.8879 3.04761C17.8879 2.20604 18.5702 1.5238 19.4118 1.5238H28.9521C29.7937 1.5238 30.4759 2.20604 30.4759 3.04761V12.588C30.4759 13.4296 29.7937 14.1118 28.9521 14.1118H19.4118C18.5702 14.1118 17.8879 13.4296 17.8879 12.588V3.04761Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        fill="none"
      />
      <path
        d="M17.8879 19.4121C17.8879 18.5705 18.5702 17.8883 19.4118 17.8883H28.9521C29.7937 17.8883 30.4759 18.5705 30.4759 19.4121V28.9525C30.4759 29.794 29.7937 30.4763 28.9521 30.4763H19.4118C18.5702 30.4763 17.8879 29.794 17.8879 28.9525V19.4121Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        fill="none"
      />
    </svg>
  );
};

export default GridViewOutline;
