import { View } from '@react-pdf/renderer';
import {
  ProjectGrant,
  ProjectProgram,
  ProjectResponse,
} from '../../../@types/projects-types';
import Group4Outlined from '../../../components/background/group4-outlined';
import CardWithIcon from '../../../components/cards/tile-with-description-and-icon';
import GrantsIcon from '../../../components/common/icons/grants';
import InvoicesIcon from '../../../components/common/icons/invoices';
import TimelineIcon from '../../../components/common/icons/timeline';
import VerifiedIcon from '../../../components/common/icons/verified';
import PageHeader from '../../../components/header';
import { createTemplateStyle } from '../../../styles/templates';
import theme from '../../../theme';
import { GamePlanTranslations } from '../types';
import getFundingAmount from '../utils/get-funding-amount';
import getGrantTimeline from '../utils/get-grant-timeline';

export interface ProjectApplicationTemplateProps {
  grant: ProjectGrant;
  application: ProjectProgram;
  translations: GamePlanTranslations;
  locale: string;
  project: ProjectResponse;
}

const CARD_SPACING = 8;

const ProjectApplicationTemplate: React.FC<ProjectApplicationTemplateProps> = ({
  grant,
  translations,
  locale,
  project,
  application,
}) => {
  return (
    <View id={application.program_id}>
      <Group4Outlined
        width={679}
        height={948}
        color={theme.colors.beige_1}
        top={-68}
        margin={-354}
        align="left"
      />

      <PageHeader
        title={project.project_goals[0]}
        textAlign="center"
        elementTheme="h6"
      />
      <View
        style={createTemplateStyle({
          type: 'center',
          withFooter: false,
          withHeader: { headerElementTheme: 'h6' },
        })}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',

            height: '100%',
            width: '100%',
          }}
        >
          <CardWithIcon
            icon={{
              Icon: InvoicesIcon,
              backgroundColor: theme.colors.blue_2,
            }}
            size={'medium'}
            direction={'horizontal'}
            label={
              application.is_subsidiary_program
                ? grant.grant_display_title +
                  ' ' +
                  '(' +
                  application.parent_company_name +
                  ')'
                : grant.grant_display_title
            }
            content={grant.grant_description_long}
            href={`https://app.hellodarwin.com/funding-explorer/${grant.grant_id}`}
            textAlign="left"
          />
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <CardWithIcon
              icon={{
                Icon: VerifiedIcon,
                backgroundColor: theme.colors.yellow_4,
              }}
              size={'small'}
              direction={'horizontal'}
              label={translations.eligibilityCriteria}
              content={grant.grant_eligibility_criteria_short}
              withLink
              spacing={CARD_SPACING}
            />
            <View
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                paddingLeft: 24,
              }}
            >
              <CardWithIcon
                icon={{
                  Icon: GrantsIcon,
                  backgroundColor: theme.colors.green_4,
                }}
                size={'small'}
                direction={'vertical'}
                label={translations.targetAmountToObtain}
                content={getFundingAmount(
                  grant,
                  application,
                  locale,
                  translations,
                )}
                withLink
                spacing={CARD_SPACING}
              />
              <CardWithIcon
                icon={{
                  Icon: TimelineIcon,
                  backgroundColor: theme.colors.purple_4,
                }}
                size={'small'}
                direction={'vertical'}
                label={translations.keyDates}
                content={getGrantTimeline(
                  locale,
                  translations,
                  grant.grant_timeline,
                )}
                withLink
                spacing={CARD_SPACING}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default ProjectApplicationTemplate;
