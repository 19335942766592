import styled, { css } from '../../../plugins/styled';
import { createScrollAnimation } from '../../../theme/keyframes';
export const ScrollingContent = styled.div `
  display: flex;
  gap: 16px;
  animation-play-state: running;
  width: fit-content;

  ${({ $speed, $inversed }) => css `
    animation: ${createScrollAnimation($inversed)} ${$speed}s linear infinite;
  `}
`;
export const ScrollingContainer = styled.div `
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  position: relative;
  animation: fadein 0.6s linear;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
    z-index: 1;
    pointer-events: none;
  }

  &::before {
    left: 0;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.white_1} 30%,
      transparent 100%
    );
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
  }

  &::after {
    right: 0;
    background: linear-gradient(
      to left,
      ${({ theme }) => theme.colors.white_1} 30%,
      transparent 100%
    );
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50px;
  }

  &:hover ${ScrollingContent} {
    animation-play-state: paused;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
