import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import { SmallDesktop } from '../../../../common/Media';
const FilterDropdownActions = ({ handleClose, company, resetFiltersWithCompany, resetFilters, }) => {
    const theme = useTheme();
    const { t } = useTranslations();
    return (_jsxs(Div, { flex: "row", gap: 8, fitContent: true, tablet: { fitContent: false }, mobile: { flex: 'column' }, children: [!!company && (_jsx(Button, { onClick: resetFiltersWithCompany, size: "small", style: { borderRadius: 4, minWidth: 0 }, defaultStyle: theme.colors.white_1, tablet: {
                    style: {
                        width: '100%',
                        justifyContent: 'flex-start',
                    },
                    defaultStyle: theme.colors.purple_1,
                }, children: t(`grantsFundingExplorer|grantsfundingExplorerFilterResetFilterWithCompany`) })), _jsx(Button, { onClick: resetFilters, size: "small", style: { borderRadius: 4, minWidth: 0 }, defaultStyle: theme.colors.white_1, tablet: {
                    style: {
                        width: '100%',
                        justifyContent: 'flex-start',
                    },
                    defaultStyle: theme.colors.purple_1,
                }, children: t(`grantsFundingExplorer|grantsfundingExplorerFilterResetFilter`) }), _jsx(SmallDesktop, { direction: "up", children: _jsx(Button, { onClick: handleClose, size: "small", style: { borderRadius: 4, minWidth: 0 }, defaultStyle: theme.colors.primary, tablet: { style: { width: '100%', justifyContent: 'flex-start' } }, children: t(`grantsFundingExplorer|grantsfundingExplorerFilterCloseFilters`) }) })] }));
};
export default FilterDropdownActions;
