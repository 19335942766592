import SeeMoreSidebarBlock from '@hellodarwin/core/lib/components/common/layout/sidebar-page-layout.tsx/see-more-sidebar-block';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { ProgramGrantStatus } from '@hellodarwin/core/lib/features/entities/programs-entities';
import {
  getShortId,
  getStringDate,
} from '@hellodarwin/core/lib/features/helpers';
import dollarFormat from '@hellodarwin/core/lib/features/helpers/dollar-format';
import { getProgramGrantStatusColor } from '@hellodarwin/core/lib/features/helpers/get-status-color';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Grants from '@hellodarwin/icons/dist/icons/Grants';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import Empty from 'antd/es/empty';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app';
import {
  getCompanyPrograms,
  selectProgramsByCompanyId,
  selectProgramsLoading,
} from '../../../../features/api/slices/programs-slice';
import { useAdminApi } from '../../../../features/api/use-admin-api';
import CardLayout from '../../../gins/sidebars/cards/card-layout';

interface CompanyApplicationsListProps {
  company_id?: string;
}
const CompanyApplicationsList: React.FC<CompanyApplicationsListProps> = ({
  company_id,
}) => {
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();
  const theme = useTheme();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) =>
    selectProgramsLoading(state, 'fetchCompanyPrograms'),
  );

  const applications = useAppSelector((state) =>
    selectProgramsByCompanyId(state, company_id),
  );

  useEffect(() => {
    if (!!company_id && company_id !== '') {
      dispatch(getCompanyPrograms({ api, companyId: company_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_id]);

  return (
    <SeeMoreSidebarBlock
      items={applications}
      isLoading={isLoading}
      title={t('singleCompanyPage|applications')}
      searchableFields={[
        'program_name',
        'program_company_name',
        'program_contact_name',
      ]}
      render={(program, index) => (
        <CardLayout
          key={program.program_id}
          title={program.program_name ?? getShortId(program.program_id)}
          infos={[
            {
              Icon: Grants,
              value: program.program_targeted_amount
                ? dollarFormat(
                    program.program_targeted_amount,
                    selectedLocale,
                    true,
                  )
                : 'N/A',
            },
            {
              Icon: Timeline,
              value: getStringDate(
                new Date(`${program.program_activated_at}`),
                selectedLocale,
              ),
            },
          ]}
          tag={{
            color: getProgramGrantStatusColor(
              program.program_grant_status ?? ProgramGrantStatus.NotSubmited,
              theme,
            ),
            withBorder: true,
            text: t(`programs_labels|${program.program_grant_status}`),
          }}
          to={`/programs/${program.program_id}`}
        />
      )}
      fallback={
        <Empty
          imageStyle={{ width: 100, height: 100, marginInline: 'auto' }}
          style={{ marginInline: 'auto' }}
          description={
            <Typography elementTheme="body3" medium>
              {t('singleCompanyPage|noApplications')}
            </Typography>
          }
        />
      }
    />
  );
};

export default CompanyApplicationsList;
