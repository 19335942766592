import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
const AssetDrawerInfoRow = ({ label, value, }) => {
    const theme = useTheme();
    if (!(value === null || value === void 0 ? void 0 : value.length))
        return _jsx(_Fragment, {});
    return (_jsxs(Div, { children: [_jsx(Typography, { elementTheme: "overline", color: theme.colors.primary, children: label }), _jsx(Typography, { elementTheme: "body2", color: theme.colors.grey_2, children: value })] }));
};
export default AssetDrawerInfoRow;
