import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Div from '../../../common/div';
import SubNavigationMenuItemsList from '../../navigation/menu-item/subitems-list';
import useHandleLinkClick from '../../navigation/utils/use-handle-link-click';
import UserMenuButton from './user-menu-button';
const MobileUserMenu = ({ user, links, app, notifs }) => {
    const handleLinkClick = useHandleLinkClick({ selectedKeys: [] });
    return (_jsxs(Div, { flex: "column", gap: 26, className: "user-menu", children: [_jsx(UserMenuButton, { user: user, app: app, isOnNav: true, notifs: notifs }), _jsx(SubNavigationMenuItemsList, { items: links, app: app, handleLinkClick: handleLinkClick, size: "large", removeBorder: true })] }));
};
export default MobileUserMenu;
