import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Divider from 'antd/es/divider';
import Drawer from 'antd/es/drawer';
import { useEffect, useMemo, useState } from 'react';
import { usePlatform } from '../../../../features/providers/platform-provider';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import UserMenu from '../../header/user-menu';
import NavigationMenuItem from '../menu-item';
import Socials from '../socials';
import { MobileNavigationContainer } from '../styles';
import MobileNavigationHeader from './mobile-navigation-header';
const MobileNavigation = ({ links, profile, userLinks, pathname, }) => {
    const { app } = usePlatform();
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen((prev) => !prev);
        if (!isOpen) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = '';
        }
    };
    useEffect(() => {
        handleClose();
    }, [pathname]);
    const handleClose = () => {
        setIsOpen(false);
    };
    const selectedKeys = useMemo(() => { var _a; return (_a = links.map((l) => l.pathname)) !== null && _a !== void 0 ? _a : []; }, [links]);
    return (_jsxs(_Fragment, { children: [_jsx(MobileNavigationHeader, { app: app, toggleMenu: toggleMenu, isOpen: isOpen }), _jsx(Drawer, { styles: {
                    header: {
                        padding: 0,
                        flexDirection: 'row-reverse',
                    },
                    content: {
                        background: app === 'provider' ? theme.colors.primary : theme.colors.white_1,
                        padding: 0,
                    },
                }, title: _jsx(MobileNavigationHeader, { app: app, toggleMenu: toggleMenu, isOpen: isOpen }), closable: false, placement: "right", onClose: () => setIsOpen(false), open: isOpen, width: '100%', children: _jsxs(MobileNavigationContainer, { "$isOpen": isOpen, "$app": app, children: [_jsx(Div, { flex: "column", gap: 12, align: "center", children: links
                                .filter((link) => !link.hide)
                                .map((link) => (_createElement(NavigationMenuItem, Object.assign({}, link, { key: link.pathname, app: app, selectedKeys: selectedKeys, handleLinkClickCallback: handleClose })))) }), profile && userLinks && (_jsxs(Div, { className: "hd-navigation-footer", flex: "column", gap: 24, children: [app !== 'admin' && (_jsx(Socials, { withoutBorder: true, align: "right", app: app })), _jsx(Divider, { style: {
                                        borderWidth: 1,
                                        borderColor: app === 'provider'
                                            ? theme.colors.white_1
                                            : theme.colors.grey_4,
                                    } }), _jsx(UserMenu, { user: { name: profile.contact_name, logo: profile.logo }, links: userLinks, app: app })] }))] }) })] }));
};
export default MobileNavigation;
