import { jsx as _jsx } from "react/jsx-runtime";
import Tooltip from 'antd/es/tooltip';
import copyToClipboard from '../../../features/helpers/copy-to-clipboard';
export const copyIntoClipboard = (text) => {
    copyToClipboard(text);
};
const Clipboard = ({ copy, title, tooltip = 'Copy to clipboard', styles, }) => {
    return (_jsx("div", { style: {
            cursor: 'pointer',
            position: 'relative',
            display: 'inline-block',
        }, children: _jsx(Tooltip, { placement: "bottom", title: tooltip, children: _jsx("div", { style: styles, onClick: () => copyIntoClipboard(copy), children: title }) }) }));
};
export default Clipboard;
