import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import { prefixHTTPS } from '../../../../features/helpers/prefix-https';
import { useLocation } from '../../../../features/providers/location-provider';
import Link from '../../../common/link';
import MenuItemLabel from './menu-item-label';
const NavigationMenuLabelWrapper = (props) => {
    const { isExternal, external_link, pathname, subLinks, onClick, handleLinkClick, handleListItemsOpening, } = props;
    const theme = useTheme();
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    const { pathname: activePathname } = useLocation();
    const isCurrent = useMemo(() => pathname === activePathname, [pathname, activePathname]);
    return (!!subLinks && !isTablet) || onClick ? (_jsx("div", { onClick: !!subLinks
            ? handleListItemsOpening
            : () => handleLinkClick && handleLinkClick(props, subLinks), children: _jsx(MenuItemLabel, Object.assign({}, props, { isCurrent: isCurrent })) })) : isExternal && !!external_link ? (_jsx("a", { href: prefixHTTPS(external_link, 'navigation', 'partner-app'), rel: `nofollow noopener noreferrer`, target: "__blank", style: { width: '100%', display: 'flex' }, children: _jsx(MenuItemLabel, Object.assign({}, props, { isCurrent: isCurrent })) })) : pathname && !isExternal ? (_jsx(Link, { to: pathname, style: { width: '100%', display: 'flex' }, children: _jsx(MenuItemLabel, Object.assign({}, props, { isCurrent: isCurrent })) })) : (_jsx(MenuItemLabel, Object.assign({}, props, { isCurrent: isCurrent })));
};
export default NavigationMenuLabelWrapper;
