export const getAverageAllReviews = (reviews) => {
    let reviewSum = 0;
    if (reviews) {
        reviews.forEach((review) => {
            var _a;
            reviewSum += (_a = review.average) !== null && _a !== void 0 ? _a : 0;
        });
        return +(reviewSum / reviews.length).toFixed(1);
    }
};
export default getAverageAllReviews;
