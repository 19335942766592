import { ProviderInitialState } from './providers-entities';
import { RfpInitialState } from './rfp-entities';
export const MatchInitialState = {
    match_id: '',
    rfp_id: '',
    provider_id: '',
    provider_contact_id: '',
    winner_at: '',
    purchased_at: '',
    shortlisted_at: '',
    shortlist_message: '',
    raise_hand_rejected_at: '',
    provider: ProviderInitialState,
    hello_message: '',
    raised_hand_at: '',
    rfp: RfpInitialState,
};
