import { useCallback, useRef } from 'react';
const useDebounceFunction = (callback, delay, isAfter) => {
    const isDebouncing = useRef(false);
    return useCallback((...args) => {
        if (!isDebouncing.current) {
            isDebouncing.current = true;
            !isAfter && callback(...args);
            setTimeout(() => {
                isDebouncing.current = false;
                isAfter && callback(...args);
            }, delay);
        }
    }, [callback, delay]);
};
export default useDebounceFunction;
