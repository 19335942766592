var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Link from '../../common/link';
import { GrantCardContainer } from './styles';
const GrantCardWrapper = (_a) => {
    var { children, onClick, parentPath } = _a, grant = __rest(_a, ["children", "onClick", "parentPath"]);
    if (onClick) {
        const handleClick = () => {
            onClick(grant);
        };
        return (_jsx(GrantCardContainer, { onClick: handleClick, children: children }));
    }
    else if (!!parentPath) {
        return (_jsx(Link, { to: `${parentPath}/${grant.grant_id}`, children: _jsx(GrantCardContainer, { children: children }) }));
    }
    else {
        return _jsx(GrantCardContainer, { children: children });
    }
};
export default GrantCardWrapper;
