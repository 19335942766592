export var ChatType;
(function (ChatType) {
    ChatType["All"] = "all";
    ChatType["Other"] = "other";
    ChatType["GrantApplication"] = "grant_application";
    ChatType["GrantContent"] = "grant_content";
    ChatType["Program"] = "program";
    ChatType["Project"] = "project";
    ChatType["GrantSearch"] = "grant_search";
})(ChatType || (ChatType = {}));
