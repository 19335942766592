import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Divider from 'antd/es/divider';
import Input from 'antd/es/input/Input';
import message from 'antd/es/message';
import Select from 'antd/es/select';
import { useEffect, useState } from 'react';
import SingleLangue from '../../../../features/enums/single-language';
import getAgenciesPage from '../../../../features/helpers/get-agencies-page';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import { Tablet } from '../../../common/Media';
import Button from '../../../common/button';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import ReviewRatings from '../../../reviews/ratings';
import ReviewList from '../../../reviews/review/review-list';
import ProfileSection from '../profile-section';
import ProfileEmptySection from '../profile-section/empty-state';
import { ReviewHeaderContainer } from './styles';
const ProfileReviews = ({ provider, reviews, handleLeaveAReview, id, state, locale, }) => {
    const { t, getFullLanguage } = useTranslations();
    const theme = useTheme();
    const [url, setUrl] = useState('');
    const [language, setLanguage] = useState(provider === null || provider === void 0 ? void 0 : provider.profile_language);
    useEffect(() => {
        (async () => {
            getAgenciesPage({
                provider,
                withReviews: true,
                locale: language !== null && language !== void 0 ? language : SingleLangue.English,
            }).then((page) => setUrl(page));
        })();
    }, [provider, language]);
    const handleCopyClick = () => {
        navigator.clipboard.writeText(url);
    };
    const [messageApi, contextHolder] = message.useMessage();
    const ReviewsHeader = () => {
        return (_jsxs(ReviewHeaderContainer, { flex: "row", style: { height: '100%' }, children: [_jsxs(Div, { flex: "column", fitContent: true, tablet: { style: { flex: 1 } }, children: [_jsx(Typography, { elementTheme: "overline", textTransform: "uppercase", color: theme.colors.grey_3, tablet: { textAlign: 'center' }, children: t('reviews|totalReviews') }), _jsx(Typography, { elementTheme: "h4", tablet: { textAlign: 'center' }, children: reviews === null || reviews === void 0 ? void 0 : reviews.length })] }), _jsx(Tablet, { direction: "up", children: _jsx(Divider, { style: {
                            borderColor: theme.colors.grey_4,
                            borderWidth: 1,
                            margin: '0 2rem',
                            height: '50px',
                        }, type: "vertical" }) }), _jsxs(Div, { flex: "column", fitContent: true, tablet: { style: { flex: 1 } }, children: [_jsx(Typography, { elementTheme: "overline", textTransform: "uppercase", color: theme.colors.grey_3, tablet: { textAlign: 'center' }, children: t('reviews|averageRating') }), _jsx(ReviewRatings, { size: "large", defaultValue: provider === null || provider === void 0 ? void 0 : provider.reviews_average, disabled: true, showValue: true, biggerText: true, allowHalf: true })] })] }));
    };
    const successcopy = () => {
        handleCopyClick();
        messageApi.open({
            type: 'success',
            content: t('common|message.clipboard'),
        });
    };
    return (_jsx(ProfileSection, { level: 2, title: t('profile_reviews|profileReviewTitle', {
            companyName: provider.name,
        }), id: id, Actions: !!handleLeaveAReview &&
            ['website', 'client'].includes(state) &&
            (reviews === null || reviews === void 0 ? void 0 : reviews.length) > 0 && (_jsx(Button, { onClick: handleLeaveAReview, defaultStyle: theme.colors.purple_1, size: "small", children: t('profile_reviews|profileReviewCta') })), children: (reviews === null || reviews === void 0 ? void 0 : reviews.length) > 0 ? (_jsxs(Div, { flex: "column", gap: 28, children: [_jsx(ReviewsHeader, {}), _jsx(ReviewList, { reviews: reviews, providerName: provider === null || provider === void 0 ? void 0 : provider.name, average: provider === null || provider === void 0 ? void 0 : provider.reviews_average, locale: locale })] })) : (_jsx(_Fragment, { children: state === 'edit' ? (_jsxs(Div, { flex: "column", align: "center", gap: 10, children: [_jsx(ProfileEmptySection, { title: t('profile_reviews|noReviewsSendLink'), sectionName: "Reviews", isEditable: true }), _jsxs(Div, { flex: "row", align: "center", justify: "center", gap: 8, tablet: { flex: 'column', style: { marginTop: 20 } }, children: [contextHolder, _jsx(Input, { disabled: true, style: {
                                    borderRadius: '0px',
                                    width: '300px',
                                    border: `1px solid ${theme.colors.grey_4}`,
                                    background: theme.colors.grey_5,
                                    color: theme.colors.grey_2,
                                }, value: url }), _jsxs(Select, { placeholder: "Select option", value: language, onSelect: setLanguage, children: [_jsx(Select.Option, { value: 'en', children: getFullLanguage('en') }), _jsx(Select.Option, { value: 'fr', children: getFullLanguage('fr') })] }), _jsx(Button, { defaultStyle: theme.colors.purple_1, size: "small", children: t('button|copy'), onClick: successcopy })] })] })) : (_jsx(Div, { flex: "column", align: "center", gap: 24, children: _jsx(ProfileEmptySection, { sectionName: "Reviews", isEditable: true, title: t('profile_reviews|noReviewsWebsiteVersion', {
                        providerName: provider === null || provider === void 0 ? void 0 : provider.name,
                    }), cta: t('profile_reviews|profileReviewCta'), handleClick: handleLeaveAReview }) })) })) }));
};
export default ProfileReviews;
