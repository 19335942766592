import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppName } from '../../../features/entities/core-entities';
import getMeetingUrl from '../../../features/helpers/get-meeting-url';
import useLocale from '../../../features/providers/locale-provider';
import { useTranslations } from '../../../features/providers/translations-provider';
import Div from '../../common/div';
import Typography from '../../common/typography';
import HubspotMeeting from '../../hubspot/hubspot-meeting';
const FormMeeting = ({ isICP }) => {
    const { t } = useTranslations();
    const { selectedLocale } = useLocale();
    const meetingUrl = getMeetingUrl(selectedLocale, 'client', isICP ? 'grants-qualification-icp' : 'grants-qualification');
    return (_jsxs(Div, { flex: "column", align: "center", gap: 8, fitContent: true, children: [_jsx(Typography, { elementTheme: "subtitle2", children: t('form_viewer|bookMeeting') }), _jsx(HubspotMeeting, { meetingUrl: meetingUrl, name: '', email: '', app: AppName.Client, companyName: '', phone: '' })] }));
};
export default FormMeeting;
