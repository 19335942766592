import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Divider from 'antd/es/divider';
import React from 'react';
import getShortId from '../../../../../../features/helpers/get-short-id';
import useLocale from '../../../../../../features/providers/locale-provider';
import { useTheme } from '../../../../../../plugins/styled';
import Div from '../../../../../common/div';
import Typography from '../../../../../common/typography';
import getItemTranslatedLabel from '../../../../form-builder/utils/get-item-translated-label';
import FormSubmisionEditorGroup from '../form-submission-group';
const FormSubmissionEditorPage = ({ page }) => {
    var _a;
    const { selectedLocale } = useLocale();
    const theme = useTheme();
    return (_jsxs(Div, { flex: "column", gap: 24, children: [_jsxs("div", { children: [_jsx(Typography, { elementTheme: "subtitle2", children: getItemTranslatedLabel({
                            label_en: page.entity.page_title_en,
                            label_fr: page.entity.page_title_fr,
                            fallback: getShortId(page.entity.form_page_id),
                            parseOptions: {},
                            locale: selectedLocale,
                        }) }), _jsx(Typography, { elementTheme: "body2", children: getItemTranslatedLabel({
                            label_en: page.entity.page_heading_en,
                            label_fr: page.entity.page_heading_fr,
                            fallback: '',
                            parseOptions: {},
                            locale: selectedLocale,
                        }) })] }), _jsx("div", { children: React.Children.toArray((_a = page.groups) === null || _a === void 0 ? void 0 : _a.map((group, j) => (_jsxs(_Fragment, { children: [_jsx(FormSubmisionEditorGroup, { group: group }), j < page.groups.length - 1 && (_jsx(Divider, { style: {
                                borderColor: theme.colors.grey_2,
                                margin: '10px 0',
                            }, variant: "dashed" }))] })))) })] }));
};
export default FormSubmissionEditorPage;
