import { jsx as _jsx } from "react/jsx-runtime";
import RatingFill from '@hellodarwin/icons/dist/icons/RatingFill';
import RatingHalf from '@hellodarwin/icons/dist/icons/RatingHalf';
import RatingOutline from '@hellodarwin/icons/dist/icons/RatingOutline';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
const useRatingIcon = (props) => {
    const theme = useTheme();
    const iconSize = useMemo(() => props.size === 'large'
        ? 32
        : props.size === 'small'
            ? 16
            : props.size === 'extra-large'
                ? 46
                : props.size === 'extra-small'
                    ? 12
                    : 24, [props]);
    return React.useCallback((starProps) => {
        var _a, _b;
        const { value, showOne, allowHalf } = props;
        if (showOne) {
            if (value > 3) {
                return (_jsx(RatingFill, { size: iconSize, color: theme.colors.yellow_star }));
            }
            else if (value < 1.5) {
                return (_jsx(RatingOutline, { size: iconSize, color: theme.colors.yellow_star }));
            }
            else {
                return (_jsx(RatingHalf, { size: iconSize, color: theme.colors.yellow_star }));
            }
        }
        else {
            const index = ((_a = starProps === null || starProps === void 0 ? void 0 : starProps.index) !== null && _a !== void 0 ? _a : 0) + 1;
            const value = (_b = starProps === null || starProps === void 0 ? void 0 : starProps.value) !== null && _b !== void 0 ? _b : 0;
            if (value >= index) {
                return (_jsx(RatingFill, { size: iconSize, color: theme.colors.yellow_star }));
            }
            else if (value >= index - 0.5 && allowHalf) {
                return (_jsx(RatingHalf, { size: iconSize, color: theme.colors.yellow_star }));
            }
            else {
                return _jsx(RatingOutline, { size: iconSize, color: theme.colors.grey_4 });
            }
        }
    }, [props, iconSize]);
};
export default useRatingIcon;
