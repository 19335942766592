import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import React from 'react';
import getShortId from '../../../../../features/helpers/get-short-id';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import HdTag from '../../../../common/hd-tag';
import Typography from '../../../../common/typography';
import getFormTypeColor from '../../utils/get-form-type-color';
import getItemTranslatedLabel from '../../utils/get-item-translated-label';
const FormBuilderHeader = ({ formResponse, locale, openGrant, }) => {
    const theme = useTheme();
    return (_jsxs(Div, { flex: "column", gap: 16, children: [_jsxs("div", { children: [_jsx(Typography.Title, { level: 2, elementTheme: "h4", textAlign: "center", children: getItemTranslatedLabel({
                            locale,
                            label_en: formResponse.title_en,
                            label_fr: formResponse.title_fr,
                            fallback: `Form ${getShortId(formResponse.form_id)}`,
                        }) }), _jsx(Typography, { elementTheme: "body1", textAlign: "center", children: getItemTranslatedLabel({
                            locale,
                            label_en: formResponse.subtitle_en,
                            label_fr: formResponse.subtitle_fr,
                            fallback: ``,
                        }) })] }), _jsxs(Div, { flex: "row", gap: 8, wrap: "wrap", align: "center", justify: "center", children: [formResponse.grants &&
                        React.Children.toArray(formResponse.grants.map((grant) => (_jsx(HdTag, { color: theme.colors.purple_2, text: grant.display_title, hoverable: true, size: "large", onClick: () => openGrant(grant), icon: _jsx(NewWindow, { size: 16 }), iconPosition: "end" })))), !!formResponse.form_type && (_jsx(HdTag, { color: getFormTypeColor(formResponse.form_type, theme), text: formResponse.form_type, size: "large" }))] })] }));
};
export default FormBuilderHeader;
