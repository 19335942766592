'use client';
import styled, { css } from 'styled-components';
export const getLineClampStyling = (lineClamp) => css `
  -webkit-line-clamp: ${lineClamp};
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
`;
const FitContentStyling = css `
  width: max-content;
  height: fit-content;
  line-height: 1;
`;
const EllipsisStyling = css `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;
const StrikeStyling = css `
position: relative;

&:before {
  position: absolute;
  content: "";
  left: 0;
  top: 45%;
  right: 0;
  border-top: ${({ $bold }) => ($bold ? '2px' : '1px')} solid;
  border-color: inherit;

  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
  $
}
`;
const TextStyling = css `
  &.hd-heading,
  &.hd-paragraph {
    margin: 0;
    color: ${({ $color }) => ($color ? $color : 'inherit')};
    ${({ $colorHover }) => $colorHover ? `&:hover { color:${$colorHover}; }` : undefined};

    ${({ $nowrap }) => (!!$nowrap ? 'white-space: nowrap;' : '')}
    ${({ $inline }) => (!!$inline ? 'display: inline-block;' : '')}
  ${({ $underline }) => ($underline ? 'text-decoration: underline;' : '')}
  ${({ $fitContent }) => (!!$fitContent ? FitContentStyling : '')}
  ${({ $strike }) => (!!$strike ? StrikeStyling : '')}
  ${({ $ellipsis }) => (!!$ellipsis ? EllipsisStyling : '')}

    ${({ $bold, $medium, theme }) => !!$bold || !!$medium
    ? `
  font-weight: ${$bold
        ? theme.fontWeights.semibold
        : !!$medium
            ? theme.fontWeights.medium
            : undefined};
    `
    : undefined}
    ${({ $lineClamp }) => (!!$lineClamp ? getLineClampStyling($lineClamp) : '')}
  }
`;
export const Highlighting = styled.div `
  position: absolute;
  width: 105%;
  height: 70%;
  position: absolute;
  bottom: 1%;
  left: -4%;
  background-color: ${({ $color }) => $color};
`;
export const HighlightContainer = styled.div `
  position: relative;
  width: fit-content;
  display: inline-block;
`;
export const ParagraphContainer = styled.div `
  ${TextStyling}
`;
export const H1 = styled.h1 `
  ${TextStyling}
`;
export const H2 = styled.h2 `
  ${TextStyling}
`;
export const H3 = styled.h3 `
  ${TextStyling}
`;
export const H4 = styled.h4 `
  ${TextStyling}
`;
export const H5 = styled.h5 `
  ${TextStyling}
`;
export const H6 = styled.h6 `
  ${TextStyling}
`;
