'use client';
import { useCallback, useMemo } from 'react';
import { usePagination } from '../../../../features/providers/pagination-provider';
const rangeBetween = (start, end) => Array.from({ length: end - start + 1 }, (_, i) => start + i);
const usePrepareFundingExplorerFilters = ({ filterform, }) => {
    const { params } = usePagination();
    const parseArrayParam = (key) => { var _a, _b; return (_b = (_a = params[key]) === null || _a === void 0 ? void 0 : _a.split(',')) !== null && _b !== void 0 ? _b : []; };
    const filteredSubindustry = useMemo(() => {
        const industry = parseArrayParam('industry');
        const subindustry = parseArrayParam('subindustry');
        return subindustry.filter((si) => industry.some((i) => {
            const [start, end] = i.split('-').map(Number);
            if (!isNaN(start) && !isNaN(end)) {
                return rangeBetween(start, end).some((index) => si.startsWith(`${index}`));
            }
            return si.startsWith(i);
        }));
    }, [params]);
    return useCallback(() => {
        var _a, _b, _c, _d;
        const formMapping = {
            filterBy: (_a = params['filterBy']) !== null && _a !== void 0 ? _a : '',
            industry: parseArrayParam('industry'),
            subindustry: filteredSubindustry,
            service: parseArrayParam('service'),
            financingType: parseArrayParam('financingType'),
            status: parseArrayParam('status'),
            selectedProjects: (_b = parseArrayParam('selectedProjects')) !== null && _b !== void 0 ? _b : [],
            closingDate: (_c = new Date(params['closingDate'])) !== null && _c !== void 0 ? _c : undefined,
            region: parseArrayParam('region'),
            companySize: params['companySize'],
            annualRevenue: params['annualRevenue'],
            textQuery: (_d = params['textQuery']) !== null && _d !== void 0 ? _d : '',
        };
        filterform.setFieldsValue(formMapping);
    }, [filterform, params, filteredSubindustry]);
};
export default usePrepareFundingExplorerFilters;
