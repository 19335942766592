import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Skeleton from 'antd/es/skeleton';
import { useTheme } from 'styled-components';
import Div from '../div';
const LanguageTranslationsViewerFieldContainer = ({ renderField, content_en, content_fr, is_loading_en, is_loading_fr, ghost, }) => {
    const theme = useTheme();
    return (_jsxs(Div, { flex: "row", align: "stretch", children: [_jsx("div", { style: {
                    padding: '8px 24px',
                    flex: 1,
                    borderRight: !ghost ? `1px solid ${theme.colors.grey_4}` : undefined,
                }, children: is_loading_en ? (_jsx(Skeleton, { style: {
                        backgroundColor: theme.colors.white_1,
                    }, active: true })) : (renderField(content_en, 'en')) }), _jsx("div", { style: { flex: 1, padding: '8px 24px' }, children: is_loading_fr ? (_jsx(Skeleton, { style: {
                        backgroundColor: theme.colors.white_1,
                    }, active: true })) : (renderField(content_fr, 'fr')) })] }));
};
export default LanguageTranslationsViewerFieldContainer;
