export const getSelectOptions = (formsSelectOptions, locale) => {
    if (locale === 'en') {
        return formsSelectOptions
            .filter((option) => !option.hidden)
            .map((option) => ({
            label: option.label_en,
            value: option.value,
        }));
    }
    else {
        return formsSelectOptions
            .filter((option) => !option.hidden)
            .map((option) => ({
            label: option.label_fr,
            value: option.value,
        }));
    }
};
