var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslations } from '../../../features/providers/translations-provider';
import Modal from '../../common/hd-modal';
import Typography from '../../common/typography';
import AssetUploadForm from './asset-upload-form';
const AssetUploadModal = (_a) => {
    var { handleSaveAsset, records, owners, asset } = _a, modalProps = __rest(_a, ["handleSaveAsset", "records", "owners", "asset"]);
    const { t } = useTranslations();
    return (_jsxs(Modal, Object.assign({}, modalProps, { styles: { content: { padding: '48px 96px' }, wrapper: { zIndex: 2000 } }, children: [_jsx(Typography, { elementTheme: "h5", style: { marginBottom: 24 }, children: t('assets|upload_new_file') }), _jsx(AssetUploadForm, { handleAfterFinish: modalProps.handleCancel, handleSaveAsset: handleSaveAsset, records: records, owners: owners, asset: asset, withCancel: true })] })));
};
export default AssetUploadModal;
