import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import ProfileBlocks from '../../../../features/enums/profile-blocks';
import useMediaQuery from '../../../../features/helpers/use-media-query';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import SeeMoreList from '../../../common/see-more/see-more-list';
import ProfileSection from '../profile-section';
import ProfileEmptySection from '../profile-section/empty-state';
import TeamMemberCard from './team-member-card';
import TeamPreviewModal from './team-preview-modal';
const ProfileTeam = ({ provider, teamMembers, isEditable, toggleEditModal, id, setTeamMembers, }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    const viewLimit = isTablet ? 3 : 6;
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedMember, setSelectedMember] = useState({});
    const handleModal = (teamMember) => {
        if (isEditable && !!toggleEditModal) {
            toggleEditModal({
                open: true,
                type: ProfileBlocks.Team,
                targetId: teamMember === null || teamMember === void 0 ? void 0 : teamMember.provider_team_member_id,
            });
        }
        else {
            setSelectedMember(teamMember);
            setModalOpen(!modalOpen);
        }
    };
    const content = useMemo(() => {
        const children = [];
        teamMembers.forEach((member) => {
            children.push(_jsx(TeamMemberCard, { member: member, handleClick: (member) => handleModal(member), teamMembers: teamMembers, setTeamMembers: setTeamMembers, provider: provider }));
        });
        return children;
    }, [teamMembers, isEditable]);
    return (_jsxs(_Fragment, { children: [_jsx(ProfileSection, { title: t('profile_team|teamTitle', { companyName: provider.name }), isEditable: isEditable, handleEdit: handleModal, hide: teamMembers.length <= 0, id: id, emptyState: _jsx(ProfileEmptySection, { sectionName: "Team", title: t('profile_team|teamEmptyTitle'), subtitle: t('profile_team|teamEmptySubtitle'), cta: t('profile_team|teamEmptyCta'), handleClick: handleModal, isEditable: isEditable }), children: _jsx(SeeMoreList, { grid: { sm: 1, md: 1, column: 2, gutter: 24 }, limit: viewLimit, content: content }) }), !isEditable && (_jsx(TeamPreviewModal, { provider: provider, selectedMember: selectedMember, setSelectedMember: setSelectedMember, teamMembers: teamMembers, open: modalOpen, onCancel: () => setModalOpen(false), setMembers: setTeamMembers }))] }));
};
export default ProfileTeam;
