import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Empty from 'antd/es/empty';
import { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { usePagination } from '../../../../features/providers/pagination-provider';
import { useTranslations } from '../../../../features/providers/translations-provider';
import Container from '../../../common/container';
import List from '../../../common/List';
import Loading from '../../../loading';
import GrantCard from '../../card';
import getFeaturedTags from '../filter/utils/get-featured-tags';
import FundingExplorerListLocked from '../funding-explorer-list-locked';
const FundingExplorerContent = ({ styles, isInitialised, stateIsLoading, isLocked, grants, grantsPath = '/funding-explorer', featuredProjects, filterValues, handlePageChange, goToSettings, }) => {
    var _a, _b;
    const theme = useTheme();
    const { t } = useTranslations();
    const { activePage, pageSize } = usePagination();
    const getFeaturedTagsCallback = useCallback(getFeaturedTags, [
        filterValues === null || filterValues === void 0 ? void 0 : filterValues.selectedProjects,
    ]);
    const hasGrants = useMemo(() => grants !== undefined && (grants === null || grants === void 0 ? void 0 : grants.length) > 0, [grants]);
    return (_jsxs(Container, { style: Object.assign({ marginTop: 24, position: 'relative', minHeight: `calc(100vh - ${theme.appearance.pageHeaderHeight + theme.appearance.appHeaderHeight + 71 + 12 + 32}px)` }, styles === null || styles === void 0 ? void 0 : styles.content), children: [!isInitialised && _jsx(Loading, {}), _jsx(List, { renderEmpty: () => (_jsx(Empty, { description: t('grantsFundingExplorer|grantsnoGrants') })), loading: stateIsLoading, dataSource: grants, renderItem: (grant) => {
                    var _a;
                    return (_jsx(GrantCard, Object.assign({ featuredTags: getFeaturedTagsCallback(grant, (_a = filterValues === null || filterValues === void 0 ? void 0 : filterValues.selectedProjects) !== null && _a !== void 0 ? _a : [], featuredProjects !== null && featuredProjects !== void 0 ? featuredProjects : []), parentPath: grantsPath }, grant)));
                }, pagination: {
                    total: hasGrants ? ((_b = (_a = grants === null || grants === void 0 ? void 0 : grants[0]) === null || _a === void 0 ? void 0 : _a.total_count) !== null && _b !== void 0 ? _b : 0) : 0,
                    currentPage: activePage,
                    onPageChange: handlePageChange,
                    pageSize,
                } }), _jsx(FundingExplorerListLocked, { isLocked: isLocked, goToSettings: goToSettings })] }));
};
export default FundingExplorerContent;
