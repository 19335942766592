import {
  ApiError,
  ApiErrorInitialState,
  GrantProject,
  GrantProjectTag,
  GrantRfpTag,
  GrantRfpTagUpdate,
  GrantTag,
  NewTag,
  ProjectTag,
  TagType,
} from '@hellodarwin/core/lib/features/entities';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app';
import { showErrorNotification } from '../../utils';
import AdminApi from '../admin-api';

const grantTagsAdapter = createEntityAdapter({
  selectId: (tag: GrantTag) => tag.id,
});

const grantProjectTagsAdapter = createEntityAdapter({
  selectId: (tag: GrantProjectTag) => tag.id,
});

const projectTagsAdapter = createEntityAdapter({
  selectId: (tag: ProjectTag) => tag.id,
});

interface GrantTagsState {
  status: 'idle' | 'pending';
  error: ApiError;
  grantTags: EntityState<GrantTag, string>;
  projectTags: EntityState<ProjectTag, string>;
  grantProjectTags: Record<
    string,
    Record<
      string,
      {
        entities: EntityState<GrantProjectTag, string>;
        loading: boolean;
      }
    >
  >;
  selectedTags: NewTag[];
  grantRfpTags: Record<string, GrantRfpTag>;
  goalOptions: { entities: Record<string, NewTag>; loading: boolean };
  expenseCategoryOptions: {
    entities: Record<string, NewTag>;
    loading: boolean;
  };
  expenseOptions: { entities: Record<string, NewTag>; loading: boolean };
}

const initialState: GrantTagsState = {
  status: 'idle',
  error: ApiErrorInitialState,
  grantTags: grantTagsAdapter.getInitialState(),
  projectTags: projectTagsAdapter.getInitialState(),
  grantProjectTags: {},
  grantRfpTags: {},
  selectedTags: [],
  goalOptions: { entities: {}, loading: false },
  expenseCategoryOptions: { entities: {}, loading: false },
  expenseOptions: { entities: {}, loading: false },
};

export const fetchChildTags = createAsyncThunk<
  { tags: NewTag[]; type: TagType },
  { api: AdminApi; parentIds: string[]; type: TagType; locale: string },
  { rejectValue: ApiError }
>(
  'admin/fetchChildTags',
  async ({ api, parentIds, type, locale }, { rejectWithValue }) => {
    try {
      const response = await api.get<NewTag[]>(
        type === TagType.Goal
          ? `/grants/tags/category?locale=${locale}&category=${type}`
          : `/grants/tags/children?parentIds=${parentIds}&type=${type}&locale=${locale}`,
      );
      return { tags: response.data, type: type };
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

// Grant Tags
export const generateGrantTags = createAsyncThunk<
  GrantTag[],
  { api: AdminApi; grantId: string },
  { rejectValue: ApiError }
>('admin/generateGrantTags', async ({ api, grantId }, { rejectWithValue }) => {
  try {
    const response = await api.post<GrantTag[]>(
      `/grants/tags/generate/${grantId}`,
    );
    return response.data;
  } catch (err: any) {
    console.error(err.response.data);
    showErrorNotification(err.response.data);
    return rejectWithValue(err.response.data);
  }
});

export const deleteAllGrantTags = createAsyncThunk<
  string,
  { api: AdminApi; grantId: string },
  { rejectValue: ApiError }
>('admin/deleteAllGrantTags', async ({ api, grantId }, { rejectWithValue }) => {
  try {
    const response = await api.delete<string>(`/grants/${grantId}/tags`);
    return response.data;
  } catch (err: any) {
    console.error(err.response.data);
    showErrorNotification(err.response.data);
    return rejectWithValue(err.response.data);
  }
});

export const fetchGrantTags = createAsyncThunk<
  GrantTag[],
  { api: AdminApi; grantId: string; locale: string },
  { rejectValue: ApiError }
>(
  'admin/fetchGrantTags',
  async ({ api, grantId, locale }, { rejectWithValue }) => {
    try {
      const response = await api.get<GrantTag[]>(
        `/grants/${grantId}/tags?locale=${locale}`,
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const addGrantTags = createAsyncThunk<
  GrantTag[],
  { api: AdminApi; grantId: string; tags: string[] },
  { rejectValue: ApiError }
>('admin/addGrantTags', async ({ api, grantId, tags }, { rejectWithValue }) => {
  try {
    const response = await api.post<GrantTag[]>(`/grants/${grantId}/tags`, {
      tagIds: tags,
    });
    return response.data;
  } catch (err: any) {
    console.error(err.response.data);
    return rejectWithValue(err.response.data);
  }
});

export const deleteGrantTagWithChildren = createAsyncThunk<
  GrantTag[],
  { api: AdminApi; grantId: string; tagId: string },
  { rejectValue: ApiError }
>(
  'admin/deleteGrantTagWithChildren',
  async ({ api, grantId, tagId }, { rejectWithValue }) => {
    try {
      const response = await api.delete<GrantTag[]>(
        `/grants/${grantId}/tags/${tagId}`,
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

// Project Tags
export const generateProjectTags = createAsyncThunk<
  ProjectTag[],
  { api: AdminApi; projectId: string },
  { rejectValue: ApiError }
>(
  'admin/generateProjectTags',
  async ({ api, projectId }, { rejectWithValue }) => {
    try {
      const response = await api.post<ProjectTag[]>(
        `/projects/tags/generate/${projectId}`,
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteAllProjectTags = createAsyncThunk<
  string,
  { api: AdminApi; projectId: string },
  { rejectValue: ApiError }
>(
  'admin/deleteAllProjectTags',
  async ({ api, projectId }, { rejectWithValue }) => {
    try {
      const response = await api.delete<string>(`/projects/${projectId}/tags`);
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchProjectTags = createAsyncThunk<
  ProjectTag[],
  { api: AdminApi; projectId: string; locale: string },
  { rejectValue: ApiError }
>(
  'admin/fetchProjectTags',
  async ({ api, projectId, locale }, { rejectWithValue }) => {
    try {
      const response = await api.get<ProjectTag[]>(
        `/projects/${projectId}/tags?locale=${locale}`,
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const addProjectTags = createAsyncThunk<
  ProjectTag[],
  { api: AdminApi; projectId: string; tags: string[] },
  { rejectValue: ApiError }
>(
  'admin/addProjectTags',
  async ({ api, projectId, tags }, { rejectWithValue }) => {
    try {
      const response = await api.post<ProjectTag[]>(
        `/projects/${projectId}/tags`,
        {
          tagIds: tags,
        },
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateProjectTags = createAsyncThunk<
  ProjectTag[],
  { api: AdminApi; projectId: string; tags: string[] },
  { rejectValue: ApiError }
>(
  'admin/updateProjectTags',
  async ({ api, projectId, tags }, { rejectWithValue }) => {
    try {
      const response = await api.put<ProjectTag[]>(
        `/projects/${projectId}/tags`,
        {
          tagIds: tags,
        },
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteProjectTagWithChildren = createAsyncThunk<
  ProjectTag[],
  { api: AdminApi; projectId: string; tagId: string },
  { rejectValue: ApiError }
>(
  'admin/deleteProjectTagWithChildren',
  async ({ api, projectId, tagId }, { rejectWithValue }) => {
    try {
      const response = await api.delete<ProjectTag[]>(
        `/projects/${projectId}/tags/${tagId}`,
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

// Grant Project Tags
export const generateGrantProjectTags = createAsyncThunk<
  { tags: GrantProjectTag[]; grantProjectId: string; locales: string[] },
  { api: AdminApi; grantProject: GrantProject },
  { rejectValue: ApiError }
>(
  'admin/generateGrantProjectTags',
  async ({ api, grantProject }, { rejectWithValue }) => {
    try {
      const response = await api.post<GrantProjectTag[]>(
        `/grant-projects/tags/generate`,
        grantProject,
      );
      const tags = response.data;
      const locales = tags
        .map((tag) => tag.locale)
        .filter((value, index, self) => self.indexOf(value) === index);
      return { tags, grantProjectId: grantProject.grant_project_id, locales };
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const addGrantProjectTags = createAsyncThunk<
  { tags: GrantProjectTag[]; grantProjectId: string; locales: string[] },
  { api: AdminApi; grantProjectId: string; newTags: string[] },
  { rejectValue: ApiError }
>(
  'admin/addGrantProjectTags',
  async ({ api, grantProjectId, newTags }, { rejectWithValue }) => {
    try {
      const response = await api.post<GrantProjectTag[]>(
        `/grant-projects/${grantProjectId}/tags`,
        {
          tagIds: newTags,
        },
      );

      const tags = response.data;
      const locales = tags
        .map((tag) => tag.locale)
        .filter((value, index, self) => self.indexOf(value) === index);
      return { tags, grantProjectId, locales };
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteGrantProjectTagWithChildren = createAsyncThunk<
  { tags: GrantProjectTag[]; grantProjectId: string; locales: string[] },
  { api: AdminApi; grantProjectId: string; tagId: string },
  { rejectValue: ApiError }
>(
  'admin/deleteGrantProjectTagWithChildren',
  async ({ api, grantProjectId, tagId }, { rejectWithValue }) => {
    try {
      const response = await api.delete<GrantProjectTag[]>(
        `/grant-projects/${grantProjectId}/tags/${tagId}`,
      );

      const tags = response.data;
      if (tags) {
        let locales = tags
          ?.map((tag) => tag.locale)
          .filter((value, index, self) => self.indexOf(value) === index);
        return { tags, grantProjectId, locales };
      }
      let locales = ['fr', 'en'];
      return { tags: [], grantProjectId, locales };
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchTagsByGrantProjectId = createAsyncThunk<
  { tags: GrantProjectTag[]; grantProjectId: string; locale: string },
  { api: AdminApi; grantProjectId: string; locale: string },
  { rejectValue: ApiError }
>(
  'admin/fetchTagsByGrantProjectId',
  async ({ api, grantProjectId, locale }, { rejectWithValue }) => {
    try {
      const response = await api.get<GrantProjectTag[]>(
        `/grant-projects/${grantProjectId}/tags?locale=${locale}`,
      );
      const tags = response.data;
      return { tags, grantProjectId, locale };
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

// Grant RFP tags
export const fetchAllGrantRfpTags = createAsyncThunk<
  GrantRfpTag[],
  { api: AdminApi; locale: string },
  { rejectValue: ApiError }
>(
  'admin/fetchAllGrantRfpTags',
  async ({ api, locale }, { rejectWithValue }) => {
    try {
      const response = await api.get<GrantRfpTag[]>(
        `/tags/rfp-grants?locale=${locale}`,
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateGrantRfpTags = createAsyncThunk<
  GrantRfpTag[],
  {
    api: AdminApi;
    locale: string;
    added_tags: GrantRfpTag[];
    deleted_tags: GrantRfpTag[];
  },
  { rejectValue: ApiError }
>(
  'admin/updateGrantRfpTags',
  async ({ api, locale, added_tags, deleted_tags }, { rejectWithValue }) => {
    try {
      added_tags = added_tags.map((tag) => ({ ...tag, id: '' }));
      deleted_tags = deleted_tags.map((tag) => ({
        ...tag,
        id: '',
      }));
      const grantRfpTagUpdate: GrantRfpTagUpdate = { added_tags, deleted_tags };
      const response = await api.put<GrantRfpTag[]>(
        `/tags/rfp-grants?locale=${locale}`,
        grantRfpTagUpdate,
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

const newTagsSlice = createSlice({
  name: 'newTags',
  initialState,
  reducers: {
    setSelectedTags(state, { payload }) {
      state.selectedTags = payload;
    },
    clearSelectedTags(state) {
      state.selectedTags = [];
    },
    clearAllOptions(state) {
      state.expenseCategoryOptions.entities = {};
      state.expenseOptions.entities = {};
    },
  },
  extraReducers: (builder) => {
    // Project Tags
    builder
      .addCase(generateProjectTags.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(generateProjectTags.fulfilled, (state, { payload }) => {
        projectTagsAdapter.setAll(state.projectTags, payload || []);
        state.status = 'idle';
      })
      .addCase(generateProjectTags.rejected, (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      });
    builder
      .addCase(deleteAllProjectTags.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(deleteAllProjectTags.fulfilled, (state) => {
        projectTagsAdapter.setAll(state.projectTags, []);
        state.status = 'idle';
      })
      .addCase(deleteAllProjectTags.rejected, (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      });
    builder
      .addCase(fetchProjectTags.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchProjectTags.fulfilled, (state, action) => {
        state.status = 'idle';
        projectTagsAdapter.setAll(state.projectTags, action.payload || []);
      })
      .addCase(fetchProjectTags.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.payload!;
      })
      .addCase(addProjectTags.rejected, (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
      })
      .addCase(addProjectTags.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(addProjectTags.fulfilled, (state, { payload }) => {
        projectTagsAdapter.setAll(state.projectTags, payload || []);
        state.status = 'idle';
      })
      .addCase(updateProjectTags.rejected, (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
      })
      .addCase(updateProjectTags.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(updateProjectTags.fulfilled, (state, { payload }) => {
        projectTagsAdapter.setAll(state.projectTags, payload || []);
        state.status = 'idle';
      })
      .addCase(deleteProjectTagWithChildren.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(deleteProjectTagWithChildren.fulfilled, (state, { payload }) => {
        projectTagsAdapter.setAll(state.projectTags, payload || []);
        state.status = 'idle';
      })
      .addCase(deleteProjectTagWithChildren.rejected, (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      });
    // Grant Tags
    builder
      .addCase(generateGrantTags.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(generateGrantTags.fulfilled, (state, { payload }) => {
        grantTagsAdapter.setAll(state.grantTags, payload || []);
        state.status = 'idle';
      })
      .addCase(generateGrantTags.rejected, (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      });
    builder
      .addCase(deleteAllGrantTags.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(deleteAllGrantTags.fulfilled, (state) => {
        grantTagsAdapter.setAll(state.grantTags, []);
        state.status = 'idle';
      })
      .addCase(deleteAllGrantTags.rejected, (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      });
    builder
      .addCase(fetchGrantTags.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchGrantTags.fulfilled, (state, action) => {
        state.status = 'idle';
        grantTagsAdapter.setAll(state.grantTags, action.payload || []);
      })
      .addCase(fetchGrantTags.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.payload!;
      })

      .addCase(fetchChildTags.pending, (state, action) => {
        switch (action.meta.arg.type) {
          case TagType.Goal:
            state.goalOptions.loading = true;
            break;
          case TagType.ExpenseCategory:
            state.expenseCategoryOptions.loading = true;
            break;
          case TagType.Expense:
            state.expenseOptions.loading = true;
            break;
        }
      })
      .addCase(fetchChildTags.fulfilled, (state, { payload }) => {
        const { tags, type } = payload;
        const entities = tags?.reduce<Record<string, NewTag>>((acc, tag) => {
          acc[tag.tag_id] = tag;
          return acc;
        }, {});
        switch (type) {
          case TagType.Goal:
            state.goalOptions.entities = entities ?? {};
            state.goalOptions.loading = false;
            break;
          case TagType.ExpenseCategory:
            state.expenseCategoryOptions.entities = entities ?? {};
            state.expenseCategoryOptions.loading = false;
            break;
          case TagType.Expense:
            state.expenseOptions.entities = entities ?? {};
            state.expenseOptions.loading = false;
            break;
        }
      })
      .addCase(fetchChildTags.rejected, (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      })
      .addCase(addGrantTags.rejected, (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
      })
      .addCase(addGrantTags.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(addGrantTags.fulfilled, (state, { payload }) => {
        grantTagsAdapter.setAll(state.grantTags, payload || []);
        state.status = 'idle';
      })
      .addCase(deleteGrantTagWithChildren.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(deleteGrantTagWithChildren.fulfilled, (state, { payload }) => {
        grantTagsAdapter.setAll(state.grantTags, payload || []);
        state.status = 'idle';
      })
      .addCase(deleteGrantTagWithChildren.rejected, (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      })
      //Grant Project Tags
      .addCase(addGrantProjectTags.pending, (state, { meta }) => {
        const { grantProjectId } = meta.arg;
        // Set loading to true for all locales that contain the grantProjectId
        Object.keys(state.grantProjectTags).forEach((locale) => {
          if (!state.grantProjectTags[locale][grantProjectId]) {
            state.grantProjectTags[locale][grantProjectId] = {
              entities: grantProjectTagsAdapter.getInitialState(),
              loading: true,
            };
          } else {
            state.grantProjectTags[locale][grantProjectId].loading = true;
          }
        });
      })
      .addCase(addGrantProjectTags.fulfilled, (state, { payload }) => {
        const { tags, grantProjectId, locales } = payload;
        locales.forEach((locale) => {
          if (!state.grantProjectTags[locale]) {
            state.grantProjectTags[locale] = {};
          }
          if (!state.grantProjectTags[locale][grantProjectId]) {
            state.grantProjectTags[locale][grantProjectId] = {
              entities: grantProjectTagsAdapter.getInitialState(),
              loading: false,
            };
          }
          const localeSpecificTags = tags?.filter(
            (tag) => tag.locale === locale,
          );
          state.grantProjectTags[locale][grantProjectId] = {
            entities: grantProjectTagsAdapter.setAll(
              {
                ...state.grantProjectTags[locale][grantProjectId].entities,
              },
              localeSpecificTags || [],
            ),
            loading: false,
          };
        });
        state.status = 'idle';
      })
      .addCase(addGrantProjectTags.rejected, (state, { payload, meta }) => {
        const { grantProjectId } = meta.arg;
        // Clear loading state for all locales that contain the grantProjectId
        Object.keys(state.grantProjectTags).forEach((locale) => {
          if (state.grantProjectTags[locale][grantProjectId]) {
            state.grantProjectTags[locale][grantProjectId].loading = false;
          }
        });
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      })
      .addCase(deleteGrantProjectTagWithChildren.pending, (state, { meta }) => {
        const { grantProjectId } = meta.arg;
        // Set loading to true for all locales that contain the grantProjectId
        Object.keys(state.grantProjectTags).forEach((locale) => {
          if (!state.grantProjectTags[locale][grantProjectId]) {
            state.grantProjectTags[locale][grantProjectId] = {
              entities: grantProjectTagsAdapter.getInitialState(),
              loading: true,
            };
          } else {
            state.grantProjectTags[locale][grantProjectId].loading = true;
          }
        });
      })
      .addCase(
        deleteGrantProjectTagWithChildren.fulfilled,
        (state, { payload }) => {
          const { tags, grantProjectId, locales } = payload;
          locales.forEach((locale) => {
            if (!state.grantProjectTags[locale]) {
              state.grantProjectTags[locale] = {};
            }
            if (!state.grantProjectTags[locale][grantProjectId]) {
              state.grantProjectTags[locale][grantProjectId] = {
                entities: grantProjectTagsAdapter.getInitialState(),
                loading: false,
              };
            }
            const localeSpecificTags = tags?.filter(
              (tag) => tag.locale === locale,
            );
            state.grantProjectTags[locale][grantProjectId] = {
              entities: grantProjectTagsAdapter.setAll(
                {
                  ...state.grantProjectTags[locale][grantProjectId].entities,
                },
                localeSpecificTags || [],
              ),
              loading: false,
            };
          });
          state.status = 'idle';
        },
      )
      .addCase(
        deleteGrantProjectTagWithChildren.rejected,
        (state, { payload, meta }) => {
          const { grantProjectId } = meta.arg;
          // Clear loading state for all locales that contain the grantProjectId
          Object.keys(state.grantProjectTags).forEach((locale) => {
            if (state.grantProjectTags[locale][grantProjectId]) {
              state.grantProjectTags[locale][grantProjectId].loading = false;
            }
          });
          state.error = payload ?? ApiErrorInitialState;
          state.status = 'idle';
        },
      )
      .addCase(generateGrantProjectTags.pending, (state, { meta }) => {
        const { grantProject } = meta.arg;
        Object.keys(state.grantProjectTags).forEach((locale) => {
          if (!state.grantProjectTags[locale][grantProject.grant_project_id]) {
            state.grantProjectTags[locale][grantProject.grant_project_id] = {
              entities: grantProjectTagsAdapter.getInitialState(),
              loading: true,
            };
          } else {
            state.grantProjectTags[locale][
              grantProject.grant_project_id
            ].loading = true;
          }
        });
      })
      .addCase(generateGrantProjectTags.fulfilled, (state, { payload }) => {
        const { tags, grantProjectId, locales } = payload;
        locales.forEach((locale) => {
          if (!state.grantProjectTags[locale]) {
            state.grantProjectTags[locale] = {};
          }
          if (!state.grantProjectTags[locale][grantProjectId]) {
            state.grantProjectTags[locale][grantProjectId] = {
              entities: grantProjectTagsAdapter.getInitialState(),
              loading: false,
            };
          }
          const localeSpecificTags = tags?.filter(
            (tag) => tag.locale === locale,
          );
          state.grantProjectTags[locale][grantProjectId] = {
            entities: grantProjectTagsAdapter.setAll(
              {
                ...state.grantProjectTags[locale][grantProjectId].entities,
              },
              localeSpecificTags || [],
            ),
            loading: false,
          };
        });
        state.status = 'idle';
      })
      .addCase(
        generateGrantProjectTags.rejected,
        (state, { payload, meta }) => {
          const { grantProject } = meta.arg;
          // Clear loading for all locales for this grant project
          Object.keys(state.grantProjectTags).forEach((locale) => {
            if (state.grantProjectTags[locale][grantProject.grant_project_id]) {
              state.grantProjectTags[locale][
                grantProject.grant_project_id
              ].loading = false;
            }
          });
          state.error = payload ?? ApiErrorInitialState;
          state.status = 'idle';
        },
      )
      .addCase(fetchTagsByGrantProjectId.pending, (state, { meta }) => {
        const { grantProjectId, locale } = meta.arg;
        if (!state.grantProjectTags[locale]) {
          state.grantProjectTags[locale] = {};
        }
        if (!state.grantProjectTags[locale][grantProjectId]) {
          state.grantProjectTags[locale][grantProjectId] = {
            entities: grantProjectTagsAdapter.getInitialState(),
            loading: true,
          };
        } else {
          state.grantProjectTags[locale][grantProjectId].loading = true;
        }
      })
      .addCase(fetchTagsByGrantProjectId.fulfilled, (state, { payload }) => {
        const { tags, grantProjectId, locale } = payload;
        if (!state.grantProjectTags[locale]) {
          state.grantProjectTags[locale] = {};
        }
        if (!state.grantProjectTags[locale][grantProjectId]) {
          state.grantProjectTags[locale][grantProjectId] = {
            entities: grantProjectTagsAdapter.getInitialState(),
            loading: false,
          };
        }
        state.grantProjectTags[locale][grantProjectId] = {
          entities: grantProjectTagsAdapter.setAll(
            {
              ...state.grantProjectTags[locale][grantProjectId].entities,
            },
            tags || [],
          ),
          loading: false,
        };
        state.status = 'idle';
      })
      .addCase(
        fetchTagsByGrantProjectId.rejected,
        (state, { payload, meta }) => {
          const { grantProjectId, locale } = meta.arg;
          if (
            state.grantProjectTags[locale] &&
            state.grantProjectTags[locale][grantProjectId]
          ) {
            state.grantProjectTags[locale][grantProjectId].loading = false;
          }
          state.error = payload ?? ApiErrorInitialState;
          state.status = 'idle';
        },
      )
      .addCase(fetchAllGrantRfpTags.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchAllGrantRfpTags.fulfilled, (state, { payload }) => {
        const entities = payload?.reduce<Record<string, GrantRfpTag>>(
          (records, tags) => {
            records[tags.id!] = tags;
            return records;
          },
          {},
        );

        state.status = 'idle';
        state.grantRfpTags = entities ?? [];
      })
      .addCase(fetchAllGrantRfpTags.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.payload!;
      })
      .addCase(updateGrantRfpTags.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(updateGrantRfpTags.fulfilled, (state, { payload }) => {
        const entities = payload?.reduce<Record<string, GrantRfpTag>>(
          (records, tags) => {
            records[tags.id!] = tags;
            return records;
          },
          {},
        );

        state.status = 'idle';
        state.grantRfpTags = entities ?? [];
      })
      .addCase(updateGrantRfpTags.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.payload!;
      });
  },
});

export const { setSelectedTags, clearSelectedTags, clearAllOptions } =
  newTagsSlice.actions;

export const selectGoalTagsOptions = (state: RootState) =>
  state.newTags.goalOptions;
export const selectExpenseCategoryTagsOptions = (state: RootState) =>
  state.newTags.expenseCategoryOptions;
export const selectExpenseTagsOptions = (state: RootState) =>
  state.newTags.expenseOptions;
export const selectSelectedTags = (state: RootState) =>
  state.newTags.selectedTags;
export const selectGrantRfpTags = (state: RootState) =>
  state.newTags.grantRfpTags;

export const { selectAll: selectGrantTags, selectById: selectGrantTagById } =
  grantTagsAdapter.getSelectors((state: RootState) => state.newTags.grantTags);

export const {
  selectAll: selectProjectTags,
  selectById: selectProjectTagById,
} = projectTagsAdapter.getSelectors(
  (state: RootState) => state.newTags.projectTags,
);

export const selectGrantProjectTagsEntities = createSelector(
  (state: RootState) => state.newTags.grantProjectTags,
  (_, locale: string) => locale,
  (_, __, grantProjectId: string) => grantProjectId,
  (grantProjectTags, locale, grantProjectId) => {
    const projectTagsState = grantProjectTags[locale]?.[grantProjectId];
    if (projectTagsState) {
      return Object.values(
        projectTagsState.entities.entities,
      ) as GrantProjectTag[];
    }

    return [];
  },
);

export const selectTagsLoading = (state: RootState) =>
  state.newTags.status === 'pending';
export const selectTagsError = (state: RootState) => state.newTags.error;

export const newTagsReducer = newTagsSlice.reducer;
