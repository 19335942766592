'use client';
import { createGlobalStyle, css } from 'styled-components';
export const createFontSizeCSS = (size, isHeading, fontWeight) => css `
  margin: 0;
  font-size: ${({ theme }) => { var _a; return (_a = theme === null || theme === void 0 ? void 0 : theme.fonts) === null || _a === void 0 ? void 0 : _a[size]; }};
  line-height: ${({ theme }) => { var _a; return (_a = theme === null || theme === void 0 ? void 0 : theme.fontsLineHeights) === null || _a === void 0 ? void 0 : _a[size]; }};
  letter-spacing: ${({ theme }) => { var _a; return (_a = theme === null || theme === void 0 ? void 0 : theme.letterSpacing) === null || _a === void 0 ? void 0 : _a[size]; }}px;
  font-family: ${isHeading ? 'Figtree' : 'Inter'};
  font-weight: ${({ theme }) => isHeading || fontWeight === 'bold'
    ? theme.fontWeights.semibold
    : fontWeight === 'medium'
        ? theme.fontWeights.medium
        : theme.fontWeights.regular};

  @media (max-width: ${({ theme }) => { var _a; return (_a = theme === null || theme === void 0 ? void 0 : theme.breakpoints) === null || _a === void 0 ? void 0 : _a.tablet; }}px) {
    font-size: ${({ theme }) => { var _a; return (_a = theme === null || theme === void 0 ? void 0 : theme.fonts) === null || _a === void 0 ? void 0 : _a[`${size}_md`]; }};
    line-height: ${({ theme }) => { var _a; return (_a = theme === null || theme === void 0 ? void 0 : theme.fontsLineHeights) === null || _a === void 0 ? void 0 : _a[`${size}_md`]; }};
    letter-spacing: ${({ theme }) => { var _a; return (_a = theme === null || theme === void 0 ? void 0 : theme.letterSpacing) === null || _a === void 0 ? void 0 : _a[`${size}_md`]; }}px;
  }
`;
export const GlobalTypographyStyling = createGlobalStyle `
.h1{
  ${createFontSizeCSS('h1', true)}
}
.h2{
  ${createFontSizeCSS('h2', true)}
}
.h3{
  ${createFontSizeCSS('h3', true)}
}
.h4{
  ${createFontSizeCSS('h4', true)}
}
.h5{
  ${createFontSizeCSS('h5', true)}
}
.h6 {
  ${createFontSizeCSS('h6', true)}
}

.subtitle1 {
  ${createFontSizeCSS('subtitle1', false, 'medium')}
}

.subtitle2 {
  ${createFontSizeCSS('subtitle2', false, 'medium')}
}

.body1 {
  ${createFontSizeCSS('body1')}
}
.body2 {
  ${createFontSizeCSS('body2')}
}
.body3 {
  ${createFontSizeCSS('body3')}
}

.caption {
  ${createFontSizeCSS('caption')}
}

.overline {
  text-transform: uppercase;
  ${createFontSizeCSS('overline', false, 'medium')}
}


 ul,
  ol {
    list-style-position: outside;
    margin-block: 12px;
    margin-inline-start: 24px;
    li {
      margin-block: 6px;
      padding-inline-start: 8px;
      & > * {
        display: inline;
      }
    }
  }
    ul.ant-pagination {
    margin-block:0;
    margin-inline-start:0;

    li {
      padding-inline-start:0;
      margin-block:0;
    }
    .ant-pagination-item {
      
      border:1px solid ${({ theme }) => theme.colors.grey_4};
      }
  }
  ${[...Array(8)]
    .map((_, i) => `
      ul li.ql-indent-${i + 1} {
        margin-inline-start: ${32 * (i + 1)}px;
        list-style-type: ${['disc', 'circle', 'square'][i % 3]};
        & + li.ql-indent-${i} {
        margin-block-end:8px;
        }
      }
      ol li.ql-indent-${i + 1} {
        margin-inline-start: ${32 * (i + 1)}px;
        list-style-type: ${['decimal', 'lower-alpha', 'lower-roman'][i % 3]};
      }
    `)
    .join('')}
    
  


`;
