const getFeaturedTags = (grant, selectedProjects, bestProjects) => {
    const mappedProjects = selectedProjects
        .map((id) => bestProjects.find((p) => p.grant_project_id === id))
        .filter((p) => !!p);
    const relatedProjects = mappedProjects.filter((project) => project.grant_id === grant.grant_id);
    if (relatedProjects.length > 0) {
        return relatedProjects.map((project) => project.short_description);
    }
    const allTags = mappedProjects.flatMap((project) => project.grant_project_tags || []);
    const filteredTags = Array.from(new Set(allTags.filter((tag, index, self) => {
        var _a;
        return ((_a = grant.grant_tags) === null || _a === void 0 ? void 0 : _a.includes(tag.tag_id)) &&
            tag.tag_type === 'expense_category' &&
            index === self.findIndex((t) => t.tag_id === tag.tag_id);
    })));
    return filteredTags.map((tag) => tag.content).slice(0, 5);
};
export default getFeaturedTags;
