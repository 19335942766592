import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef, useState, } from 'react';
import { useTheme } from '../../../plugins/styled';
import Loading from '../../loading';
import Div from '../div';
import Typography from '../typography';
import { CarouselButton, CarouselContainer, CarouselContent } from './styles';
const Carousel = ({ children, title, actions, emptyState, isLoading, tileWidth = 300, buttonStyle, buttonSize = 'medium', buttonPosition = 'bottom-left', hideOnMobile, styles, }) => {
    const theme = useTheme();
    const carouselRef = useRef(null);
    const containerRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [activeSlideToShow, setActiveSlideToShow] = useState(0);
    const tileWidthAndPadding = tileWidth + 32;
    const size = children.length;
    const handleNext = () => {
        var _a;
        const newSlide = Math.min(size - activeSlideToShow, currentSlide + 1);
        setCurrentSlide(newSlide);
        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.goTo(newSlide);
    };
    const handlePrev = () => {
        var _a;
        const newSlide = Math.max(0, currentSlide - 1);
        setCurrentSlide(newSlide);
        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.goTo(newSlide);
    };
    const handleResize = () => {
        var _a, _b, _c, _d;
        if (((_b = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth) !== null && _b !== void 0 ? _b : 0) > tileWidthAndPadding) {
            const slidesPerView = ((_d = (_c = containerRef.current) === null || _c === void 0 ? void 0 : _c.clientWidth) !== null && _d !== void 0 ? _d : 0) / tileWidthAndPadding;
            setActiveSlideToShow(slidesPerView);
        }
        else {
            setActiveSlideToShow(1);
        }
    };
    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [tileWidth]);
    useEffect(() => {
        handleResize();
    }, [children]);
    const prevDisabled = useMemo(() => currentSlide === 0, [currentSlide]);
    const nextDisabled = useMemo(() => currentSlide >= size - activeSlideToShow, [currentSlide, size, activeSlideToShow]);
    const Buttons = () => {
        if (!size || isLoading || currentSlide === undefined)
            return _jsx(_Fragment, {});
        if (currentSlide === 0 && size < activeSlideToShow)
            return _jsx(_Fragment, {});
        return (_jsxs(Div, { flex: "row", gap: 15, tablet: { style: { display: hideOnMobile ? 'none' : 'flex' } }, fitContent: true, style: styles === null || styles === void 0 ? void 0 : styles.carouselButtons, children: [_jsx(CarouselButton, { size: "auto", "$buttonSize": buttonSize, withArrowLeft: true, onClick: handlePrev, defaultStyle: buttonStyle || theme.colors.transparent, disabled: prevDisabled, style: styles === null || styles === void 0 ? void 0 : styles.carouselSingleButton }), _jsx(CarouselButton, { size: "auto", "$buttonSize": buttonSize, withArrowRight: true, onClick: handleNext, defaultStyle: buttonStyle || theme.colors.transparent, disabled: nextDisabled, style: styles === null || styles === void 0 ? void 0 : styles.carouselSingleButton })] }));
    };
    return (_jsxs(CarouselContainer, { flex: "column", gap: 26, style: styles === null || styles === void 0 ? void 0 : styles.container, children: [(!!title || ['top-right'].includes(buttonPosition)) && (_jsxs(Div, { flex: "row", justify: "space-between", align: "center", style: styles === null || styles === void 0 ? void 0 : styles.header, children: [!!title && (_jsx(Typography.Title, { level: 2, elementTheme: "h6", children: title })), ['top-right'].includes(buttonPosition) && _jsx(Buttons, {})] })), _jsxs("div", { style: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: 34,
                    height: '100%',
                }, ref: containerRef, children: [_jsx(Div, { style: Object.assign({ width: '100%', height: '100%' }, styles === null || styles === void 0 ? void 0 : styles.content), children: isLoading ? (_jsx(Loading, {})) : children.length > 0 ? (_jsx(CarouselContent, { ref: carouselRef, dots: false, slide: "0", slidesToShow: activeSlideToShow, infinite: false, slidesToScroll: 1, children: React.Children.toArray(children.map((child, i) => (_jsx("div", { style: {
                                    height: '100%',
                                    width: activeSlideToShow === 1 ? '100%' : tileWidthAndPadding,
                                    padding: 10,
                                }, children: child })))) })) : (_jsx(Div, { style: { height: '100%' }, flex: "row", align: "center", justify: "center", children: emptyState })) }), (!!actions ||
                        ['bottom-left', 'bottom-right'].includes(buttonPosition)) && (_jsxs("div", { style: {
                            display: 'flex',
                            flexDirection: buttonPosition === 'bottom-right' ? 'row-reverse' : 'row',
                            justifyContent: 'space-between',
                        }, children: [_jsx("div", { children: ['bottom-left', 'bottom-right'].includes(buttonPosition) && (_jsx(Buttons, {})) }), !!actions && (_jsx(Div, { flex: 'column', gap: 8, fitContent: true, style: styles === null || styles === void 0 ? void 0 : styles.actions, children: actions }))] }))] })] }));
};
export default Carousel;
