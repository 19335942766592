'use client';
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import FormLabel from '../form-label';
import { StyledFormItem } from './styles';
const FormItem = (_a) => {
    var { hideContent } = _a, antdProps = __rest(_a, ["hideContent"]);
    const label = typeof antdProps.label === 'string' ? (_jsx(FormLabel, { label: antdProps.label, extra: antdProps.extra })) : (antdProps.label);
    return (_jsx(StyledFormItem, Object.assign({ validateTrigger: "onBlur", "$hideContent": hideContent }, antdProps, { label: label })));
};
export default FormItem;
