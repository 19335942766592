'use client';
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import useMediaQuery from '../../../features/helpers/use-media-query';
import { usePlatform } from '../../../features/providers/platform-provider';
const useButtonProps = (props) => {
    const theme = useTheme();
    const isXL = useMediaQuery({ maxWidth: theme.breakpoints.l });
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    const { app } = usePlatform();
    const defaultButtonSize = app !== 'website' ? 'small' : 'medium';
    return useMemo(() => {
        const currentProps = {};
        const currentStyle = {};
        const addProp = (property, value) => {
            if (value !== undefined) {
                currentProps[property] = value;
            }
        };
        const addStyling = (property, value) => {
            if (value !== undefined) {
                currentStyle[property] = value;
            }
        };
        const prepareStyling = (style) => {
            if (style) {
                Object.entries(style).forEach(([key, value]) => {
                    addStyling(key, value);
                });
            }
        };
        const prepareProps = (data) => {
            if (data) {
                const { fitContent, fullWidth, isLink, transparent, textWrap, defaultStyle, hidden, style, } = data;
                if (style) {
                    prepareStyling(style);
                }
                const propMappings = {
                    $fitContent: fitContent,
                    $isTransparent: transparent,
                    $isLink: isLink,
                    $fullWidth: fullWidth,
                    $wrap: textWrap,
                    $hidden: hidden,
                    $defaultTheme: defaultStyle,
                };
                Object.entries(propMappings).forEach(([key, value]) => {
                    addProp(key, value);
                });
            }
        };
        const handleClick = (e) => {
            var _a, _b;
            e.stopPropagation();
            e.preventDefault();
            const defaultClick = () => props.onClick && props.onClick(e);
            if (isXL) {
                ((_a = props.xl) === null || _a === void 0 ? void 0 : _a.onClick) ? props.xl.onClick(e) : defaultClick();
            }
            else if (isTablet) {
                ((_b = props.tablet) === null || _b === void 0 ? void 0 : _b.onClick) ? props.tablet.onClick(e) : defaultClick();
            }
            else {
                defaultClick();
            }
        };
        const { children, id, className, headingIcon, trailingIcon, withArrowLeft, withArrowRight, size, loading, disabled, xl, tablet, onMouseEnter, onMouseLeave, onPointerEnter, onPointerLeave } = props, buttonProps = __rest(props, ["children", "id", "className", "headingIcon", "trailingIcon", "withArrowLeft", "withArrowRight", "size", "loading", "disabled", "xl", "tablet", "onMouseEnter", "onMouseLeave", "onPointerEnter", "onPointerLeave"]);
        const defaultProps = {
            children,
            id,
            className,
            headingIcon,
            trailingIcon,
            withArrowLeft,
            withArrowRight,
            disabled,
            size: size !== null && size !== void 0 ? size : defaultButtonSize,
            loading,
            handleClick,
            onMouseEnter,
            onMouseLeave,
            onPointerEnter,
            onPointerLeave,
        };
        prepareProps(buttonProps);
        if (isXL && xl)
            prepareProps(xl);
        if (isTablet && tablet)
            prepareProps(tablet);
        return { currentProps, currentStyle, defaultProps };
    }, [isTablet, isXL, props, app]);
};
export default useButtonProps;
