var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getStringDate } from '../../../../features/helpers';
import useLocale from '../../../../features/providers/locale-provider';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import SingleHeaderMenu from '../../../common/single-header-menu';
import Typography from '../../../common/typography';
import ProfileLogo from '../../../provider/profile/profile-assets/profile-logo';
import useGrantsRegion from '../hooks/use-grants-region';
import GrantNotification from './grant-notification';
import { GrantHeaderContainer, GrantHeaderContent } from './styles';
const GrantHeader = (_a) => {
    var { sections, blocks, hideNotifications } = _a, grant = __rest(_a, ["sections", "blocks", "hideNotifications"]);
    const theme = useTheme();
    const { selectedLocale } = useLocale();
    const { t } = useTranslations();
    const { grant_display_title, grant_logo, province, country, grant_description_short, city, } = grant;
    const region = useGrantsRegion(grant);
    return (_jsxs(GrantHeaderContainer, { children: [_jsxs(GrantHeaderContent, { children: [_jsx(ProfileLogo, { name: [grant_display_title, city, province, country]
                            .filter((itm) => !!itm)
                            .join(' - '), logo: grant_logo !== null && grant_logo !== void 0 ? grant_logo : '', type: 'grant-provider', size: 172 }), _jsxs(Div, { flex: "column", gap: 8, children: [_jsxs(Div, { flex: "row", justify: "space-between", gap: 8, tablet: { flex: 'column' }, style: { width: '100%' }, children: [_jsx(Typography.Title, { level: 1, elementTheme: "h4", noMargin: true, tablet: { textAlign: 'center' }, children: grant_display_title }), _jsxs(Div, { flex: "column", gap: 8, fitContent: true, children: [!!grant.grant_updated_at && (_jsxs(Typography, { elementTheme: "body3", textAlign: "right", tablet: { style: { display: 'none' } }, children: [t('grant_single|update'), ' ', getStringDate(new Date(grant.grant_updated_at), selectedLocale)] })), _jsx(Typography, { textAlign: "right", elementTheme: "body3", color: theme.colors.grey_2, style: { width: '250px' }, tablet: { textAlign: 'center', style: { width: '100%' } }, children: region })] })] }), _jsx(Typography, { color: theme.colors.grey_2, tablet: { textAlign: 'center' }, children: grant_description_short })] }), !hideNotifications && _jsx(GrantNotification, { grant: grant, animate: true })] }), _jsx(SingleHeaderMenu, { items: sections
                    .filter((key) => !blocks[key].hide)
                    .map((key) => ({
                    label: blocks[key].label,
                    id: blocks[key].id,
                })) })] }));
};
export default GrantHeader;
