import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import Save from '@hellodarwin/icons/dist/icons/Save';
import Drawer from 'antd/es/drawer';
import Form from 'antd/es/form';
import useForm from 'antd/es/form/hooks/useForm';
import Input from 'antd/es/input/Input';
import Radio from 'antd/es/radio';
import Select from 'antd/es/select';
import { useEffect, useState } from 'react';
import { FormsConditionTypeList, InitialFormsConditionsResponse, InitialFormsConditionTriggerValueResponse, InitialFormsSelectOptions, } from '../../../../../features/entities/form-entities';
import { getShortId } from '../../../../../features/helpers';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Clipboard from '../../../../common/clipboard';
import Div from '../../../../common/div';
import RichTextEditor from '../../../../common/rich-editor';
import Typography from '../../../../common/typography';
import FormItem from '../../../form-layouts/form-item';
import FormLabel from '../../../form-layouts/form-label';
import { FormItemOptions } from '../../utils/form-items-type';
import getItemTypeFromItemFieldType from '../../utils/get-item-type-from-item-field-type';
import FormItemEditList from './form-edit-list';
const FormBuilderItemEditButton = ({ itemEntity, pages, functions, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const theme = useTheme();
    const [editModalOpen, setEditModalOpen] = useState(false);
    const handleEditModal = () => setEditModalOpen(!editModalOpen);
    const [fieldType, setFieldType] = useState((_a = itemEntity.field_type) !== null && _a !== void 0 ? _a : '');
    const [form] = useForm();
    const [activeOption, setActiveOption] = useState();
    const [activeCondition, setActiveCondition] = useState();
    const [activeTrigger, setActiveTrigger] = useState();
    const handleSubmit = () => {
        var _a, _b;
        const values = form.getFieldsValue();
        const updatedItem = Object.assign(Object.assign(Object.assign({}, itemEntity), values), { type: getItemTypeFromItemFieldType((_b = (_a = values.field_type) !== null && _a !== void 0 ? _a : itemEntity.field_type) !== null && _b !== void 0 ? _b : '') });
        functions.updateFormItem(updatedItem);
    };
    const reorderOptions = (newOptions) => {
        for (let index = 0; index < newOptions.length; index++) {
            newOptions[index] = Object.assign(Object.assign({}, newOptions[index]), { display_order: index });
        }
        functions.updateFormSelectOptions(newOptions);
    };
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f;
        setFieldType((_a = itemEntity.field_type) !== null && _a !== void 0 ? _a : '');
        if (!!activeOption) {
            setActiveOption((_b = itemEntity.form_select_options) === null || _b === void 0 ? void 0 : _b.find((option) => option.form_select_option_id === activeOption.form_select_option_id));
        }
        if (!!activeCondition) {
            setActiveCondition((_c = itemEntity.form_item_conditions) === null || _c === void 0 ? void 0 : _c.find((option) => option.form_condition_id === activeCondition.form_condition_id));
        }
        if (!!activeTrigger) {
            setActiveTrigger((_f = (_e = (_d = itemEntity.form_item_conditions) === null || _d === void 0 ? void 0 : _d.find((option) => option.form_condition_id === activeTrigger.form_condition_id)) === null || _e === void 0 ? void 0 : _e.trigger_values) === null || _f === void 0 ? void 0 : _f.find((trigger) => trigger.forms_conditions_trigger_values_id ===
                activeTrigger.forms_conditions_trigger_values_id));
        }
    }, [itemEntity]);
    const addNewOption = () => {
        var _a, _b;
        setActiveOption(Object.assign(Object.assign({}, InitialFormsSelectOptions), { form_item_id: itemEntity.form_item_id, display_order: (_b = (_a = itemEntity.form_select_options) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0 }));
    };
    const addNewCondition = () => {
        setActiveCondition(Object.assign(Object.assign({}, InitialFormsConditionsResponse), { form_item_id: itemEntity.form_item_id, trigger_values: [] }));
    };
    const handleSubmitFormSelectOption = (option) => {
        if (!!option.form_select_option_id) {
            functions.updateSingleFormSelectOption(option);
        }
        else {
            functions.createSingleFormSelectOption(Object.assign(Object.assign({}, option), { form_item_id: itemEntity.form_item_id }));
        }
    };
    const deleteFormSelectCondition = (condition) => {
        functions.updateSingleFormSelectCondition(Object.assign(Object.assign({}, condition), { deleted: true }));
    };
    const handleSubmitFormSelectCondition = async (condition) => {
        if (!!condition.form_condition_id) {
            await functions.updateSingleFormSelectCondition(condition);
        }
        else {
            await functions.createSingleFormSelectCondition(condition);
        }
    };
    const updateConditionTrigger = (trigger, condition, isDelete) => {
        if (isDelete) {
            condition = Object.assign(Object.assign({}, condition), { trigger_values: condition.trigger_values.map((t) => t === trigger ? Object.assign(Object.assign({}, t), { deleted: true }) : t) });
        }
        else if (trigger.forms_conditions_trigger_values_id) {
            condition = Object.assign(Object.assign({}, condition), { trigger_values: condition.trigger_values.map((t) => t.forms_conditions_trigger_values_id ===
                    trigger.forms_conditions_trigger_values_id
                    ? trigger
                    : t) });
        }
        else {
            condition = Object.assign(Object.assign({}, condition), { trigger_values: [...condition.trigger_values, trigger] });
        }
        setActiveCondition(condition);
        setActiveTrigger(undefined);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { size: "square", trailingIcon: _jsx(Edit, { width: 12, height: 12 }), onClick: handleEditModal, defaultStyle: theme.colors.purple_3 }), _jsx(Drawer, { open: editModalOpen, onClose: handleEditModal, title: _jsxs(Div, { align: "center", flex: "row", style: { height: '100%' }, justify: "space-between", children: [_jsxs(Div, { fitContent: true, flex: "row", gap: 8, align: "center", children: [_jsx(Typography, { elementTheme: "subtitle1", lineClamp: 3, children: "Item :" }), _jsx(Typography, { elementTheme: "subtitle1", color: theme.colors.purple_1, colorHover: theme.colors.purple_2, children: _jsx(Clipboard, { title: getShortId(itemEntity.form_item_id), copy: itemEntity.form_item_id }) })] }), _jsx(Button, { size: "circle", defaultStyle: theme.colors.white_1, onClick: handleSubmit, children: _jsx(Save, { size: 16 }) })] }), children: _jsx(Form, { layout: "vertical", initialValues: itemEntity, form: form, children: _jsxs(Div, { flex: "column", gap: 24, children: [_jsx(FormItem, { name: 'forms_groups_items_order', label: _jsx(FormLabel, { label: "Item Order" }), children: _jsx(Input, { disabled: true }) }), _jsx(FormItem, { name: 'field_type', label: _jsx(FormLabel, { label: "Field Type" }), children: _jsx(Select, { options: FormItemOptions, onChange: setFieldType }) }), ['radio', 'checkbox'].includes(fieldType) && (_jsx(FormItem, { name: 'disposition', label: _jsx(FormLabel, { label: "Direction" }), children: _jsx(Select, { defaultValue: 'horizontal', options: [
                                        { value: 'vertical', label: 'Vertical' },
                                        { value: 'horizontal', label: 'Horizontal' },
                                    ] }) })), _jsx(FormItem, { name: 'required', label: _jsx(FormLabel, { label: "Required" }), children: _jsx(Radio.Group, { options: [
                                        { label: 'Yes', value: 'required' },
                                        { label: 'No', value: '' },
                                    ] }) }), _jsx(FormItem, { name: 'content_en', label: _jsx(FormLabel, { label: "Content En" }), children: _jsx(Input, {}) }), _jsx(FormItem, { name: 'content_fr', label: _jsx(FormLabel, { label: "Content Fr" }), children: _jsx(Input, {}) }), _jsx(FormItem, { name: 'heading_en', label: _jsx(FormLabel, { label: "Heading En" }), children: _jsx(RichTextEditor, { initialValue: (_b = itemEntity.heading_en) !== null && _b !== void 0 ? _b : '' }) }), _jsx(FormItem, { name: 'heading_fr', label: _jsx(FormLabel, { label: "Heading Fr" }), children: _jsx(RichTextEditor, { initialValue: (_c = itemEntity.heading_fr) !== null && _c !== void 0 ? _c : '' }) }), ['radio', 'select', 'checkbox'].includes((_d = itemEntity.field_type) !== null && _d !== void 0 ? _d : '') && (_jsx(FormItem, { name: 'form_select_options', label: _jsx(FormLabel, { label: "Options", actions: _jsx(Button, { transparent: true, defaultStyle: theme.colors.primary, onClick: () => addNewOption(), children: _jsx(AddNew, { size: 16 }) }) }), children: _jsx(FormItemEditList, { options: (_e = itemEntity.form_select_options) !== null && _e !== void 0 ? _e : [], optionLabel: 'label_en', optionTitleLabel: 'form_select_option_id', reorder: reorderOptions, update: handleSubmitFormSelectOption, activeElement: activeOption, setActiveElement: setActiveOption, renderTitleLabel: (key) => (_jsx(Typography, { elementTheme: "subtitle1", color: theme.colors.purple_1, colorHover: theme.colors.purple_2, children: _jsx(Clipboard, { title: getShortId(key), copy: key }) })), typeLabel: "Option", inputs: [
                                        {
                                            label: 'Display order',
                                            name: 'display_order',
                                            children: _jsx(Input, { disabled: true }),
                                        },
                                        {
                                            label: 'Label En',
                                            name: 'label_en',
                                            children: _jsx(Input, {}),
                                        },
                                        {
                                            label: 'Label Fr',
                                            name: 'label_fr',
                                            children: _jsx(Input, {}),
                                        },
                                        {
                                            label: 'Value',
                                            name: 'value',
                                            children: _jsx(Input, {}),
                                        },
                                        {
                                            label: 'Hidden',
                                            name: 'hidden',
                                            children: (_jsx(Radio.Group, { options: [
                                                    { label: 'Yes', value: true },
                                                    { label: 'No', value: false },
                                                ] })),
                                        },
                                    ] }) })), _jsx(FormItem, { name: 'form_item_conditions', label: _jsx(FormLabel, { label: "Conditions", actions: _jsx(Button, { transparent: true, defaultStyle: theme.colors.primary, onClick: addNewCondition, children: _jsx(AddNew, { size: 16 }) }) }), children: _jsx(FormItemEditList, { options: (_f = itemEntity.form_item_conditions) !== null && _f !== void 0 ? _f : [], optionLabel: 'action_page', optionTitleLabel: 'form_condition_id', deleteItem: deleteFormSelectCondition, activeElement: !!activeCondition
                                        ? Object.assign(Object.assign({}, activeCondition), { trigger_values: (_g = activeCondition.trigger_values) !== null && _g !== void 0 ? _g : [] }) : undefined, setActiveElement: setActiveCondition, renderLabel: (key) => ('Go to page ' + key), update: handleSubmitFormSelectCondition, renderTitleLabel: (key) => (_jsx(Typography, { elementTheme: "subtitle1", color: theme.colors.purple_1, colorHover: theme.colors.purple_2, children: _jsx(Clipboard, { title: getShortId(key), copy: key }) })), typeLabel: "Condition", inputs: [
                                        {
                                            label: 'Condition Type',
                                            name: 'condition_type',
                                            children: (_jsx(Select, { options: !!FormsConditionTypeList &&
                                                    ((_h = Object.values(FormsConditionTypeList)) === null || _h === void 0 ? void 0 : _h.map((value) => ({
                                                        label: value,
                                                        value,
                                                    }))) })),
                                        },
                                        {
                                            label: 'Triggers',
                                            name: 'trigger_values',
                                            children: {
                                                addItm() {
                                                    setActiveTrigger(InitialFormsConditionTriggerValueResponse);
                                                },
                                                render: (itm) => {
                                                    var _a;
                                                    return (_jsx(FormItemEditList, { options: (_a = itm.trigger_values) !== null && _a !== void 0 ? _a : [], optionLabel: "trigger_value", typeLabel: "string", activeElement: activeTrigger, setActiveElement: setActiveTrigger, deleteItem: (trigger) => updateConditionTrigger(trigger, itm, true), update: (trigger) => updateConditionTrigger(trigger, itm), inputs: [
                                                            {
                                                                label: 'Value',
                                                                name: 'trigger_value',
                                                                children: !!itemEntity.form_select_options ? (_jsx(Select, { options: itemEntity.form_select_options.map((option) => ({
                                                                        label: option.label_en,
                                                                        value: option.value,
                                                                    })) })) : (_jsx(Input, {})),
                                                            },
                                                        ] }));
                                                },
                                            },
                                        },
                                        {
                                            label: 'Action Page',
                                            name: 'form_page_id',
                                            children: (_jsx(Select, { defaultValue: pages[0].entity.form_page_id, options: pages.map((page) => ({
                                                    label: page.entity.page_title_en,
                                                    value: page.entity.form_page_id,
                                                })) })),
                                        },
                                    ] }) })] }) }) })] }));
};
export default FormBuilderItemEditButton;
