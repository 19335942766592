'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import Checkbox from 'antd/es/checkbox';
import Select from 'antd/es/select';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import HdTag from '../../../../common/hd-tag';
import Typography from '../../../../common/typography';
import FormItem from '../../../../forms/form-layouts/form-item';
import GrantStatusTag from '../../../card/grant-status-tag';
import { FilterSelectDropdown } from '../styles';
import getFilterTagBarTheme from '../utils/get-filter-tag-bar-theme';
const FilterMultipleSelect = ({ label, name, placeholder, options, filterform, onChange, isMultiple, isStatusTag, notFoundContent, }) => {
    const theme = useTheme();
    const filterOption = (input, option) => {
        if (!(option === null || option === void 0 ? void 0 : option.label))
            return false;
        const comparingKey = `${option.label}`;
        return comparingKey.toLowerCase().indexOf(input.toLowerCase()) !== -1;
    };
    const checkIfSelected = (value, fieldValue) => {
        const fieldValueArray = Array.isArray(fieldValue) ? fieldValue : [];
        return fieldValueArray.includes(value);
    };
    const dropdownStyle = {
        padding: 0,
        borderRadius: 0,
        border: `1px solid ${theme.colors.primary}`,
    };
    const dropdownRender = (el) => (_jsx(FilterSelectDropdown, { children: el }));
    const optionRender = (option) => {
        return (_jsxs(Div, { flex: "row", gap: 8, align: "center", borderColor: theme.colors.grey_4, bordersSide: "bottom", justify: "space-between", style: { padding: '12px 16px' }, children: [_jsx(Typography, { elementTheme: "body3", color: theme.colors.primary, ellipsis: true, overflow: true, nowrap: true, children: option.label }), isMultiple && (_jsx(Checkbox, { type: "square", checked: checkIfSelected(option.value.toString(), filterform.getFieldValue(name)) }))] }));
    };
    const optionRenderStatusTag = (option) => {
        return (_jsxs(Div, { flex: "row", gap: 8, align: "center", borderColor: theme.colors.grey_4, bordersSide: "bottom", justify: "space-between", style: { padding: '12px 16px' }, children: [_jsx(GrantStatusTag, { status: option.value }), isMultiple && (_jsx(Checkbox, { type: "square", checked: checkIfSelected(option.value.toString(), filterform.getFieldValue(name)) }))] }));
    };
    const baseProps = {
        style: { width: '100%' },
        onChange,
        options,
        filterOption,
        suffixIcon: _jsx(TabArrow, { size: 16, down: true }),
        placeholder,
        optionRender: isStatusTag ? optionRenderStatusTag : optionRender,
        notFoundContent,
        dropdownRender,
        dropdownStyle,
        size: 'middle',
    };
    const multipleProps = isMultiple
        ? {
            mode: 'multiple',
            tagRender: ({ label, onClose, value }) => (_jsx(HdTag, { onClose: onClose, text: label, size: "small", style: { marginRight: 2, overflow: 'hidden' }, color: getFilterTagBarTheme(name, theme, value) })),
            maxTagCount: 3,
            menuItemSelectedIcon: _jsx(_Fragment, {}),
        }
        : {};
    return (_jsx(FormItem, { label: label, name: name, style: { margin: 0 }, layout: "vertical", children: _jsx(Select, Object.assign({}, baseProps, multipleProps)) }));
};
export default FilterMultipleSelect;
