import Div from '@hellodarwin/core/lib/components/common/div';
import RichTextEditor from '@hellodarwin/core/lib/components/common/rich-editor';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { GinApplication } from '@hellodarwin/core/lib/features/entities';
import parse, {
  attributesToProps,
  domToReact,
  HTMLReactParserOptions,
} from '@hellodarwin/core/lib/features/helpers/parse';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Empty from 'antd/es/empty';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/app-hooks';
import {
  clearGrantContentSection,
  clearSection,
  fetchGrantContentSection,
  fetchSection,
  saveGrantContentSection,
  saveSection,
  selectContent,
  selectGrantContentEn,
  selectGrantContentFr,
} from '../../../features/api/slices/gin-sections-slice';
import {
  selectGinApplication,
  updateGinApplication,
} from '../../../features/api/slices/gins-slice';
import {
  useAdminApi,
  useNewAdminApi,
} from '../../../features/api/use-admin-api';
import { GinBlockComponentProps } from './gin-block';

export const ginEditorParseOptions: HTMLReactParserOptions = {
  replace: (domNode: any) => {
    if (domNode.attribs && domNode.name === 'a') {
      const props = attributesToProps(domNode.attribs);

      return (
        <a {...props} rel="nofollow" target="_blank">
          {domToReact(domNode.children)}
        </a>
      );
    }

    if (domNode.attribs && domNode.name === 'p') {
      return (
        <Typography elementTheme="body2">
          {domToReact(domNode.children)}
        </Typography>
      );
    }
    if (domNode.attribs && domNode.name === 'li') {
      return (
        <li>
          <Typography elementTheme="body2">
            {domToReact(domNode.children)}
          </Typography>
        </li>
      );
    }
  },
};

const GinTextEditor = ({
  entityId,
  section,
  isEditing,
  prepareHandleSave,
  type,
  contentType,
  editingLocale,
}: GinBlockComponentProps) => {
  const content = useAppSelector((state) => selectContent(state, section));
  const grantContentEn = useAppSelector((state) =>
    selectGrantContentEn(state, section),
  );
  const grantContentFr = useAppSelector((state) =>
    selectGrantContentFr(state, section),
  );
  const application = useAppSelector(selectGinApplication);
  const api = useAdminApi();
  const newApi = useNewAdminApi();
  const dispatch = useAppDispatch();
  const [editorContent, setEditorContent] = useState('');
  const { t } = useTranslations();
  const theme = useTheme();

  useEffect(() => {
    dispatch(clearSection(section));
    dispatch(clearGrantContentSection(section));
    if (type === 'program') {
      if (contentType === 'grant-content') {
        dispatch(
          fetchGrantContentSection({
            api,
            grantId: entityId,
            section,
            locale: 'en',
          }),
        );
        dispatch(
          fetchGrantContentSection({
            api,
            grantId: entityId,
            section,
            locale: 'fr',
          }),
        );
      } else {
        dispatch(fetchSection({ api, grantId: entityId, section }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, section, type]);

  const textContent = useMemo(() => {
    if (type === 'program') {
      if (
        contentType === 'grant-content' &&
        ((!!grantContentEn && editingLocale === 'en') ||
          (!!grantContentFr && editingLocale === 'fr'))
      ) {
        return editingLocale === 'en' ? grantContentEn : grantContentFr;
      } else if (contentType === 'gin' && !!content) {
        return content;
      } else {
        return '';
      }
    } else if (type === 'application') {
      const applicationContent = `${
        application[section as keyof GinApplication] ?? ''
      }`;
      return applicationContent;
    } else {
      return '';
    }
  }, [
    content,
    grantContentEn,
    grantContentFr,
    contentType,
    section,
    application,
    type,
    editingLocale,
  ]);

  useEffect(() => {
    setEditorContent(textContent);
  }, [textContent]);

  const trimHtml = (html: string): string => {
    const htmlTrimmed = html.replace(/>\s+</g, '><').replace(/\s+$/, '');
    setEditorContent(htmlTrimmed);
    return htmlTrimmed;
  };

  useEffect(() => {
    prepareHandleSave(() => {
      return () => {
        if (type === 'program') {
          if (contentType === 'grant-content') {
            dispatch(
              saveGrantContentSection({
                api,
                grantId: entityId,
                section,
                locale: editingLocale!,
                content: trimHtml(editorContent),
              }),
            );
          } else {
            dispatch(
              saveSection({
                api,
                grantId: entityId,
                section,
                content: trimHtml(editorContent),
              }),
            );
          }
        } else {
          dispatch(
            updateGinApplication({
              api: newApi,
              updatedApplication: {
                ...application,
                [section]: trimHtml(editorContent),
              },
            }),
          );
        }
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, section, editorContent, type]);

  const handleEditorChange = (newValue: string) => {
    setEditorContent(newValue);
  };

  const isValidTextContent = (content: string) => {
    const invalidValues = ['', '<p><br></p>', '<p></p>'];
    return content.trim().length > 0 && !invalidValues.includes(content.trim());
  };

  return isEditing ? (
    <RichTextEditor value={textContent || ''} onChange={handleEditorChange} />
  ) : (
    <Typography elementTheme="body2">
      <Div flex="column" gap={8}>
        {isValidTextContent(textContent) ? (
          parse(textContent || '', ginEditorParseOptions)
        ) : (
          <Empty
            description={
              <Typography elementTheme="subtitle2" color={theme.colors.grey_2}>
                {t(`grant_single|emptyField`, {
                  fieldName: t(`gin_section|${section}`),
                })}
              </Typography>
            }
          />
        )}
      </Div>
    </Typography>
  );
};

export default GinTextEditor;
