import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Form from 'antd/es/form';
import Input from 'antd/es/input/Input';
import Select from 'antd/es/select';
import { useEffect, useMemo, useState } from 'react';
import SingleLanguage from '../../../features/enums/single-language';
import handleDispatchResponse from '../../../features/helpers/handle-dispatch-response';
import { useTranslation } from '../../../plugins/i18n';
import { useTheme } from '../../../plugins/styled';
import Button from '../../common/button';
import Div from '../../common/div';
import Modal from '../../common/hd-modal';
import { ModalConfirm } from '../../common/hd-modal/confirm';
import Typography from '../../common/typography';
import FormItem from '../../forms/form-layouts/form-item';
import FormLabel from '../../forms/form-layouts/form-label';
import { UserFormContainer, UserFormRow } from './styles';
export const EMAIL_ALREADY_EXISTS_ERROR = 5023;
const AddForm = ({ form, initialValues, handleNext, handleClose, isLoading, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (_jsx(Form, { layout: "vertical", form: form, initialValues: initialValues, onFinish: handleNext, children: _jsxs(Div, { flex: "column", gap: 32, children: [_jsx(Typography.Title, { level: 2, elementTheme: "h4", children: t('profile|profileForm.users.form.addTitle') }), _jsx(FormItem, { style: { flex: 3 }, label: _jsx(FormLabel, { label: t('profile|profileForm.users.form.labels.email'), extra: t('profile|profileForm.users.form.extra.email') }), name: "email", rules: [
                        {
                            required: true,
                            message: t('profile|profileForm.users.form.validation.email'),
                        },
                        {
                            type: 'email',
                            message: t('profile|profileForm.users.form.validation.invalidEmail'),
                        },
                    ], children: _jsx(Input, { type: "email", placeholder: t('profile|profileForm.users.form.placeholder.email'), size: "large" }) }), _jsxs(Div, { flex: "row", gap: 16, align: "center", justify: "flex-end", children: [_jsx(Button, { defaultStyle: theme.colors.white_1, onClick: handleClose, size: "small", disabled: isLoading, children: t('button.cancel') }), _jsx(Button, { onClick: handleNext, size: "small", loading: isLoading, children: t('button.next') })] })] }) }));
};
const EditionForm = ({ form, initialValues, handleSubmit, handleClose, showPhone, isLoading, isNew, additionalContent, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const languageOptions = [
        {
            value: SingleLanguage.French,
            label: t(`language.${SingleLanguage.French}`),
        },
        {
            value: SingleLanguage.English,
            label: t(`language.${SingleLanguage.English}`),
        },
    ];
    return (_jsx(Form, { layout: "vertical", form: form, initialValues: initialValues, onFinish: handleSubmit, children: _jsxs(Div, { flex: "column", gap: 37, children: [_jsxs(Div, { flex: "column", gap: 32, children: [_jsxs(Div, { flex: "row", align: "center", justify: "space-between", children: [_jsx(Typography.Title, { level: 2, elementTheme: "h4", style: { whiteSpace: 'nowrap' }, children: isNew
                                        ? t('profile|profileForm.users.form.addTitle')
                                        : t('profile|profileForm.users.form.editTitle') }), additionalContent && _jsx(Div, { fitContent: true, children: additionalContent })] }), _jsxs(UserFormContainer, { children: [_jsx(FormItem, { label: _jsx(FormLabel, { label: t('profile|profileForm.users.form.labels.name') }), name: "name", rules: [
                                        {
                                            required: true,
                                            message: t('profile|profileForm.users.form.validation.name'),
                                        },
                                    ], children: _jsx(Input, { placeholder: t('profile|profileForm.users.form.placeholder.name'), size: "large" }) }), _jsxs(UserFormRow, { style: { display: !isNew || showPhone ? 'block' : 'none' }, children: [_jsx(FormItem, { style: { flex: 3, display: !isNew ? 'block' : 'none' }, label: _jsx(FormLabel, { label: t('profile|profileForm.users.form.labels.email'), extra: t('profile|profileForm.users.form.extra.email') }), name: "email", rules: [
                                                {
                                                    required: true,
                                                    message: t('profile|profileForm.users.form.validation.email'),
                                                },
                                                {
                                                    type: 'email',
                                                    message: t('profile|profileForm.users.form.validation.invalidEmail'),
                                                },
                                            ], children: _jsx(Input, { type: "email", placeholder: t('profile|profileForm.users.form.placeholder.email'), size: "large", disabled: true }) }), showPhone && (_jsxs(Div, { flex: "row", gap: 8, style: { flex: 2 }, align: "flex-end", children: [_jsx(Form.Item, { label: t('profile|profileForm.users.form.labels.phone'), name: "phone", style: { flex: 3 }, children: _jsx(Input, { type: "phone", placeholder: t('profile|profileForm.users.form.placeholder.phone'), size: "large" }) }), _jsx(Form.Item, { name: "ext", style: { flex: 1 }, children: _jsx(Input, { name: "ext", placeholder: t('profile|profileForm.users.form.placeholder.ext'), size: "large" }) })] }))] }), _jsxs(UserFormRow, { children: [_jsx(FormItem, { label: _jsx(FormLabel, { label: t('profile|profileForm.users.form.labels.title') }), name: "title", style: { flex: 3 }, children: _jsx(Input, { placeholder: t('profile|profileForm.users.form.placeholder.title'), size: "large" }) }), _jsx(FormItem, { label: _jsx(FormLabel, { label: t('profile|profileForm.users.form.labels.preferredLanguage'), extra: t('profile|profileForm.users.form.extra.preferredLanguage') }), name: "preferred_language", style: { flex: 2 }, children: _jsx(Select, { options: languageOptions, size: "large", placeholder: t('profile|profileForm.users.form.placeholder.preferredLanguage') }) })] })] })] }), _jsx(Div, { justify: "space-between", children: _jsxs(Div, { gap: 16, align: "center", flex: "row", children: [_jsx(Button, { defaultStyle: theme.colors.white_1, onClick: handleClose, size: "small", disabled: isLoading, children: t('button.cancel') }), _jsx(Button, { onClick: handleSubmit, size: "small", loading: isLoading, children: t('button.save') })] }) })] }) }));
};
const UserForm = ({ open, handleEditUser, handleClose, contact, companyId, showPhone, handleContactAdd, handleContactAssign, handleEmailExists, isLoading, additionalContent, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [isNew, setIsNew] = useState(!(contact === null || contact === void 0 ? void 0 : contact.contact_id));
    const [form] = Form.useForm();
    const initialValues = useMemo(() => {
        return {
            name: (contact === null || contact === void 0 ? void 0 : contact.name) || '',
            email: (contact === null || contact === void 0 ? void 0 : contact.email) || '',
            title: (contact === null || contact === void 0 ? void 0 : contact.title) || '',
            phone: (contact === null || contact === void 0 ? void 0 : contact.phone) || '',
            ext: (contact === null || contact === void 0 ? void 0 : contact.ext) || '',
            preferred_language: (contact === null || contact === void 0 ? void 0 : contact.preferred_language) || SingleLanguage.English,
            gender: (contact === null || contact === void 0 ? void 0 : contact.gender) || '',
        };
    }, [contact]);
    const handleReset = () => {
        setIsNew(false);
        form.resetFields();
        form.setFieldsValue(initialValues);
    };
    useEffect(() => {
        if (!open) {
            setIsNew(!(contact === null || contact === void 0 ? void 0 : contact.contact_id));
            form.resetFields();
        }
    }, [open]);
    useEffect(() => {
        handleReset();
    }, [form, initialValues]);
    useEffect(() => {
        setIsNew(!(contact === null || contact === void 0 ? void 0 : contact.contact_id));
    }, [contact]);
    const handleAssign = (contact) => {
        try {
            handleContactAssign(contact).then((res) => {
                handleDispatchResponse(res, t('notification.saved'), t('notification.saving_error'));
                handleClose();
            });
        }
        catch (error) { }
    };
    const handleNext = () => {
        handleEmailExists(form.getFieldValue('email'))
            .then((res) => {
            if (res && res.payload && res.payload.contact_id !== '') {
                ModalConfirm({
                    title: t('profile|profileForm.users.assign.title'),
                    content: t('profile|profileForm.users.assign.subtitle'),
                    okText: t('profile|assetsModal.confirm'),
                    cancelText: t('profile|assetsModal.cancel'),
                    okButtonProps: {
                        style: {
                            backgroundColor: theme.colors.purple_1,
                            color: theme.colors.white_1,
                            borderRadius: '24px',
                        },
                    },
                    cancelButtonProps: {
                        style: {
                            backgroundColor: theme.colors.white_1,
                            borderColor: theme.colors.grey_1,
                            color: theme.colors.grey_1,
                            borderRadius: '24px',
                        },
                    },
                    onOk: async () => {
                        handleAssign(res.payload);
                        form.setFieldsValue(res.payload);
                    },
                    onCancel: async () => {
                        handleClose();
                    },
                });
            }
            else {
                setIsNew(false);
            }
        })
            .catch((error) => {
            if (error == 'Email already exists') {
                form.setFields([
                    {
                        name: 'email',
                        errors: [
                            t('profile|profileForm.users.form.validation.emailExists'),
                        ],
                    },
                ]);
            }
        });
    };
    const handleSubmit = () => {
        try {
            form.validateFields().then((values) => {
                if (!!(contact === null || contact === void 0 ? void 0 : contact.contact_id.length)) {
                    !!handleEditUser &&
                        handleEditUser(Object.assign(Object.assign({}, values), { company_id: companyId, contact_id: contact.contact_id })).then((res) => {
                            handleDispatchResponse(res, t('notification.saved'), t('notification.saving_error'));
                            handleClose();
                        });
                }
                else if (!!handleContactAdd) {
                    const newContact = Object.assign(Object.assign({}, values), { company_id: companyId });
                    handleContactAdd(newContact).then((res) => {
                        handleDispatchResponse(res, t('notification.saved'), t('notification.saving_error'));
                        handleClose();
                    });
                }
            });
        }
        catch (error) { }
    };
    return isNew ? (_jsx(AddForm, { initialValues: initialValues, form: form, isLoading: isLoading, handleClose: handleClose, handleNext: handleNext })) : (_jsx(EditionForm, { isNew: !(contact === null || contact === void 0 ? void 0 : contact.contact_id), initialValues: initialValues, form: form, isLoading: isLoading, showPhone: showPhone, handleClose: handleClose, handleSubmit: handleSubmit, additionalContent: additionalContent }));
};
const UserFormModal = (props) => {
    const { open, handleClose } = props;
    return (_jsx(Modal, { open: open, handleCancel: handleClose, size: "large", styles: { content: { padding: '56px 72px' } }, children: _jsx(UserForm, Object.assign({}, props)) }));
};
export default UserFormModal;
