import { jsx as _jsx } from "react/jsx-runtime";
import { Auth0Provider, } from '@auth0/auth0-react';
import { useMemo } from 'react';
import ErrorNetwork from '../../components/error/network';
import { useNavigate } from './location-provider';
import LoginProvider from './login-provider';
export const Auth0ProviderWithNavigate = ({ children, config, error, app, }) => {
    var _a;
    const navigate = useNavigate();
    const onRedirectCallback = (appState) => {
        navigate((appState === null || appState === void 0 ? void 0 : appState.returnTo) || window.location.pathname);
    };
    const { client_id, domain, scope, audience, isError } = useMemo(() => {
        return {
            client_id: (config === null || config === void 0 ? void 0 : config.client_id) || '',
            domain: (config === null || config === void 0 ? void 0 : config.domain) || '',
            scope: (config === null || config === void 0 ? void 0 : config.scope) || '',
            audience: (config === null || config === void 0 ? void 0 : config.audience) || '',
            isError: !(config === null || config === void 0 ? void 0 : config.client_id) && !(config === null || config === void 0 ? void 0 : config.domain),
        };
    }, [config]);
    const authorizationParams = useMemo(() => {
        var _a;
        const params = {
            redirect_uri: ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.origin) || '',
            scope: app === 'admin' ? 'profile' : scope,
            audience: audience,
            cacheLocation: 'localstorage',
            useRefreshTokens: true,
        };
        return params;
    }, [window.location, app, scope, audience]);
    if (isError) {
        return _jsx(ErrorNetwork, { errorMessage: (_a = error === null || error === void 0 ? void 0 : error.message) !== null && _a !== void 0 ? _a : '' });
    }
    return (_jsx(Auth0Provider, { domain: domain, clientId: client_id, authorizationParams: authorizationParams, onRedirectCallback: onRedirectCallback, children: _jsx(LoginProvider, { domain: domain, app: app, children: children }) }));
};
export default Auth0ProviderWithNavigate;
