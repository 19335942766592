const normalizePhoneNumber = (value) => {
    if (!value)
        return '';
    const digits = value.replace(/\D/g, '');
    const mainPart = digits.slice(0, 10);
    const extPart = digits.length > 10 ? digits.slice(10) : '';
    const formattedMain = mainPart.replace(/(\d{1,3})(\d{1,3})?(\d{1,4})?/, (_, p1, p2, p3) => `${p1 ? `(${p1}` : ''}${p2 ? `) ${p2}` : ''}${p3 ? `-${p3}` : ''}`);
    const fullNumber = `${formattedMain}${extPart ? ` ext. ${extPart}` : ''}`;
    return fullNumber.trim();
};
export default normalizePhoneNumber;
