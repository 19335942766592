import { jsx as _jsx } from "react/jsx-runtime";
import GamePlanBusinessPriorities from '@hellodarwin/pdf/lib/builders/game-plan/sections/business-priorities';
import GamePlanBusinessProfile from '@hellodarwin/pdf/lib/builders/game-plan/sections/business-profile';
import GamePlanOthers from '@hellodarwin/pdf/lib/builders/game-plan/sections/others';
import GamePlanProjectsAndGrants from '@hellodarwin/pdf/lib/builders/game-plan/sections/projects-and-grants';
import GamePlanSummary from '@hellodarwin/pdf/lib/builders/game-plan/sections/summary';
import { useMemo } from 'react';
import { dollarFormat } from '../../../../features/helpers';
import useLocale from '../../../../features/providers/locale-provider';
import { useTranslations } from '../../../../features/providers/translations-provider';
const useGamePlanSections = ({ projects, company, translations, grants, summary, }) => {
    const { t } = useTranslations();
    const { selectedLocale } = useLocale();
    return useMemo(() => {
        var _a;
        const address = !!company
            ? [
                company.address,
                company.city,
                company.province,
                company.country,
                company.postal_code,
            ].filter((itm) => !!itm)
            : [];
        const sections = [
            {
                label: t('gamePlanPdf|businessProfile'),
                children: (_jsx(GamePlanBusinessProfile, { projects: projects, label: t('gamePlanPdf|businessProfile'), company: company, columns: [
                        {
                            label: t('gamePlanPdf|naicsCode'),
                            content: ((_a = company === null || company === void 0 ? void 0 : company.naics_code) === null || _a === void 0 ? void 0 : _a.length)
                                ? company.naics_code.join(', ')
                                : ' - ',
                            id: 'naics',
                        },
                        {
                            label: t('gamePlanPdf|businessNumber'),
                            content: !!(company === null || company === void 0 ? void 0 : company.business_number)
                                ? company.business_number
                                : ' - ',
                            id: 'business_number',
                        },
                        {
                            label: t('gamePlanPdf|annualRevenue'),
                            content: !!(company === null || company === void 0 ? void 0 : company.exact_annual_revenue)
                                ? dollarFormat(company.exact_annual_revenue, selectedLocale, true)
                                : !!(company === null || company === void 0 ? void 0 : company.annual_revenue)
                                    ? company.annual_revenue
                                    : ' - ',
                            id: 'business_number',
                        },
                        {
                            label: t('gamePlanPdf|nbEmployees'),
                            content: !!(company === null || company === void 0 ? void 0 : company.size) ? company.size : ' - ',
                            id: 'business_number',
                        },
                        {
                            label: t('gamePlanPdf|companyAddress'),
                            content: !!(address === null || address === void 0 ? void 0 : address.length) ? address.join(', ') : ' - ',
                            id: 'business_number',
                        },
                    ] })),
                id: 'business-profile-section',
            },
            {
                label: t('gamePlanPdf|mainBusinessPriorities'),
                children: !!projects.length && (_jsx(GamePlanBusinessPriorities, { label: t('gamePlanPdf|mainBusinessPriorities'), projects: projects })),
                id: 'business-priorities-section',
            },
            {
                label: t('gamePlanPdf|projectsAndGrantsSection'),
                children: !!projects.length && (_jsx(GamePlanProjectsAndGrants, { projects: projects, translations: translations, locale: selectedLocale })),
                id: 'projects-and-grants-section',
            },
        ];
        if (!!grants && grants.length > 0) {
            sections.push({
                label: t('gamePlanPdf|others'),
                children: (_jsx(GamePlanOthers, { grants: company.grants, locale: selectedLocale, translations: translations })),
                id: 'others-section',
            });
        }
        if (!!summary &&
            !(summary.summary == '' &&
                summary.todo_client == '' &&
                summary.todo_hd == '')) {
            sections.push({
                label: t('gamePlanPdf|summary'),
                children: (_jsx(GamePlanSummary, { summary: summary, translations: translations })),
                id: 'summary-section',
            });
        }
        return sections;
    }, [projects, company, grants, selectedLocale]);
};
export default useGamePlanSections;
