import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import AssetUpload from '../../asset-upload';
const FilesListFooter = ({ handleSaveAsset, activeRecord, rootRecord, additionalOwners, allowUpload, title, }) => {
    const records = useMemo(() => {
        const records = [];
        if (activeRecord) {
            records.push(activeRecord);
        }
        else {
            records.push(rootRecord);
        }
        return records;
    }, [activeRecord, rootRecord]);
    return (_jsxs(Div, { flex: "row", gap: 54, align: "center", justify: !!title ? 'space-between' : 'flex-end', style: { width: '100%' }, children: [!!title && _jsx(Typography, { elementTheme: "subtitle2", children: title }), !!allowUpload && (_jsx(AssetUpload, { handleSaveAsset: handleSaveAsset, records: records, owners: additionalOwners }))] }));
};
export default FilesListFooter;
