var ProfileBlocks;
(function (ProfileBlocks) {
    ProfileBlocks["FastInfos"] = "fast-infos";
    ProfileBlocks["About"] = "about";
    ProfileBlocks["Portfolios"] = "portfolios";
    ProfileBlocks["Presentation"] = "video";
    ProfileBlocks["Logo"] = "logo";
    ProfileBlocks["Banner"] = "banner";
    ProfileBlocks["Team"] = "team";
    ProfileBlocks["Reviews"] = "reviews";
    ProfileBlocks["Rfp"] = "rfp";
    ProfileBlocks["Expertise"] = "expertise";
    ProfileBlocks["Contact"] = "contact";
    ProfileBlocks["AdBanner"] = "ad-banner";
})(ProfileBlocks || (ProfileBlocks = {}));
export default ProfileBlocks;
