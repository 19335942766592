import dayjs from 'dayjs';
import('dayjs/locale/en');
import('dayjs/locale/fr');
export const getFormattedDate = (date, locale = 'en') => {
    return dayjs(date).locale(locale).format('DD/MM/YYYY HH:mm:ss');
};
export const getShortFormattedDate = (date, locale = 'en') => {
    return dayjs(date).locale(locale).format('DD/MM/YYYY');
};
export const getStringDate = (date, locale) => {
    if (locale == undefined) {
        locale = 'en';
    }
    if (locale === 'fr') {
        return dayjs(date).locale(locale).format('D MMMM YYYY');
    }
    else if (locale === 'en') {
        return dayjs(date).locale(locale).format('MMMM D, YYYY');
    }
    else {
        return date.toString();
    }
};
export const getLongStringDate = (date, locale) => {
    if (locale == undefined) {
        locale = 'en';
    }
    if (locale === 'fr') {
        return dayjs(date).locale(locale).format('DD MMMM YYYY - HH:mm:ss');
    }
    else if (locale === 'en') {
        return dayjs(date).locale(locale).format('MMMM DD, YYYY - HH:mm:ss');
    }
    else {
        return date.toString();
    }
};
export const getStringDateFromString = (date, locale) => {
    if (!date)
        return '';
    if (locale === undefined) {
        locale = 'en';
    }
    if (locale === 'fr') {
        return dayjs(date).locale(locale).format('DD MMMM YYYY');
    }
    else if (locale === 'en') {
        return dayjs(date).locale(locale).format('MMMM DD, YYYY');
    }
    else {
        return date.toString();
    }
};
export const getStringDateAndTime = (date, locale) => {
    if (locale == undefined) {
        locale = 'en';
    }
    if (locale === 'fr') {
        return dayjs(date).locale(locale).format('DD MMMM YYYY - HH:mm');
    }
    else if (locale === 'en') {
        return dayjs(date).locale(locale).format('MMMM DD, YYYY - HH:mm');
    }
    else {
        return date.toString();
    }
};
export default getFormattedDate;
