const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length)
        return false;
    return (arr1.every((item) => arr2.includes(item)) &&
        arr2.every((item) => arr1.includes(item)));
};
export const objectsEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length)
        return false;
    for (const key of keys1) {
        const val1 = obj1[key];
        const val2 = obj2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !objectsEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)) {
            return false;
        }
    }
    return true;
};
export const isObject = (object) => {
    return object != null && typeof object === 'object';
};
export const arraysOfObjectsEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length)
        return false;
    for (let i = 0; i < arr1.length; i++) {
        if (!objectsEqual(arr1[i], arr2[i]))
            return false;
    }
    return true;
};
export default arraysEqual;
