'use client';
import { useMemo } from 'react';
import { useTranslations } from '../../../features/providers/translations-provider';
export const useAnnualRevenue = () => {
    const { t } = useTranslations();
    return useMemo(() => {
        const annualRevenue = [
            { label: t('annualRevenue|annualRevenue1'), value: '500k' },
            { label: t('annualRevenue|annualRevenue2'), value: '1m' },
            { label: t('annualRevenue|annualRevenue3'), value: '2m' },
            { label: t('annualRevenue|annualRevenue4'), value: '5m' },
            { label: t('annualRevenue|annualRevenue5'), value: '10m' },
            { label: t('annualRevenue|annualRevenue6'), value: '25m' },
            { label: t('annualRevenue|annualRevenue7'), value: '50m' },
            { label: t('annualRevenue|annualRevenue8'), value: '100m' },
            { label: t('annualRevenue|annualRevenue9'), value: '100m+' },
        ];
        return annualRevenue;
    }, [t]);
};
export const useCompanySize = () => {
    const { t } = useTranslations();
    return useMemo(() => {
        const companySize = [
            { label: t('companySize|companySize1'), value: '1' },
            { label: t('companySize|companySize2'), value: '2-10' },
            { label: t('companySize|companySize3'), value: '11-25' },
            { label: t('companySize|companySize4'), value: '26-50' },
            { label: t('companySize|companySize5'), value: '51-100' },
            { label: t('companySize|companySize6'), value: '101-300' },
            { label: t('companySize|companySize7'), value: '300+' },
        ];
        return companySize;
    }, [t]);
};
