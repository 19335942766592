import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import parse, { domToReact } from '../../../features/helpers/parse';
import { useTheme } from '../../../plugins/styled';
import Button from '../../common/button';
import Div from '../../common/div';
import Typography from '../../common/typography';
const CompanyMainProjectTableHeader = ({ project, projectsListState: { handleEditProject, editState } }) => {
    const theme = useTheme();
    const { title, description } = project;
    const handleEdit = () => {
        handleEditProject(project);
    };
    return (_jsxs(Div, { flex: "row", gap: 54, justify: "space-between", children: [_jsxs(Div, { flex: "column", gap: 8, style: { maxWidth: theme.caps.big }, children: [_jsx(Typography, { elementTheme: "subtitle1", children: title }), _jsx(Typography, { elementTheme: "body2", children: parse(description !== null && description !== void 0 ? description : '', {
                            replace(domNode) {
                                if (domNode.attribs && domNode.name === 'p') {
                                    return _jsx(_Fragment, { children: domToReact(domNode.children) });
                                }
                            },
                        }) })] }), editState === 'editable' && (_jsx(Button, { defaultStyle: theme.colors.grey_3, transparent: true, size: "square", headingIcon: _jsx(Edit, { size: 20 }), onClick: handleEdit }))] }));
};
export default CompanyMainProjectTableHeader;
