import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Divider from 'antd/es/divider';
import { useMemo } from 'react';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import FilterCompanyInfos from './filter-company-infos';
import FilterCompanySectors from './filter-company-sectors';
const FilterCompanyCardContent = ({ company, industries = [], subIndustries = [], annualRevenue = [], companySize = [], provinces = [], }) => {
    var _a, _b;
    const theme = useTheme();
    const { t } = useTranslations();
    const mappedProvinces = useMemo(() => provinces.reduce((o, province) => { var _a; return (Object.assign(Object.assign({}, o), { [(_a = province.value) !== null && _a !== void 0 ? _a : '']: province.label })); }, {}), [provinces]);
    const mappedCompanySizes = useMemo(() => companySize.reduce((o, compSize) => { var _a; return (Object.assign(Object.assign({}, o), { [(_a = compSize.value) !== null && _a !== void 0 ? _a : '']: compSize.label })); }, {}), [companySize]);
    const mappedAnnualRevenues = useMemo(() => annualRevenue.reduce((o, annRevenue) => {
        var _a;
        return (Object.assign(Object.assign({}, o), { [(_a = annRevenue.value) !== null && _a !== void 0 ? _a : '']: annRevenue.label }));
    }, {}), [annualRevenue]);
    return (_jsxs(Div, { flex: "row", justify: "space-between", tablet: { flex: 'column', align: 'flex-start' }, align: "stretch", children: [_jsxs(Div, { flex: "column", style: { flex: 1, padding: '8px 16px' }, gap: 12, children: [_jsx(FilterCompanyInfos, { label: 'companySize', children: _jsx(Typography, { elementTheme: "body3", children: (company === null || company === void 0 ? void 0 : company.size)
                                ? mappedCompanySizes[company === null || company === void 0 ? void 0 : company.size]
                                : t(`grantsFundingExplorer|grantsgrantFilterCardNoCompanySize`) }) }), _jsx(FilterCompanyInfos, { label: 'region', children: _jsx(Typography, { elementTheme: "body3", children: (company === null || company === void 0 ? void 0 : company.province)
                                ? mappedProvinces[company === null || company === void 0 ? void 0 : company.province]
                                : t(`grantsFundingExplorer|grantsgrantFilterCardNoRegion`) }) }), _jsx(FilterCompanyInfos, { label: 'annualRevenue', children: _jsx(Typography, { elementTheme: "body3", children: (company === null || company === void 0 ? void 0 : company.annual_revenue)
                                ? mappedAnnualRevenues[company === null || company === void 0 ? void 0 : company.annual_revenue]
                                : t(`grantsFundingExplorer|grantsgrantFilterCardNoAnnualRevenue`) }) })] }), _jsx(Divider, { type: "vertical", style: {
                    height: 'auto',
                    borderColor: theme.colors.grey_4,
                    margin: 0,
                    top: 0,
                } }), _jsxs(Div, { flex: "column", style: { flex: 1, padding: '8px 16px' }, gap: 12, children: [_jsx(FilterCompanySectors, { companyInfo: (_a = company.industry_sector) !== null && _a !== void 0 ? _a : [], industries: industries, theme: theme, typeSector: "" }), _jsx(FilterCompanySectors, { companyInfo: (_b = company.industry_subsector) !== null && _b !== void 0 ? _b : [], industries: subIndustries, theme: theme, typeSector: "Subsector" })] })] }));
};
export default FilterCompanyCardContent;
