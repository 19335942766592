import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import AssetEditorDrawer from './asset-editor-drawer/edit-single-asset-drawer';
import AssetViewerDrawer from './asset-viewer-drawer';
import MultiAssetsDrawer from './multi-assets-drawer';
const AssetDrawer = ({ open, onClose, metas, activeMetaId, isMulti, isEdit, handleSaveAsset, records, owners, suggestedAssets, handleAddSuggestedAsset, suggestedAssetsSectionTitle, }) => {
    const selectedMeta = useMemo(() => metas[activeMetaId], [activeMetaId]);
    return isMulti ? (_jsx(MultiAssetsDrawer, { onClose: onClose, open: open, metas: metas, isEdit: isEdit, handleSaveAsset: handleSaveAsset, records: records, owners: owners, activeMetaId: activeMetaId, suggestedAssets: suggestedAssets, handleAddSuggestedAsset: handleAddSuggestedAsset, suggestedAssetsSectionTitle: suggestedAssetsSectionTitle })) : isEdit ? (_jsx(AssetEditorDrawer, { onClose: onClose, open: open, meta: selectedMeta, handleSaveAsset: handleSaveAsset, records: records, owners: owners })) : (_jsx(AssetViewerDrawer, { onClose: onClose, open: open, meta: selectedMeta }));
};
export default AssetDrawer;
