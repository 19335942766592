import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useTheme } from '../../../plugins/styled';
import { VideoPlayerContainer } from './styles';
const VideoPlayer = ({ url, title, controls = true, light, playing = false, width = '100%', height = '100%', style, }) => {
    const theme = useTheme();
    const [currentUrl, setCurrentUrl] = useState(url);
    const [isPlaying, setIsPlaying] = useState(playing);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (currentUrl !== url) {
            setIsLoading(true);
            setCurrentUrl(url);
            setTimeout(() => setIsLoading(false), 500);
        }
    }, [url]);
    const handlePlay = () => {
        if (!isPlaying) {
            setIsPlaying(true);
        }
    };
    if (isLoading)
        return _jsx(_Fragment, {});
    return (_jsx(VideoPlayerContainer, { style: Object.assign({ background: theme.colors.primary, width,
            height }, style), children: _jsx(ReactPlayer, { light: light, controls: controls, playing: isPlaying, onPlay: handlePlay, url: currentUrl, width: width, height: height, style: { aspectRatio: 16 / 9 }, title: title }) }));
};
export default VideoPlayer;
