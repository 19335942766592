export const API_URL = (() => {
    var _a;
    const env = process.env['CI_HELLODARWIN_API_URL'];
    if (env !== undefined) {
        return env;
    }
    const hostname = typeof window !== 'undefined' ? (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname : '';
    return hostname === 'localhost'
        ? 'http://localhost:8080'
        : `https://api.${hostname.substring(hostname.indexOf('.') + 1)}`;
})();
export const CONTENTFUL_API_URL = (() => {
    const env = process.env['CI_HELLODARWIN_CONTENTFUL_API_URL'];
    if (env !== undefined) {
        return env;
    }
    const spaceId = 'modxx330knqj';
    return `https://graphql.contentful.com/content/v1/spaces/${spaceId}`;
})();
const STRIPE_TEST_PUBLISHABLE_KEY = 'pk_test_51IB0JjBFLFuEKur7WuOqbL4ngsdzdOr4A2Y3hlYgBAYErhJGH1buYedJGKjDdACvPokYWs87r62yKgOQ2KmzdkKn00B2c2Rthk';
export const STRIPE_PUBLISHABLE_KEY = (() => {
    const env = process.env['REACT_APP_HELLODARWIN_STRIPE_PUBLISHABLE_KEY'];
    if (env !== undefined) {
        return env;
    }
    return STRIPE_TEST_PUBLISHABLE_KEY;
})();
export const GMAPS_API_KEY = (() => {
    const env = process.env['REACT_APP_HELLODARWIN_GMAPS_API_KEY'];
    if (env !== undefined) {
        return env;
    }
    const env2 = process.env['GATSBY_REACT_APP_HELLODARWIN_GMAPS_API_KEY'];
    if (env2 !== undefined) {
        return env2;
    }
    return '';
})();
export const GMAPS_MAP_ID = (() => {
    const env = process.env['REACT_APP_HELLODARWIN_GMAPS_ID'];
    if (env !== undefined) {
        return env;
    }
    return '';
})();
