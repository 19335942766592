export default class PortfolioAdapter {
    constructor() {
        this.getPortfolio = (portfolios, portfolio_id) => {
            if (portfolios) {
                return portfolios[portfolio_id];
            }
            return {};
        };
        this.selectAll = (portfolios) => {
            if (portfolios) {
                const portfoliosList = Object.values(portfolios);
                portfoliosList.sort((portfolioA, portfolioB) => new Date(portfolioA.created_at).getTime() <
                    new Date(portfolioB.created_at).getTime()
                    ? 1
                    : -1);
                return portfoliosList;
            }
            return [];
        };
        this.setAll = (mappedPortfolios, portfolios) => {
            mappedPortfolios = this.getInitialState();
            for (let portfolio of portfolios) {
                mappedPortfolios[portfolio.provider_portfolio_id] = portfolio;
            }
            return mappedPortfolios;
        };
        this.removeOne = (portfolios, id) => {
            if (portfolios) {
                delete portfolios[id];
            }
            return portfolios;
        };
        this.insertOne = (portfolios, portfolio) => {
            if (portfolios) {
                portfolios[portfolio.provider_portfolio_id] = portfolio;
            }
            return portfolios;
        };
        this.insertOneAtStart = (portfolios, portfolio) => {
            if (portfolios) {
                portfolios[portfolio.provider_portfolio_id] = portfolio;
            }
            return portfolios;
        };
        this.updateOne = (portfolios, { id, changes }) => {
            if (portfolios) {
                portfolios[id] = changes;
            }
            return portfolios;
        };
        this.updateOnePortfolioAssets = (portfolios, { portfolio_id, changes, }) => {
            if (portfolios) {
                const portfolio = portfolios[portfolio_id];
                portfolios[portfolio_id] = Object.assign(Object.assign({}, portfolio), { assets: changes });
            }
            return portfolios;
        };
        this.insertOnePortfolioAsset = (portfolios, { portfolio_id, asset }) => {
            if (portfolios) {
                const portfolio = portfolios[portfolio_id];
                const assets = [...portfolio.assets, asset];
                portfolios[portfolio_id] = Object.assign(Object.assign({}, portfolio), { assets });
            }
            return portfolios;
        };
        this.removeOnePortfolioAsset = (portfolios, { id, changes }) => {
            if (portfolios) {
                const assetIndex = portfolios[id].assets.findIndex((asset) => asset.provider_portfolio_asset_id ===
                    changes.provider_portfolio_asset_id);
                portfolios[id].assets.splice(assetIndex, 1);
            }
            return portfolios;
        };
        this.getInitialState = () => {
            return {};
        };
    }
}
export const createPortfolioAdapter = () => {
    return new PortfolioAdapter();
};
