import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import theme from '@hellodarwin/core/lib/theme';
import Informations from '@hellodarwin/icons/dist/icons/Informations';

const GameplanWarningModal = () => {
  const { t } = useTranslations();

  return (
    <Div flex="column" align="center" justify="center" gap={12}>
      <Informations color={theme.colors.yellow_1} size={44} />
      <Typography elementTheme="subtitle1" textAlign="center">
        {t('gamePlanPdf|cannot_generate_warning')}
      </Typography>
    </Div>
  );
};

export default GameplanWarningModal;
