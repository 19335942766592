export const getFormPageKeysToCheck = (page) => {
    var _a;
    return (((_a = page.groups) === null || _a === void 0 ? void 0 : _a.flatMap((group) => { var _a; return ((_a = group.items) === null || _a === void 0 ? void 0 : _a.map((item) => item.form_item_id)) || []; })) || []);
};
export const getFormPageSubmissionValues = (page, formData) => {
    var _a;
    return (((_a = page.groups) === null || _a === void 0 ? void 0 : _a.flatMap((group) => group.items
        .filter((item) => !!formData[item.form_item.form_item_id])
        .map((item) => item.forms_submissions_item))) || []);
};
export const getFormItemConditions = (value, nextPageIndex, item) => {
    if (!(item === null || item === void 0 ? void 0 : item.form_item_conditions))
        return nextPageIndex;
    const matchedCondition = item.form_item_conditions.find((condition) => {
        return ((condition.condition_type === 'equals' ||
            condition.condition_type === 'contains') &&
            condition.trigger_values.some((trigger) => trigger.trigger_value === value));
    });
    return (matchedCondition === null || matchedCondition === void 0 ? void 0 : matchedCondition.form_page_id) || nextPageIndex;
};
