import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import Save from '@hellodarwin/icons/dist/icons/Save';
import Drawer from 'antd/es/drawer';
import Form, { useForm } from 'antd/es/form/Form';
import Input from 'antd/es/input/Input';
import Select from 'antd/es/select';
import { useState } from 'react';
import { getShortId } from '../../../../../features/helpers';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Clipboard from '../../../../common/clipboard';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import FormItem from '../../../form-layouts/form-item';
import FormLabel from '../../../form-layouts/form-label';
const FormBuilderGroupEditButton = ({ builderGroup, functions, }) => {
    const theme = useTheme();
    const [editModalOpen, setEditModalOpen] = useState(false);
    const handleEditModal = () => setEditModalOpen(!editModalOpen);
    const [form] = useForm();
    const handleSubmit = () => {
        const values = form.getFieldsValue();
        const editedGroup = Object.assign(Object.assign({}, builderGroup.entity), values);
        functions.updateFormGroup(editedGroup);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { size: "circle", trailingIcon: _jsx(Edit, { size: 16 }), onClick: handleEditModal, defaultStyle: theme.colors.purple_2 }), _jsx(Drawer, { open: editModalOpen, onClose: handleEditModal, title: _jsxs(Div, { align: "center", flex: "row", style: { height: '100%' }, justify: "space-between", children: [_jsxs(Div, { fitContent: true, flex: "row", gap: 8, align: "center", children: [_jsx(Typography, { elementTheme: "subtitle1", lineClamp: 3, children: "Group :" }), _jsx(Typography, { elementTheme: "subtitle1", color: theme.colors.purple_1, colorHover: theme.colors.purple_2, children: _jsx(Clipboard, { title: getShortId(builderGroup.entity.form_group_id), copy: getShortId(builderGroup.entity.form_group_id) }) })] }), _jsx(Button, { size: "circle", defaultStyle: theme.colors.white_1, onClick: handleSubmit, children: _jsx(Save, { size: 16 }) })] }), children: _jsx(Form, { layout: "vertical", form: form, initialValues: builderGroup.entity, children: _jsxs(Div, { flex: "column", gap: 24, children: [_jsx(FormItem, { label: _jsx(FormLabel, { label: "Group ID" }), children: _jsx(Input, { value: getShortId(builderGroup.entity.form_group_id), disabled: true }) }), _jsx(FormItem, { name: 'group_title_en', label: _jsx(FormLabel, { label: "Title EN" }), children: _jsx(Input, {}) }), _jsx(FormItem, { name: 'group_title_fr', label: _jsx(FormLabel, { label: "Title FR" }), children: _jsx(Input, {}) }), _jsx(FormItem, { name: 'group_disposition', label: _jsx(FormLabel, { label: "Disposition" }), children: _jsx(Select, { options: [
                                        { label: 'Horizontal', value: 'horizontal' },
                                        { label: 'Vertical', value: 'vertical' },
                                    ] }) }), _jsx(FormItem, { label: _jsx(FormLabel, { label: "Group Order", extra: "Please use the builder to change the order." }), children: _jsx(Input, { value: builderGroup.entity.group_order, disabled: true }) })] }) }) })] }));
};
export default FormBuilderGroupEditButton;
