var _a, _b;
const hostname = typeof window !== 'undefined' ? (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname : '';
const port = typeof window !== 'undefined' ? (_b = window === null || window === void 0 ? void 0 : window.location) === null || _b === void 0 ? void 0 : _b.port : '';
const getWebsiteURL = () => {
    const websiteUrl = hostname.includes('localhost')
        ? 'http://localhost:8000'
        : hostname.includes('staging')
            ? 'website-git-development-hello-darwin.vercel.app'
            : 'https://hellodarwin.com';
    return websiteUrl;
};
const getClientAppURL = () => {
    const clientAppUrl = hostname.includes('localhost')
        ? 'http://localhost:3000'
        : hostname.includes('staging') || hostname.includes('development')
            ? 'https://app.staging.darwinrevolution.com/'
            : 'https://app.hellodarwin.com';
    return clientAppUrl;
};
const getOrigin = () => {
    const origin = hostname.includes('localhost')
        ? port.includes('3000')
            ? 'client'
            : port.includes('3001')
                ? 'admin'
                : port.includes('3002')
                    ? 'partner'
                    : port.includes('8000') || port.includes('9000')
                        ? 'website'
                        : 'other'
        : hostname.includes('app')
            ? 'client'
            : hostname.includes('partner')
                ? 'partner'
                : hostname.includes('admin')
                    ? 'admin'
                    : 'admin';
    return origin;
};
const handleExternalLinkCLick = (path) => {
    window === null || window === void 0 ? void 0 : window.open(path, '_blank');
};
export default getWebsiteURL;
export { getClientAppURL, getOrigin, handleExternalLinkCLick };
