import message from 'antd/es/message';
import { InitialFormPageResponse, } from '../../../../features/entities/form-entities';
export const findInfoByPageId = ({ pageId, pages, }) => {
    var _a;
    return ((_a = pages.find((page) => page.form_page_id === pageId)) !== null && _a !== void 0 ? _a : InitialFormPageResponse);
};
export const handleReorderFormPages = ({ formPageId, functions, formResponse, page_order, }) => {
    const pages = [...formResponse.pages];
    pages.sort((g1, g2) => g1.page_number - g2.page_number);
    const movingPageID = pages.findIndex((page) => page.form_page_id === formPageId);
    const [removedPage] = pages.splice(movingPageID, 1);
    pages.splice(page_order, 0, removedPage);
    const pagesResponse = [];
    pages.forEach((page, index) => {
        pagesResponse.push(Object.assign(Object.assign({}, page), { page_number: index }));
    });
    functions.updateFormPages(Object.assign(Object.assign({}, formResponse), { pages: pagesResponse }));
};
export const handleDeleteFormPage = ({ form_id, form_page_id, formResponse, formPages, functions, }) => {
    var _a;
    if (((_a = formResponse.pages) === null || _a === void 0 ? void 0 : _a.length) > 1) {
        const deletedIndex = formPages[form_page_id].entity.page_number;
        const newFormPages = Object.assign({}, formPages);
        delete newFormPages[form_page_id];
        var newAdminResponse = {
            form_id: form_id,
            pages: [],
        };
        for (const pageIndex in newFormPages) {
            const page = newFormPages[pageIndex];
            newAdminResponse.pages.push({
                form_page_id: page.id,
                page_number: page.entity.page_number <= deletedIndex
                    ? page.entity.page_number
                    : --page.entity.page_number,
                page_title_en: page.entity.page_title_en,
                page_title_fr: page.entity.page_title_fr,
                form_id,
                groups: [],
            });
        }
        newAdminResponse.pages.push({
            form_page_id,
            page_number: 0,
            form_id: form_id,
            groups: [],
            deleted: true,
        });
        functions.updateFormPages(newAdminResponse);
    }
    else {
        message.error('The form has to have a least one page');
    }
};
export const handleNewPageDrop_FromControls = ({ form_id, functions, page_number, }) => {
    let formPage = {
        form_page_id: '',
        page_number,
        page_title_en: 'New Page Title',
        page_title_fr: 'Nouveau titre de page',
        form_id,
        groups: [],
    };
    functions.createPage(formPage);
};
