import Typography from '@hellodarwin/core/lib/components/common/typography';
import FormItem from '@hellodarwin/core/lib/components/forms/form-layouts/form-item';
import {
  GinApplication,
  GrantsRefusalReason,
} from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Form, { FormInstance } from 'antd/es/form';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import RenderInputNumber from './application-form-input-number';
import RenderSelectDate from './application-form-select-date';

const RefusedForm = ({
  form,
  application,
}: {
  form: FormInstance<GinApplication>;
  application: GinApplication;
}) => {
  const { t } = useTranslations();
  return (
    <Form form={form} layout="vertical" initialValues={application}>
      <RenderInputNumber
        label="submitted_amount"
        name="application_accepted_amount"
      />
      <FormItem
        name={'application_refusal_reason'}
        label={
          <Typography elementTheme="body2">
            {t('application_single|refused_reason')}
          </Typography>
        }
        rules={[
          {
            required: true,
            message: t('application_single|refusal_reason_required'),
          },
        ]}
      >
        <Select placeholder="Select grants refusal reason">
          {Object.entries(GrantsRefusalReason).map(([key, value]) => (
            <Select.Option key={key} value={value}>
              {key.replace(/([A-Z])/g, ' $1').trim()}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        name={'application_refusal_reason_specified'}
        label={
          <Typography elementTheme="body2">
            {t('application_single|refused_reason_details')}
          </Typography>
        }
      >
        <Input style={{ width: '100%' }} />
      </FormItem>
      <RenderSelectDate
        label="application_refused_at"
        name="application_rejected_at"
        defaultToday={true}
      />
    </Form>
  );
};

export default RefusedForm;
