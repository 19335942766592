import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { domToReact, } from '../../../../features/helpers/parse';
import Typography from '../../../common/typography';
export const itemDescriptionOptions = {
    replace(domNode) {
        if (domNode.type === 'tag') {
            const nodeTag = domNode;
            if (nodeTag.name === 'h2') {
                return (_jsx(Typography.Title, { level: 2, elementTheme: "body3", bold: true, style: { marginBottom: 8, marginTop: 16 }, children: domToReact(nodeTag.children) }));
            }
            else if (nodeTag.name === 'ul') {
                return (_jsx(Typography, { elementTheme: "body3", style: { marginBottom: 4 }, children: _jsx("ul", { style: {
                            margin: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 6,
                        }, children: domToReact(nodeTag.children, itemDescriptionOptions) }) }));
            }
            else if (nodeTag.name === 'li') {
                return _jsx("li", { children: domToReact(nodeTag.children) });
            }
            else if (nodeTag.name === 'p') {
                return (_jsx(Typography, { elementTheme: "body3", style: { marginBottom: 8 }, children: domToReact(nodeTag.children) }));
            }
        }
    },
};
export const pageDescriptionParseOptions = {
    replace(domNode) {
        if (domNode.type === 'tag') {
            const nodeTag = domNode;
            if (nodeTag.name === 'strong') {
                return (_jsx(Typography, { elementTheme: "subtitle1", style: { marginBottom: 8, marginTop: 8 }, textAlign: "center", children: domToReact(nodeTag.children) }));
            }
            else if (nodeTag.name === 'p') {
                if (nodeTag.children.length === 1 &&
                    nodeTag.children[0].name === 'strong') {
                    return (_jsx(_Fragment, { children: domToReact(nodeTag.children, pageDescriptionParseOptions) }));
                }
                else {
                    return (_jsx(Typography, { elementTheme: "body1", textAlign: "center", style: { marginBottom: 8 }, children: domToReact(nodeTag.children) }));
                }
            }
        }
    },
};
