import { useMemo } from 'react';
import getShortId from '../../../../features/helpers/get-short-id';
import { useTranslations } from '../../../../features/providers/translations-provider';
const useOwnerLabelAndPath = (owner) => {
    const { t } = useTranslations();
    const path = useMemo(() => {
        if (!(owner === null || owner === void 0 ? void 0 : owner.owner_type))
            return '';
        switch (owner.owner_type) {
            case 'company':
                return `/companies/${owner.owner_id}`;
            case 'admin':
                return `/admins`;
            case 'contact':
                return `/companies`;
            case 'provider':
                return `/providers/${owner.owner_id}`;
            default:
                return '/';
        }
    }, [owner]);
    const label = useMemo(() => {
        var _a, _b, _c, _d;
        if (!(owner === null || owner === void 0 ? void 0 : owner.owner_type))
            return '';
        switch (owner.owner_type) {
            case 'contact':
                return ((_c = t('files_gin|contact_of_company', {
                    contact_name: (_a = owner.owner_name) !== null && _a !== void 0 ? _a : getShortId(owner.owner_id),
                    company_name: (_b = owner.owner_relation) !== null && _b !== void 0 ? _b : '-',
                })) !== null && _c !== void 0 ? _c : owner.owner_id);
            default:
                return (_d = owner.owner_name) !== null && _d !== void 0 ? _d : owner.owner_id;
        }
    }, [owner, t]);
    return useMemo(() => ({ label, path }), [path, label]);
};
export default useOwnerLabelAndPath;
