import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { getStringDate } from '../../../features/helpers/get-formatted-date';
import useLocale from '../../../features/providers/locale-provider';
import { useTranslations } from '../../../features/providers/translations-provider';
const GrantsTimeline = ({ grant }) => {
    const { grant_timeline, application_status } = grant;
    const { t } = useTranslations();
    const { selectedLocale } = useLocale();
    const timeline_infos = useMemo(() => {
        var _a, _b;
        const timeline_infos = [];
        if (!!grant_timeline) {
            if (!!((_a = grant_timeline[0]) === null || _a === void 0 ? void 0 : _a.opened_at)) {
                timeline_infos.push(`${t('grant_single|openingDate')} ${getStringDate(new Date(grant_timeline[0].opened_at), selectedLocale)}`);
            }
            if (!!((_b = grant_timeline[0]) === null || _b === void 0 ? void 0 : _b.closed_at)) {
                timeline_infos.push(`${t('grant_single|closingDate')} ${getStringDate(new Date(grant_timeline[0].closed_at), selectedLocale)}`);
            }
        }
        else {
            switch (application_status) {
                case 'suspending':
                    timeline_infos.push(t('grant_single|grantStatusSuspending'));
                    break;
                case 'closed':
                    timeline_infos.push(t('grant_single|grantStatusClosed'));
                    break;
                default:
                    timeline_infos.push(t('grant_single|timelineUnspecified'));
                    break;
            }
        }
        const filtered_infos = timeline_infos.filter((itm) => !!itm);
        if (!filtered_infos.length) {
            filtered_infos.push(t('grant_single|timelineUnspecified'));
        }
        return filtered_infos.filter((itm) => !!itm);
    }, [grant_timeline, application_status, selectedLocale, t]);
    return (_jsx("ul", { children: timeline_infos.map((v) => (_jsx("li", { children: v }, v))) }));
};
export default GrantsTimeline;
