'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { DivContainer } from './styles';
import useDivProps from './utils/use-div-props';
const Div = (props) => {
    const { currentProps, currentStyle, generalProps } = useDivProps(props);
    const { children, onClick, onDoubleClick, onMouseEnter, onMouseLeave, onPointerEnter, onPointerLeave, id, className, } = generalProps;
    return (_jsx(DivContainer, { "$backgroundColor": currentProps === null || currentProps === void 0 ? void 0 : currentProps.$backgroundColor, "$backgroundOpacity": currentProps === null || currentProps === void 0 ? void 0 : currentProps.$backgroundOpacity, "$backgroundOpacityHover": currentProps === null || currentProps === void 0 ? void 0 : currentProps.$backgroundOpacityHover, "$flexDirection": currentProps === null || currentProps === void 0 ? void 0 : currentProps.$flexDirection, "$borderColor": currentProps === null || currentProps === void 0 ? void 0 : currentProps.$borderColor, "$borderColorHover": currentProps === null || currentProps === void 0 ? void 0 : currentProps.$borderColorHover, "$hidden": currentProps === null || currentProps === void 0 ? void 0 : currentProps.$hidden, "$fitContent": currentProps === null || currentProps === void 0 ? void 0 : currentProps.$fitContent, "$minContent": currentProps === null || currentProps === void 0 ? void 0 : currentProps.$minContent, "$maxContent": currentProps === null || currentProps === void 0 ? void 0 : currentProps.$maxContent, "$fullHeight": currentProps === null || currentProps === void 0 ? void 0 : currentProps.$fullHeight, "$borderSide": currentProps === null || currentProps === void 0 ? void 0 : currentProps.$borderSide, className: className, style: currentStyle, id: id, onClick: onClick, onDoubleClick: onDoubleClick, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onPointerEnter: onPointerEnter, onPointerLeave: onPointerLeave, children: children }));
};
export default Div;
