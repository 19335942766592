import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import Div from '../../../../common/div';
import HdTag from '../../../../common/hd-tag';
import Typography from '../../../../common/typography';
import getFilterTagBarTheme from '../utils/get-filter-tag-bar-theme';
import FilterCompanyInfos from './filter-company-infos';
const FilterCompanySectors = ({ companyInfo, industries, theme, typeSector, }) => {
    const { t } = useTranslations();
    return (_jsx(FilterCompanyInfos, { label: `industry${typeSector}`, children: _jsx(Div, { flex: "row", wrap: "wrap", gap: 8, children: companyInfo && companyInfo.length > 0 ? (React.Children.toArray(companyInfo
                .slice()
                .sort((a, b) => a.localeCompare(b))
                .map((industryId, index) => {
                const industry = industries === null || industries === void 0 ? void 0 : industries.find((ind) => ind.value === industryId);
                return industry ? (_jsx(HdTag, { text: industry.label, color: getFilterTagBarTheme('industry' + typeSector, theme, '') }, index)) : (_jsx(Typography, { elementTheme: "body2", children: t(`grantsFundingExplorer|grantsgrantFilterCardNoIndustry${typeSector}`) }, index));
            }))) : (_jsx(Typography, { elementTheme: "body2", children: t(`grantsFundingExplorer|grantsgrantFilterCardNoIndustry${typeSector}`) })) }) }));
};
export default FilterCompanySectors;
