import { jsx as _jsx } from "react/jsx-runtime";
import Form from 'antd/es/form';
import React from 'react';
import Div from '../../../../../common/div';
import renderInput from './render-input';
const defaultRenderForm = (inputs, form, activeItem, deleteItem) => {
    return (_jsx(Form, { layout: "vertical", initialValues: activeItem, form: form, children: _jsx(Div, { flex: "column", gap: 16, children: React.Children.toArray(inputs.map((input) => renderInput({
                input,
                activeItem,
                deleteItem,
                form,
            }))) }) }));
};
export default defaultRenderForm;
