import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Tooltip from 'antd/es/tooltip';
import { useTranslations } from '../../../features/providers/translations-provider';
import { useTheme } from '../../../plugins/styled';
import Div from '../../common/div';
import HdTag from '../../common/hd-tag';
import GrantStatusTag from './grant-status-tag';
const GrantCardStatusList = ({ application_status, featuredTags, }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    return (_jsxs(Div, { flex: "row", gap: 8, wrap: "wrap", overflow: "hidden", children: [application_status === 'closingSoon' && _jsx(GrantStatusTag, { status: "open" }), _jsx(GrantStatusTag, { status: application_status }), featuredTags &&
                featuredTags.map((featuredString) => (_jsx(Tooltip, { title: t('grant_card|idealFor') + featuredString, children: _jsx(HdTag, { color: theme.colors.yellow_2, text: featuredString, withBorder: true }) }, featuredString)))] }));
};
export default GrantCardStatusList;
