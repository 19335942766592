import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Form from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import Radio from 'antd/es/radio';
import Space from 'antd/es/space';
import { useTranslation } from '../../plugins/i18n';
import Button from '../common/button';
import Div from '../common/div';
import Modal from '../common/hd-modal';
import Typography from '../common/typography';
const ProviderRefuseModal = ({ match, closeModal, handleRefuse, modalVisible, }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const matchRefusalFormValues = {
        refusalReason: match.raise_hand_reject_reason || '',
        refusalReasonSpecified: match.raise_hand_reject_reason || '',
    };
    const handleCancel = async () => {
        form.resetFields();
        closeModal();
    };
    const handleFinish = async () => {
        handleRefuse(match.match_id, form.getFieldValue(matchRefusalFormValues.refusalReason), form.getFieldValue(matchRefusalFormValues.refusalReasonSpecified));
        form.resetFields();
        closeModal();
    };
    const handleChange = (e) => {
        if (!form.getFieldValue('refusalReason')) {
            form.setFieldsValue({
                ['refusalReason']: 'Other',
            });
        }
    };
    return (_jsx(Modal, { open: modalVisible, handleCancel: handleCancel, children: _jsxs(Div, { flex: "column", gap: 16, children: [_jsx(Typography.Title, { level: 4, children: t('rfp|matchRejectModal.title') }), _jsxs(Form, { form: form, layout: 'vertical', size: "large", onFinish: handleFinish, name: 'refuseMatch', initialValues: matchRefusalFormValues, children: [_jsx(Form.Item, { name: "refusalReason", rules: [
                                {
                                    required: true,
                                    message: t('rfp|matchRejectModal.form.validation'),
                                },
                            ], children: _jsx(Radio.Group, { size: 'large', children: _jsxs(Space, { direction: "vertical", children: [_jsx(Radio, { value: "Portfolio doesn't fit", children: t('rfp|matchRejectModal.reasons.portfolioFit') }), _jsx(Radio, { value: 'Company size', children: t('rfp|matchRejectModal.reasons.companySize') }), _jsx(Radio, { value: 'Intro message', children: t('rfp|matchRejectModal.reasons.introMessage') }), _jsx(Radio, { value: 'Localisation', children: t('rfp|matchRejectModal.reasons.localisation') }), _jsx(Radio, { value: 'Other', children: t('rfp|matchRejectModal.reasons.other') })] }) }) }), _jsx(Form.Item, { name: "refusalReasonSpecified", children: _jsx(TextArea, { allowClear: true, placeholder: t('rfp|matchRejectModal.reasons.pleaseSpecify'), onChange: handleChange }) }), _jsx(Button, { onClick: handleFinish, size: 'large', children: t('button.refuseProvider') })] })] }) }));
};
export default ProviderRefuseModal;
