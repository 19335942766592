import { ProviderSearchResult } from '@hellodarwin/core/lib/features/entities';
import styled from '@hellodarwin/core/lib/plugins/styled';
import { createFontSizeCSS } from '@hellodarwin/core/lib/theme/global-style';
import Table from 'antd/es/table/Table';

export const ProvidersTable = styled(Table<ProviderSearchResult>)`
  .ant-table {
    border-radius: 0;
  }
  .ant-table-thead {
    & > tr {
      & > th {
        &:before {
          display: none;
        }
        padding: 10px 24px;
        ${createFontSizeCSS('overline')}
        font-weight:500;
        text-transform: uppercase;
        background: ${({ theme }) => theme.colors.white_1};
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey_4};
      }
    }
  }
  .ant-table-tbody {
    ${createFontSizeCSS('body3')}
    & > .ant-table-row {
      & > .ant-table-cell-row-hover {
        background: ${({ theme }) => theme.colors.purple_5};
      }
      & > td {
        padding: 12px 24px;
        &.actions-column {
          padding: 0;
          & > * {
            margin: auto;
          }
        }
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey_4};
      }
      &:last-child {
        & > td {
          border-bottom: none;
        }
      }
    }
  }
`;
