import styled from '../../../../../plugins/styled';
import { createFontSizeCSS } from '../../../../../theme/global-style';
export const SummaryBoxContent = styled.div `
  padding-inline-start: calc(16px + 8px);
  ul {
    padding-inline-start: 0;
  }
`;
export const SummaryBoxContainer = styled.div `
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-inline: 32px;
  padding-block: 24px;

  ul {
    margin: 0;
    li {
      margin-block-start: 0;
      margin-block-end: 8px;
      padding-inline-start: 0;
      ${createFontSizeCSS('body2')}
    }
  }
`;
export const SummaryBoxesList = styled.div `
  display: flex;
  flex-direction: row;
  padding: 0 48px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey_4};
  gap: 24px;
  & > * {
    padding-inline-start: 0;
    padding-inline-end: 24px;
    border-right: 1px solid ${({ theme }) => theme.colors.grey_4};
    &:last-child {
      border: none;
      padding-inline-end: 0;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    gap: 0px;
    padding: 0;
    & > * {
      border-right: none;
      border-bottom: 1px solid ${({ theme }) => theme.colors.grey_4};

      padding-inline: 32px;
      padding-block: 24px;

      &:last-child {
        border: none;
      }
    }
  }
`;
