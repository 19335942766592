import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import parse from '../../../../../features/helpers/parse';
import GrantSteps from '../../grant-steps';
import { GrantSingleSectionContent, grantSingleParseOptions, } from '../../styles';
const GrantRegister = ({ grant_steps, steps_how_to_apply, }) => {
    if (!grant_steps && !steps_how_to_apply)
        return _jsx(_Fragment, {});
    if (!grant_steps)
        return (_jsx(GrantSingleSectionContent, { children: parse(steps_how_to_apply, grantSingleParseOptions) }));
    return _jsx(GrantSteps, { grantSteps: grant_steps });
};
export default GrantRegister;
