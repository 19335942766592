import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import HTMLReactParser, { attributesToProps, domToReact, } from 'html-react-parser';
import addLineBreak from '../../../features/helpers/add-line-break';
import Typography from '../typography';
const SeeMoreText = ({ text, limit, elementTheme, color, }) => {
    const parseOptions = {
        replace: (domNode) => {
            if (domNode.attribs && domNode.name === 'a') {
                const props = attributesToProps(domNode.attribs);
                return (_jsx("a", Object.assign({}, props, { rel: "nofollow", target: "_blank", children: domToReact(domNode.children) })));
            }
            if (domNode.attribs && domNode.name === 'img') {
                return _jsx(_Fragment, {});
            }
        },
    };
    return (_jsx(Typography, { elementTheme: elementTheme, color: color, style: { columnCount: 1, columnGap: 32 }, tablet: { style: { columnCount: 1 } }, children: HTMLReactParser(addLineBreak(text), parseOptions) }));
};
export default SeeMoreText;
