import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useMemo } from 'react';
import isBrowser from '../helpers/is-browser';
export const LocationContext = React.createContext({
    pathname: '',
    search: '',
    navigate: function (to, options) {
        throw new Error('Navigate function not implemented.');
    },
});
const LocationProvider = ({ children, LinkElement, navigate, pathname, search, }) => {
    const location = useMemo(() => ({
        pathname: !!pathname
            ? pathname
            : isBrowser
                ? window.location.pathname
                : '',
        search: !!pathname ? search : isBrowser ? window.location.search : '',
    }), [pathname, search]);
    const handleNavigate = (to, options) => {
        if (!!navigate) {
            navigate(to, options);
        }
        else if (isBrowser) {
            window.location.href = to.toString();
        }
    };
    return (_jsx(LocationContext.Provider, { value: {
            LinkElement,
            navigate: handleNavigate,
            pathname: location.pathname,
            search: location.search,
        }, children: children }));
};
export const useLocation = () => useContext(LocationContext);
export const useNavigate = () => useContext(LocationContext).navigate;
export default LocationProvider;
