import styled, { css, keyframes } from 'styled-components';
import { ButtonContainer } from '../../../../common/button/styles';
const ActiveStyling = css `
  border: 1px solid ${({ theme }) => theme.colors.grey_1};
  border-width: 0 0 1px 1px;
  border-bottom-left-radius: 16px;
  background: ${({ theme }) => theme.colors.beige_4};
`;
const growAndShake = keyframes `
0% {
      transform: scale(0.8);
    }
    10% {
      transform: scale(1.1);
      opacity: 1;
    }
    20% {
      transform: scale(1);
    }

    /* First shake */
    25% {
      transform: translateX(-3px) rotate(10deg);
    }
    30% {
      transform: translateX(3px) rotate(-10deg);
    }
    35% {
      transform: translateX(-2px) rotate(5deg);
    }
    40% {
      transform: translateX(2px) rotate(-5deg);
    }
    45% {
      transform: translateX(0) rotate(0deg);
    }

    /* Second shake */
    50% {
      transform: translateX(-3px) rotate(10deg);
    }
    55% {
      transform: translateX(3px) rotate(-10deg);
    }
    60% {
      transform: translateX(-2px) rotate(5deg);
    }
    65% {
      transform: translateX(2px) rotate(-5deg);
    }
    70% {
      transform: translateX(0) rotate(0deg);
    }

    /* Third shake */
    75% {
      transform: translateX(-3px) rotate(10deg);
    }
    80% {
      transform: translateX(3px) rotate(-10deg);
    }
    85% {
      transform: translateX(-2px) rotate(5deg);
    }
    90% {
      transform: translateX(2px) rotate(-5deg);
    }
    95% {
      transform: translateX(0) rotate(0deg);
    }
    100% {
      transform: scale(1);
    }
      `;
const AnimationStyling = css `
  ${ButtonContainer} {
    animation: ${growAndShake} 1.5s ease-out;
  }
`;
export const GrantNotificationButtonContainer = styled.div `
  ${({ $active }) => ($active ? ActiveStyling : undefined)}
  width:320px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  padding-inline-start: 16px;
  position: absolute;
  top: 0;
  right: 0;
  ${({ $animate }) => ($animate ? AnimationStyling : undefined)}
`;
