import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import { useMemo, useState } from 'react';
import ProfileBlocks from '../../../../features/enums/profile-blocks';
import useMediaQuery from '../../../../features/helpers/use-media-query';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import SeeMoreList from '../../../common/see-more/see-more-list';
import ProfileSection from '../profile-section';
import ProfileEmptySection from '../profile-section/empty-state';
import PortfolioCard from './portfolio-card';
import { PortfolioCardContainer, PortfolioLogoContainer, } from './portfolio-card/styles';
import PortfolioModal from './portfolio-modal';
const ProfilePortfolio = ({ portfolios, isEditable = false, toggleEditModal, id, providerName, }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    const viewLimit = isTablet ? 3 : 6;
    const [previewPortfolio, setPreviewPortfolio] = useState(undefined);
    const [modalOpen, setModalOpen] = useState(false);
    const handleModal = () => setModalOpen(!modalOpen);
    const handleEditModal = (portfolio) => {
        if (isEditable && toggleEditModal) {
            toggleEditModal({
                open: true,
                targetId: portfolio === null || portfolio === void 0 ? void 0 : portfolio.provider_portfolio_id,
                type: ProfileBlocks.Portfolios,
            });
        }
        else {
            setPreviewPortfolio(portfolio);
            handleModal();
        }
    };
    const AddPortfolioCard = () => {
        return (_jsx(PortfolioCardContainer, { hoverable: true, onClick: () => handleEditModal(), children: _jsx(PortfolioLogoContainer, { children: _jsx(AddNew, { width: 64, height: 64, color: theme.colors.white_1 }) }) }));
    };
    const content = useMemo(() => {
        const content = [];
        if (isEditable) {
            content.push(_jsx(AddPortfolioCard, {}));
        }
        portfolios.forEach((portfolio) => {
            content.push(_jsx(PortfolioCard, { portfolio: portfolio, onClick: handleEditModal }));
        });
        return content;
    }, [portfolios, isEditable]);
    return (_jsxs(_Fragment, { children: [_jsx(ProfileSection, { title: t('profile_portfolio|portfolioProfileTitle', {
                    companyName: providerName,
                }), id: id, level: 2, isEditable: isEditable, handleEdit: () => handleEditModal(), hide: !portfolios.length, emptyState: _jsx(ProfileEmptySection, { title: t('profile_portfolio|portfolioEmptyTitle'), subtitle: t('profile_portfolio|portfolioEmptySubtitle'), cta: t('profile_portfolio|portfolioEmptyCta'), sectionName: "Portfolio", handleClick: handleEditModal, isEditable: isEditable }), children: _jsx(SeeMoreList, { limit: viewLimit, grid: { sm: 1, md: 1, lg: 2, column: 3, gutter: 24 }, content: content }) }), !isEditable && previewPortfolio && (_jsx(PortfolioModal, { open: modalOpen, handleCancel: handleModal, portfolio: previewPortfolio }))] }));
};
export default ProfilePortfolio;
