import SeeMoreSidebarBlock from '@hellodarwin/core/lib/components/common/layout/sidebar-page-layout.tsx/see-more-sidebar-block';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Empty from 'antd/es/empty';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  fetchGinContacts,
  selectGinContacts,
  selectGinContactsIsLoading,
} from '../../../features/api/slices/gin-contacts-slice';
import {
  fetchGrantProvidersByGrantId,
  selectGrantProviders,
} from '../../../features/api/slices/grants-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';
import { ContactCard } from '../../../pages/gin/single-grant/contact-card';
import GinContactDrawer from './related-contacts-drawer';
interface GinRelatedContactsProps {
  grantId: string;
  open: boolean;
  onClose: () => void;
}

const GinRelatedContacts = ({
  grantId,
  open,
  onClose,
}: GinRelatedContactsProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { selectedLocale } = useLocale();
  const [selectedProvider, setSelectedProvider] = useState<string>('');
  const ginContacts = useAppSelector(selectGinContacts);
  const isLoading = useAppSelector(selectGinContactsIsLoading);
  const providers = useAppSelector(selectGrantProviders);
  const { t } = useTranslations();

  useEffect(() => {
    dispatch(fetchGinContacts({ api, grantId }));
    dispatch(
      fetchGrantProvidersByGrantId({
        api,
        locale: selectedLocale,
        grantId: grantId,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantId]);

  useEffect(() => {
    if (!!providers && providers.length > 0) {
      setSelectedProvider(providers[0].value);
    }
  }, [providers]);

  return (
    <>
      <SeeMoreSidebarBlock
        items={ginContacts}
        isLoading={isLoading}
        render={(contact, index) => (
          <ContactCard key={index} contact={contact} grantId={grantId} />
        )}
        title={t('gin_program|mandated_agents')}
        fallback={
          <Empty
            imageStyle={{ width: 100, height: 100, marginInline: 'auto' }}
            style={{ marginInline: 'auto' }}
            description={
              <Typography elementTheme="body3" medium>
                {t('gin_program|no_related_contact')}
              </Typography>
            }
          />
        }
        searchableFields={['first_name', 'last_name', 'email']}
      />
      <GinContactDrawer
        isAddAgentVisible={open}
        onClose={onClose}
        providers={providers}
        selectedProvider={selectedProvider}
        setSelectedProvider={setSelectedProvider}
        ginContacts={ginContacts}
        isLoading={isLoading}
        grantId={grantId}
      />
    </>
  );
};

export default GinRelatedContacts;
