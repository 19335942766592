import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { CompanyInitialState } from '../entities/companies-entities';
import { ProfileInitialState } from '../entities/profile-entities';
import { ProviderInitialState } from '../entities/providers-entities';
export const CompanyInfosContext = React.createContext({
    company: CompanyInitialState,
    contacts: [],
    provider: ProviderInitialState,
    profile: ProfileInitialState,
});
export const CompanyInfosProvider = ({ children, company = CompanyInitialState, contacts = [], provider = ProviderInitialState, profile = ProfileInitialState, }) => {
    return (_jsx(CompanyInfosContext.Provider, { value: { company, contacts, provider, profile }, children: children }));
};
export const useCompanyInfos = () => useContext(CompanyInfosContext);
export default CompanyInfosProvider;
