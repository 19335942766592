var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Tooltip from 'antd/es/tooltip';
import { useMemo } from 'react';
import { useAppData } from '../../../../features/providers/app-data-provider';
import useHandleLinkClick from '../utils/use-handle-link-click';
import NavigationMenuLabelWrapper from './menu-item-label-wrapper';
import { NavigationMenuItemStyled } from './styles';
import SubitemsListDropdown from './subitems-list-dropdown';
const NavigationMenuItem = (_a) => {
    var { selectedKeys, setSelectedKeys, handleLinkClickCallback } = _a, props = __rest(_a, ["selectedKeys", "setSelectedKeys", "handleLinkClickCallback"]);
    const { subLinks, app, pathname, label } = props;
    const { navCollapsed } = useAppData();
    const handleLinkClick = useHandleLinkClick({
        selectedKeys,
        handleLinkClickCallback,
    });
    const handleListitemsOpen = () => {
        !!setSelectedKeys &&
            setSelectedKeys((prevSelectedKeys) => [...prevSelectedKeys, pathname]);
    };
    const handleListItemsClose = () => {
        !!setSelectedKeys &&
            setSelectedKeys((prevSelectedKeys) => prevSelectedKeys.filter((key) => key !== pathname));
    };
    const listItemsOpen = useMemo(() => !!(selectedKeys === null || selectedKeys === void 0 ? void 0 : selectedKeys.includes(pathname)), [selectedKeys, pathname]);
    const handleListItemsOpening = () => {
        if (listItemsOpen) {
            handleListItemsClose();
        }
        else {
            handleListitemsOpen();
        }
    };
    return (_jsxs(NavigationMenuItemStyled, { "$isCollapsed": navCollapsed, onMouseEnter: navCollapsed ? handleListitemsOpen : undefined, children: [navCollapsed && !(subLinks === null || subLinks === void 0 ? void 0 : subLinks.length) ? (_jsx(Tooltip, { title: label, placement: "right", children: _jsx("div", { children: _jsx(NavigationMenuLabelWrapper, Object.assign({}, props, { handleListItemsOpening: handleListItemsOpening, listItemsOpen: listItemsOpen, handleLinkClick: handleLinkClick, isCollapsed: navCollapsed })) }) })) : (_jsx(NavigationMenuLabelWrapper, Object.assign({}, props, { handleListItemsOpening: handleListItemsOpening, listItemsOpen: listItemsOpen, handleLinkClick: handleLinkClick, isCollapsed: navCollapsed }))), !!subLinks && (_jsx(SubitemsListDropdown, { style: { width: '100%' }, isCollapsed: navCollapsed, handleDropdownMouseLeave: handleListItemsClose, isActive: listItemsOpen, items: subLinks, app: app, isDropdown: navCollapsed, parentItem: props }))] }));
};
export default NavigationMenuItem;
