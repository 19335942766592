import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import filterFiles from '../../../../features/helpers/filter-files';
import Div from '../../../common/div';
import RowSelector from '../../../common/row-selector';
import Typography from '../../../common/typography';
import FilesListBreadcrumb from './files-list-breadcrumb';
import FilesListOrderBySelect from './files-list-order-by';
import FilesListSortBySelect from './files-list-sort-by';
const FilesListHeader = (props) => {
    const { items, setSortedItems, isRow, setIsRow, vaultPath, title, records, handleNavigateToFolder, } = props;
    const [sortType, setSortType] = useState('recent');
    const [orderType, setOrderType] = useState('descending');
    useEffect(() => {
        setSortedItems([...filterFiles(items, sortType, orderType)]);
    }, [sortType]);
    useEffect(() => {
        setSortedItems([...filterFiles(items, sortType, orderType)]);
    }, [orderType]);
    return (_jsxs(Div, { flex: "column", gap: 8, children: [!!title && _jsx(Typography, { elementTheme: "subtitle2", children: title }), _jsxs(Div, { flex: "row", align: "center", wrap: "wrap", justify: "space-between", gap: 8, tablet: { flex: 'column', gap: 8, align: 'flex-start' }, children: [_jsx(FilesListBreadcrumb, { vaultPath: vaultPath, records: records, handleNavigateToFolder: handleNavigateToFolder }), _jsxs(Div, { flex: "row", gap: 16, fitContent: true, align: "center", wrap: "wrap", tablet: {
                            flex: 'column-reverse',
                            gap: 8,
                            align: 'flex-start',
                            fitContent: false,
                        }, children: [_jsx(RowSelector, { isRow: isRow, setIsRow: setIsRow }), _jsxs(Div, { flex: "row", gap: 16, fitContent: true, align: "center", wrap: "wrap", tablet: { gap: 8, align: 'flex-start' }, children: [_jsx(FilesListSortBySelect, { onChange: setSortType, value: sortType }), _jsx(FilesListOrderBySelect, { onChange: setOrderType, value: orderType })] })] })] })] }));
};
export default FilesListHeader;
