import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
const ReviewNavigation = ({ handleSubmit, handleNext, handlePrev, isSubmit, }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    const handlePrimary = async () => {
        if (isSubmit && !!handleSubmit) {
            handleSubmit();
        }
        else {
            handleNext();
        }
    };
    return (_jsxs(Div, { flex: 'row', justify: 'center', gap: 16, className: 'review-navigation', style: { marginTop: 24 }, children: [!!handlePrev && (_jsx(Button, { onClick: handlePrev, defaultStyle: theme.colors.white_1, withArrowLeft: true, size: "medium", children: t('navigation|previous') })), _jsx(Button, { onClick: (e) => {
                    e.preventDefault();
                    handlePrimary();
                }, defaultStyle: theme.colors.grey_1, withArrowRight: !isSubmit, size: "medium", children: isSubmit ? t('navigation|submit') : t('navigation|next') })] }));
};
export default ReviewNavigation;
