import { useTheme } from '../../plugins/styled';
import { ProgramGrantStatus } from '../entities/programs-entities';
import RfpStatus from '../enums/rfp-status';
export const getProgramStatusColor = (status) => {
    const theme = useTheme();
    const colors = {
        Completed: theme.colors.green_1,
        Active: theme.colors.white_1,
        Rejected: theme.colors.red_1,
        Canceled: theme.colors.red_1,
    };
    return colors[status];
};
export const getMilestoneStatusColor = (status) => {
    const theme = useTheme();
    const colors = {
        Completed: theme.colors.green_1,
        Scheduled: theme.colors.grey_2,
        ToSchedule: theme.colors.yellow_1,
    };
    return colors[status];
};
export const getRfpStatusColor = (status, theme) => {
    switch (status) {
        case RfpStatus.PendingApproval:
            return theme.colors.yellow_4;
        case RfpStatus.Active:
            return theme.colors.orange_4;
        case RfpStatus.FilterCandidates:
            return theme.colors.blue_4;
        case RfpStatus.EvaluateFinalists:
            return theme.colors.purple_4;
        case RfpStatus.Completed:
            return theme.colors.green_4;
        case RfpStatus.Canceled:
            return theme.colors.grey_5;
        case RfpStatus.Rejected:
            return theme.colors.red_5;
        default:
            return '';
    }
};
export const getProgramGrantStatusColor = (status, theme) => {
    switch (status) {
        case ProgramGrantStatus.ValidationRequired:
            return theme.colors.yellow_4;
        case ProgramGrantStatus.IdentifiedOpportunities:
            return theme.colors.orange_4;
        case ProgramGrantStatus.OngoingWorkByHelloDarwin:
        case ProgramGrantStatus.OngoingInformationRequired:
            return theme.colors.blue_4;
        case ProgramGrantStatus.CompletedToSubmit:
            return theme.colors.purple_4;
        case ProgramGrantStatus.Accepted:
            return theme.colors.green_4;
        case ProgramGrantStatus.Refused:
            return theme.colors.grey_5;
        default:
            return '';
    }
};
