import styled from 'styled-components';
export const ReviewRatingsContainer = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: ${({ $textPlacement }) => $textPlacement === 'end' ? 'row-reverse' : 'row'};
  flex-wrap: wrap;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: ${({ $textPlacement }) => $textPlacement === 'end' ? 'column-reverse' : 'column'};
  }
`;
