import { View } from '@react-pdf/renderer';
import theme from '../../theme';
import Typography from '../common/typography';

interface TitleWithDescriptionCardProps {
  title: string;
  description: string;
  header?: {
    bgColor: string;
    textColor: string;
  };
  isWrapped?: boolean;
}

const TitleWithDescriptionCard: React.FC<TitleWithDescriptionCardProps> = ({
  title,
  description,
  header = {
    textColor: theme.colors.white_1,
    bgColor: theme.colors.purple_2,
  },
  isWrapped,
}) => {
  const { bgColor: headerBgColor, textColor: headerTextColor } = header;

  return (
    <View
      style={{
        width: isWrapped ? `40vw` : undefined,
        flex: !isWrapped ? 1 : undefined,
        backgroundColor: theme.colors.white_1,
        border: `1px solid ${theme.colors.grey_1}`,
        height: isWrapped ? '40vh' : undefined,
      }}
    >
      <View
        style={{
          backgroundColor: headerBgColor,
          color: theme.colors.white_1,
          padding: '4px 8px',
          height: 36,
          display: 'flex',
          alignItems: 'center',
          borderBottom: `1px solid ${theme.colors.grey_1}`,
          justifyContent: 'center',
        }}
      >
        <Typography
          elementTheme="overline"
          color={headerTextColor}
          textAlign="left"
        >
          {title}
        </Typography>
      </View>
      <View style={{ padding: 8 }}>
        <Typography elementTheme="body4" textAlign="left" parseHtml>
          {description}
        </Typography>
      </View>
    </View>
  );
};

export default TitleWithDescriptionCard;
