import { jsx as _jsx } from "react/jsx-runtime";
import ProfileBlocks from '../../../../features/enums/profile-blocks';
import useMediaQuery from '../../../../features/helpers/use-media-query';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import SeeMoreText from '../../../common/see-more/see-more-text';
import ProfileSection from '../profile-section';
import ProfileEmptySection from '../profile-section/empty-state';
const maxDescLengthMobile = 200;
const maxDescLength = 500;
const ProfileAbout = ({ provider, isEditable = false, id, toggleEditModal, }) => {
    var _a;
    const { t } = useTranslations();
    const theme = useTheme();
    const handleEditionModal = () => !!toggleEditModal &&
        toggleEditModal({ open: true, type: ProfileBlocks.About });
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    const descLength = isTablet ? maxDescLengthMobile : maxDescLength;
    return (_jsx(ProfileSection, { title: t('profile_about|aboutTitle', {
            companyName: provider === null || provider === void 0 ? void 0 : provider.name,
        }), isEditable: isEditable, handleEdit: handleEditionModal, id: id, level: 2, hide: !((_a = provider === null || provider === void 0 ? void 0 : provider.description) === null || _a === void 0 ? void 0 : _a.length), emptyState: _jsx(ProfileEmptySection, { title: t('profile_about|aboutEmptyTitle'), subtitle: t('profile_about|aboutEmptySubtitle'), sectionName: "About", cta: t('profile_about|aboutEmptyCta'), handleClick: handleEditionModal, isEditable: isEditable }), children: _jsx(SeeMoreText, { text: (provider === null || provider === void 0 ? void 0 : provider.description) || '', limit: descLength, elementTheme: "body1", color: theme.colors.grey_1 }) }));
};
export default ProfileAbout;
