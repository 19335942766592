import styled from 'styled-components';
import { SpinSize } from '.';

const SizesTheme: {
  [name: string]: {
    parentSize: number;
    childSize: number;
    border: number;
  };
} = {
  ['extra-large']: {
    parentSize: 92,
    childSize: 72,
    border: 12,
  },
  ['large']: {
    parentSize: 46,
    childSize: 36,
    border: 6,
  },
  ['small']: {
    parentSize: 24,
    childSize: 22,
    border: 2,
  },
  ['medium']: {
    parentSize: 32,
    childSize: 26,
    border: 4,
  },
};

export const SpinContainer = styled.div<{ $color: string; $size: SpinSize }>`
  color: ${({ $color }) => $color};

  display: inline-block;
  position: relative;
  width: ${({ $size }) => SizesTheme[$size].parentSize}px;
  height: ${({ $size }) => SizesTheme[$size].parentSize}px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ $size }) => SizesTheme[$size].childSize}px;
    height: ${({ $size }) => SizesTheme[$size].childSize}px;
    margin: ${({ $size }) => SizesTheme[$size].border}px;
    border: ${({ $size }) => SizesTheme[$size].border}px solid currentColor;
    border-radius: 50%;
    animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
