import Button from '@hellodarwin/core/lib/components/common/button';
import SidebarPageLayout, {
  OnSidebarChangeFunc,
} from '@hellodarwin/core/lib/components/common/layout/sidebar-page-layout.tsx';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import theme from '@hellodarwin/core/lib/theme';
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import { Modal } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app';
import GinApplicationSingle from '../../components/gins/application-single';
import { GinApplicationSinglePageSection } from '../../components/gins/application-single/use-gin-application-blocks';
import ApplicationCompanyCard from '../../components/gins/sidebars/cards/company-card';
import ApplicationContactCard from '../../components/gins/sidebars/cards/contact-card';
import ApplicationProgramCard from '../../components/gins/sidebars/cards/program-card';
import ProgramContactsModal from '../../components/gins/sidebars/cards/program-contacts-modal';
import RelatedApplicationsList from '../../components/gins/sidebars/related-applications';
import {
  fetchGinApplicationCompany,
  fetchGinApplicationContacts,
  selectGinApplication,
  selectGinApplicationCompany,
  selectGinApplicationContacts,
  selectGinProgramContactsModal,
  toggleProgramContactsModal,
} from '../../features/api/slices/gins-slice';
import { deleteProgram } from '../../features/api/slices/milestones-slice';
import { useAdminApi, useNewAdminApi } from '../../features/api/use-admin-api';
import { useAdminSettings } from '../../features/providers/admin-settings-provider';

const SingleProgramPage = () => {
  const { t } = useTranslations();
  const { programId } = useParams<{ programId: string }>();
  const dispatch = useAppDispatch();
  const oldApi = useAdminApi();
  const api = useNewAdminApi();
  const application = useAppSelector(selectGinApplication);
  const programContactsModal = useAppSelector(selectGinProgramContactsModal);
  const programContacts = useAppSelector(selectGinApplicationContacts);
  const company = useAppSelector(selectGinApplicationCompany);
  const navigate = useNavigate();
  const handleProgramContactsModalOpen = () => {
    dispatch(toggleProgramContactsModal({ isVisible: true }));
  };

  const { getSetting, updateSetting } = useAdminSettings();
  const sidebarSetting = getSetting('applicationSidebarsCollapsed');

  useEffect(() => {
    if (programId) {
      dispatch(fetchGinApplicationCompany({ api, applicationId: programId }));
      dispatch(fetchGinApplicationContacts({ api, applicationId: programId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId]);

  const sections: GinApplicationSinglePageSection[] = [
    GinApplicationSinglePageSection.ReasonForRefusal,
    GinApplicationSinglePageSection.GrantSteps,
    GinApplicationSinglePageSection.Notes,
    GinApplicationSinglePageSection.ImportantDate,
    GinApplicationSinglePageSection.Files,
    GinApplicationSinglePageSection.ProductionStages,
  ];
  const handleBack = () => {
    navigate(`/programs`);
  };

  const onSidebarChange: OnSidebarChangeFunc = (side, value) => {
    updateSetting('applicationSidebarsCollapsed', {
      ...sidebarSetting,
      [side]: value,
    });
  };

  const handleDeleteProgram = () => {
    Modal.confirm({
      title: 'Supprimer application ?',
      content:
        'Êtes-vous sûr que vous voulez supprimer cette application ? Ceci est irréversible',
      async onOk() {
        dispatch(
          deleteProgram({
            api: oldApi,
            programId: programId || '',
          }),
        );
      },
      onCancel() {},
    });
  };

  return (
    <SidebarPageLayout
      title={application?.application_name}
      app="admin"
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: t('application_list|applications'),
          path: '/programs',
        },
        {
          breadcrumbName: application?.application_name,
        },
      ]}
      actions={
        <Button
          size="square"
          defaultStyle={theme.colors.white_1}
          headingIcon={<Delete size={20} />}
          onClick={handleDeleteProgram}
        />
      }
      rightSidebarBlocks={[
        {
          title: t('application_single|application_company'),
          content: <ApplicationCompanyCard company={company} />,
        },
        {
          title: t('application_single|application_contact'),
          extra: (
            <Button
              onClick={handleProgramContactsModalOpen}
              headingIcon={<Edit size={16} />}
              transparent
              defaultStyle={theme.colors.purple_2}
            />
          ),
          content: <ApplicationContactCard programContacts={programContacts} />,
        },
        {
          title: t('application_single|application_program'),
          content: <ApplicationProgramCard applicationId={programId ?? ''} />,
        },
      ]}
      leftSidebarBlocks={[
        {
          title: t('application_single|other_company_applications'),
          content: <RelatedApplicationsList applicationId={programId ?? ''} />,
        },
      ]}
      defaultLeftSidebarCollapsed={sidebarSetting.left}
      defaultRightSidebarCollapsed={sidebarSetting.right}
      defaultNavCollapsed
      noFooter
      handleBack={handleBack}
      onSidebarChange={onSidebarChange}
    >
      <ProgramContactsModal
        isVisible={programContactsModal.isVisible}
        handleClose={() =>
          dispatch(
            toggleProgramContactsModal({
              isVisible: false,
            }),
          )
        }
        applicationId={programId || ''}
        programContacts={programContacts}
        companyId={company?.company_id || ''}
      />
      <GinApplicationSingle
        applicationId={programId ?? ''}
        sections={sections}
      />
    </SidebarPageLayout>
  );
};
export default SingleProgramPage;
