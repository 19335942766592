import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import getFileIcon from './get-file-icon';
import isAssetFolder from './is-asset-folder';
const useGetAssetMeta = ({ getAssetActions }) => {
    const { t } = useTranslation();
    return useMemo(() => {
        return (asset) => {
            const isFolder = isAssetFolder(asset);
            const actions = getAssetActions(asset);
            const Icon = getFileIcon(asset);
            if (isFolder) {
                const { record_name, record_type, asset_details, created_at, updated_at, record_id, } = asset;
                return {
                    id: record_id,
                    name: record_name,
                    created_at,
                    updated_at,
                    size: t(`assets|assetCard.nbFiles`, {
                        count: asset_details ? asset_details.length : 0,
                    }),
                    type: t(`assets|recordType.${record_type}`),
                    actions,
                    Icon,
                    isFolder,
                    original_asset: asset,
                };
            }
            else {
                const { asset_id, created_at, display_name, file_size, extension, uploaded_at, version, last_uploaded_by, hidden, } = asset;
                const meta = {
                    id: asset_id,
                    name: display_name,
                    created_at: created_at,
                    updated_at: uploaded_at,
                    size: file_size,
                    type: extension === null || extension === void 0 ? void 0 : extension.replace('.', ''),
                    actions,
                    Icon,
                    isFolder,
                    original_asset: asset,
                    version,
                    last_uploaded_by,
                    hidden: hidden,
                };
                return meta;
            }
        };
    }, [getAssetActions, t]);
};
export default useGetAssetMeta;
