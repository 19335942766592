import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, } from 'react';
const defaultContext = {
    functions: undefined,
    handleFooter: () => { },
    footer: null,
};
export const ProfileEditionContext = createContext(defaultContext);
const ProfileEditionProvider = ({ children, functions, }) => {
    const [footer, setFooter] = useState(null);
    return (_jsx(ProfileEditionContext.Provider, { value: { functions, handleFooter: setFooter, footer }, children: children }));
};
export const useProfileEdition = () => useContext(ProfileEditionContext);
export default ProfileEditionProvider;
