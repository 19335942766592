import { jsx as _jsx } from "react/jsx-runtime";
import Drawer from 'antd/es/drawer';
import { useMediaQuery } from 'react-responsive';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import theme from '../../../../../theme';
import FilterDropdownDesktopDrawer from './filter-dropdown-desktop-drawer';
const FiltersDropdownMediaWrapper = ({ children, isOpen, handleClose }) => {
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    const { t } = useTranslations();
    return isTablet ? (_jsx(Drawer, { title: t(`grantsFundingExplorer|grantsfundingExplorerFilterAdvancedFilters`), open: isOpen, onClose: handleClose, styles: { wrapper: { width: '100vw' } }, children: children })) : (_jsx(FilterDropdownDesktopDrawer, { open: isOpen, handleClose: handleClose, children: children }));
};
export default FiltersDropdownMediaWrapper;
