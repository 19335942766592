import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '../../../plugins/styled';
import Div from '../div';
import Typography from '../typography';
const CardInfoRowWithLabel = ({ Icon, label, value }) => {
    const theme = useTheme();
    if (!(label === null || label === void 0 ? void 0 : label.length))
        return _jsx(_Fragment, {});
    return (_jsxs(Div, { flex: "column", gap: 2, children: [_jsxs(Div, { flex: "row", gap: 8, children: [_jsx(Icon, { size: 12, style: { flexShrink: 0 }, color: theme.colors.grey_2 }), _jsx(Typography, { elementTheme: "overline", color: theme.colors.grey_2, children: label })] }), _jsx(Typography, { style: { paddingLeft: 20 }, elementTheme: "body2", children: value })] }));
};
export default CardInfoRowWithLabel;
