import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Divider from 'antd/es/divider';
import React, { useEffect, useMemo, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ItemsTypes, } from '../../../../../features/entities/form-entities';
import theme from '../../../../../theme';
import Div from '../../../../common/div';
import { DragDropContainer } from '../../styles';
import FormGroupContent from '../group';
import FormPageContentButtons from './form-page-content-buttons';
import FormPageHeader from './form-page-content-header';
const FormBuilderPage = ({ groups, id, isFirstPage, functions, formPageResponse, pages, locale, }) => {
    var _a, _b;
    const isEndPage = (_a = formPageResponse === null || formPageResponse === void 0 ? void 0 : formPageResponse.page_type) === null || _a === void 0 ? void 0 : _a.includes('end');
    const isOpeningPage = (_b = formPageResponse === null || formPageResponse === void 0 ? void 0 : formPageResponse.page_type) === null || _b === void 0 ? void 0 : _b.includes('opening');
    const [formGroups, setFormGroups] = useState(groups);
    useEffect(() => {
        setFormGroups(groups);
    }, [groups]);
    const groupsList = useMemo(() => !!formGroups
        ? Object.values(formGroups).sort((group1, group2) => group1.entity.group_order - group2.entity.group_order)
        : [], [formGroups]);
    return (_jsxs(Div, { borderColor: theme.colors.primary, backgroundColor: theme.colors.white_1, flex: isEndPage ? 'row' : 'column', children: [_jsx(FormPageHeader, { page: formPageResponse, isEndPage: isEndPage, isOpeningPage: isOpeningPage, formPageId: id, functions: functions, locale: locale }), _jsx("div", { style: {
                    padding: 24,
                    borderTop: !isEndPage
                        ? `1px solid ${theme.colors.grey_4}`
                        : undefined,
                    borderBottom: !isEndPage
                        ? `1px solid ${theme.colors.grey_4}`
                        : undefined,
                    borderLeft: isEndPage
                        ? `1px solid ${theme.colors.grey_4}`
                        : undefined,
                    flex: 1,
                }, children: _jsx(Droppable, { droppableId: `page_droppable_${id}`, type: ItemsTypes.groups, children: (provided) => (_jsxs(DragDropContainer, Object.assign({ "$isDroppable": true, style: {
                            minHeight: 100,
                        } }, provided.droppableProps, { ref: provided.innerRef, children: [React.Children.toArray(groupsList.map((builderGroup, index) => (_jsx(Draggable, { draggableId: `group_draggable_${builderGroup.id}`, index: index, children: (provided) => {
                                    return (_jsxs("div", Object.assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { children: [_jsx(DragDropContainer, { "$isMovable": true, children: _jsx(FormGroupContent, { builderGroup: builderGroup, formPageId: formPageResponse.form_page_id, pages: pages, functions: functions, locale: locale }) }), index < groupsList.length - 1 && (_jsx(Divider, { style: {
                                                    margin: '32px 0',
                                                    borderColor: theme.colors.primary,
                                                } }))] })));
                                } }, `group_draggable_${builderGroup.id}`)))), provided.placeholder] }))) }) }), _jsx(FormPageContentButtons, { isFirstPage: isFirstPage, isEndPage: isEndPage, isOpeningPage: isOpeningPage })] }));
};
export default FormBuilderPage;
