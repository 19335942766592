import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Image from 'antd/es/image';
import { PortfolioAssetsType, } from '../../../../../../../features/entities';
import { useTranslation } from '../../../../../../../plugins/i18n';
import { useTheme } from '../../../../../../../plugins/styled';
import VideoPlayer from '../../../../../../common/VideoPlayer';
import Button from '../../../../../../common/button';
import Div from '../../../../../../common/div';
import { ModalConfirm } from '../../../../../../common/hd-modal/confirm';
import Loading from '../../../../../../loading';
import { AssetOverlay } from './styles';
const PortfolioAssetCard = ({ asset, deleteAsset, loading, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const getUrl = () => {
        if (!!asset.file) {
            if (typeof asset.file === 'string') {
                return asset.file;
            }
            else if ((asset === null || asset === void 0 ? void 0 : asset.file) instanceof Blob) {
                return URL.createObjectURL(asset.file);
            }
            else {
                const file = asset.file;
                return URL.createObjectURL(file);
            }
        }
        else {
            return asset.asset_url || '';
        }
    };
    return (_jsx(Div, { backgroundColor: theme.colors.grey_2, borderRadius: 6, borderColor: theme.colors.grey_4, style: {
            width: 100,
            height: 100,
            overflow: 'hidden',
            position: 'relative',
            cursor: 'pointer',
        }, children: loading ? (_jsx(Loading, {})) : (_jsxs(_Fragment, { children: [asset.asset_type === PortfolioAssetsType.Video ? (typeof window !== 'undefined' && (_jsx(VideoPlayer, { url: getUrl(), width: "100%", height: '100%', controls: true, light: true }))) : (_jsx(Image, { src: getUrl(), preview: false, width: '100%', height: '100%', style: {
                        objectPosition: 'center',
                        objectFit: 'contain',
                    } })), _jsx(AssetOverlay, { children: _jsx(Button, { transparent: true, size: "auto", headingIcon: _jsx(Delete, { width: 20, height: 20 }), onClick: (e) => {
                            e.preventDefault();
                            ModalConfirm({
                                title: t('profile|assetsModal.confirm'),
                                content: t('profile|assetsModal.delete.asset'),
                                okText: t('profile|assetsModal.confirm'),
                                cancelText: t('profile|assetsModal.cancel'),
                                okButtonProps: {
                                    style: {
                                        backgroundColor: theme.colors.purple_1,
                                        color: theme.colors.white_1,
                                        borderRadius: '24px',
                                    },
                                },
                                cancelButtonProps: {
                                    style: {
                                        backgroundColor: theme.colors.white_1,
                                        borderColor: theme.colors.grey_1,
                                        color: theme.colors.grey_1,
                                        borderRadius: '24px',
                                    },
                                },
                                onOk: async () => {
                                    deleteAsset(asset);
                                },
                            });
                        } }) })] })) }));
};
export default PortfolioAssetCard;
