import LoadingScreen from '@hellodarwin/core/lib/components/loading/screen';
import Empty from 'antd/es/empty';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  fetchGinApplication,
  selectGinApplication,
  selectGinsIsLoading,
} from '../../../features/api/slices/gins-slice';
import {
  fetchGrant,
  selectGrantById,
} from '../../../features/api/slices/grants-slice';
import { useNewAdminApi } from '../../../features/api/use-admin-api';
import GinBlock from '../gin-single/gin-block';
import { GinSummaryBlock } from '../gin-single/gin-grant-summary';
import SingleGinHeader from '../gin-single/gin-single-header';
import { GinSingleContainer } from '../gin-single/styles';
import GinApplicationHeaderContent from './application-single-header';
import useGinApplicationBlocks, {
  GinApplicationSinglePageSection,
} from './use-gin-application-blocks';
import useGinApplicationSummary from './use-gin-application-summary';

interface GinApplicationSingleProps {
  applicationId: string;
  sections: GinApplicationSinglePageSection[];
}

const GinApplicationSingle = ({
  applicationId,
  sections,
}: GinApplicationSingleProps) => {
  const api = useNewAdminApi();
  const dispatch = useAppDispatch();

  const blocks = useGinApplicationBlocks();
  const application = useAppSelector(selectGinApplication);
  const grant = useAppSelector((state) =>
    selectGrantById(state, application?.application_program_grant_id ?? ''),
  );
  const isLoading = useAppSelector((state) =>
    selectGinsIsLoading(state, 'applicationSingle'),
  );

  useEffect(() => {
    if (!applicationId) return;
    dispatch(fetchGinApplication({ api, applicationId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (application?.application_program_grant_id) {
      dispatch(
        fetchGrant({ api, grantId: application.application_program_grant_id }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application?.application_program_grant_id]);

  const activeSections = sections.filter(
    (s) => !!blocks[s] && !blocks[s]?.hide,
  );

  const summaryBlocks: GinSummaryBlock[] = useGinApplicationSummary(
    application,
    grant,
  );

  if (!applicationId.length) return <Empty />;
  if (isLoading) return <LoadingScreen />;
  return (
    <GinSingleContainer>
      <SingleGinHeader
        HeaderContent={GinApplicationHeaderContent}
        blocks={summaryBlocks}
        activeSections={activeSections}
      />

      {React.Children.toArray(
        activeSections.map((s) => (
          <GinBlock
            {...blocks[s]}
            entityId={applicationId}
            grantId={application.application_program_grant_id}
            type="application"
            additionalOwners={[
              {
                owner_type: 'company',
                owner_id: application.application_company_id,
              },
              {
                owner_type: 'contact',
                owner_id: application.application_contact_id,
              },
            ]}
          />
        )),
      )}
    </GinSingleContainer>
  );
};

export default GinApplicationSingle;
