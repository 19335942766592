import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ProfileBlocks from '../../../../features/enums/profile-blocks';
import { useTranslations } from '../../../../features/providers/translations-provider';
import VideoPlayer from '../../../common/VideoPlayer';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import ProfileSection from '../profile-section';
import ProfileEmptySection from '../profile-section/empty-state';
import { VideoWrapper, VideoWrapperContainer } from '../styles';
const ProfileVideo = ({ provider, isEditable, isWebsite, toggleEditModal, id, }) => {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslations();
    const handleEdit = () => !!toggleEditModal &&
        toggleEditModal({ open: true, type: ProfileBlocks.Presentation });
    const url = ((_b = (_a = provider.assets) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.asset_url) || '';
    const title = ((_d = (_c = provider.assets) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.asset_title) || '';
    return (_jsx(ProfileSection, { title: t('profile_video|videoTitle'), isEditable: isEditable, handleEdit: handleEdit, id: id, hide: !((_e = provider.assets) === null || _e === void 0 ? void 0 : _e[0]), emptyState: _jsx(ProfileEmptySection, { title: t('profile_video|videoEmptyTitle'), subtitle: t('profile_video|videoEmptySubtitle'), cta: t('profile_video|videoEmptyCta'), sectionName: "Video", handleClick: handleEdit, isEditable: isEditable }), children: _jsx(VideoWrapperContainer, { className: isWebsite ? 'full-width' : undefined, children: _jsxs(VideoWrapper, { children: [_jsx(Div, { className: "video", children: !!url.length && typeof window !== 'undefined' && (_jsx(VideoPlayer, { url: url, controls: true, light: true, playing: true })) }), _jsx(Typography, { elementTheme: "body1", bold: true, className: "video-title", children: title })] }) }) }));
};
export default ProfileVideo;
