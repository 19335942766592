import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HdLogo from '@hellodarwin/icons/dist/icons/HdLogo';
import Industry from '@hellodarwin/icons/dist/icons/Industry';
import Service from '@hellodarwin/icons/dist/icons/Service';
import React, { useEffect, useState } from 'react';
import getShortId from '../../../../../features/helpers/get-short-id';
import parse from '../../../../../features/helpers/parse';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { useTranslation } from '../../../../../plugins/i18n';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import HdTag from '../../../../common/hd-tag';
import Typography from '../../../../common/typography';
import Gallery from './Gallery';
import { PortfolioPreviewInfoContainer } from './styles';
const PortfolioPreview = ({ portfolio }) => {
    const { t } = useTranslations();
    const { t: translateTag } = useTranslation(['tags'], { useSuspense: false });
    const theme = useTheme();
    const [assets, setAssets] = useState([]);
    useEffect(() => {
        var _a;
        if (!!((_a = portfolio.assets) === null || _a === void 0 ? void 0 : _a.length)) {
            setAssets([...portfolio.assets]);
        }
    }, [portfolio]);
    const Header = () => (_jsx(Div, { flex: "row", gap: 38, tablet: { flex: 'column' }, style: { padding: 40 }, children: _jsx(Typography, { elementTheme: "h5", style: { minWidth: 353 }, children: portfolio.portfolio_title ||
                portfolio.client_company_name ||
                translateTag(`tags|${portfolio.service}`) ||
                `Portfolio ${getShortId(portfolio.provider_portfolio_id)}` }) }));
    const Infos = () => {
        const InfoTile = ({ category, value, Icon, }) => (_jsx(PortfolioPreviewInfoContainer, { style: {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '16px 40px',
            }, children: _jsxs(Div, { flex: "row", gap: 8, style: { maxWidth: 184 }, children: [_jsx(Icon, { width: 13, height: 13 }), _jsxs(Div, { style: { marginTop: 2 }, children: [_jsx(Typography, { elementTheme: "overline", color: theme.colors.grey_2, children: category }), _jsx(Typography, { elementTheme: "body2", children: value })] })] }) }));
        return (_jsxs(Div, { flex: "row", borderColor: theme.colors.grey_4, bordersSide: "horizontal", children: [_jsx(InfoTile, { category: t('profile_portfolio|portfolioLabelsServiceReceived'), value: translateTag(`tags|${portfolio.service}`), Icon: Service }), _jsx(InfoTile, { category: t('profile_portfolio|portfolioLabelsIndustry'), value: translateTag(`tags|${portfolio.category}`), Icon: Industry })] }));
    };
    const RfpDescription = () => (_jsxs(Div, { style: { padding: '0 35px', overflow: 'hidden' }, flex: "column", gap: 26, children: [_jsx(Typography, { elementTheme: "h6", children: t('profile_portfolio|rfpDescription') }), _jsx(Typography, { elementTheme: "body2", style: { columnCount: 2, columnGap: 32 }, tablet: { style: { columnCount: 1 } }, children: parse((portfolio === null || portfolio === void 0 ? void 0 : portfolio.description) || '') })] }));
    const RfpTags = () => (_jsxs(Div, { style: { padding: '0 35px', overflow: 'hidden' }, flex: "column", gap: 20, children: [_jsx(Typography, { elementTheme: "overline", textTransform: "uppercase", children: t('profile_portfolio|rfpTags') }), _jsx(Div, { flex: "row", wrap: "wrap", gap: 8, style: { maxWidth: 458 }, children: React.Children.toArray(portfolio.tags.map((tag) => (_jsx(HdTag, { color: theme.colors.purple_5, text: translateTag(`tags|${tag}`) })))) })] }));
    return (_jsxs(Div, { style: { maxHeight: '80vh', overflowY: 'scroll' }, children: [_jsx(Header, {}), _jsxs(Div, { flex: "column", gap: 35, style: { marginBottom: 45 }, children: [_jsx(Infos, {}), !!assets.length && _jsx(Gallery, Object.assign({}, portfolio)), !!portfolio.description && _jsx(RfpDescription, {}), !!portfolio.tags && _jsx(RfpTags, {}), !!portfolio.from_hellodarwin_at && (_jsxs(Div, { flex: "row", align: "center", style: { padding: '6px 27px', paddingLeft: 45 }, gap: 6, backgroundColor: theme.colors.yellow_1, fitContent: true, children: [_jsx(Typography, { elementTheme: "body2", inline: true, children: t('profile_portfolio|madeWith') }), _jsx(HdLogo, { width: 125, height: 15, color: "b/w", backgroundType: "light", style: { marginBottom: 4 } })] }))] })] }));
};
export default PortfolioPreview;
