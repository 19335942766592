import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
const StepNumberHeader = ({ stepNumber, isCompleted, isActive, isMobile, }) => {
    const theme = useTheme();
    const getBackgroundColor = () => {
        if (isCompleted) {
            return theme.colors.grey_4;
        }
        if (isActive) {
            return theme.colors.purple_2;
        }
        return theme.colors.beige_1;
    };
    const size = isMobile ? '24px' : '40px';
    return (_jsx(Div, { backgroundColor: getBackgroundColor(), style: {
            width: size,
            height: size,
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${theme.colors.grey_1}`,
            flexShrink: 0,
            overflow: 'hidden',
        }, children: _jsx(Typography, { color: isActive ? theme.colors.white_1 : theme.colors.grey_1, elementTheme: isMobile ? 'h6' : 'h5', textAlign: "center", children: stepNumber }) }));
};
export default StepNumberHeader;
