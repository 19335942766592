var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Dots from '@hellodarwin/icons/dist/icons/Dots';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import Button from '../button';
import Typography from '../typography';
import { DropdownContainer, DropdownStyled } from './styles';
const Dropdown = (_a) => {
    var { items, dropdownRender, placement, cta, ctaRender, menuStyle, overlayClassName, styles, overlayStyle } = _a, props = __rest(_a, ["items", "dropdownRender", "placement", "cta", "ctaRender", "menuStyle", "overlayClassName", "styles", "overlayStyle"]);
    const theme = useTheme();
    const ctaDefaultRender = (cta) => {
        var _a, _b;
        const buttonSize = (_a = cta === null || cta === void 0 ? void 0 : cta.size) !== null && _a !== void 0 ? _a : 16;
        const buttonColor = (_b = cta === null || cta === void 0 ? void 0 : cta.color) !== null && _b !== void 0 ? _b : theme.colors.grey_2;
        const buttonText = cta === null || cta === void 0 ? void 0 : cta.text;
        return (_jsxs(Button, { transparent: true, size: 'square', style: cta.style, children: [!!buttonText && _jsx(Typography, { children: buttonText }), _jsx(Dots, { color: buttonColor, size: buttonSize })] }));
    };
    const defaultDropdownRender = (el) => (_jsx(DropdownContainer, { style: Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.dropdown), children: el }));
    const ButtonRender = useMemo(() => (!!ctaRender ? ctaRender : ctaDefaultRender), [ctaRender]);
    const currentDropdownRender = useMemo(() => (!!dropdownRender ? dropdownRender : defaultDropdownRender), [dropdownRender]);
    return (_jsx(DropdownStyled, Object.assign({ menu: { items, style: Object.assign(Object.assign({}, menuStyle), styles === null || styles === void 0 ? void 0 : styles.menu) }, dropdownRender: currentDropdownRender, placement: placement, overlayClassName: overlayClassName, overlayStyle: Object.assign(Object.assign({}, overlayStyle), styles === null || styles === void 0 ? void 0 : styles.overlay) }, props, { children: _jsx("div", { style: Object.assign({ width: 'fit-content' }, styles === null || styles === void 0 ? void 0 : styles.container), children: _jsx(ButtonRender, Object.assign({}, cta, { style: Object.assign(Object.assign({}, cta === null || cta === void 0 ? void 0 : cta.style), styles === null || styles === void 0 ? void 0 : styles.cta) })) }) })));
};
export default Dropdown;
