import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Container from '../../../common/container';
import Div from '../../../common/div';
import ProgramEmptyStateContent from './program-empty-state-content';
import ProgramEmptyStateFooter from './program-empty-state-footer';
import ProgramEmptyStateHeader from './program-empty-state-header';
const ProgramEmptyState = () => {
    return (_jsx(Container, { style: { paddingTop: 54, paddingBottom: 40 }, children: _jsxs(Div, { fitContent: true, flex: "column", gap: 50, align: "center", style: { margin: 'auto' }, tablet: { style: { width: '100%' } }, children: [_jsx(ProgramEmptyStateHeader, {}), _jsx(ProgramEmptyStateContent, {}), _jsx(ProgramEmptyStateFooter, {})] }) }));
};
export default ProgramEmptyState;
