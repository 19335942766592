import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import TabArrowIcon from '@hellodarwin/icons/dist/icons/TabArrow';
import { useEffect, useState } from 'react';
import parseWithoutListIndent from '../../../../../features/helpers/parse-without-list-indent';
import useLocale from '../../../../../features/providers/locale-provider';
import { useTheme } from '../../../../../plugins/styled';
import Collapse from '../../../../common/Collapse';
import Div from '../../../../common/div';
import Modal from '../../../../common/hd-modal';
import Typography from '../../../../common/typography';
import StepValidationModal from './step-validation-modal';
import StepNumberHeader from './step_number_header';
import { GrantStepContainer, SquareCheckbox } from './styles';
const GrantStepSingle = ({ step, isCompleted, isActive, isMobile, isImmediatePreviousStep, onChangeStepCheckBox, canStepsCollapse, }) => {
    const { selectedLocale } = useLocale();
    const theme = useTheme();
    const [title, setTitle] = useState();
    const [description, setDescription] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const toggleCollapse = () => {
        setIsOpen((prev) => !prev);
    };
    useEffect(() => {
        if (selectedLocale === 'fr') {
            !!step.title_fr && setTitle(step.title_fr);
            !!step.description_fr && setDescription(step.description_fr);
        }
        else {
            !!step.title_en && setTitle(step.title_en);
            !!step.description_en && setDescription(step.description_en);
        }
    }, [selectedLocale]);
    const handleConfirmModal = () => {
        onChangeStepCheckBox(step.grant_step_id);
        setModalOpen(false);
    };
    const displayContent = () => {
        return isMobile ? (_jsxs(GrantStepContainer, { isCompleted: isCompleted, isActive: isActive, isMobile: isMobile, canHover: !!onChangeStepCheckBox, onClick: () => !!onChangeStepCheckBox &&
                (isActive
                    ? onChangeStepCheckBox(step.grant_step_id)
                    : setModalOpen(true)), children: [_jsxs(Div, { flex: "row", style: { justifyContent: 'space-between', alignItems: 'center' }, gap: 4, children: [_jsx(StepNumberHeader, { stepNumber: step.display_order + 1, isCompleted: isCompleted, isActive: isActive, isMobile: isMobile }), _jsx(Typography.Title, { level: 2, elementTheme: "h6", textAlign: "center", style: { display: 'block' }, children: title }), !!onChangeStepCheckBox ? (_jsx(SquareCheckbox, { isActive: isActive, checked: isCompleted, style: { transform: 'scale(1.5)', borderRadius: '4px' } })) : (_jsx(Div, { style: { width: '0px' } }))] }), _jsx(Div, { children: _jsx(Typography, { elementTheme: "body1", style: {
                            whiteSpace: 'normal',
                            overflowWrap: 'anywhere',
                        }, children: parseWithoutListIndent(description) }) })] })) : (_jsxs(GrantStepContainer, { isCompleted: isCompleted, isActive: isActive, isMobile: isMobile, canHover: !!onChangeStepCheckBox, onClick: () => !!onChangeStepCheckBox &&
                (isActive
                    ? onChangeStepCheckBox(step.grant_step_id)
                    : setModalOpen(true)), children: [_jsx(StepNumberHeader, { stepNumber: step.display_order + 1, isCompleted: isCompleted, isActive: isActive, isMobile: isMobile }), _jsxs(Div, { flex: "column", children: [_jsxs(Div, { flex: "row", style: { justifyContent: 'space-between', alignItems: 'center' }, children: [_jsx(Typography.Title, { level: 2, elementTheme: "h6", textAlign: "center", children: title }), !!onChangeStepCheckBox ? (_jsx(SquareCheckbox, { isActive: isActive, checked: isCompleted, style: { transform: 'scale(2)', borderRadius: '4px' } })) : (_jsx(_Fragment, {}))] }), _jsx(Typography, { elementTheme: "body1", style: { paddingRight: '24px' }, children: parseWithoutListIndent(description) })] })] }));
    };
    const collapseItems = [
        {
            id: step.grant_step_id,
            title: (_jsxs(Div, { flex: "row", style: { justifyContent: 'space-between', alignItems: 'center' }, children: [_jsx(StepNumberHeader, { stepNumber: step.display_order + 1, isCompleted: isCompleted, isActive: isActive, isMobile: isMobile }), _jsx(Typography.Title, { level: 2, elementTheme: "subtitle1", textAlign: "center", children: title }), _jsx(SquareCheckbox, { isActive: isActive, checked: isCompleted, style: { transform: 'scale(1.5)', borderRadius: '4px' }, onClick: () => setIsOpen(!isOpen), onChange: () => isActive
                            ? onChangeStepCheckBox(step.grant_step_id)
                            : setModalOpen(true) })] })),
            children: (_jsx(Typography, { elementTheme: "body1", style: { paddingTop: '16px' }, children: parseWithoutListIndent(description) })),
        },
    ];
    const displayCollapseContent = () => {
        return (_jsx(GrantStepContainer, { isCompleted: isCompleted, isActive: isActive, isMobile: false, canCollapse: true, canHover: !!onChangeStepCheckBox, onClick: toggleCollapse, style: { cursor: 'pointer' }, children: _jsx(Collapse, { activeKey: isOpen ? [step.grant_step_id] : [], items: collapseItems, expandIconPosition: "end", style: { width: '100%' }, ExpandIcon: ({ isActive }) => (_jsx(TabArrowIcon, { size: 16, color: theme.colors.grey_1, down: isActive, onClick: toggleCollapse })), ghost: true, noPadding: true }) }));
    };
    return (_jsxs(_Fragment, { children: [canStepsCollapse ? displayCollapseContent() : displayContent(), _jsx(Modal, { open: isModalOpen, handleCancel: () => {
                    setModalOpen(false);
                }, children: _jsx(StepValidationModal, { onClose: () => setModalOpen(false), onConfirm: !!onChangeStepCheckBox && handleConfirmModal, isValidatingStep: !isCompleted, isSingle: isImmediatePreviousStep }) })] }));
};
export default GrantStepSingle;
