'use client';

import Spin, { SpinProps } from '../common/spin';

interface LoadingProps extends SpinProps {}

const Loading = ({ size, ...props }: LoadingProps) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flex: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spin size={size || 'large'} {...props} />
    </div>
  );
};

export default Loading;
