import Div from '@hellodarwin/core/lib/components/common/div';
import { AssetOwnerInformation } from '@hellodarwin/core/lib/features/entities';
import parse from '@hellodarwin/core/lib/features/helpers/parse';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { IconNode } from '@hellodarwin/icons/dist/features/entities/general';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { ginEditorParseOptions } from '../gin-editor';
import { GinSectionContainer } from '../styles';
import GinBlockHeader from './gin-block-header';

export type GinBlockComponentType = 'program' | 'application' | 'company';
export type GinBlockContentType = 'gin' | 'grant-content';
export interface GinBlockComponentProps {
  isAdding?: boolean;
  toggleIsAdding?: () => void;
  prepareHandleSave: HandleSaveFuncStateDispatch;
  handleEditing: () => void;
  isToggle?: boolean;
  handleToggle?: () => void;
  entityId: string;
  grantId?: string;
  section: string;
  isEditing: boolean;
  type: GinBlockComponentType;
  contentType?: GinBlockContentType;
  additionalOwners?: AssetOwnerInformation[];
  isHiddenActions?: boolean;
  editingLocale?: string;
  canGenerateGameplan?: boolean;
  editCanGenerateGameplan?: (value: boolean) => void;
}

interface ActionButtonConfig {
  label?: string;
  Icon?: IconNode;
}
interface ButtonTexts {
  edit?: ActionButtonConfig;
  cancel?: ActionButtonConfig;
  save?: ActionButtonConfig;
  add?: ActionButtonConfig;
}

export interface GinBlockConfig {
  Content: (props: GinBlockComponentProps) => ReactNode;
  id: string;
  hide?: boolean;
  isHiddenActions?: boolean;
  isSimpleActions?: boolean;
  noHistory?: boolean;
  hasLocaleToggle?: boolean;
  toggleName?: string;
  buttonTexts?: ButtonTexts;
  contentType?: GinBlockContentType;
  showAddingButton?: boolean;
}

export type HandleSaveFunc = () => void;
export type HandleSaveFuncState = HandleSaveFunc | undefined;
export type HandleSaveFuncStateDispatch = Dispatch<
  SetStateAction<HandleSaveFuncState>
>;

export interface GinBlockProps extends GinBlockConfig {
  entityId: string;
  grantId?: string;
  type: GinBlockComponentType;
  additionalOwners?: AssetOwnerInformation[];
  translationFile?: string;
  canGenerateGameplan?: boolean;
  editCanGenerateGameplan?: (newValue: boolean) => void;
}

const GinBlock = (props: GinBlockProps) => {
  const {
    Content,
    entityId,
    grantId,
    id,
    additionalOwners,
    isSimpleActions,
    contentType,
    canGenerateGameplan,
    editCanGenerateGameplan,
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isToggle, setIsToggle] = useState(false);
  const [editingLocale, setEditingLocale] = useState('');
  const { selectedLocale } = useLocale();

  const [handleSave, prepareHandleSave] =
    useState<HandleSaveFuncState>(undefined);

  const [showHistory, setShowHistory] = useState(false);
  const [content, setContent] = useState<string | undefined>(undefined);
  const handleEditing = () => setIsEditing(!isEditing);
  const handleHistory = () => setShowHistory(!showHistory);
  const handleToggle = () => setIsToggle(!isToggle);
  const onSave = () => {
    if (!!handleSave) {
      handleSave();
    }
    handleEditing();
  };

  const handleLocaleToggle = (isEn: boolean) => {
    if (isEn) {
      setEditingLocale('en');
    } else {
      setEditingLocale('fr');
    }
  };

  useEffect(() => {
    setEditingLocale(selectedLocale);
  }, [selectedLocale]);

  return (
    <GinSectionContainer id={id}>
      <GinBlockHeader
        {...props}
        showAddingButton={props.showAddingButton}
        toggleIsAdding={() => setIsAdding(!isAdding)}
        handleSave={
          isSimpleActions ? handleEditing : !!handleSave ? onSave : undefined
        }
        handleEditing={handleEditing}
        isEditing={isEditing}
        handleHistory={handleHistory}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
        setContent={setContent}
        isToggle={isToggle}
        handleToggle={setIsToggle}
        editingLocale={editingLocale}
        handleLocaleToggle={handleLocaleToggle}
      />
      <div style={{ width: '100%' }}>
        {!showHistory || content === undefined ? (
          <Content
            isAdding={isAdding}
            toggleIsAdding={() => setIsAdding(!isAdding)}
            prepareHandleSave={prepareHandleSave}
            handleEditing={handleEditing}
            entityId={entityId}
            grantId={grantId}
            section={id}
            type={props.type}
            contentType={contentType}
            isEditing={isEditing}
            additionalOwners={additionalOwners}
            isToggle={isToggle}
            handleToggle={handleToggle}
            editingLocale={editingLocale}
            canGenerateGameplan={canGenerateGameplan}
            editCanGenerateGameplan={editCanGenerateGameplan}
          />
        ) : (
          <Div flex="column" gap={8}>
            {parse(content, ginEditorParseOptions)}
          </Div>
        )}
      </div>
    </GinSectionContainer>
  );
};

export default GinBlock;
