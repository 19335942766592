import HdTag from '@hellodarwin/core/lib/components/common/hd-tag';
import SeeMoreSidebarBlock from '@hellodarwin/core/lib/components/common/layout/sidebar-page-layout.tsx/see-more-sidebar-block';
import SeeMoreSidebarModal from '@hellodarwin/core/lib/components/common/layout/sidebar-page-layout.tsx/see-more-sidebar-block/see-more-sidebar-modal';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { GinProgram } from '@hellodarwin/core/lib/features/entities';
import { getProgramGrantStatusColor } from '@hellodarwin/core/lib/features/helpers/get-status-color';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Empty from 'antd/es/empty';
import { SelectProps } from 'antd/es/select';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  fetchGinApplications,
  selectGinsIsLoading,
  selectSelectedGinApplications,
} from '../../../features/api/slices/gins-slice';
import { useNewAdminApi } from '../../../features/api/use-admin-api';
import { ProgramCard } from './cards/application-card';

interface GinRelatedProgramsProps {
  grantId: string;
  modal?: {
    open: boolean;
    handleCancel: () => void;
  };
}

const GinRelatedPrograms = ({ grantId, modal }: GinRelatedProgramsProps) => {
  const api = useNewAdminApi();
  const dispatch = useAppDispatch();
  const { selectedLocale } = useLocale();
  const { t } = useTranslations();
  const theme = useTheme();
  const ginPrograms = useAppSelector(selectSelectedGinApplications);
  const isLoading = useAppSelector((state) =>
    selectGinsIsLoading(state, 'relatedApplications'),
  );

  useEffect(() => {
    dispatch(fetchGinApplications({ api, grantId }));
  }, [api, dispatch, grantId]);

  const statuses: SelectProps['options'] = useMemo(
    () =>
      Array.from(new Set(ginPrograms?.map((p) => p.program_grant_status)))
        .filter((itm) => !!itm)
        .map((status) => ({
          label: (
            <HdTag
              color={getProgramGrantStatusColor(status, theme)}
              withBorder
              text={t(`programs_labels|${status}`)}
              size="small"
              style={{ margin: '8px 0 8px 4px' }}
            />
          ),
          value: status,
        })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ginPrograms],
  );

  const Component = useMemo(
    () => (!!modal ? SeeMoreSidebarModal : SeeMoreSidebarBlock),
    [modal],
  );
  return (
    <Component<GinProgram>
      title={t('gin_program|applications')}
      items={ginPrograms ?? []}
      isLoading={isLoading}
      render={(program, index) => (
        <ProgramCard
          key={index}
          program={program}
          selectedLocale={selectedLocale}
        />
      )}
      statusOptions={statuses}
      statusField="program_grant_status"
      searchableFields={[
        'program_company_name',
        'program_contact_name',
        'program_name',
      ]}
      fallback={
        <Empty
          imageStyle={{ width: 100, height: 100, marginInline: 'auto' }}
          style={{ marginInline: 'auto' }}
          description={
            <Typography elementTheme="body3" medium>
              {t('gin_program|no_related_program')}
            </Typography>
          }
        />
      }
      {...modal}
    />
  );
};

export default GinRelatedPrograms;
