export const InitialStateAssetFolderEntity = {
    record_id: '',
    record_name: '',
    updated_at: undefined,
    created_at: undefined,
    record_type: 'contact',
    asset_details: [],
    mapped_assets: {},
};
export const InitialStateAssetEntity = {
    asset_id: '',
};
