import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import Modal from '../../../common/hd-modal';
import PortfolioPreview from './portfolio-preview';
export var ModalState;
(function (ModalState) {
    ModalState["PortfolioEdit"] = "portfolioEdit";
    ModalState["Empty"] = "";
})(ModalState || (ModalState = {}));
const PortfolioModal = ({ open, handleCancel, portfolio }) => {
    if (!portfolio)
        return _jsx(_Fragment, {});
    return (_jsx(Modal, { open: open, handleCancel: handleCancel, size: "extra-large", noPadding: true, children: _jsx(PortfolioPreview, { portfolio: portfolio }) }));
};
export default PortfolioModal;
