import Div from '@hellodarwin/core/lib/components/common/div';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import useDebounceFunction from '@hellodarwin/core/lib/features/hooks/use-debounce-function';
import { usePagination } from '@hellodarwin/core/lib/features/providers/pagination-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Search from '@hellodarwin/icons/dist/icons/Search';
import Input from 'antd/es/input/Input';
import { ChangeEventHandler, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app';
import ProvidersList from '../components/providers/providers-list.tsx';
import {
  queryProviders,
  selectAllProviders,
  selectProvidersLoading,
  selectProvidersTotal,
} from '../features/api/slices/providers-slice';
import { useAdminApi } from '../features/api/use-admin-api';

const DEBOUNCE_DELAY_MS = 500;
const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 50;

const ProvidersPage = () => {
  const dispatch = useAppDispatch();
  const providers = useAppSelector(selectAllProviders);
  const total = useAppSelector(selectProvidersTotal);
  const isLoading = useAppSelector(selectProvidersLoading);
  const [isInitialised, setIsInitialised] = useState(false);
  const api = useAdminApi();
  const { t } = useTranslations();
  const { pathname } = useLocation();
  const { activePage, pageSize, addParam, removeParam, params, goToPage } =
    usePagination();
  const [query, setQuery] = useState(params?.['query'] ?? '');

  const handleFetch = useDebounceFunction(
    () => {
      dispatch(
        queryProviders({ api, page: activePage, limit: pageSize, query }),
      );

      if (!isInitialised) setIsInitialised(true);
    },
    DEBOUNCE_DELAY_MS,
    true,
  );

  useEffect(() => {
    if (!!params['query'] && params['query'] !== query) {
      setQuery(params.query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, pageSize, pathname]);

  useEffect(() => {
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInitialised) {
      if (!!query.length) {
        addParam('query', query);
      } else {
        removeParam('query');
      }
      if (activePage !== 1) {
        goToPage(DEFAULT_PAGE, DEFAULT_LIMIT);
      } else {
        handleFetch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    setQuery(e.target.value);
  };

  return (
    <PageLayout
      app="admin"
      title={t('provider_page|providers')}
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: t('provider_page|providers'),
        },
      ]}
      actions={
        <Div flex="row" gap={8} align="center" justify="flex-end">
          <Input
            prefix={<Search size={20} />}
            placeholder={t('provider_page|search_provider')}
            size="large"
            style={{ width: 500, maxWidth: '100%', alignSelf: 'flex-end' }}
            value={query}
            onChange={handleSearch}
          />
        </Div>
      }
    >
      <ProvidersList
        providers={providers}
        total={total}
        isLoading={isLoading}
      />
    </PageLayout>
  );
};

export default ProvidersPage;
