import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import { useMemo } from 'react';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import SingleHeaderMenu from '../../../common/single-header-menu';
import Typography from '../../../common/typography';
import ProfileActions from '../profile-actions';
import ProfileBanner from '../profile-assets/profile-banner';
import ProfileLogo from '../profile-assets/profile-logo';
import ReviewsAverageCard from './reviews-average-card';
const LOGO_SIZE = 128;
const ProfileHeader = ({ provider, isEditable, handleRedirectToSettings, sections, blocks, providerActions, websiteCTA, handleLeaveAReview, toggleEditModal, }) => {
    const theme = useTheme();
    const { logo, name, banner, tagline } = provider;
    const region = useMemo(() => {
        const { city, province, country } = provider;
        return [city, province, country]
            .filter((itm) => !!itm)
            .slice(0, 2)
            .join(', ');
    }, [provider]);
    return (_jsxs(Div, { style: { borderRadius: '11px 11px 0 0', overflow: 'hidden' }, borderColor: theme.colors.primary, backgroundColor: theme.colors.white_1, children: [_jsx(ProfileBanner, { banner: !!banner ? `${banner}?${new Date().toString()}` : '', name: name, isEditable: isEditable, toggleEditModal: toggleEditModal }), _jsxs(Div, { style: { paddingBlock: 16, paddingInline: 48, paddingBottom: 32 }, xl: {
                    flex: 'column',
                    style: { paddingInline: 16 },
                    align: 'center',
                }, borderColor: theme.colors.grey_4, bordersSide: "top", flex: "row", gap: 32, position: "relative", justify: "space-between", children: [_jsxs(Div, { flex: "row", gap: 42, xl: { flex: 'column', align: 'center' }, children: [_jsx(ProfileLogo, { style: { marginTop: -(LOGO_SIZE / 3 + 24) }, name: provider.name, logo: !!logo ? `${logo}?${new Date().toString()}` : '', isEditable: isEditable, toggleEditModal: toggleEditModal, size: LOGO_SIZE, type: "provider" }), _jsxs(Div, { flex: "column", gap: 8, children: [_jsxs(Div, { flex: "column", gap: 2, children: [_jsx(Typography.Title, { level: 1, elementTheme: "h4", xl: { textAlign: 'center' }, noMargin: true, children: provider.name }), !!region.length && (_jsx(Typography, { color: theme.colors.grey_2, elementTheme: "body3", xl: { textAlign: 'center' }, children: region }))] }), (!!tagline || isEditable) && (_jsxs(Typography, { elementTheme: "body2", xl: { textAlign: 'center' }, style: { cursor: isEditable ? 'pointer' : 'default' }, onClick: isEditable ? handleRedirectToSettings : undefined, children: [!!tagline.length ? tagline : isEditable ? '<tagline>' : '', isEditable && (_jsx(Edit, { size: 16, onClick: handleRedirectToSettings, style: {
                                                    color: theme.colors.grey_2,
                                                    lineHeight: theme.fontsLineHeights.body2,
                                                    marginInlineStart: 8,
                                                } }))] }))] })] }), _jsxs(Div, { fitContent: true, flex: "row", gap: 24, children: [_jsx("div", { style: { paddingTop: 8 }, children: _jsx(ProfileActions, { providerActions: providerActions, websiteCTA: websiteCTA }) }), !providerActions && (_jsx(ReviewsAverageCard, { average: provider.reviews_average || 0, handleLeaveAReview: handleLeaveAReview, mobileStyle: { position: 'absolute', top: 16, right: 16 } }))] })] }), _jsx(SingleHeaderMenu, { items: sections
                    .filter((key) => !blocks[key].hide)
                    .map((key) => ({
                    label: blocks[key].label,
                    id: key,
                })) })] }));
};
export default ProfileHeader;
