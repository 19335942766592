import styled from '../../../../plugins/styled';
import { createFontSizeCSS } from '../../../../theme/global-style';
export const GrantSectionHeaderContent = styled.div `
  padding: 32px 48px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  @media (max-width: ${(props) => { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.breakpoints) === null || _b === void 0 ? void 0 : _b.tablet; }}px) {
    padding: 24px;
    align-items: flex-start;
    flex-direction: column;
  }
`;
export const GrantSectionContent = styled.div `
  padding-inline: 48px;
  padding-block-end: 40px;
  ${createFontSizeCSS('body1')}
  a {
    text-decoration: none;
  }

  @media (max-width: ${(props) => { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.breakpoints) === null || _b === void 0 ? void 0 : _b.tablet; }}px) {
    padding: 24px;
    padding-top: 0;
  }
`;
export const GrantSectionContainer = styled.div `
  background: ${({ theme }) => theme.colors.white_1};
  border: 1px solid ${({ theme }) => theme.colors.primary};

  &:last-child {
    border-radius: 0px 0px 16px 16px;
  }
`;
