import { jsx as _jsx } from "react/jsx-runtime";
import Accueil from '@hellodarwin/icons/dist/icons/Accueil';
import NotFound from '@hellodarwin/icons/dist/icons/NotFound';
import { useLocation } from '../../features/providers/location-provider';
import { usePlatform } from '../../features/providers/platform-provider';
import { useTranslations } from '../../features/providers/translations-provider';
import Div from '../common/div';
import PageLayout from '../common/layout/page-layout';
import ErrorBox from './error-box';
const ErrorNotFound = () => {
    const { t } = useTranslations();
    const { app } = usePlatform();
    const { navigate } = useLocation();
    const goToHome = () => {
        navigate('/');
    };
    return (_jsx(PageLayout, { tabTitle: "Page not found", app: app, breadcrumbs: [], children: _jsx(Div, { flex: "column", justify: "center", align: "center", style: {
                minHeight: `calc(100vh - 40px - 40px - 32px - 54px - 64px - 40px)`,
            }, children: _jsx(ErrorBox, { errorMessage: t(`errorsPage|NotFound`), ErrorIcon: NotFound, ctaAction: goToHome, CtaIcon: Accueil }) }) }));
};
export default ErrorNotFound;
