import { jsx as _jsx } from "react/jsx-runtime";
import React, { Fragment, useRef } from 'react';
import Typography from '../typography';
import { MenuContainer, MenuContent } from './styles';
const SingleHeaderMenu = ({ items }) => {
    const ref = useRef(null);
    const handleScroll = (e) => {
        const menuItem = e.currentTarget;
        const container = ref.current;
        if (!container)
            return;
        const { offsetWidth: containerWidth, scrollLeft } = container;
        const { clientWidth: menuItemWidth, offsetLeft: menuItemLeft } = menuItem;
        const menuItemRight = menuItemLeft + menuItemWidth;
        const isOverflowingRight = menuItemRight > scrollLeft + containerWidth;
        const isOverflowingLeft = menuItemLeft < scrollLeft;
        if (isOverflowingRight) {
            container.scrollTo({
                left: menuItemRight - containerWidth + 40,
                behavior: 'smooth',
            });
        }
        else if (isOverflowingLeft) {
            container.scrollTo({
                left: menuItemLeft - 40,
                behavior: 'smooth',
            });
        }
    };
    return (_jsx(MenuContainer, { children: _jsx(MenuContent, { ref: ref, children: React.Children.toArray(items.map((s, i) => !!s.label.length ? (_jsx("a", { href: `#${s.id}`, onMouseOver: handleScroll, style: { textDecoration: 'inherit' }, children: _jsx(Typography, { elementTheme: "body2", style: {
                        padding: '8px 24px',
                        cursor: 'pointer',
                    }, nowrap: true, children: s.label }) }, s.label)) : (_jsx(Fragment, {}, `menu-item-${i}`)))) }) }));
};
export default SingleHeaderMenu;
