import { ProjectResponse } from '../../../@types/projects-types';
import { ListItemProps } from '../../../components/common/list/list-item';

const getGrantsOverview = (projects: ProjectResponse[]) => {
  const items: ListItemProps[] = projects.reduce((arr, priority) => {
    arr.push({
      children: priority.title,
      level: 0,
      typography: { elementTheme: 'body3', weight: 'bold' },
      index: 0,
      href: `#${priority.project_id}`,
    });
    priority.programs?.forEach((program) => {
      arr.push({
        children: program.program_name,
        level: 1,
        typography: { elementTheme: 'body3', weight: 'regular' },
        index: 1,
        href: `#${program.program_id}`,
      });
    });
    priority.projects?.forEach((project) => {
      project.programs?.forEach((program) => {
        arr.push({
          children: program.is_subsidiary_program
            ? program.program_name + ' (' + program.parent_company_name + ')'
            : program.program_name,
          level: 1,
          typography: { elementTheme: 'body3', weight: 'regular' },
          index: 2,
          href: `#${program.program_id}`,
        });
      });
    });
    return arr;
  }, [] as ListItemProps[]);

  return items;
};

export default getGrantsOverview;
