import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LanguageIcon from '@hellodarwin/icons/dist/icons/Language';
import { useAppData } from '../../../features/providers/app-data-provider';
import useLocale from '../../../features/providers/locale-provider';
import { useTranslations } from '../../../features/providers/translations-provider';
import Typography from '../../common/typography';
import { LanguageSwitcherStyled } from './styles';
export const SUPPORTED_LANGUAGES = ['fr', 'en'];
const LanguageSwitcher = ({ app, handleLanguageChange, }) => {
    const { t } = useTranslations();
    const { selectedLocale, changeLanguage } = useLocale();
    const { navCollapsed } = useAppData();
    const otherLang = SUPPORTED_LANGUAGES.find((lang) => selectedLocale !== lang);
    const clickHandler = () => {
        if (otherLang) {
            if (handleLanguageChange) {
                handleLanguageChange(otherLang);
            }
            else {
                changeLanguage(otherLang);
            }
        }
    };
    return (_jsxs(LanguageSwitcherStyled, { "$app": app, onClick: clickHandler, children: [_jsx(LanguageIcon, { size: 24 }), _jsx(Typography, { elementTheme: "body3", style: { fontSize: navCollapsed ? 11 : 14 }, textAlign: "center", children: t(`language|switch`) })] }));
};
export default LanguageSwitcher;
