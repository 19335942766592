import Div from '@hellodarwin/core/lib/components/common/div';
import { AdminProfile } from '@hellodarwin/core/lib/features/entities';
import HubspotUserButtonCheck from './hubspot-user-button-check';
type HubspotUserButtonProps = {
  admin: AdminProfile;
};

const HubspotUserButton = ({ admin }: HubspotUserButtonProps) => {
  const hubspotId = admin.hubspot?.crm_id || '';
  const hubspotOwnerId = admin.hubspot_owner?.crm_id || '';

  return (
    <Div flex="row" fitContent gap={8}>
      <HubspotUserButtonCheck crmId={hubspotId} type="Account" />
      <HubspotUserButtonCheck crmId={hubspotOwnerId} type="Owner" />
    </Div>
  );
};

export default HubspotUserButton;
