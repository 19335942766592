import Div from '@hellodarwin/core/lib/components/common/div';
import ProfileLogo from '@hellodarwin/core/lib/components/provider/profile/profile-assets/profile-logo';
import { GrantProvider } from '@hellodarwin/core/lib/features/entities';
import { getShortId } from '@hellodarwin/core/lib/features/helpers';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Table, { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAdminApi } from '../../features/api/use-admin-api';

export type GrantProviderFilterValues = {
  textQuery: string;
};

const GrantProvidersList = () => {
  const api = useAdminApi();
  const { selectedLocale } = useLocale();
  const [filterform] = Form.useForm<GrantProviderFilterValues>();
  const { t } = useTranslations();
  const DEFAULT_PAGE = 1;
  const DEFAULT_LIMIT = 100;

  type PageState = {
    grants: GrantProvider[];
    pagination: {
      page: number;
      size: number;
    };
  };

  useEffect(() => {
    fetch(DEFAULT_PAGE, DEFAULT_LIMIT, false, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageStateInitialValues: PageState = {
    grants: [],
    pagination: {
      page: DEFAULT_PAGE,
      size: DEFAULT_LIMIT,
    },
  };
  const [pageState, setPageState] = useState<PageState>(pageStateInitialValues);
  const [apiState, setApiState] = useState<{
    isLoading: boolean;
    isSearching: boolean;
    isErrored: Error | null;
  }>({
    isLoading: false,
    isSearching: false,
    isErrored: null,
  });

  const fetch = (
    page: number,
    size: number,
    loading: boolean,
    isSearching: boolean,
  ) => {
    (async () => {
      setApiState({
        isLoading: loading,
        isSearching: isSearching,
        isErrored: null,
      });
      try {
        setApiState({ isLoading: true, isSearching: true, isErrored: null });
        const filterValues = filterform.getFieldsValue()!;
        const response = await api.queryGrantProviders(
          selectedLocale,
          page,
          size,
          filterValues?.textQuery ?? '',
        );
        const newPageState = {
          grants: response?.map((grant, index) => ({
            key: index,
            ...grant,
          })),
          pagination: { page: page, size: size },
        };
        setPageState(newPageState);
        setApiState({ isLoading: false, isSearching: false, isErrored: null });
      } catch (e: any) {
        setApiState({ isLoading: false, isSearching: false, isErrored: e });
        console.error(e);
      }
    })();
  };

  const onPageChange = (page: number, size: number) => {
    fetch(page, size, true, false);
  };

  const applyFilter = async () => {
    fetch(DEFAULT_PAGE, DEFAULT_LIMIT, true, false);
  };

  const columns: ColumnsType<any> = [
    {
      title: t('programs_admin|programsProviders'),
      dataIndex: 'grant_provider_id',
      key: 'grant_provider_id',
      render: (v) =>
        v ? (
          <Link target="_blank" to={`/grants/providers/${v}`}>
            {getShortId(v)}
          </Link>
        ) : (
          <></>
        ),
      width: 110,
    },
    {
      title: t('programs_admin|titleProgram'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('programs_admin|file_description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Logo',
      dataIndex: 'logo',
      key: 'logo',
      render: (v, record) => (
        <ProfileLogo
          key={record.grant_provider_id}
          name={record.title}
          logo={v ? v : 'logo'}
          type="grant-provider"
        />
      ),
    },
  ];

  const navigate = useNavigate();
  const handleCreateGrantProvider = () => {
    navigate(`/grants/providers/create`);
  };

  return (
    <Div flex="column" style={{ marginTop: 24 }} gap={24}>
      <Link to={`/grants/providers/create`}>
        <Button onClick={handleCreateGrantProvider}>
          {t('programs_admin|createProgramProvider')}
        </Button>
      </Link>
      <Div>
        <Form form={filterform} style={{ width: '100%' }}>
          <Form.Item name="textQuery">
            <Input
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  applyFilter();
                }
              }}
              placeholder={t('programs_admin|searchProgramProviderPlaceholder')}
            />
          </Form.Item>
        </Form>
        <Button onClick={applyFilter} size="small">
          {t('programs_admin|search')}
        </Button>
      </Div>

      <Table
        dataSource={pageState.grants}
        columns={columns}
        pagination={{
          pageSize: pageState.pagination.size,
          current: pageState.pagination.page,
          total: pageState.grants?.[0]?.total_count,
          showSizeChanger: true,
          onChange: (page, size) => onPageChange(page, size ?? DEFAULT_LIMIT),
        }}
        loading={apiState.isLoading}
      />
    </Div>
  );
};

export default GrantProvidersList;
