import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import { useMemo } from 'react';
import { GinBlockConfig } from '../../../gins/gin-single/gin-block';
import { GinGrantSinglePageSection } from '../../../gins/grant-single/use-gin-grant-blocks';
import BusinessProfileBlock from '../business-profile-block';
import CompanyCommentsEditor from '../comments-editor-block';
import CompanyFilesSection from '../company-files';
import CompanyGrantsBlock from '../company-grants';
import CompanyGamePlanSummaryBlock from '../game-plan-summary';
import CompanyGamePlansBlock from '../game-plans-section';
import CompanyProjectsBlock from '../projects-block';

export enum CompanySinglePageSections {
  BusinessProfile = 'business_profile',
  GamePlans = 'game_plans',
  Comments = 'comments',
  Priorities = 'priorities',
  Files = 'files',
  Grants = 'grants',
  GamePlanSummary = 'game_plan_summary',
}

const useCompanyBlocks = () => {
  const { t } = useTranslations();

  return useMemo(() => {
    const CompanySectionBlocks: { [section: string]: GinBlockConfig } = {
      [CompanySinglePageSections.Comments]: {
        id: CompanySinglePageSections.Comments,
        Content: CompanyCommentsEditor,
        noHistory: true,
      },
      [CompanySinglePageSections.GamePlans]: {
        id: CompanySinglePageSections.GamePlans,
        Content: CompanyGamePlansBlock,
        noHistory: true,
        buttonTexts: {
          edit: { label: t('singleCompanyPage|seeGamePlan'), Icon: NewWindow },
        },
        isSimpleActions: true,
      },
      [CompanySinglePageSections.Priorities]: {
        id: CompanySinglePageSections.Priorities,
        Content: CompanyProjectsBlock,
        noHistory: true,
        buttonTexts: {
          add: { label: t('project|addNewPriority'), Icon: AddNew },
          edit: { label: t('project|editPriorities'), Icon: Edit },
        },
        isSimpleActions: false,
        showAddingButton: true,
      },
      [CompanySinglePageSections.Grants]: {
        id: CompanySinglePageSections.Grants,
        Content: CompanyGrantsBlock,
        noHistory: true,
      },
      [CompanySinglePageSections.BusinessProfile]: {
        id: CompanySinglePageSections.BusinessProfile,
        Content: BusinessProfileBlock,
        noHistory: true,
        isSimpleActions: true,
      },
      [GinGrantSinglePageSection.Files]: {
        id: GinGrantSinglePageSection.Files,
        Content: CompanyFilesSection,
        isSimpleActions: true,
        noHistory: true,
      },
      [CompanySinglePageSections.GamePlanSummary]: {
        id: CompanySinglePageSections.GamePlanSummary,
        Content: CompanyGamePlanSummaryBlock,
        noHistory: true,
      },
    };

    return CompanySectionBlocks;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
};

export default useCompanyBlocks;
