export const InitialFundingExplorerFilterValues = {
    subindustry: [],
    textQuery: '',
    service: [],
    status: [],
    filterBy: '',
    financingType: [],
    industry: [],
    region: [],
    closingDate: undefined,
    annualRevenue: '',
    companySize: '',
    selectedProjects: [],
};
