import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useAppData } from '../../../../features/providers/app-data-provider';
import { useBreadcrumbs } from '../../../../features/providers/breadcrumbs-provider';
import Breadcrumbs from '../../../layout/header/breadcrumbs';
import Div from '../../div';
const DefaultAppHeader = ({ withoutPaddingHorizontal, withoutPaddingRight, withoutPaddingLeft, style, beforeHeader, }) => {
    const { header } = useAppData();
    return (_jsxs("div", { style: Object.assign(Object.assign({}, style), { width: '100%', paddingRight: withoutPaddingHorizontal || withoutPaddingRight ? 60 : 0, paddingLeft: withoutPaddingHorizontal || withoutPaddingLeft ? 60 : 0 }), children: [beforeHeader && _jsx(Div, { children: beforeHeader }), header] }));
};
const AdminAppHeader = ({ withoutPaddingRight, withoutPaddingHorizontal, style, beforeHeader, }) => {
    const theme = useTheme();
    const { header } = useAppData();
    const { breadCrumbs } = useBreadcrumbs();
    if (!header)
        return _jsx(_Fragment, {});
    return (_jsxs("div", { style: Object.assign({ position: 'sticky', zIndex: 100, width: '100%', top: 0, transition: 'left 200ms linear', paddingRight: withoutPaddingHorizontal || withoutPaddingRight ? 60 : 0, paddingLeft: withoutPaddingHorizontal ? 60 : 0 }, style), children: [!!beforeHeader && _jsx("div", { children: beforeHeader }), header, _jsx("div", { style: { borderTop: `1px solid ${theme.colors.grey_5}` } }), !!breadCrumbs.length && (_jsx("div", { style: {
                    backdropFilter: 'blur(4px)',
                    padding: 6,
                    backgroundColor: theme.colors.white_1,
                    width: '100%',
                    borderBottom: `1px solid ${theme.colors.grey_5}`,
                }, children: _jsx(Breadcrumbs, {}) }))] }));
};
const AppHeader = (props) => {
    const { app, breadcrumbs } = props;
    const { setBreadCrumbs } = useBreadcrumbs();
    useEffect(() => {
        setBreadCrumbs(breadcrumbs);
    }, [breadcrumbs]);
    return app === 'admin' ? (_jsx(AdminAppHeader, Object.assign({}, props))) : (_jsx(DefaultAppHeader, Object.assign({}, props)));
};
export default AppHeader;
