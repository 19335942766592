import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import UploadIcon from '@hellodarwin/icons/dist/icons/Upload';
import ImgCrop from 'antd-img-crop';
import Checkbox from 'antd/es/checkbox';
import Form from 'antd/es/form';
import InputNumber from 'antd/es/input-number';
import notification from 'antd/es/notification';
import { useState } from 'react';
import { useTranslation } from '../../../../plugins/i18n';
import { useTheme } from '../../../../plugins/styled';
import Typography from '../../../common/typography';
import validateFile, { getFileTypes } from '../../utils/validate-file';
import StepContainer from './StepContainer';
import { StepContentStyled, UploadStyled } from './styles';
const Uploader = ({ fileList, name, width, height, handleChange, }) => {
    const [isValidFile, setIsValidFile] = useState(true);
    const [isBlackBG, setIsBlackBG] = useState(false);
    const [crop, onCropChange] = useState({ x: 0, y: 0 });
    const [zoomScale, setZoomScale] = useState(0.05);
    const [zoom, onZoomChange] = useState(0);
    const { t } = useTranslation();
    const theme = useTheme();
    const Step1 = () => (_jsx(StepContainer, { label: t('step', { count: 1 }).toUpperCase(), description: t('profile|assetsModal.chooseBg'), children: _jsxs(StepContentStyled, { children: [_jsx("div", { children: _jsx(Checkbox, { checked: isBlackBG, onChange: () => setIsBlackBG(true), children: _jsx(Typography, { elementTheme: "body2", children: t('profile|assetsModal.blackBg') }) }) }), _jsx("div", { children: _jsx(Checkbox, { checked: !isBlackBG, onChange: () => setIsBlackBG(false), children: _jsx(Typography, { elementTheme: "body2", children: t('profile|assetsModal.whiteBg') }) }) }), _jsxs("div", { children: [_jsx(Typography, { elementTheme: "body2", children: t('profile|assetsModal.minZoom') }), _jsx(InputNumber, { value: zoomScale, onChange: (value) => setZoomScale(value !== null && value !== void 0 ? value : 0) })] })] }) }));
    const Step2 = () => {
        const beforeUpload = (file) => {
            const isValid = validateFile(file);
            if (isValid) {
                if (isValidFile) {
                    handleChange([file]);
                }
                else {
                    notification.error({ type: 'error', message: 'Upload failed' });
                }
            }
            setIsValidFile(isValid);
            return file;
        };
        const imgCropProps = {
            quality: 1,
            fillColor: isBlackBG ? 'black' : 'white',
            modalTitle: t('profile|assetsModal.editImage'),
            modalOk: t('profile|assetsModal.crop'),
            modalCancel: t('profile|assetsModal.cancel'),
            minZoom: zoomScale,
            maxZoom: 2,
            showGrid: true,
            modalWidth: 600,
            modalProps: {
                wrapProps: {
                    style: { zIndex: 3100 },
                },
                styles: {
                    mask: { zIndex: 3100 },
                    content: {
                        background: theme.colors.white_1,
                        borderRadius: 8,
                    },
                    header: {
                        fontFamily: 'Figtree',
                    },
                },
            },
            aspect: width / height,
        };
        const uploadProps = {
            className: 'assets-uploader',
            name,
            fileList,
            beforeUpload: beforeUpload,
            maxCount: 1,
            multiple: false,
            onChange: (info) => {
                console.info(info);
            },
            customRequest(options) {
                setTimeout(() => {
                    if (options.onSuccess) {
                        options.onSuccess('ok', new XMLHttpRequest()), 0;
                    }
                }, 500);
            },
        };
        return (_jsx(StepContainer, { label: t('step', { count: 2 }).toUpperCase(), description: t('profile|assetsModal.deposit', {
                type: t(`provider-profile|assets.${name}`),
            }), children: _jsx(ImgCrop, Object.assign({}, imgCropProps, { children: _jsxs(UploadStyled, Object.assign({}, uploadProps, { children: [_jsx(UploadIcon, { width: 100, height: 100 }), _jsxs("div", { style: { display: 'flex', flexDirection: 'column', gap: 6 }, children: [_jsx(Typography, { elementTheme: "body3", textAlign: "center", color: theme.colors.grey_2, children: t('upload.resolution', {
                                        width: width,
                                        height: height,
                                    }) }), _jsxs(Typography, { elementTheme: "body3", textAlign: "center", color: theme.colors.grey_2, children: [' ', t('upload.fileSize')] }), _jsx(Typography, { elementTheme: "body3", textAlign: "center", color: theme.colors.grey_2, children: t('upload.allowedFile', {
                                        types: getFileTypes().join(', '),
                                    }) })] })] })) })) }));
    };
    return (_jsxs("div", { style: {
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        }, children: [_jsx(Step1, {}), _jsx(Step2, {})] }));
};
export default Uploader;
Uploader.Demo = () => {
    const [fileList, setFileList] = useState([]);
    const handleUploadChange = (newFileList) => {
        setFileList(newFileList);
    };
    return (_jsx(Form, { layout: "vertical", children: _jsx(Uploader, { fileList: fileList, name: 'logo', width: 200, height: 200, handleChange: handleUploadChange }) }));
};
