import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { ScrollingContainer, ScrollingContent } from './styles';
const DynamicScroller = ({ children, speed = 20, inversed = false, }) => {
    const items = useMemo(() => React.Children.toArray(children), [children]);
    if (!items.length)
        return _jsx(_Fragment, {});
    return (_jsx(ScrollingContainer, { children: _jsx(ScrollingContent, { "$speed": speed, "$inversed": inversed, children: React.Children.toArray([...items, ...items].map((itm) => _jsx(_Fragment, { children: itm }))) }) }));
};
export default DynamicScroller;
