import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Divider from 'antd/es/divider';
import Form from 'antd/es/form';
import React, { useEffect } from 'react';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import FormInput from '../form-input';
import FormCurrentPageHeader from './form-current-page-header';
const FormCurrentPage = ({ formResponse, currentPage, handleClose, formData, handleNext, setForms, isEndPage, isOpening, viewerType, formAssetList, setFormAssetList, }) => {
    var _a, _b, _c;
    const theme = useTheme();
    const [form] = Form.useForm();
    useEffect(() => {
        setForms((prevState) => (Object.assign(Object.assign({}, prevState), { dataForm: form })));
    }, [form, setForms]);
    if (((_a = formResponse === null || formResponse === void 0 ? void 0 : formResponse.pages) === null || _a === void 0 ? void 0 : _a.length) === 0)
        return null;
    return (_jsx(Form, { form: form, layout: "vertical", initialValues: formData, onFinish: handleNext, name: currentPage.page_title, children: _jsxs(Div, { flex: "column", gap: 32, children: [_jsx(FormCurrentPageHeader, { page: currentPage, handleClose: handleClose, form_type: formResponse.form_type, isEndPage: isEndPage, viewerType: viewerType, isOpening: isOpening }), !!((_b = currentPage === null || currentPage === void 0 ? void 0 : currentPage.groups) === null || _b === void 0 ? void 0 : _b.length) && (_jsx(Div, { flex: "column", align: "center", style: { width: '100%' }, children: React.Children.toArray((_c = currentPage.groups) === null || _c === void 0 ? void 0 : _c.map((group, index) => {
                        var _a;
                        return (_jsxs(_Fragment, { children: [_jsxs(Div, { flex: "column", gap: 16, children: [!!group.group_title && (_jsx(Typography, { elementTheme: "subtitle2", children: group.group_title })), _jsx(Div, { flex: group.group_disposition === 'vertical'
                                                ? 'column'
                                                : 'row', gap: group.group_disposition === 'vertical' ? 16 : 32, children: React.Children.toArray(group.items.map((item) => (_jsx(FormInput, { formItem: item, formAssetList: formAssetList, setFormAssetList: setFormAssetList })))) })] }), index < ((_a = currentPage === null || currentPage === void 0 ? void 0 : currentPage.groups) === null || _a === void 0 ? void 0 : _a.length) - 1 && (_jsx(Divider, { style: {
                                        borderColor: theme.colors.grey_3,
                                        margin: '16px 0',
                                    } }))] }));
                    })) }))] }) }));
};
export default FormCurrentPage;
