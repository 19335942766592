import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Collapse as ReactCollapse } from 'react-collapse';
import useMediaQuery from '../../../features/helpers/use-media-query';
import { useTheme } from '../../../plugins/styled';
import Div from '../div';
import Typography from '../typography';
import { CollapsePanelBody, CollapsePanelContainer, CollapsePanelHeader, } from './styles';
const CollapseHeader = (props) => {
    const { children, title, styles, expandIconPosition = 'center', handleClickButtonOnly, id, onClick, headerIsColumnOnTablet, } = props;
    return (_jsxs(CollapsePanelHeader, { "$expandIconPosition": expandIconPosition, "$headerIsColumnOnTablet": headerIsColumnOnTablet, onClick: !handleClickButtonOnly ? () => onClick(id) : undefined, style: Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.header), children: [typeof title === 'string' ? (_jsx(Typography, { elementTheme: "subtitle2", children: title })) : (title), !!children && !['bottom', 'top'].includes(expandIconPosition) && (_jsx(ExpandButton, Object.assign({}, props)))] }));
};
const DefaultExpandIcon = ({ isActive, showArrow = true, }) => {
    return showArrow ? (isActive ? (_jsxs("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { width: "24", height: "24", rx: "12", fill: "#2B2B2B" }), _jsx("path", { d: "M17.5381 12L6.46115 12", stroke: "white", strokeWidth: "1.4", strokeLinecap: "round" })] })) : (_jsxs("svg", { width: "26", height: "24", viewBox: "0 0 26 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { x: "0.451294", width: "24.7742", height: "24", rx: "12", fill: "#2B2B2B" }), _jsx("path", { d: "M12.9181 6.46167L12.9181 17.5386", stroke: "white", strokeWidth: "1.4", strokeLinecap: "round" }), _jsx("path", { d: "M18.5558 12.0002L7.12156 12.0002", stroke: "white", strokeWidth: "1.4", strokeLinecap: "round" })] }))) : (_jsx(_Fragment, {}));
};
const ExpandButton = (props) => {
    const { expandIconPosition, ExpandIcon, onClick, id, extra } = props;
    return (_jsxs(Div, { flex: "row", gap: 8, align: "center", fitContent: true, style: { flexShrink: 0 }, children: [extra, _jsx("div", { style: {
                    width: ['top', 'bottom'].includes(expandIconPosition !== null && expandIconPosition !== void 0 ? expandIconPosition : 'center')
                        ? '100%'
                        : 'fit-content',
                    cursor: 'pointer',
                    flexShrink: 0,
                }, onClick: (e) => {
                    e.stopPropagation();
                    onClick(id);
                }, children: !!ExpandIcon ? (_jsx(ExpandIcon, Object.assign({}, props))) : (_jsx(DefaultExpandIcon, Object.assign({}, props))) })] }));
};
const CollapsePanel = (props) => {
    const theme = useTheme();
    const { children, ghost, styles, align = 'start', isActive, hasGap, expandIconPosition = 'end', expandIconPositionTablet, noPadding, } = props;
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    const iconPosition = isTablet && expandIconPositionTablet
        ? expandIconPositionTablet
        : expandIconPosition;
    return (_jsxs(CollapsePanelContainer, { "$isOpened": isActive, "$noPadding": noPadding, "$ghost": ghost, "$align": align, "$hasGap": hasGap, style: Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.container), children: [!!children && ['top'].includes(iconPosition) && (_jsx("div", { style: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }, children: _jsx(ExpandButton, Object.assign({}, props, { expandIconPosition: iconPosition })) })), _jsx(CollapseHeader, Object.assign({}, props, { expandIconPosition: iconPosition })), !!children && (_jsx(ReactCollapse, { isOpened: !!isActive, children: _jsx(CollapsePanelBody, { style: Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.content), children: children }) })), !!children && ['bottom'].includes(iconPosition) && (_jsx("div", { style: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }, children: _jsx(ExpandButton, Object.assign({}, props, { expandIconPosition: iconPosition })) }))] }));
};
export default CollapsePanel;
