import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Download from '@hellodarwin/icons/dist/icons/Download';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import Hide from '@hellodarwin/icons/dist/icons/Hide';
import View from '@hellodarwin/icons/dist/icons/View';
import Tooltip from 'antd/es/tooltip';
import { useTranslation } from '../../../../plugins/i18n';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
import DrawerEditRow from '../../../drawer/drawer-edit-row';
const AssetEditRow = ({ editAsset, meta, openHideModal, }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const handleClick = () => {
        editAsset(meta);
    };
    const handleDeleteRow = (e) => {
        e.preventDefault();
        if (meta.actions.onDelete)
            meta.actions.onDelete();
    };
    const handleDownload = (e) => {
        e.preventDefault();
        if (meta.actions.onAction)
            meta.actions.onAction();
    };
    const isAssetHidden = () => {
        return (meta.hidden.is_admin_hidden ||
            meta.hidden.is_contacts_hidden ||
            meta.hidden.is_companies_hidden ||
            meta.hidden.is_providers_hidden);
    };
    const actions = (_jsxs(Div, { gap: 8, flex: "row", align: "center", fitContent: true, children: [_jsx(Tooltip, { title: isAssetHidden()
                    ? t('assets|show_user_asset')
                    : t('assets|hide_user_asset'), children: _jsx(Button, { size: "square", transparent: true, defaultStyle: theme.colors.primary, onClick: openHideModal, children: isAssetHidden() ? _jsx(Hide, { size: 16 }) : _jsx(View, { size: 16 }) }) }), _jsx(Button, { size: "square", transparent: true, defaultStyle: theme.colors.primary, onClick: handleClick, children: _jsx(Edit, { size: 16 }) }), meta.original_asset.file_name && (_jsx(Button, { size: "square", transparent: true, defaultStyle: theme.colors.primary, onClick: handleDownload, children: _jsx(Download, { size: 16 }) })), _jsx(Button, { size: "square", transparent: true, defaultStyle: theme.colors.primary, onClick: handleDeleteRow, children: _jsx(Delete, { size: 16 }) })] }));
    return (_jsx(DrawerEditRow, { title: meta.name, actions: actions, onClick: handleClick, hidden: !!meta.hidden }));
};
export default AssetEditRow;
