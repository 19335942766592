export const findInfoByGroupId = ({ form_group_id, pages, }) => {
    var _a, _b;
    const page = Object.values(pages).find((page) => Object.keys(page.groups).includes(form_group_id));
    const group = page === null || page === void 0 ? void 0 : page.groups[form_group_id];
    return [(_a = group === null || group === void 0 ? void 0 : group.entity) !== null && _a !== void 0 ? _a : null, (_b = page === null || page === void 0 ? void 0 : page.entity) !== null && _b !== void 0 ? _b : null];
};
export const handleReorderFormGroups = ({ form_page_id, form_group_id, functions, formPages, group_order, }) => {
    if (formPages[form_page_id]) {
        var formPageResponse = Object.assign(Object.assign({}, formPages[form_page_id].entity), { groups: [] });
        const groups = Object.values(formPages[form_page_id].groups).sort((g1, g2) => g1.entity.group_order - g2.entity.group_order);
        const movingGroupID = groups.findIndex((group) => group.id === form_group_id);
        const [removedGroup] = groups.splice(movingGroupID, 1);
        groups.splice(group_order, 0, removedGroup);
        groups.forEach((groupBuilder, index) => {
            var _a;
            formPageResponse.groups.push({
                form_page_id: groupBuilder.entity.form_page_id,
                form_id: groupBuilder.entity.form_id,
                form_group_id: groupBuilder.entity.form_group_id,
                group_title_en: groupBuilder.entity.group_title_en,
                group_title_fr: groupBuilder.entity.group_title_fr,
                items: (_a = groupBuilder === null || groupBuilder === void 0 ? void 0 : groupBuilder.entity) === null || _a === void 0 ? void 0 : _a.items,
                group_order: index,
            });
        });
        functions.updateFormGroups(formPageResponse);
    }
};
export const handleDeleteFormGroup = async ({ form_id, form_page_id, form_group_id, formPages, functions, }) => {
    if (formPages[form_page_id]) {
        var formPageResponse = Object.assign(Object.assign({}, formPages[form_page_id].entity), { groups: [] });
        Object.values(formPages[form_page_id].groups).forEach((groupBuilder, index) => {
            var _a;
            formPageResponse.groups.push({
                form_id,
                form_page_id,
                form_group_id: groupBuilder.entity.form_group_id,
                items: (_a = groupBuilder === null || groupBuilder === void 0 ? void 0 : groupBuilder.entity) === null || _a === void 0 ? void 0 : _a.items,
                group_order: index,
            });
        });
        formPageResponse.groups.push({
            form_group_id: form_group_id,
            items: [],
            group_order: 0,
            deleted: true,
            form_page_id,
            form_id,
        });
        functions.deleteFormGroup(form_group_id, form_page_id);
    }
};
export const handleNewGroup_DropInPage_FromControls = async ({ form_id, form_page_id, group_order, functions, }) => {
    const newGroup = {
        form_group_id: '',
        form_id: form_id,
        form_page_id,
        group_order: group_order,
        items: [],
    };
    await functions.createGroup(newGroup);
};
