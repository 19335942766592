'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from 'react';
import { useAppTranslations } from '../app-translations-provider';
import { addParamToTranslation, flattenTranslations, } from './translations-utils';
export const TranslationsContext = React.createContext({
    t: (key) => {
        console.error(`can't render ${key}. Not inside translations-provider`);
        return key;
    },
    getFullLanguage: (lang) => {
        console.error(`can't render ${lang}. Not inside translations-provider`);
        return lang;
    },
    prepareTranslations: () => { },
    translationsLoaded: false,
});
export const TranslationsProvider = ({ children, }) => {
    const { translationsData, isLoading } = useAppTranslations();
    const [translations, setTranslations] = useState(translationsData);
    const prepareTranslations = useCallback((newTranslations) => {
        if (!newTranslations || !Object.keys(newTranslations).length)
            return;
        const flat = Array.isArray(newTranslations)
            ? flattenTranslations(newTranslations)
            : newTranslations;
        setTranslations((prev) => (Object.assign(Object.assign({}, prev), flat)));
    }, []);
    useEffect(() => {
        if (translationsData && Object.keys(translationsData).length) {
            prepareTranslations(translationsData);
        }
    }, [translationsData, prepareTranslations]);
    const translationsLoaded = useMemo(() => Object.keys(translations).length > 0, [translations]);
    const t = useCallback((key, params = {}) => {
        const value = translations[key];
        if (!value)
            return key;
        return addParamToTranslation(value, params);
    }, [translations]);
    const getFullLanguage = useCallback((lang) => {
        const fallback = '';
        const languages = {
            fr: t('language|fr'),
            en: t('language|en'),
            'en:fr': t('language|any'),
        };
        return languages[lang] || fallback;
    }, [t]);
    const contextValue = useMemo(() => ({
        t,
        getFullLanguage,
        prepareTranslations,
        translationsLoaded,
    }), [t, getFullLanguage, prepareTranslations, translationsLoaded]);
    return (_jsx(TranslationsContext.Provider, { value: contextValue, children: children }));
};
export const useTranslations = () => useContext(TranslationsContext);
export default TranslationsProvider;
