import isEmptyStringOrArray from '../../../../features/helpers/is-empty-string-or-array';
const compareValues = (value1, value2) => {
    if (Array.isArray(value1) !== Array.isArray(value2))
        return false;
    if (Array.isArray(value1)) {
        if ((value1 === null || value1 === void 0 ? void 0 : value1.length) !== (value2 === null || value2 === void 0 ? void 0 : value2.length))
            return false;
        return value1 === null || value1 === void 0 ? void 0 : value1.every((element) => value2 === null || value2 === void 0 ? void 0 : value2.includes(element));
    }
    else {
        return value1 ? value2 === value1 : !value2;
    }
};
const checkCompanyIsInFilter = ({ company, filterValues, }) => {
    var _a;
    if (!company)
        return false;
    if (!!filterValues && !((_a = Object.keys(filterValues)) === null || _a === void 0 ? void 0 : _a.length))
        return false;
    const { annualRevenue, industry: industries, subindustry: subindustries, region, companySize, service, status, financingType, } = filterValues;
    if (!(annualRevenue === null || annualRevenue === void 0 ? void 0 : annualRevenue.length) &&
        !(industries === null || industries === void 0 ? void 0 : industries.length) &&
        !(subindustries === null || subindustries === void 0 ? void 0 : subindustries.length) &&
        !(region === null || region === void 0 ? void 0 : region.length) &&
        !(companySize === null || companySize === void 0 ? void 0 : companySize.length)) {
        return false;
    }
    const industryMatch = isEmptyStringOrArray(industries) &&
        isEmptyStringOrArray(company.industry_sector)
        ? true
        : compareValues(company.industry_sector, industries);
    const subindustryMatch = compareValues(company.industry_subsector, subindustries);
    const regionMatch = !!region && !!region.length ? region[0] === company.province : true;
    const annualRevenueMatch = compareValues(company.annual_revenue, annualRevenue);
    const companySizeMatch = compareValues(company.size, companySize);
    const serviceMatch = !(service === null || service === void 0 ? void 0 : service.length);
    const financingTypeMatch = !(financingType === null || financingType === void 0 ? void 0 : financingType.length);
    const statusMatch = !(status === null || status === void 0 ? void 0 : status.length);
    if (industryMatch &&
        subindustryMatch &&
        statusMatch &&
        financingTypeMatch &&
        regionMatch &&
        serviceMatch &&
        annualRevenueMatch &&
        companySizeMatch) {
        return true;
    }
    else {
        return false;
    }
};
export default checkCompanyIsInFilter;
