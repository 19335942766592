import {
  ApiError,
  ApiErrorInitialState,
  Company,
  CompanyRequest,
  Contact,
  GrantResult,
  Provider,
  Rfp,
} from '@hellodarwin/core/lib/features/entities';
import {
  EntityState,
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app';
import { showErrorNotification } from '../../utils';
import AdminApiClient from '../admin-api-client';

const companiesAdapter = createEntityAdapter({
  selectId: (model: Company) => model.company_id,
});
const companiesRFPAdapter = createEntityAdapter({
  selectId: (model: Rfp) => model.rfp_id,
});
const companiesContactAdapter = createEntityAdapter({
  selectId: (model: Contact) => model.contact_id,
});

export interface ModalState {
  open: boolean;
  type: string;
  company_id?: string;
}

export const InitialModalState: ModalState = {
  open: false,
  type: '',
  company_id: '',
};

type StatusType = 'generic' | 'fetchRFP' | 'fetchProvider' | 'fetchContacts';

type Status = {
  [key in StatusType]: 'idle' | 'pending';
};

export interface CompanyState {
  status: 'idle' | 'pending';
  mappedStatus: Status;
  error: ApiError;
  companies: EntityState<Company, string>;
  activeCompanyRFP: EntityState<Rfp, string>;
  activeCompanyProvider: Provider | null;
  totalCompanies: number;
  modal: ModalState;
  activeCompanyContacts: EntityState<Contact, string>;
}

const initialState: CompanyState = {
  status: 'idle',
  mappedStatus: {
    generic: 'idle',
    fetchRFP: 'idle',
    fetchProvider: 'idle',
    fetchContacts: 'idle',
  },
  error: ApiErrorInitialState,
  companies: companiesAdapter.getInitialState(),
  activeCompanyRFP: companiesRFPAdapter.getInitialState(),
  activeCompanyProvider: null,
  totalCompanies: 0,
  modal: InitialModalState,
  activeCompanyContacts: companiesContactAdapter.getInitialState(),
};

export const queryCompanies = createAsyncThunk<
  { results: Company[]; total: number },
  { api: AdminApiClient; page: number; limit: number; query: string },
  { rejectValue: ApiError }
>(
  'admin/queryCompanies',
  async (
    {
      api,
      page,
      limit,
      query,
    }: { api: AdminApiClient; page: number; limit: number; query: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.queryCompanies(page, limit, query);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchCompanyNames = createAsyncThunk<
  Record<string, string>,
  { api: AdminApiClient; companyIds: string[] },
  { rejectValue: ApiError }
>(
  'admin/fetchCompanyNames',
  async ({ api, companyIds }, { rejectWithValue }) => {
    try {
      return await api.fetchCompanyNames(companyIds);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchCompany = createAsyncThunk<
  Company,
  {
    api: AdminApiClient;
    companyId: string;
    locale?: string;
    withGrants?: boolean;
    withSubsidiaries?: boolean;
  },
  { rejectValue: ApiError }
>(
  'admin/fetchCompany',
  async (
    {
      api,
      companyId,
      locale = 'fr',
      withGrants = false,
      withSubsidiaries = false,
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchCompany(
        companyId,
        locale,
        withGrants,
        withSubsidiaries,
      );
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateCompany = createAsyncThunk<
  Company,
  { api: AdminApiClient; company: Company },
  { rejectValue: ApiError }
>(
  'admin/updateCompany',
  async (
    { api, company }: { api: AdminApiClient; company: Company },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateCompany(company);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const createCompany = createAsyncThunk<
  Company,
  { api: AdminApiClient; company: CompanyRequest },
  { rejectValue: ApiError }
>(
  'admin/createCompany',
  async (
    { api, company }: { api: AdminApiClient; company: CompanyRequest },
    { rejectWithValue },
  ) => {
    try {
      return await api.createCompany(company);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const fetchCompanyRFP = createAsyncThunk<
  Rfp[],
  { api: AdminApiClient; company_id: string },
  { rejectValue: ApiError }
>(
  'admin/fetchCompanyRFP',
  async (
    { api, company_id }: { api: AdminApiClient; company_id: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchCompanyRFP(company_id);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const fetchCompanyProvider = createAsyncThunk<
  Provider,
  { api: AdminApiClient; company_id: string },
  { rejectValue: ApiError }
>(
  'admin/fetchCompanyProvider',
  async (
    { api, company_id }: { api: AdminApiClient; company_id: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchCompanyProvider(company_id);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchCompanyContacts = createAsyncThunk<
  Contact[],
  {
    api: AdminApiClient;
    company_id: string;
    page: number;
    limit: number;
    query: string;
  },
  { rejectValue: ApiError }
>(
  'admin/fetchCompanyContacts',
  async (
    {
      api,
      company_id,
      page,
      limit,
      query,
    }: {
      api: AdminApiClient;
      company_id: string;
      page: number;
      limit: number;
      query: string;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.queryCompanyContacts(company_id, page, limit, query);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const assignGrantToCompany = createAsyncThunk<
  { company_id: string; grant: GrantResult },
  { api: AdminApiClient; company_id: string; grant_id: string; locale: string },
  { rejectValue: ApiError }
>(
  'admin/assignGrantToCompany',
  async ({ api, company_id, grant_id, locale }, { rejectWithValue }) => {
    try {
      const { company_id: companyId, grant_id: grantId } =
        await api.assignGrantToCompany(company_id, grant_id);
      const grant = await api.fetchGrantResult(grantId, locale);
      return { company_id: companyId, grant };
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const removeGrantFromCompany = createAsyncThunk<
  { company_id: string; grant_id: string },
  { api: AdminApiClient; company_id: string; grant_id: string },
  { rejectValue: ApiError }
>(
  'admin/removeGrantFromCompany',
  async ({ api, company_id, grant_id }, { rejectWithValue }) => {
    try {
      await api.removeGrantFromCompany(company_id, grant_id);
      return { company_id, grant_id };
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const reorderCompanyGrants = createAsyncThunk<
  { company_id: string; ordered_grant_ids: string[] },
  { api: AdminApiClient; companyId: string; reorderedCompanyGrants: string[] },
  { rejectValue: ApiError }
>(
  'admin/reorderCompanyGrants',
  async ({ api, companyId, reorderedCompanyGrants }, { rejectWithValue }) => {
    try {
      return await api.reorderCompanyGrants(companyId, reorderedCompanyGrants);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateCompanyGrantTitle = createAsyncThunk<
  { company_id: string; grant_id: string; title: string },
  { api: AdminApiClient; company_id: string; grant_id: string; title: string },
  { rejectValue: ApiError }
>(
  'admin/updateCompanyGrantTitle',
  async ({ api, company_id, grant_id, title }, { rejectWithValue }) => {
    try {
      const response = await api.updateCompanyGrantTitle(
        company_id,
        grant_id,
        title,
      );
      return response;
    } catch (err: any) {
      console.error(err.response?.data || err.message);
      showErrorNotification(err.response?.data || err.message);
      return rejectWithValue(err.response?.data || err.message);
    }
  },
);

export const setCompanySubsidiaries = createAsyncThunk<
  { companyId: string; subsidiary_ids: string[] },
  { api: AdminApiClient; companyId: string; subsidiaryIds: string[] },
  { rejectValue: ApiError }
>(
  'admin/setCompanySubsidiaries',
  async ({ api, companyId, subsidiaryIds }, { rejectWithValue }) => {
    try {
      const response = await api.setCompanySubsidiaries(
        companyId,
        subsidiaryIds,
      );
      return {
        companyId,
        subsidiary_ids: response,
      };
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const toggleCompanyModal = createAction<ModalState>(
  'admin/toggleCompanyModal',
);

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(queryCompanies.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(queryCompanies.fulfilled, (state, { payload }) => {
      if (!!payload) {
        companiesAdapter.setAll(state.companies, payload.results ?? []);
        state.totalCompanies = payload.total;
      }
      state.status = 'idle';
    });
    builder.addCase(queryCompanies.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchCompany.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchCompany.fulfilled, (state, { payload }) => {
      companiesAdapter.upsertOne(state.companies, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchCompany.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateCompany.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateCompany.fulfilled, (state, { payload }) => {
      if (!payload.account_manager) {
        payload.account_manager = undefined;
      }
      companiesAdapter.upsertOne(state.companies, payload);
      state.status = 'idle';
    });
    builder.addCase(updateCompany.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(createCompany.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createCompany.fulfilled, (state, { payload }) => {
      companiesAdapter.upsertOne(state.companies, payload);
      state.status = 'idle';
    });
    builder.addCase(createCompany.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchCompanyRFP.pending, (state) => {
      state.mappedStatus.fetchRFP = 'pending';
    });
    builder.addCase(fetchCompanyRFP.fulfilled, (state, { payload }) => {
      if (!payload?.length) return;
      companiesRFPAdapter.setAll(state.activeCompanyRFP, payload);
      state.mappedStatus.fetchRFP = 'idle';
    });
    builder.addCase(fetchCompanyRFP.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.mappedStatus.fetchRFP = 'idle';
    });
    builder.addCase(fetchCompanyProvider.pending, (state) => {
      state.mappedStatus.fetchProvider = 'pending';
    });
    builder.addCase(fetchCompanyProvider.fulfilled, (state, { payload }) => {
      state.activeCompanyProvider = payload;
      state.mappedStatus.fetchProvider = 'idle';
    });
    builder.addCase(fetchCompanyProvider.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.mappedStatus.fetchProvider = 'idle';
    });
    builder.addCase(fetchCompanyContacts.pending, (state) => {
      state.mappedStatus.fetchContacts = 'pending';
    });
    builder.addCase(fetchCompanyContacts.fulfilled, (state, { payload }) => {
      companiesContactAdapter.setAll(state.activeCompanyContacts, payload);
      state.mappedStatus.fetchContacts = 'idle';
    });
    builder.addCase(fetchCompanyContacts.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.mappedStatus.fetchContacts = 'idle';
    });
    builder.addCase(toggleCompanyModal, (state, { payload }) => {
      state.modal = payload;
    });
    builder.addCase(assignGrantToCompany.fulfilled, (state, { payload }) => {
      const { company_id, grant } = payload;
      const company = state.companies.entities[company_id];
      company.grants = company.grants ? [...company.grants, grant] : [grant];
      state.status = 'idle';
    });
    builder.addCase(assignGrantToCompany.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(removeGrantFromCompany.fulfilled, (state, { payload }) => {
      const { company_id, grant_id } = payload;
      const company = state.companies.entities[company_id];
      if (company && company.grants) {
        company.grants = company.grants.filter(
          (grant) => grant.grant_id !== grant_id,
        );
      }
      state.status = 'idle';
    });
    builder.addCase(removeGrantFromCompany.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(reorderCompanyGrants.fulfilled, (state, { payload }) => {
      const company = state.companies.entities[payload.company_id];
      if (company && company.grants) {
        const grantsMap = Object.fromEntries(
          company.grants.map((g) => [g.grant_id, g]),
        );
        company.grants = payload.ordered_grant_ids
          .map((id) => grantsMap[id])
          .filter(Boolean);
      }
      state.status = 'idle';
    });
    builder.addCase(reorderCompanyGrants.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateCompanyGrantTitle.fulfilled, (state, { payload }) => {
      const { company_id, grant_id, title } = payload;
      const company = state.companies.entities[company_id];
      if (company && company.grants) {
        company.grants = company.grants.map((grant) =>
          grant.grant_id === grant_id
            ? { ...grant, company_grant_title: title }
            : grant,
        );
      }
      state.status = 'idle';
    });
    builder.addCase(updateCompanyGrantTitle.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(setCompanySubsidiaries.fulfilled, (state, { payload }) => {
      companiesAdapter.upsertOne(state.companies, {
        ...state.companies.entities[payload.companyId],
        subsidiary_ids: payload.subsidiary_ids,
      });
      state.status = 'idle';
    });
    builder.addCase(setCompanySubsidiaries.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
  },
});

export const { selectAll: selectAllCompanies, selectById: selectCompanyById } =
  companiesAdapter.getSelectors(
    (state: RootState) => state.companies.companies,
  );
export const {
  selectAll: selectAllCompanyRFP,
  selectById: selectCompanyRFPByID,
} = companiesRFPAdapter.getSelectors(
  (state: RootState) => state.companies.activeCompanyRFP,
);

export const {
  selectAll: selectAllCompanyContacts,
  selectById: selectCompanyContactByID,
} = companiesContactAdapter.getSelectors(
  (state: RootState) => state.companies.activeCompanyContacts,
);

export const selectError = (state: RootState) =>
  state.companies.error.error_code;

export const selectCompaniesIsLoading = createSelector(
  [
    (state: RootState, _?: StatusType) => state.companies.mappedStatus,
    (state: RootState, _?: StatusType) => state.companies.status,
    (_, type?: StatusType) => type,
  ],
  (mappedStatus, status, type) => {
    if (!type) {
      return status === 'pending';
    } else {
      return mappedStatus[type] === 'pending';
    }
  },
);

export const selectCompanyContacysIsLoading = createSelector(
  (state: RootState) => state.companies.mappedStatus.fetchContacts,
  (fetchContactsStatus) => fetchContactsStatus === 'pending',
);

export const selectCompanyProvider = (state: RootState) =>
  state.companies.activeCompanyProvider;
export const selectCompaniesTotal = (state: RootState) =>
  state.companies.totalCompanies;
export const selectCompanyModal = (state: RootState) => state.companies.modal;

export const selectCompanyHasProvider = (state: RootState) => {
  return !!state.companies.activeCompanyProvider?.provider_id;
};

export const companiesReducer = companiesSlice.reducer;
