import { useEffect, useMemo, useState } from 'react';
const useGrantNotificationCache = (grantId) => {
    const selectedState = useState(false);
    const [selected, setSelected] = selectedState;
    const cacheId = useMemo(() => `grant-notification-${grantId}`, [grantId]);
    useEffect(() => {
        setSelected(window.sessionStorage.getItem(cacheId) === 'true');
    }, [cacheId]);
    const handleSelected = (selected) => {
        setSelected(selected);
        window.sessionStorage.setItem(cacheId, `${selected}`);
    };
    return useMemo(() => ({
        selected,
        handleSelected,
    }), [selected, handleSelected]);
};
export default useGrantNotificationCache;
