import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Form from 'antd/es/form';
import Input from 'antd/es/input/Input';
import Upload from 'antd/es/upload';
import { useEffect } from 'react';
import { useTranslation } from '../../../../../plugins/i18n';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import Grid from '../../../../common/hd-grid';
import Typography from '../../../../common/typography';
import FormItem from '../../../form-layouts/form-item';
import validateFile, { getFileTypes } from '../../../utils/validate-file';
const TeamMemberForm = ({ form, teamMember, fileList, setFileList, handleSubmit, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            name: teamMember.name,
            role: teamMember.role,
            linked_in: teamMember.linked_in,
        });
        if (teamMember.profile_picture) {
            setFileList([
                {
                    uid: '-1',
                    url: !!teamMember.profile_picture
                        ? `${teamMember.profile_picture}?${new Date().toString()}`
                        : undefined,
                    name: `${teamMember.name} picture`,
                },
            ]);
        }
        else {
            setFileList([]);
        }
    }, [teamMember]);
    const handleFileChange = (fileList) => {
        if (fileList.length > 0) {
            if (!validateFile(fileList[0].originFileObj))
                return;
        }
        setFileList(fileList);
    };
    const uploadButton = (_jsxs("div", { children: [_jsx(AddNew, { size: 24 }), _jsx("div", { style: { marginTop: 8 }, children: t('upload.upload') })] }));
    return (_jsxs(Form, { layout: "vertical", form: form, onFinish: handleSubmit, initialValues: teamMember, children: [_jsx(FormItem, { name: "upload", rules: [
                    () => ({
                        validator(_, value) {
                            if (fileList.length < 1) {
                                return Promise.reject(new Error(t('team|form.validation.upload')));
                            }
                            return Promise.resolve();
                        },
                    }),
                ], children: _jsxs(Div, { flex: "row", gap: 24, tablet: {
                        flex: 'column',
                        align: 'center',
                        gap: 12,
                        style: { marginBottom: 18 },
                    }, children: [_jsx(Upload, { listType: "picture-card", fileList: fileList, beforeUpload: () => false, onChange: (fileList) => handleFileChange(fileList.fileList), children: fileList.length >= 1 ? null : uploadButton }), _jsxs("div", { style: { paddingLeft: '1rem', paddingRight: '1rem' }, children: [_jsx(Typography, { elementTheme: "caption", color: theme.colors.grey_3, children: t('upload.resolution', { width: '200', height: '200' }) }), _jsx(Typography, { elementTheme: "caption", color: theme.colors.grey_3, children: t('upload.fileSize') }), _jsx(Typography, { elementTheme: "caption", color: theme.colors.grey_3, children: t('upload.allowedFile', { types: getFileTypes().join(', ') }) })] })] }) }), _jsxs(Grid, { xl: 2, md: 1, gutter: 8, children: [_jsx(FormItem, { name: "name", label: t('team|form.label.name'), rules: [{ required: true, message: t('team|form.validation.name') }], children: _jsx(Input, { size: "large", placeholder: t('team|form.placeholder.name') }) }), _jsx(FormItem, { name: "role", label: t('team|form.label.role'), rules: [{ required: true, message: t('team|form.validation.role') }], children: _jsx(Input, { size: "large", placeholder: t('team|form.placeholder.role') }) }), _jsx(FormItem, { className: "hd-0-mb", name: "linked_in", label: t('team|form.label.linkedIn'), children: _jsx(Input, { size: "large", placeholder: t('team|form.placeholder.linkedIn') }) })] })] }));
};
export default TeamMemberForm;
