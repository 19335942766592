import { ProviderInitialState } from './providers-entities';
export const PortfolioInitialState = {
    provider_portfolio_id: '',
    provider_id: '',
    description: '',
    project_url: '',
    client_company_name: '',
    from_hellodarwin_at: '',
    curated_at: '',
    public_at: '',
    created_at: '',
    updated_at: '',
    thumbnail: '',
    category: '',
    service: '',
    tags: [],
    assets: [],
    provider: ProviderInitialState,
    portfolio_title: '',
    linked_in: '',
    client_name: '',
    portfolio_picture: '',
    rfp_id: '',
    specialties: '',
};
export var PortfolioAssetsType;
(function (PortfolioAssetsType) {
    PortfolioAssetsType["Video"] = "Video";
    PortfolioAssetsType["Image"] = "Image";
})(PortfolioAssetsType || (PortfolioAssetsType = {}));
