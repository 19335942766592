import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ErrorNotFound from '../../components/error/notfound';
const renderRoutes = (pages) => {
    return React.Children.toArray(Object.entries(pages).map(([path, page]) => (_jsxs(_Fragment, { children: [_jsx(Route, { path: page.pathname, element: _jsx(Suspense, { fallback: "", children: page.component }) }, path), !!page.children ? renderRoutes(page.children) : undefined] }))));
};
const AppRoutesRender = ({ defaultHomepage, pages, }) => {
    return (_jsxs(Routes, { children: [!!defaultHomepage && (_jsx(Route, { path: "/", element: _jsx(Navigate, { to: defaultHomepage }) })), renderRoutes(pages), _jsx(Route, { path: "*", element: _jsx(ErrorNotFound, {}) })] }));
};
export default AppRoutesRender;
