var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useLocation } from '../../../features/providers/location-provider';
const Link = React.forwardRef((props, ref) => {
    const { LinkElement } = useLocation();
    if (!LinkElement) {
        const { to, children } = props, anchorProps = __rest(props, ["to", "children"]);
        return (_jsx("a", Object.assign({ href: to.toString(), ref: ref }, anchorProps, { children: children })));
    }
    return _jsx(LinkElement, Object.assign({ ref: ref }, props));
});
export default Link;
