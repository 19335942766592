import { CompanyInitialState, ContactInitialState, } from './core-entities';
export const InitialForms = {
    form_id: '',
    grants: [],
    title_en: '',
    title_fr: '',
    subtitle_en: '',
    subtitle_fr: '',
    form_type: '',
};
export const InitialFormsSelectOptions = {
    form_select_option_id: '',
    form_item_id: '',
    label_en: '',
    label_fr: '',
    value: '',
    display_order: 0,
    hidden: false,
};
export const InitialAdminFormResponse = {
    form_id: '',
    pages: [],
};
export const InitialFormPageResponse = {
    form_page_id: '',
    page_number: 0,
    groups: [],
};
export const InitialFormGroupResponse = {
    form_group_id: '',
    form_id: '',
    form_page_id: '',
    group_order: 0,
    items: [],
};
export const InitialFormItemResponse = {
    forms_groups_items_id: '',
    forms_groups_items_order: 0,
    form_item_id: '',
    key: '',
};
export var FormsConditionTypeList;
(function (FormsConditionTypeList) {
    FormsConditionTypeList["empty"] = "Empty";
    FormsConditionTypeList["equals"] = "Equals";
    FormsConditionTypeList["not equals"] = "Not Equals";
    FormsConditionTypeList["contains"] = "Contains";
    FormsConditionTypeList["filled"] = "Filled";
    FormsConditionTypeList["greater"] = "Greater";
    FormsConditionTypeList["lower"] = "Lower";
})(FormsConditionTypeList || (FormsConditionTypeList = {}));
export const InitialFormsConditionsResponse = {
    form_condition_id: '',
    form_page_id: '',
    form_item_id: '',
    condition_type: 'equals',
    trigger_values: [],
};
export const InitialFormsConditionTriggerValueResponse = {
    form_condition_id: '',
    forms_conditions_trigger_values_id: '',
    trigger_value: '',
};
export const InitialFormsSubmission = {
    form_id: '',
    form_submission_id: '',
    form_crm_id: '',
    source: '',
    page_name: '',
};
export const InitialTransferFormGroup = {
    form_page_source_id: '',
    form_page_destination_id: '',
    form_group_id: '',
    group_order: 0,
};
export const InitialAdminFormSubmission = {
    form: InitialForms,
    contact: ContactInitialState,
    company: CompanyInitialState,
    form_sections: [],
};
export var ItemsTypes;
(function (ItemsTypes) {
    ItemsTypes["main"] = "Main";
    ItemsTypes["groups"] = "Groups";
    ItemsTypes["items"] = "Items";
})(ItemsTypes || (ItemsTypes = {}));
export const InitialTranslatedFormResponse = {
    form_id: '',
    crm_id: '',
    pages: [],
    number_pages: 0,
};
export const InitialEditableFormSubmissionResponse = {
    company: CompanyInitialState,
    contact: ContactInitialState,
    form: {
        entity: InitialForms,
        pages: [],
    },
    submission: InitialFormsSubmission,
    all_items: [],
};
