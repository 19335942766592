import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Checkbox from 'antd/es/checkbox/Checkbox';
import { useForm } from 'antd/es/form/Form';
import Input from 'antd/es/input/Input';
import { useEffect, useState } from 'react';
import { GMAPS_API_KEY } from '../../../../api';
import useLocale from '../../../../features/providers/locale-provider';
import { Status, Wrapper } from '../../../../features/wrappers/gmaps-wrapper';
import { useTranslation } from '../../../../plugins/i18n';
import Collapse from '../../../common/Collapse';
import Div from '../../../common/div';
import FormItem from '../../form-layouts/form-item';
import FormLabel from '../../form-layouts/form-label';
import { getLongAddress } from '../../utils/get-address';
import SelectCountry from '../country-select';
import SelectProvince from '../province-select';
import GoogleAutocomplete from './GoogleAutocomplete';
const AddressAutocompleteInput = ({ form, showManualAddress, required, provinceRequired, disabled, }) => {
    const { t } = useTranslation();
    const { selectedLocale } = useLocale();
    const [isManual, setManual] = useState(false);
    const [isCanada, setCanada] = useState(false);
    const getCompleteFromForm = () => {
        const values = form.getFieldsValue();
        const complete = getLongAddress(values.address, {
            city: values.city,
            address: values.address,
            country: values.country,
            postal_code: values.postal_code,
            province: values.province,
        });
        if (!complete || complete === 'undefined') {
            form.setFieldValue('complete', '');
        }
        else {
            form.setFieldValue('complete', complete);
        }
    };
    useEffect(() => {
        getCompleteFromForm();
    }, []);
    useEffect(() => {
        setCanada(form.getFieldValue('isCanada'));
    }, [isCanada]);
    useEffect(() => {
        if (isCanada) {
            form.setFieldValue('country', 'Canada');
        }
    }, [isManual]);
    const render = (status) => {
        getCompleteFromForm();
        switch (status) {
            case Status.FAILURE:
            case Status.LOADING:
                return _jsx(_Fragment, {});
            default:
                return _jsx(GoogleAutocomplete, { form: form, setCanada: setCanada });
        }
    };
    const handleChange = (e) => {
        getCompleteFromForm();
    };
    return (_jsxs(Div, { style: { marginBottom: 16 }, children: [_jsx(FormItem, { name: "complete", label: _jsx(FormLabel, { label: t('profile|profileForm.company.labels.address') }), style: { margin: 0 }, rules: [
                    required || provinceRequired
                        ? {
                            required: true,
                            message: provinceRequired && !required
                                ? t('profile|profileForm.address.validation.province')
                                : t('profile|profileForm.company.validation.address'),
                        }
                        : {},
                ], children: _jsx(Wrapper, { apiKey: GMAPS_API_KEY, language: `${selectedLocale || 'en'}-ca`, region: "CA", id: "GOOGLE_MAPS", render: render, libraries: ['maps', 'places'], library: "places" }) }), _jsx(Div, { hidden: !showManualAddress, children: _jsx(Collapse, { noPadding: true, ExpandIcon: (props) => _jsx(_Fragment, {}), ghost: true, items: [
                        {
                            id: 'autocomplete',
                            title: (_jsx(Checkbox, { style: { marginTop: 16 }, onChange: (e) => setManual(e.target.checked), children: t('profile|profileForm.address.extra.addressManually') })),
                            isActive: isManual,
                            children: (_jsx(_Fragment, { children: _jsxs(Div, { style: { padding: '16px 0' }, flex: "column", gap: 16, children: [_jsx(FormItem, { name: "address", label: _jsx(FormLabel, { label: t('profile|profileForm.address.labels.address') }), style: { margin: 0 }, children: _jsx(Input, { size: "large", onChange: handleChange }) }), _jsxs(Div, { flex: "row", wrap: "wrap", gap: 16, children: [_jsx(SelectCountry, { setCanada: setCanada, form: form }), _jsx(FormItem, { hidden: isCanada, name: "country", label: _jsx(FormLabel, { label: t('profile|profileForm.address.labels.otherCountry') }), rules: [
                                                        {
                                                            message: t('profile|profileForm.address.validation.country'),
                                                        },
                                                    ], children: _jsx(Input, { size: "large", onChange: handleChange, placeholder: t('profile|profileForm.address.placeholder.otherCountry'), width: 100 }) }), _jsx(SelectProvince, { isRequired: provinceRequired, onChange: handleChange, isCanada: isCanada }), _jsx(FormItem, { name: "city", label: _jsx(FormLabel, { label: t('profile|profileForm.address.labels.city') }), children: _jsx(Input, { size: "large", onChange: handleChange }) }), _jsx(FormItem, { name: "postal_code", label: _jsx(FormLabel, { label: t(`profile|profileForm.address.labels.${isCanada ? 'postalCode' : 'zipCode'}`) }), children: _jsx(Input, { size: "large", onChange: handleChange }) })] }), _jsxs(Div, { hidden: true, children: [_jsx(FormItem, { name: "lat", children: _jsx(Input, { size: "large", onChange: handleChange }) }), _jsx(FormItem, { name: "lng", children: _jsx(Input, { size: "large" }) })] })] }) })),
                        },
                    ] }) })] }));
};
export default AddressAutocompleteInput;
AddressAutocompleteInput.Demo = () => {
    const [form] = useForm();
    return _jsx(AddressAutocompleteInput, { form: form, showManualAddress: true });
};
