var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { HubspotMeetingContainer } from './styles';
const HubspotMeeting = (_a) => {
    var { meetingUrl, app, additionalParams } = _a, props = __rest(_a, ["meetingUrl", "app", "additionalParams"]);
    const meetingUrlWithProps = useMemo(() => {
        const allProps = Object.assign(Object.assign({}, props), additionalParams);
        let params = '';
        Object.keys(allProps).forEach((key, i) => {
            const value = allProps[key];
            if (!!value) {
                const separator = i > 0 ? '&' : '';
                if (key === 'name') {
                    const names = value.split(' ');
                    const last_name = names.pop();
                    const first_name = names.join(' ');
                    params += `${separator}firstName=${first_name}&lastName=${last_name}`;
                }
                else {
                    params += `${separator}${key}=${value}`;
                }
            }
        });
        const separator = meetingUrl.includes('?') ? '&' : '?';
        const url = `${meetingUrl}${separator}${params}`;
        return url;
    }, [props, additionalParams]);
    return (_jsx(HubspotMeetingContainer, { children: _jsx("iframe", { src: meetingUrlWithProps, width: "100%", height: "650", allowTransparency: true }) }));
};
export default HubspotMeeting;
