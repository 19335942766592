'use client';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FilterDropdownDesktopDrawerStyled } from '../styles';
const FilterDropdownDesktopDrawer = ({ open, handleClose, children, }) => {
    return (_jsxs(_Fragment, { children: [open && (_jsx("div", { onClick: handleClose, style: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    zIndex: 99,
                } })), _jsx(FilterDropdownDesktopDrawerStyled, { "$open": open, children: children })] }));
};
export default FilterDropdownDesktopDrawer;
