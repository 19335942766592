import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Modal from '@hellodarwin/core/lib/components/common/hd-modal';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import theme from '@hellodarwin/core/lib/theme';
import XCircle from '@hellodarwin/icons/dist/icons/XCircle';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  fetchGinApplications,
  selectSelectedGinApplications,
} from '../../../features/api/slices/gins-slice';
import { useNewAdminApi } from '../../../features/api/use-admin-api';

type DeleteGrantModalProps = {
  grant_id: string;
  setDeleteModal: (state: boolean) => void;
  handleDeleteGrant: (grant_id: string) => void;
};

const DeleteGrantModal = ({
  grant_id,
  setDeleteModal,
  handleDeleteGrant,
}: DeleteGrantModalProps) => {
  const dispatch = useAppDispatch();
  const api = useNewAdminApi();
  const { t } = useTranslations();
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const ginApplications = useAppSelector(selectSelectedGinApplications);

  useEffect(() => {
    dispatch(fetchGinApplications({ api, grantId: grant_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async () => {
    if (ginApplications?.length || -1 >= 0) {
      setConfirmationModal(true);
    } else {
      handleDeleteGrant(grant_id);
    }
  };

  return (
    <Div flex="column" align="center" justify="center" gap={12}>
      <XCircle color={theme.colors.red_1} size={44} />
      <Typography elementTheme="subtitle1">
        {t('programs_admin|delete_grant_long')}
      </Typography>
      <Div flex="row" gap={8} justify="center">
        <Button
          onClick={() => setDeleteModal(false)}
          style={{ backgroundColor: theme.colors.grey_4 }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          size="small"
          style={{ backgroundColor: theme.colors.red_1 }}
        >
          Delete
        </Button>
      </Div>
      <Modal
        open={confirmationModal}
        handleCancel={() => setConfirmationModal(false)}
      >
        <Div flex="column" align="center" justify="center" gap={12}>
          <XCircle color={theme.colors.red_1} size={44} />
          <Typography elementTheme="subtitle1">
            {t('programs_admin|delete_grant_long')}
          </Typography>
          <Typography elementTheme="subtitle1">
            {t('programs_admin|delete_grant_application')}
          </Typography>
          {React.Children.toArray(
            ginApplications?.map((itm) => (
              <a
                href={`/programs/${itm.program_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography>{itm.program_company_name}</Typography>
              </a>
            )),
          )}
          <Div flex="row" justify="center" gap={8}>
            <Button
              onClick={() => {
                setDeleteModal(false);
                setConfirmationModal(false);
              }}
              style={{ backgroundColor: theme.colors.grey_4 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleDeleteGrant(grant_id);
                setConfirmationModal(false);
              }}
              style={{ backgroundColor: theme.colors.red_1 }}
            >
              Delete
            </Button>
          </Div>
        </Div>
      </Modal>
    </Div>
  );
};

export default DeleteGrantModal;
