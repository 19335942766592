import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTheme } from 'styled-components';
import Div from '../../../common/div';
import NavigationMenuLabelWrapper from './menu-item-label-wrapper';
const SubNavigationMenuItemsList = ({ items, app, isCollapsed, handleLinkClick, size, removeBorder, }) => {
    const theme = useTheme();
    return (_jsxs(Div, { flex: "row", gap: 12, align: "stretch", style: { paddingLeft: isCollapsed || removeBorder ? 0 : 24 }, children: [!isCollapsed && !removeBorder && (_jsx("div", { style: { width: 1, borderLeft: `1px solid ${theme.colors.grey_2}` } })), _jsx(Div, { flex: "column", gap: isCollapsed ? 8 : 0, children: React.Children.toArray(items.map((itm) => (_jsx(NavigationMenuLabelWrapper, Object.assign({}, itm, { isSublink: size !== 'large', app: app, handleLinkClick: handleLinkClick, isCollapsed: isCollapsed }))))) })] }));
};
export default SubNavigationMenuItemsList;
