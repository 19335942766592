import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { GrantStep } from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import RightArrow from '@hellodarwin/icons/dist/icons/BottomArrow';
import LeftArrow from '@hellodarwin/icons/dist/icons/LeftArrow';
import Button from 'antd/es/button';
import { Col } from 'antd/es/grid';
import { GrantFormValues } from './grant-form';

type GrantFormTranslateButtonsProps = {
  generateSectionFromTranslation: (
    section: (keyof GrantFormValues)[] | (keyof GrantStep)[],
    otherContent: string[],
    targetLocale: string,
  ) => void;
  contentEn: string[];
  contentFr: string[];
  sectionEn: (keyof GrantFormValues)[] | (keyof GrantStep)[];
  sectionFr: (keyof GrantFormValues)[] | (keyof GrantStep)[];
};

const GrantFormTranslateButtons = ({
  generateSectionFromTranslation,
  contentEn,
  contentFr,
  sectionEn,
  sectionFr,
}: GrantFormTranslateButtonsProps) => {
  const { t } = useTranslations();

  const isLocaleEmpty = (locale: string) => {
    if (locale === 'en') {
      return !!!contentEn || contentEn.every((str) => str === '');
    } else {
      return !!!contentFr || contentFr.every((str) => str === '');
    }
  };

  return (
    <Col span={2}>
      <Div
        gap={20}
        flex="column"
        justify="center"
        align="center"
        style={{ height: '100%' }}
      >
        <Typography textAlign="center">
          {t('grant_single|translate')}
        </Typography>
        <Button
          onClick={() => {
            generateSectionFromTranslation(sectionEn, contentFr, 'en');
          }}
          disabled={isLocaleEmpty('fr')}
        >
          <LeftArrow size={24}></LeftArrow>
        </Button>
        <Button
          onClick={() => {
            generateSectionFromTranslation(sectionFr, contentEn, 'fr');
          }}
          disabled={isLocaleEmpty('en')}
        >
          <RightArrow size={24}></RightArrow>
        </Button>
      </Div>
    </Col>
  );
};

export default GrantFormTranslateButtons;
