import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Form from 'antd/es/form';
import Input from 'antd/es/input/Input';
import { useEffect } from 'react';
import useLocale from '../../../../features/providers/locale-provider';
import { useTranslations } from '../../../../features/providers/translations-provider';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import FormItem from '../../form-layouts/form-item';
import FormLabel from '../../form-layouts/form-label';
import validateEmail from '../../utils/validate-email';
import ProvincesSelect from './provinces-select';
const ContactForm = ({ onContactChange, initialValues, handleNext, setForms, }) => {
    const { t } = useTranslations();
    const [form] = Form.useForm();
    const { selectedLocale } = useLocale();
    useEffect(() => {
        setForms((prevState) => (Object.assign(Object.assign({}, prevState), { contactForm: form })));
    }, [form, setForms]);
    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [initialValues]);
    return (_jsxs(Form, { form: form, layout: "vertical", initialValues: initialValues, onFinish: handleNext, name: 'contact-form', children: [_jsx(Typography, { elementTheme: "h5", style: { marginBottom: 8 }, children: t('form_viewer|viewerContactFormTitle') }), _jsx(Typography, { elementTheme: "body2", style: { marginBottom: 24 }, children: t('form_viewer|viewerContactFormSubtitle') }), _jsxs(Div, { flex: "column", gap: 24, children: [_jsxs(Div, { flex: "row", gap: 32, tablet: { flex: 'column', gap: 24 }, children: [_jsx(FormItem, { name: "first_name", label: _jsx(FormLabel, { label: t('form_viewer|firstName') }), style: { flex: 1, margin: 0 }, children: _jsx(Input, { size: "large", style: { width: '100%' }, placeholder: t('form_viewer|firstNameExample'), onChange: (e) => onContactChange('first_name', e.target.value) }) }), _jsx(FormItem, { style: { flex: 1, margin: 0 }, name: "last_name", label: _jsx(FormLabel, { label: t('form_viewer|lastName') }), children: _jsx(Input, { size: "large", style: { width: '100%' }, placeholder: t('form_viewer|lastNameExample'), onChange: (e) => onContactChange('last_name', e.target.value) }) })] }), _jsxs(Div, { flex: "row", gap: 32, tablet: { flex: 'column', gap: 24 }, children: [_jsx(FormItem, { name: "company_name", label: _jsx(FormLabel, { label: t('form_viewer|companyName') }), style: { flex: 1, margin: 0 }, children: _jsx(Input, { size: "large", style: { width: '100%' }, placeholder: t('form_viewer|companyNameExample'), onChange: (e) => onContactChange('company_name', e.target.value) }) }), _jsx(FormItem, { name: "email", label: _jsx(FormLabel, { label: t('form_viewer|email') }), style: { flex: 1, margin: 0 }, rules: [
                                    {
                                        required: true,
                                        message: t('form_viewer|emailRequired'),
                                        validator: validateEmail(t('form_viewer|emailRequired')),
                                    },
                                ], children: _jsx(Input, { type: "email", size: "large", style: { width: '100%' }, placeholder: t('form_viewer|emailExample'), onChange: (e) => onContactChange('email', e.target.value) }) })] }), _jsxs(Div, { flex: "row", gap: 32, style: { width: '100%' }, tablet: { flex: 'column', gap: 24 }, children: [_jsx(FormItem, { style: { flex: 1, margin: 0 }, name: "phone", label: _jsx(FormLabel, { label: t('form_viewer|phone') }), children: _jsx(Input, { size: "large", style: { width: '100%' }, placeholder: t('form_viewer|phoneNumberExample'), onChange: (e) => onContactChange('phone', e.target.value) }) }), _jsx("div", { style: { flex: 1, margin: 0 }, children: _jsx(ProvincesSelect, { name: "province", errorMessage: t('form_viewer|onlyQuebecEligible'), isRequired: true, isLongName: true }) })] })] })] }));
};
export default ContactForm;
