import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import { useTranslations } from '../../../features/providers/translations-provider';
import { useTheme } from '../../../plugins/styled';
import Button from '../../common/button';
import Div from '../../common/div';
import Typography from '../../common/typography';
import MainProjectTableContent from './main-project-table-content';
import CompanyMainProjectTableHeader from './main-project-table-header';
import { MainProjectContainer } from './styles';
const CompanyMainProjectTable = (props) => {
    const { project, projectsListState } = props;
    const { handleAddProject, editState } = projectsListState;
    const { t } = useTranslations();
    const theme = useTheme();
    const onAddProject = () => handleAddProject(project);
    return (_jsxs(MainProjectContainer, { children: [_jsx(CompanyMainProjectTableHeader, { project: project, projectsListState: projectsListState }), _jsxs(Div, { flex: "column", gap: 12, children: [editState === 'editable' && (_jsxs(Div, { flex: "row", gap: 32, align: "flex-end", justify: "space-between", style: { paddingInlineStart: 24 }, children: [_jsx(Typography, { elementTheme: "body3", bold: true, color: theme.colors.grey_2, children: t('project|childProjects') }), _jsx(Button, { defaultStyle: theme.colors.white_1, size: "small", headingIcon: _jsx(AddNew, { size: 20 }), onClick: onAddProject, children: t('project|addNewProject') })] })), _jsx(MainProjectTableContent, Object.assign({}, props))] })] }));
};
export default CompanyMainProjectTable;
