import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Steps from 'antd/es/steps';
import { useRef, useState } from 'react';
import { ReviewStatus, } from '../../../../features/entities/reviews-entities';
import { ReviewFormSteps } from '../../../../features/entities/rfp-status-flow-entities';
import useMediaQuery from '../../../../features/helpers/use-media-query';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Spin from '../../../common/spin';
import ReviewFlowLayout from '../../common/review-flow-layout';
import ReviewConfirm from './review-confirm';
import ReviewContact from './review-contact';
import ReviewExperience from './review-experience';
const ReviewFormHeader = ({ rfpId, reviewStep, }) => {
    const theme = useTheme();
    const { t } = useTranslations();
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    if (rfpId || isTablet)
        return _jsx(_Fragment, {});
    return (_jsx("div", { style: { marginBottom: 24 }, children: _jsx(Steps, { style: {
                width: '100%',
            }, size: "small", current: reviewStep == ReviewFormSteps.Experience ? 0 : 1, items: [
                {
                    title: t('reviews|newReviewStepsStep1'),
                },
                {
                    title: t('reviews|newReviewStepsStep2'),
                },
            ] }) }));
};
const ReviewForm = ({ providerName, providerId, rfpId, handleClose, reviewProvider, reviewStep, setReviewStep, tags, companyTags, }) => {
    var _a, _b;
    const { t } = useTranslations();
    const ref = useRef(null);
    const [forms, setForms] = useState({
        experienceForm: undefined,
        contactForm: undefined,
    });
    const [submitLoading, setSubmitLoading] = useState(false);
    const handleSubmit = async () => {
        var _a, _b;
        try {
            const [experienceFormValues, contactFromValues] = await Promise.all([
                (_a = forms.experienceForm) === null || _a === void 0 ? void 0 : _a.validateFields(),
                (_b = forms.contactForm) === null || _b === void 0 ? void 0 : _b.validateFields(),
            ]);
            setSubmitLoading(true);
            const review = {
                review_id: '',
                provider_id: providerId,
                rfp_id: rfpId,
                description_summary: experienceFormValues === null || experienceFormValues === void 0 ? void 0 : experienceFormValues.summary,
                rating_collaboration: experienceFormValues === null || experienceFormValues === void 0 ? void 0 : experienceFormValues.relationship,
                rating_quality: experienceFormValues === null || experienceFormValues === void 0 ? void 0 : experienceFormValues.quality,
                recommend: experienceFormValues === null || experienceFormValues === void 0 ? void 0 : experienceFormValues.recommend,
                status: ReviewStatus.Public,
                locale: 'fr',
                service: experienceFormValues === null || experienceFormValues === void 0 ? void 0 : experienceFormValues.service,
                verified: 'Unverified',
                review_source: 'Client App',
            };
            const reviewRequest = {
                review: review,
                contact_name: contactFromValues === null || contactFromValues === void 0 ? void 0 : contactFromValues.contact_name,
                contact_email: contactFromValues === null || contactFromValues === void 0 ? void 0 : contactFromValues.contact_email,
                contact_title: contactFromValues === null || contactFromValues === void 0 ? void 0 : contactFromValues.contact_title,
                company_name: contactFromValues === null || contactFromValues === void 0 ? void 0 : contactFromValues.company_name,
                company_industry: contactFromValues === null || contactFromValues === void 0 ? void 0 : contactFromValues.company_industry,
                company_size: contactFromValues === null || contactFromValues === void 0 ? void 0 : contactFromValues.company_size,
                terms: 'Agree',
            };
            await reviewProvider(reviewRequest);
            setReviewStep(ReviewFormSteps.Confirm);
            setSubmitLoading(false);
        }
        catch (e) {
            console.error(e);
            setSubmitLoading(false);
        }
    };
    const theme = useTheme();
    const isFromWebsite = !rfpId;
    return reviewStep === ReviewFormSteps.Confirm ? (_jsx(ReviewConfirm, { handleClose: handleClose, isActive: true })) : (_jsx(ReviewFlowLayout, { title: _jsx(_Fragment, { children: t('reviews|newReviewRatingHeading', { providerName }) }), description: reviewStep === ReviewFormSteps.Contact
            ? t('reviews|newReviewRatingSubheadingContact')
            : t('reviews|newReviewRatingSubheading'), children: _jsx("div", { ref: ref, children: submitLoading ? (_jsx(Spin, {})) : (_jsxs(_Fragment, { children: [_jsx(ReviewFormHeader, { rfpId: rfpId, reviewStep: reviewStep }), _jsx(ReviewExperience, { providerName: providerName, experiences: (_a = forms === null || forms === void 0 ? void 0 : forms.experienceForm) === null || _a === void 0 ? void 0 : _a.getFieldsValue(true), setForms: setForms, setFlowStep: setReviewStep, isActive: reviewStep == ReviewFormSteps.Experience, tags: tags, isFromWebsite: isFromWebsite, handleSubmit: handleSubmit }), isFromWebsite && (_jsx(ReviewContact, { contactInfo: (_b = forms === null || forms === void 0 ? void 0 : forms.contactForm) === null || _b === void 0 ? void 0 : _b.getFieldsValue(true), setForms: setForms, setFlowStep: setReviewStep, isActive: reviewStep == ReviewFormSteps.Contact, handleSubmit: handleSubmit, isSubmit: true, companyTags: companyTags }))] })) }) }));
};
export default ReviewForm;
