import { Loader } from '@googlemaps/js-api-loader';
export class LoaderService {
    constructor(options) {
        this.loader = new Loader(options);
    }
    getLoader() {
        return this.loader;
    }
    static getInstance(options) {
        if (this.instance) {
            return this.instance;
        }
        this.instance = new LoaderService(options);
        return this.instance;
    }
}
