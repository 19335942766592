import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Warning from '@hellodarwin/icons/dist/icons/Warning';
import { useTranslations } from '../../../../features/providers/translations-provider';
import theme from '../../../../theme';
import Button from '../../../common/button';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import { GrantSectionContainer, GrantSectionContent, GrantSectionHeaderContent, } from './styles';
const GrantSectionHeader = ({ title, styles, onClick, applyButton, alreadyApplied, }) => {
    const { t } = useTranslations();
    return (_jsxs(GrantSectionHeaderContent, { style: Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.header), children: [_jsx(Typography.Title, { noMargin: true, level: 2, elementTheme: "h5", children: title }), applyButton && !!onClick && (_jsxs("div", { style: { position: 'relative' }, children: [_jsx(Button, { size: "small", onClick: onClick, children: t('grant_single|applyNow') }), alreadyApplied ? (_jsxs(Div, { flex: "row", gap: 4, align: "center", style: {
                            position: 'absolute',
                            width: 'fit-content',
                            top: 'calc(100% + 4px)',
                            right: 0,
                        }, children: [_jsx(Warning, { size: 14, color: theme.colors.grey_2 }), _jsx(Typography, { elementTheme: "overline", color: theme.colors.grey_2, nowrap: true, children: t('grant_single|alreadyApplied') })] })) : undefined] }))] }));
};
const GrantSection = (props) => {
    const { id, children, styles } = props;
    return (_jsxs(GrantSectionContainer, { style: { width: '100%' }, id: id, children: [_jsx(GrantSectionHeader, Object.assign({}, props)), _jsx(GrantSectionContent, { style: Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.body), children: children })] }));
};
export default GrantSection;
