import Dropdown from '@hellodarwin/core/lib/components/common/dropdown';
import { Rfp } from '@hellodarwin/core/lib/features/entities';
import RfpStatus from '@hellodarwin/core/lib/features/enums/rfp-status';
import { getWebsiteURL } from '@hellodarwin/core/lib/features/helpers';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import { DefaultTheme } from '@hellodarwin/core/lib/theme/index';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import { MenuProps } from 'antd/es/menu';
import Modal from 'antd/es/modal';
import notification from 'antd/es/notification';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app';
import { fetchRfpMatches } from '../../features/api/slices/rfp-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
import { usePlatform } from '@hellodarwin/core/lib/features/providers/platform-provider';

type RfpActionsProps = {
  rfp: Rfp;
};

const RfpActions = ({ rfp }: RfpActionsProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as DefaultTheme;
  const { isDev } = usePlatform();

  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const toggleDeleteRfp = () => {
    if (!!rfp.activated_at) {
      Modal.error({
        title: t('rfp|actions.deleteError'),
      });
    } else {
      Modal.confirm({
        title: t('rfp|actions.delete'),
        content: t('rfp|actions.deleteConfirm'),
        okText: t('button.confirm'),
        cancelText: t('button.cancel'),
        okButtonProps: {
          style: {
            backgroundColor: theme.colors.purple_1,
            color: theme.colors.white_1,
            borderRadius: '24px',
          },
        },
        cancelButtonProps: {
          style: {
            backgroundColor: theme.colors.white_1,
            borderColor: theme.colors.grey_1,
            color: theme.colors.grey_1,
            borderRadius: '24px',
          },
        },
        onOk() {
          return new Promise((resolve, reject) => {
            api
              .deleteRfp(rfp.rfp_id || '')
              .then(() => {
                notification.success({
                  message: 'successDelete',
                  placement: 'topLeft',
                  duration: 0,
                });
                navigate('/rfps');
                resolve('Suppression');
              })
              .catch((e: Error) => reject(e));
          }).catch(() => console.error('Oops errors!'));
        },
      });
    }
  };

  const handleResetMatches = async () => {
    await api.resetRfpMatches(rfp.rfp_id);
    await api.fetchRfpPurchasedMatches(rfp.rfp_id);

    dispatch(fetchRfpMatches({ api, rfpId: rfp.rfp_id }));
  };

  const handleResetPurchases = async () => {
    await api.resetRfpPurchases(rfp.rfp_id);
    await api.fetchRfpPurchasedMatches(rfp.rfp_id);

    dispatch(fetchRfpMatches({ api, rfpId: rfp.rfp_id }));
  };

  const handleResetRaiseHands = async () => {
    await api.resetRfpRaiseHands(rfp.rfp_id);
    await api.fetchRfpPurchasedMatches(rfp.rfp_id);

    dispatch(fetchRfpMatches({ api, rfpId: rfp.rfp_id }));
  };

  const devActionMenuItems: MenuProps['items'] = useMemo(
    () => [
      {
        label: 'Public Rfp Update',
        onClick: () =>
          window.open(
            getWebsiteURL() +
              '/rfp-status-update?token=' +
              rfp.status_update_token,
            '_blank',
          ),
        key: 1,
        icon: <NewWindow width={16} style={{ color: theme.colors.purple_1 }} />,
      },
      {
        label: t('rfp|actions.delete'),
        onClick: toggleDeleteRfp,
        key: 2,
      },
      {
        label: 'Reset Matches',
        onClick: handleResetMatches,
        key: 3,
      },
      {
        label: 'Reset Purchases',
        onClick: handleResetPurchases,
        key: 4,
      },
      {
        label: 'Reset Raise Hands',
        onClick: handleResetRaiseHands,
        key: 5,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rfp],
  );

  const actionMenuItems: MenuProps['items'] = useMemo(() => {
    const items: MenuProps['items'] = [];
    items?.push({
      label: 'Public',
      onClick: () =>
        window.open(
          getWebsiteURL() +
            '/rfp-status-update?token=' +
            rfp.status_update_token,
          '_blank',
        ),
      key: 1,
      icon: <NewWindow width={16} style={{ color: theme.colors.purple_1 }} />,
    });
    if (
      rfp.status === RfpStatus.PendingApproval ||
      rfp.status === RfpStatus.Canceled
    ) {
      items?.push({
        label: t('rfp|actions.delete'),
        onClick: toggleDeleteRfp,
        key: 1,
      });
    }
    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfp]);

  const items = useMemo(
    () => (isDev ? devActionMenuItems : actionMenuItems),
    [devActionMenuItems, actionMenuItems, isDev],
  );

  return <Dropdown items={items} cta={{ size: 32 }} />;
};

export default RfpActions;
