import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import React from 'react';
import { useTheme } from 'styled-components';
import Loading from '../../../loading';
import Collapse from '../../Collapse';
import Typography from '../../typography';
import { SidebarBlockContainer, SidebarBlockHeader } from './styles';
const ChildSidebarBlock = ({ isLoading, content, title, extra, actions, }) => {
    const theme = useTheme();
    return (_jsx(SidebarBlockContainer, { children: _jsx(Collapse, { ghost: true, style: { overflow: 'hidden' }, defaultActiveKey: 'sidebar-block', noPadding: true, styles: {
                panelContainer: {
                    padding: '20px 32px',
                },
                panelContent: {
                    paddingTop: 0,
                },
            }, expandIconPosition: "start", items: [
                {
                    id: 'sidebar-block',
                    title: (_jsxs(SidebarBlockHeader, { children: [_jsx(Typography, { elementTheme: "subtitle1", children: title }), actions, extra] })),
                    children: (_jsx(_Fragment, { children: React.Children.toArray(content.map((itm) => (_jsx(Collapse, { ghost: true, style: { overflow: 'hidden' }, defaultActiveKey: 'sidebar-block', noPadding: true, styles: {
                                panelContainer: {
                                    padding: '8px',
                                },
                                panelContent: {
                                    paddingTop: 16,
                                },
                            }, expandIconPosition: "start", items: [
                                {
                                    id: 'sidebar-block',
                                    title: (_jsxs(SidebarBlockHeader, { children: [_jsx(Typography, { elementTheme: "body2", children: itm.title }), itm.actions, itm.extra] })),
                                    children: (_jsx(SidebarBlockContainer, { children: isLoading ? (_jsx(Loading, {})) : itm.content })),
                                },
                            ], ExpandIcon: ({ isActive }) => (_jsx(TabArrow, { down: isActive, size: 12, color: theme.colors.grey_2 })) })))) })),
                    extra,
                },
            ], ExpandIcon: ({ isActive }) => (_jsx(TabArrow, { down: isActive, size: 12, color: theme.colors.grey_2 })) }) }));
};
export default ChildSidebarBlock;
