'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Suspense, lazy } from 'react';
import { ParagraphContainer } from './styles';
import useTypographyProps from './utils/use-typography-props';
const CopyButton = lazy(() => import('./copyButton'));
const Content = (props) => {
    const { currentProps, currentStyle, defaultProps } = useTypographyProps(props);
    const { className, onBlur, onClick, onMouseEnter, onMouseLeave, onPointerEnter, onPointerLeave, id, children, } = defaultProps;
    const { $size } = currentProps;
    const classes = classNames(className, 'hd-paragraph', {
        [`${$size}`]: !!$size,
    });
    const textProps = Object.assign(Object.assign({}, currentProps), { className: classes, onClick,
        onBlur,
        onMouseEnter,
        onMouseLeave,
        onPointerEnter,
        onPointerLeave,
        id, style: currentStyle, children });
    return _jsx(ParagraphContainer, Object.assign({}, textProps));
};
const Paragraph = (props) => {
    const { copyable } = props;
    return !!copyable ? (_jsxs("div", { style: {
            display: 'flex',
            gap: 8,
            alignItems: 'center',
        }, children: [_jsx(Content, Object.assign({}, props)), !!copyable ? (_jsx(Suspense, { fallback: '', children: _jsx(CopyButton, { copyable: copyable }) })) : undefined] })) : (_jsx(Content, Object.assign({}, props)));
};
export default Paragraph;
