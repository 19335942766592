import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import parse from '../../../../../features/helpers/parse';
import VideoPlayer from '../../../../common/VideoPlayer';
import Div from '../../../../common/div';
import { VideoWrapper, VideoWrapperContainer, } from '../../../../provider/profile/styles';
import { grantSingleParseOptions } from '../../styles';
const GrantPreview = ({ grant_description_long, grant_youtube_url, }) => {
    return (_jsxs(_Fragment, { children: [_jsx(Div, { flex: "column", gap: 8, children: parse(grant_description_long || '', grantSingleParseOptions) }), grant_youtube_url && (_jsx(Div, { flex: "column", align: "center", gap: 24, style: { paddingTop: '40px' }, children: _jsx(VideoWrapperContainer, { children: _jsx(VideoWrapper, { children: _jsx(Div, { className: "video", children: typeof window !== 'undefined' && (_jsx(VideoPlayer, { url: grant_youtube_url, controls: true, light: true, playing: true })) }) }) }) }))] }));
};
export default GrantPreview;
