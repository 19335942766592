'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import Quill from 'quill';
import { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { RichTextEditorContainer } from './styles';
const defaultQuillModules = {
    toolbar: [
        [{ header: [3, 4, 5, false] }],
        ['bold', 'underline', 'italic', 'strike'],
        [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
        ],
        ['link'],
    ],
    clipboard: {
        matchVisual: false,
    },
};
const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');
class PlainClipboard extends Clipboard {
    onPaste(e) {
        e.preventDefault();
        const range = this.quill.getSelection();
        const html = e.clipboardData.getData('text/html');
        const text = e.clipboardData.getData('text/plain');
        let delta = new Delta().retain(range.index).delete(range.length);
        if (html) {
            let parsed = new DOMParser().parseFromString(html, 'text/html');
            let quillDelta = this.quill.clipboard.convert(parsed.body.innerHTML);
            let filteredDelta = new Delta();
            quillDelta.ops.forEach((op) => {
                if (op.insert) {
                    let filteredAttributes = op.attributes
                        ? this.filterAttributes(op.attributes)
                        : undefined;
                    filteredDelta.insert(op.insert, filteredAttributes);
                }
            });
            delta = delta.concat(filteredDelta);
        }
        else {
            delta = delta.insert(text);
        }
        this.quill.updateContents(delta, 'silent');
        this.quill.setSelection(range.index + delta.length(), 0, 'silent');
        this.quill.scrollIntoView();
    }
    filterAttributes(attributes) {
        const allowedFormats = [
            'header',
            'bold',
            'underline',
            'italic',
            'strike',
            'link',
        ];
        if (attributes.header) {
            if ([1, 2].includes(attributes.header)) {
                attributes.header = 3;
            }
            else if ([6].includes(attributes.header)) {
                attributes.header = 5;
            }
        }
        if (attributes.link) {
            attributes.target = '_blank';
            attributes.rel = 'noopener noreferrer';
        }
        return Object.keys(attributes)
            .filter((key) => allowedFormats.includes(key) &&
            (key !== 'header' || [3, 4, 5].includes(attributes[key])))
            .reduce((obj, key) => {
            obj[key] = attributes[key];
            return obj;
        }, {});
    }
}
Quill.register('modules/clipboard', PlainClipboard, true);
const RichTextEditor = ({ initialValue, value, onChange, placeholder, toolbar, minRows, }) => {
    const ref = useRef(null);
    const [content, setContent] = useState(value || initialValue || '');
    useEffect(() => {
        if (initialValue !== undefined) {
            setContent(value);
            console.warn('[Deprecated] `initialValue` is deprecated and will be removed in a future update. Use `value` instead for controlled components.');
        }
    }, [initialValue]);
    useEffect(() => {
        if (value !== undefined) {
            setContent(value);
        }
    }, [value]);
    const handleChange = (newValue) => {
        setContent(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };
    const ROW_HEIGHT = 24;
    const minHeightFromRows = minRows ? minRows * ROW_HEIGHT : 200;
    const minHeight = `${minHeightFromRows}px`;
    return (_jsx(RichTextEditorContainer, { "$min_height": minHeight, children: _jsx(ReactQuill, { ref: ref, modules: toolbar || defaultQuillModules, value: content, onChange: handleChange, placeholder: placeholder }) }));
};
export default RichTextEditor;
