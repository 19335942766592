import Div from '@hellodarwin/core/lib/components/common/div';
import Loading from '@hellodarwin/core/lib/components/loading';
import useDebounceFunction from '@hellodarwin/core/lib/features/hooks/use-debounce-function';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Select from 'antd/es/select';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  queryCompanies,
  selectAllCompanies,
  selectCompaniesIsLoading,
} from '../../../features/api/slices/companies-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';

interface SelectCompanyProps {
  handleSubmit: (companyId: string) => void;
}

const SEARCH_DELAY_MS = 500;
const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;

const SelectCompany: React.FC<SelectCompanyProps> = ({ handleSubmit }) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const companies = useAppSelector(selectAllCompanies);
  const searchCompaniesLoading = useAppSelector(selectCompaniesIsLoading);
  const { t } = useTranslations();

  const [companyOptions, setCompanyOptions] = useState(
    companies?.map((company) => ({
      key: company.company_id,
      value: company.company_id,
      label: company.name,
    })) || undefined,
  );

  useEffect(() => {
    if (companies) {
      if (!companies || companies.length < 1) {
        setCompanyOptions([]);
        return;
      }

      setCompanyOptions(
        companies.map((company) => ({
          key: company.company_id,
          value: company.company_id,
          label: company.name,
        })),
      );
    }
  }, [companies]);

  const onSearch = useDebounceFunction(
    async (query: string) => {
      await dispatch(
        queryCompanies({
          api,
          page: DEFAULT_PAGE,
          limit: DEFAULT_LIMIT,
          query,
        }),
      );
    },
    SEARCH_DELAY_MS,
    true,
  );

  return (
    <Select
      showSearch
      options={companyOptions}
      placeholder={t('createApplication|search_company')}
      loading={searchCompaniesLoading}
      filterOption={false}
      onSearch={onSearch}
      onChange={(companyId) => {
        handleSubmit(companyId);
        setSearchTerm(companyId);
      }}
      value={searchTerm || undefined}
      size="large"
      notFoundContent={
        searchCompaniesLoading ? (
          <Loading />
        ) : !!!companyOptions ? (
          <Div>No companies found</Div>
        ) : null
      }
    />
  );
};

export default SelectCompany;
