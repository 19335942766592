import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HdLogo from '@hellodarwin/icons/dist/icons/HdLogo';
import { useTheme } from 'styled-components';
import { useTranslations } from '../../features/providers/translations-provider';
import Button from '../common/button';
import Div from '../common/div';
import Typography from '../common/typography';
import { ErrorBoxContainer } from './styles';
const ErrorBox = ({ errorMessage, ErrorIcon, ctaAction, CtaIcon, }) => {
    const theme = useTheme();
    const { t } = useTranslations();
    return (_jsx(ErrorBoxContainer, { children: _jsxs("div", { children: [_jsxs(Div, { style: { padding: '8px 24px' }, flex: "row", align: "center", justify: "space-between", borderColor: theme.colors.grey_3, bordersSide: "bottom", children: [_jsx(HdLogo, { backgroundType: "light", width: 200 }), _jsx(Button, { fitContent: true, size: "circle", onClick: ctaAction, style: { padding: 12 }, defaultStyle: theme.colors.grey_1, headingIcon: _jsx(CtaIcon, { size: 24 }) })] }), _jsxs(Div, { flex: "column", gap: 24, align: "center", style: { padding: 32 }, children: [_jsx(ErrorIcon, { size: 96 }), _jsxs(Div, { flex: "column", gap: 12, align: "center", children: [_jsx(Typography.Title, { level: 4, color: theme.colors.primary, textAlign: "center", children: t('errorsPage|problemPageTitle') }), _jsx(Typography, { textAlign: "center", children: errorMessage })] })] })] }) }));
};
export default ErrorBox;
