import { useCallback } from 'react';
import { useNavigate } from '../../../../features/providers/location-provider';
const useHandleLinkClick = ({ selectedKeys, handleLinkClickCallback, }) => {
    const navigate = useNavigate();
    return useCallback((link, sublinks, callback) => {
        if (!(sublinks === null || sublinks === void 0 ? void 0 : sublinks.length) ||
            !selectedKeys.find((key) => !!sublinks.find((sl) => sl.key === key))) {
            if (link.onClick)
                link.onClick();
            else if (!link.external_link) {
                navigate(link.pathname);
            }
        }
        if (!!handleLinkClickCallback)
            handleLinkClickCallback();
    }, [selectedKeys, navigate]);
};
export default useHandleLinkClick;
