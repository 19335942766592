import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Divider from 'antd/es/divider';
import { InitialTeamMember, } from '../../../../features/entities/core-entities';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { Tablet } from '../../../common/Media';
import Div from '../../../common/div';
import ModalWithHeaderFooter from '../../../common/hd-modal/layouts/modal-with-header-footer';
import TeamList from './team-list';
import TeamMemberPreview from './team-member-preview';
const TeamPreviewModal = ({ provider, open, onCancel, teamMembers, selectedMember, setSelectedMember, setMembers, }) => {
    const { t } = useTranslations();
    const handleClose = () => {
        setSelectedMember(undefined);
        onCancel();
    };
    return (_jsx(ModalWithHeaderFooter, { open: open, handleCancel: handleClose, size: "large", header: _jsx(Tablet, { direction: "down", children: _jsx(ModalWithHeaderFooter.Header, { title: t('profile_team|teamPreviewTitle') }) }), children: _jsxs(Div, { flex: "row", align: "stretch", children: [_jsx(TeamMemberPreview, { teamMember: selectedMember !== null && selectedMember !== void 0 ? selectedMember : InitialTeamMember, name: provider.name, provider: provider }), _jsxs(Tablet, { direction: "up", children: [_jsx(Divider, { type: "vertical", style: { height: '100%' } }), _jsx(TeamList, { teamMembers: teamMembers, selectedMember: selectedMember !== null && selectedMember !== void 0 ? selectedMember : InitialTeamMember, setSelectedMember: setSelectedMember, setMembers: setMembers, provider: provider })] })] }) }));
};
export default TeamPreviewModal;
