import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import notification from 'antd/es/notification';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import handleDispatchResponse from '../../../../../features/helpers/handle-dispatch-response';
import validateURL from '../../../../../features/helpers/validate-url';
import { useCompanyInfos } from '../../../../../features/providers/company-infos-provider';
import { useProfileEdition } from '../../../../../features/providers/profile-edition-provider';
import Button from '../../../../common/button';
import ModalConfirm from '../../../../common/hd-modal/confirm';
import ModalWithHeaderFooter from '../../../../common/hd-modal/layouts/modal-with-header-footer';
const ProfileAssetsFormFooter = ({ type, handleCancel, fileList, asset_url, form, }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { company } = useCompanyInfos();
    const { functions } = useProfileEdition();
    const company_id = useMemo(() => company.company_id, [company]);
    const handleDelete = () => {
        ModalConfirm({
            title: t('deleteModal.title', { type }),
            content: t('deleteModal.content'),
            okText: t('deleteModal.ok'),
            cancelText: t('deleteModal.cancel'),
            okButtonProps: {
                style: {
                    backgroundColor: theme.colors.purple_1,
                    color: theme.colors.white_1,
                    borderRadius: '24px',
                },
            },
            cancelButtonProps: {
                style: {
                    backgroundColor: theme.colors.white_1,
                    borderColor: theme.colors.grey_1,
                    color: theme.colors.grey_1,
                    borderRadius: '24px',
                },
            },
            onOk: async () => {
                switch (type) {
                    case 'video':
                        !!functions.deleteVideos &&
                            (await functions
                                .deleteVideos({ companyId: company_id })
                                .then((res) => handleDispatchResponse(res, t('notification.saved'), t('notification.saving_error'))));
                        break;
                    case 'banner':
                        !!functions.deleteBanner &&
                            (await functions
                                .deleteBanner({ companyId: company_id })
                                .then((res) => handleDispatchResponse(res, t('notification.saved'), t('notification.saving_error'))));
                        break;
                    case 'logo':
                        !!functions.deleteLogo &&
                            (await functions
                                .deleteLogo({ companyId: company_id })
                                .then((res) => handleDispatchResponse(res, t('notification.saved'), t('notification.saving_error'))));
                        break;
                }
                handleCancel();
            },
        });
    };
    const handleSave = async () => {
        const data = new FormData();
        switch (type) {
            case 'video':
                const { asset_title, asset_url } = form.getFieldsValue();
                if ((asset_url === null || asset_url === void 0 ? void 0 : asset_url.length) > 0 && (await !validateURL(asset_url))) {
                    notification.error({
                        message: t('notification.url_error'),
                        placement: 'top',
                    });
                }
                else {
                    const request = {
                        title: asset_title,
                        url: asset_url,
                    };
                    data.append('request', JSON.stringify(request));
                    !!functions.updateVideos &&
                        (await functions
                            .updateVideos({ data, companyId: company_id })
                            .then((res) => handleDispatchResponse(res, t('notification.saved'), t('notification.saving_error'))));
                }
                break;
            case 'logo':
                if (!!(fileList === null || fileList === void 0 ? void 0 : fileList[0])) {
                    data.append('logo', fileList[0]);
                    if (!(fileList === null || fileList === void 0 ? void 0 : fileList[0].name.includes('.png')) &&
                        !(fileList === null || fileList === void 0 ? void 0 : fileList[0].name.includes('.jpg')) &&
                        !(fileList === null || fileList === void 0 ? void 0 : fileList[0].name.includes('.jpeg'))) {
                        break;
                    }
                    !!functions.updateLogo &&
                        (await functions
                            .updateLogo({ data, companyId: company_id })
                            .then((res) => handleDispatchResponse(res, t('notification.saved'), t('notification.saving_error'))));
                }
                break;
            case 'banner':
                if (!!(fileList === null || fileList === void 0 ? void 0 : fileList[0])) {
                    data.append('banner', fileList[0]);
                    !!functions.updateBanner &&
                        (await functions
                            .updateBanner({ data, companyId: company_id })
                            .then((res) => handleDispatchResponse(res, t('notification.saved'), t('notification.saving_error'))));
                }
                break;
            default:
                notification.error({
                    message: t('notification.saving_error'),
                    placement: 'top',
                });
                break;
        }
        handleCancel();
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalWithHeaderFooter.Footer, { actions: _jsxs(_Fragment, { children: [!!asset_url ? (_jsx(Button, { defaultStyle: theme.colors.red_1, size: "small", onClick: handleDelete, children: t('provider-profile|assets.delete') })) : (_jsx(Button, { defaultStyle: theme.colors.transparent, size: "small", onClick: handleCancel, children: t('provider-profile|assets.cancel') })), _jsx(Button, { size: "small", onClick: handleSave, children: t('provider-profile|assets.save') })] }) }), ","] }));
};
export default ProfileAssetsFormFooter;
