import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Budget from '@hellodarwin/icons/dist/icons/Budget';
import Language from '@hellodarwin/icons/dist/icons/Language';
import Projects from '@hellodarwin/icons/dist/icons/Projects';
import TeamSize from '@hellodarwin/icons/dist/icons/TeamSize';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import Verified from '@hellodarwin/icons/dist/icons/Verified';
import dayjs from 'dayjs';
import ProfileBlocks from '../../../../features/enums/profile-blocks';
import dollarFormat from '../../../../features/helpers/dollar-format';
import { useTranslations } from '../../../../features/providers/translations-provider';
import ProfileSection from '../profile-section';
import InformationCard from './information-card';
import { ProfileFastInformationsContainer } from './styles';
const ProfileFastInformations = ({ provider, isEditable, id, toggleEditModal, locale, }) => {
    var _a, _b, _c, _d;
    const { t } = useTranslations();
    const yearsVerified = dayjs().diff(provider === null || provider === void 0 ? void 0 : provider.verified_at, 'years') + 1;
    const yearFounded = (_a = provider === null || provider === void 0 ? void 0 : provider.founded_at) === null || _a === void 0 ? void 0 : _a.split('-')[0];
    const handleModal = () => {
        if (!!toggleEditModal) {
            toggleEditModal({ open: true, type: ProfileBlocks.FastInfos });
        }
    };
    return (_jsx(_Fragment, { children: _jsx(ProfileSection, { title: t('profile_overview|fastInformation'), isEditable: isEditable, id: id, handleEdit: handleModal, children: _jsxs(ProfileFastInformationsContainer, { children: [!!yearsVerified && (provider === null || provider === void 0 ? void 0 : provider.verified_at) && (_jsx(InformationCard, { Icon: Verified, title: t('profile_overview|verify'), value: `${dayjs(provider === null || provider === void 0 ? void 0 : provider.verified_at).year()}` })), !!(provider === null || provider === void 0 ? void 0 : provider.rfp_total_value) && (_jsx(InformationCard, { Icon: Projects, value: `${dollarFormat(provider === null || provider === void 0 ? void 0 : provider.rfp_total_value, locale)}$`, title: t('profile_overview|projectsWon') })), (!!yearFounded || isEditable) && (_jsx(InformationCard, { Icon: Timeline, title: t('profile_overview|foundation'), value: yearFounded || '' })), (!!((_b = provider === null || provider === void 0 ? void 0 : provider.size) === null || _b === void 0 ? void 0 : _b.length) || isEditable) && (_jsx(InformationCard, { Icon: TeamSize, value: (_c = provider === null || provider === void 0 ? void 0 : provider.size) !== null && _c !== void 0 ? _c : '', title: t('profile_overview|teamSize') })), ((!!(provider === null || provider === void 0 ? void 0 : provider.hourly_rate) && (provider === null || provider === void 0 ? void 0 : provider.hourly_rate) > 1) ||
                        isEditable) && (_jsx(InformationCard, { Icon: Budget, title: t('profile_overview|hourlyRate'), value: t('profile_overview|hourlyRateValue', {
                            value: (provider === null || provider === void 0 ? void 0 : provider.hourly_rate) || 0,
                        }) })), (!!((_d = provider === null || provider === void 0 ? void 0 : provider.website) === null || _d === void 0 ? void 0 : _d.length) || isEditable) && (_jsx(InformationCard, { Icon: Language, title: t('profile_overview|website'), value: t('profile_overview|viewSite'), link: provider === null || provider === void 0 ? void 0 : provider.website, tooltip: provider === null || provider === void 0 ? void 0 : provider.website }))] }) }) }));
};
export default ProfileFastInformations;
