import styled from '../../../plugins/styled';
export const VideoPlayerContainer = styled.div ``;
export const VideoOverlay = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.$isPlaying ? 0 : 1)};
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
`;
