import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { Step } from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import RightArrow from '@hellodarwin/icons/dist/icons/BottomArrow';
import LeftArrow from '@hellodarwin/icons/dist/icons/LeftArrow';
import Button from 'antd/es/button';
import React from 'react';

export interface GinStepTranslateButtonsProps {
  generateSectionFromTranslation: (
    section: (keyof Step)[],
    otherContent: string[],
    targetLocale: string,
  ) => void;
  contentEn: string[];
  contentFr: string[];
  sectionEn: (keyof Step)[];
  sectionFr: (keyof Step)[];
}
const GinStepTranslateButtons: React.FC<GinStepTranslateButtonsProps> = ({
  generateSectionFromTranslation,
  contentEn,
  contentFr,
  sectionEn,
  sectionFr,
}) => {
  const { t } = useTranslations();

  const isLocaleEmpty = (locale: string) => {
    if (locale === 'en') {
      return !!!contentEn || contentEn.every((str) => str === '');
    } else {
      return !!!contentFr || contentFr.every((str) => str === '');
    }
  };

  return (
    <Div gap={16} flex="row" justify="center" align="center">
      <Button
        onClick={() => {
          generateSectionFromTranslation(sectionEn, contentFr, 'en');
        }}
        disabled={isLocaleEmpty('fr')}
        size="small"
      >
        <LeftArrow size={16}></LeftArrow>
      </Button>
      <Typography textAlign="center">{t('grant_single|translate')}</Typography>
      <Button
        onClick={() => {
          generateSectionFromTranslation(sectionFr, contentEn, 'fr');
        }}
        disabled={isLocaleEmpty('en')}
        size="small"
      >
        <RightArrow size={16}></RightArrow>
      </Button>
    </Div>
  );
};

export default GinStepTranslateButtons;
