var GrantEligibilitySections;
(function (GrantEligibilitySections) {
    GrantEligibilitySections["EligibilityCriteria"] = "eligibility_criteria";
    GrantEligibilitySections["EligibleExpenses"] = "eligible_expenses";
    GrantEligibilitySections["WhoCanApply"] = "who_can_apply";
    GrantEligibilitySections["WhoCannotApply"] = "who_cannot_apply";
    GrantEligibilitySections["ProjectActivity"] = "project_activity";
    GrantEligibilitySections["Zone"] = "zone";
})(GrantEligibilitySections || (GrantEligibilitySections = {}));
export default GrantEligibilitySections;
