import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslations } from '../../../features/providers/translations-provider';
import Div from '../../common/div';
import SeeMoreList from '../../common/see-more/see-more-list';
import Loading from '../../loading';
import ProfileSection from '../profile/profile-section';
import RfpCard from './RfpCard';
const ProfileRfps = ({ rfps, isLoading, isEditable = false, id, }) => {
    const { t } = useTranslations();
    const content = useMemo(() => {
        const content = [];
        let index = 0;
        rfps.forEach((rfp) => {
            index++;
            content.push(_jsx(RfpCard, Object.assign({}, rfp)));
        });
        return content;
    }, [rfps]);
    return (_jsx(_Fragment, { children: _jsx(ProfileSection, { title: t('profile_projects|projectsTitle'), isEditable: isEditable, id: id, hide: !rfps.length, children: _jsx(Div, { style: { display: 'flex', flexDirection: 'column' }, gap: 32, children: isLoading ? (_jsx(Loading, {})) : (_jsx(SeeMoreList, { limit: 3, grid: { sm: 1, md: 1, lg: 1, column: 1, gutter: 24 }, content: content })) }) }) }));
};
export default ProfileRfps;
