var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ContainerIcons } from '@hellodarwin/icons/dist/backgrounds/icon-entities';
import LeftArrow from '@hellodarwin/icons/dist/icons/LeftArrow';
import { useEffect, useState } from 'react';
import { useAppData } from '../../../../features/providers/app-data-provider';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../button';
import Div from '../../div';
import AppHeader from '../app-layout/app-header';
import PageContainer from '../page-layout/PageContainer';
import SidebarPageSider from './sidebar-page-sider';
import { SidebarPageBody, SidebarPageContent } from './styles';
const SidebarPageLayout = (_a) => {
    var { tabTitle, styles, noGap, children, className, noPadding, leftSidebarBlocks = [], rightSidebarBlocks = [], defaultNavCollapsed = false, defaultLeftSidebarCollapsed = false, defaultRightSidebarCollapsed = false, underSearch, noFooter, fitContent, actions, handleBack, onSidebarChange } = _a, appHeaderProps = __rest(_a, ["tabTitle", "styles", "noGap", "children", "className", "noPadding", "leftSidebarBlocks", "rightSidebarBlocks", "defaultNavCollapsed", "defaultLeftSidebarCollapsed", "defaultRightSidebarCollapsed", "underSearch", "noFooter", "fitContent", "actions", "handleBack", "onSidebarChange"]);
    const { title, app } = appHeaderProps;
    const { t } = useTranslations();
    const theme = useTheme();
    const { setNavCollapsed } = useAppData();
    const [leftNavCollapsed, setLeftNavCollapsed] = useState(defaultLeftSidebarCollapsed);
    const [rightNavCollapsed, setRightNavCollapsed] = useState(defaultRightSidebarCollapsed);
    const handleLeftNavCollapsed = () => setLeftNavCollapsed(!leftNavCollapsed);
    const handleRightNavCollapsed = () => setRightNavCollapsed(!rightNavCollapsed);
    useEffect(() => {
        if (leftNavCollapsed !== defaultLeftSidebarCollapsed) {
            setLeftNavCollapsed(defaultLeftSidebarCollapsed);
        }
    }, [defaultLeftSidebarCollapsed]);
    useEffect(() => {
        if (rightNavCollapsed !== defaultRightSidebarCollapsed) {
            setRightNavCollapsed(defaultRightSidebarCollapsed);
        }
    }, [defaultRightSidebarCollapsed]);
    useEffect(() => {
        !!onSidebarChange && onSidebarChange('left', leftNavCollapsed);
    }, [leftNavCollapsed]);
    useEffect(() => {
        !!onSidebarChange && onSidebarChange('right', rightNavCollapsed);
    }, [rightNavCollapsed]);
    useEffect(() => {
        if (!!defaultNavCollapsed) {
            setNavCollapsed(true);
        }
    }, [defaultNavCollapsed]);
    useEffect(() => {
        if (typeof document !== 'undefined') {
            if (!!tabTitle) {
                document.title = `${tabTitle} | ${t(`appsNames|${app}`)}`;
            }
            else if ((typeof title === 'string' || title instanceof String) &&
                !!title) {
                document.title = `${title} | ${t(`appsNames|${app}`)}`;
            }
            else {
                document.title = t(`appsNames|${app}`);
            }
        }
    }, [tabTitle, title]);
    const containerProps = {
        className,
        cap: app === 'admin' ? theme.caps.xl : theme.caps.normal,
        styles: Object.assign(Object.assign({}, styles), { container: Object.assign(Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.container), { position: 'relative' }) }),
        noPadding: true,
        app,
    };
    return (_jsx(_Fragment, { children: _jsxs(PageContainer, Object.assign({}, containerProps, { icons: [
                {
                    side: 'right',
                    name: ContainerIcons.Group1Filled,
                    color: theme.colors.beige_1,
                    top: 300,
                    margin: -300,
                    width: 900,
                    height: 800,
                },
            ], styles: {
                container: { height: '100vh' },
                content: {
                    position: 'relative',
                    gap: 0,
                    height: '100vh',
                    overflow: 'hidden',
                    justifyContent: 'flex-start',
                },
            }, noFooter: noFooter, children: [_jsx(AppHeader, Object.assign({}, appHeaderProps, { style: Object.assign(Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.header), { position: 'static' }) })), _jsxs(Div, { borderColor: theme.colors.primary, backgroundColor: theme.colors.beige_1, justify: "space-between", bordersSide: "horizontal", flex: "row", align: "center", style: {
                        borderColor: theme.colors.grey_5,
                        padding: '8px 32px',
                        height: 56,
                    }, children: [_jsx("div", { children: !!handleBack && (_jsx(Button, { size: "circle", defaultStyle: theme.colors.white_1, onClick: handleBack, headingIcon: _jsx(LeftArrow, { size: 18 }), style: {
                                    transition: 'none',
                                } })) }), _jsxs("div", { children: [underSearch && _jsx(_Fragment, { children: underSearch }), actions] })] }), _jsxs(SidebarPageContent, { children: [_jsx(SidebarPageSider, { sidebarBlocks: leftSidebarBlocks, isCollapsed: leftNavCollapsed, side: "left", handleCollapse: handleLeftNavCollapsed }), _jsx(SidebarPageBody, { style: Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.body), children: children }), _jsx(SidebarPageSider, { sidebarBlocks: rightSidebarBlocks, isCollapsed: rightNavCollapsed, side: "right", handleCollapse: handleRightNavCollapsed })] })] })) }));
};
export default SidebarPageLayout;
