import { css } from 'styled-components';
export const media = {
    tablet: (...args) => css `
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      ${css(...args)}
    }
  `,
    l: (...args) => css `
    @media (max-width: ${({ theme }) => theme.breakpoints.l}px) {
      ${css(...args)}
    }
  `,
    xl: (...args) => css `
    @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
      ${css(...args)}
    }
  `,
    xxl: (...args) => css `
    @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
      ${css(...args)}
    }
  `,
    wide: (...args) => css `
    @media (max-width: ${({ theme }) => theme.breakpoints.wide}px) {
      ${css(...args)}
    }
  `,
};
