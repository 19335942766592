import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { Program } from '@hellodarwin/core/lib/features/entities/programs-entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Empty from 'antd/es/empty';
import Select from 'antd/es/select';
import { useEffect, useMemo, useState } from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../app/app-hooks';
import {
  getCompanyPrograms,
  selectProgramsByCompanyId,
  selectProgramsByCompanyIds,
  selectProgramsLoading,
} from '../../../../../../../features/api/slices/programs-slice';
import { useAdminApi } from '../../../../../../../features/api/use-admin-api';
import { ProjectApplicationFormProps } from '../../../types';
import { selectCompanyById } from '../../../../../../../features/api/slices/companies-slice';

const ProjectSearchExistingApplication: React.FC<
  ProjectApplicationFormProps
> = ({
  handleSaveApplication,
  companyId,
  handleCancel,
  projectApplicationsIds,
  projectId,
}) => {
  const { t } = useTranslations();
  const theme = useTheme();

  const api = useAdminApi();
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector((state) =>
    selectProgramsLoading(state, 'fetchCompanyPrograms'),
  );
  const company = useAppSelector((state) =>
    companyId ? selectCompanyById(state, companyId) : undefined,
  );
  const applications = useAppSelector((state) =>
    company?.subsidiary_ids != null && company.subsidiary_ids.length > 0
      ? selectProgramsByCompanyIds(state, [
          ...company.subsidiary_ids,
          companyId,
        ])
      : selectProgramsByCompanyId(state, companyId),
  );
  const [subsidiaryNames, setSubsidiaryNames] = useState<
    Record<string, string>
  >({});

  const [activeApplication, setActiveApplication] = useState<
    Program | undefined
  >(undefined);

  useEffect(() => {
    if (!!companyId && companyId !== '') {
      dispatch(
        company?.subsidiary_ids != null && company.subsidiary_ids.length > 0
          ? getCompanyPrograms({
              api,
              companyId,
              subsidiaryIds: company.subsidiary_ids,
            })
          : getCompanyPrograms({ api, companyId }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (company != null && company.subsidiary_ids != null) {
      api
        .fetchCompanyNames(company.subsidiary_ids)
        .then((namesMap) => {
          setSubsidiaryNames(namesMap);
        })
        .catch((error) => {
          console.error('Error fetching company names', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const onSave = () => {
    if (!!activeApplication?.program_id) {
      if (handleSaveApplication) {
        handleSaveApplication(activeApplication);
      }
      handleCancel();
    }
  };

  const handleSelect = (applicationId: string) => {
    setActiveApplication(
      applications.find(
        (application) => application.program_id === applicationId,
      ),
    );
  };

  function buildOptions(
    applications: Program[],
    projectAppsIds: string[],
    filterByCompany?: string,
  ) {
    return applications
      .filter((application) =>
        filterByCompany
          ? application.program_company_id === filterByCompany
          : true,
      )
      .filter((app) => !projectAppsIds.includes(app.program_id))
      .map((app) => ({
        label: app.program_name ?? '',
        value: app.program_id,
      }));
  }

  const optionGroups = useMemo(() => {
    const groups: {
      label: string;
      options: { label: string; value: string }[];
    }[] = [];

    if (!company?.subsidiary_ids?.length) {
      groups.push({
        label: company?.name ?? '',
        options: buildOptions(applications, projectApplicationsIds),
      });
    } else {
      groups.push({
        label: company.name,
        options: buildOptions(applications, projectApplicationsIds, companyId),
      });

      for (const subId in subsidiaryNames) {
        groups.push({
          label: subsidiaryNames[subId],
          options: buildOptions(applications, projectApplicationsIds, subId),
        });
      }
    }

    return groups;
  }, [
    applications,
    projectApplicationsIds,
    company,
    companyId,
    subsidiaryNames,
  ]);

  return (
    <Div flex="column" gap={12} style={{ overflow: 'hidden' }}>
      <Typography elementTheme="h5">
        {t(`project|searchApplicationTitle`)}
      </Typography>

      {!optionGroups.length ? (
        <Empty description={t('project|noExistingProjectApplication')} />
      ) : (
        <>
          <Select
            placeholder={t(`project|searchApplicationsPlaceholder`)}
            loading={isLoading}
            options={optionGroups}
            size="large"
            onSelect={(value) => handleSelect(value)}
          />
          <Button
            onClick={onSave}
            defaultStyle={theme.colors.purple_2}
            style={{ alignSelf: 'flex-end' }}
          >
            {t('button|assign')}
          </Button>
        </>
      )}
    </Div>
  );
};

export default ProjectSearchExistingApplication;
