import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import Network from '@hellodarwin/icons/dist/icons/Network';
import Refresh from '@hellodarwin/icons/dist/icons/Refresh';
import { useNavigate } from '../../features/providers/location-provider';
import { usePlatform } from '../../features/providers/platform-provider';
import { useTranslations } from '../../features/providers/translations-provider';
import Div from '../common/div';
import PageLayout from '../common/layout/page-layout';
import ErrorBox from './error-box';
const ErrorNetwork = ({ errorMessage }) => {
    const { t } = useTranslations();
    const { app } = usePlatform();
    const navigate = useNavigate();
    const refreshPage = () => {
        errorMessage.toLowerCase().includes('state')
            ? navigate('/', { replace: true })
            : navigate(0);
    };
    if (!errorMessage)
        return _jsx(_Fragment, {});
    return (_jsx(PageLayout, { tabTitle: "Network Error", app: app, breadcrumbs: [], children: _jsx(Div, { flex: "column", justify: "center", align: "center", style: {
                minHeight: `calc(100vh - 40px - 32px - 54px - 64px - 40px)`,
            }, children: _jsx(ErrorBox, { errorMessage: t(`errorsPage|${errorMessage.replaceAll(' ', '')}`), ErrorIcon: Network, ctaAction: refreshPage, CtaIcon: Refresh }) }) }));
};
export default ErrorNetwork;
