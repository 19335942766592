import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTheme } from 'styled-components';
import { getStringDate } from '../../../../features/helpers/get-formatted-date';
import useLocale from '../../../../features/providers/locale-provider';
import { useTranslations } from '../../../../features/providers/translations-provider';
import Div from '../../../common/div';
import Link from '../../../common/link';
import Typography from '../../../common/typography';
import useOwnerLabelAndPath from '../utils/use-owner-label-and-path';
const AssetCardUpdateInfos = (asset) => {
    const theme = useTheme();
    const { selectedLocale } = useLocale();
    const { t } = useTranslations();
    const { last_uploaded_by, updated_at } = asset;
    const ownerLabelAndPath = useOwnerLabelAndPath(last_uploaded_by);
    return (_jsxs(Div, { flex: "column", gap: 6, children: [_jsx(Typography, { elementTheme: "overline", color: theme.colors.grey_2, style: { marginLeft: 12 }, children: t('files_gin|last_update') }), _jsxs("ul", { style: { margin: 0, marginBottom: 12 }, children: [_jsx("li", { children: _jsx(Typography, { elementTheme: "body3", style: { alignSelf: 'stretch' }, children: getStringDate(new Date(`${updated_at}`), selectedLocale) }) }), !!(last_uploaded_by === null || last_uploaded_by === void 0 ? void 0 : last_uploaded_by.owner_name) && (_jsx(_Fragment, { children: _jsx("li", { children: _jsxs(Typography, { elementTheme: "body3", color: theme.colors.grey_1, children: [t('files_gin|by'), ' ', _jsx(Link, { to: ownerLabelAndPath.path, target: "_blank", children: ownerLabelAndPath.label })] }) }) }))] })] }));
};
export default AssetCardUpdateInfos;
