import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import useLocale from '../../../../features/providers/locale-provider';
import getItemTranslatedLabel from '../../form-builder/utils/get-item-translated-label';
import { FormSubmissionFieldsListContainer } from './styles';
const FormSubmissionFieldsList = ({ formSubmission, }) => {
    const { selectedLocale } = useLocale();
    const columns = [
        {
            title: 'Question',
            key: 'content_en',
            render(value, record, index) {
                return getItemTranslatedLabel({
                    label_en: record.form_item.content_en,
                    label_fr: record.form_item.content_fr,
                    locale: selectedLocale,
                    fallback: record.form_item.form_item_id,
                });
            },
        },
        {
            title: 'Value',
            key: 'value',
            width: 380,
            render(value, record, index) {
                return record.forms_submissions_item.value;
            },
        },
    ];
    const activeItems = useMemo(() => formSubmission.all_items.filter((item) => !!item.forms_submissions_item.form_submission_item_id), [formSubmission]);
    return (_jsx(FormSubmissionFieldsListContainer, { columns: columns, dataSource: activeItems, pagination: {
            defaultPageSize: 20,
            position: ['bottomCenter'],
            showSizeChanger: true,
        } }));
};
export default FormSubmissionFieldsList;
