var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import SingleGrantSections from '../../../features/enums/single-grant-sections';
import { useTranslations } from '../../../features/providers/translations-provider';
import AdminCard from '../../admin/admin-card';
import Container from '../../common/container';
import Div from '../../common/div';
import FilesList from '../../files/files-list';
import SingleGrant from '../../grants/single';
import GrantSteps from '../../grants/single/grant-steps';
import ProgramEmptyState from './program-empty-state';
import ProgramSingleHeader from './program-single-header';
export var ProgramSingleTabs;
(function (ProgramSingleTabs) {
    ProgramSingleTabs["ProgramGrantSteps"] = "steps";
    ProgramSingleTabs["ProgramInfo"] = "program";
    ProgramSingleTabs["ProgramFiles"] = "files";
})(ProgramSingleTabs || (ProgramSingleTabs = {}));
const ProgramSingle = (props) => {
    const { t } = useTranslations();
    const { admin, isLoading, grant, grantSteps, fileList, handleTabChange, onChangeStepCheckBox, activeTab } = props, program = __rest(props, ["admin", "isLoading", "grant", "grantSteps", "fileList", "handleTabChange", "onChangeStepCheckBox", "activeTab"]);
    if (!program || !program.program_id)
        return _jsx(ProgramEmptyState, {});
    const tabs = useMemo(() => {
        const tabs = [
            {
                key: ProgramSingleTabs.ProgramGrantSteps,
                label: t(`gin_section|steps`),
                children: (_jsx(GrantSteps, { grantSteps: grantSteps, onChangeStepCheckBox: onChangeStepCheckBox })),
            },
            {
                key: ProgramSingleTabs.ProgramInfo,
                label: t(`gin_section|grant`),
                children: (_jsx(SingleGrant, { grant: grant, sections: [
                        SingleGrantSections.Summary,
                        SingleGrantSections.Preview,
                        SingleGrantSections.TermsAndConditions,
                        SingleGrantSections.Projects,
                        SingleGrantSections.Admissibility,
                        SingleGrantSections.Criteria,
                        SingleGrantSections.Apply,
                        SingleGrantSections.OtherInfo,
                        SingleGrantSections.Documents,
                        SingleGrantSections.Contact,
                    ] })),
            },
        ];
        if (!!fileList) {
            tabs.push({
                key: ProgramSingleTabs.ProgramFiles,
                label: t(`gin_section|files`),
                children: (_jsx(Div, { flex: "column", gap: 24, style: { paddingTop: 24 }, children: _jsx(FilesList, Object.assign({}, fileList, { noHeader: true })) })),
            });
        }
        return tabs;
    }, [grant, fileList]);
    return (_jsxs(Div, { flex: "column", gap: 32, children: [_jsx(ProgramSingleHeader, Object.assign({}, props)), _jsx(Container, { tabs: tabs, activeTab: activeTab, handleTabClick: handleTabChange }), !!admin && (_jsx(AdminCard, { admin: admin, title: t('adminCard|adminCardGenericTitle'), subtitle: t('adminCard|adminCardGenericSubtitle'), fullWidth: true }))] }));
};
export default ProgramSingle;
