import FilesList from '@hellodarwin/core/lib/components/files/files-list';
import {
  AssetEntity,
  AssetHiddenType,
  AssetItemType,
  AssetWithOwnersEntity,
} from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app';
import {
  addAssetLinks,
  deleteAssetLink,
  downloadAsset,
  fetchAssetsFromCompany,
  fetchAssetsFromRecord,
  hideShowAsset,
  selectAllRecordAssets,
  uploadAsset,
} from '../../../../features/api/slices/assets-slice';
import { fetchProgramById } from '../../../../features/api/slices/programs-slice';
import {
  useAdminApi,
  useNewAdminApi,
} from '../../../../features/api/use-admin-api';
import { GinBlockComponentProps } from '../gin-block';

export type setSelectedAssetFunc = React.Dispatch<
  React.SetStateAction<AssetWithOwnersEntity | undefined>
>;
const FileSectionGin = ({
  entityId,
  type,
  isEditing,
  handleEditing,
  additionalOwners,
}: GinBlockComponentProps) => {
  const recordType = useMemo(
    () =>
      type === 'company'
        ? 'company'
        : type === 'application'
          ? 'application'
          : 'gin',
    [type],
  );
  const api = useNewAdminApi();
  const adminApi = useAdminApi();
  const dispatch = useAppDispatch();
  const { t } = useTranslations();
  const assets = useAppSelector((state) =>
    selectAllRecordAssets(state, entityId),
  );

  useEffect(() => {
    if (!!entityId) {
      if (recordType === 'company') {
        dispatch(
          fetchAssetsFromCompany({
            api,
            companyId: entityId,
          }),
        );
      } else {
        dispatch(
          fetchAssetsFromRecord({
            api,
            record: {
              record_id: entityId,
              record_type: recordType,
            },
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, recordType]);

  const [fetchedGinAssets, setFetchedGinAssets] = useState<AssetEntity[]>([]);

  useEffect(() => {
    const fetchGinRelatedAssets = async () => {
      if (recordType === 'application') {
        try {
          const applicationResponse = await dispatch(
            fetchProgramById({
              api: adminApi,
              programId: entityId,
            }),
          ).unwrap();

          const grantId = applicationResponse.program_grant_id;
          if (!grantId) {
            return;
          }

          const response = await dispatch(
            fetchAssetsFromRecord({
              api,
              record: {
                record_id: grantId,
                record_type: 'gin',
              },
            }),
          ).unwrap();

          setFetchedGinAssets(response);
        } catch (error) {
          console.error('Failed to fetch Gin related assets:', error);
        }
      }
    };

    fetchGinRelatedAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredGinRelatedAssets = useMemo(() => {
    return fetchedGinAssets.filter(
      (asset: AssetEntity) =>
        !assets.some(
          (existingAsset: AssetEntity) =>
            existingAsset.asset_id === asset.asset_id,
        ),
    );
  }, [fetchedGinAssets, assets]);

  const handleDownloadClick = async (asset: AssetItemType) => {
    try {
      const file = asset as AssetWithOwnersEntity;
      const url = (
        await dispatch(downloadAsset({ api, asset_id: file.asset_id })).unwrap()
      ).link;
      window.open(url, 'download');
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveAsset = async (
    formData: FormData,
  ): Promise<'rejected' | 'fulfilled'> =>
    (
      await dispatch(
        uploadAsset({
          api,
          formData,
        }),
      )
    ).meta.requestStatus;

  const handleAddSuggestedAsset = async (
    assetId: string,
  ): Promise<'rejected' | 'fulfilled'> => {
    try {
      const result = await dispatch(
        addAssetLinks({
          api,
          assetId,
          record: {
            record_id: entityId,
            record_type: recordType,
          },
          additionalOwners,
        }),
      ).unwrap();

      return result ? 'fulfilled' : 'rejected';
    } catch (error) {
      console.error('Failed to add suggested asset:', error);
      return 'rejected';
    }
  };

  const handleHideShowAssets = (asset_id: string, hide: AssetHiddenType) => {
    dispatch(hideShowAsset({ api, asset_id, hide }));
  };
  const handleDelete = async (assetId: string) => {
    try {
      await dispatch(
        deleteAssetLink({
          api,
          assetId,
          record: {
            record_id: entityId,
            record_type: recordType,
          },
        }),
      ).unwrap();
    } catch (error) {
      console.error('Failed to delete asset link:', error);
    }
  };
  return (
    <>
      <FilesList
        vaultPath=""
        items={assets}
        activeRecord={undefined}
        handleDownload={handleDownloadClick}
        handleSaveAsset={handleSaveAsset}
        noHeader
        defaultIsRow={true}
        additionalOwners={additionalOwners}
        rootRecord={{
          record_id: entityId,
          record_type: recordType,
        }}
        isEdit
        isMultiFiles
        multiDrawerOpen={isEditing}
        handleMultiDrawer={handleEditing}
        handleDeleteAsset={handleDelete}
        handleHideShowAsset={handleHideShowAssets}
        suggestedAssets={filteredGinRelatedAssets}
        handleAddSuggestedAsset={handleAddSuggestedAsset}
        suggestedAssetsSectionTitle={t('files_gin|existing_grant_files')}
      />
    </>
  );
};

export default FileSectionGin;
