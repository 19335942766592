import styled from 'styled-components';
import Button from '../../common/button';
export const AssetUploadButton = styled(Button) `
  ${({ $ghost, theme }) => !$ghost
    ? `
box-shadow: ${theme.appearance.box_shadow};
border: 1px solid ${theme.colors.purple_2};
&:hover {
  box-shadow: ${theme.appearance.box_shadow_hover};
  }
  `
    : undefined}
`;
