import styled, { css } from '../../../plugins/styled';
import { fadeIn, fadeOut } from '../../../theme/keyframes';
export const ListContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 54px;

  animation: ${fadeIn} 200ms linear;
  opacity: 1;
  .fade-out {
    animation: ${fadeOut} 200ms linear;
    opacity: 0;
  }
`;
const HorizontalLayoutStyle = css `
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 32px;
  flex-wrap: wrap;
`;
const VerticalLayoutStyle = css `
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const GridLayoutStyle = css `
  display: grid;
  grid-template-columns: ${(props) => { var _a, _b; return `repeat(${!!((_a = props.$grid) === null || _a === void 0 ? void 0 : _a.column) ? (_b = props.$grid) === null || _b === void 0 ? void 0 : _b.column : 1}, 1fr)`; }};

  @media (min-width: ${(props) => props.theme.breakpoints.xl + 1}px) {
    ${(props) => {
    var _a, _b;
    return !!((_a = props.$grid) === null || _a === void 0 ? void 0 : _a.xxl)
        ? `grid-template-columns: repeat(${(_b = props.$grid) === null || _b === void 0 ? void 0 : _b.xxl}, 1fr)`
        : undefined;
}};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.xl}px) {
    ${(props) => {
    var _a, _b;
    return !!((_a = props.$grid) === null || _a === void 0 ? void 0 : _a.xl)
        ? `grid-template-columns: repeat(${(_b = props.$grid) === null || _b === void 0 ? void 0 : _b.xl}, 1fr)`
        : undefined;
}};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.l}px) {
    ${(props) => {
    var _a, _b;
    return !!((_a = props.$grid) === null || _a === void 0 ? void 0 : _a.lg)
        ? `grid-template-columns: repeat(${(_b = props.$grid) === null || _b === void 0 ? void 0 : _b.lg}, 1fr)`
        : undefined;
}};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    ${(props) => {
    var _a, _b;
    return !!((_a = props.$grid) === null || _a === void 0 ? void 0 : _a.md)
        ? `grid-template-columns: repeat(${(_b = props.$grid) === null || _b === void 0 ? void 0 : _b.md}, 1fr)`
        : undefined;
}};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    ${(props) => {
    var _a, _b;
    return !!((_a = props.$grid) === null || _a === void 0 ? void 0 : _a.sm)
        ? `grid-template-columns: repeat(${(_b = props.$grid) === null || _b === void 0 ? void 0 : _b.sm}, 1fr)`
        : undefined;
}};
  }
  ${(props) => { var _a, _b; return !!((_a = props.$grid) === null || _a === void 0 ? void 0 : _a.gutter) ? `column-gap:${(_b = props.$grid) === null || _b === void 0 ? void 0 : _b.gutter}px` : undefined; }}
`;
export const ListContent = styled.div `
  ${(props) => !!props.$grid
    ? GridLayoutStyle
    : props.$layout === 'horizontal'
        ? HorizontalLayoutStyle
        : VerticalLayoutStyle};
  ${(props) => !!props.$bordered
    ? `border: 1px solid ${props.theme.colors.primary};`
    : undefined}
  ${(props) => !!props.$divider
    ? `
      ${ListItemContainer} {
        &::after{
          content:"";
          display:block;
          width:100%;
          margin:8px 0;
          border-bottom: 1px solid ${props.theme.colors.grey_4};
        }
        &:last-child {
          &::after{
            display:none;
          }
        }
      }
      `
    : undefined}
`;
export const ListItemContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > * {
    width: 100%;
    height: 100%;
  }
`;
