import SidebarPageLayout from '@hellodarwin/core/lib/components/common/layout/sidebar-page-layout.tsx';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import GamePlanViewer from '../../components/projects/game-plan-viewer';
import {
  fetchCompany,
  selectCompanyById,
} from '../../features/api/slices/companies-slice';
import { useAdminApi } from '../../features/api/use-admin-api';

export interface GamePlanViewerPageProps {}

const GamePlanViewerPage: React.FC<GamePlanViewerPageProps> = () => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { t } = useTranslations();
  const params = useParams<{
    id: string;
  }>();

  const companyId = useMemo(() => params.id ?? '', [params.id]);
  const company = useAppSelector((state) =>
    selectCompanyById(state, companyId),
  );

  useEffect(() => {
    dispatch(fetchCompany({ api, companyId, withGrants: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  if (!company?.company_id) return <></>;

  return (
    <SidebarPageLayout
      goBackTo={`/companies/${company.company_id}`}
      noFooter
      app={'admin'}
      title={t('companyAdminPage|gamePlan')}
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: t('companyAdminPage|companies'),
          path: '/companies',
        },
        {
          breadcrumbName: company?.name,
          path: `/companies/${company.company_id}`,
        },
        {
          breadcrumbName: t('companyAdminPage|gamePlan'),
        },
      ]}
    >
      <GamePlanViewer company={company} />
    </SidebarPageLayout>
  );
};

export default GamePlanViewerPage;
