import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Form from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import Radio from 'antd/es/radio';
import Space from 'antd/es/space';
import { useState } from 'react';
import { useTranslation } from '../../plugins/i18n';
import Button from '../common/button';
import Div from '../common/div';
import ModalLayoutSimple from '../common/hd-modal/layouts/modal-simple';
import Typography from '../common/typography';
const RfpIgnoreModal = ({ match, closeModal, handleIgnore, modalVisible, }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [ignoreReason, setIgnoreReason] = useState('Rfp type');
    const initialValues = {
        ignore_reason: 'Rfp type',
        ignore_reason_specified: '',
    };
    const handleCancel = async () => {
        form.resetFields();
        closeModal();
    };
    const handleFinish = async () => {
        handleIgnore(match.match_id, form.getFieldValue('ignore_reason'), form.getFieldValue('ignore_reason_specified'));
        handleCancel();
    };
    const handleChange = (e) => {
        if (!form.getFieldValue('ignore_reason')) {
            form.setFieldsValue({
                ['ignore_reason']: 'Other',
            });
        }
    };
    return (_jsx(ModalLayoutSimple, { open: modalVisible, handleCancel: handleCancel, children: _jsxs(Div, { flex: "column", gap: 16, children: [_jsx(Typography.Title, { level: 4, children: t('project|ignoreModal.title') }), _jsx(Form, { form: form, layout: 'vertical', size: "large", onFinish: handleFinish, style: { width: '100%' }, initialValues: initialValues, name: 'ignore-rfp', children: _jsxs(Div, { flex: "column", gap: 32, children: [_jsxs(Div, { flex: "column", gap: 8, children: [_jsx(Form.Item, { name: "ignore_reason", rules: [
                                            {
                                                required: true,
                                                message: t('project|ignoreModal.form.validation'),
                                            },
                                        ], children: _jsx(Radio.Group, { size: 'large', onChange: (e) => setIgnoreReason(e.target.value), defaultValue: ignoreReason, children: _jsxs(Space, { direction: "vertical", children: [_jsx(Radio, { value: 'Rfp type', children: _jsx(Typography, { elementTheme: "body2", children: t('project|ignoreModal.reasons.rfpType') }) }), _jsx(Radio, { value: 'Client need', children: _jsx(Typography, { elementTheme: "body2", children: t('project|ignoreModal.reasons.clientNeed') }) }), _jsx(Radio, { value: 'Budget', children: _jsx(Typography, { elementTheme: "body2", children: t('project|ignoreModal.reasons.budget') }) }), _jsx(Radio, { value: 'Price', children: _jsx(Typography, { elementTheme: "body2", children: t('project|ignoreModal.reasons.price') }) }), _jsx(Radio, { value: 'Timeline', children: _jsx(Typography, { elementTheme: "body2", children: t('project|ignoreModal.reasons.timeline') }) }), _jsx(Radio, { value: 'Other', children: _jsx(Typography, { elementTheme: "body2", children: t('project|ignoreModal.reasons.other') }) })] }) }) }), ignoreReason === 'Other' && (_jsx(Form.Item, { name: "ignore_reason_specified", children: _jsx(TextArea, { allowClear: true, placeholder: t('project|ignoreModal.reasons.pleaseSpecify'), onChange: handleChange }) }))] }), _jsx(Div, { flex: "column", align: "flex-end", children: _jsx(Button, { onClick: handleFinish, size: 'large', children: t('button.ignoreRfp') }) })] }) })] }) }));
};
export default RfpIgnoreModal;
