import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Form from 'antd/es/form';
import React from 'react';
import { useTheme } from '../../../../../../plugins/styled';
import Button from '../../../../../common/button';
import Div from '../../../../../common/div';
import FormItem from '../../../../form-layouts/form-item';
import FormLabel from '../../../../form-layouts/form-label';
const renderInput = ({ input, activeItem, deleteItem, form, }) => {
    var _a;
    const theme = useTheme();
    const isArray = Array.isArray(activeItem[input.name]);
    const isNode = React.isValidElement(input.children);
    const addRowToArray = (itm) => {
        const value = form.getFieldValue(itm.name);
        form.setFieldValue(itm.name, [...value, '']);
    };
    const removeRowFromArray = (itm, index) => {
        const value = form.getFieldValue(itm.name);
        delete value[index];
        form.setFieldValue(itm.name, value);
    };
    return (_jsx(FormItem, { name: input.name, label: _jsx(FormLabel, { label: input.label, actions: _jsxs(_Fragment, { children: [input.actions, isArray &&
                        (isNode ? (_jsx(Button, { transparent: true, defaultStyle: theme.colors.primary, onClick: () => addRowToArray(input), children: _jsx(AddNew, { size: 16 }) })) : (!!((_a = input.children) === null || _a === void 0 ? void 0 : _a.addItm))) ? (_jsx(Button, { transparent: true, defaultStyle: theme.colors.primary, onClick: () => input.children.addItm(), children: _jsx(AddNew, { size: 16 }) })) : undefined] }) }), rules: input.rules, children: React.isValidElement(input.children) ? (isArray ? (_jsx(Form.List, { name: input.name, children: (fields) => {
                return (_jsx("div", { children: React.Children.toArray(fields.map((field, i) => (_jsxs(Div, { flex: "row", gap: 8, align: "center", children: [_jsx(FormItem, Object.assign({ style: { margin: 0, width: '100%' } }, field, { fieldId: 'trigger_value', children: input.children })), !!deleteItem && (_jsx(Button, { transparent: true, defaultStyle: theme.colors.primary, onClick: () => removeRowFromArray(input, i), children: _jsx(Delete, { size: 16 }) }))] })))) }));
            } })) : (input.children)) : (input.children.render(activeItem)) }));
};
export default renderInput;
