import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import DatePicker from 'antd/es/date-picker';
import Form from 'antd/es/form';
import { useForm } from 'antd/es/form/Form';
import InputNumber from 'antd/es/input-number';
import Input from 'antd/es/input/Input';
import Select from 'antd/es/select';
import dayjs from 'dayjs';
import { Suspense, useEffect, useState } from 'react';
import handleDispatchResponse from '../../../../features/helpers/handle-dispatch-response';
import { useCompanyInfos } from '../../../../features/providers/company-infos-provider';
import { useProfileEdition } from '../../../../features/providers/profile-edition-provider';
import { useTranslation } from '../../../../plugins/i18n';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
import ModalWithHeaderFooter from '../../../common/hd-modal/layouts/modal-with-header-footer';
import Loading from '../../../loading';
import FormItem from '../../form-layouts/form-item';
import { useCompanySize } from '../../utils/company-infos';
import validateWebsite from '../../utils/validate-website';
const FastInfosForm = ({ handleCancel }) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const theme = useTheme();
    const { provider } = useCompanyInfos();
    const { functions, handleFooter } = useProfileEdition();
    const { isLoading } = functions;
    const companySizeOptions = useCompanySize();
    const initialValues = {
        company_website: provider.website || '',
        company_size: provider.size || '',
        provider_hourly_rate: provider.hourly_rate || 0,
        company_founded_at: provider.founded_at || '',
    };
    const [foundedAt, setFoundedAt] = useState(dayjs(provider.founded_at || new Date()));
    const handleFoundedAtChange = (date) => {
        if (date !== null)
            setFoundedAt(date);
    };
    useEffect(() => {
        form.setFieldValue('company_founded_at', foundedAt);
    }, [foundedAt]);
    useEffect(() => {
        setFoundedAt(dayjs(provider.founded_at || new Date()));
    }, [provider]);
    const handleSave = async () => {
        form.validateFields().then((newCompany) => {
            const { company_id } = provider;
            const data = new FormData();
            data.append('request', JSON.stringify(Object.assign(Object.assign({}, newCompany), { founded_at: foundedAt, company_id })));
            !!functions.updateProfile &&
                functions
                    .updateProfile({ data })
                    .then((res) => handleDispatchResponse(res, t('notification.saved'), t('notification.saving_error')))
                    .finally(handleCancel);
        });
    };
    useEffect(() => {
        handleFooter(_jsx(ModalWithHeaderFooter.Footer, { actions: _jsxs(_Fragment, { children: [_jsx(Button, { defaultStyle: theme.colors.transparent, onClick: handleCancel, size: "small", children: t('button.cancel') }), _jsx(Button, { onClick: handleSave, size: "small", defaultStyle: theme.colors.purple_1, children: t('button.save') })] }) }));
    }, []);
    return (_jsx(Div, { flex: "row", align: "center", justify: "stretch", children: isLoading ? (_jsx(Loading, {})) : (_jsx(Form, { form: form, initialValues: initialValues, onFinish: handleSave, layout: 'vertical', style: { width: '100%' }, children: _jsxs(Div, { flex: "column", gap: 16, children: [_jsx(Suspense, { fallback: _jsx(Loading, {}), children: _jsx(FormItem, { style: { flex: 1 }, name: "company_founded_at", valuePropName: 'year', label: t('profile|profileForm.company.labels.foundedAt'), children: _jsx(DatePicker, { size: "large", style: { width: '100%' }, onChange: handleFoundedAtChange, value: foundedAt, picker: "year" }) }) }), _jsx(FormItem, { label: t('profile|profileForm.company.labels.size'), name: "company_size", children: _jsx(Select, { placeholder: "Select option", options: companySizeOptions }) }), _jsx(FormItem, { name: "company_website", label: t('profile|profileForm.company.labels.website'), rules: [
                            {
                                required: true,
                                validator: validateWebsite({
                                    message: t('profile|profileForm.validation.website'),
                                    isRequired: true,
                                }),
                            },
                        ], children: _jsx(Input, { size: 'large', placeholder: t('profile|profileForm.company.labels.website') }) }), _jsx(FormItem, { name: "provider_hourly_rate", label: t('profile|profileForm.company.labels.hourlyRate'), children: _jsx(InputNumber, {}) })] }) })) }));
};
export default FastInfosForm;
