import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Sort from '@hellodarwin/icons/dist/icons/Sort';
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import Select from 'antd/es/select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
const FilesListSortBySelect = ({ onChange, value, }) => {
    const { t } = useTranslation();
    const SortOptions = useMemo(() => [
        {
            value: 'recent',
            label: t('assets|filters.sortBy.options.recent'),
        },
        {
            value: 'alpha',
            label: t('assets|filters.sortBy.options.alphabetical'),
        },
        {
            value: 'type',
            label: t('assets|filters.sortBy.options.type'),
        },
    ], [t]);
    return (_jsx(Select, { options: SortOptions, onChange: onChange, placeholder: t('rfp|filters.sortBy.title'), value: value, labelRender: ({ label }) => (_jsxs(Div, { flex: "row", align: "center", gap: 8, children: [_jsx(Sort, { size: 16 }), _jsx(Typography, { elementTheme: "body2", children: label })] })), style: { width: 221 }, suffixIcon: _jsx(TabArrow, { size: 16, down: true }) }));
};
export default FilesListSortBySelect;
