import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { arraysOfObjectsEqual } from '../helpers/arrays-equal';
export const BreadcrumbsContext = React.createContext({
    setBreadCrumbs: (crumbs) => { },
    breadCrumbs: [],
});
const BreadcrumbsProvider = ({ children }) => {
    const [breadCrumbs, setBreadCrumbs] = React.useState([]);
    const handleBreadcrumbs = (crumbs) => {
        if (!arraysOfObjectsEqual(crumbs, breadCrumbs)) {
            setBreadCrumbs(crumbs);
        }
    };
    return (_jsx(BreadcrumbsContext.Provider, { value: {
            breadCrumbs,
            setBreadCrumbs: handleBreadcrumbs,
        }, children: children }));
};
export const useBreadcrumbs = () => useContext(BreadcrumbsContext);
export default BreadcrumbsProvider;
