import { useWatch } from 'antd/es/form/Form';
import { useEffect, useMemo, useState } from 'react';
const usePreviewInfos = ({ form, fileList, asset_title, asset_url, }) => {
    const formValues = useWatch([], form);
    const [fileUrl, setFileUrl] = useState(null);
    useEffect(() => {
        if (fileList === null || fileList === void 0 ? void 0 : fileList.length) {
            const file = fileList[0];
            if (file instanceof File || file instanceof Blob) {
                const url = URL.createObjectURL(file);
                setFileUrl(url);
                return () => URL.revokeObjectURL(url);
            }
            else if (file.url) {
                setFileUrl(file.url);
            }
        }
    }, [fileList]);
    const previewUrl = useMemo(() => {
        if (!!(formValues === null || formValues === void 0 ? void 0 : formValues.asset_url))
            return formValues.asset_url;
        if (!!(fileUrl === null || fileUrl === void 0 ? void 0 : fileUrl.length))
            return fileUrl;
        return asset_url;
    }, [fileUrl, asset_url, formValues]);
    const previewTitle = useMemo(() => {
        if (!!(formValues === null || formValues === void 0 ? void 0 : formValues.asset_title))
            return formValues.asset_title;
        return asset_title;
    }, [formValues, asset_title]);
    useEffect(() => {
        return () => {
            if (fileUrl && fileUrl.startsWith('blob:')) {
                URL.revokeObjectURL(fileUrl);
            }
        };
    }, [fileUrl]);
    return useMemo(() => ({ previewTitle, previewUrl }), [previewTitle, previewUrl]);
};
export default usePreviewInfos;
