import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Close from '@hellodarwin/icons/dist/icons/Close';
import Popconfirm from 'antd/es/popconfirm';
import classNames from 'classnames';
import { StyledRecursiveTag } from './styles';
const HdRecursiveTag = ({ text, icon, color, size, shape, style, onClick, onClose, hoverable, onMouseEnter, onMouseLeave, isDimmed, isHovered, iconPosition, deleteConfirmation, }) => {
    const classes = classNames({ [`hovered`]: isHovered, [`dimmed`]: isDimmed });
    return (_jsxs(StyledRecursiveTag, { onClick: onClick, "$size": size, "$color": color, "$shape": shape, style: style, "$hoverable": hoverable, "$isDimmed": isDimmed, "$isHovered": isHovered, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, className: classes, children: [iconPosition !== 'end' && icon, _jsx("span", { children: text }), iconPosition === 'end' && icon, !!onClose &&
                (deleteConfirmation ? (_jsx(Popconfirm, { title: "Are you sure you want to delete this tag and its children?", description: "This action cannot be undone.", onConfirm: onClose, children: _jsx(Close, { style: { flexShrink: 0, flexGrow: 0 }, onClick: (e) => e.stopPropagation(), size: 8 }) })) : (_jsx(Close, { style: { flexShrink: 0, flexGrow: 0 }, onClick: onClose, height: 8, width: 8 })))] }));
};
export default HdRecursiveTag;
