import Div from '@hellodarwin/core/lib/components/common/div';
import Loading from '@hellodarwin/core/lib/components/loading';
import { Company } from '@hellodarwin/core/lib/features/entities';
import useDebounceFunction from '@hellodarwin/core/lib/features/hooks/use-debounce-function';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Select from 'antd/es/select';
import React, { useEffect, useState } from 'react';
import { useAdminApi } from '../../features/api/use-admin-api';

const SEARCH_DELAY_MS = 500;
const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;

interface SelectCompanySubsidiariesProps {
  includeCompaniesWithIds?: string[];
  excludeCompaniesWithIds?: string[];
  value?: string[];
  onChange?: (value: string[]) => void;
}

const SelectCompanySubsidiaries: React.FC<SelectCompanySubsidiariesProps> = ({
  includeCompaniesWithIds,
  excludeCompaniesWithIds,
  value,
  onChange,
}) => {
  const api = useAdminApi();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [isCompaniesSearchLoading, setIsCompaniesSearchLoading] =
    useState(false);
  const { t } = useTranslations();

  const [companyOptions, setCompanyOptions] = useState(
    companies
      .filter(
        (company) => !excludeCompaniesWithIds?.includes(company.company_id),
      )
      .map((company) => ({
        key: company.company_id,
        value: company.company_id,
        label: company.name,
      })) || [],
  );

  useEffect(() => {
    setCompanyOptions(
      companies
        .filter(
          (company) => !excludeCompaniesWithIds?.includes(company.company_id),
        )
        .map((company) => ({
          key: company.company_id,
          value: company.company_id,
          label: company.name,
        })),
    );
  }, [companies, excludeCompaniesWithIds]);

  useEffect(() => {
    setIsCompaniesSearchLoading(true);
    api
      .queryCompanies(DEFAULT_PAGE, DEFAULT_LIMIT, '', includeCompaniesWithIds)
      .then((result) => {
        setCompanies(result.results);
      })
      .catch((error) => {
        console.error('Error fetching companies:', error);
        setCompanies([]);
      })
      .finally(() => {
        setIsCompaniesSearchLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = useDebounceFunction(
    async (query: string) => {
      setIsCompaniesSearchLoading(true);
      try {
        const result = await api.queryCompanies(
          DEFAULT_PAGE,
          DEFAULT_LIMIT,
          query,
          includeCompaniesWithIds,
        );
        setCompanies(result.results);
      } catch (error) {
        console.error('Error fetching companies:', error);
        setCompanies([]);
      } finally {
        setIsCompaniesSearchLoading(false);
      }
    },
    SEARCH_DELAY_MS,
    true,
  );

  return (
    <Select
      mode="multiple"
      showSearch
      value={value}
      onChange={onChange}
      options={companyOptions}
      placeholder={t('createApplication|search_company')}
      loading={isCompaniesSearchLoading}
      filterOption={false}
      onSearch={onSearch}
      notFoundContent={
        isCompaniesSearchLoading ? (
          <Loading />
        ) : !companyOptions?.length ? (
          <Div>No companies found</Div>
        ) : null
      }
    />
  );
};

export default SelectCompanySubsidiaries;
