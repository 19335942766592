import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from 'styled-components';
import Loading from '.';
const LoadingScreen = () => {
    const theme = useTheme();
    return (_jsx("div", { style: {
            width: '100vw',
            height: '100vh',
            background: theme.colors.background,
        }, children: _jsx(Loading, { size: "extra-large" }) }));
};
export default LoadingScreen;
