import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import GrantEligibilitySections from '../../../../../features/enums/grant-eligibility-sections';
import parse from '../../../../../features/helpers/parse';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { grantSingleParseOptions, GrantSingleSectionContent, } from '../../styles';
import AdmissibilitySectionTile from './AdmissibilitySectionTile';
const GrantAdmissibility = ({ eligibility_criteria, who_can_apply, who_cannot_apply, eligible_expenses, zone, }) => {
    const { t } = useTranslations();
    const showSection = (field) => {
        return !!field && field !== '' && field !== '<p><br></p>';
    };
    return (_jsxs(_Fragment, { children: [showSection(eligibility_criteria) && !!eligibility_criteria && (_jsx(GrantSingleSectionContent, { children: parse(eligibility_criteria, grantSingleParseOptions) })), showSection(who_can_apply) && (_jsx(AdmissibilitySectionTile, { label: t(`grant_eligibility_criteria|${GrantEligibilitySections.WhoCanApply}`), content: who_can_apply })), showSection(who_cannot_apply) && (_jsx(AdmissibilitySectionTile, { label: t(`grant_eligibility_criteria|${GrantEligibilitySections.WhoCannotApply}`), content: who_cannot_apply })), showSection(eligible_expenses) && (_jsx(AdmissibilitySectionTile, { label: t(`grant_eligibility_criteria|${GrantEligibilitySections.EligibleExpenses}`), content: eligible_expenses })), showSection(zone) && (_jsx(AdmissibilitySectionTile, { label: t(`grant_eligibility_criteria|${GrantEligibilitySections.Zone}`), content: zone }))] }));
};
export default GrantAdmissibility;
