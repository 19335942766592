import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import prefixHTTPS from '../../../../features/helpers/prefix-https';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
const InformationCard = ({ title, value, Icon, link, tooltip, }) => {
    const theme = useTheme();
    return (_jsx(Div, { style: { padding: 16, paddingTop: 19 }, borderColor: theme.colors.beige_2, backgroundColor: theme.colors.beige_4, overflow: "hidden", children: _jsxs(Div, { flex: "column", gap: 11, align: "center", justify: "center", children: [_jsxs(Div, { flex: "column", align: "center", gap: 4, children: [_jsx(Icon, { size: 18, style: { color: theme.colors.grey_2 } }), _jsx(Typography, { elementTheme: "overline", color: theme.colors.grey_2, textAlign: "center", nowrap: true, ellipsis: true, children: title })] }), _jsx(Typography, { elementTheme: "h6", color: theme.colors.primary, textAlign: "center", nowrap: true, ellipsis: true, children: link ? (_jsx("a", { rel: "nofollow", href: prefixHTTPS(link.toLowerCase(), 'profile_header'), target: "_blank", style: { fontSize: 'inherit', fontWeight: 'inherit' }, children: value })) : (value) })] }) }));
};
export default InformationCard;
