import styled, { css } from '../../../plugins/styled';
const SquareAvatar = css `
  border-radius: 0px;
`;
const RoundedCornerAvatar = css `
  border-radius: 4px;
`;
const RoundAvatar = css `
  border-radius: 99px;
`;
export const AvatarContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  background: ${({ $background }) => $background};
  border: 1px solid ${({ theme }) => theme.colors.grey_4};
  color: ${(props) => props.theme.colors.white_1};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${({ $size }) => $size * 0.66}px;
  line-height: ${({ $size }) => $size}px;
  overflow: hidden;
  text-transform: capitalize;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;

  ${({ $shape }) => $shape === 'round'
    ? RoundAvatar
    : $shape === 'rounded-corner'
        ? RoundedCornerAvatar
        : SquareAvatar}
`;
export const StyledAvatar = styled.img `
  object-fit: cover;
  transition: opacity linear 100ms;
`;
