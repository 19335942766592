import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HdLogo from '@hellodarwin/icons/dist/icons/HdLogo';
import { useTheme } from 'styled-components';
import { useNavigate } from '../../../../features/providers/location-provider';
import Div from '../../../common/div';
import NavigationBurger from '../navigation-burger';
const MobileNavigationHeader = ({ app, toggleMenu, isOpen, }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const handleRedirect = () => {
        if (isOpen)
            toggleMenu();
        navigate('/');
    };
    return (_jsxs(Div, { backgroundColor: app === 'provider' ? theme.colors.primary : theme.colors.white_1, flex: 'row', justify: "space-between", style: { padding: 21, zIndex: 100 }, align: "center", children: [_jsx(HdLogo, { onClick: handleRedirect, backgroundType: app === 'provider' ? 'dark' : 'light', type: 'primary', color: "b/w", width: 178, height: 48 }), _jsx(NavigationBurger, { app: app, isOpen: isOpen, onClick: toggleMenu })] }));
};
export default MobileNavigationHeader;
