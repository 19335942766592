import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Budget from '@hellodarwin/icons/dist/icons/Budget';
import Industry from '@hellodarwin/icons/dist/icons/Industry';
import Service from '@hellodarwin/icons/dist/icons/Service';
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import Divider from 'antd/es/divider';
import React from 'react';
import RfpDescriptionType from '../../../features/enums/rfp-description-type';
import parse, { attributesToProps, domToReact, } from '../../../features/helpers/parse';
import { useTranslations } from '../../../features/providers/translations-provider';
import { useTranslation } from '../../../plugins/i18n';
import { useTheme } from '../../../plugins/styled';
import Collapse from '../../common/Collapse';
import { Tablet } from '../../common/Media';
import Div from '../../common/div';
import HdTag from '../../common/hd-tag';
import Typography from '../../common/typography';
const RfpCard = ({ description_container, service, category, specialties, industry, budget_min, budget_max, start_when, rfp_id, title, }) => {
    const { t } = useTranslations();
    const { t: translateTag } = useTranslation();
    const theme = useTheme();
    const options = {
        replace: (domNode) => {
            if (domNode.attribs) {
                const props = attributesToProps(domNode.attribs);
                if (domNode.name === 'ul') {
                    return (_jsx(Typography, { elementTheme: "body2", children: _jsx("ul", Object.assign({}, props, { children: domToReact(domNode.children) })) }));
                }
                if (domNode.name === 'h4') {
                    return (_jsxs(Div, { flex: "column", gap: 6, children: [_jsx(Typography, { elementTheme: "caption", color: theme.colors.grey_2, textTransform: "uppercase", bold: true, children: domToReact(domNode.children) }), _jsx(Divider, { style: { margin: 0 } })] }));
                }
            }
        },
    };
    const renderDescription = () => {
        if (!description_container ||
            !description_container[RfpDescriptionType.Details])
            return null;
        return (_jsx(Typography, { elementTheme: "body3", style: {
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '600px',
                gap: 16,
            }, children: parse(description_container[RfpDescriptionType.Details], options) }));
    };
    const InfoRow = ({ Icon, label, value, }) => !!value ? (_jsxs(Div, { flex: "row", gap: 10, align: "center", justify: "space-between", children: [_jsxs(Div, { flex: "row", gap: 10, align: "center", fitContent: true, children: [_jsx(Icon, { size: 10, style: { color: theme.colors.grey_2 } }), _jsx(Typography, { color: theme.colors.grey_2, elementTheme: "overline", medium: true, textTransform: "uppercase", nowrap: true, children: label })] }), _jsx(Typography, { elementTheme: "caption", nowrap: true, ellipsis: true, children: value })] })) : (_jsx(_Fragment, {}));
    return (_jsx(_Fragment, { children: _jsx(Collapse, { ExpandIcon: ({ isActive }) => (_jsxs(Div, { gap: 16, flex: "row", align: "center", fitContent: true, xl: { gap: 0 }, children: [_jsx(Tablet, { direction: "up", children: _jsx(Typography, { bold: true, textTransform: "uppercase", letterSpacing: 0.36, nowrap: true, elementTheme: "overline", children: isActive
                                ? t('profile_expertise|seeLessDetails')
                                : t('profile_expertise|seeMoreDetails') }) }), _jsx(TabArrow, { down: isActive, size: 12 })] })), items: [
                {
                    id: rfp_id,
                    styles: {
                        header: {
                            backgroundColor: theme.colors.beige_2,
                            padding: 24,
                        },
                        content: {
                            padding: 24,
                        },
                    },
                    title: (_jsx(_Fragment, { children: _jsx(Typography, { bold: true, textTransform: "capitalize", style: { height: 60, display: 'flex', alignItems: 'center' }, xl: { style: { height: 'fit-content' } }, textAlign: "center", children: title ? title : category }) })),
                    headerIsColumnOnTablet: true,
                    children: (_jsxs(Div, { flex: "column", gap: 32, children: [_jsxs(Div, { flex: "row", xl: { flex: 'column' }, gap: 20, justify: "space-between", align: "stretch", children: [_jsxs(Div, { flex: "column", gap: 9, style: { flex: '3 0 0%' }, children: [_jsx(Typography, { color: theme.colors.grey_2, elementTheme: "overline", textTransform: "uppercase", letterSpacing: 0.33, children: t('profile_projects|projectSummary') }), renderDescription()] }), _jsx(Tablet, { direction: "up", children: _jsx(Divider, { type: "vertical", style: {
                                                height: 'auto',
                                                borderColor: theme.colors.grey_4,
                                            } }) }), _jsxs(Div, { flex: "column", gap: 18, style: { flex: '2 0 0%' }, overflow: "hidden", children: [_jsx(InfoRow, { Icon: Service, label: t('profile_projects|serviceReceived'), value: service }), industry && (_jsx(InfoRow, { Icon: Industry, label: t('profile_projects|industry'), value: industry })), ((budget_max !== null && budget_max !== void 0 ? budget_max : 0) > 0 || (budget_min !== null && budget_min !== void 0 ? budget_min : 0) > 0) && (_jsx(InfoRow, { Icon: Budget, label: t('profile_projects|budgetRange'), value: `\$${budget_min || 0} - \$${budget_max || 0}` })), _jsx(InfoRow, { Icon: Timeline, label: t('profile_projects|timeline'), value: t(`projects_start_when|${start_when === null || start_when === void 0 ? void 0 : start_when.toLowerCase()}`) })] })] }), _jsxs(Div, { wrap: "wrap", flex: "row", gap: 8, xl: { flex: 'column', gap: 11 }, children: [_jsx(HdTag, { color: theme.colors.purple_5, text: translateTag(`tags|${category}`) }), _jsx(HdTag, { color: theme.colors.purple_5, text: translateTag(`tags|${service}`) }), React.Children.toArray(specialties === null || specialties === void 0 ? void 0 : specialties.map((tag, tagIndex) => (_jsx(HdTag, { color: theme.colors.purple_5, text: translateTag(`tags|${tag}`) }, tagIndex))))] })] })),
                },
            ] }) }));
};
export default RfpCard;
