import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ArrowOutlineStyled, TriggerStyled, } from '../../../layout/navigation/styles';
import ChildSidebarBlock from './child-sidebar-block';
import SidebarBlock from './sidebar-block';
import { SidebarPageSiderContainer } from './styles';
import { useTheme } from 'styled-components';
const SidebarPageSider = ({ sidebarBlocks, side, isCollapsed, handleCollapse, }) => {
    const theme = useTheme();
    if (!(sidebarBlocks === null || sidebarBlocks === void 0 ? void 0 : sidebarBlocks.length))
        return _jsx(_Fragment, {});
    return (_jsx(SidebarPageSiderContainer, { "$position": side, collapsible: true, collapsed: isCollapsed, onCollapse: handleCollapse, width: 300, collapsedWidth: 20, trigger: _jsx(TriggerStyled, { style: { top: theme.appearance.appHeaderHeight + 56 + 32 }, children: _jsx(ArrowOutlineStyled, { size: 24, style: {
                    transform: (isCollapsed && side === 'left') ||
                        (!isCollapsed && side === 'right')
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)',
                    transition: 'transform 500ms linear',
                } }) }), children: !isCollapsed &&
            React.Children.toArray(sidebarBlocks.map((block, index) => React.isValidElement(block.content) ? (_jsx(SidebarBlock, Object.assign({}, block))) : (_jsx(ChildSidebarBlock, Object.assign({}, block))))) }));
};
export default SidebarPageSider;
