import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Budget from '@hellodarwin/icons/dist/icons/Budget';
import Industry from '@hellodarwin/icons/dist/icons/Industry';
import Invoices from '@hellodarwin/icons/dist/icons/Invoices';
import React from 'react';
import { useTheme } from 'styled-components';
import { dollarFormat } from '../../../features/helpers';
import useLocale from '../../../features/providers/locale-provider';
import { useTranslations } from '../../../features/providers/translations-provider';
import HdArrow from '../../common/button/arrow';
import Div from '../../common/div';
import HdTag from '../../common/hd-tag';
import { Tablet } from '../../common/Media';
import GrantCardBox from './grant-card-box';
import { GrantCardBodyContainer, GrantCardBodyContent } from './styles';
const GrantCardBody = ({ grant, locationString, }) => {
    const theme = useTheme();
    const { t } = useTranslations();
    const { selectedLocale } = useLocale();
    const { funding_max_amount, funding_min_amount, grant_financing_type, percentage_funding, grant_industry_sectors, } = grant;
    return (_jsx(GrantCardBodyContainer, { children: _jsxs(GrantCardBodyContent, { children: [_jsx(GrantCardBox, { Icon: Budget, label: t('grant_card|eligibleFinancing'), children: _jsxs("ul", { children: [!!funding_min_amount && !!funding_max_amount ? (_jsx("li", { children: t('grant_card|fromMinToMax', {
                                    min: `${dollarFormat(funding_min_amount, selectedLocale)}`,
                                    max: `${dollarFormat(funding_max_amount, selectedLocale)}`,
                                }) })) : !!funding_min_amount ? (_jsx("li", { children: t('grant_card|minCount', {
                                    count: `${dollarFormat(funding_min_amount, selectedLocale)}`,
                                }) })) : (!!funding_max_amount && (_jsx("li", { children: t('grant_card|maxCount', {
                                    count: `${dollarFormat(funding_max_amount, selectedLocale)}`,
                                }) }))), !!percentage_funding && (_jsx("li", { children: t('grant_card|projectCostPercent', {
                                    percent: `${percentage_funding}`,
                                }) })), !funding_min_amount &&
                                !funding_max_amount &&
                                !percentage_funding && _jsx("li", { children: t('grant_card|noCondition') })] }) }), _jsx(GrantCardBox, { Icon: Industry, label: t('grant_card|eligibleIndustries'), children: _jsx("ul", { style: { width: '100%' }, children: !!(grant_industry_sectors === null || grant_industry_sectors === void 0 ? void 0 : grant_industry_sectors.length) ? (React.Children.toArray(grant_industry_sectors
                            .slice(0, 4)
                            .map((tag) => _jsx("li", { children: tag }, tag)))) : (_jsx("li", { style: { width: '100%' }, children: t('grant_card|allIndustries') })) }) }), _jsx(GrantCardBox, { Icon: Invoices, label: t('grant_card|financingType'), children: _jsx(Div, { flex: "row", gap: 8, wrap: "wrap", children: React.Children.toArray(grant_financing_type === null || grant_financing_type === void 0 ? void 0 : grant_financing_type.map((type) => (_jsx(HdTag, { text: type, color: theme.colors.purple_3 }, type)))) }) }), _jsx(Tablet, { direction: "down", children: _jsxs("div", { style: { display: 'flex', flexDirection: 'column', padding: 24 }, children: [_jsx("div", { style: {
                                    height: 35,
                                    color: theme.colors.grey_2,
                                }, className: "body3", children: locationString }), _jsxs("button", { style: { width: '100%' }, children: [t('grant_card|learnMore'), _jsx(HdArrow, { dir: "right" })] })] }) })] }) }));
};
export default GrantCardBody;
