import { GinApplication } from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { Form, Input, InputNumber, Modal, message } from 'antd';
import { useAppDispatch } from '../../../app';
import { updateGinApplication } from '../../../features/api/slices/gins-slice';
import { useNewAdminApi } from '../../../features/api/use-admin-api';

interface ProgramDetailsModalProps {
  isVisible: boolean;
  handleClose: () => void;
  application: GinApplication;
}

const ProgramDetailsModal = ({
  isVisible,
  handleClose,
  application,
}: ProgramDetailsModalProps) => {
  const { t } = useTranslations();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const api = useNewAdminApi();

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const { application_name, application_amount } = values;

      const updatedApplication = getUpdatedApplication(
        application_name,
        application_amount,
      );

      const result = await dispatch(
        updateGinApplication({ api, updatedApplication }),
      );

      if (updateGinApplication.fulfilled.match(result)) {
        form.resetFields();
        message.success(
          t('application_single|application_details_update_success'),
        );
        handleClose();
      } else {
        message.error(
          t('application_single|application_details_update_failed'),
        );
      }
    } catch (e) {
      message.error(t('application_single|application_details_update_failed'));
      console.error(e);
    }
  };

  const getCurrentAmountField = () =>
    application.application_accepted_amount !== undefined
      ? 'application_accepted_amount'
      : application.application_submitted_amount !== undefined
        ? 'application_submitted_amount'
        : 'application_targeted_amount';

  const applicationAmount = () => application[getCurrentAmountField()] ?? 0;

  const applicationAmountType = () => {
    const field = getCurrentAmountField();
    switch (field) {
      case 'application_accepted_amount':
        return t('application_single|accepted_amount');
      case 'application_submitted_amount':
        return t('application_single|submitted_amount');
      default:
        return t('application_single|targeted_amount');
    }
  };

  const getUpdatedApplication = (newName: string, newAmount: number) => {
    const field = getCurrentAmountField();
    return {
      ...application,
      application_name: newName,
      [field]: newAmount,
    };
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        title={t('application_single|edit_application_details')}
        open={isVisible}
        onCancel={handleClose}
        onOk={handleSubmit}
        width={400}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            application_name: application.application_name,
            application_amount: applicationAmount(),
          }}
        >
          <Form.Item
            label={t('application_single|application_name')}
            name="application_name"
            rules={[
              {
                required: true,
                message: t('application_single|please_enter_application_name'),
              },
            ]}
          >
            <Input
              placeholder={t('application_single|enter_application_name')}
            />
          </Form.Item>
          <Form.Item
            label={applicationAmountType()}
            name="application_amount"
            rules={[
              {
                required: true,
                message: t('application_single|please_enter_amount'),
              },
              {
                type: 'number',
                message: t('application_single|please_enter_a_valid_amount'),
              },
            ]}
          >
            <InputNumber
              placeholder={t('application_single|enter_amount')}
              style={{ width: '100%' }}
              controls={false}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ProgramDetailsModal;
