'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useMemo, useState } from 'react';
import { getI18n } from '../../plugins/i18n/utils';
import formatLang from '../helpers/format-lang';
import isBrowser from '../helpers/is-browser';
import useDebounceFunction from '../hooks/use-debounce-function';
export const LOCALE_CACHE_KEY = 'language';
export const DEFAULT_LANGUAGE = 'en';
export const SUPPORTED_LANGUAGES = ['en', 'fr'];
export const LocaleContext = React.createContext({
    selectedLocale: 'en',
    changeLanguage(locale) { },
    localeProviderLoading: false,
});
export const LocaleProvider = ({ children }) => {
    const i18n = !!getI18n && getI18n();
    const storedLanguage = useMemo(() => {
        if (!isBrowser)
            return DEFAULT_LANGUAGE;
        const storedLanguage = localStorage.getItem(LOCALE_CACHE_KEY);
        const browserLanguage = navigator.language;
        return SUPPORTED_LANGUAGES.includes(storedLanguage)
            ? storedLanguage
            : formatLang(browserLanguage);
    }, [isBrowser]);
    const [selectedLocale, setSelectedLocale] = useState(storedLanguage);
    const [localeProviderLoading, setLocaleProviderLoading] = useState(true);
    const changeLanguage = (locale) => {
        if (!isBrowser)
            return;
        if (!(locale === null || locale === void 0 ? void 0 : locale.length))
            return;
        setLocaleProviderLoading(true);
        const validLocale = formatLang(locale);
        if (validLocale !== localStorage.getItem(LOCALE_CACHE_KEY)) {
            localStorage.setItem(LOCALE_CACHE_KEY, validLocale);
        }
        if (!!i18n) {
            i18n.changeLanguage(validLocale);
        }
        setSelectedLocale(validLocale);
        setTimeout(() => {
            setLocaleProviderLoading(false);
        }, 500);
    };
    const debouncedChangeLanguage = useDebounceFunction(changeLanguage, 200);
    const contextValue = useMemo(() => ({
        selectedLocale,
        changeLanguage: debouncedChangeLanguage,
        localeProviderLoading,
    }), [selectedLocale, localeProviderLoading]);
    return (_jsx(LocaleContext.Provider, { value: contextValue, children: children }));
};
const useLocale = () => useContext(LocaleContext);
export default useLocale;
