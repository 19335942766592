var _a;
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import isBrowser from '../../../features/helpers/is-browser';
import useLocale from '../../../features/providers/locale-provider';
import Div from '../../common/div';
import Loading from '../../loading';
import { HubspotFormStyling } from './styles';
export const hasHubspot = isBrowser && !!((_a = window === null || window === void 0 ? void 0 : window.hbspt) === null || _a === void 0 ? void 0 : _a.forms);
const HubspotForm = ({ hubspotId, containerTag, assignedTheme, additionalFields, data, minHeight, onFormSubmit, }) => {
    const formElement = useRef(null);
    const formId = `hubspot-form-${containerTag}`;
    const { selectedLocale } = useLocale();
    const [isLoading, setLoading] = useState(false);
    const [currentHubspotId, setCurrentHubspotId] = useState(hubspotId);
    const loadScript = () => {
        if (isBrowser) {
            let script = document.createElement(`script`);
            script.defer = true;
            script.onload = () => {
                createForm();
            };
            script.charset = 'utf-8';
            script.type = 'text/javascript';
            script.src = `//js.hsforms.net/forms/embed/v2.js`;
            document.head.appendChild(script);
        }
    };
    const createForm = () => {
        var _a, _b, _c;
        if (((_b = (_a = window.hbspt) === null || _a === void 0 ? void 0 : _a.forms) === null || _b === void 0 ? void 0 : _b.create) && ((_c = formElement.current) === null || _c === void 0 ? void 0 : _c.id)) {
            setLoading(true);
            formElement.current.innerHTML = '';
            const options = Object.assign({ region: 'na1', portalId: '21902860', formId: currentHubspotId, target: `#${formId}`, onFormSubmit, onFormReady: function (form) {
                    if (!!additionalFields) {
                        Object.keys(additionalFields).forEach((name) => {
                            var _a;
                            if (!((_a = additionalFields[name]) === null || _a === void 0 ? void 0 : _a.length))
                                return;
                            const field = form.querySelector(`input[name="${name}"]`);
                            if (field) {
                                field.value = additionalFields[name];
                                const event = new Event('input', { bubbles: true });
                                field.dispatchEvent(event);
                            }
                        });
                    }
                    setTimeout(() => populateFormData(form), 500);
                } }, additionalFields);
            window.hbspt.forms.create(options);
        }
        else {
            setTimeout(createForm, 500);
        }
    };
    const populateFormData = (form) => {
        Object.entries(data || {}).forEach(([key, value]) => {
            if (!(value === null || value === void 0 ? void 0 : value.length))
                return;
            const input = form.querySelector(`input[name="${key}"]`);
            if (input) {
                input.value = value;
                if (form.setFieldValue) {
                    form.setFieldValue(key, value);
                }
                input.dispatchEvent(new Event('input', { bubbles: true }));
                input.dispatchEvent(new Event('change', { bubbles: true }));
            }
        });
    };
    useEffect(() => {
        if (!hasHubspot) {
            loadScript();
        }
        else {
            createForm();
        }
    }, [hasHubspot]);
    useEffect(() => {
        if (hubspotId !== currentHubspotId) {
            setCurrentHubspotId(hubspotId);
        }
    }, [hubspotId, selectedLocale]);
    useEffect(() => {
        const targetNode = formElement.current;
        if (!targetNode)
            return;
        const observer = new MutationObserver((mutationsList) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    setLoading(false);
                }
            }
        });
        observer.observe(targetNode, {
            childList: true,
            subtree: true,
            characterData: true,
        });
        return () => {
            observer.disconnect();
        };
    }, [formElement]);
    if (!currentHubspotId)
        return _jsx(_Fragment, {});
    return (_jsxs(_Fragment, { children: [isLoading && (_jsx(Div, { flex: "column", align: "center", justify: "center", style: { minHeight: minHeight !== null && minHeight !== void 0 ? minHeight : 400 }, children: _jsx(Loading, {}) })), _jsx(HubspotFormStyling, { id: formId, ref: formElement, style: {
                    position: 'relative',
                    overflowX: 'hidden',
                    overflowY: minHeight ? 'hidden' : 'auto',
                    minHeight,
                }, "$assignedTheme": assignedTheme, "$isNewsletter": containerTag === 'newsletter' })] }));
};
export default HubspotForm;
