import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Download from '@hellodarwin/icons/dist/icons/Download';
import Hide from '@hellodarwin/icons/dist/icons/Hide';
import Informations from '@hellodarwin/icons/dist/icons/Informations';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import View from '@hellodarwin/icons/dist/icons/View';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { usePlatform } from '../../../features/providers/platform-provider';
import Div from '../../common/div';
import { ModalConfirm } from '../../common/hd-modal/confirm';
import Typography from '../../common/typography';
import getAssetTypeId from './get-asset-type-id';
import isAssetFolder from './is-asset-folder';
const Label = ({ title, Icon, }) => {
    return (_jsxs(Div, { flex: "row", gap: 8, align: "center", style: { padding: '4px 12px' }, children: [_jsx(Icon, { size: 16 }), _jsx(Typography, { elementTheme: "body2", children: title })] }));
};
const useGetAssetActions = ({ onItemAction, onItemSelected, setActiveItemId, handleDeleteAsset, handleHideShowAsset, }) => {
    const { t } = useTranslation();
    const { app } = usePlatform();
    const theme = useTheme();
    return useMemo(() => (asset) => {
        const isFolder = isAssetFolder(asset);
        const id = getAssetTypeId(asset);
        const onClick = () => setActiveItemId(id);
        const onDoubleClick = () => onItemSelected(id);
        const onAction = () => onItemAction(asset);
        const onHideShow = (hide) => {
            !isFolder
                ? handleHideShowAsset(asset.asset_id, hide)
                : undefined;
        };
        const onDelete = () => {
            if (isFolder || !handleDeleteAsset)
                return;
            ModalConfirm({
                title: t('assets|deleteConfirm.title'),
                content: t('assets|deleteConfirm.description'),
                okText: t('button.confirm'),
                cancelText: t('button.cancel'),
                onOk: async () => {
                    handleDeleteAsset(asset.asset_id);
                },
            });
        };
        const dropdownItems = [
            {
                label: (_jsx(Label, { title: t('assets|filesListHeader.infos'), Icon: Informations })),
                onClick: onDoubleClick,
                key: '1',
            },
        ];
        if (isFolder || !!asset.display_name) {
            dropdownItems.push({
                label: isFolder ? (_jsx(Label, { title: t('assets|filesListHeader.open'), Icon: NewWindow })) : (_jsx(Label, { title: t('assets|filesListHeader.download'), Icon: Download })),
                onClick: onAction,
                key: 'download',
            });
        }
        if (app === 'admin') {
            if (!isFolder) {
                const file = asset;
                if (!!handleHideShowAsset && !!file.hidden) {
                    dropdownItems.push({
                        label: (_jsx(Label, { title: file.hidden.is_companies_hidden
                                ? t('assets|filesListHeader.view')
                                : t('assets|filesListHeader.hide'), Icon: file.hidden.is_companies_hidden ? View : Hide })),
                        onClick: () => {
                            var hidden = Object.assign({}, file.hidden);
                            hidden.is_companies_hidden = !hidden.is_companies_hidden;
                            hidden.is_contacts_hidden = !hidden.is_contacts_hidden;
                            hidden.is_providers_hidden = !hidden.is_providers_hidden;
                            onHideShow(hidden);
                        },
                        key: 'hideshow',
                    });
                }
                if (!!handleDeleteAsset) {
                    dropdownItems.push({
                        label: (_jsx(Label, { title: t('assets|filesListHeader.delete'), Icon: Delete })),
                        onClick: onDelete,
                        key: 'delete',
                    });
                }
            }
        }
        return {
            dropdownItems,
            onClick,
            onDoubleClick,
            onAction,
            onHideShow,
            onDelete,
        };
    }, [onItemAction, onItemSelected, t, app]);
};
export default useGetAssetActions;
