import { useMemo } from 'react';
import { InitialFormData } from '../../types';
const getFormSubmissionData = ({ formSubmission, }) => {
    const { all_items } = formSubmission;
    return useMemo(() => {
        if (!all_items.length)
            return InitialFormData;
        return all_items.reduce((o, itm) => (Object.assign(Object.assign({}, o), { [itm.form_item.form_item_id]: itm.forms_submissions_item.value })), InitialFormData);
    }, [all_items]);
};
export default getFormSubmissionData;
