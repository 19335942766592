import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Empty from 'antd/es/empty';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import ReviewRatings from '../../../../reviews/ratings';
const EmptySection = ({ handleClick, cta, title, subtitle, isEditable, sectionName, }) => {
    const theme = useTheme();
    return (_jsxs(Div, { flex: "column", gap: 8, style: { paddingBlock: 24 }, children: [_jsx(Empty, { imageStyle: { height: 'auto', margin: 0 }, image: sectionName === 'Reviews' ? (_jsx(ReviewRatings, { size: "extra-large", disabled: true, defaultValue: 0 })) : (Empty.PRESENTED_IMAGE_DEFAULT), description: "" }), (!!isEditable || !!title) && (_jsxs(Div, { flex: "column", align: "center", gap: 12, children: [!!(title === null || title === void 0 ? void 0 : title.length) && (_jsx(Typography, { textAlign: "center", elementTheme: "body1", color: theme.colors.grey_1, children: title })), isEditable && (_jsxs(_Fragment, { children: [!!(subtitle === null || subtitle === void 0 ? void 0 : subtitle.length) && (_jsx(Typography, { color: theme.colors.grey_2, textAlign: "center", children: subtitle })), !!handleClick && !!(cta === null || cta === void 0 ? void 0 : cta.length) && (_jsx(Button, { onClick: handleClick, fitContent: true, defaultStyle: theme.colors.purple_1, size: "small", children: cta }))] }))] }))] }));
};
export default EmptySection;
