import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import Image from 'antd/es/image';
import React from 'react';
import { PortfolioAssetsType, } from '../../../../../features/entities';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import theme from '../../../../../theme';
import VideoPlayer from '../../../../common/VideoPlayer';
import Carousel from '../../../../common/carousel';
import Div from '../../../../common/div';
import useDarwinLogoFallback from '../../hooks/use-darwin-picture-fallback';
const Gallery = ({ assets }) => {
    const { t } = useTranslations();
    const fallback = useDarwinLogoFallback({ type: 'portfolio', size: 380 });
    if (!(assets === null || assets === void 0 ? void 0 : assets.length))
        return _jsx(_Fragment, {});
    return (_jsx(_Fragment, { children: _jsx(Div, { style: { padding: '0 35px', overflow: 'hidden' }, flex: "column", gap: 26, children: _jsx(Carousel, { tileWidth: 356, title: t('profile_portfolio|portfolioLabelsGallery'), buttonPosition: "top-right", children: React.Children.toArray(assets === null || assets === void 0 ? void 0 : assets.map((asset) => {
                    var _a;
                    return (_jsx("div", { style: {
                            background: theme.colors.grey_1,
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            width: 356,
                        }, children: asset.asset_type === PortfolioAssetsType.Video ? (_jsx(VideoPlayer, { url: (_a = asset.asset_url) !== null && _a !== void 0 ? _a : '', width: "100%", height: 356, controls: true, light: true })) : (_jsx(Image, { width: 356, height: 356, src: asset.asset_url, fallback: fallback, loading: "lazy", style: {
                                padding: 0,
                                margin: 0,
                                objectFit: 'contain',
                            } })) }));
                })) }) }) }));
};
export default Gallery;
