import LocationProvider from '@hellodarwin/core/lib/features/providers/location-provider';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const LocationProviderWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  return (
    <LocationProvider
      pathname={pathname}
      search={search}
      navigate={navigate}
      LinkElement={Link}
    >
      {children}
    </LocationProvider>
  );
};

export default LocationProviderWrapper;
