import { jsx as _jsx } from "react/jsx-runtime";
import Select from 'antd/es/select';
import { useMemo } from 'react';
import { useTranslations } from '../../../../features/providers/translations-provider';
import FormItem from '../../form-layouts/form-item';
import FormLabel from '../../form-layouts/form-label';
var Provinces;
(function (Provinces) {
    Provinces["ON"] = "ON";
    Provinces["QC"] = "QC";
    Provinces["NS"] = "NS";
    Provinces["NB"] = "NB";
    Provinces["MB"] = "MB";
    Provinces["BC"] = "BC";
    Provinces["PE"] = "PE";
    Provinces["SK"] = "SK";
    Provinces["AB"] = "AB";
    Provinces["NL"] = "NL";
})(Provinces || (Provinces = {}));
const ProvincesSelect = ({ name = 'province', errorMessage, isRequired, isLongName, }) => {
    const { t } = useTranslations();
    const options = useMemo(() => {
        return Object.keys(Provinces).map((province) => {
            const data = {
                value: province,
                label: isLongName ? t(`province|${province}`) : province,
            };
            return data;
        });
    }, []);
    return (_jsx(FormItem, { style: { flex: 1, margin: 0 }, name: name, label: _jsx(FormLabel, { label: t('province|province') }), rules: [
            {
                required: isRequired,
                message: errorMessage !== null && errorMessage !== void 0 ? errorMessage : t('province|provinceRequired'),
                validator: (_, value) => {
                    if (!isRequired)
                        return Promise.resolve();
                    return value === 'QC'
                        ? Promise.resolve()
                        : Promise.reject(new Error(errorMessage !== null && errorMessage !== void 0 ? errorMessage : t('province|provinceRequired')));
                },
            },
        ], children: _jsx(Select, { size: "large", style: { width: '100%' }, options: options }) }));
};
export default ProvincesSelect;
