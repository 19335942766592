import {
  ApiError,
  ApiErrorInitialState,
} from '@hellodarwin/core/lib/features/entities/api-entitites';
import {
  AdminProjectResponse,
  CreateProjectFormResponse,
  Project,
  ProjectGrant,
  ProjectProgram,
} from '@hellodarwin/core/lib/features/entities/projects-entities';
import { ProjectTag } from '@hellodarwin/core/lib/features/entities/tags-entities';
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app/app-store';
import AdminApi from '../admin-api';
import SliceRequest from '../slice-request';
import { addProjectTags } from './new-tags-slice';

type StatusType =
  | 'generic'
  | 'singleProjects'
  | 'allProjects'
  | 'assignToProject'
  | 'dissociateFromProject'
  | 'deleteApplication'
  | 'deleteProject'
  | 'deletePriority'
  | 'createProject'
  | 'updateProject'
  | 'reorderProjectPrograms'
  | 'reorderProjects';
type Status = {
  [key in StatusType]: 'idle' | 'pending';
};

const projectsAdapter = createEntityAdapter({
  selectId: (model: AdminProjectResponse) => model.project_id,
});

export interface ProjectsState {
  status: Status;
  error: ApiError;
  projects: EntityState<AdminProjectResponse, string>;
}

const initialState: ProjectsState = {
  status: {
    generic: 'idle',
    singleProjects: 'idle',
    allProjects: 'idle',
    createProject: 'idle',
    assignToProject: 'idle',
    dissociateFromProject: 'idle',
    deleteApplication: 'idle',
    deleteProject: 'idle',
    deletePriority: 'idle',
    updateProject: 'idle',
    reorderProjectPrograms: 'idle',
    reorderProjects: 'idle',
  },
  error: ApiErrorInitialState,
  projects: projectsAdapter.getInitialState(),
};

export const fetchSingleProjects = SliceRequest<
  Project,
  { api: AdminApi; single_id: string }
>('fetchSingleProjects', async ({ api, single_id }) => {
  const response = await api.get<Project>(`/projects/${single_id}`);
  return response.data;
});

export const fetchAllProjects = SliceRequest<Project[], { api: AdminApi }>(
  'fetchAllProjects',
  async ({ api }) => {
    const response = await api.get<Project[]>(`/projects`);
    return response.data;
  },
);
export const fetchAllCompanyProjects = SliceRequest<
  AdminProjectResponse[],
  { api: AdminApi; companyId: string; locale: string }
>('fetchAllCompanyProjects', async ({ api, companyId, locale }) => {
  const response = await api.get<AdminProjectResponse[]>(
    `/projects/company/${companyId}?locale=${locale}`,
  );
  return response.data;
});

export const reorderProjectPrograms = SliceRequest<
  ProjectProgram[],
  { api: AdminApi; projectId: string; programs: ProjectProgram[] }
>('reorderProjectPrograms', async ({ api, projectId, programs }) => {
  const response = await api.put<ProjectProgram[]>(
    `/projects/program/reorder/${projectId}`,
    programs,
  );
  return response.data;
});

export const reorderProjects = SliceRequest<
  string[],
  { api: AdminApi; projectIDs: string[] }
>('reorderProjects', async ({ api, projectIDs }) => {
  const response = await api.put<string[]>(`/projects/reorder`, projectIDs);
  return response.data;
});

export const createProject = SliceRequest<
  Project,
  { api: AdminApi; data: CreateProjectFormResponse }
>('createProject', async ({ api, data }) => {
  const response = await api.post<Project>(`/projects`, data);
  return response.data;
});

export const assignGrantToProject = SliceRequest<
  ProjectGrant,
  { api: AdminApi; grantId: string; projectId: string }
>('assignGrantToProject', async ({ api, grantId, projectId }) => {
  const response = await api.post<ProjectGrant>(
    `/projects/grant/${projectId}?grantId=${grantId}`,
  );
  return response.data;
});

export const assignApplicationToProject = SliceRequest<
  ProjectProgram,
  { api: AdminApi; applicationId: string; projectId: string }
>('assignApplicationToProject', async ({ api, applicationId, projectId }) => {
  const response = await api.post<ProjectProgram>(
    `/projects/program/${projectId}?programId=${applicationId}`,
  );
  return response.data;
});

export const dissociateApplicationFromProject = SliceRequest<
  ProjectProgram,
  { api: AdminApi; applicationId: string; projectId: string }
>(
  'dissociateApplicationFromProject',
  async ({ api, applicationId, projectId }) => {
    const response = await api.delete<ProjectProgram>(
      `/projects/program/dissociate/${projectId}?programId=${applicationId}`,
    );
    return response.data;
  },
);

export const deleteApplication = SliceRequest<
  ProjectProgram,
  { api: AdminApi; applicationId: string; projectId: string }
>('deleteApplication', async ({ api, applicationId, projectId }) => {
  const response = await api.delete<ProjectProgram>(
    `/projects/program/delete/${projectId}?programId=${applicationId}`,
  );
  return response.data;
});

export const deleteProject = SliceRequest<
  string,
  { api: AdminApi; projectId: string }
>('deleteProject', async ({ api, projectId }) => {
  const response = await api.delete<string>(`/projects/${projectId}`);
  return response.data;
});

export const deletePriority = SliceRequest<
  string,
  { api: AdminApi; priorityId: string }
>('deletePriority', async ({ api, priorityId }) => {
  const response = await api.delete<string>(`/projects/priority/${priorityId}`);
  return response.data;
});

export const updateProject = SliceRequest<
  Project,
  { api: AdminApi; data: Project }
>('updateProject', async ({ api, data }) => {
  const response = await api.put<Project>(`/projects`, data);
  return response.data;
});

const projectsSlice = createSlice({
  name: 'Projects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSingleProjects.pending, (state) => {
      state.status.singleProjects = 'pending';
    });
    builder.addCase(fetchSingleProjects.fulfilled, (state, { payload }) => {
      state.projects = projectsAdapter.upsertOne(state.projects, payload);
      state.status.singleProjects = 'idle';
    });
    builder.addCase(fetchSingleProjects.rejected, (state, { payload }) => {
      state.status.singleProjects = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(fetchAllProjects.pending, (state) => {
      state.status.allProjects = 'pending';
    });
    builder.addCase(fetchAllProjects.fulfilled, (state, { payload }) => {
      projectsAdapter.setAll(state.projects, payload);
      state.status.allProjects = 'idle';
    });
    builder.addCase(fetchAllProjects.rejected, (state, { payload }) => {
      state.status.allProjects = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(fetchAllCompanyProjects.pending, (state) => {
      state.status.allProjects = 'pending';
    });
    builder.addCase(fetchAllCompanyProjects.fulfilled, (state, { payload }) => {
      projectsAdapter.setAll(state.projects, payload);
      state.status.allProjects = 'idle';
    });
    builder.addCase(fetchAllCompanyProjects.rejected, (state, { payload }) => {
      state.status.allProjects = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(addProjectTags.fulfilled, (state, { payload }) => {
      if (!!payload?.length) {
        const mappedTags = payload.reduce(
          (o, tag) => {
            const isGoal = tag.tag_type === 'goal';
            return {
              ...o,
              [tag.project_id]: {
                tags: [...(o[tag.project_id]?.tags ?? []), tag],
                project_goals: isGoal
                  ? [...(o[tag.project_id]?.project_goals ?? []), tag.content]
                  : (o[tag.project_id]?.project_goals ?? []),
              },
            };
          },
          {} as {
            [projectId: string]: {
              tags: ProjectTag[];
              project_goals?: string[];
            };
          },
        );

        Object.keys(mappedTags).forEach((projectId) => {
          const parentProject = Object.values(state.projects.entities).find(
            (p) => !!p.projects?.find((p2) => p2.project_id === projectId),
          );

          if (!!parentProject) {
            projectsAdapter.updateOne(state.projects, {
              id: parentProject.project_id,
              changes: {
                projects: parentProject.projects?.map((p) =>
                  p.project_id === projectId
                    ? {
                        ...p,
                        tags: [
                          ...(p.tags ?? []),
                          ...mappedTags[projectId].tags,
                        ],
                        project_goals: [
                          ...(p.project_goals ?? []),
                          ...(mappedTags[projectId].project_goals ?? []),
                        ],
                      }
                    : p,
                ),
              },
            });
          }
        });
      }
      state.status.allProjects = 'idle';
    });
    builder.addCase(createProject.pending, (state) => {
      state.status.createProject = 'pending';
    });
    builder.addCase(createProject.fulfilled, (state, { payload }) => {
      if (!!payload.parent_project?.length) {
        const parent = state.projects.entities[payload.parent_project];

        projectsAdapter.updateOne(state.projects, {
          id: payload.parent_project,
          changes: {
            projects: [...(parent.projects ?? []), payload],
          },
        });
      } else {
        projectsAdapter.addOne(state.projects, payload);
      }
      state.status.createProject = 'idle';
    });
    builder.addCase(createProject.rejected, (state, { payload }) => {
      state.status.createProject = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(assignGrantToProject.pending, (state) => {
      state.status.assignToProject = 'pending';
    });
    builder.addCase(assignGrantToProject.fulfilled, (state, { payload }) => {
      const parentProject = Object.values(state.projects.entities).find(
        (p) => !!p.projects?.find((p2) => p2.project_id === payload.project_id),
      );
      if (!!parentProject) {
        projectsAdapter.updateOne(state.projects, {
          id: parentProject.project_id,
          changes: {
            projects: parentProject.projects?.map((p) =>
              p.project_id === payload.project_id
                ? { ...p, grants: [...(p.grants ?? []), payload] }
                : p,
            ),
          },
        });
      }
      state.status.assignToProject = 'idle';
    });
    builder.addCase(assignGrantToProject.rejected, (state, { payload }) => {
      state.status.assignToProject = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(assignApplicationToProject.pending, (state) => {
      state.status.assignToProject = 'pending';
    });
    builder.addCase(
      assignApplicationToProject.fulfilled,
      (state, { payload }) => {
        const parentProject = Object.values(state.projects.entities).find(
          (project) =>
            project.projects?.some(
              (subProject) => subProject.project_id === payload.project_id,
            ),
        );

        if (parentProject) {
          const updatedProjects = parentProject.projects?.map((subProject) => {
            if (subProject.project_id === payload.project_id) {
              const updatedGrants = subProject.grants?.map((grant) =>
                grant.grant_id === payload.program_grant_id
                  ? {
                      ...grant,
                      programs: [...(grant.programs ?? []), payload],
                    }
                  : grant,
              );

              return {
                ...subProject,
                programs: [...(subProject.programs ?? []), payload],
                grants: updatedGrants,
              };
            }
            return subProject;
          });

          projectsAdapter.updateOne(state.projects, {
            id: parentProject.project_id,
            changes: { projects: updatedProjects },
          });
        }

        state.status.assignToProject = 'idle';
      },
    );
    builder.addCase(
      assignApplicationToProject.rejected,
      (state, { payload }) => {
        state.status.assignToProject = 'idle';
        state.error = payload ?? ApiErrorInitialState;
      },
    );
    builder.addCase(dissociateApplicationFromProject.pending, (state) => {
      state.status.dissociateFromProject = 'pending';
    });
    builder.addCase(
      dissociateApplicationFromProject.fulfilled,
      (state, { payload }) => {
        const parentProject = Object.values(state.projects.entities).find(
          (project) =>
            project.projects?.some(
              (subProject) => subProject.project_id === payload.project_id,
            ),
        );

        if (parentProject) {
          const updatedProjects = parentProject.projects?.map((subProject) => {
            if (subProject.project_id === payload.project_id) {
              const updatedGrants = subProject.grants
                ?.map((grant) => {
                  if (grant.grant_id === payload.program_grant_id) {
                    return {
                      ...grant,
                      programs: grant.programs?.filter(
                        (program) => program.program_id !== payload.program_id,
                      ),
                    };
                  }
                  return grant;
                })
                .filter(
                  (grant) => !!grant.programs && grant.programs!.length > 0,
                );

              return {
                ...subProject,
                programs: subProject.programs?.filter(
                  (program) => program.program_id !== payload.program_id,
                ),
                grants: updatedGrants,
              };
            }
            return subProject;
          });

          projectsAdapter.updateOne(state.projects, {
            id: parentProject.project_id,
            changes: { projects: updatedProjects },
          });
        }

        state.status.dissociateFromProject = 'idle';
      },
    );
    builder.addCase(
      dissociateApplicationFromProject.rejected,
      (state, { payload }) => {
        state.status.dissociateFromProject = 'idle';
        state.error = payload ?? ApiErrorInitialState;
      },
    );
    builder.addCase(deleteApplication.pending, (state) => {
      state.status.deleteApplication = 'pending';
    });
    builder.addCase(deleteApplication.fulfilled, (state, { payload }) => {
      const parentProject = Object.values(state.projects.entities).find(
        (project) =>
          project.projects?.some(
            (subProject) => subProject.project_id === payload.project_id,
          ),
      );

      if (parentProject) {
        const updatedProjects = parentProject.projects?.map((subProject) => {
          if (subProject.project_id === payload.project_id) {
            const updatedGrants = subProject.grants
              ?.map((grant) => {
                if (grant.grant_id === payload.program_grant_id) {
                  return {
                    ...grant,
                    programs: grant.programs?.filter(
                      (program) => program.program_id !== payload.program_id,
                    ),
                  };
                }
                return grant;
              })
              .filter(
                (grant) => !!grant.programs && grant.programs!.length > 0,
              );

            return {
              ...subProject,
              programs: subProject.programs?.filter(
                (program) => program.program_id !== payload.program_id,
              ),
              grants: updatedGrants,
            };
          }
          return subProject;
        });

        projectsAdapter.updateOne(state.projects, {
          id: parentProject.project_id,
          changes: { projects: updatedProjects },
        });
      }

      state.status.deleteApplication = 'idle';
    });
    builder.addCase(deleteApplication.rejected, (state, { payload }) => {
      state.status.deleteApplication = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(deleteProject.pending, (state) => {
      state.status.deleteProject = 'pending';
    });
    builder.addCase(deleteProject.fulfilled, (state, { payload }) => {
      const parentProject = Object.values(state.projects.entities).find(
        (project) =>
          project.projects?.some(
            (subProject) => subProject.project_id === payload,
          ),
      );

      if (parentProject) {
        const updatedProjects = parentProject.projects?.filter(
          (subProject) => subProject.project_id !== payload,
        );

        projectsAdapter.updateOne(state.projects, {
          id: parentProject.project_id,
          changes: { projects: updatedProjects },
        });
      }

      state.status.deleteProject = 'idle';
    });
    builder.addCase(deleteProject.rejected, (state, { payload }) => {
      state.status.deleteProject = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(deletePriority.pending, (state) => {
      state.status.deletePriority = 'pending';
    });
    builder.addCase(deletePriority.fulfilled, (state, { payload }) => {
      projectsAdapter.removeOne(state.projects, payload);
      state.status.deletePriority = 'idle';
    });
    builder.addCase(deletePriority.rejected, (state, { payload }) => {
      state.status.deletePriority = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(updateProject.pending, (state) => {
      state.status.updateProject = 'pending';
    });
    builder.addCase(updateProject.fulfilled, (state, { payload }) => {
      if (!!payload.parent_project?.length) {
        const parent = state.projects.entities[payload.parent_project];
        projectsAdapter.updateOne(state.projects, {
          id: payload.parent_project,
          changes: {
            projects: parent.projects?.map((p) =>
              p.project_id === payload.project_id
                ? { ...payload, programs: p.programs, grants: p.grants }
                : p,
            ),
          },
        });
      } else {
        projectsAdapter.updateOne(state.projects, {
          id: payload.project_id,
          changes: payload,
        });
      }

      state.status.updateProject = 'idle';
    });
    builder.addCase(updateProject.rejected, (state, { payload }) => {
      state.status.updateProject = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });

    builder.addCase(reorderProjectPrograms.pending, (state) => {
      state.status.reorderProjectPrograms = 'pending';
    });
    builder.addCase(
      reorderProjectPrograms.fulfilled,
      (state, { payload, meta }) => {
        const { projectId } = meta.arg;
        const parentProject = Object.values(state.projects.entities).find((p) =>
          p.projects?.some((sub) => sub.project_id === projectId),
        );
        if (parentProject) {
          const updatedProjects = parentProject.projects?.map((sub) => {
            if (sub.project_id === projectId) {
              return { ...sub, programs: payload };
            }
            return sub;
          });
          projectsAdapter.updateOne(state.projects, {
            id: parentProject.project_id,
            changes: { projects: updatedProjects },
          });
        }
        state.status.reorderProjectPrograms = 'idle';
      },
    );
    builder.addCase(reorderProjectPrograms.rejected, (state, { payload }) => {
      state.status.reorderProjectPrograms = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(reorderProjects.pending, (state) => {
      state.status.reorderProjects = 'pending';
    });
    builder.addCase(reorderProjects.fulfilled, (state, { payload }) => {
      // Reorder the projects in the state according to the order of IDs in the payload
      state.projects.ids = payload;
      state.status.reorderProjects = 'idle';
    });
    builder.addCase(reorderProjects.rejected, (state, { payload }) => {
      state.status.reorderProjects = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
  },
});

export const selectProjectsIsLoading = createSelector(
  [
    (state: RootState, _?: StatusType) => state.projects.status,
    (_: RootState, type?: StatusType) => type,
  ],
  (status, type) => {
    if (!type) {
      return !!Object.keys(status).find((state) => state === 'pending');
    } else {
      return status[type] === 'pending';
    }
  },
);

export const { selectAll: selectAllProjects, selectById: selectProjectById } =
  projectsAdapter.getSelectors((state: RootState) => state.projects.projects);

export const ProjectsHasError = (state: RootState) =>
  state.projects.error !== ApiErrorInitialState;

export const projectsReducer = projectsSlice.reducer;
