import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import ProfileLogo from '@hellodarwin/core/lib/components/provider/profile/profile-assets/profile-logo';
import { CompanyGrantResult } from '@hellodarwin/core/lib/features/entities';
import { useTheme } from 'styled-components';

const SimpleGrantCard = ({ grant }: { grant: CompanyGrantResult }) => {
  const theme = useTheme();

  return (
    <Div
      flex="row"
      gap={16}
      style={{
        padding: '12px 16px',
        alignItems: 'flex-start',
      }}
    >
      {grant.grant_display_title && grant.grant_logo && (
        <ProfileLogo
          name={grant.grant_display_title}
          logo={grant.grant_logo}
          type="grant-provider"
          size={60}
        />
      )}

      <Div flex="column" style={{ overflow: 'hidden' }}>
        <Typography.Title
          level={3}
          elementTheme="h6"
          style={{ marginBottom: '4px' }}
        >
          {grant.grant_display_title}
          {grant.company_grant_title && ` (${grant.company_grant_title})`}
        </Typography.Title>

        <Typography elementTheme="body2" color={theme.colors.grey_3}>
          {grant.grant_description_short}
        </Typography>
      </Div>
    </Div>
  );
};

export default SimpleGrantCard;
