import { useMemo } from 'react';
import { getFormItemConditions, getFormPageKeysToCheck, getFormPageSubmissionValues, } from './submission-page-validator';
const getSubmissionActivePages = ({ formSubmission, formData, mappedSubmissionItems, }) => {
    const { form } = formSubmission;
    return useMemo(() => {
        var _a;
        const pages = form.pages.filter((page) => { var _a; return !((_a = page.entity.page_type) === null || _a === void 0 ? void 0 : _a.includes('opening')); });
        const orderedPages = pages.reduce((acc, page) => {
            acc[page.entity.form_page_id] = page;
            return acc;
        }, {});
        const activePagesIds = [];
        let currentPageId = (_a = pages[0]) === null || _a === void 0 ? void 0 : _a.entity.form_page_id;
        let isLastPage = false;
        while (currentPageId && !isLastPage) {
            const currentPage = orderedPages[currentPageId];
            const currentPageIndex = pages.findIndex((page) => page.entity.form_page_id === currentPageId);
            activePagesIds.push(currentPageId);
            const nextPage = pages[currentPageIndex + 1];
            let nextPageId = nextPage === null || nextPage === void 0 ? void 0 : nextPage.entity.form_page_id;
            const keysToCheck = getFormPageKeysToCheck(currentPage.entity);
            const pageValues = getFormPageSubmissionValues(currentPage, formData);
            keysToCheck.forEach((key) => {
                var _a;
                const value = formData[key];
                const conditionPageId = getFormItemConditions(value, nextPageId, (_a = mappedSubmissionItems[key]) === null || _a === void 0 ? void 0 : _a.form_item);
                if (conditionPageId !== nextPageId) {
                    nextPageId = conditionPageId;
                }
            });
            const alreadySeenThisPage = activePagesIds.includes(nextPageId);
            if (!pageValues.length ||
                !currentPage.groups.length ||
                alreadySeenThisPage) {
                isLastPage = true;
            }
            currentPageId = nextPageId;
        }
        const activePages = activePagesIds.map((page_id) => orderedPages[page_id]);
        return activePages;
    }, [formSubmission, formData, mappedSubmissionItems]);
};
export default getSubmissionActivePages;
