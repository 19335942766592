var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import GrantCardBody from './grant-card-body';
import GrantCardHeader from './grant-card-header';
import GrantCardWrapper from './grant-card-wrapper';
const GrantCard = (props) => {
    const { isSmall, featuredTags, parentPath, onClick } = props, grant = __rest(props, ["isSmall", "featuredTags", "parentPath", "onClick"]);
    const { city, grant_province, country } = grant;
    const locationString = useMemo(() => {
        const locations = [city, grant_province];
        if (!city) {
            locations.push(country);
        }
        return locations.filter((itm) => !!itm).join(', ');
    }, [city, grant_province, country]);
    return (_jsxs(GrantCardWrapper, Object.assign({}, props, { children: [_jsx(GrantCardHeader, Object.assign({}, props, { locationString: locationString, featuredTags: featuredTags })), !isSmall && (_jsx(GrantCardBody, { grant: grant, locationString: locationString }))] })));
};
export default GrantCard;
