var RfpStatus;
(function (RfpStatus) {
    RfpStatus["PendingApproval"] = "PendingApproval";
    RfpStatus["Active"] = "Active";
    RfpStatus["FilterCandidates"] = "FilterCandidates";
    RfpStatus["EvaluateFinalists"] = "EvaluateFinalists";
    RfpStatus["Completed"] = "Completed";
    RfpStatus["Canceled"] = "Canceled";
    RfpStatus["Rejected"] = "Rejected";
})(RfpStatus || (RfpStatus = {}));
export default RfpStatus;
