import { useMemo } from 'react';
import { useTheme } from '../../../../../plugins/styled';
import getFilterTagBarTheme from './get-filter-tag-bar-theme';
import useGetOptions from './use-get-options';
const InitialFilterMapping = {};
const useFilterReducer = (data, valueKey, labelKey, type) => {
    const theme = useTheme();
    return useMemo(() => {
        return data.reduce((o, value) => (Object.assign(Object.assign({}, o), { [value[valueKey]]: {
                label: value[labelKey],
                tagColor: getFilterTagBarTheme(type, theme, value[valueKey]),
                type,
            } })), InitialFilterMapping);
    }, [data, labelKey, valueKey]);
};
const useTagsInformations = ({ industries, subIndustries, financingType, provinces, services, companySize, annualRevenue, filterValues, featuredProjects, }) => {
    const { statusOptions, filterOptions } = useGetOptions();
    const mappedIndustries = useFilterReducer(industries, 'value', 'label', 'industry');
    const mappedSubindustries = useFilterReducer(subIndustries, 'value', 'label', 'subindustry');
    const mappedFinancingType = useFilterReducer(financingType, 'value', 'label', 'financingType');
    const mappedProvinces = useFilterReducer(provinces, 'value', 'label', 'region');
    const mappedServices = useFilterReducer(services, 'value', 'label', 'service');
    const mappedProjects = useFilterReducer(featuredProjects !== null && featuredProjects !== void 0 ? featuredProjects : [], 'grant_project_id', 'short_description', 'selectedProjects');
    const mappedCompanySizes = useFilterReducer(companySize, 'value', 'label', 'companySize');
    const mappedAnnualRevenues = useFilterReducer(annualRevenue, 'value', 'label', 'annualRevenue');
    const mappedStatus = useFilterReducer(statusOptions, 'value', 'label', 'status');
    const allTagsInformations = useMemo(() => {
        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, mappedIndustries), mappedSubindustries), mappedAnnualRevenues), mappedCompanySizes), mappedProjects), mappedServices), mappedProvinces), mappedFinancingType), mappedStatus);
    }, [
        mappedAnnualRevenues,
        mappedCompanySizes,
        mappedProjects,
        mappedServices,
        mappedProvinces,
        mappedFinancingType,
        mappedIndustries,
        mappedSubindustries,
        mappedStatus,
    ]);
    const allTags = useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g;
        if (!filterValues)
            return [];
        return [
            ...((_a = filterValues.industry) !== null && _a !== void 0 ? _a : []),
            ...((_b = filterValues.subindustry) !== null && _b !== void 0 ? _b : []),
            ...((_c = filterValues.financingType) !== null && _c !== void 0 ? _c : []),
            ...((_d = filterValues.region) !== null && _d !== void 0 ? _d : []),
            ...((_e = filterValues.service) !== null && _e !== void 0 ? _e : []),
            ...((_f = filterValues.status) !== null && _f !== void 0 ? _f : []),
            ...((_g = filterValues.selectedProjects) !== null && _g !== void 0 ? _g : []),
            ...(filterValues.companySize ? [filterValues.companySize] : []),
            ...(filterValues.annualRevenue ? [filterValues.annualRevenue] : []),
        ];
    }, [filterValues]);
    return useMemo(() => ({ allTags, allTagsInformations }), [allTagsInformations, allTags]);
};
export default useTagsInformations;
