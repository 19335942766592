const getTranslationsFromString = ({ translation_en, translation_fr, selectedLocale, fallback, }) => {
    if (selectedLocale === 'en' && !!(translation_en === null || translation_en === void 0 ? void 0 : translation_en.length))
        return translation_en;
    else if (!!(translation_fr === null || translation_fr === void 0 ? void 0 : translation_fr.length))
        return translation_fr;
    else if (!!fallback)
        return fallback;
    else
        return '';
};
export default getTranslationsFromString;
