import FormItemEditList from '@hellodarwin/core/lib/components/forms/form-builder/controllers/group/form-edit-list';
import { AdminProjectResponse } from '@hellodarwin/core/lib/features/entities';
import { Drawer } from 'antd';
import { useState } from 'react';
import { useAppDispatch } from '../../../../../app';
import {
  deletePriority,
  reorderProjects,
} from '../../../../../features/api/slices/projects-slice';
import { useNewAdminApi } from '../../../../../features/api/use-admin-api';

export interface EditPrioritiesDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  priorities: AdminProjectResponse[];
}

const EditPrioritiesDrawer = (props: EditPrioritiesDrawerProps) => {
  const { isOpen, onClose, priorities } = props;
  const dispatch = useAppDispatch();
  const api = useNewAdminApi();
  const [isReorderingLoading, setIsReorderingLoading] = useState(false);

  const handleReorderPriorities = (
    reorderedPriorities: AdminProjectResponse[],
  ) => {
    setIsReorderingLoading(true);
    dispatch(
      reorderProjects({
        api,
        projectIDs: reorderedPriorities.map((priority) => priority.project_id),
      }),
    ).finally(() => {
      setIsReorderingLoading(false);
    });
  };

  return (
    <Drawer
      title="Priorities"
      size="default"
      placement="right"
      closable={true}
      open={isOpen}
      onClose={onClose}
    >
      <FormItemEditList<AdminProjectResponse>
        options={priorities}
        optionLabel={'title'}
        inputs={[]}
        reorder={handleReorderPriorities}
        deleteItem={(priority) => {
          dispatch(deletePriority({ api, priorityId: priority.project_id }));
        }}
        isLoading={isReorderingLoading}
      ></FormItemEditList>
    </Drawer>
  );
};

export default EditPrioritiesDrawer;
