import Button from '@hellodarwin/core/lib/components/common/button';
import FormItemEditList from '@hellodarwin/core/lib/components/forms/form-builder/controllers/group/form-edit-list';
import {
  CompanyGrantResult,
  Grant,
  GrantResult,
} from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import { Input } from 'antd';
import Drawer from 'antd/es/drawer';
import { useState } from 'react';
import GrantSearchDrawer from './grant-search-drawer';

export interface CompanyGrantsDrawerProps {
  open: boolean;
  handleCancel: () => void;
  companyGrants: CompanyGrantResult[];
  onAddGrantToCompany: (grant: Grant) => void;
  onRemoveGrantFromCompany: (grant: Grant) => void;
  onReorderCompanyGrants: (reorderedItems: GrantResult[]) => Promise<any>;
  onSaveCompanyGrantTitle: (grantId: string, newTitle: string) => void;
}

const CompanyGrantsDrawer = ({
  open,
  handleCancel,
  companyGrants,
  onAddGrantToCompany,
  onRemoveGrantFromCompany,
  onReorderCompanyGrants,
  onSaveCompanyGrantTitle,
}: CompanyGrantsDrawerProps) => {
  const { t } = useTranslations();
  const [isAddGrantDrawerOpen, setIsAddGrantDrawerOpen] = useState(false);
  const [isReorderingLoading, setIsReorderingLoading] = useState(false);

  const handleDeleteGrant = (grant: Grant) => {
    onRemoveGrantFromCompany(grant);
  };

  const handleAddGrant = (grant: Grant) => {
    onAddGrantToCompany(grant);
    setIsAddGrantDrawerOpen(false);
  };

  const handleReorderCompanyGrants = async (reorderedItems: GrantResult[]) => {
    setIsReorderingLoading(true);
    await onReorderCompanyGrants(reorderedItems);
    setIsReorderingLoading(false);
  };

  const drawerHeader = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <span>{t('companyAdminPage|add_grant_to_consider')}</span>
      <Button
        size="square"
        transparent
        onClick={() => {
          setIsAddGrantDrawerOpen(true);
        }}
      >
        <AddNew size={16} />
      </Button>
    </div>
  );

  return (
    <>
      <Drawer
        open={open}
        onClose={handleCancel}
        size="large"
        width={500}
        title={drawerHeader}
        styles={{
          body: {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            alignItems: 'stretch',
          },
        }}
      >
        <FormItemEditList<CompanyGrantResult>
          options={companyGrants}
          optionLabel="grant_display_title"
          deleteItem={handleDeleteGrant}
          update={(updatedCompanyGrantResult) =>
            updatedCompanyGrantResult.company_grant_title
              ? onSaveCompanyGrantTitle(
                  updatedCompanyGrantResult.grant_id,
                  updatedCompanyGrantResult.company_grant_title,
                )
              : null
          }
          inputs={[
            {
              label: t('companyAdminPage|potential_project_name'),
              name: 'company_grant_title',
              rules: [
                {
                  required: true,
                  message: t(
                    'companyAdminPage|please_enter_potential_project_name',
                  ),
                },
              ],
              children: {
                render: (companyGrantResult) => (
                  <div>
                    <Input
                      defaultValue={companyGrantResult.company_grant_title}
                      placeholder={t(
                        'companyAdminPage|enter_potential_project_name',
                      )}
                    />
                  </div>
                ),
              },
            },
          ]}
          canEditItem={true}
          isEditButtonVisible={true}
          reorder={handleReorderCompanyGrants}
          isLoading={isReorderingLoading}
        />
      </Drawer>
      <GrantSearchDrawer
        open={isAddGrantDrawerOpen}
        onClose={() => setIsAddGrantDrawerOpen(false)}
        onAddGrant={handleAddGrant}
        companyGrants={companyGrants}
      />
    </>
  );
};

export default CompanyGrantsDrawer;
