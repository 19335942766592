import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from '../../../../../plugins/styled';
import HdTag from '../../../../common/hd-tag';
const FilterProjectTag = ({ project, isActive, handleProjectSelection, }) => {
    const theme = useTheme();
    if (!project.short_description)
        return _jsx(_Fragment, {});
    const handleTagClick = () => {
        handleProjectSelection(project.grant_project_id);
    };
    return (_jsx(HdTag, { onClick: handleTagClick, hoverable: true, text: project.short_description, color: isActive ? theme.colors.purple_3 : theme.colors.purple_4 }));
};
export default FilterProjectTag;
