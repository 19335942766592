import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import Div from '../../../div';
import Loading from '../../../../loading';
import React from 'react';
import Button from '../../../button';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { useTheme } from 'styled-components';
import SeeMoreSidebarModal from './see-more-sidebar-modal';
const MAX_SIZE = 3;
const LIST_GAP = 8;
const SeeMoreSidebarBlock = (props) => {
    const { items, isLoading, render, fallback } = props;
    const { t } = useTranslations();
    const theme = useTheme();
    const [showMore, setShowMore] = useState(false);
    const { hasMore, moreTotal } = useMemo(() => {
        var _a, _b;
        const moreTotal = Math.max(0, ((_a = items === null || items === void 0 ? void 0 : items.length) !== null && _a !== void 0 ? _a : 0) - MAX_SIZE);
        const hasMore = ((_b = items === null || items === void 0 ? void 0 : items.length) !== null && _b !== void 0 ? _b : 0) > MAX_SIZE;
        return { hasMore, moreTotal };
    }, [items]);
    const handleMore = () => setShowMore(!showMore);
    if (isLoading)
        return _jsx(Loading, {});
    if (!items || !(items === null || items === void 0 ? void 0 : items.length))
        return fallback;
    return (_jsxs(Div, { flex: "column", gap: LIST_GAP, style: { width: '100%' }, children: [React.Children.toArray(items.slice(0, MAX_SIZE).map(render)), hasMore && (_jsxs(_Fragment, { children: [_jsx(Button, { fullWidth: true, isLink: true, style: { alignSelf: 'center' }, onClick: handleMore, defaultStyle: theme.colors.purple_2, children: t('gin_program|show_count_more', { count: moreTotal }) }), _jsx(SeeMoreSidebarModal, Object.assign({ open: showMore, handleCancel: handleMore }, props))] }))] }));
};
export default SeeMoreSidebarBlock;
