import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useMediaQuery from '../../../../features/helpers/use-media-query';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import { UserAvatar } from './styles';
const ReviewHeader = ({ review }) => {
    var _a;
    const theme = useTheme();
    const { t } = useTranslations();
    const isLarge = useMediaQuery({ minWidth: theme.breakpoints.l });
    const size = isLarge ? 60 : 38;
    return (_jsx(Div, { flex: "column", fullHeight: true, gap: 8, fitContent: true, children: _jsxs(Div, { flex: "row", align: "center", gap: 22, tablet: { flex: 'column' }, fitContent: true, children: [_jsx(UserAvatar, { shape: "round", size: size, style: { flexShrink: 0, flexGrow: 0, flexBasis: size }, children: (_a = review.contact_name) === null || _a === void 0 ? void 0 : _a[0] }), _jsxs(Div, { flex: "column", gap: 7, fitContent: true, children: [_jsx(Typography.Title, { level: 4, elementTheme: "h6", noMargin: true, tablet: { textAlign: 'center' }, children: review.contact_name }), _jsx(Typography, { elementTheme: "caption", color: theme.colors.grey_3, tablet: { textAlign: 'center' }, children: review.title
                                ? t('profile_reviews|reviewRole', {
                                    role_name: review.title,
                                    company_name: review.company_name,
                                })
                                : review.company_name })] })] }) }));
};
export default ReviewHeader;
