import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Order from '@hellodarwin/icons/dist/icons/Order';
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import Select from 'antd/es/select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
const FilesListOrderBySelect = ({ onChange, value, }) => {
    const { t } = useTranslation();
    const orderOptions = useMemo(() => [
        {
            value: 'ascending',
            label: t('assets|filters.orderBy.options.ascending'),
        },
        {
            value: 'descending',
            label: t('assets|filters.orderBy.options.descending'),
        },
    ], [t]);
    return (_jsx(Select, { options: orderOptions, onChange: onChange, placeholder: t('assets|filters.orderBy.title'), value: value, labelRender: ({ label }) => (_jsxs(Div, { flex: "row", align: "center", gap: 8, children: [_jsx(Order, { size: 16 }), _jsx(Typography, { elementTheme: "body2", children: label })] })), style: {
            width: 221,
        }, suffixIcon: _jsx(TabArrow, { size: 16, down: true }) }));
};
export default FilesListOrderBySelect;
