import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import SingleGrantSections from '../../../features/enums/single-grant-sections';
import { useTranslations } from '../../../features/providers/translations-provider';
import GrantHeader from './grant-header';
import GrantSection from './grant-sections';
import GrantAdmissibility from './grant-sections/admissibility';
import GrantContact from './grant-sections/contact';
import GrantCriteria from './grant-sections/criteria';
import GrantDocuments from './grant-sections/documents';
import GrantOtherInfos from './grant-sections/other-infos';
import GrantPreview from './grant-sections/preview';
import GrantProjects from './grant-sections/projects';
import GrantRegister from './grant-sections/register';
import GrantSummary from './grant-sections/summary';
import GrantTermsAndConditions from './grant-sections/terms-and-conditions';
import { GrantSingleContainer } from './styles';
const SingleGrant = ({ grant, sections, onClick, alreadyApplied, hideNotifications, }) => {
    var _a;
    const { t } = useTranslations();
    if (!grant)
        return _jsx(_Fragment, {});
    const showAdmissibility = !(grant === null || grant === void 0 ? void 0 : grant.eligibility_criteria) &&
        !(grant === null || grant === void 0 ? void 0 : grant.eligible_expenses) &&
        !(grant === null || grant === void 0 ? void 0 : grant.who_can_apply) &&
        !(grant === null || grant === void 0 ? void 0 : grant.who_cannot_apply) &&
        !(grant === null || grant === void 0 ? void 0 : grant.zone);
    const blocks = {
        [SingleGrantSections.Summary]: {
            label: t(`grant_single_labels|${SingleGrantSections.Summary}`),
            menuLabel: t(`grant_single_labels|menu_${SingleGrantSections.Summary}`),
            content: _jsx(GrantSummary, Object.assign({}, grant)),
            id: SingleGrantSections.Summary,
            styles: { body: { padding: 0 }, header: { paddingBottom: 32 } },
        },
        [SingleGrantSections.Admissibility]: {
            label: t(`grant_single_labels|${SingleGrantSections.Admissibility}`),
            menuLabel: t(`grant_single_labels|menu_${SingleGrantSections.Admissibility}`),
            content: _jsx(GrantAdmissibility, Object.assign({}, grant)),
            id: SingleGrantSections.Admissibility,
            hide: showAdmissibility,
        },
        [SingleGrantSections.Preview]: {
            label: t(`grant_single_labels|${SingleGrantSections.Preview}`),
            menuLabel: t(`grant_single_labels|menu_${SingleGrantSections.Preview}`),
            content: _jsx(GrantPreview, Object.assign({}, grant)),
            id: SingleGrantSections.Preview,
            hide: !(grant === null || grant === void 0 ? void 0 : grant.grant_description_long) && !(grant === null || grant === void 0 ? void 0 : grant.grant_youtube_url),
        },
        [SingleGrantSections.TermsAndConditions]: {
            label: t(`grant_single_labels|${SingleGrantSections.TermsAndConditions}`),
            menuLabel: t(`grant_single_labels|menu_${SingleGrantSections.TermsAndConditions}`),
            content: _jsx(GrantTermsAndConditions, Object.assign({}, grant)),
            id: SingleGrantSections.TermsAndConditions,
            hide: !(grant === null || grant === void 0 ? void 0 : grant.funding_terms_and_conditions),
        },
        [SingleGrantSections.OtherInfo]: {
            label: t(`grant_single_labels|${SingleGrantSections.OtherInfo}`),
            menuLabel: t(`grant_single_labels|menu_${SingleGrantSections.OtherInfo}`),
            content: _jsx(GrantOtherInfos, Object.assign({}, grant)),
            id: SingleGrantSections.OtherInfo,
            hide: !(grant === null || grant === void 0 ? void 0 : grant.additional_information),
        },
        [SingleGrantSections.Documents]: {
            label: t(`grant_single_labels|${SingleGrantSections.Documents}`),
            menuLabel: t(`grant_single_labels|menu_${SingleGrantSections.Documents}`),
            content: _jsx(GrantDocuments, Object.assign({}, grant)),
            id: SingleGrantSections.Documents,
            hide: !(grant === null || grant === void 0 ? void 0 : grant.official_documents),
        },
        [SingleGrantSections.Apply]: {
            label: t(`grant_single_labels|${SingleGrantSections.Apply}`),
            menuLabel: t(`grant_single_labels|menu_${SingleGrantSections.Apply}`),
            content: _jsx(GrantRegister, Object.assign({}, grant)),
            id: SingleGrantSections.Apply,
            hide: !grant.grant_steps && !grant.steps_how_to_apply,
        },
        [SingleGrantSections.Criteria]: {
            label: t(`grant_single_labels|${SingleGrantSections.Criteria}`),
            menuLabel: t(`grant_single_labels|menu_${SingleGrantSections.Criteria}`),
            content: _jsx(GrantCriteria, Object.assign({}, grant)),
            id: SingleGrantSections.Criteria,
            hide: !grant.selection_criteria,
        },
        [SingleGrantSections.Contact]: {
            label: t(`grant_single_labels|${SingleGrantSections.Contact}`),
            menuLabel: t(`grant_single_labels|menu_${SingleGrantSections.Contact}`),
            content: _jsx(GrantContact, Object.assign({}, grant)),
            id: SingleGrantSections.Contact,
            hide: !grant.application_email_address && !grant.application_phone_number,
        },
        [SingleGrantSections.Projects]: {
            label: t(`grant_single_labels|${SingleGrantSections.Projects}`),
            menuLabel: t(`grant_single_labels|menu_${SingleGrantSections.Projects}`),
            content: _jsx(GrantProjects, Object.assign({}, grant)),
            id: SingleGrantSections.Projects,
            hide: !grant.project_activity && !((_a = grant.grant_projects) === null || _a === void 0 ? void 0 : _a.length),
        },
    };
    const activeSections = sections.filter((s) => { var _a; return !!blocks[s] && !((_a = blocks[s]) === null || _a === void 0 ? void 0 : _a.hide); });
    return (_jsxs(GrantSingleContainer, { flex: "column", gap: 25, style: { padding: '0px 16px' }, children: [_jsx(GrantHeader, Object.assign({}, grant, { sections: activeSections, blocks: blocks, hideNotifications: hideNotifications })), React.Children.toArray(activeSections.map((s) => (_jsx(GrantSection, { styles: blocks[s].styles, title: blocks[s].label, id: blocks[s].id, applyButton: [
                    SingleGrantSections.Admissibility,
                    SingleGrantSections.Apply,
                    SingleGrantSections.Contact,
                    SingleGrantSections.Summary,
                ]
                    .toString()
                    .includes(blocks[s].id), alreadyApplied: !!alreadyApplied, onClick: onClick, children: blocks[s].content }, s))))] }));
};
export default SingleGrant;
