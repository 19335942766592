import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
const FormCurrentPageEndFooter = ({ isEndPage, isOpening, isContact, currentPageIndex, lastPageIndex, firstPageIndex, handlePrevious, handleNext, }) => {
    const theme = useTheme();
    const { t } = useTranslations();
    if (isEndPage)
        return _jsx(_Fragment, {});
    return (_jsxs(Div, { flex: "row", align: "center", justify: isOpening ? 'center' : 'flex-end', gap: 16, style: { width: '100%', marginBottom: isOpening ? 54 : 0 }, tablet: { flex: 'column-reverse', gap: 8 }, children: [!isOpening && currentPageIndex > firstPageIndex && (_jsx(Button, { onClick: handlePrevious, defaultStyle: theme.colors.white_1, size: "small", style: { transition: 'background-color 200ms linear' }, children: t('button|previous') })), (currentPageIndex < lastPageIndex || isContact) && (_jsx(Button, { onClick: handleNext, defaultStyle: isOpening ? theme.colors.yellow_1 : theme.colors.grey_1, size: isOpening ? 'medium' : 'small', style: { transition: 'background-color 200ms linear' }, withArrowRight: isOpening, children: isContact
                    ? t('button|submit')
                    : isOpening
                        ? t('button|startNow')
                        : t('button|next') }))] }));
};
export default FormCurrentPageEndFooter;
