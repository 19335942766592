import { jsx as _jsx } from "react/jsx-runtime";
import { useNavigate } from '../../../features/providers/location-provider';
import useExpandedProjectsRowRender from '../hooks/use-expanded-projects-row-render';
import projectRowExpandable from '../utils/project-row-expandable';
import { ProjectsTableLevel1 } from './styles';
const MainProjectTableContent = ({ project, projectsListState, }) => {
    const { projects } = project;
    const navigate = useNavigate();
    const { projectsColumns: { projectColumns }, expandedRowKeys, projectsOnly, onExpand, projectPath, } = projectsListState;
    const expandedProjectsRowRender = useExpandedProjectsRowRender({
        projectsListState,
    });
    return (_jsx(ProjectsTableLevel1, { dataSource: projects, rowKey: (p) => p.project_id, pagination: false, rowClassName: (_r, i) => (i % 2 ? 'odd' : 'even'), onRow: (record) => {
            return {
                onClick: () => {
                    if (projectRowExpandable(record)) {
                        onExpand(record.project_id);
                    }
                },
                onDoubleClick: () => {
                    if (!!projectPath) {
                        navigate(`${projectPath}/${record.project_id}`);
                    }
                },
            };
        }, expandable: !projectsOnly
            ? {
                expandedRowRender: expandedProjectsRowRender,
                rowExpandable: projectRowExpandable,
                expandedRowKeys: expandedRowKeys,
                showExpandColumn: false,
                fixed: 'left',
            }
            : undefined, columns: projectColumns, scroll: { x: 'max-content' } }));
};
export default MainProjectTableContent;
