import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import ProjectsList from '@hellodarwin/icons/dist/icons/ProjectsList';
import Save from '@hellodarwin/icons/dist/icons/Save';
import Tooltip from 'antd/es/tooltip';
import getShortId from '../../../../features/helpers/get-short-id';
import useLocale from '../../../../features/providers/locale-provider';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import getItemTranslatedLabel from '../../form-builder/utils/get-item-translated-label';
const FormSubmissionHeader = ({ onFinish, formSubmission: { form }, hasChanged, mode, handleMode, }) => {
    const theme = useTheme();
    const { selectedLocale } = useLocale();
    return (_jsxs(Div, { flex: "row", align: "center", justify: "space-between", gap: 8, borderColor: theme.colors.grey_5, style: {
            position: 'sticky',
            top: theme.appearance.appHeaderHeight,
            boxShadow: theme.appearance.box_shadow_hover,
            padding: 24,
            zIndex: 200,
            backdropFilter: 'blur(4px)',
        }, backgroundColor: theme.colors.white_2, children: [_jsxs("div", { children: [_jsx(Typography, { elementTheme: "h6", children: getItemTranslatedLabel({
                            label_en: form.entity.subtitle_en,
                            label_fr: form.entity.subtitle_fr,
                            fallback: getShortId(form.entity.form_id),
                            parseOptions: {},
                            locale: selectedLocale,
                        }) }), _jsx(Typography, { elementTheme: "body2", children: getItemTranslatedLabel({
                            label_en: form.entity.subtitle_en,
                            label_fr: form.entity.subtitle_fr,
                            fallback: getShortId(form.entity.form_id),
                            parseOptions: {},
                            locale: selectedLocale,
                        }) })] }), _jsxs(Div, { flex: "row", gap: 8, fitContent: true, children: [mode === 'edit' && (_jsx(Tooltip, { title: 'Save', children: _jsx("div", { children: _jsx(Button, { size: "square", onClick: onFinish, headingIcon: _jsx(Save, { width: 20, height: 20 }), defaultStyle: theme.colors.primary, disabled: !hasChanged }) }) })), _jsx(Tooltip, { title: mode === 'edit' ? 'See as List' : 'Edit', children: _jsx("div", { children: _jsx(Button, { size: "square", onClick: handleMode, headingIcon: mode === 'edit' ? (_jsx(ProjectsList, { width: 20, height: 20 })) : (_jsx(Edit, { width: 20, height: 20 })), defaultStyle: theme.colors.primary }) }) })] })] }));
};
export default FormSubmissionHeader;
