import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Rate from 'antd/es/rate';
import { useEffect, useMemo, useState } from 'react';
import Typography from '../../common/typography';
import { ReviewRatingsContainer } from './styles';
import useRatingIcon from './use-rating-icon';
const ReviewRatings = (props) => {
    const { defaultValue, disabled, showValue, showOne, showTotal, size = 'medium', textPlacement, biggerText, onChange, value, allowHalf, } = props;
    const [currentValue, setCurrentValue] = useState(defaultValue);
    const getRatingIcon = useRatingIcon(Object.assign(Object.assign({}, props), { value: currentValue }));
    const typographySize = useMemo(() => {
        const elementTheme = biggerText
            ? ['extra-large', 'large'].includes(size)
                ? 'h4'
                : ['extra-small', 'small'].includes(size)
                    ? 'subtitle1'
                    : 'h5'
            : ['extra-large', 'large'].includes(size)
                ? 'subtitle2'
                : ['small'].includes(size)
                    ? 'body2'
                    : ['extra-small'].includes(size)
                        ? 'overline'
                        : 'body1';
        return elementTheme;
    }, [props]);
    useEffect(() => {
        if (!!value) {
            setCurrentValue(value);
        }
        else {
            setCurrentValue(defaultValue);
        }
    }, [value, defaultValue]);
    const handleChange = (value) => {
        setCurrentValue(value);
        onChange(value);
    };
    const rateProps = useMemo(() => ({
        allowHalf,
        character: getRatingIcon,
        defaultValue,
        disabled,
        onChange: handleChange,
    }), [props, currentValue]);
    const icon = useMemo(() => (showOne ? getRatingIcon() : _jsx(Rate, Object.assign({}, rateProps))), [props]);
    return showValue ? (_jsxs(ReviewRatingsContainer, { "$textPlacement": textPlacement, children: [_jsxs(Typography, { elementTheme: typographySize, style: { lineHeight: 1, transform: 'translateY(2px)' }, children: [defaultValue, " ", !!showTotal && `/ 5`] }), icon] })) : (icon);
};
export default ReviewRatings;
