import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import SidebarPageLayout, {
  OnSidebarChangeFunc,
} from '@hellodarwin/core/lib/components/common/layout/sidebar-page-layout.tsx';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Search from '@hellodarwin/icons/dist/icons/Search';
import { message } from 'antd';
import Empty from 'antd/es/empty';
import { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app';
import GinSingleGrantPage from '../../../components/gins/grant-single';
import { GinGrantSinglePageSection } from '../../../components/gins/grant-single/use-gin-grant-blocks';
import GinRelatedContacts from '../../../components/gins/sidebars/related-contacts';
import GinSidebarInterviewGuide from '../../../components/gins/sidebars/related-interview-guides';
import GinRelatedPrograms from '../../../components/gins/sidebars/related-programs';
import GinSidebarEmail from '../../../components/gins/sidebars/templates-emails';
import GrantChangeCompletionTimeEstimate from '../../../components/grants/grant-change-completion-time';
import GrantChangeDifficulty from '../../../components/grants/grant-change-difficulty';
import GrantValidationButton from '../../../components/grants/grant-validation/grant-validation-button';
import {
  fetchGinByGrantId,
  fetchGinInterviewGuides,
  selectCurrentGin,
  selectGinInterviewGuides,
  selectProgramsSearchModal,
  toggleProgramsSearchModal,
} from '../../../features/api/slices/gins-slice';
import {
  selectGrantById,
  updateGrant,
} from '../../../features/api/slices/grants-slice';
import {
  useAdminApi,
  useNewAdminApi,
} from '../../../features/api/use-admin-api';
import { useAdminSettings } from '../../../features/providers/admin-settings-provider';

const SingleGinPage = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const adminApi = useNewAdminApi();
  const adminApiClient = useAdminApi();
  const dispatch = useAppDispatch();
  const { selectedLocale } = useLocale();
  const { t } = useTranslations();

  const [isAddAgentVisible, setAddAgentVisible] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState<boolean>(false);
  const [activeSnippet, setActiveSnippet] = useState<boolean>(false);
  const [activeInterviewGuide, setActiveInterviewGuide] =
    useState<boolean>(false);
  const grant = useAppSelector((state) => selectGrantById(state, params.id!));
  const [localGrant, setLocalGrant] = useState(grant);
  const [selectedDifficulty, setSelectedDifficulty] = useState<string>('');
  const [selectedCte, setSelectedCte] = useState<string>('');

  const { getSetting, updateSetting } = useAdminSettings();
  const sidebarSetting = getSetting('ginSidebarsCollapsed');

  const onCloseContactsDrawer = () => {
    setAddAgentVisible(false);
  };

  const onOpenContactsDrawer = () => {
    setAddAgentVisible(true);
  };
  useEffect(() => {
    if (params.id) {
      dispatch(fetchGinByGrantId({ api: adminApi, grantId: params.id }));
      dispatch(fetchGinInterviewGuides({ api: adminApi, grantId: params.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  const adminGin = useAppSelector(selectCurrentGin);
  const interviewGuides = useAppSelector(selectGinInterviewGuides);
  const onNewTemplates: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setActiveTemplate(true);
  };
  const onNewSnippet: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setActiveSnippet(true);
  };
  const onNewInterviewGuides: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setActiveInterviewGuide(true);
  };

  const handleBack = () => {
    navigate('/gin');
  };
  const title = useMemo(() => {
    if (!adminGin?.grant) return 'Grant';

    return selectedLocale === 'fr'
      ? adminGin?.grant?.grant_title_fr
      : adminGin?.grant?.grant_title_en;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminGin?.grant]);

  useEffect(() => {
    if (grant) {
      setSelectedDifficulty(grant?.difficulty || '');
      setSelectedCte(grant?.completion_time_estimate || '');
      setLocalGrant(grant);
    }
  }, [grant]);

  const handleChange = async (field: string, newValue: string) => {
    if (!localGrant) return;

    try {
      switch (field) {
        case 'completion_time_estimate':
          setSelectedCte(newValue);
          break;
        case 'difficulty':
          setSelectedDifficulty(newValue);
      }

      const updatedGrant = {
        ...localGrant,
        [field]: newValue,
      };

      setLocalGrant(updatedGrant);

      await dispatch(updateGrant({ api: adminApiClient, grant: updatedGrant }));

      message.success(t('programs_admin|saved'));
    } catch (error) {
      message.error(t('Oops!'));
    }
  };

  const onSidebarChange: OnSidebarChangeFunc = (side, value) => {
    updateSetting('ginSidebarsCollapsed', { ...sidebarSetting, [side]: value });
  };

  const handleProgramsSearchModalOpen = () => {
    dispatch(toggleProgramsSearchModal({ isVisible: true }));
  };

  const programsSearchModal = useAppSelector(selectProgramsSearchModal);

  if (!params.id) return <Empty />;
  const sections: GinGrantSinglePageSection[] = [
    GinGrantSinglePageSection.ProductionStages,
    GinGrantSinglePageSection.GameplanDescription,
    GinGrantSinglePageSection.EligibilityCriteria,
    GinGrantSinglePageSection.BusinessAdmissibility,
    GinGrantSinglePageSection.ActivitiesAdmissibility,
    GinGrantSinglePageSection.ExpenseAdmissibility,
    GinGrantSinglePageSection.Files,
    GinGrantSinglePageSection.FinancialAssistance,
    GinGrantSinglePageSection.ProcessSummary,
    GinGrantSinglePageSection.SelectionCriteria,
    GinGrantSinglePageSection.Other,
  ];

  return (
    <SidebarPageLayout
      app="admin"
      title={title}
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: 'Grants Internal Notes',
          path: '/gin',
        },
        {
          breadcrumbName: title,
        },
      ]}
      leftSidebarBlocks={[
        {
          content: (
            <GinRelatedContacts
              grantId={params.id}
              open={isAddAgentVisible}
              onClose={onCloseContactsDrawer}
            />
          ),
          title: t('gin_program|mandated_agents'),
          extra: (
            <AddNew
              size={16}
              onClick={onOpenContactsDrawer}
              style={{ cursor: 'pointer' }}
            />
          ),
        },
        {
          title: t('gin_program|interview_guides'),
          content: (
            <GinSidebarInterviewGuide
              grant_id={params.id}
              open={activeInterviewGuide}
              onClose={setActiveInterviewGuide}
              interviewGuides={interviewGuides}
            />
          ),
          extra: (
            <Button transparent onClick={onNewInterviewGuides}>
              <AddNew size={16} style={{ cursor: 'pointer' }} />
            </Button>
          ),
        },
        {
          title: t('gin_program|only_email'),
          content: [
            {
              title: t('gin_program|template'),
              content: (
                <GinSidebarEmail
                  grant_id={params.id}
                  type="template"
                  open={activeTemplate}
                  onClose={setActiveTemplate}
                  email_items={adminGin?.templates}
                />
              ),
              extra: (
                <Button transparent onClick={onNewTemplates}>
                  <AddNew size={16} style={{ cursor: 'pointer' }} />
                </Button>
              ),
            },
            {
              title: t('gin_program|snippet'),
              content: (
                <GinSidebarEmail
                  grant_id={params.id}
                  type="snippet"
                  open={activeSnippet}
                  onClose={setActiveSnippet}
                  email_items={adminGin?.snippets}
                />
              ),
              extra: (
                <Button transparent onClick={onNewSnippet}>
                  <AddNew size={16} style={{ cursor: 'pointer' }} />
                </Button>
              ),
            },
          ],
        },
      ]}
      rightSidebarBlocks={[
        {
          content: <GinRelatedPrograms grantId={params.id} />,
          extra: (
            <Button
              style={{ paddingLeft: 10, paddingBottom: 5 }}
              transparent
              onClick={handleProgramsSearchModalOpen}
            >
              <Search size={20} />
            </Button>
          ),
          title: t('gin_program|applications'),
        },
      ]}
      defaultNavCollapsed
      noFooter
      fitContent
      underSearch={
        <Div flex="row" gap={16} align="center">
          <GrantChangeCompletionTimeEstimate
            grantId={params.id}
            handleChange={(value) =>
              handleChange('completion_time_estimate', value)
            }
            cte={selectedCte}
          />
          <GrantChangeDifficulty
            grantId={params.id}
            handleChange={(value) => handleChange('difficulty', value)}
            difficulty={selectedDifficulty}
          />
          <GrantValidationButton grantId={params.id} filterByType="gin" />
        </Div>
      }
      handleBack={handleBack}
      defaultLeftSidebarCollapsed={sidebarSetting.left}
      defaultRightSidebarCollapsed={sidebarSetting.right}
      onSidebarChange={onSidebarChange}
    >
      {!!params.id && (
        <GinSingleGrantPage grantId={params.id} sections={sections} />
      )}
      <GinRelatedPrograms
        grantId={params.id}
        modal={{
          open: programsSearchModal.isVisible,
          handleCancel: () =>
            dispatch(
              toggleProgramsSearchModal({
                isVisible: false,
              }),
            ),
        }}
      />
    </SidebarPageLayout>
  );
};

export default SingleGinPage;
