import Typography from '@hellodarwin/core/lib/components/common/typography';
import {
  InitialStepAssetValue,
  InitialStepValue,
  Step,
  StepAsset,
  StepFormFunction,
} from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Drawer from 'antd/es/drawer';
import message from 'antd/es/message';
import React from 'react';
import { useAppDispatch } from '../../../../../app';
import {
  createNewSteps,
  createStepAsset,
  deleteSingleSelectedStep,
  deleteStepAsset,
  translateStepSection,
  updateSingleSelectedStep,
  updateStepAsset,
  updateStepsOrder,
} from '../../../../../features/api/slices/gin-steps-slice';
import {
  useAdminApi,
  useNewAdminApi,
} from '../../../../../features/api/use-admin-api';
import GinStepForm from './gin-steps-form';

interface GinStepsDrawerProps {
  open: boolean;
  onClose: () => void;
  section: string;
  grantId: string;
  steps: Step[];
  setActiveStep: (step: Step | undefined) => void;
  activeStep: Step | undefined;
  setActiveAsset: (asset: StepAsset | undefined) => void;
  activeAsset: StepAsset | undefined;
}
const GinStepsDrawer: React.FC<GinStepsDrawerProps> = ({
  open,
  onClose,
  section,
  ...formProps
}) => {
  const { t } = useTranslations();
  const api = useAdminApi();
  const newApi = useNewAdminApi();
  const dispatch = useAppDispatch();

  const functions: StepFormFunction = {
    updateSelectedStep: async (step: Step) => {
      try {
        const response = await dispatch(
          updateSingleSelectedStep({ api, step: step }),
        ).unwrap();
        message.success(t('gin_steps|step_update_successful'));
        return response.updatedStep;
      } catch (error) {
        message.error(t('gin_steps|step_update_error'));
        console.error(error);
      }
      return step;
    },
    createNewStep: async (entityId: string, step: Step) => {
      try {
        const response = await dispatch(
          createNewSteps({ api, grantId: entityId, step: step }),
        ).unwrap();

        message.success(t('gin_steps|step_created_successful'));
        return response;
      } catch (error) {
        message.error(t('gin_steps|step_created_error'));
        console.error(error);
      }
      return InitialStepValue;
    },
    updateSelectedAsset: async (asset: StepAsset) => {
      try {
        const response = await dispatch(
          updateStepAsset({ api: newApi, asset: asset }),
        ).unwrap();
        message.success(t('gin_steps|step_asset_update_successful'));
        return response;
      } catch (error) {
        message.error(t('gin_steps|step_asset_update_error'));
        console.error(error);
      }
      return InitialStepAssetValue;
    },
    createNewAsset: async (asset: StepAsset) => {
      try {
        const response = await dispatch(
          createStepAsset({
            api: newApi,
            asset: asset,
            stepID: formProps.activeStep?.step_id || '',
          }),
        ).unwrap();
        message.success(t('gin_steps|step_asset_create_successful'));
        return response;
      } catch (error) {
        message.error(t('gin_steps|step_asset_create_error'));
        console.error(error);
      }
      return InitialStepAssetValue;
    },
    deleteSelectedStep: async (grant_id: string, step_id: string) => {
      try {
        const response = await dispatch(
          deleteSingleSelectedStep({
            api,
            grant_id: grant_id,
            step_id: step_id,
          }),
        ).unwrap();
        message.success(t('gin_steps|step_delete_successful'));
        return response;
      } catch (error) {
        message.error(t('gin_steps|step_delete_error'));
        console.error(error);
      }
      return [];
    },
    deleteSelectedAsset: async (step_asset_id: string) => {
      try {
        const response = await dispatch(
          deleteStepAsset({
            api: newApi,
            stepID: formProps.activeStep?.step_id || '',
            assetID: step_asset_id,
          }),
        ).unwrap();
        message.success(t('gin_steps|step_asset_delete_successful'));
        return response.assetID;
      } catch (error) {
        message.error(t('gin_steps|step_asset_delete_error'));
        console.error(error);
      }
      return '';
    },
    reorderSteps: async (grant_id: string, steps: Step[]) => {
      try {
        const response = await dispatch(
          updateStepsOrder({ api, grantId: grant_id, steps: steps }),
        ).unwrap();
        message.success(t('gin_steps|step_reorder_successful'));
        return response;
      } catch (error) {
        message.error(t('gin_steps|step_reorder_error'));
        console.error(error);
      }
      return [];
    },
    translateStepSections: async (
      section: keyof Step,
      otherContent: string,
      targetLocale: string,
    ) => {
      try {
        await dispatch(
          translateStepSection({
            api: newApi,
            stepID: formProps.activeStep?.step_id!,
            targetLocale,
            otherContent,
            section,
          }),
        ).unwrap();
        message.success(t('gin_steps|translation_successful'));
      } catch (error) {
        message.error(t('gin_steps|step_translation_error'));
        console.error(error);
      }
    },
  };
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={
        <Typography elementTheme="subtitle2">
          {t(`gin_section|${section}`)}
        </Typography>
      }
    >
      <GinStepForm functions={functions} {...formProps} />
    </Drawer>
  );
};
export default GinStepsDrawer;
