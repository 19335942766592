const fileTypes = 'jpg:jpeg:png';
export const getFileTypes = () => {
    return fileTypes.split(':');
};
const validateFile = (file) => {
    const isFileSizeOk = file.size / 1024 / 1024 < 100;
    const isFileTypeOk = fileTypes.includes(file.type.split('/')[1].toLowerCase());
    if (!isFileSizeOk || !isFileTypeOk) {
        return false;
    }
    return true;
};
export default validateFile;
