import Language from '../enums/language';
import ProviderType from '../enums/provider-type';
import RfpStatus from '../enums/rfp-status';
import { default as RfpType } from '../enums/rfp-type';
export const RfpInitialState = {
    rfp_id: '',
    title: '',
    description: '',
    original_description: '',
    type: RfpType.Classic,
    status: RfpStatus.PendingApproval,
    budget_min: 0,
    budget_max: 0,
    budget_type: '',
    premium_at: '',
    provider_max: 0,
    account_manager: '',
    activated_at: '',
    assets: [],
    category: '',
    created_at: '',
    provider_type: ProviderType.Any,
    languages: Language.Any,
    service: '',
    raise_hand_max: 0,
    industry: '',
};
