import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Language = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 30C19.1033 29.9998 22.1187 28.969 24.5727 27.0694C27.0267 25.1697 28.7803 22.5089 29.5582 19.5047M16 30C12.8967 29.9998 9.88128 28.969 7.42729 27.0694C4.9733 25.1697 3.21968 22.5089 2.44178 19.5047M16 30C19.8655 30 23 23.7311 23 16C23 8.26889 19.8655 2 16 2M16 30C12.1344 30 9 23.7311 9 16C9 8.26889 12.1344 2 16 2M29.5582 19.5047C29.846 18.3847 30 17.2102 30 16C30.0038 13.5922 29.3838 11.2244 28.2002 9.12756M29.5582 19.5047C25.4098 21.8044 20.7432 23.0074 16 23C11.0813 23 6.45978 21.7322 2.44178 19.5047M2.44178 19.5047C2.14739 18.3597 1.99895 17.1822 2.00001 16C2.00001 13.5033 2.65334 11.1576 3.79978 9.12756M16 2C18.483 1.99897 20.9216 2.65856 23.0656 3.9111C25.2096 5.16364 26.9817 6.96404 28.2002 9.12756M16 2C13.517 1.99897 11.0783 2.65856 8.93437 3.9111C6.79039 5.16364 5.01826 6.96404 3.79978 9.12756M28.2002 9.12756C24.8133 12.0612 20.4808 13.6731 16 13.6667C11.3364 13.6667 7.07111 11.9556 3.79978 9.12756"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};

export default Language;
