'use client';
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState, } from 'react';
import { useLocation } from './location-provider';
const PAGE_KEY = 'page';
const PAGE_SIZE_KEY = 'size';
export const PaginationContext = createContext({
    goToPrevPage() { },
    goToNextPage() { },
    goToPage(page) { },
    addParamsToURL() { },
    addParam(key, value) { },
    addMultipleParams(params) { },
    params: {},
    getParam(key) {
        return key;
    },
    removeParam(key) { },
    clearAllParams(activePage) { },
    activePage: 1,
    pageSize: 10,
    paramsIsLoading: true,
});
export const PaginationProvider = ({ children, defaultPageSize = 10, defaultPage = 1, }) => {
    const { navigate, pathname, search } = useLocation();
    const [activePage, setActivePage] = useState(defaultPage);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [data, setData] = useState({});
    const [paramsIsLoading, setParamsIsLoading] = useState(true);
    const getParam = (key) => {
        return data[key] || '';
    };
    const clearAllParams = (activePage, size) => {
        const params = new URLSearchParams();
        params.set(PAGE_KEY, `${activePage}`);
        if (!!size) {
            params.set(PAGE_SIZE_KEY, `${size || defaultPageSize}`);
        }
        const newUrl = `?${params.toString()}`;
        setData({});
        window.history.replaceState(null, '', newUrl);
        navigate(newUrl);
    };
    const preparePage = () => {
        setParamsIsLoading(true);
        const params = new URLSearchParams(search);
        const page = parseInt(`${params.get(PAGE_KEY)}`);
        if (!!page && page !== activePage) {
            setActivePage(page);
        }
        else {
            setActivePage(defaultPage);
        }
        const size = parseInt(`${params.get(PAGE_SIZE_KEY)}`);
        if (!!size && size !== pageSize) {
            setPageSize(size);
        }
        else {
            setPageSize(defaultPageSize);
        }
        if (Object.keys(data).length === 0) {
            addSearchToParams();
        }
        setTimeout(() => setParamsIsLoading(false), 500);
    };
    useEffect(() => {
        preparePage();
    }, []);
    useEffect(() => {
        const page = parseInt(getParam(PAGE_KEY));
        if (activePage !== page && (activePage !== defaultPage || !!page)) {
            setData((prevData) => (Object.assign(Object.assign({}, prevData), { [`${PAGE_KEY}`]: `${activePage}` })));
        }
    }, [activePage]);
    useEffect(() => {
        const size = parseInt(getParam(PAGE_SIZE_KEY));
        if (pageSize !== size && pageSize !== defaultPageSize) {
            setData((prevData) => (Object.assign(Object.assign({}, prevData), { [`${PAGE_SIZE_KEY}`]: `${pageSize}` })));
        }
    }, [pageSize]);
    useEffect(() => {
        addParamsToURL();
    }, [data]);
    useEffect(() => {
        const page = parseInt(getParam(PAGE_KEY));
        if (!!page && page !== activePage) {
            setActivePage(page);
        }
    }, [pathname]);
    const addParam = (key, value) => {
        if ([PAGE_KEY, PAGE_SIZE_KEY].includes(key))
            return;
        setData((prevData) => (Object.assign(Object.assign({}, prevData), { [key]: value })));
    };
    const addMultipleParams = (params) => {
        setData((prevData) => {
            return Object.assign(Object.assign({}, prevData), params);
        });
    };
    const addParamsToURL = () => {
        const params = new URLSearchParams();
        for (const key of Object.keys(data)) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                const element = data[key];
                if (element !== undefined && element !== 'undefined') {
                    params.set(key, element);
                }
                else {
                    params.delete(key);
                }
            }
        }
        navigate(`?${params.toString()}`, { replace: true });
    };
    const addSearchToParams = () => {
        const params = new URLSearchParams(search);
        setData(Object.fromEntries(params.entries()));
    };
    const removeParam = (key) => {
        if ([PAGE_KEY, PAGE_SIZE_KEY].includes(key))
            return;
        setData((prevData) => {
            const _a = prevData, _b = key, _ = _a[_b], rest = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
            return rest;
        });
    };
    const goToPrevPage = () => {
        setActivePage(activePage - 1);
    };
    const goToNextPage = () => {
        setActivePage(activePage + 1);
    };
    const goToPage = (page, size) => {
        setActivePage(page);
        if (!!size) {
            setPageSize(size);
        }
    };
    return (_jsx(PaginationContext.Provider, { value: {
            goToNextPage,
            goToPrevPage,
            goToPage,
            addParam,
            addMultipleParams,
            getParam,
            removeParam,
            clearAllParams,
            activePage,
            params: data,
            pageSize,
            addParamsToURL,
            paramsIsLoading,
        }, children: children }));
};
export const usePagination = () => useContext(PaginationContext);
export default PaginationProvider;
