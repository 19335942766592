import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import CollapsePanel from './CollapsePanel';
import { CollapseContainer } from './styles';
const Collapse = ({ accordion, activeKey, defaultActiveKey = [], gap, ExpandIcon, expandIconPosition, expandIconPositionTablet, className, onChange, ghost, noPadding, styles, style, items, }) => {
    const [activeElements, setActiveElements] = useState(defaultActiveKey);
    const handleClick = (key) => {
        const values = !!activeKey ? activeKey : activeElements;
        let newValues;
        if (typeof values === 'string' && values === key) {
            newValues = '';
        }
        else if (!!accordion || typeof values === 'string') {
            newValues = key;
        }
        else if (values === null || values === void 0 ? void 0 : values.includes(key)) {
            newValues = values.filter((e) => e !== key);
        }
        else {
            newValues = [...values, key];
        }
        if (!!onChange) {
            onChange(newValues);
        }
        else {
            setActiveElements(newValues);
        }
    };
    useEffect(() => {
        if (!!activeKey) {
            setActiveElements(activeKey);
        }
    }, [activeKey]);
    const isActive = (key) => {
        const values = !!activeKey ? activeKey : activeElements;
        if (typeof values === 'string') {
            return key === values;
        }
        else {
            return !!(values === null || values === void 0 ? void 0 : values.includes(key));
        }
    };
    return (_jsx(CollapseContainer, { style: Object.assign(Object.assign({ display: 'flex', flexDirection: 'column', gap }, style), styles === null || styles === void 0 ? void 0 : styles.container), className: className, children: React.Children.toArray(items.map((itm) => (_jsx(CollapsePanel, Object.assign({ ExpandIcon: ExpandIcon, expandIconPosition: expandIconPosition, expandIconPositionTablet: expandIconPositionTablet, onClick: handleClick, isActive: isActive(itm.id), hasGap: !!gap, ghost: ghost, noPadding: noPadding, styles: {
                container: styles === null || styles === void 0 ? void 0 : styles.panelContainer,
                content: styles === null || styles === void 0 ? void 0 : styles.panelContent,
                header: styles === null || styles === void 0 ? void 0 : styles.panelHeader,
            } }, itm))))) }));
};
export default Collapse;
