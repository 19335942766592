import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Budget from '@hellodarwin/icons/dist/icons/Budget';
import Confirm from '@hellodarwin/icons/dist/icons/Confirm';
import Enterprise from '@hellodarwin/icons/dist/icons/Enterprise';
import Industry from '@hellodarwin/icons/dist/icons/Industry';
import Invoices from '@hellodarwin/icons/dist/icons/Invoices';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import React from 'react';
import { dollarFormat } from '../../../../../features/helpers';
import useLocale from '../../../../../features/providers/locale-provider';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../plugins/styled';
import HdTag from '../../../../common/hd-tag';
import GrantsTimeline from '../../../components/grants-timeline';
import StatusTags from './status-tags-list';
import { SummaryBoxesList } from './styles';
import SummaryBox from './summary-box';
const GrantSummary = (grant) => {
    const { application_status, grant_providers, funding_min_amount, funding_max_amount, percentage_funding, grant_industry_sectors, grant_financing_type, grant_timeline, } = grant;
    const { selectedLocale } = useLocale();
    const theme = useTheme();
    const { t } = useTranslations();
    return (_jsxs(_Fragment, { children: [_jsxs(SummaryBoxesList, { children: [_jsx(SummaryBox, { label: t('grant_single|eligibleFinancing'), Icon: Budget, children: _jsxs("ul", { children: [!!funding_min_amount && !!funding_max_amount ? (_jsx("li", { children: t('grant_single|fromMinToMax', {
                                        min: `${dollarFormat(funding_min_amount, selectedLocale)}`,
                                        max: `${dollarFormat(funding_max_amount, selectedLocale)}`,
                                    }) })) : !!funding_min_amount ? (_jsx("li", { children: t('grant_single|minCount', {
                                        count: `${dollarFormat(funding_min_amount, selectedLocale)}`,
                                    }) })) : (!!funding_max_amount && (_jsx("li", { children: t('grant_single|maxCount', {
                                        count: `${dollarFormat(funding_max_amount, selectedLocale)}`,
                                    }) }))), !!percentage_funding && (_jsx("li", { children: t('grant_single|projectCostPercent', {
                                        percent: `${percentage_funding}`,
                                    }) })), !funding_min_amount &&
                                    !funding_max_amount &&
                                    !percentage_funding && _jsx("li", { children: t('grant_single|noCondition') })] }) }), _jsx(SummaryBox, { label: t('grant_single|deadlines'), Icon: Timeline, children: _jsx(GrantsTimeline, { grant: grant }) }), _jsx(SummaryBox, { label: t('grant_single|financingType'), Icon: Invoices, children: _jsx("div", { style: {
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 8,
                                alignItems: 'flex-start',
                                height: 'fit-content',
                            }, children: React.Children.toArray(grant_financing_type === null || grant_financing_type === void 0 ? void 0 : grant_financing_type.map((type) => (_jsx(HdTag, { color: theme.colors.purple_2, text: type }, type)))) }) })] }), _jsxs(SummaryBoxesList, { children: [_jsx(SummaryBox, { label: t('grant_single|eligibleIndustries'), Icon: Industry, children: _jsx("ul", { style: { width: '100%' }, children: !!(grant_industry_sectors === null || grant_industry_sectors === void 0 ? void 0 : grant_industry_sectors.length) ? (React.Children.toArray(grant_industry_sectors.map((tag) => _jsx("li", { children: tag }, tag)))) : (_jsx("li", { style: { width: '100%' }, children: t('grant_single|allIndustries') })) }) }), _jsx(SummaryBox, { label: t('grant_single|grantors'), Icon: Enterprise, children: _jsx("ul", { style: { width: '100%' }, children: !!(grant_providers === null || grant_providers === void 0 ? void 0 : grant_providers.length) ? (React.Children.toArray(grant_providers.map((tag) => _jsx("li", { children: tag }, tag)))) : (_jsx("li", { style: { width: '100%' }, children: t('grant_single|unspecified') })) }) }), _jsx(SummaryBox, { label: t('grant_single|status'), Icon: Confirm, children: _jsx(StatusTags, { application_status: application_status !== null && application_status !== void 0 ? application_status : 'closed' }) })] })] }));
};
export default GrantSummary;
