import { jsx as _jsx } from "react/jsx-runtime";
import Checkbox from 'antd/es/checkbox';
import Divider from 'antd/es/divider';
import Input from 'antd/es/input';
import TextArea from 'antd/es/input/TextArea';
import Radio from 'antd/es/radio';
import Select from 'antd/es/select';
import theme from '../../../../theme';
import AddressAutocompleteInput from '../../form-groups/adress-autocomplete-input';
import SelectCountry from '../../form-groups/country-select';
import GenderSelect from '../../form-groups/gender-select';
import SelectProvince from '../../form-groups/province-select';
import Uploader from '../../form-groups/uploader';
import FileUploader from '../../form-groups/uploader/file';
export const FORM_PREBUILT_COMPONENTS_TYPES = [
    {
        key: 'province',
        label: 'Province / State Select',
        content: _jsx(SelectProvince.Demo, {}),
    },
    { key: 'gender', label: 'Gender Select', content: _jsx(GenderSelect.Demo, {}) },
    { key: 'country', label: 'Country Select', content: _jsx(SelectCountry.Demo, {}) },
    {
        key: 'address',
        label: 'Address Autocomplete Input',
        content: _jsx(AddressAutocompleteInput.Demo, {}),
    },
    {
        key: 'uploader',
        label: 'Uploader',
        content: _jsx(Uploader.Demo, {}),
    },
];
export const FORM_MAIN_TYPES = [
    {
        key: 'page',
        label: 'New page',
        content: (_jsx(Divider, { style: { borderColor: theme.colors.primary }, children: "New Page" })),
    },
];
export const FORM_GROUPS_TYPES = [
    {
        key: 'group',
        label: 'Group',
        content: (_jsx(Divider, { style: { borderColor: theme.colors.primary }, children: "Group" })),
    },
];
export const FORM_ITEMS_TYPES = [
    {
        key: 'text',
        label: 'Text',
        content: _jsx(TextArea, {}),
    },
    {
        key: 'shortText',
        label: 'Short Text',
        content: _jsx(Input, {}),
    },
    {
        key: 'datetime',
        label: 'Date',
        content: _jsx(Input, { type: "date" }),
    },
    {
        key: 'select',
        label: 'Select',
        content: _jsx(Select, {}),
    },
    {
        key: 'radio',
        label: 'Radio',
        content: _jsx(Radio, {}),
    },
    {
        key: 'checkbox',
        label: 'Checkbox',
        content: _jsx(Checkbox, {}),
    },
    {
        key: 'attachment',
        label: 'Attachment',
        content: _jsx(FileUploader.Demo, {}),
    },
];
export const FormItemOptions = [
    { label: 'Radio', value: 'radio' },
    { label: 'Checkbox', value: 'checkbox' },
    { label: 'Select', value: 'select' },
    { label: 'Text', value: 'text' },
    { label: 'Short Text', value: 'shortText' },
    { label: 'Date', value: 'datetime' },
];
