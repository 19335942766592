import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grants from '@hellodarwin/icons/dist/icons/Grants';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import { FundingExplorerListPageLockedWarningContainer } from './styles';
const FundingExplorerListLockedWarning = ({ goToSettings, }) => {
    const theme = useTheme();
    const { t } = useTranslations();
    return (_jsx(Div, { flex: "column", align: "center", justify: "center", style: { position: 'sticky', top: 100, padding: 54 }, tablet: { style: { padding: 0 } }, children: _jsx(FundingExplorerListPageLockedWarningContainer, { children: _jsxs(Div, { flex: "column", align: "center", children: [_jsx(Grants, { size: 48, style: { marginBottom: 16 } }), _jsx(Typography, { elementTheme: "h5", color: theme.colors.primary, textAlign: "center", style: { marginBottom: 8, maxWidth: 400 }, children: t('grantsFundingExplorer|grantsfundingExplorerLockedTitle') }), _jsx(Typography, { textAlign: "center", style: { maxWidth: 500, width: '100%' }, children: t('grantsFundingExplorer|grantsfundingExplorerLockedSubtitle') }), _jsx(Button, { defaultStyle: theme.colors.primary, onClick: goToSettings, style: { margin: '24px 0 32px 0' }, textWrap: true, children: t('grantsFundingExplorer|grantsfundingExplorerLockedButton') }), _jsx(Typography, { elementTheme: "body3", textAlign: "center", style: { fontStyle: 'italic' }, color: theme.colors.grey_2, children: t('grantsFundingExplorer|grantsfundingExplorerLockedOverline') })] }) }) }));
};
export default FundingExplorerListLockedWarning;
