import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import {
  AdminTags,
  GrantRfpTag,
  TagType,
} from '@hellodarwin/core/lib/features/entities';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Copy from '@hellodarwin/icons/dist/icons/Copy';
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Design from '@hellodarwin/icons/dist/icons/Design';
import { Button, message, Select, Table, Tooltip } from 'antd';
import { locale } from 'dayjs';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app';
import {
  fetchAllGrantRfpTags,
  fetchChildTags,
  selectExpenseCategoryTagsOptions,
  selectExpenseTagsOptions,
  selectGoalTagsOptions,
  selectGrantRfpTags,
} from '../../features/api/slices/new-tags-slice';
import { fetchTags, selectAllTags } from '../../features/api/slices/tags-slice';
import { useAdminApi, useNewAdminApi } from '../../features/api/use-admin-api';

interface MatchmakingListProps {
  searchTerm: string;
  setAddedGrantRfpTags: Dispatch<SetStateAction<GrantRfpTag[]>>;
  setDeletedGrantRfpTags: Dispatch<SetStateAction<GrantRfpTag[]>>;
}

interface DataSourceItem {
  key: string;
  expense: string;
  expenseCategory: string;
  goal: string;
  serviceTags: AdminTags[];
  specialtyTags: AdminTags[];
}

const MatchmakingList = ({
  searchTerm,
  setAddedGrantRfpTags,
  setDeletedGrantRfpTags,
}: MatchmakingListProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const api = useAdminApi();
  const newApi = useNewAdminApi();
  const dispatch = useAppDispatch();
  const { selectedLocale } = useLocale();
  const tags = useAppSelector(selectAllTags);
  const goalTagsOptions = useAppSelector(selectGoalTagsOptions);
  const expenseCategoryTagsOptions = useAppSelector(
    selectExpenseCategoryTagsOptions,
  );
  const expenseTagsOptions = useAppSelector(selectExpenseTagsOptions);
  const grantRfpTags = useAppSelector(selectGrantRfpTags);
  const [tableData, setTableData] = useState<DataSourceItem[]>();
  const [serviceClipboard, setServiceClipboard] = useState<AdminTags[]>([]);
  const [specialtyClipboard, setSpecialtyClipboard] = useState<AdminTags[]>([]);
  const [isShowingOnlyEmptyRows, setIsShowingOnlyEmptyRows] =
    useState<boolean>(false);
  const [filledRows, setFilledRows] = useState<number>(0);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 25,
  });
  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const initialFetch = async () => {
    await dispatch(fetchTags({ api }));

    // Fetch goal tags initially
    await dispatch(
      fetchChildTags({
        api: newApi,
        parentIds: [],
        locale: selectedLocale,
        type: TagType.Goal,
      }),
    );

    await dispatch(
      fetchAllGrantRfpTags({ api: newApi, locale: selectedLocale }),
    );
  };

  useEffect(() => {
    initialFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch child tags dynamically based on selected parent tags
  useEffect(() => {
    const parentIds = Object.values(goalTagsOptions.entities).map(
      (goal) => goal.tag_id,
    );
    if (parentIds.length > 0) {
      dispatch(
        fetchChildTags({
          api: newApi,
          parentIds,
          locale: selectedLocale,
          type: TagType.ExpenseCategory,
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalTagsOptions, locale]);

  useEffect(() => {
    const parentIds = Object.values(expenseCategoryTagsOptions.entities).map(
      (category) => category.tag_id,
    );
    if (parentIds.length > 0) {
      dispatch(
        fetchChildTags({
          api: newApi,
          parentIds,
          locale: selectedLocale,
          type: TagType.Expense,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseCategoryTagsOptions, locale]);

  const handleTagChange = (
    key: string,
    field: string,
    selectedTags: AdminTags[],
  ) => {
    setTableData((prevData) =>
      prevData!.map((item) =>
        item.key === key ? { ...item, [field]: selectedTags } : item,
      ),
    );

    const existingTagsForExpense = Object.values(grantRfpTags)
      .filter((tag) => tag.expense_tag_id === key)
      .flatMap((tag) => tag.tag);

    const newTags = selectedTags.filter(
      (tag) => !existingTagsForExpense.includes(tag),
    );

    const newGrantRfpTags: GrantRfpTag[] = newTags.map((tag) => ({
      tag,
      expense_tag_id: key,
    }));

    const removedTags = existingTagsForExpense.filter(
      (tag) => !selectedTags.includes(tag),
    );

    const removedGrantRfpTags: GrantRfpTag[] = removedTags.map((tag) => ({
      tag,
      expense_tag_id: key,
    }));

    const isService = field === 'serviceTags';
    const isSpecialty = field === 'specialtyTags';

    setAddedGrantRfpTags((prev) => [
      ...prev.filter((tag) => {
        const tokens = tag.tag.toString().split(':');
        const category = tokens[2];

        if (isService && category === 'services') {
          return !selectedTags.includes(tag.tag) || tag.expense_tag_id !== key;
        }
        if (isSpecialty && category === 'specialties') {
          return !selectedTags.includes(tag.tag) || tag.expense_tag_id !== key;
        }

        return true;
      }),
      ...newGrantRfpTags,
    ]);

    setDeletedGrantRfpTags((prev) => [
      ...prev.filter((tag) => {
        const tokens = tag.tag.toString().split(':');
        const category = tokens[2];

        if (isService && category === 'services') {
          return selectedTags.includes(tag.tag) || tag.expense_tag_id !== key;
        }
        if (isSpecialty && category === 'specialties') {
          return selectedTags.includes(tag.tag) || tag.expense_tag_id !== key;
        }

        return true;
      }),
      ...removedGrantRfpTags.filter((tag) => {
        const tokens = tag.tag.toString().split(':');
        const category = tokens[2];

        return (
          (isService && category === 'services') ||
          (isSpecialty && category === 'specialties')
        );
      }),
    ]);
  };

  const handleCopyRow = (
    serviceTags: AdminTags[],
    specialtyTags: AdminTags[],
  ) => {
    const numberOfTags = serviceTags.length + specialtyTags.length;
    setServiceClipboard(serviceTags);
    setSpecialtyClipboard(specialtyTags);
    message.success(`Copied ${numberOfTags} tags`);
  };

  const handlePasteRow = (expenseTagId: string) => {
    handleTagChange(expenseTagId, 'serviceTags', serviceClipboard);
    handleTagChange(expenseTagId, 'specialtyTags', specialtyClipboard);
  };

  const handleDeleteRow = (expenseTagId: string) => {
    handleTagChange(expenseTagId, 'serviceTags', []);
    handleTagChange(expenseTagId, 'specialtyTags', []);
  };

  const columns = [
    {
      title: t('Goals'),
      dataIndex: 'goal',
      key: 'goal',
      sorter: (a: DataSourceItem, b: DataSourceItem) =>
        a.goal.localeCompare(b.goal),
    },
    {
      title: t('Expense Categories'),
      dataIndex: 'expenseCategory',
      key: 'expenseCategory',
      sorter: (a: DataSourceItem, b: DataSourceItem) =>
        a.expenseCategory.localeCompare(b.expenseCategory),
    },
    {
      title: t('Expenses'),
      dataIndex: 'expense',
      key: 'expense',
      sorter: (a: DataSourceItem, b: DataSourceItem) =>
        a.expense.localeCompare(b.expense),
    },
    {
      title: t('Services'),
      dataIndex: 'serviceTags',
      key: 'serviceTags',
      width: 350,
      render: (_: unknown, record: DataSourceItem) => (
        <Select
          placeholder={t('portfolio|form.placeholder.service')}
          showSearch
          notFoundContent={null}
          style={{ width: '100%' }}
          mode="multiple"
          value={record.serviceTags}
          onChange={(selectedTags) =>
            handleTagChange(record.key, 'serviceTags', selectedTags)
          }
        >
          {React.Children.toArray(
            tags?.categories?.map((category, index) => (
              <Select.OptGroup key={index} label={t(`tags|${category?.label}`)}>
                {React.Children.toArray(
                  category?.services?.map((service) => {
                    return (
                      <Select.Option key={service?.tag} value={service?.tag}>
                        {t(`tags|${service?.label}`)}
                      </Select.Option>
                    );
                  }),
                )}
              </Select.OptGroup>
            )),
          )}
        </Select>
      ),
    },
    {
      title: t('Specialties'),
      dataIndex: 'specialtyTags',
      key: 'specialtyTags',
      width: 350,
      render: (_: unknown, record: DataSourceItem) => (
        <Select
          placeholder={t('portfolio|form.placeholder.specialties')}
          showSearch
          notFoundContent={null}
          style={{ width: '100%' }}
          mode="multiple"
          value={record.specialtyTags}
          onChange={(selectedTags) =>
            handleTagChange(record.key, 'specialtyTags', selectedTags)
          }
        >
          {React.Children.toArray(
            tags?.categories?.map((category, index) => (
              <Select.OptGroup key={index} label={t(`tags|${category?.label}`)}>
                {React.Children.toArray(
                  category?.specialties?.map((specialty) => {
                    return (
                      <Select.Option
                        key={specialty?.tag}
                        value={specialty?.tag}
                      >
                        {t(`tags|${specialty?.label}`)}
                      </Select.Option>
                    );
                  }),
                )}
              </Select.OptGroup>
            )),
          )}
        </Select>
      ),
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_: unknown, record: DataSourceItem) => (
        <Div flex="row" gap={8}>
          <Tooltip title="Copier les RFP tags de cette rangée">
            <Button
              size="middle"
              disabled={
                record.serviceTags.length === 0 &&
                record.specialtyTags.length === 0
              }
              onClick={() => {
                handleCopyRow(record.serviceTags, record.specialtyTags);
              }}
            >
              <Copy size={24} />
            </Button>
          </Tooltip>
          <Tooltip title="Coller les RFP tags de cette rangée">
            <Button
              size="middle"
              disabled={
                serviceClipboard.length === 0 && specialtyClipboard.length === 0
              }
              onClick={() => {
                handlePasteRow(record.key);
              }}
            >
              <Design size={24} />
            </Button>
          </Tooltip>
          <Tooltip title="Retirer les RFP tags de cette rangée">
            <Button
              size="middle"
              danger
              disabled={
                record.serviceTags.length === 0 &&
                record.specialtyTags.length === 0
              }
              onClick={() => {
                handleDeleteRow(record.key);
              }}
            >
              <Delete size={24} color={theme.colors.red_1} />
            </Button>
          </Tooltip>
        </Div>
      ),
    },
  ];

  const handleTableData = (onlyEmptyRows: boolean) => {
    if (!!expenseCategoryTagsOptions && !!grantRfpTags) {
      const filteredExpenseTags =
        searchTerm === ''
          ? Object.values(expenseTagsOptions.entities)
          : Object.values(expenseTagsOptions.entities).filter((expense) => {
              const category =
                expenseCategoryTagsOptions.entities[expense.parent_id];
              return (
                category?.content.toLowerCase() === searchTerm.toLowerCase()
              );
            });

      var dataSource = filteredExpenseTags.map((expense) => {
        const category = expenseCategoryTagsOptions.entities[expense.parent_id];
        const goal = goalTagsOptions.entities[category?.parent_id];

        const grantTagEntries = Object.values(grantRfpTags)
          .filter((tag) => tag.expense_tag_id === expense.tag_id)
          .map((entry) => entry.tag);

        return {
          key: expense.tag_id,
          expense: expense.content,
          expenseCategory: category ? category.content : '-',
          goal: goal ? goal.content : '-',
          serviceTags: grantTagEntries.filter((tag) => {
            if (!!!tag) return false;
            var tokens: string[] = tag.toString().split(':');
            return tokens[2] === 'services';
          }),
          specialtyTags: grantTagEntries.filter((tag) => {
            if (!!!tag) return false;
            var tokens: string[] = tag.toString().split(':');
            return tokens[2] === 'specialties';
          }),
        };
      });

      if (onlyEmptyRows) {
        dataSource = dataSource.filter(
          (row) =>
            row.serviceTags.length === 0 && row.specialtyTags.length === 0,
        );
      }

      setTableData(dataSource);
    }
  };

  useEffect(() => {
    handleTableData(isShowingOnlyEmptyRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseTagsOptions, grantRfpTags, searchTerm]);

  useEffect(() => {
    if (!!expenseTagsOptions) {
      setTotalRows(Object.values(expenseTagsOptions.entities).length);
    }
  }, [expenseTagsOptions]);

  useEffect(() => {
    if (!!grantRfpTags) {
      const uniqueExpenseTagIds = new Set(
        Object.values(grantRfpTags).map((tag) => tag.expense_tag_id),
      );

      setFilledRows(uniqueExpenseTagIds.size);
    }
  }, [grantRfpTags]);

  return (
    <Div flex="column" style={{ marginTop: 24 }} gap={24}>
      <Div flex="row" gap={40} justify="end" align="center">
        <Typography elementTheme="body2">{`${filledRows} / ${totalRows} Matched`}</Typography>
        <Button
          onClick={() => {
            setIsShowingOnlyEmptyRows(true);
            handleTableData(true);
          }}
          size="large"
          type="primary"
          color={'primary'}
        >
          Only keep empty rows
        </Button>
        <Button
          onClick={() => {
            setIsShowingOnlyEmptyRows(false);
            handleTableData(false);
          }}
          size="large"
          type="primary"
          color={'primary'}
        >
          See all rows
        </Button>
      </Div>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['25', '35', '50', '100'],
        }}
        onChange={handleTableChange}
      />
    </Div>
  );
};

export default MatchmakingList;
