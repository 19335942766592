import { jsx as _jsx } from "react/jsx-runtime";
import GridViewFill from '@hellodarwin/icons/dist/icons/GridViewFill';
import LineViewFill from '@hellodarwin/icons/dist/icons/LineViewFill';
import { useMemo } from 'react';
import { RowSelectorButton, RowSelectorContainer } from './styles';
const RowSelector = ({ isRow, setIsRow }) => {
    const items = useMemo(() => [
        { Icon: GridViewFill, isActive: !isRow, onClick: () => setIsRow(false) },
        { Icon: LineViewFill, isActive: isRow, onClick: () => setIsRow(true) },
    ], [isRow]);
    return (_jsx(RowSelectorContainer, { children: items.map(({ Icon, isActive, onClick }, index) => (_jsx(RowSelectorButton, { "$isActive": isActive, onClick: onClick, children: _jsx(Icon, { size: 18 }) }, index))) }));
};
export default RowSelector;
