import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Popconfirm from 'antd/es/popconfirm';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import FormItemEditButton from './form-builder-item-edit-button';
const FormBuilderItemActions = ({ formItemId, itemEntity, pages, functions, }) => {
    const theme = useTheme();
    const handleFormItemDelete = () => {
        functions.deleteFormItem(formItemId);
    };
    return (_jsxs(Div, { flex: "column", gap: 2, fitContent: true, children: [_jsx(FormItemEditButton, { itemEntity: itemEntity, functions: functions, pages: pages }), _jsx(Popconfirm, { title: "Delete the form Page", description: "Are you sure to delete this item ?", onConfirm: handleFormItemDelete, onCancel: () => { }, okText: "Yes", cancelText: "No", children: _jsx("div", { children: _jsx(Button, { size: "square", defaultStyle: theme.colors.purple_3, trailingIcon: _jsx(Delete, { width: 12, height: 12 }) }) }) })] }));
};
export default FormBuilderItemActions;
