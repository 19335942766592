export var ProviderStatus;
(function (ProviderStatus) {
    ProviderStatus["Unverified"] = "Unverified";
    ProviderStatus["Verified"] = "Verified";
    ProviderStatus["Rejected"] = "Rejected";
    ProviderStatus["Suspended"] = "Suspended";
    ProviderStatus["Canceled"] = "Canceled";
})(ProviderStatus || (ProviderStatus = {}));
export var QueriedProvidersType;
(function (QueriedProvidersType) {
    QueriedProvidersType["Shortlisted"] = "shortlisted";
    QueriedProvidersType["Raisedhand"] = "raisedhand";
    QueriedProvidersType["Matched"] = "matched";
    QueriedProvidersType["All"] = "all";
})(QueriedProvidersType || (QueriedProvidersType = {}));
export const ProviderInitialState = {
    provider_id: '',
    rfp_total_value: 0,
    rfp_bought_count: 0,
    reviews_average: 0,
    reviews_count: 0,
    tags: {},
    name: '',
    verified_at: '',
    company_id: '',
};
export const InitialTeamMember = {
    profile_picture: '',
    provider_id: '',
    name: '',
    role: '',
    linked_in: '',
    provider_team_member_id: '',
};
