export const CORE_NAMESPACES = [
    'common',
    'errors',
    'reviews',
    'provider-profile',
    'profile',
    'team',
    'portfolio',
    'tags',
    'lang',
    'confirm-modal',
    'project-single',
    'programs',
    'grants',
    'assets',
];
