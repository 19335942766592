import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dollarFormat from '../../../../../features/helpers/dollar-format';
import useMediaQuery from '../../../../../features/helpers/use-media-query';
import useLocale from '../../../../../features/providers/locale-provider';
import { useTheme } from '../../../../../plugins/styled';
import Typography from '../../../../common/typography';
import { GrantProjectCardContainer, GrantProjectCardContent, GrantProjectCardHeader, } from './styles';
const TWO_CARD_ROWS_CUTOFF = 922;
const ONE_CARD_ROWS_CUTOFF = 626;
const GrantProjectCard = ({ project, open, handleOpen, }) => {
    const { selectedLocale } = useLocale();
    const theme = useTheme();
    const isTwoCardRows = useMediaQuery({
        maxWidth: `${TWO_CARD_ROWS_CUTOFF}px`,
    });
    const isOneCardRows = useMediaQuery({
        maxWidth: `${ONE_CARD_ROWS_CUTOFF}px`,
    });
    return (_jsxs(GrantProjectCardContainer, { onClick: handleOpen, style: { cursor: 'pointer' }, "$open": open, "$isTwoCardRows": isTwoCardRows, "$isOneCardRows": isOneCardRows, children: [_jsx(GrantProjectCardHeader, { children: _jsx(Typography.Title, { level: 3, textAlign: 'center', elementTheme: "subtitle1", lineClamp: 3, color: theme.colors.purple_1, children: dollarFormat(project.amount_funded, selectedLocale, true) }) }), _jsx(GrantProjectCardContent, { elementTheme: "body2", textAlign: 'center', lineClamp: !open ? 4 : 12, children: project.short_description })] }));
};
export default GrantProjectCard;
