import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Gin = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1855 21.9297C11.6606 22.0195 10.9825 22.0645 10.392 21.8039C10.0092 21.6332 9.68114 21.3547 9.67021 20.8426C9.64834 19.5038 11.8137 18.3179 13.0276 18.0933C13.629 17.9854 14.1102 17.9765 14.4711 18.1292C14.7664 18.255 14.9414 18.4077 15.0288 18.5694C15.1273 18.7402 15.1054 18.9109 14.9523 19.1085C14.8211 19.2792 14.657 19.5218 14.5367 19.7105C14.5039 19.7644 14.4274 19.8003 14.3071 19.7464C14.0555 19.6476 13.8915 19.4679 14.0227 19.1534C14.1649 18.839 14.5586 18.4976 14.318 18.3538C13.979 18.1472 12.2292 18.6862 11.2012 19.5847C10.2061 20.4652 10.2389 21.4176 10.6654 21.6422C11.2559 21.9566 12.5136 21.4894 13.1697 20.7437C12.8307 20.7797 12.4808 20.8156 12.2292 20.8336C11.6934 20.8695 11.3653 20.7347 11.2012 20.5371C11.1466 20.4742 11.1794 20.4382 11.3106 20.4382C12.1527 20.4382 12.9619 20.2765 13.6181 20.2675C14.154 20.2675 14.4602 20.4023 14.5914 20.582C14.6461 20.6539 14.6242 20.6898 14.482 20.6808C14.2743 20.6718 14.0118 20.6808 13.7275 20.6898C14.0009 21.2738 12.6995 21.8309 12.1636 21.9207L12.1855 21.9297Z"
        fill="currentColor"
      />
      <path
        d="M17.8394 18.0663C17.9925 18.246 17.9816 18.5425 17.5223 19.0187C16.8552 19.6925 15.6741 20.8965 14.8976 21.9207C14.8539 21.9836 14.8101 21.9926 14.7226 21.9297C14.5695 21.8398 14.4492 21.6152 14.6789 21.2379C15.2038 20.3933 16.713 18.7851 17.5879 18.0573C17.6863 17.9765 17.7847 17.9854 17.8503 18.0573L17.8394 18.0663Z"
        fill="currentColor"
      />
      <path
        d="M16.7349 21.9297C16.7021 21.9926 16.6255 22.0016 16.549 21.9566C16.363 21.8398 16.2756 21.5973 16.4943 21.2558C17.0192 20.4203 18.4299 18.9378 19.272 18.1112C19.3704 18.0214 19.447 18.0124 19.5454 18.0573C19.9391 18.228 20.0266 18.3987 19.9063 18.83C19.7313 19.423 19.6329 20.1957 19.5564 21.0672C20.2891 20.1058 21.5248 18.812 22.3232 18.0843C22.4106 18.0124 22.5091 18.0304 22.5747 18.0933C22.7387 18.2819 22.7278 18.5245 22.2466 18.9917C21.6123 19.6027 20.2453 21.0941 19.622 21.9207C19.5782 21.9836 19.5235 21.9926 19.4032 21.9566C19.0861 21.8578 18.933 21.7051 18.8783 21.3098C18.8236 20.9324 18.8783 20.0878 19.0642 19.2074C18.255 20.007 17.1614 21.166 16.7349 21.9117V21.9297Z"
        fill="currentColor"
      />
      <path
        d="M7.77002 15.505L24.23 15.395M7.77002 24.815L24.23 24.705M12.98 4.88498H18.79C19.34 4.88498 19.79 5.33498 19.79 5.88498V9.01498H11.98V5.88498C11.98 5.33498 12.43 4.88498 12.98 4.88498ZM11.68 1.47498H20.04C20.5923 1.47498 21.04 1.92269 21.04 2.47498V3.88498C21.04 4.43726 20.5923 4.88498 20.04 4.88498H11.68C11.1277 4.88498 10.68 4.43726 10.68 3.88498V2.47498C10.68 1.92269 11.1277 1.47498 11.68 1.47498ZM11.77 9.01501H20.23C22.44 9.01501 24.23 10.805 24.23 13.015V28.525C24.23 29.625 23.33 30.525 22.23 30.525H9.77002C8.67002 30.525 7.77002 29.625 7.77002 28.525V13.015C7.77002 10.805 9.56002 9.01501 11.77 9.01501Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default Gin;
