import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import Save from '@hellodarwin/icons/dist/icons/Save';
import Unlocked from '@hellodarwin/icons/dist/icons/Unlocked';
import Drawer from 'antd/es/drawer';
import { useForm } from 'antd/es/form/Form';
import Popconfirm from 'antd/es/popconfirm';
import React, { useEffect, useState } from 'react';
import { isObject } from '../../../../../../features/helpers/arrays-equal';
import { useTranslations } from '../../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../../plugins/styled';
import Button from '../../../../../common/button';
import Div from '../../../../../common/div';
import DragDropSingleColumnContainer from '../../../../../common/drag-drop/single';
import Typography from '../../../../../common/typography';
import defaultRenderForm from './default-render-form';
import { ButtonGroup, FormEditListItem } from './styles';
const FormItemEditList = ({ options, optionLabel, optionTitleLabel, inputs, typeLabel, reorder, update, renderLabel, renderTitleLabel, activeElement, deleteItem, setActiveElement, renderForm, drawerWidth, canEditItem, unlinkItem, isLoading, isEditButtonVisible = false, }) => {
    const theme = useTheme();
    const { t } = useTranslations();
    const [form] = useForm();
    const [activeItem, setActiveItem] = useState(activeElement);
    const closeDrawer = () => {
        !!setActiveElement ? setActiveElement(undefined) : setActiveItem(undefined);
    };
    const openDrawer = (item) => !!setActiveElement ? setActiveElement(item) : setActiveItem(item);
    useEffect(() => {
        setActiveItem(activeElement);
    }, [activeElement]);
    if (!options)
        return _jsx(_Fragment, {});
    const getLabel = (option, isTitle, index) => {
        return !!renderTitleLabel && isTitle
            ? renderTitleLabel(option[optionTitleLabel !== null && optionTitleLabel !== void 0 ? optionTitleLabel : optionLabel])
            : !!renderLabel
                ? renderLabel(option[optionLabel], index, option)
                : option[optionLabel];
    };
    const handleUpdateOption = () => {
        form
            .validateFields()
            .then((values) => {
            const editedValues = Object.assign(Object.assign({}, activeItem), values);
            !!update && update(editedValues);
        })
            .catch((e) => console.error(e));
    };
    const renderItem = (option, index) => (_jsxs(FormEditListItem, { onClick: () => {
            if (canEditItem)
                openDrawer(option);
        }, children: [_jsx(_Fragment, { children: getLabel(option, false, index) }), _jsxs(ButtonGroup, { children: [canEditItem && isEditButtonVisible && (_jsx(Button, { trailingIcon: _jsx(Edit, { size: 12 }), size: "circle", onClick: (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            openDrawer(option);
                        }, defaultStyle: theme.colors.primary })), !!unlinkItem && (_jsx(Popconfirm, { title: t('companyForm|dissociate'), description: t('companyForm|dissociate_long'), onConfirm: (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            unlinkItem(option);
                        }, okText: t('companyForm|dissociate'), cancelText: t('gin_section|no'), okButtonProps: {
                            style: {
                                backgroundColor: theme.colors.purple_1,
                                borderColor: theme.colors.purple_1,
                            },
                        }, children: _jsx(Button, { trailingIcon: _jsx(Unlocked, { width: 12, height: 12 }), size: "circle", defaultStyle: theme.colors.primary }) })), !!deleteItem && (_jsx(Popconfirm, { title: t('gin_section|delete_short'), description: t('gin_section|delete_long'), onConfirm: (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            deleteItem(option);
                        }, okText: t('gin_section|delete_short'), cancelText: t('gin_section|no'), okButtonProps: {
                            style: {
                                backgroundColor: theme.colors.purple_1,
                                borderColor: theme.colors.purple_1,
                            },
                        }, children: _jsx(Button, { trailingIcon: _jsx(Delete, { width: 12, height: 12 }), size: "circle", defaultStyle: theme.colors.primary }) }))] })] }));
    useEffect(() => {
        if (!!activeItem && isObject(activeItem)) {
            form.resetFields();
            Object.entries(activeItem).forEach(([key, value]) => {
                form.setFieldValue(key, value);
            });
        }
    }, [activeItem]);
    const renderFormFunc = !!renderForm ? renderForm : defaultRenderForm;
    return (_jsxs(Div, { flex: "column", gap: 8, style: {
            pointerEvents: isLoading ? 'none' : 'auto',
            opacity: isLoading ? 0.5 : 1,
        }, children: [!!reorder ? (_jsx(DragDropSingleColumnContainer, { setItems: reorder, items: options, direction: "vertical", itemStyle: { padding: 0, marginBottom: 8, width: '100%' }, renderItem: renderItem })) : (React.Children.toArray(options.map(renderItem))), _jsx(Drawer, { open: !!activeItem, onClose: closeDrawer, title: !!activeItem && (_jsxs(Div, { align: "center", flex: "row", style: { height: '100%' }, justify: "space-between", children: [_jsxs(Div, { fitContent: true, flex: "row", gap: 8, align: "center", children: [typeLabel && (_jsxs(Typography, { elementTheme: "subtitle1", children: [typeLabel, ":"] })), _jsx(Typography, { elementTheme: "subtitle1", children: getLabel(activeItem, true) })] }), ' ', !!update && (_jsx(Button, { size: "circle", defaultStyle: theme.colors.white_1, onClick: handleUpdateOption, children: _jsx(Save, { size: 16 }) }))] })), width: drawerWidth, children: !!activeItem &&
                    renderFormFunc(inputs, form, activeItem, deleteItem) })] }));
};
export default FormItemEditList;
