const selectInputEmail = (e) => {
    if (e.detail > 1) {
        e.preventDefault();
        if (e.target) {
            const { value, selectionStart } = e.target;
            const emailParts = value.split('@');
            if (selectionStart < emailParts[0].length) {
                e.target.setSelectionRange(0, emailParts[0].length);
            }
            else {
                e.target.setSelectionRange(emailParts[0].length + 1, value.length);
            }
        }
    }
};
export default selectInputEmail;
