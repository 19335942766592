import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import SidebarPageLayout, {
  OnSidebarChangeFunc,
} from '@hellodarwin/core/lib/components/common/layout/sidebar-page-layout.tsx';
import Loading from '@hellodarwin/core/lib/components/loading';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import theme from '@hellodarwin/core/lib/theme';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import HubspotLink from '@hellodarwin/icons/dist/icons/HubspotLink';
import Key from '@hellodarwin/icons/dist/icons/Key';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import Clipboard from '../../components/clipboard';
import CompanySingle from '../../components/companies/single';
import CompanyAdminsList from '../../components/companies/single/admins-list';
import CompanyApplicationsList from '../../components/companies/single/applications-list';
import CompanyFormsSubmissionsList from '../../components/companies/single/form-submissions-list';
import CompanyRelatedLinks from '../../components/companies/single/related-links';
import CompanyRFPList from '../../components/companies/single/rfp-list';
import { CompanySinglePageSections } from '../../components/companies/single/single-company-header/use-company-blocks';
import CompanyUsersList from '../../components/companies/single/users-list';
import {
  selectCompanyById,
  toggleCompanyModal,
} from '../../features/api/slices/companies-slice';
import { useAdminSettings } from '../../features/providers/admin-settings-provider';
import AddAccountManagerButton from './add-new-account-manager-button';
import AddNewUserButton from './add-new-user-button';

const SingleCompanyPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslations();
  const params = useParams<{
    id: string;
  }>();

  const company = useAppSelector((state) =>
    selectCompanyById(state, params.id || ''),
  );

  const { getSetting, updateSetting } = useAdminSettings();
  const sidebarSetting = getSetting('companySidebarsCollapsed');

  const onSidebarChange: OnSidebarChangeFunc = (side, value) => {
    updateSetting('companySidebarsCollapsed', {
      ...sidebarSetting,
      [side]: value,
    });
  };

  if (!params.id) return <Loading />;

  const handleEditCompany = () => {
    dispatch(
      toggleCompanyModal({
        open: true,
        type: 'update',
        company_id: company.company_id,
      }),
    );
  };

  return (
    <SidebarPageLayout
      app="admin"
      tabTitle={company?.name}
      title={company?.name}
      handleBack={() => navigate('/companies')}
      actions={
        <Div flex="row" fitContent gap={8}>
          <Clipboard
            copy={company?.company_id}
            tooltip="Copy Id to clipboard"
            title={
              <Button
                size="square"
                defaultStyle={theme.colors.white_1}
                headingIcon={<Key size={20} />}
              />
            }
          />

          {!!company?.hubspot_url && (
            <a href={company.hubspot_url} target="_blank" rel="noreferrer">
              <Button
                size="square"
                defaultStyle={theme.colors.white_1}
                headingIcon={<HubspotLink size={20} />}
              />
            </a>
          )}
          <Button
            size="square"
            defaultStyle={theme.colors.white_1}
            headingIcon={<Edit size={20} />}
            onClick={handleEditCompany}
          />
        </Div>
      }
      leftSidebarBlocks={[
        {
          title: t('singleCompanyPage|users'),
          content: <CompanyUsersList company_id={params.id} />,
          actions: !!params.id && <AddNewUserButton companyId={params.id} />,
        },
        {
          title: t('singleCompanyPage|accountManager'),
          content: <CompanyAdminsList company_id={params.id} />,
          actions: !!params.id && (
            <AddAccountManagerButton companyId={params.id} />
          ),
        },
        {
          title: t('singleCompanyPage|relatedLinks'),
          content: <CompanyRelatedLinks company_id={params.id} />,
        },
      ]}
      rightSidebarBlocks={[
        {
          title: t('singleCompanyPage|applications'),
          content: <CompanyApplicationsList company_id={params.id} />,
        },
        {
          title: t('singleCompanyPage|rfp'),
          content: <CompanyRFPList company_id={params.id} />,
          defaultClosed: true,
        },
        {
          title: t('singleCompanyPage|formsSubmissions'),
          content: <CompanyFormsSubmissionsList company_id={params.id} />,
          defaultClosed: true,
        },
      ]}
      noFooter
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: t('companyAdminPage|companies'),
          path: '/companies',
        },
        {
          breadcrumbName: company?.name,
        },
      ]}
      defaultLeftSidebarCollapsed={sidebarSetting.left}
      defaultRightSidebarCollapsed={sidebarSetting.right}
      onSidebarChange={onSidebarChange}
    >
      <CompanySingle
        company_id={params.id}
        sections={[
          CompanySinglePageSections.BusinessProfile,
          CompanySinglePageSections.GamePlans,
          CompanySinglePageSections.Priorities,
          CompanySinglePageSections.Grants,
          CompanySinglePageSections.GamePlanSummary,
          CompanySinglePageSections.Files,
          CompanySinglePageSections.Comments,
        ]}
      />
    </SidebarPageLayout>
  );
};

export default SingleCompanyPage;
