import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import SingleHeaderMenu from '@hellodarwin/core/lib/components/common/single-header-menu';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import ProfileLogo from '@hellodarwin/core/lib/components/provider/profile/profile-assets/profile-logo';
import getShortId from '@hellodarwin/core/lib/features/helpers/get-short-id';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import createBorderStyle from '@hellodarwin/core/lib/theme/utils/create-border-style';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import { useAppSelector } from '../../../../app';
import {
  selectCompanyById,
  selectCompanyHasProvider,
  selectCompanyProvider,
} from '../../../../features/api/slices/companies-slice';
import { CompanySinglePageSections } from './use-company-blocks';

interface SingleCompanyHeaderProps {
  company_id?: string;
  sections: CompanySinglePageSections[];
}

const SingleCompanyHeader: React.FC<SingleCompanyHeaderProps> = ({
  company_id,
  sections,
}) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const company = useAppSelector((state) =>
    selectCompanyById(state, company_id ?? ''),
  );
  const provider = useAppSelector(selectCompanyProvider);
  const hasProvider = useAppSelector(selectCompanyHasProvider);

  const handleGoToProvider = () => {
    window.open(`/providers/${provider?.provider_id}`, '_blank');
  };

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          width: '100%',
          padding: '24px 32px',
          borderRadius: '8px 8px 0 0',
          background: theme.colors.white_1,
          ...createBorderStyle({ color: theme.colors.grey_1 }),
          borderBottom: 'none',
        }}
      >
        <Div flex="row" gap={32} align="center">
          <Div flex="row" gap={32} align="center">
            <ProfileLogo
              logo={company.logo ?? ''}
              name={company.name}
              type="client"
            />

            <div>
              <Typography
                elementTheme="overline"
                color={theme.colors.purple_2}
                copyable={{ text: company.company_id }}
              >
                #{getShortId(company.company_id)}
              </Typography>
              <Typography elementTheme="h6">{company.name}</Typography>
            </div>
          </Div>
          {hasProvider && (
            <Button
              onClick={handleGoToProvider}
              size="small"
              defaultStyle={theme.colors.white_1}
              trailingIcon={<NewWindow size={16} />}
            >
              {t('singleCompanyPage|providerPage')}
            </Button>
          )}
        </Div>
      </div>
      <div
        style={{
          width: '100%',
          ...createBorderStyle({ color: theme.colors.grey_1, side: 'all' }),
          borderTop: 'none',
          position: 'sticky',
          top: theme.appearance.appHeaderHeight,
          zIndex: 10,
        }}
      >
        <SingleHeaderMenu
          items={sections.map((s) => ({
            label: t(`singleCompanyPage|${s}`),
            id: s,
          }))}
        />
      </div>
    </div>
  );
};

export default SingleCompanyHeader;
