import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import chunkArray from '../../../../../features/helpers/chunk-array';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../plugins/styled';
import Collapse from '../../../../common/Collapse';
import DynamicScroller from '../../../../common/dynamic-scroller';
import Typography from '../../../../common/typography';
import { FilterSectionContainterStyled } from '../styles';
import FilterProjectTag from './filter-project-tag';
import { FilterProjectContent } from './styles';
import { useAppData } from '../../../../../features/providers/app-data-provider';
const FilterProjects = ({ bestProjects = [], filterValues, filterform, isLoading, style, }) => {
    const theme = useTheme();
    const { t } = useTranslations();
    const { navCollapsed } = useAppData();
    const [prevBubbleRows, setPrevBubbleRows] = useState([]);
    const bubbleRows = useMemo(() => chunkArray(bestProjects, Math.ceil((bestProjects === null || bestProjects === void 0 ? void 0 : bestProjects.length) / 2)), [bestProjects]);
    const [isRowsLoading, setIsRowsLoading] = useState(isLoading);
    useEffect(() => {
        if (isLoading) {
            setIsRowsLoading(true);
        }
    }, [isLoading]);
    useEffect(() => {
        if (!isEqual(bubbleRows, prevBubbleRows)) {
            setPrevBubbleRows(bubbleRows);
            setTimeout(() => setIsRowsLoading(false), 300);
        }
    }, [bubbleRows, isLoading]);
    const checkProjectIsActive = useCallback((rfpId) => {
        var _a;
        return !!((_a = filterValues === null || filterValues === void 0 ? void 0 : filterValues.selectedProjects) === null || _a === void 0 ? void 0 : _a.find((sp) => sp === rfpId));
    }, [filterValues === null || filterValues === void 0 ? void 0 : filterValues.selectedProjects]);
    const handleProjectSelection = (rfpId) => {
        var _a, _b, _c;
        const newSelectedProjects = ((_a = filterValues === null || filterValues === void 0 ? void 0 : filterValues.selectedProjects) === null || _a === void 0 ? void 0 : _a.includes(rfpId))
            ? (_b = filterValues === null || filterValues === void 0 ? void 0 : filterValues.selectedProjects) === null || _b === void 0 ? void 0 : _b.filter((id) => rfpId !== id)
            : [...((_c = filterValues === null || filterValues === void 0 ? void 0 : filterValues.selectedProjects) !== null && _c !== void 0 ? _c : []), rfpId];
        filterform.setFieldValue('selectedProjects', newSelectedProjects);
    };
    if (!(prevBubbleRows === null || prevBubbleRows === void 0 ? void 0 : prevBubbleRows.length))
        return _jsx(_Fragment, {});
    return (_jsx(FilterSectionContainterStyled, { "$position": "bottom", "$navCollapsed": navCollapsed, style: Object.assign({ background: theme.colors.white_1, borderTop: `1px solid ${theme.colors.grey_4}` }, style), children: _jsx(Collapse, { ghost: true, noPadding: true, defaultActiveKey: 'proposed-projects', ExpandIcon: ({ isActive }) => (_jsx(TabArrow, { down: !isActive, color: theme.colors.primary, style: { cursor: 'pointer' }, size: 20 })), styles: {
                panelHeader: { padding: '8px 30px' },
                panelContainer: { width: '100%' },
            }, items: [
                {
                    title: (_jsx(Typography, { elementTheme: "overline", children: t(`grantsFundingExplorer|grantsfundingExplorerFilterProposedProjects`) })),
                    children: (_jsx("div", { style: { height: 72 }, children: _jsx(FilterProjectContent, { className: isLoading || isRowsLoading ? 'fade-out' : '', children: React.Children.toArray(prevBubbleRows.map((row, i) => (_jsx(DynamicScroller, { speed: 150, inversed: i % 2 === 0, children: React.Children.toArray(row.map((project) => (_jsx(FilterProjectTag, { project: project, isActive: checkProjectIsActive(project.grant_project_id), handleProjectSelection: handleProjectSelection })))) })))) }) })),
                    id: 'proposed-projects',
                },
            ] }) }));
};
export default FilterProjects;
