import isColorDark from '../../../features/helpers/is-color-dark';
import styled from '../../../plugins/styled';
export const MenuContainer = styled.div ``;
export const MenuContent = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  position: relative;
  overflow: auto hidden;
  border-top: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => isColorDark(theme.colors.beige_1)
    ? theme.colors.white_1
    : theme.colors.primary};
  &::-webkit-scrollbar {
    display: none;
  }

  & > a {
    flex: 1;
    text-align: center;
    border-right: 1px solid ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.beige_1};

    &:last-child {
      border-right: none;
    }
    &:hover {
      background: ${({ theme }) => theme.colors.beige_3};
      color: ${({ theme }) => isColorDark(theme.colors.beige_3)
    ? theme.colors.white_1
    : theme.colors.primary};
    }
  }

  @media (max-width: ${(props) => { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.breakpoints) === null || _b === void 0 ? void 0 : _b.tablet; }}px) {
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 1px;
      height: 0px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: transparent;
      transition: background 400ms ease;
      border-radius: 5px;
      cursor: pointer;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
    & > * {
      padding-left: 10px;
      padding-right: 10px;
      flex-shrink: 0;
      text-wrap: nowrap;
    }
  }
`;
