import { useMemo } from 'react';
import { useTranslations } from '../../../../features/providers/translations-provider';
const useGamePlanTranslations = () => {
    const { t } = useTranslations();
    return useMemo(() => {
        const translations = {
            introTitle: t('gamePlanPdf|gamePlanPdfTitle'),
            introSubtitle: t('gamePlanPdf|gamePlanPdfSubtitle'),
            accountManager: t('gamePlanPdf|accountManager'),
            date: t('gamePlanPdf|date'),
            presentedAt: t('gamePlanPdf|gamePlanPdfPresentedAt'),
            section: t('gamePlanPdf|section'),
            introduction: t('gamePlanPdf|introduction'),
            menuTitle: t('gamePlanPdf|gamePlanPdfMenuTitle'),
            fromMinToMax: t('gamePlanPdf|fromMinToMax'),
            upTo: t('gamePlanPdf|upTo'),
            projectCostPercent: t('gamePlanPdf|projectCostPercent'),
            targetAmountToObtain: t('gamePlanPdf|targetAmountToObtain'),
            keyDates: t('gamePlanPdf|keyDates'),
            eligibilityCriteria: t('gamePlanPdf|eligibilityCriteria'),
            fromDate: t('gamePlanPdf|fromDate'),
            toDate: t('gamePlanPdf|toDate'),
            fromDateToDate: t('gamePlanPdf|fromDateToDate'),
            targetedGrants: t('gamePlanPdf|targetedGrants'),
            associatedGamePlanProjects: t('gamePlanPdf|associatedGamePlanProjects'),
            associatedGamePlanGrants: t('gamePlanPdf|associatedGamePlanGrants'),
            adminCardTitle: t('gamePlanPdf|adminCardTitle'),
            adminCardDescription: t('gamePlanPdf|adminCardDescription'),
            adminCardCta: t('gamePlanPdf|adminCardCta'),
            todoClient: t('gamePlanPdf|todo_client'),
            todoHd: t('gamePlanPdf|todo_hd'),
            summaryTitle: t('gamePlanPdf|summary_title'),
        };
        return translations;
    }, [t]);
};
export default useGamePlanTranslations;
