export const handleReorderTeamMembers = ({ team_member_id, item_order, members, functions, setTeamMembers, }) => {
    const movingItemId = members.findIndex((item) => item.provider_team_member_id === team_member_id);
    const [removedItem] = members.splice(movingItemId, 1);
    if (!!item_order) {
        members.splice(movingItemId + item_order, 0, removedItem);
    }
    const teamMemberItems = [];
    members.forEach((item, index) => {
        teamMemberItems.push({
            provider_team_member_id: item.provider_team_member_id,
            order_team: index,
        });
    });
    const updatedMembers = members.map((member, index) => member.provider_team_member_id === team_member_id
        ? Object.assign(Object.assign({}, member), { order_team: index }) : member);
    !!(functions === null || functions === void 0 ? void 0 : functions.updateTeamMemberOrder) &&
        functions.updateTeamMemberOrder({ teamMembers: teamMemberItems });
    !!setTeamMembers && setTeamMembers(updatedMembers);
};
