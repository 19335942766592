var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Select from 'antd/es/select';
import { useTranslations } from '../../../../features/providers/translations-provider';
import FormItem from '../../form-layouts/form-item';
import FormLabel from '../../form-layouts/form-label';
const genders = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'not_disclosed', label: 'Not Disclosed' },
    { value: 'non-binary', label: 'Non-binary' },
    { value: 'genderqueer', label: 'Genderqueer' },
    { value: 'agender', label: 'Agender' },
    { value: 'bigender', label: 'Bigender' },
    { value: 'genderfluid', label: 'Genderfluid' },
    { value: 'nonconforming', label: 'Gender Nonconforming' },
    { value: 'two-spirit', label: 'Two-Spirit' },
    { value: 'pangender', label: 'Pangender' },
    { value: 'other', label: 'Other' },
];
const GenderSelect = (_a) => {
    var { isRequired } = _a, selectProps = __rest(_a, ["isRequired"]);
    const { t } = useTranslations();
    return (_jsx(FormItem, { label: _jsx(FormLabel, { label: t('admin_settings|gender') }), name: "gender", rules: [
            {
                required: isRequired,
                message: 'Please add a gender for the contact',
            },
        ], children: _jsx(Select, Object.assign({}, selectProps, { placeholder: "Select option", options: genders, defaultValue: 'not_disclosed' })) }));
};
export default GenderSelect;
GenderSelect.Demo = () => {
    return _jsx(GenderSelect, { disabled: true });
};
