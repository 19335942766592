'use client';
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import useMediaQuery from '../../../../features/helpers/use-media-query';
const useTypographyProps = (props) => {
    const theme = useTheme();
    const isXL = useMediaQuery({ maxWidth: theme.breakpoints.l });
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    return useMemo(() => {
        const currentProps = {
            $size: 'body1',
        };
        const currentStyle = {};
        const addProp = (property, value) => {
            if (value !== undefined) {
                currentProps[property] = value;
            }
        };
        const addStyling = (property, value) => {
            if (value !== undefined) {
                currentStyle[property] = value;
            }
        };
        const prepareStyling = (data) => {
            const { style } = data, rest = __rest(data, ["style"]);
            addStyling('textTransform', data === null || data === void 0 ? void 0 : data.textTransform);
            addStyling('textAlign', data === null || data === void 0 ? void 0 : data.textAlign);
            addStyling('letterSpacing', data === null || data === void 0 ? void 0 : data.letterSpacing);
            Object.entries(rest).forEach(([key, value]) => {
                addStyling(key, value);
            });
            if (data.style) {
                Object.entries(data.style).forEach(([key, value]) => {
                    addStyling(key, value);
                });
            }
        };
        const prepareProps = (data) => {
            if (data) {
                const { bold, medium, strike, color, colorHover, underline, elementTheme, center, ellipsis, fitContent, nowrap, inline, font, lineClamp, overflow, normal } = data, rest = __rest(data, ["bold", "medium", "strike", "color", "colorHover", "underline", "elementTheme", "center", "ellipsis", "fitContent", "nowrap", "inline", "font", "lineClamp", "overflow", "normal"]);
                const propMappings = {
                    $bold: bold,
                    $medium: medium,
                    $strike: strike,
                    $color: color,
                    $colorHover: colorHover,
                    $underline: underline,
                    $size: elementTheme,
                    $center: center,
                    $ellipsis: ellipsis,
                    $fitContent: fitContent,
                    $nowrap: nowrap,
                    $inline: inline,
                    $font: font,
                    $lineClamp: lineClamp,
                    $overflow: overflow,
                    $normal: normal,
                };
                Object.entries(propMappings).forEach(([key, value]) => {
                    addProp(key, value);
                });
                prepareStyling(rest);
            }
        };
        const { children, onClick, onBlur, onMouseEnter, onMouseLeave, onPointerEnter, onPointerLeave, id, className, xl, tablet } = props, viewProps = __rest(props, ["children", "onClick", "onBlur", "onMouseEnter", "onMouseLeave", "onPointerEnter", "onPointerLeave", "id", "className", "xl", "tablet"]);
        const defaultProps = {
            children,
            onClick,
            onMouseEnter,
            onMouseLeave,
            onPointerEnter,
            onPointerLeave,
            onBlur,
            id,
            className,
        };
        prepareProps(viewProps);
        if (isXL && !!xl)
            prepareProps(xl);
        if (isTablet && !!tablet)
            prepareProps(tablet);
        return { currentProps, currentStyle, defaultProps };
    }, [isTablet, isXL, props]);
};
export default useTypographyProps;
