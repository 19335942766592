import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import StatusTags from '@hellodarwin/core/lib/components/grants/single/grant-sections/summary/status-tags-list';
import ProfileLogo from '@hellodarwin/core/lib/components/provider/profile/profile-assets/profile-logo';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Gsp from '@hellodarwin/icons/dist/icons/Gsp';
import Invoices from '@hellodarwin/icons/dist/icons/Invoices';
import Tooltip from 'antd/es/tooltip';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../app';
import { selectCurrentGin } from '../../../features/api/slices/gins-slice';

const GinGrantHeaderContent = () => {
  const theme = useTheme();
  const { selectedLocale } = useLocale();
  const { t } = useTranslations();

  const adminGin = useAppSelector(selectCurrentGin);

  if (!adminGin) return <></>;
  const {
    grant: {
      application_status,
      grant_title_en,
      grant_title_fr,
      grant_description_short_en,
      grant_description_short_fr,
      grant_id,
      grant_logo,
    },
  } = adminGin;

  return (
    <Div flex="column" gap={12}>
      <Div
        flex="row"
        justify="space-between"
        align="center"
        tablet={{ flex: 'column' }}
      >
        <Div>
          {application_status && (
            <StatusTags application_status={application_status} />
          )}
        </Div>
        <Div
          flex="row"
          gap={16}
          align="center"
          justify="flex-end"
          style={{ width: '100%' }}
        >
          <Tooltip title={t('gin_program|open_in_gsp')}>
            <Link target="_blank" to={`/grants/${grant_id}`}>
              <Gsp size={24} />
            </Link>
          </Tooltip>
          <Tooltip title={t('gin_program|open_in_funding_explorer')}>
            <Link target="_blank" to={`/funding-explorer/${grant_id}`}>
              <Invoices size={24} />
            </Link>
          </Tooltip>
        </Div>
      </Div>
      <Div flex="row" gap={16} align="center">
        <ProfileLogo
          name={grant_title_en || ''}
          logo={grant_logo || ''}
          type={'grant-provider'}
          size={80}
        />
        <Div flex="column" gap={2}>
          <Typography
            elementTheme="subtitle1"
            tablet={{ textAlign: 'center' }}
            color={theme.colors.grey_2}
          >
            {t('gin_program|program')}
          </Typography>
          <Typography.Title
            level={1}
            elementTheme="h5"
            noMargin
            tablet={{ textAlign: 'center' }}
          >
            {selectedLocale === 'fr' ? grant_title_fr : grant_title_en}
          </Typography.Title>
        </Div>
      </Div>
      <Typography
        elementTheme="body1"
        color={theme.colors.grey_2}
        tablet={{ textAlign: 'center' }}
      >
        {selectedLocale === 'fr'
          ? grant_description_short_fr
          : grant_description_short_en}
      </Typography>
    </Div>
  );
};

export default GinGrantHeaderContent;
