export const CompanyInitialState = {
    company_id: '',
    name: '',
};
export const CompanyGamePlanInitialState = {
    company_id: '',
    game_plan_id: '',
    account_manager: '',
    asset_id: '',
    file_name: '',
    locale: '',
};
