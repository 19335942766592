import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ItemsTypes, } from '../../../../../features/entities/form-entities';
import { getShortId } from '../../../../../features/helpers';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import { DragDropContainer } from '../../styles';
import getItemTranslatedLabel from '../../utils/get-item-translated-label';
import getTypeComponent from '../../utils/get-type-component';
import FormBuilderGroupActions from './form-builder-group-actions';
import FormItemContentButtons from './form-builder-item-actions';
const FormBuilderGroup = ({ builderGroup, pages, locale, functions, formPageId, }) => {
    var _a, _b;
    const theme = useTheme();
    const [formItems, setFormItems] = useState((_a = builderGroup === null || builderGroup === void 0 ? void 0 : builderGroup.items) !== null && _a !== void 0 ? _a : {});
    useEffect(() => {
        var _a;
        setFormItems((_a = builderGroup === null || builderGroup === void 0 ? void 0 : builderGroup.items) !== null && _a !== void 0 ? _a : {});
    }, [builderGroup === null || builderGroup === void 0 ? void 0 : builderGroup.items]);
    const direction = (_b = builderGroup.entity.group_disposition) !== null && _b !== void 0 ? _b : 'horizontal';
    return (_jsxs(Div, { flex: "column", gap: 16, children: [_jsxs(Div, { flex: "row", align: "center", justify: "space-between", children: [_jsx(Typography, { elementTheme: "subtitle2", children: getItemTranslatedLabel({
                            label_en: builderGroup === null || builderGroup === void 0 ? void 0 : builderGroup.entity.group_title_en,
                            label_fr: builderGroup === null || builderGroup === void 0 ? void 0 : builderGroup.entity.group_title_fr,
                            fallback: `${getShortId(builderGroup === null || builderGroup === void 0 ? void 0 : builderGroup.entity.form_group_id)}`,
                            locale,
                        }) }), _jsx(FormBuilderGroupActions, { formPageId: formPageId, formGroupId: builderGroup === null || builderGroup === void 0 ? void 0 : builderGroup.entity.form_group_id, builderGroup: builderGroup, functions: functions })] }), _jsx(DragDropContainer, { "$isDroppable": true, children: _jsx(Droppable, { droppableId: `group_droppable_${builderGroup.id}`, type: ItemsTypes.items, direction: direction, children: (provided) => (_jsx("div", Object.assign({}, provided.droppableProps, { ref: provided.innerRef, style: {
                            display: 'flex',
                            flexDirection: direction === 'horizontal' ? 'row' : 'column',
                            gap: direction === 'horizontal' ? 32 : 8,
                            minHeight: 50,
                        }, children: React.Children.toArray(Object.values(formItems)
                            .sort((itm1, itm2) => (itm1 === null || itm1 === void 0 ? void 0 : itm1.entity.forms_groups_items_order) -
                            (itm2 === null || itm2 === void 0 ? void 0 : itm2.entity.forms_groups_items_order))
                            .map(({ category, entity }, index) => (_jsx(Draggable, { draggableId: `form_item_draggable_${entity === null || entity === void 0 ? void 0 : entity.form_item_id}`, index: index, children: (provided) => {
                                const Component = getTypeComponent(category);
                                return (_jsx(DragDropContainer, Object.assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { children: _jsxs(Div, { flex: "row", align: "center", justify: "space-between", gap: 24, style: {
                                            padding: '12px 0',
                                            borderTop: `1px dashed ${theme.colors.grey_2}`,
                                            borderBottom: `1px dashed ${theme.colors.grey_2}`,
                                            cursor: 'pointer',
                                        }, children: [_jsx(Component, { entity: entity, type: entity.field_type, isSimple: true, locale: locale }), _jsx(FormItemContentButtons, { formItemId: entity.form_item_id, itemEntity: entity, pages: pages, functions: functions })] }) })));
                            } }, `form_item_draggable_${entity === null || entity === void 0 ? void 0 : entity.form_item_id}`)))) }))) }) })] }));
};
export default FormBuilderGroup;
