import styled, { css } from 'styled-components';
const ContainerVerticalStyling = css `
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  .hs-dependent-field {
    gap: 24px;
  }
  input,
  .hs-input,
  select  {
    width: 312px !important;
  }
`;
const ContainerHorizontalStyling = css `
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;
  .hs-dependent-field {
    gap: 24px;
  }
`;
const FullWidthStyling = css `
  flex-direction: column;
  gap: 16px;
  .form-columns-2,
  .form-columns-1,
  .form-columns-0 {
    display: flex;
    gap: 24px;
    & > * {
      flex: 1;
    }
  }
  .hs-dependent-field {
    gap: 16px;
  }
  .hs-submit {
    margin: auto;
  }
`;
export const HubspotFormStyling = styled.div `
  width: 100%;
  h3 {
    font-size: ${({ theme }) => theme.fonts.h5};
    line-height: ${({ theme }) => theme.fontsLineHeights.h5};
    font-family: Figtree;
  }
  h2 {
    font-family: Figtree;
    font-weight: 600;
    font-size: ${({ theme }) => theme.fonts.h2};
    line-height: ${({ theme }) => theme.fontsLineHeights.h2};
    margin-bottom: 20px;
  }
  h2,
  p {
    max-width: 650px;
  }
  .hs-richtext {
    margin-bottom: 30px;
  }

  form {
    width: 100%;
    max-width: 100%;
    display: flex;
    overflow: auto;
    ${({ $assignedTheme }) => $assignedTheme === 'Container - Vertical'
    ? ContainerVerticalStyling
    : $assignedTheme === 'Container - Horizontal'
        ? ContainerHorizontalStyling
        : FullWidthStyling}

    & > div:first-child {
      flex-grow: 1;
      min-width: 0;
      width: 100%;
    }
    .hs-richtext {
      margin-bottom: 0;
      width: 100%;
    }
    fieldset {
      max-width: 100%;
    }
    .field + .field {
      padding-left: 24px;
    }
    .hs-field-desc {
      font-size: ${({ theme }) => theme.fonts.body3};
      line-height: ${({ theme }) => theme.fontsLineHeights.body3};
      color: ${({ theme }) => theme.colors.grey_2};
    }
    .hs-error-msgs {
      color: ${({ theme }) => theme.colors.red_1};
    }
    .hs-dependent-field {
      display: flex;
      flex-direction: column;
    }
    .field {
      label {
        font-size: ${({ theme }) => theme.fonts.body2};
        line-height: ${({ theme }) => theme.fontsLineHeights.body2};
        margin-bottom: 8px;
        .hs-form-required  {
          margin-left: 8px;
          color: ${({ theme }) => theme.colors.red_1};
        }
      }
      legend {
        margin-bottom: 8px;
      }
      .input {
        margin: 0 !important;
        input,
        .hs-input,
        select {
          border: 1px solid ${({ theme }) => theme.colors.primary};
          background: ${({ theme }) => theme.colors.white_1};
          color: ${({ theme }) => theme.colors.primary};
          border-radius: 4px;
          max-width: 100%;
          height: 40px;
          width: 100%;
          padding-left: 16px;
          padding-right: 16px;
          font-size: 14px;
          &::placeholder,
          &.is-placeholder {
            color: ${({ theme }) => theme.colors.grey_3};
          }
        }
        .hs-form-checkbox-display {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
          .hs-input[type='checkbox'] {
            width: 16px !important;
            height: 16px;
          }
          span {
            font-size: ${({ theme }) => theme.fonts.body3};
            line-height: ${({ theme }) => theme.fontsLineHeights.body3};
          }
        }
      }
    }
    .inputs-list {
      padding: 0;
      li {
        display: block;
        margin: 0;
        padding: 0;
        label {
          margin-bottom: 0;
        }
      }
    }
  }
  .hs_submit {
    width: fit-content;
    height: auto;
    display: flex;
    align-items: flex-end;
  }
  .hs-button {
    cursor: pointer;
    border-radius: 30px;
    min-width: 190px;
    width: fit-content !important;
    min-height: 32px;
    background: ${({ theme }) => theme.colors.purple_2};
    color: ${({ theme }) => theme.colors.white_1};
    padding: 0 30px;
    font-size: ${({ theme }) => theme.fonts.buttonC};
    line-height: ${({ theme }) => theme.fontsLineHeights.buttonC};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    border: none;
    &:hover {
      background: ${({ theme }) => theme.colors.purple_3};
    }
  }
  .submitted-message {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: ${({ theme }) => theme.fonts.body1};
    line-height: ${({ theme }) => theme.fontsLineHeights.body1};
    letter-spacing: ${({ theme }) => theme.letterSpacing.body1};

    &:before {
      content: url('/Checkmark.Png');
      margin-bottom: 8px;
    }
    a {
      color: ${({ theme }) => theme.colors.purple_2};
      &:hover {
        color: ${({ theme }) => theme.colors.purple_1};
      }
    }
  }
  iframe {
    height: calc(100% - 5px) !important;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    .form-columns-2 {
      flex-direction: column;
      .hs-form-field,
      .hs-input {
        width: 100% !important;
      }
    }
  }
`;
