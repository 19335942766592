import styled, { css } from '../../../plugins/styled';
const getTagTheme = (theme, color) => {
    const allTagsThemes = {
        [theme.colors.purple_1]: {
            backgroundColor: theme.colors.purple_5,
            borderColor: theme.colors.purple_5,
            textColor: theme.colors.purple_1,
            backgroundColorHover: theme.colors.purple_1,
            borderColorHover: theme.colors.purple_1,
            textColorHover: theme.colors.purple_5,
        },
        [theme.colors.white_1]: {
            backgroundColor: theme.colors.purple_3,
            borderColor: theme.colors.purple_3,
            textColor: theme.colors.white_1,
            backgroundColorHover: theme.colors.white_1,
            borderColorHover: theme.colors.purple_3,
            textColorHover: theme.colors.purple_3,
        },
        [theme.colors.purple_2]: {
            backgroundColor: theme.colors.purple_4,
            borderColor: theme.colors.purple_4,
            textColor: theme.colors.purple_2,
            backgroundColorHover: theme.colors.purple_2,
            borderColorHover: theme.colors.purple_2,
            textColorHover: theme.colors.white_1,
        },
        [theme.colors.purple_3]: {
            backgroundColor: theme.colors.purple_5,
            borderColor: theme.colors.purple_5,
            textColor: theme.colors.purple_3,
            backgroundColorHover: theme.colors.purple_4,
            borderColorHover: theme.colors.purple_4,
            textColorHover: theme.colors.purple_1,
        },
        [theme.colors.yellow_1]: {
            backgroundColor: theme.colors.yellow_4,
            borderColor: theme.colors.yellow_4,
            textColor: theme.colors.yellow_1,
            backgroundColorHover: theme.colors.yellow_1,
            borderColorHover: theme.colors.yellow_1,
            textColorHover: theme.colors.white_1,
        },
        [theme.colors.grey_2]: {
            backgroundColor: theme.colors.grey_5,
            borderColor: theme.colors.grey_5,
            textColor: theme.colors.grey_2,
            backgroundColorHover: theme.colors.grey_2,
            borderColorHover: theme.colors.grey_2,
            textColorHover: theme.colors.grey_5,
        },
        [theme.colors.green_1]: {
            backgroundColor: theme.colors.green_4,
            borderColor: theme.colors.green_4,
            textColor: theme.colors.green_1,
            backgroundColorHover: theme.colors.green_1,
            borderColorHover: theme.colors.green_1,
            textColorHover: theme.colors.green_4,
        },
        [theme.colors.blue_1]: {
            backgroundColor: theme.colors.blue_4,
            borderColor: theme.colors.blue_4,
            textColor: theme.colors.blue_1,
            backgroundColorHover: theme.colors.blue_1,
            borderColorHover: theme.colors.blue_1,
            textColorHover: theme.colors.blue_4,
        },
        [theme.colors.orange_1]: {
            backgroundColor: theme.colors.orange_4,
            borderColor: theme.colors.orange_4,
            textColor: theme.colors.orange_1,
            backgroundColorHover: theme.colors.orange_1,
            borderColorHover: theme.colors.orange_1,
            textColorHover: theme.colors.orange_4,
        },
        [theme.colors.red_1]: {
            backgroundColor: theme.colors.red_1,
            borderColor: theme.colors.red_1,
            textColor: theme.colors.white_1,
            backgroundColorHover: theme.colors.red_2,
            borderColorHover: theme.colors.red_2,
            textColorHover: theme.colors.white_1,
        },
        [theme.colors.red_1]: {
            backgroundColor: theme.colors.red_1,
            borderColor: theme.colors.red_1,
            textColor: theme.colors.white_1,
            backgroundColorHover: theme.colors.red_2,
            borderColorHover: theme.colors.red_2,
            textColorHover: theme.colors.white_1,
        },
    };
    return allTagsThemes[color !== null && color !== void 0 ? color : theme.colors.purple_5];
};
const colorStyling = css `
  color: ${({ theme, $color }) => {
    var _a;
    return ((_a = getTagTheme(theme, $color)) === null || _a === void 0 ? void 0 : _a.textColor) ||
        theme.colors.purple_1;
}};
  background: ${({ theme, $color }) => {
    var _a;
    return ((_a = getTagTheme(theme, $color)) === null || _a === void 0 ? void 0 : _a.backgroundColor) ||
        $color ||
        theme.colors.purple_5;
}};
  border: ${({ theme, $color }) => {
    var _a;
    return `1px solid ${((_a = getTagTheme(theme, $color)) === null || _a === void 0 ? void 0 : _a.borderColor) ||
        $color ||
        theme.colors.purple_5}`;
}};
`;
const smallStyling = css `
  width: fit-content;
  padding: 1px 6px;
  line-height: 1;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.3px;
`;
const mediumStyling = css `
  width: fit-content;
  padding: 5px 11px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.3px;
`;
const largeStyling = css `
  width: fit-content;
  padding: 5px 11px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.3px;
`;
const hoverableStyling = css `
  &:hover {
    cursor: pointer;
    scale: 1.02;

    color: ${({ $color, theme }) => {
    var _a;
    return ((_a = getTagTheme(theme, $color)) === null || _a === void 0 ? void 0 : _a.textColorHover) ||
        theme.colors.purple_1;
}};
    background: ${({ $color, theme }) => {
    var _a;
    return ((_a = getTagTheme(theme, $color)) === null || _a === void 0 ? void 0 : _a.backgroundColorHover) ||
        $color ||
        theme.colors.purple_5;
}};
    border: ${({ $color, theme }) => {
    var _a;
    return `1px solid ${((_a = getTagTheme(theme, $color)) === null || _a === void 0 ? void 0 : _a.borderColorHover) ||
        $color ||
        theme.colors.purple_5}`;
}};
  }
`;
const DimmedStyle = css `
  filter: brightness(0.8);
  transition: filter 0.3s;
`;
const HoveredStyle = css `
  transform: scale(1.05);
  transition:
    filter 0.3s,
    transform 0.3s;
`;
export const StyledRecursiveTag = styled.div `
  border-radius: ${(props) => (props.$shape === 'square' ? '4px' : '24px')};
  display: inline-flex;
  align-items: center;
  gap: 6px;
  max-width: 100%;
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  ${colorStyling}
  ${({ $hoverable }) => ($hoverable ? hoverableStyling : undefined)}
  ${(props) => props.$size === 'small'
    ? smallStyling
    : props.$size === 'large'
        ? largeStyling
        : mediumStyling}

    ${({ $isDimmed }) => ($isDimmed ? DimmedStyle : undefined)}
    ${({ $isHovered }) => ($isHovered ? HoveredStyle : undefined)}
`;
