import { CollapsePanelProps } from '@hellodarwin/core/lib/components/common/Collapse/CollapsePanel';
import SingleHeaderMenu from '@hellodarwin/core/lib/components/common/single-header-menu';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import createBorderStyle from '@hellodarwin/core/lib/theme/utils/create-border-style';
import TabArrowIcon from '@hellodarwin/icons/dist/icons/TabArrow';
import { ReactNode } from 'react';
import GinGrantSummary, { GinSummaryProps } from '../gin-grant-summary';
import { GinSingleHeaderContainer } from '../styles';

interface GinGrantHeaderProps extends GinSummaryProps {
  HeaderContent: () => ReactNode;
  activeSections?: string[];
  translationFile?: string;
}

const GinSingleHeader = ({
  HeaderContent,
  activeSections,
  translationFile = 'gin_section',
  ...ginSummaryProps
}: GinGrantHeaderProps) => {
  const theme = useTheme();
  const { t } = useTranslations();
  const collapseItems: CollapsePanelProps[] = [
    {
      id: 'gin',
      title: <HeaderContent />,
      children: <GinGrantSummary {...ginSummaryProps} />,
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <GinSingleHeaderContainer
        items={collapseItems}
        expandIconPosition="end"
        ExpandIcon={({ isActive }) => (
          <TabArrowIcon size={24} color={theme.colors.grey_1} down={isActive} />
        )}
        defaultActiveKey={'gin'}
        ghost
        noPadding
        styles={{
          panelContainer: {
            background: theme.colors.white_1,
            border: `1px solid ${theme.colors.grey_1}`,
            borderRadius: '8px 8px 0 0',
            borderBottom: 'none',
          },
          panelHeader: { padding: '24px 48px' },
          panelContent: {
            padding: 0,
            borderTop: `1px solid ${theme.colors.grey_1}`,
          },
        }}
      />
      {!!activeSections && (
        <div
          style={{
            width: '100%',
            ...createBorderStyle({ color: theme.colors.grey_1, side: 'all' }),
            borderTop: 'none',
            position: 'sticky',
            top: theme.appearance.appHeaderHeight,
            zIndex: 10,
          }}
        >
          <SingleHeaderMenu
            items={activeSections.map((s) => ({
              label: t(`${translationFile}|${s}`),
              id: s,
            }))}
          />
        </div>
      )}
    </div>
  );
};

export default GinSingleHeader;
