import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { usePlatform } from '../../../features/providers/platform-provider';
import Div from '../../common/div';
import Dropdown from '../../common/dropdown';
import AssetRowActions from './components/asset-card-actions';
import AssetCardUpdateInfos from './components/asset-card-update-infos';
import AssetMetaContent from './components/asset-meta';
import { AssetBadgeContainer, AssetIconContainer, AssetTileContainer, } from './styles';
const AssetCard = (props) => {
    const { meta, isActive } = props;
    const { Icon, opened_at, actions: { dropdownItems, onDoubleClick }, } = meta;
    const { app } = usePlatform();
    const isOpened = useMemo(() => !!opened_at, [opened_at]);
    return (_jsx(Dropdown, { items: dropdownItems, ctaRender: () => (_jsx(AssetBadgeContainer, { dot: !isOpened && app !== 'admin', offset: [-12, 12], styles: {
                root: {
                    display: 'block',
                    width: 'fit-content',
                },
                indicator: { width: 10, height: 10 },
            }, children: _jsxs(AssetTileContainer, { "$isActive": isActive, onClick: onDoubleClick, "$withUpdateInfos": app === 'admin', "$isRow": false, children: [_jsxs(Div, { flex: "column", gap: 4, align: "center", justify: "center", children: [_jsx(AssetIconContainer, { children: _jsx(Icon, { size: 32 }) }), _jsx(AssetMetaContent, Object.assign({}, meta))] }), app === 'admin' && _jsx(AssetCardUpdateInfos, Object.assign({}, meta)), _jsx(AssetRowActions, { items: dropdownItems })] }) })), trigger: ['contextMenu'] }));
};
export default AssetCard;
