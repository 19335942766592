import { GrantTimeline } from 'src/@types/grants-types';
import { GamePlanTranslations } from '../types';
import formatTimeline from './format-timeline';

const getGrantTimeline = (
  locale: string,
  translations: GamePlanTranslations,
  grant_timeline?: GrantTimeline[],
) => {
  let value = '';

  if (!grant_timeline?.length) return ' - ';

  let index = 0;
  for (const timeline of grant_timeline) {
    value += formatTimeline(
      timeline.opened_at,
      timeline.closed_at,
      locale,
      translations,
    );
    if (index < grant_timeline.length - 1) {
      value += '\n';
    }
    index++;
  }

  return value;
};
export default getGrantTimeline;
