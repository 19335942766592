import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import splitName from '../../../../../../features/helpers/split-name';
import { useCompanyInfos } from '../../../../../../features/providers/company-infos-provider';
import useLocale from '../../../../../../features/providers/locale-provider';
import Modal from '../../../../../common/hd-modal';
import HubspotForm from '../../../../../hubspot/hubspot-form';
import { GrantNotificationContainer } from './styles';
const GrantNotificationModal = ({ open, handleClose, grantId, }) => {
    const { profile } = useCompanyInfos();
    const { selectedLocale } = useLocale();
    const hubspotId = useMemo(() => selectedLocale === 'fr'
        ? 'bac73972-b646-4750-a635-8e84f8fe102d'
        : '0c14d7d8-7db8-4f71-93f6-df3201853695', [selectedLocale]);
    const data = useMemo(() => {
        const { firstname, lastname } = splitName(profile.contact_name);
        return {
            email: profile.contact_email,
            firstname,
            lastname,
            grant_notified: grantId,
        };
    }, [profile, grantId]);
    return (_jsx(Modal, { open: open, handleCancel: handleClose, children: _jsx(GrantNotificationContainer, { children: _jsx(HubspotForm, { hubspotId: hubspotId, containerTag: 'grant-single-notification', assignedTheme: 'Full Width', data: data }) }) }));
};
export default GrantNotificationModal;
