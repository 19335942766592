var RfpDescriptionType;
(function (RfpDescriptionType) {
    RfpDescriptionType["Client_Description"] = "client_description";
    RfpDescriptionType["Project_Description"] = "project_description";
    RfpDescriptionType["Context"] = "context";
    RfpDescriptionType["Goals"] = "goals";
    RfpDescriptionType["Details"] = "details";
    RfpDescriptionType["Provider_Requirement"] = "provider_requirement";
    RfpDescriptionType["Budget"] = "budget";
    RfpDescriptionType["Contact"] = "contact";
    RfpDescriptionType["Other"] = "other";
})(RfpDescriptionType || (RfpDescriptionType = {}));
export default RfpDescriptionType;
