import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Form from 'antd/es/form';
import Input from 'antd/es/input/Input';
import Select from 'antd/es/select';
import React, { useEffect } from 'react';
import { ReviewFormSteps } from '../../../../features/entities/rfp-status-flow-entities';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTranslation } from '../../../../plugins/i18n';
import Grid from '../../../common/hd-grid';
import FormItem from '../../../forms/form-layouts/form-item';
import FormLabel from '../../../forms/form-layouts/form-label';
import { useCompanySize } from '../../../forms/utils/company-infos';
import validateEmail from '../../../forms/utils/validate-email';
import ReviewNavigation from './review-navigation';
const ReviewContact = ({ contactInfo, setForms, setFlowStep, isActive, handleSubmit, isSubmit, companyTags, }) => {
    const [form] = Form.useForm();
    const { t } = useTranslations();
    const { t: translateTags } = useTranslation(['tags']);
    useEffect(() => {
        setForms((prevState) => (Object.assign(Object.assign({}, prevState), { contactForm: form })));
    }, [form, setForms]);
    const initialValues = {
        contact_name: contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.contact_name,
        contact_email: contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.contact_email,
        contact_title: contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.contact_title,
        company_name: contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.company_name,
        company_size: contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.company_size,
        company_industry: contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.company_industry,
        terms_and_conditions: contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.terms_and_conditions,
    };
    const validateFormFields = async (form) => {
        try {
            const values = await form.validateFields();
            return values;
        }
        catch (error) {
            return null;
        }
    };
    const handleNextStep = async () => {
        if (await validateFormFields(form)) {
            handleSubmit && handleSubmit();
        }
    };
    const companySizeOptions = useCompanySize();
    return (_jsx(Form, { form: form, layout: 'vertical', initialValues: initialValues, name: 'contact', hidden: !isActive, children: _jsxs("div", { style: { marginTop: 32 }, children: [_jsxs(Grid, { center: true, xl: 2, lg: 2, md: 2, sm: 1, gutter: 24, children: [_jsx(FormItem, { label: _jsx(FormLabel, { label: t('review_contact|rc_name_label') }), name: 'contact_name', style: { width: '100%' }, rules: [
                                { required: true, message: t('review_contact|rc_name_error') },
                            ], children: _jsx(Input, { placeholder: t('review_contact|rc_name_placeholder') }) }), _jsx(FormItem, { label: _jsx(FormLabel, { label: t('review_contact|rc_email_label') }), name: 'contact_email', style: { width: '100%' }, rules: [
                                { validator: validateEmail(t('review_contact|rc_email_error')) },
                            ], children: _jsx(Input, { placeholder: t('review_contact|rc_email_placeholder') }) }), _jsx(FormItem, { label: _jsx(FormLabel, { label: t('review_contact|rc_title_label') }), name: 'contact_title', style: { width: '100%' }, children: _jsx(Input, { placeholder: t('review_contact|rc_title_placeholder') }) }), _jsx(FormItem, { label: _jsx(FormLabel, { label: t('review_contact|rc_companyName_label') }), name: 'company_name', style: { width: '100%' }, rules: [
                                {
                                    required: true,
                                    message: t('review_contact|rc_companyName_error'),
                                },
                            ], children: _jsx(Input, { placeholder: t('review_contact|rc_companyName_placeholder') }) }), _jsx(FormItem, { name: 'company_size', label: _jsx(FormLabel, { label: t('review_contact|rc_companySize') }), children: _jsx(Select, { placeholder: "Select company size", options: companySizeOptions }) }), _jsx(FormItem, { name: 'company_industry', label: _jsx(FormLabel, { label: t('review_contact|rc_companyIndustry') }), children: _jsx(Select, { placeholder: "Select company industry", children: React.Children.toArray(companyTags === null || companyTags === void 0 ? void 0 : companyTags.map((category) => (_jsx(Select.Option, { value: category, children: translateTags(`tags|${category}`) }, category)))) }) })] }), _jsx(ReviewNavigation, { handleNext: () => setFlowStep(ReviewFormSteps.Confirm), handlePrev: () => setFlowStep(ReviewFormSteps.Experience), handleSubmit: handleNextStep, isSubmit: isSubmit })] }) }));
};
export default ReviewContact;
