import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import reactParser, { Element, attributesToProps, domToReact, } from 'html-react-parser';
import Typography from '../../components/common/typography';
export { Element, attributesToProps, domToReact };
export const parseOptions = {
    replace: (domNode) => {
        if (domNode.attribs && domNode.name === 'a') {
            const props = attributesToProps(domNode.attribs);
            return (_jsx("a", Object.assign({}, props, { rel: "nofollow", target: "_blank", children: domToReact(domNode.children) })));
        }
        if (domNode.attribs && domNode.name === 'p') {
            return _jsx(Typography, { children: domToReact(domNode.children) });
        }
        if (domNode.attribs && domNode.name === 'img') {
            return _jsx(_Fragment, {});
        }
        if (domNode.attribs && domNode.name === 'ul') {
            return (_jsx("ul", { style: { paddingInline: 0, marginInline: '16px' }, children: domToReact(domNode.children) }));
        }
    },
};
const parseWithoutListIndent = (content, options = parseOptions) => {
    return reactParser(content, options);
};
export default parseWithoutListIndent;
