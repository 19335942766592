'use client';
import { useMemo } from 'react';
import useMediaQuery from '../../../features/helpers/use-media-query';
import { useTheme } from '../../../plugins/styled';
export const Desktop = ({ children, direction = 'down', inactive, }) => {
    const theme = useTheme();
    if (inactive)
        return children;
    const breakpoint = direction === 'down'
        ? { maxWidth: theme.breakpoints.xl }
        : { minWidth: theme.breakpoints.xl };
    const isDesktop = useMediaQuery(breakpoint);
    return isDesktop ? children : null;
};
export const SmallDesktop = ({ children, direction = 'down', inactive, }) => {
    const theme = useTheme();
    if (inactive)
        return children;
    const breakpoint = direction === 'down'
        ? { maxWidth: theme.breakpoints.l }
        : { minWidth: theme.breakpoints.l };
    const isDesktop = useMediaQuery(breakpoint);
    return isDesktop ? children : null;
};
export const Tablet = ({ children, direction = 'up', inactive, }) => {
    const theme = useTheme();
    if (inactive)
        return children;
    const breakpoint = direction === 'down'
        ? { maxWidth: theme.breakpoints.tablet }
        : { minWidth: theme.breakpoints.tablet };
    const isTablet = useMediaQuery(breakpoint);
    return isTablet ? children : null;
};
export const useMediaStyle = ({ initial, tablet, xl, l, }) => {
    const theme = useTheme();
    const isXL = useMediaQuery({ maxWidth: theme.breakpoints.xxl });
    const isL = useMediaQuery({ maxWidth: theme.breakpoints.l });
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    return useMemo(() => {
        const currentStyle = {};
        const addStyling = (property, value) => {
            if (value !== undefined) {
                currentStyle[property] = value;
            }
        };
        const prepareStyling = (style) => {
            Object.entries(style).forEach(([key, value]) => {
                addStyling(key, value);
            });
        };
        prepareStyling(initial);
        if (isXL && !!xl)
            prepareStyling(xl);
        if (isL && !!l)
            prepareStyling(l);
        if (isTablet && !!tablet)
            prepareStyling(tablet);
        return currentStyle;
    }, [isXL, isTablet, initial, xl, tablet]);
};
