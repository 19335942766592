import notification from 'antd/es/notification';
const Copy = ({ text, successMessage = 'Text copied to clipboard', errorMessage = 'Could not copy text', }) => {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
            notification.success({ message: successMessage });
        }, (err) => {
            notification.error({ message: errorMessage });
        });
    }
    else {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
            notification.success({ message: successMessage });
        }
        catch (err) {
            notification.error({ message: errorMessage });
        }
        document.body.removeChild(textArea);
    }
};
const copyToClipboard = (props) => {
    var _a;
    if (typeof props === 'string') {
        Copy({ text: props });
    }
    else {
        Copy({
            text: (_a = props.text) !== null && _a !== void 0 ? _a : '',
            errorMessage: props.errorMessage,
            successMessage: props.successMessage,
        });
        if (!!props.onCopy)
            props.onCopy();
    }
};
export default copyToClipboard;
