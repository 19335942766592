import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import getShortId from '../../../../../features/helpers/get-short-id';
import { useTranslation } from '../../../../../plugins/i18n';
import { useTheme } from '../../../../../plugins/styled';
import Typography from '../../../../common/typography';
import PortfolioThumbnail from './portfolio-thumbnail';
import { PortfolioCardContainer, PortfolioCover, PortfolioLogoContainer, } from './styles';
const PortfolioCard = ({ portfolio, onClick }) => {
    const { t: translateTag } = useTranslation(['tags'], { useSuspense: false });
    const theme = useTheme();
    const handleClick = () => {
        onClick(portfolio);
    };
    return (_jsxs(PortfolioCardContainer, { hoverable: true, onClick: handleClick, children: [_jsx(PortfolioLogoContainer, { children: _jsx(PortfolioThumbnail, { portfolio: portfolio }) }), _jsx(PortfolioCover, { flex: "column", align: "center", justify: "center", children: _jsx(Typography, { bold: true, elementTheme: "body1", color: theme.colors.white_1, className: "portfolio-infos", children: portfolio.portfolio_title ||
                        translateTag(`tags|${portfolio.service}`) ||
                        `Portfolio ${getShortId(portfolio.provider_portfolio_id)}` }) })] }));
};
export default PortfolioCard;
