import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Form from 'antd/es/form';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ItemsTypes } from '../../../../../features/entities/form-entities';
import { DragDropContainer } from '../../styles';
import FormPageBuilder from '../page';
import FormBuilderHeader from './form-builder-header';
const FormBuilderController = ({ formResponse, pages, functions, locale, openGrant, }) => {
    return (_jsxs("div", { style: {
            width: '100%',
            display: 'flex',
            gap: 24,
            flexDirection: 'column',
            overflow: 'hidden',
            marginTop: 32,
        }, children: [_jsx(FormBuilderHeader, { formResponse: formResponse, locale: locale, openGrant: openGrant }), _jsx(Form, { layout: "vertical", style: { width: '100%' }, children: _jsx(Droppable, { droppableId: `form_droppable`, type: ItemsTypes.main, children: (provided) => (_jsxs(DragDropContainer, Object.assign({ "$isDroppable": true }, provided.droppableProps, { ref: provided.innerRef, style: { display: 'flex', flexDirection: 'column', gap: 24 }, children: [React.Children.toArray(pages.map((page, index) => (_jsx(Draggable, { draggableId: `page_draggable_${page.id}`, index: index, children: (provided) => {
                                    return (_jsx("div", Object.assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { children: _jsx(FormPageBuilder, { pages: pages, groups: page.groups, isFirstPage: index === 0, id: page.id, formPageResponse: page.entity, functions: functions, locale: locale }) })));
                                } }, `page_draggable_${page.id}`)))), provided.placeholder] }))) }) })] }));
};
export default FormBuilderController;
