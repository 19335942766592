var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Close from '@hellodarwin/icons/dist/icons/Close';
import { useTheme } from 'styled-components';
import Button from '../../common/button';
import FilterCompanyCardContent from './filter/filter-company-card/filter-company-card-content';
const FundingExplorerCompanyInfo = (_a) => {
    var { removeCompany } = _a, cardProps = __rest(_a, ["removeCompany"]);
    const theme = useTheme();
    return (_jsxs("div", { style: {
            padding: 0,
            background: theme.colors.white_2,
            border: `1px solid ${theme.colors.primary}`,
            position: 'relative',
        }, children: [_jsx(FilterCompanyCardContent, Object.assign({}, cardProps)), _jsx(Button, { size: "square", transparent: true, style: { position: 'absolute', padding: 8, top: 0, right: 0 }, onClick: removeCompany, children: _jsx(Close, { size: 16 }) })] }));
};
export default FundingExplorerCompanyInfo;
