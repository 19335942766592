import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useTranslations } from '../../../../features/providers/translations-provider';
import ContactModal from '../../../modals/contact-modal';
const ContactPreview = ({ isVisible, onCancel, logo, name, title, email, phone, ext, preferred_language, }) => {
    const { t, getFullLanguage } = useTranslations();
    if (!(name === null || name === void 0 ? void 0 : name.length))
        return _jsx(_Fragment, {});
    return (_jsx(ContactModal, { open: isVisible, handleClose: onCancel, name: name, picture: logo, description: title, infos: [
            {
                label: t(`profile_contact|email`),
                value: email,
                type: 'email',
            },
            {
                label: t(`profile_contact|phone`),
                value: `${phone} ${ext}`,
                type: 'phone',
            },
            {
                label: t(`profile_contact|preferredLanguage`),
                value: getFullLanguage(preferred_language !== null && preferred_language !== void 0 ? preferred_language : 'en'),
            },
        ] }));
};
export default ContactPreview;
