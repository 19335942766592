import styled from '../../../../../plugins/styled';
export const TeamMemberCardContainer = styled.div `
  cursor: pointer;
  border-radius: 0;
  border-width: 1px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  display: flex;
  align-items: center;
  padding: 16px;
  background: ${(props) => props.theme.colors.beige_3};
  border: 2px solid ${(props) => props.theme.colors.grey_1};

  a {
    width: fit-content;
    border: none;
    color: ${(props) => props.theme.colors.purple_2};
    &:hover {
      color: ${(props) => props.theme.colors.purple_3};
    }
  }

  &:hover {
    border: 2px solid ${(props) => props.theme.colors.purple_2};
  }
  ${({ $selected, theme }) => $selected
    ? `
      background:${theme.colors.purple_4}; 
    border: 2px solid ${(props) => props.theme.colors.purple_1};
      `
    : undefined};
`;
