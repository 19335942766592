import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from 'styled-components';
import { useTranslations } from '../../../features/providers/translations-provider';
import Div from '../div';
import Typography from '../typography';
import LanguageTranslationsViewerFieldContainer from './languages-translations-viewer-field-container';
const LanguageTranslationsViewer = ({ fields, minHeight = 100, maxHeight, ghost, }) => {
    const theme = useTheme();
    const { t } = useTranslations();
    return (_jsxs(Div, { borderColor: !ghost ? theme.colors.grey_4 : undefined, children: [_jsx(LanguageTranslationsViewerFieldContainer, { content_en: t('language|en'), content_fr: t('language|fr'), ghost: ghost, renderField: (field) => (_jsx(Typography, { elementTheme: "body2", medium: true, children: field })) }), !ghost && (_jsx("div", { style: {
                    borderBottom: `1px solid ${theme.colors.grey_4}`,
                    boxShadow: theme.appearance.box_shadow_hover,
                } })), _jsx(Div, { flex: "column", gap: 4, overflow: "auto", style: { minHeight, maxHeight }, children: fields.map((field) => (_jsx(LanguageTranslationsViewerFieldContainer, Object.assign({}, field, { ghost: ghost })))) })] }));
};
export default LanguageTranslationsViewer;
