import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Input from 'antd/es/input';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { useMemo, useState } from 'react';
import Div from '../../../div';
import Modal from '../../../hd-modal';
import Typography from '../../../typography';
import Select from 'antd/es/select';
import React from 'react';
import Pagination from 'antd/es/pagination/Pagination';
const itemsPerPage = 10;
const SeeMoreSidebarModal = ({ items, searchableFields, render, open, handleCancel, title, statusOptions, statusField, }) => {
    const { t } = useTranslations();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const handleClose = () => {
        handleCancel();
        setSearchTerm('');
        setCurrentPage(1);
        setSelectedStatuses([]);
    };
    const handleSearch = (value) => {
        setCurrentPage(1);
        setSearchTerm(value);
    };
    const handleStatuses = (statuses) => {
        setCurrentPage(1);
        setSelectedStatuses(statuses);
    };
    const handleChange = (e) => handleSearch(e.target.value);
    const filteredItems = useMemo(() => {
        if (!items)
            return [];
        return items.filter((item) => {
            let searchedMatch = !searchTerm.length;
            let statusMatched = !selectedStatuses.length;
            if (!!(searchableFields === null || searchableFields === void 0 ? void 0 : searchableFields.length)) {
                searchableFields === null || searchableFields === void 0 ? void 0 : searchableFields.forEach((field) => {
                    switch (typeof item[field]) {
                        case 'bigint':
                        case 'number':
                            const numberTerm = parseInt(searchTerm);
                            if (item[field] === numberTerm) {
                                searchedMatch = true;
                            }
                            break;
                        case 'string':
                            const stringTerm = searchTerm.toLowerCase();
                            if (item[field].toLowerCase().includes(stringTerm)) {
                                searchedMatch = true;
                            }
                            break;
                    }
                });
            }
            if (!!selectedStatuses.length) {
                if (typeof item[statusField] === 'string') {
                    if (selectedStatuses.includes(item[statusField])) {
                        statusMatched = true;
                    }
                }
            }
            const itemMatched = searchedMatch && statusMatched;
            return itemMatched;
        });
    }, [items, searchTerm, selectedStatuses]);
    const paginatedItems = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        return filteredItems.slice(startIndex, startIndex + itemsPerPage);
    }, [filteredItems, currentPage]);
    return (_jsx(Modal, { open: open, handleCancel: handleClose, children: _jsxs(Div, { flex: "column", gap: 24, children: [_jsxs(Div, { flex: "column", gap: 4, children: [_jsx(Typography, { elementTheme: "subtitle1", children: title }), _jsxs(Div, { flex: "row", gap: 16, children: [!!statusOptions && (_jsx(Select, { options: statusOptions, onChange: handleStatuses, style: { flex: 1, overflow: 'hidden' }, value: selectedStatuses, placeholder: t('gin_program|search_status') })), !!(searchableFields === null || searchableFields === void 0 ? void 0 : searchableFields.length) && (_jsx(Input.Search, { placeholder: t('gin_program|search_term_from', { type: title }), allowClear: true, onSearch: handleSearch, onChange: handleChange, style: { flex: 2 } }))] })] }), _jsxs(Div, { flex: "column", gap: 8, children: [React.Children.toArray(paginatedItems.map(render)), _jsx(Pagination, { current: currentPage, total: filteredItems.length, pageSize: itemsPerPage, onChange: setCurrentPage, style: { marginTop: 16, alignSelf: 'center' } })] })] }) }));
};
export default SeeMoreSidebarModal;
