import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useNavigate } from '../../../features/providers/location-provider';
import Div from '../../common/div';
import { ProjectsTableLevel2 } from '../main-project-table/styles';
import useExpandedGrantRowRender from './use-expanded-grants-row-render';
const useExpandedProjectsRowRender = ({ projectsListState, }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const expandedGrantRowRender = useExpandedGrantRowRender({
        projectsListState,
    });
    return useMemo(() => {
        const { projectsColumns: { expandProgramColumns }, applicationsPath, } = projectsListState;
        const expandedProjectsRowRender = (record) => {
            var _a;
            return (_jsx(Div, { flex: "column", gap: 16, backgroundColor: theme.colors.grey_5, style: {
                    paddingInline: 24,
                    paddingBlock: 16,
                }, children: !!((_a = record.programs) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(ProjectsTableLevel2, { columns: expandProgramColumns.map((col, i) => i === 0 ? Object.assign(Object.assign({}, col), { width: 500 - 24 }) : col), dataSource: record.programs, pagination: false, rowKey: (g) => g.program_id, onRow: (g) => {
                        return {
                            onDoubleClick: () => {
                                navigate(`${applicationsPath}/${g.program_id}`);
                            },
                        };
                    }, rowClassName: (_r, i) => (i % 2 ? 'odd' : 'even'), scroll: { x: 'max-content' } })) }));
        };
        return expandedProjectsRowRender;
    }, [projectsListState, expandedGrantRowRender]);
};
export default useExpandedProjectsRowRender;
