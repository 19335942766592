import Div from '@hellodarwin/core/lib/components/common/div';
import RichTextEditor from '@hellodarwin/core/lib/components/common/rich-editor';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import parseHtml, {
  attributesToProps,
  domToReact,
  HTMLReactParserOptions,
} from '@hellodarwin/core/lib/features/helpers/parse';
import theme from '@hellodarwin/core/lib/theme';
import EmptyState from 'antd/es/empty';
import React, { useEffect, useState } from 'react';

export interface TextEditorProps {
  content?: string;
  onContentChange: (content: string) => void;
  isEditMode: boolean;
  placeholderText: string;
  customParseOptions?: HTMLReactParserOptions;
}

const defaultParseOptions: HTMLReactParserOptions = {
  replace: (domNode: any) => {
    if (domNode.attribs) {
      const props = attributesToProps(domNode.attribs);

      switch (domNode.name) {
        case 'a':
          return (
            <a {...props} rel="nofollow" target="_blank">
              {domToReact(domNode.children)}
            </a>
          );
        case 'p':
          return (
            <Typography elementTheme="body2">
              {domToReact(domNode.children)}
            </Typography>
          );
        case 'li':
          return (
            <li>
              <Typography elementTheme="body2">
                {domToReact(domNode.children)}
              </Typography>
            </li>
          );
      }
    }
  },
};

const isMeaningfulContent = (content?: string): boolean => {
  if (!content) return false;
  const trimmedContent = content.trim();
  return (
    trimmedContent.length > 0 &&
    trimmedContent !== '<p><br></p>' &&
    !/^<p>\s*<\/p>$/.test(trimmedContent)
  );
};

const TextEditor: React.FC<TextEditorProps> = ({
  content,
  onContentChange,
  isEditMode,
  placeholderText,
  customParseOptions,
}) => {
  const [currentContent, setCurrentContent] = useState<string | undefined>(
    content,
  );

  useEffect(() => {
    setCurrentContent(content);
  }, [content]);

  const handleContentChange = (newContent: string) => {
    if (isMeaningfulContent(newContent)) {
      setCurrentContent(newContent);
      onContentChange(newContent);
    } else {
      setCurrentContent('');
      onContentChange('');
    }
  };

  return isEditMode ? (
    <RichTextEditor value={currentContent} onChange={handleContentChange} />
  ) : (
    <Typography elementTheme="body2">
      <Div flex="column" gap={8}>
        {isMeaningfulContent(currentContent) ? (
          parseHtml(
            currentContent ?? '',
            customParseOptions || defaultParseOptions,
          )
        ) : (
          <EmptyState
            description={
              <Typography elementTheme="subtitle2" color={theme.colors.grey_2}>
                {placeholderText}
              </Typography>
            }
          />
        )}
      </Div>
    </Typography>
  );
};

export default TextEditor;
