import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy, Suspense, } from 'react';
import { ContainerStyled } from './styles';
const ContainerTabsStyled = lazy(() => import('./styles').then((module) => ({
    default: module.ContainerTabsStyled,
})));
const Container = ({ children, className, noPadding, fitContent, style, styles, onClick, handleTabClick, activeTab, defaultActiveTab, tabs, tabBarExtraContent, fullWidth = false, overflow, }) => {
    const withTabs = !!tabs;
    return (_jsxs("div", { style: Object.assign(Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.container), { width: '100%' }), children: [!!tabs && (_jsx(Suspense, { fallback: _jsx(_Fragment, {}), children: _jsx(ContainerTabsStyled, { items: tabs, activeKey: activeTab, defaultActiveKey: defaultActiveTab, onTabClick: handleTabClick, type: "card", style: Object.assign(Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.content), style), tabBarExtraContent: tabBarExtraContent, "$fullWidth": fullWidth }) })), !!children && (_jsx(ContainerStyled, { "$fitContent": fitContent, "$noPadding": noPadding || withTabs, style: Object.assign(Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.content), style), className: className, onClick: onClick, "$fullWidth": fullWidth, "$overflow": overflow, children: children }))] }));
};
export default Container;
