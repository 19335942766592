import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Dots from '@hellodarwin/icons/dist/icons/Dots';
import Dropdown from 'antd/es/dropdown';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { getStringDate } from '../../../../features/helpers/get-formatted-date';
import useLocale from '../../../../features/providers/locale-provider';
import Button from '../../../common/button';
import Div from '../../../common/div';
import HdDropdown from '../../../common/dropdown';
import Typography from '../../../common/typography';
import { FilesTableContainer } from './styles';
const getTableColumns = () => {
    const { t } = useTranslation();
    const { selectedLocale } = useLocale();
    const theme = useTheme();
    return useMemo(() => {
        const columns = [
            {
                title: '',
                dataIndex: 'Icon',
                key: 'Icon',
                render: (Icon) => (_jsx(Div, { flex: "row", align: "center", style: { width: 'fit-content' }, children: _jsx(Icon, { size: 24, style: { flexShrink: 0, width: 24, display: 'block' } }) })),
            },
            {
                title: t('assets|infosDrawer.labels.name'),
                dataIndex: 'name',
                key: 'name',
                render: (value) => (_jsx(Typography, { elementTheme: "subtitle2", color: theme.colors.primary, nowrap: true, overflow: true, ellipsis: true, children: value })),
            },
            {
                title: t('assets|infosDrawer.labels.size'),
                dataIndex: 'size',
                key: 'size',
                render: (value) => (_jsx(Typography, { elementTheme: "body2", color: theme.colors.grey_2, nowrap: true, overflow: true, ellipsis: true, children: value })),
            },
            {
                title: t('assets|infosDrawer.labels.type'),
                dataIndex: 'type',
                key: 'type',
                render: (value) => (_jsx(Typography, { elementTheme: "body2", color: theme.colors.grey_2, nowrap: true, overflow: true, ellipsis: true, children: value })),
            },
            {
                title: t('assets|infosDrawer.labels.createdAt'),
                dataIndex: 'created_at',
                key: 'created_at',
                render: (value) => (_jsx(Typography, { elementTheme: "body2", color: theme.colors.grey_2, nowrap: true, overflow: true, ellipsis: true, children: getStringDate(value, selectedLocale) })),
            },
            {
                title: t('assets|infosDrawer.labels.updatedAt'),
                dataIndex: 'updated_at',
                key: 'updated_at',
                render: (value) => (_jsx(Typography, { elementTheme: "body2", color: theme.colors.grey_2, nowrap: true, overflow: true, ellipsis: true, children: getStringDate(value, selectedLocale) })),
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                render: (actions) => (_jsx(HdDropdown, { items: actions.dropdownItems, menuStyle: { padding: 0 }, ctaRender: () => (_jsx(Button, { size: "square", style: { padding: 0 }, transparent: true, children: _jsx(Dots, { size: 32 }) })) })),
                fixed: 'right',
            },
        ];
        return columns;
    }, [t, selectedLocale]);
};
const FilesListTable = ({ items, activeItem, }) => {
    const columns = getTableColumns();
    const [dropdownPosition, setDropdownPosition] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const onContextMenu = (event, record) => {
        event.preventDefault();
        setSelectedRow(record);
        setDropdownPosition({ x: event.clientX, y: event.clientY });
    };
    const handleCloseDropdown = () => {
        setDropdownPosition(null);
        setSelectedRow(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx(FilesTableContainer, { dataSource: items, columns: columns, tableLayout: "auto", rowSelection: { selectedRowKeys: [activeItem === null || activeItem === void 0 ? void 0 : activeItem.id] }, rowKey: 'id', sticky: true, scroll: { x: 1200 }, onRow: (record, rowIndex) => {
                    return {
                        contextMenu: 'test',
                        onClick: record.actions.onClick,
                        onDoubleClick: record.isFolder
                            ? record.actions.onAction
                            : record.actions.onDoubleClick,
                        onContextMenu: (event) => onContextMenu(event, record),
                    };
                } }), dropdownPosition && (_jsx(Dropdown, { menu: {
                    items: selectedRow.actions.dropdownItems,
                    style: { padding: 0 },
                }, open: !!dropdownPosition, onOpenChange: (visible) => !visible && handleCloseDropdown(), trigger: ['click'], children: _jsx("div", { style: {
                        position: 'fixed',
                        width: 1,
                        background: 'red',
                        top: dropdownPosition.y,
                        left: dropdownPosition.x,
                    } }) }))] }));
};
export default FilesListTable;
