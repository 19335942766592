import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import HdTag from '../../../common/hd-tag';
import { FundingExplorerTagBarContainer, FundingExplorerTagBarStyled, } from './styles';
import useTagsInformations from './utils/use-tag-informations';
const FilterTagBar = (props) => {
    const theme = useTheme();
    const { filterform, filterValues } = props;
    const [isOpen, setIsOpen] = useState(false);
    const handleTagBarOpen = () => setIsOpen(!isOpen);
    const removeTag = (newValue, type) => {
        var _a;
        const value = filterValues[type];
        if (Array.isArray(value)) {
            filterform.setFieldValue(type, (_a = filterValues[type]) === null || _a === void 0 ? void 0 : _a.filter((selectedValue) => selectedValue !== newValue));
        }
        else if (value === newValue) {
            filterform.setFieldValue(type, undefined);
        }
        else {
            filterform.setFieldValue(type, newValue);
        }
    };
    const [scrollHeight, setHeight] = useState(0);
    const ref = useRef(null);
    const updateHeight = () => {
        if (ref.current) {
            setHeight(ref.current.scrollHeight);
        }
    };
    useEffect(() => {
        updateHeight();
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);
    const { allTags, allTagsInformations } = useTagsInformations(props);
    useEffect(() => {
        updateHeight();
    }, [allTags]);
    const withDrawer = useMemo(() => scrollHeight > 70, [scrollHeight]);
    useEffect(() => {
        if (!withDrawer) {
            setIsOpen(false);
        }
    }, [withDrawer]);
    if (!(allTags === null || allTags === void 0 ? void 0 : allTags.length))
        return _jsx(_Fragment, {});
    return (_jsxs(FundingExplorerTagBarContainer, { ref: ref, "$isOpen": isOpen, "$withDrawer": withDrawer, children: [_jsx(FundingExplorerTagBarStyled, { "$isOpen": isOpen, "$withDrawer": withDrawer, children: !!allTags.length &&
                    React.Children.toArray(allTags
                        .filter((value) => { var _a; return !!((_a = allTagsInformations === null || allTagsInformations === void 0 ? void 0 : allTagsInformations[value]) === null || _a === void 0 ? void 0 : _a.label); })
                        .map((value) => {
                        var _a;
                        return (_jsx(HdTag, { text: allTagsInformations[value].label, hoverable: true, color: (_a = allTagsInformations[value]) === null || _a === void 0 ? void 0 : _a.tagColor, onClose: () => {
                                var _a;
                                removeTag(value, (_a = allTagsInformations[value]) === null || _a === void 0 ? void 0 : _a.type);
                            } }, value));
                    })) }), withDrawer && (_jsx(Button, { onClick: handleTagBarOpen, defaultStyle: theme.colors.primary, style: { position: 'absolute', bottom: 8, right: 8, zIndex: 100 }, headingIcon: _jsx(TabArrow, { size: 16, down: !isOpen }), size: "square", transparent: true }))] }));
};
export default FilterTagBar;
