import Modal from '@hellodarwin/core/lib/components/common/hd-modal';
import { GrantResult } from '@hellodarwin/core/lib/features/entities';
import { Program } from '@hellodarwin/core/lib/features/entities/programs-entities';
import { useEffect, useReducer } from 'react';
import {
  AddNewProjectItemModalProps,
  AddNewProjectItemModalState,
} from '../../types';
import ProjectApplicationForm from './application-form';
import AddNewProjectItemCongrats from './congrats';
import ProjectGrantForm from './grant-form';
import ProjectRfpForm from './rfp-form';
import AddNewProjectItemSelectionFrame from './selection-frame';

interface State {
  currentState: AddNewProjectItemModalState;
  assignedValue: string;
}

type Action =
  | { type: 'SET_STATE'; payload: AddNewProjectItemModalState }
  | { type: 'SET_ASSIGNED_VALUE'; payload: string }
  | { type: 'RESET'; payload: AddNewProjectItemModalState };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_STATE':
      return { ...state, currentState: action.payload };
    case 'SET_ASSIGNED_VALUE':
      return { ...state, assignedValue: action.payload };
    case 'RESET':
      return { currentState: action.payload, assignedValue: '' };
    default:
      throw new Error('Unknown action type');
  }
};

const AddNewProjectItemModal: React.FC<AddNewProjectItemModalProps> = ({
  handleCancel,
  open,
  company,
  handleSaveGrant,
  handleSaveApplication,
  defaultState = 'grant',
  parentGrant,
  project,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    currentState: defaultState,
    assignedValue: '',
  });

  useEffect(() => {
    if (open) {
      dispatch({ type: 'RESET', payload: defaultState });
    }
  }, [open, defaultState]);

  const handleItemClick = (id: AddNewProjectItemModalState) => {
    dispatch({ type: 'SET_STATE', payload: id });
  };

  const onGrantSave = (grant: GrantResult) => {
    handleSaveGrant &&
      handleSaveGrant(grant.grant_id).then((succeeded) => {
        if (succeeded) {
          dispatch({
            type: 'SET_ASSIGNED_VALUE',
            payload: grant.grant_display_title ?? grant.grant_title ?? '',
          });
          dispatch({ type: 'SET_STATE', payload: 'congrats' });
        }
      });
  };

  const onApplicationSave = (application: Program) => {
    handleSaveApplication({
      applicationId: application.program_id,
      projectId: !parentGrant ? project?.project_id : undefined,
      grantId: application.program_grant_id,
    }).then((succeeded) => {
      if (succeeded) {
        dispatch({
          type: 'SET_ASSIGNED_VALUE',
          payload: application.program_name ?? '',
        });
        dispatch({ type: 'SET_STATE', payload: 'congrats' });
      }
    });
  };

  return (
    <Modal
      open={open}
      handleCancel={handleCancel}
      styles={{ body: { display: 'flex', gap: 32 } }}
      size={['congrats'].includes(state.currentState) ? 'small' : 'large'}
    >
      {state.currentState === 'application' ? (
        <ProjectApplicationForm
          companyId={company.company_id}
          isSelectingCompany={false}
          projectId={project?.project_id ?? ''}
          handleSaveApplication={onApplicationSave}
          handleCancel={handleCancel}
          projectApplicationsIds={
            project?.programs?.map((p) => p.program_id) ?? []
          }
        />
      ) : state.currentState === 'rfp' ? (
        <ProjectRfpForm />
      ) : state.currentState === 'grant' ? (
        <ProjectGrantForm handleSaveGrant={onGrantSave} />
      ) : state.currentState === 'congrats' ? (
        <AddNewProjectItemCongrats
          handleCancel={handleCancel}
          assignedValue={state.assignedValue}
        />
      ) : (
        <AddNewProjectItemSelectionFrame handleItemClick={handleItemClick} />
      )}
    </Modal>
  );
};

export default AddNewProjectItemModal;
