import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Informations from '@hellodarwin/icons/dist/icons/Informations';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import theme from '../../../../../theme';
import Button from '../../../../common/button';
const StepValidationModal = ({ onClose, onConfirm, isValidatingStep, isSingle, }) => {
    const { t } = useTranslations();
    return (_jsxs(Div, { flex: "column", align: "center", justify: "center", gap: 16, children: [_jsx(Informations, { color: theme.colors.yellow_1, size: 40 }), _jsx(Typography, { elementTheme: "h5", textAlign: "center", children: isValidatingStep
                    ? t('singleCompanyPage|markAsCompleted')
                    : t('singleCompanyPage|markAsIncomplete') }), _jsx(Typography, { elementTheme: "body2", textAlign: "center", children: isValidatingStep
                    ? t('singleCompanyPage|markAsCompletedLong')
                    : isSingle
                        ? t('singleCompanyPage|markAsIncompleteSingle')
                        : t('singleCompanyPage|markAsIncompleteLong') }), _jsxs(Div, { flex: "row", gap: 8, justify: "center", children: [_jsx(Button, { onClick: onClose, style: { backgroundColor: theme.colors.primary }, children: t('button|cancel') }), _jsx(Button, { onClick: onConfirm, children: t('button|confirm') })] })] }));
};
export default StepValidationModal;
