import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Warning from '@hellodarwin/icons/dist/icons/Warning';
import { createRoot } from 'react-dom/client';
import { ThemeProvider, useTheme } from 'styled-components';
import { useTranslations } from '../../../features/providers/translations-provider';
import theme from '../../../theme';
import Button from '../button';
import Div from '../div';
import Typography from '../typography';
import { ModalContainer, ModalContent, ModalWrapper } from './styles';
import useModalOpenFunctions from './use-modal-open-functions';
export const ModalConfirmComponent = (props) => {
    const { t } = useTranslations();
    const theme = useTheme();
    const { okText = t('button|confirm'), cancelText = t('button|cancel'), cancelButtonProps, okButtonProps, title, content, onOk, onCancel, onClose, width, } = props;
    const handleCancel = () => {
        !!onCancel && onCancel();
        onClose();
    };
    const handleConfirm = () => {
        onOk();
        onClose();
    };
    const { onCancel: onFinish, fadeOut, isOpen, } = useModalOpenFunctions({
        handleCancel,
        open: true,
    });
    if (!isOpen && !fadeOut)
        return _jsx(_Fragment, {});
    return (_jsx(ModalWrapper, { onClick: onCancel, children: _jsx(ModalContainer, { "$size": 'small', children: _jsx(ModalContent, { onClick: (e) => e.stopPropagation(), style: { width, minHeight: 'auto' }, children: _jsxs(Div, { flex: "column", gap: 24, children: [_jsxs(Div, { flex: "column", gap: 8, align: "center", children: [_jsx(Warning, { size: 48 }), _jsx(Typography, { elementTheme: "subtitle1", color: theme.colors.primary, textAlign: "center", children: title }), _jsx(Typography, { elementTheme: "body1", color: theme.colors.primary, textAlign: "center", children: content })] }), _jsxs(Div, { flex: "row", align: "center", justify: "center", gap: 8, children: [_jsx(Button, Object.assign({ defaultStyle: theme.colors.white_1 }, cancelButtonProps, { size: "small", onClick: onFinish, children: cancelText })), _jsx(Button, Object.assign({ defaultStyle: theme.colors.purple_1 }, okButtonProps, { size: "small", onClick: handleConfirm, children: okText }))] })] }) }) }) }));
};
export const ModalConfirm = (props) => {
    let modalRoot = document.createElement('div');
    modalRoot.id = 'modal-root';
    document.body.appendChild(modalRoot);
    let root = createRoot(modalRoot);
    const closeModal = async () => {
        root.unmount();
    };
    root.render(_jsx(ThemeProvider, { theme: theme, children: _jsx(ModalConfirmComponent, Object.assign({}, props, { onClose: closeModal })) }));
};
export default ModalConfirm;
