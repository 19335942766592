import Div from '@hellodarwin/core/lib/components/common/div';
import RichTextEditor from '@hellodarwin/core/lib/components/common/rich-editor';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import AddressAutocompleteInput from '@hellodarwin/core/lib/components/forms/form-groups/adress-autocomplete-input';
import FormItem from '@hellodarwin/core/lib/components/forms/form-layouts/form-item';
import {
  useAnnualRevenue,
  useCompanySize,
} from '@hellodarwin/core/lib/components/forms/utils/company-infos';
import validateWebsite from '@hellodarwin/core/lib/components/forms/utils/validate-website';
import { Provider } from '@hellodarwin/core/lib/features/entities';
import Language from '@hellodarwin/core/lib/features/enums/language';
import SingleLanguage from '@hellodarwin/core/lib/features/enums/single-language';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Collapse from 'antd/es/collapse';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Select from 'antd/es/select';
import Switch from 'antd/es/switch';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { AdminPagesForms } from '../../pages/single-rfp-page';

const { Title } = Typography;
const { Panel } = Collapse;

type ProviderFormProps = {
  provider?: Provider;
  setForms: Dispatch<SetStateAction<AdminPagesForms>>;
};

export type ProviderFormValues = {
  name: string;
  type: string;
  company_size: string;
  annual_revenue: string;
  project_language: string;
  province: string;
  postal_code: string;
  country: string;
  isCanada: boolean;
  description: string;
  comments: string;
  website: string;
  business_number: string;
  email: string;
  phone: string;
  address: string;
  facebook: string;
  twitter: string;
  linked_in: string;
  instagram: string;
  tiktok: string;
  pinterest: string;
  youtube: string;
  city: string;
  is_aqt: boolean;
  available_raise_hands: number;
  lat: number;
  lng: number;
  sb_promocode: string;
  profile_language: string;
};

const ProviderForm = ({ provider, setForms }: ProviderFormProps) => {
  const [form] = Form.useForm<ProviderFormValues>();
  const { t } = useTranslations();
  const theme = useTheme();
  const annualRevenueOptions = useAnnualRevenue();
  const companySizeOptions = useCompanySize();

  useEffect(() => {
    setForms((prevState: AdminPagesForms) => ({
      ...prevState,
      providerForm: form,
    }));
  }, [form, setForms]);

  useEffect(() => {
    form.resetFields();
    if (provider) form.setFieldsValue(provider);
  }, [form, provider]);

  const initialValues: ProviderFormValues = {
    name: provider?.name ?? '',
    type: provider?.type === undefined ? 'Agency' : provider?.type,
    company_size: provider?.size ?? '',
    annual_revenue: provider?.annual_revenue ?? '',
    project_language: provider?.rfp_language ?? '',
    province: provider?.province ?? '',
    description: provider?.description ?? '',
    comments: provider?.comments ?? '',
    website: provider?.website ?? '',
    business_number: provider?.business_number ?? '',
    email: provider?.email ?? '',
    phone: provider?.phone ?? '',
    address: provider?.address ?? '',
    postal_code: provider?.postal_code ?? '',
    country: provider?.country ?? '',
    isCanada: provider?.country === 'Canada' || provider?.country === '',
    facebook: provider?.facebook ?? '',
    twitter: provider?.twitter ?? '',
    linked_in: provider?.linked_in ?? '',
    instagram: provider?.instagram ?? '',
    tiktok: provider?.tiktok ?? '',
    pinterest: provider?.pinterest ?? '',
    youtube: provider?.youtube ?? '',
    city: provider?.city ?? '',
    is_aqt: !!provider?.aqt_at,
    available_raise_hands: provider?.available_raise_hands ?? 0,
    lat: provider?.lat || 0,
    lng: provider?.lng || 0,
    sb_promocode: provider?.sb_promocode || '',
    profile_language: provider?.profile_language || SingleLanguage.English,
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return (
    <Card style={{ padding: '1rem' }}>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        name="company"
      >
        <Div flex="column" gap={54}>
          <Div flex="column" gap={16}>
            <FormItem label={t('provider_form|tagline')} name="tagline">
              <Input />
            </FormItem>
            <FormItem
              label={t('provider_form|file_description')}
              name="description"
            >
              <RichTextEditor />
            </FormItem>

            <Div flex="row" gap={16}>
              <FormItem
                label="AQT"
                style={{ flex: 4 }}
                name="is_aqt"
                valuePropName={'checked'}
              >
                <Switch />
              </FormItem>
              <FormItem
                style={{ flex: 8 }}
                label={t('provider_form|availableHands')}
                name="available_raise_hands"
              >
                <InputNumber />
              </FormItem>
              <FormItem
                label={t('provider_form|promoCode')}
                name="sb_promocode"
                style={{ flex: 4 }}
              >
                <Input />
              </FormItem>
            </Div>
          </Div>

          <Div flex="column" gap={16}>
            <Title level={4} elementTheme="h6">
              {t('provider_form|companyDetails')}
            </Title>

            <Div flex="row" style={{ width: '100%' }} gap={16}>
              <FormItem
                label={t('provider_form|form_name')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('provider_form|companyNameRequired'),
                  },
                ]}
                style={{ width: '100%' }}
              >
                <Input />
              </FormItem>
              <Div
                flex="row"
                gap={6}
                align="flex-end"
                style={{ width: '100%' }}
              >
                <FormItem
                  label={t('provider_form|website')}
                  name="website"
                  style={{ width: '100%' }}
                  rules={[
                    {
                      required: true,
                      validator: validateWebsite({
                        message: t('provider_form|websiteInvalid'),
                        isAdmin: true,
                        isRequired: true,
                      }),
                    },
                  ]}
                >
                  <Input />
                </FormItem>
                {provider?.website && (
                  <Button
                    onClick={() =>
                      window.open(
                        provider?.website?.includes('https://')
                          ? provider?.website
                          : 'https://' + provider?.website,
                        '_blank',
                      )
                    }
                    icon={
                      <NewWindow
                        style={{ color: theme.colors.purple_1 }}
                        size={16}
                      />
                    }
                  />
                )}
              </Div>
            </Div>

            <Div flex="row" gap={16}>
              <FormItem label="Type" name="type" style={{ width: '100%' }}>
                <Select placeholder={t('provider_form|selectOption')}>
                  <Select.Option value="Agency">
                    {t('provider_form|agency')}
                  </Select.Option>
                  <Select.Option value="Freelance">Freelance</Select.Option>
                </Select>
              </FormItem>{' '}
              <FormItem
                label="Company size"
                name="company_size"
                style={{ width: '100%' }}
              >
                <Select
                  placeholder={t('provider_form|selectOption')}
                  options={companySizeOptions}
                />
              </FormItem>
            </Div>
            <Div flex="row" gap={16} style={{ width: '100%' }}>
              <FormItem
                label={t('provider_form|annualRevenue')}
                name="annual_revenue"
                style={{ width: '100%' }}
              >
                <Select
                  placeholder={t('provider_form|selectOption')}
                  options={annualRevenueOptions}
                />
              </FormItem>{' '}
              <FormItem
                label={t('provider_form|businessNumber')}
                name="business_number"
                style={{ width: '100%' }}
              >
                <Input />
              </FormItem>
            </Div>
            <Div flex="row" gap={16}>
              <FormItem
                label={t('provider_form|phone')}
                name="phone"
                style={{ flex: 2 }}
              >
                <Input />
              </FormItem>{' '}
              <FormItem
                label={t('provider_form|projectLanguage')}
                name="project_language"
                style={{ flex: 1 }}
              >
                <Select placeholder={t('provider_form|selectOption')}>
                  <Select.Option value={Language.French}>
                    Français
                  </Select.Option>
                  <Select.Option value={Language.English}>
                    English
                  </Select.Option>
                  <Select.Option value={Language.Any}>Any</Select.Option>
                </Select>
              </FormItem>
              <FormItem
                label={t('provider_form|profileLanguage')}
                name="profile_language"
                style={{ flex: 1 }}
              >
                <Select placeholder={t('provider_form|selectOption')}>
                  <Select.Option value={Language.French}>
                    Français
                  </Select.Option>
                  <Select.Option value={Language.English}>
                    English
                  </Select.Option>
                </Select>
              </FormItem>
            </Div>
            <FormItem label={t('provider_form|only_email')} name="email">
              <Input />
            </FormItem>
            <AddressAutocompleteInput form={form} showManualAddress />

            <Collapse style={{ backgroundColor: '#fff' }}>
              <Panel
                header={<Typography>{t('provider_form|socials')}</Typography>}
                key={'1'}
              >
                <FormItem label="Facebook" name="facebook">
                  <Input />
                </FormItem>
                <FormItem label="Twitter" name="twitter">
                  <Input />
                </FormItem>
                <FormItem label="Linkedin" name="linked_in">
                  <Input />
                </FormItem>
                <FormItem label="Instagram" name="instagram">
                  <Input />
                </FormItem>
                <FormItem label="Tiktok" name="tiktok">
                  <Input />
                </FormItem>
                <FormItem label="Pinterest" name="pinterest">
                  <Input />
                </FormItem>
                <FormItem label="Youtube" name="youtube">
                  <Input />
                </FormItem>
              </Panel>
            </Collapse>

            <FormItem
              label={t('provider_form|comments')}
              name="comments"
              style={{ width: '100%' }}
            >
              <RichTextEditor />
            </FormItem>
          </Div>
        </Div>
      </Form>
    </Card>
  );
};

export default ProviderForm;
