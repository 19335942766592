import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import Badge from 'antd/es/badge';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import ProfileLogo from '../../../provider/profile/profile-assets/profile-logo';
import { UserMenuButtonStyled } from './styles';
const UserMenuButton = ({ withArrow, user, onClick, app, isOnNav, notifs, }) => {
    return (_jsxs(UserMenuButtonStyled, { "$isOnNav": isOnNav, "$app": app, onClick: onClick, children: [_jsxs(Div, { gap: 16, flex: "row", align: "center", children: [_jsx(Badge, { count: notifs, size: "small", offset: [-2, 6], children: _jsx(ProfileLogo, { logo: user.logo, size: 36, name: user.name, type: app !== 'website' ? app : 'provider' }) }), _jsx(Typography, { nowrap: true, overflow: true, medium: true, elementTheme: "body1", children: user === null || user === void 0 ? void 0 : user.name })] }), withArrow && _jsx(TabArrow, { down: true, size: 16 })] }));
};
export default UserMenuButton;
