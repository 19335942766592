var SingleGrantSections;
(function (SingleGrantSections) {
    SingleGrantSections["Summary"] = "summary";
    SingleGrantSections["Preview"] = "preview";
    SingleGrantSections["TermsAndConditions"] = "terms_and_conditions";
    SingleGrantSections["Admissibility"] = "admissibility";
    SingleGrantSections["Criteria"] = "criteria";
    SingleGrantSections["Apply"] = "apply";
    SingleGrantSections["OtherInfo"] = "otherInfo";
    SingleGrantSections["Documents"] = "documents";
    SingleGrantSections["Contact"] = "contact";
    SingleGrantSections["Projects"] = "projects";
})(SingleGrantSections || (SingleGrantSections = {}));
export default SingleGrantSections;
