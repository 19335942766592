var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Modal from '..';
import Div from '../../div';
import Typography from '../../typography';
const Header = ({ title, action }) => {
    return (_jsxs(Div, { flex: "row", align: "center", justify: "space-between", gap: 32, tablet: {
            flex: 'column',
            align: 'flex-start',
            justify: 'flex-start',
            gap: 8,
        }, children: [_jsx(Typography, { elementTheme: "subtitle1", children: title }), action] }));
};
const Footer = ({ actions }) => {
    return (_jsx(Div, { flex: "row", align: "center", justify: "flex-end", gap: 8, tablet: { flex: 'column-reverse', align: 'center' }, children: actions }));
};
const ModalWithHeaderFooter = (_a) => {
    var modalProps = __rest(_a, []);
    return _jsx(Modal, Object.assign({}, modalProps));
};
ModalWithHeaderFooter.Header = Header;
ModalWithHeaderFooter.Footer = Footer;
export default ModalWithHeaderFooter;
