import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import Image from 'antd/es/image';
import { useState } from 'react';
import ProfileBlocks from '../../../../features/enums/profile-blocks';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import { AssetOverlay, EditButton } from './styles';
const ProfileBanner = ({ isEditable = false, banner, name, toggleEditModal, }) => {
    const theme = useTheme();
    const [bannerFailed, setbannerFailed] = useState(false);
    const handleEdit = () => !!toggleEditModal &&
        toggleEditModal({ open: true, type: ProfileBlocks.Banner });
    return (_jsx(_Fragment, { children: _jsxs(Div, { backgroundColor: theme.colors.grey_5, flex: "column", align: "center", justify: "center", style: { position: 'relative', aspectRatio: `1120 / 240` }, xl: { style: { aspectRatio: `326 / 119` } }, overflow: "hidden", children: [banner && !bannerFailed ? (_jsx(Image, { style: { objectFit: 'cover', objectPosition: 'center' }, onError: () => setbannerFailed(true), src: banner, alt: name, preview: false, width: '100%', height: '100%' })) : (_jsx(Div, { backgroundColor: theme.colors.gradient, style: {
                        height: 100,
                        width: '100%',
                        position: 'absolute',
                        bottom: 0,
                    }, children: '' })), isEditable && (_jsx(AssetOverlay, { onClick: handleEdit, "$type": "banner", children: _jsx(EditButton, { children: _jsx(Edit, { size: 18 }) }) }))] }) }));
};
export default ProfileBanner;
