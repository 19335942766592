import Div from '@hellodarwin/core/lib/components/common/div';
import HdTag from '@hellodarwin/core/lib/components/common/hd-tag';
import Loading from '@hellodarwin/core/lib/components/loading';
import { NewTag, TagType } from '@hellodarwin/core/lib/features/entities';
import theme from '@hellodarwin/core/lib/theme';
import Close from '@hellodarwin/icons/dist/icons/Close';
import Search from '@hellodarwin/icons/dist/icons/Search';
import Button from 'antd/es/button';
import Input from 'antd/es/input/Input';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useAdminApi } from '../../features/api/use-admin-api';
import useDebounce from '../../hooks/use-debounce';

type TagSearchProps = {
  handleSubmit: (tag: NewTag) => void;
  locale: string;
};

const SEARCH_DELAY_MS = 500;
const DEFAULT_LIMIT = 10;

const TagsSearch = ({ handleSubmit, locale }: TagSearchProps) => {
  const api = useAdminApi();
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState<NewTag[] | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const debouncedQuery = useDebounce(searchTerm, SEARCH_DELAY_MS);

  const getTagColor = (category: TagType) => {
    let baseColor;
    switch (category) {
      case TagType.Goal:
        baseColor = theme.colors.orange_1;
        break;
      case TagType.ExpenseCategory:
        baseColor = theme.colors.green_1;
        break;
      case TagType.Expense:
        baseColor = theme.colors.blue_1;
        break;
      default:
        baseColor = theme.colors.purple_3;
        break;
    }
    return baseColor;
  };

  const renderTagHierarchy = (tag: NewTag): ReactNode => {
    const hierarchy = [];
    let currentTag: NewTag | undefined = tag;
    let index = 0;

    while (currentTag) {
      hierarchy.unshift(
        <HdTag
          key={`tag-${index}`}
          text={currentTag.content}
          size={'small'}
          color={getTagColor(currentTag.tag_type)}
        />,
      );
      currentTag = currentTag.parent;
      index++;
    }

    return hierarchy;
  };

  const onSearch = (query: string) => {
    if (query === '') return;
    (async () => {
      setIsSearching(true);
      try {
        const response = await api.searchAllTags(
          debouncedQuery,
          locale,
          DEFAULT_LIMIT,
        );
        if (!response || !response.length) {
          setResults(undefined);
        } else {
          setResults(response);
        }
      } catch (e: any) {
        console.error(e);
      } finally {
        setIsSearching(false);
      }
    })();
  };

  const clearSearch = () => {
    setSearchTerm('');
    setResults(undefined);
  };

  const handleResultClick = (tag: NewTag) => {
    handleSubmit(tag);
    clearSearch();
  };

  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DELAY_MS);

  useEffect(() => {
    onSearch(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target as Node)
      ) {
        clearSearch();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchContainerRef]);

  return (
    <div ref={searchContainerRef} style={{ position: 'relative' }}>
      <Div flex="row" gap={16}>
        <Div
          backgroundColor={theme.colors.purple_5}
          borderColor={theme.colors.purple_3}
          borderRadius={4}
          style={{ padding: '0.2rem 1rem' }}
          flex="row"
          align="center"
          justify="space-between"
        >
          <Search width={14} />
          <Input
            value={searchTerm}
            placeholder="Search... (tag)"
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              outline: 'none',
              border: 'none',
              background: theme.colors.transparent,
              width: '100%',
              boxShadow: 'none',
              height: 35,
            }}
          />
          <Div fitContent>
            {isSearching ? (
              <Loading size={'small'} />
            ) : (
              searchTerm !== '' && (
                <Div onClick={clearSearch} style={{ cursor: 'pointer' }}>
                  <Close width={14} />
                </Div>
              )
            )}
          </Div>
        </Div>
      </Div>
      {!!results && (
        <Div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            zIndex: 1000,
            backgroundColor: theme.colors.white_1,
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            padding: '1rem',
            borderRadius: '4px',
          }}
        >
          {results.map((t) => (
            <Div key={t.tag_id} style={{ width: '100%', marginBottom: 8 }}>
              <Button
                onClick={() => handleResultClick(t)}
                style={{
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '4px',
                  whiteSpace: 'normal',
                  lineHeight: 'normal',
                  height: 'auto',
                }}
              >
                {renderTagHierarchy(t)}
              </Button>
            </Div>
          ))}
        </Div>
      )}
    </div>
  );
};

export default TagsSearch;
