import Div from '@hellodarwin/core/lib/components/common/div';
import Modal from '@hellodarwin/core/lib/components/common/hd-modal';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../app';
import { selectCompanyById } from '../../../../features/api/slices/companies-slice';
import AdminFilesList from '../../../files/files-list';
import { GinBlockComponentProps } from '../../../gins/gin-single/gin-block';
import GameplanWarningModal from './game-plan-warning-modal';

const CompanyGamePlansBlock: React.FC<GinBlockComponentProps> = ({
  isEditing,
  handleEditing,
  entityId,
  canGenerateGameplan,
}) => {
  useEffect(() => {
    if (!!isEditing) {
      if (canGenerateGameplan) {
        window.open(`/companies/${entityId}/game-plan`);
      } else {
        setWarningModal(true);
      }
      handleEditing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, entityId]);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const company = useAppSelector((state) => selectCompanyById(state, entityId));

  return (
    <>
      <Div align="center">
        <AdminFilesList
          owner={{
            owner_id: entityId,
            owner_type: 'company',
            owner_name: company.name,
          }}
          vaultPath={'/'}
          isGameplanFileList={true}
        />
      </Div>
      <Modal
        open={warningModal}
        handleCancel={() => {
          setWarningModal(false);
        }}
      >
        <GameplanWarningModal />
      </Modal>
    </>
  );
};

export default CompanyGamePlansBlock;
