import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import React, { useMemo, useState } from 'react';
import { TagType } from '../../../features/entities';
import { useTranslations } from '../../../features/providers/translations-provider';
import { useTheme } from '../../../plugins/styled';
import Collapse from '../Collapse';
import Div from '../div';
import HdRecursiveTag from '../hd-recursive-tag';
import Typography from '../typography';
const SeeMoreTags = (props) => {
    const { limit, content, size, position } = props;
    const theme = useTheme();
    const { t } = useTranslations();
    const [highlightedTags, setHighlightedTags] = useState(new Set());
    const contentLength = content.length;
    const remainingCount = contentLength - limit;
    const showMore = useMemo(() => remainingCount <= 1
        ? t('actions|seeCountMoreSingular', {
            count: remainingCount,
        })
        : t('actions|seeCountMore', { count: remainingCount }), [remainingCount, t]);
    const showLess = t('actions|seeLess');
    const getTagColor = (category) => {
        let baseColor;
        switch (category) {
            case TagType.Goal:
                baseColor = theme.colors.orange_1;
                break;
            case TagType.ExpenseCategory:
                baseColor = theme.colors.green_1;
                break;
            case TagType.Expense:
                baseColor = theme.colors.blue_1;
                break;
            default:
                baseColor = theme.colors.purple_3;
                break;
        }
        return baseColor;
    };
    const expandIcon = ({ isActive }) => (_jsxs(Typography, { nowrap: true, elementTheme: "body2", color: theme.colors.grey_3, children: [_jsx("span", { style: { marginRight: 8 }, children: isActive ? showLess : showMore }), _jsx(TabArrow, { width: 8, height: 8, down: !isActive })] }));
    const findParentTags = (tagId) => {
        const highlighted = new Set();
        const findParent = (id) => {
            var _a;
            const parent = (_a = content.find((tag) => tag.tag_id === id)) === null || _a === void 0 ? void 0 : _a.parent_id;
            if (parent) {
                highlighted.add(parent);
                findParent(parent);
            }
        };
        findParent(tagId);
        return highlighted;
    };
    const handleMouseEnter = (tagId) => {
        const parents = findParentTags(tagId);
        setHighlightedTags(new Set([tagId, ...Array.from(parents)]));
    };
    const handleMouseLeave = () => {
        setHighlightedTags(new Set());
    };
    const collapseItems = useMemo(() => [
        {
            handleClickButtonOnly: true,
            id: 'see-more-header',
            title: (_jsx(Div, { flex: "row", gap: 8, style: { paddingBottom: 8 }, align: "center", wrap: "wrap", children: React.Children.toArray(content
                    .slice(0, limit)
                    .map((tag, index) => (_jsx(HdRecursiveTag, { text: tag.content, size: size, color: getTagColor(tag.tag_type), onMouseEnter: () => handleMouseEnter(tag.tag_id), onMouseLeave: handleMouseLeave, isDimmed: highlightedTags.size > 0 &&
                        !highlightedTags.has(tag.tag_id), isHovered: highlightedTags.has(tag.tag_id), onClose: tag.onClose, deleteConfirmation: true }, `tag-${index}`)))) })),
            children: contentLength > limit ? (_jsx(Div, { flex: "row", gap: 8, style: { paddingBottom: 8 }, align: "center", wrap: "wrap", children: React.Children.toArray(content
                    .slice(limit)
                    .map((tag, index) => (_jsx(HdRecursiveTag, { text: tag.content, size: size, color: getTagColor(tag.tag_type), onMouseEnter: () => handleMouseEnter(tag.tag_id), onMouseLeave: handleMouseLeave, isDimmed: highlightedTags.size > 0 &&
                        !highlightedTags.has(tag.tag_id), isHovered: highlightedTags.has(tag.tag_id), onClose: tag.onClose, deleteConfirmation: true }, `tag-${index + limit}`)))) })) : undefined,
        },
    ], [
        content,
        limit,
        contentLength,
        highlightedTags,
        size,
        handleMouseEnter,
        handleMouseLeave,
    ]);
    return (_jsx(Collapse, { expandIconPosition: position || 'bottom', ExpandIcon: expandIcon, ghost: true, noPadding: true, items: collapseItems }));
};
export default SeeMoreTags;
