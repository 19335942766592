export const GrantInitialState = {
    grant_id: '',
};
export const GrantProviderInitialState = {
    grant_provider_id: '',
};
export const GrantValidationInitialState = {
    grantValidationId: '',
    grantId: '',
    adminId: '',
    type: 'basic_info',
    isValidated: false,
};
export var PromptType;
(function (PromptType) {
    PromptType["Grant"] = "grant";
    PromptType["Program"] = "program";
})(PromptType || (PromptType = {}));
