'use client';
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Suspense, lazy } from 'react';
import { H1, H2, H3, H4, H5, H6 } from './styles';
import useTypographyProps from './utils/use-typography-props';
const CopyButton = lazy(() => import('./copyButton'));
const getHeading = (level) => {
    switch (level) {
        case 1:
            return H1;
        case 2:
            return H2;
        case 3:
            return H3;
        case 4:
            return H4;
        case 5:
            return H5;
        case 6:
            return H6;
    }
};
const Content = (props) => {
    const { level } = props, restProps = __rest(props, ["level"]);
    let headingElementTheme = !!props.elementTheme
        ? props.elementTheme
        : `h${level}`;
    const { currentProps, currentStyle, defaultProps } = useTypographyProps(Object.assign(Object.assign({}, restProps), { elementTheme: headingElementTheme }));
    const { className, onBlur, onClick, id, children } = defaultProps;
    const classes = classNames(className, 'hd-heading', {
        [`${currentProps.$size}`]: !!currentProps.$size,
    });
    const textProps = Object.assign(Object.assign({}, currentProps), { className: classes, style: currentStyle, onClick,
        onBlur,
        id,
        children });
    const Title = getHeading(level);
    return _jsx(Title, Object.assign({}, textProps));
};
const Heading = (props) => {
    const { copyable } = props;
    return !!copyable ? (_jsxs("div", { style: {
            display: 'flex',
            gap: 8,
            alignItems: 'center',
        }, children: [_jsx(Content, Object.assign({}, props)), !!copyable ? (_jsx(Suspense, { fallback: '', children: _jsx(CopyButton, { copyable: copyable }) })) : undefined] })) : (_jsx(Content, Object.assign({}, props)));
};
export default Heading;
