import Language from '../enums/language';
import SingleLanguage from '../enums/single-language';
export const AdminRoles = [
    'SuperAdmin',
    'Admin',
    'Viewer',
    'Editor',
    'Unverified',
];
export const ProfileInitialState = {
    contact_id: '',
    contact_name: '',
    contact_email: '',
    contact_phone: '',
    logo: '',
    contact_preferred_language: SingleLanguage.English,
    project_language: Language.Any,
    profile_language: SingleLanguage.English,
    company_id: '',
    company_name: '',
    company_address: '',
    company_email: '',
    company_website: '',
    company_city: '',
    company_postal_code: '',
    company_country: '',
    company_province: '',
    company_size: '',
    company_founded_at: null,
    company_description: '',
    terms_and_conditions: false,
};
export var AdminTeams;
(function (AdminTeams) {
    AdminTeams["ProductionGrants"] = "44826381";
    AdminTeams["TransformationNumerique"] = "46033032";
    AdminTeams["AcquisitionClients"] = "16355594";
    AdminTeams["Developpement"] = "42163043";
    AdminTeams["FinanceAdmin"] = "42377641";
    AdminTeams["Marketplace"] = "13811587";
    AdminTeams["SR"] = "42377745";
})(AdminTeams || (AdminTeams = {}));
export const adminTeamsMap = {
    [AdminTeams.ProductionGrants]: 'Production - Grants',
    [AdminTeams.TransformationNumerique]: 'Transformation Numérique',
    [AdminTeams.AcquisitionClients]: 'Acquisition Clients',
    [AdminTeams.Developpement]: 'Développement',
    [AdminTeams.FinanceAdmin]: 'Finance / Admin',
    [AdminTeams.Marketplace]: 'Marketplace',
    [AdminTeams.SR]: 'SR',
};
export const AdminCallersTeams = [
    AdminTeams.AcquisitionClients,
    AdminTeams.Marketplace,
    AdminTeams.ProductionGrants,
    AdminTeams.TransformationNumerique,
];
export const AdminProfileInitialState = {
    first_name: '',
    last_name: '',
    auth_id: '',
    auth_id_match: false,
    google_auth_id: '',
    email: '',
    phone: '',
    admin_id: '',
    last_login_at: '',
    roles: 'Admin',
    picture: '',
    title: '',
    is_verified_admin: false,
    preferred_language: 'en',
};
export const newClientAnalyticsUser = (profile) => {
    const user = {
        user_type: 'Client',
        entity_id: `${profile.contact_id}`,
        company_id: profile.company_id,
        company_name: profile.company_name,
        company_email: profile.company_email,
        company_size: profile.company_size,
        city: profile.company_city,
    };
    return user;
};
