import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { DragDropContext, Draggable, } from 'react-beautiful-dnd';
import theme from '../../../theme';
import Drop from './drop';
const grid = 8;
const getItemStyle = (isDragging, theme, draggableStyle, itemStyle) => (Object.assign(Object.assign({ userSelect: 'none', padding: grid * 2, width: 'fit-content', background: isDragging ? theme.colors.purple_4 : undefined }, draggableStyle), itemStyle));
const DragDropSingleColumnContainer = ({ items, setItems, renderItem, style, direction = 'horizontal', itemStyle, onChange, }) => {
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const newList = reorder(items, result.source.index, result.destination.index);
        setItems(newList);
        onChange && onChange(newList);
    };
    return (_jsx(DragDropContext, { onDragEnd: onDragEnd, children: _jsx(Drop, { className: "column-content", droppableId: "all-columns", type: "column", direction: direction, children: React.Children.toArray(items.map((itm, index) => (_jsx(Draggable, { draggableId: `drop-item-${index}`, index: index, children: (provided, snapshot) => (_jsx("div", Object.assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { style: getItemStyle(snapshot.isDragging, theme, provided.draggableProps.style, itemStyle), children: renderItem(itm, index) }))) }, `drop-item-${index}`)))) }) }));
};
export default DragDropSingleColumnContainer;
