import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import FilterTagBar from '../tag-bar';
import AdvancedFiltersButton from './advanced-filters-button';
import IndustriesSelector from './industries-selector';
import FundingExplorerSearchBar from './search-bar';
const FilterHeader = (props) => {
    const { handleAdvancedFilters, advancedFiltersOpened } = props;
    const theme = useTheme();
    const isXl = useMediaQuery({ maxWidth: theme.breakpoints.l });
    if (isXl) {
        return (_jsxs(Div, { flex: "column", gap: 24, children: [_jsxs(Div, { flex: "column", gap: 12, children: [_jsx(FundingExplorerSearchBar, Object.assign({}, props)), _jsxs(Div, { flex: "row", gap: 12, tablet: { flex: 'column' }, children: [_jsx(IndustriesSelector, Object.assign({}, props)), _jsx(AdvancedFiltersButton, { handleAdvancedFilters: handleAdvancedFilters, advancedFiltersOpened: advancedFiltersOpened })] })] }), _jsx(FilterTagBar, Object.assign({}, props))] }));
    }
    else {
        return (_jsxs(Div, { flex: "column", gap: 8, tablet: { gap: 24 }, children: [_jsxs(Div, { flex: "row", gap: 8, children: [_jsx(IndustriesSelector, Object.assign({}, props)), _jsx(FundingExplorerSearchBar, Object.assign({}, props)), _jsx(AdvancedFiltersButton, { handleAdvancedFilters: handleAdvancedFilters, advancedFiltersOpened: advancedFiltersOpened })] }), _jsx(FilterTagBar, Object.assign({}, props))] }));
    }
};
export default FilterHeader;
