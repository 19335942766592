import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
const SavingBlock = ({ handleClose }) => {
    const theme = useTheme();
    return (_jsxs(Div, { backgroundColor: theme.colors.primary, flex: "column", style: { padding: '14px 32px' }, gap: 8, borderRadius: 4, children: [_jsx(Typography, { nowrap: true, textAlign: "center", color: theme.colors.white_1, elementTheme: "body2", children: "This shouldnt appear. This is for Kamel!" }), _jsxs(Div, { flex: "row", gap: 8, align: "center", justify: "center", children: [_jsx(Button, { size: "small", defaultStyle: theme.colors.white_1, onClick: handleClose, children: "No" }), _jsx(Button, { size: "small", defaultStyle: theme.colors.purple_3, children: "Yes" })] })] }));
};
export default SavingBlock;
