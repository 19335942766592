import { jsx as _jsx } from "react/jsx-runtime";
import Search from '@hellodarwin/icons/dist/icons/Search';
import Input from 'antd/es/input/Input';
import { useTheme } from 'styled-components';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import FormItem from '../../../../forms/form-layouts/form-item';
import usePrepareFundingExplorerURL from '../../hooks/prepare-url';
const FundingExplorerSearchBar = ({ filterform, fetch }) => {
    const theme = useTheme();
    const { t } = useTranslations();
    const prepareFundingExplorerURL = usePrepareFundingExplorerURL({
        filterform,
    });
    const handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const value = e.target.value;
            filterform.setFieldsValue({ textQuery: value });
            prepareFundingExplorerURL();
            !!fetch && (await fetch(filterform.getFieldsValue()));
        }
    };
    return (_jsx(FormItem, { name: "textQuery", style: { flex: 4, margin: 0 }, children: _jsx(Input, { placeholder: t(`funding_explorer|fundingExplorerTextQueryPlaceholder`), size: "large", style: {
                fontSize: 18,
                height: 45,
                padding: '0 12px',
            }, prefix: _jsx(Search, { size: 20, style: { marginRight: 8 }, color: theme.colors.grey_3 }), onKeyDown: handleKeyDown }) }));
};
export default FundingExplorerSearchBar;
