import { useTheme } from 'styled-components';
import { SpinContainer } from './styles';

export type SpinSize = 'extra-large' | 'large' | 'medium' | 'small';
export interface SpinProps {
  color?: string;
  size?: SpinSize;
}
const Spin = (props: SpinProps) => {
  const theme = useTheme();
  const { color = theme.colors.purple_1, size = 'medium' } = props;
  return (
    <SpinContainer $color={color} $size={size}>
      <div></div>
      <div></div>
      <div></div>
    </SpinContainer>
  );
};

export default Spin;
