import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useNavigate } from '../../../features/providers/location-provider';
import { ProjectsTableLevel3 } from '../main-project-table/styles';
const useExpandedGrantRowRender = ({ projectsListState, }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    return useMemo(() => {
        const { projectsColumns: { expandProgramColumns }, applicationsPath, } = projectsListState;
        const expandedGrantRowRender = (record) => {
            return (_jsx("div", { style: {
                    paddingInline: 24,
                    paddingBlock: 16,
                    background: theme.colors.grey_5,
                }, children: _jsx(ProjectsTableLevel3, { columns: expandProgramColumns.map((col, i) => i === 0 ? Object.assign(Object.assign({}, col), { width: 500 - 48 }) : col), dataSource: record.programs, pagination: false, scroll: { x: 'max-content' }, rowClassName: (_r, i) => (i % 2 ? 'odd' : 'even'), onRow: (record) => {
                        return {
                            onDoubleClick: () => {
                                navigate(`/${applicationsPath}/${record.program_id}`);
                            },
                        };
                    } }) }));
        };
        return expandedGrantRowRender;
    }, [projectsListState]);
};
export default useExpandedGrantRowRender;
