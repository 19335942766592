import ArrowOutline from '@hellodarwin/icons/dist/icons/ArrowOutline';
import Sider from 'antd/es/layout/Sider';
import { toHex } from '../../../features/helpers/convertToHex';
import styled from '../../../plugins/styled';
import theme from '../../../theme';
import { UserMenuButtonStyled } from '../header/user-menu/styles';
import { MenuItemColorsStyling, } from './menu-item/styles';
export const SocialIcon = styled.svg `
  cursor: pointer;
`;
export const SocialContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: ${({ $align }) => $align === 'right' ? 'flex-end' : 'flex-start'};
  width: ${(props) => (props.$isOpen ? '100%' : 'fit-content')};

  color: ${(props) => props.$app === 'provider'
    ? props.theme.colors.white_1
    : props.theme.colors.grey_2};
  ${SocialIcon} {
    color: ${(props) => props.$app === 'provider'
    ? props.theme.colors.white_1
    : props.theme.colors.grey_2};
    &:hover {
      color: ${(props) => props.$app === 'provider'
    ? props.theme.colors.purple_3
    : props.theme.colors.purple_1};
    }
  }
`;
export const Navigation = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  min-height: 100%;
  padding: ${(props) => (!props.$isOpen ? '32px 8px' : '32px 8px')};
  padding-top: 40px;
  border-right: 1px solid ${({ theme }) => theme.colors.grey_5};
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};
`;
export const MobileNavigationContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden auto;
  padding: 0;
  gap: 20px;
  height: 100%;

  .hd-navigation-footer {
    ${UserMenuButtonStyled} {
      padding: 0 16px;
    }
  }
`;
export const NavSider = styled(Sider) `
  z-index: 100;
  background-color: ${({ $app }) => $app === 'provider'
    ? theme.colors.grey_1
    : $app === 'admin'
        ? theme.colors.purple_5
        : theme.colors.white_1};
  .ant-layout-sider-trigger {
    position: absolute;
    top: 0;
    z-index: 1100;
    ${(props) => props.right
    ? 'right: -15px;'
    : props.left
        ? 'left: -15px;'
        : 'right: -15px;'}
    background-color: transparent !important;
    color: white;
    width: 30px !important;
    height: 100%;
    cursor: pointer;
  }
`;
export const BurgerBar = styled.div `
  width: 26px;
  height: 2px;
  border-radius: 12px;
`;
export const Burger = styled.div `
  background-color: ${({ theme, $app }) => ($app === 'provider' ? theme.colors.grey_4 : theme.colors.grey_3) +
    toHex(3)};
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: fit-content;
  aspect-ratio: 1;

  ${BurgerBar} {
    background-color: ${({ theme, $app }) => $app === 'provider' ? theme.colors.grey_4 : theme.colors.grey_2};
  }
  &:hover {
    background-color: ${({ theme, $app }) => ($app === 'provider' ? theme.colors.grey_4 : theme.colors.grey_3) +
    toHex(6)};
  }

  ${({ $isOpen }) => $isOpen
    ? `
    ${BurgerBar} {
    &:nth-child(1) {
       transform:rotate(45deg) translate(6px,5px) scale(1.02);
    }
    &:nth-child(2) {
    opacity:0;
    }
    &:nth-child(3) {
   
       transform:rotate(-45deg) translate(6px,-5px) scale(1.005);

    }
    }
    `
    : undefined}
`;
export const TriggerStyled = styled.div `
  border-radius: 99px;
  box-shadow: 0px 2px 4px 1px rgba(43, 43, 43, 0.15);

  z-index: 1000;
  position: sticky;
  top: 80px;
  width: fit-content;
  height: fit-content;
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
`;
export const ArrowOutlineStyled = styled(ArrowOutline) `
  color: #848484;
  .background {
    fill: #f5f5f5;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.white_1};
    .background {
      fill: ${({ theme }) => theme.colors.grey_4};
    }
  }
`;
export const LanguageSwitcherStyled = styled.div `
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  cursor: pointer;
  align-items: center;
  ${MenuItemColorsStyling}
`;
export const DesktopNavigationHeaderContainer = styled.div `
  width: ${({ $navCollapsed }) => ($navCollapsed ? 48 : 178)}px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;
