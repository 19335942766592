import { ProviderSearchResult } from '@hellodarwin/core/lib/features/entities';
import {
  getFormattedDate,
  getShortId,
} from '@hellodarwin/core/lib/features/helpers';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { usePagination } from '@hellodarwin/core/lib/features/providers/pagination-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { Tag } from 'antd';
import { PaginationConfig } from 'antd/es/pagination';
import { ColumnsType, TableProps } from 'antd/es/table';
import Tooltip from 'antd/es/tooltip';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStatusColor } from '../../../features/utils';
import Clipboard from '../../clipboard';
import { ProvidersTable } from './styles';

interface ProvidersListProps {
  providers: ProviderSearchResult[];
  total: number;
  isLoading?: boolean;
}

const ProvidersList = ({ providers, total, isLoading }: ProvidersListProps) => {
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();
  const navigate = useNavigate();

  const columns: TableProps<ProviderSearchResult>['columns'] = useMemo(() => {
    const columns: ColumnsType<ProviderSearchResult> = [
      {
        title: t('provider_page|providerId'),
        dataIndex: 'provider_id',
        key: 'provider_id',
        render: (v) => <Clipboard copy={v} title={getShortId(v)} />,
      },
      {
        title: t('provider_page|companyName'),
        dataIndex: 'company_name',
        key: 'company_name',
        sorter: {
          compare: (a, b) => a.company_name.localeCompare(b.company_name),
          multiple: 4,
        },
      },
      {
        title: t('provider_page|companyEmail'),
        dataIndex: 'company_email',
        key: 'company_email',
        sorter: {
          compare: (a, b) => a.company_email.localeCompare(b.company_email),
          multiple: 3,
        },
        ellipsis: {
          showTitle: false,
        },
        render: (v) => <Tooltip title={v}>{v}</Tooltip>,
      },
      {
        title: t('provider_page|status'),
        dataIndex: 'provider_status',
        key: 'provider_status',
        render: (v) => <Tag color={getStatusColor(v)}>{v}</Tag>,
        sorter: {
          compare: (a, b) => a.provider_status.localeCompare(b.provider_status),
          multiple: 1,
        },
      },
      {
        title: t('provider_page|subscription'),
        dataIndex: 'provider_subscription_status',
        key: 'provider_subscription_status',
        render: (v) => <Tag color={getStatusColor(v)}>{v}</Tag>,
        sorter: {
          compare: (a, b) =>
            a.provider_subscription_status.localeCompare(
              b.provider_subscription_status,
            ),
          multiple: 1,
        },
      },
      {
        title: t('provider_page|created_on'),
        dataIndex: 'provider_created_at',
        key: 'provider_created_at',
        sorter: {
          compare: (a, b) =>
            new Date(a.provider_created_at).getTime() -
            new Date(b.provider_created_at).getTime(),
        },
        render: (v) => <Tooltip title={v}>{getFormattedDate(v)}</Tooltip>,
        ellipsis: {
          showTitle: false,
        },
      },
    ];

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providers, selectedLocale]);

  const { goToPage, pageSize, activePage } = usePagination();

  const handlePageChange: PaginationConfig['onChange'] = (page, pageSize) => {
    goToPage(page, pageSize);
  };

  return (
    <ProvidersTable
      dataSource={providers}
      columns={columns}
      onRow={(record) => {
        return {
          onDoubleClick: () => {
            navigate(`/providers/${record.provider_id}`);
          },
        };
      }}
      loading={isLoading}
      scroll={{ x: 'max-content' }}
      pagination={{
        showSizeChanger: true,
        pageSize,
        current: activePage,
        total,
        align: 'center',
        onChange: handlePageChange,
      }}
    />
  );
};

export default ProvidersList;
