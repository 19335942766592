import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import parse from '../../../../../features/helpers/parse';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import { grantSingleParseOptions, GrantSingleSectionContent, } from '../../styles';
const AdmissibilitySectionTile = ({ label, content, }) => {
    if (!content)
        return _jsx(_Fragment, {});
    return (_jsxs(Div, { flex: "column", gap: 16, align: "flex-start", children: [_jsx("div", { style: { width: '100%', paddingTop: '24px' }, children: _jsx(Typography.Title, { level: 2, elementTheme: "h6", noMargin: true, children: label }) }), _jsx(GrantSingleSectionContent, { children: parse(content, grantSingleParseOptions) })] }));
};
export default AdmissibilitySectionTile;
