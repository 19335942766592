import { gql } from '../../../plugins/apollo';
export const TRANSLATIONS_QUERY_BY_FILES = gql `
  query GetTranslations($locale: String!, $files: [String!]) {
    translationFileCollection(
      where: { sys: { firstPublishedAt_exists: true }, key_in: $files }
      locale: $locale
    ) {
      items {
        key
        translationsFieldsCollection(
          where: { sys: { firstPublishedAt_exists: true } }
        ) {
          items {
            key
            value
          }
        }
      }
    }
  }
`;
