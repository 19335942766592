var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HdLogo from '@hellodarwin/icons/dist/icons/HdLogo';
import Modal from '..';
import Div from '../../div';
import { useTheme } from 'styled-components';
const ModalLayoutWithHdLogo = (_a) => {
    var _b;
    var { children } = _a, modalProps = __rest(_a, ["children"]);
    const theme = useTheme();
    const logoContainerSize = 90;
    const logoSize = logoContainerSize / 2;
    return (_jsx(Modal, Object.assign({}, modalProps, { style: {
            top: 0,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        }, styles: Object.assign(Object.assign({}, modalProps === null || modalProps === void 0 ? void 0 : modalProps.styles), { body: {
                padding: 24,
                overflow: 'auto',
                height: '100%',
            }, content: Object.assign({ padding: `${logoContainerSize / 2}px 0 0 0`, overflow: 'visible', height: '100%', maxHeight: `calc(95vh - ${logoContainerSize}px)` }, (_b = modalProps === null || modalProps === void 0 ? void 0 : modalProps.styles) === null || _b === void 0 ? void 0 : _b.content) }), children: _jsxs(Div, { className: 'hd-modal-with-hd-logo', flex: 'column', align: 'center', overflow: "hidden", children: [_jsx(Div, { flex: 'row', align: 'center', justify: 'center', backgroundColor: theme.colors.primary, style: {
                        width: logoContainerSize,
                        height: logoContainerSize,
                        borderRadius: 99,
                        top: 0,
                        transform: `translateY(-${logoContainerSize / 2}px)`,
                        position: 'absolute',
                        border: `1px solid ${theme.colors.primary}`,
                    }, children: _jsx(HdLogo, { width: logoSize, height: logoSize, backgroundType: "dark", color: "b/w", type: "tertiary" }) }), children] }) })));
};
export default ModalLayoutWithHdLogo;
