import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Checkbox from 'antd/es/checkbox';
import TextArea from 'antd/es/input/TextArea';
import Radio from 'antd/es/radio';
import Select from 'antd/es/select';
import { useTheme } from 'styled-components';
import useMediaQuery from '../../../../features/helpers/use-media-query';
import Div from '../../../common/div';
import FormItem from '../../../forms/form-layouts/form-item';
import FormLabel from '../../../forms/form-layouts/form-label';
import validateAnyType from '../../../forms/utils/validate-any-type';
import ReviewRatings from '../../ratings';
const ReviewStepCard = ({ extra, label, name, type, error, placeholder, options, }) => {
    const theme = useTheme();
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    return (_jsx("div", { children: type === 'rating' ? (_jsxs(Div, { flex: "row", tablet: { flex: 'column' }, gap: isTablet ? 20 : 54, align: "center", children: [_jsx("div", { style: { flex: 1 }, children: _jsx(FormLabel, { label: label, extra: extra }) }), _jsx("div", { style: { width: isTablet ? 45 * 5 : 52 * 5 }, children: _jsx(FormItem, { style: {
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            overflow: 'hidden',
                            justifyContent: 'flex-end',
                        }, name: name, rules: [
                            () => ({
                                validator: validateAnyType({
                                    message: error !== null && error !== void 0 ? error : '',
                                    isRequired: true,
                                }),
                            }),
                        ], children: _jsx(ReviewRatings, { size: "medium", defaultValue: 0 }) }) })] })) : (_jsx(FormItem, { name: name, label: _jsx(FormLabel, { label: label, extra: extra }), rules: [
                () => ({
                    validator: validateAnyType({
                        message: error !== null && error !== void 0 ? error : '',
                        isRequired: true,
                    }),
                }),
            ], children: type === 'select' ? (_jsx(Select, { style: { width: '100%' }, placeholder: placeholder, options: options })) : type === 'textarea' ? (_jsx(TextArea, { placeholder: placeholder, style: { minHeight: 100, width: '100%' } })) : type === 'radio' ? (_jsx(Radio.Group, { style: {
                    display: isTablet ? 'flex' : '',
                    justifyContent: isTablet ? 'center' : '',
                }, options: options })) : (type === 'checkbox' && (_jsx(Checkbox.Group, { style: {
                    display: isTablet ? 'flex' : '',
                    justifyContent: isTablet ? 'center' : '',
                }, options: options }))) })) }));
};
export default ReviewStepCard;
