import { Page, View } from '@react-pdf/renderer';
import React from 'react';
import { CompanyGamePlanSummary } from 'src/@types/company-types';
import Group4Outlined from '../../../components/background/group4-outlined';
import CardWithIcon from '../../../components/cards/tile-with-description-and-icon';
import CheckIcon from '../../../components/common/icons/check';
import ProjectIcon from '../../../components/common/icons/project';
import PageHeader from '../../../components/header';
import { PdfPagesStyles } from '../../../styles/pages';
import { createTemplateStyle } from '../../../styles/templates';
import theme from '../../../theme';
import { GamePlanTranslations } from '../types';

export interface GamePlanSummaryProps {
  summary: CompanyGamePlanSummary;
  translations: GamePlanTranslations;
}

const isMeaningfulContent = (content?: string): boolean => {
  if (!content) return false;
  const trimmedContent = content.trim();
  return (
    trimmedContent.length > 0 &&
    trimmedContent !== '<p><br></p>' &&
    !/^<p>\s*<\/p>$/.test(trimmedContent)
  );
};

const GamePlanSummary: React.FC<GamePlanSummaryProps> = ({
  summary,
  translations,
}) => {
  const hasClientTodo = isMeaningfulContent(summary.todo_client);
  const hasHdTodo = isMeaningfulContent(summary.todo_hd);
  const hasSummary = isMeaningfulContent(summary.summary);
  const hasBothTodos = hasClientTodo && hasHdTodo;
  const hasAnyTodo = hasClientTodo || hasHdTodo;

  return (
    <Page size="A4" orientation="landscape" style={PdfPagesStyles.pageLevel2}>
      <View>
        <Group4Outlined
          width={679}
          height={948}
          color={theme.colors.beige_1}
          top={-68}
          margin={-354}
          align="left"
        />

        <PageHeader
          title={translations.summaryTitle}
          textAlign="center"
          elementTheme="h5"
        />

        <View
          style={createTemplateStyle({
            type: 'center',
            withFooter: false,
            withHeader: { headerElementTheme: 'h6' },
          })}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
              gap: (hasAnyTodo && hasSummary) || hasBothTodos ? 16 : 0,
            }}
          >
            {hasSummary && (
              <View style={{ flex: 1 }}>
                <CardWithIcon
                  icon={{
                    Icon: ProjectIcon,
                    backgroundColor: theme.colors.yellow_4,
                  }}
                  size={hasAnyTodo ? 'medium' : 'xl'}
                  direction="horizontal"
                  content={summary.summary}
                  textAlign="left"
                  style={{ flex: 1 }}
                />
              </View>
            )}

            {hasAnyTodo && (
              <View
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: hasSummary ? 'row' : 'column',
                  gap: hasBothTodos ? 16 : 0,
                }}
              >
                {hasClientTodo && (
                  <View style={{ flex: 1 }}>
                    <CardWithIcon
                      icon={{
                        Icon: CheckIcon,
                        backgroundColor: theme.colors.yellow_4,
                      }}
                      size={hasSummary ? 'medium' : 'small'}
                      direction="horizontal"
                      label={translations.todoClient}
                      content={summary.todo_client}
                      spacing={8}
                      textAlign="left"
                      style={{ flex: 1 }}
                    />
                  </View>
                )}

                {hasHdTodo && (
                  <View style={{ flex: 1 }}>
                    <CardWithIcon
                      icon={{
                        Icon: CheckIcon,
                        backgroundColor: theme.colors.yellow_4,
                      }}
                      size={hasSummary ? 'medium' : 'small'}
                      direction="horizontal"
                      label={translations.todoHd}
                      content={summary.todo_hd}
                      spacing={8}
                      textAlign="left"
                      style={{ flex: 1 }}
                    />
                  </View>
                )}
              </View>
            )}
          </View>
        </View>
      </View>
    </Page>
  );
};

export default GamePlanSummary;
