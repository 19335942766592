'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import { FormLabelContainer } from './styles';
const Label = (props) => {
    const { label, extra, color, onClick, actions, style, styles } = props;
    const theme = useTheme();
    return (_jsxs(FormLabelContainer, { "$withExtra": !!extra, onClick: !actions ? onClick : undefined, style: Object.assign(Object.assign({ width: '100%' }, style), styles === null || styles === void 0 ? void 0 : styles.container), children: [_jsx(Typography, { elementTheme: "body2", className: "hd-form-label", color: color, style: Object.assign({ width: '100%' }, styles === null || styles === void 0 ? void 0 : styles.label), children: label }), !!extra && (_jsx(Typography, { elementTheme: "body3", className: "hd-form-extra", color: color !== null && color !== void 0 ? color : theme.colors.grey_2, style: Object.assign({ width: '100%' }, styles === null || styles === void 0 ? void 0 : styles.extra), children: extra }))] }));
};
const FormLabel = (props) => {
    const { actions, onClick } = props;
    return !!actions ? (_jsxs(Div, { flex: "row", gap: 8, align: "center", justify: "space-between", style: { width: '100%' }, onClick: onClick, children: [_jsx(Label, Object.assign({}, props)), actions] })) : (_jsx(Label, Object.assign({}, props)));
};
export default FormLabel;
