import { ProfileEditModalFunctions } from '@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-edit-modal';
import ProfileEditionProvider from '@hellodarwin/core/lib/features/providers/profile-edition-provider';
import { PropsWithChildren, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../app';
import {
  createPortfolio,
  deleteBanner,
  deleteLogo,
  deletePortfolio,
  deleteVideos,
  editTeamMember,
  editTeamMemberOrder,
  fetchProviderById,
  removeTeamMember,
  selectProvidersLoading,
  selectSelectedProvider,
  submitTeamMember,
  updateBanner,
  updateCompany,
  updateLogo,
  updatePortfolio,
  updateVideos,
} from '../api/slices/providers-slice';
import { useAdminApi } from '../api/use-admin-api';

interface PartnerProfileEditionProviderProps extends PropsWithChildren {}

const PartnerProfileEditionProvider: React.FC<
  PartnerProfileEditionProviderProps
> = ({ children }) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const provider = useAppSelector(selectSelectedProvider);

  const isLoading = useAppSelector(selectProvidersLoading);

  const functions: ProfileEditModalFunctions = useMemo(
    () => ({
      isLoading: isLoading,
      fetchProfile: () =>
        dispatch(fetchProviderById({ api, providerId: provider.provider_id })),
      updateProfile: ({ data }) =>
        dispatch(
          updateCompany({
            api,
            request: data,
            company_id: provider.company_id,
          }),
        ),
      submitPortfolio: ({ data }) =>
        dispatch(
          createPortfolio({ api, data, providerId: provider.provider_id }),
        ),
      updatePortfolio: ({ data }) =>
        dispatch(
          updatePortfolio({ api, data, providerId: provider.provider_id }),
        ),

      removePortfolio: ({ portfolioID }) =>
        dispatch(deletePortfolio({ api, portfolio_id: portfolioID })),
      updateBanner: ({ companyId, data }) =>
        dispatch(updateBanner({ api, data, companyId })),
      updateLogo: ({ companyId, data }) =>
        dispatch(updateLogo({ api, data, companyId })),
      updateVideos: ({ companyId, data }) =>
        dispatch(updateVideos({ api, data, companyId })),
      submitTeamMember: ({ data }) =>
        dispatch(
          submitTeamMember({ api, data, providerId: provider.provider_id }),
        ),
      editTeamMember: ({ data, teamMemberId }) =>
        dispatch(
          editTeamMember({
            api,
            data,
            teamMemberId,
            providerId: provider.provider_id,
          }),
        ),
      removeTeamMember: ({ teamMemberID }) =>
        dispatch(
          removeTeamMember({
            api,
            teamMemberID,
            providerId: provider.provider_id,
          }),
        ),
      deleteBanner: ({ companyId }) =>
        dispatch(deleteBanner({ api, companyId })),
      deleteLogo: ({ companyId }) => dispatch(deleteLogo({ api, companyId })),
      deleteVideos: ({ companyId }) =>
        dispatch(deleteVideos({ api, companyId })),
      updateTeamMemberOrder: ({ teamMembers }) =>
        dispatch(editTeamMemberOrder({ api, teamMembers })),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [provider, isLoading],
  );

  return (
    <ProfileEditionProvider functions={functions}>
      {children}
    </ProfileEditionProvider>
  );
};

export default PartnerProfileEditionProvider;
