'use client';
const createButtonTheme = (backgroundColor, borderColor, textColor, backgroundColorHover, borderColorHover, textColorHover) => ({
    backgroundColor,
    borderColor,
    textColor,
    backgroundColorHover,
    borderColorHover,
    textColorHover,
});
const getButtonTheme = (theme, color) => {
    const { colors } = theme;
    const buttonThemes = {
        [colors.purple_1]: createButtonTheme(colors.purple_1, colors.purple_1, colors.white_1, colors.purple_2, colors.purple_2, colors.white_1),
        [colors.purple_2]: createButtonTheme(colors.purple_2, colors.purple_2, colors.white_1, colors.purple_3, colors.purple_3, colors.white_1),
        [colors.purple_3]: createButtonTheme(colors.purple_3, colors.purple_3, colors.white_1, colors.purple_2, colors.purple_2, colors.white_1),
        [colors.purple_4]: createButtonTheme(colors.purple_4, colors.purple_4, colors.purple_1, colors.purple_2, colors.purple_2, colors.white_1),
        [colors.purple_5]: createButtonTheme(colors.purple_5, colors.purple_1, colors.purple_1, colors.purple_3, colors.purple_1, colors.purple_1),
        [colors.green_1]: createButtonTheme(colors.green_1, colors.green_1, colors.grey_1, colors.green_2, colors.green_2, colors.grey_1),
        [colors.green_2]: createButtonTheme(colors.green_2, colors.green_2, colors.grey_1, colors.green_3, colors.green_3, colors.grey_1),
        [colors.green_3]: createButtonTheme(colors.green_3, colors.green_3, colors.grey_1, colors.green_4, colors.green_4, colors.grey_1),
        [colors.green_4]: createButtonTheme(colors.green_4, colors.green_4, colors.grey_1, colors.green_2, colors.green_2, colors.grey_1),
        [colors.yellow_1]: createButtonTheme(colors.yellow_1, colors.yellow_1, colors.grey_1, colors.yellow_2, colors.yellow_2, colors.grey_1),
        [colors.yellow_2]: createButtonTheme(colors.yellow_2, colors.yellow_2, colors.grey_1, colors.yellow_3, colors.yellow_3, colors.grey_1),
        [colors.yellow_4]: createButtonTheme(colors.yellow_4, colors.yellow_4, colors.grey_1, colors.yellow_2, colors.yellow_2, colors.grey_1),
        [colors.grey_1]: createButtonTheme(colors.grey_1, colors.grey_1, colors.white_1, colors.white_1, colors.grey_1, colors.grey_1),
        [colors.grey_2]: createButtonTheme(colors.grey_2, colors.grey_2, colors.white_1, colors.grey_3, colors.grey_3, colors.white_1),
        [colors.grey_3]: createButtonTheme(colors.grey_3, colors.grey_3, colors.white_1, colors.grey_4, colors.grey_4, colors.white_1),
        [colors.grey_4]: createButtonTheme(colors.grey_4, colors.grey_4, colors.white_1, colors.grey_3, colors.grey_3, colors.white_1),
        [colors.grey_5]: createButtonTheme(colors.grey_5, colors.grey_5, colors.grey_1, colors.grey_4, colors.grey_4, colors.white_1),
        [colors.white_1]: createButtonTheme(colors.white_1, colors.grey_1, colors.grey_1, colors.grey_1, colors.grey_1, colors.white_1),
        [colors.transparent]: createButtonTheme(colors.white_1, colors.grey_1, colors.grey_1, colors.grey_1, colors.grey_1, colors.white_1),
        [colors.red_1]: createButtonTheme(colors.red_1, colors.red_1, colors.white_1, colors.red_5, colors.red_1, colors.error),
    };
    return buttonThemes[color !== null && color !== void 0 ? color : colors.purple_1];
};
export default getButtonTheme;
