import styled from 'styled-components';
export const GrantCardContainer = styled.div `
  display: block;
  background: ${({ theme }) => theme.colors.beige_3};
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 8px 8px 16px 16px;
  overflow: hidden;

  &:hover {
    box-shadow: ${({ theme }) => theme.appearance.box_shadow_hover};
    transform: translateY(-4px);
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
  }

  .box {
    .content {
      font-size: ${({ theme }) => theme.fonts.body2};
      line-height: ${({ theme }) => theme.fontsLineHeights.body2};
    }
  }
`;
export const GrantCardBodyContainer = styled.div `
  border-top: 1px solid ${({ theme }) => theme.colors.grey_4};
`;
export const GrantCardBodyContent = styled.div `
  display: flex;
  max-width: 90%;
  flex-wrap: wrap;
  margin: auto;
  & > div {
    flex: 1;
    border-right: 1px solid ${({ theme }) => theme.colors.grey_4};
    padding: 16px 46px;
    padding-bottom: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:last-child {
      border: none;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    max-width: 100%;
    & > div {
      flex: 1 0 50%;
      border: none;

      &:first-child {
        border-right: 1px solid ${({ theme }) => theme.colors.grey_4};
      }
      &:last-child {
        flex: 0 1 100%;
        border-top: 1px solid ${({ theme }) => theme.colors.grey_4};
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column;
    margin: 0;
    max-width: 100%;
    width: 100%;

    & > div {
      border-right: none;
      padding: 16px 24px;

      border-bottom: 1px solid ${({ theme }) => theme.colors.grey_4};
    }
  }
`;
export const GrantCardHeaderContainer = styled.div `
  padding: 24px 32px 20px 17px;
  display: flex;
  gap: 32px;
  background: ${({ theme }) => theme.colors.beige_2};
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const GrantCardHeaderInfos = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    align-items: center;
    text-align: center;
    .description {
      text-align: center;
    }
  }
`;
export const GrantCardHeading = styled.div `
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
`;
export const GrantCardInfosBoxContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const GrantCardInfosBoxBody = styled.div `
  padding-inline-start: calc(14px + 10px);
  width: 100%;
  ul {
    margin: 0;
    padding-inline-start: 0;
  }
`;
