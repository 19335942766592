import Select from 'antd/es/select';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  fetchAdmins,
  selectAdminIsLoading,
  selectAllAdmins,
} from '../../../features/api/slices/admins-slice';
import { selectAuthentifiedUser } from '../../../features/api/slices/global-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';

interface SelectAdminProps {
  handleSubmit: (adminId: string) => void;
  initialAdminId?: string;
}

const SelectAdmin: React.FC<SelectAdminProps> = ({
  handleSubmit,
  initialAdminId,
}) => {
  const admins = useAppSelector(selectAllAdmins);
  const user = useAppSelector(selectAuthentifiedUser);
  const isAdminsLoading = useAppSelector(selectAdminIsLoading);
  const [selectedAdmin, setSelectedAdmin] = useState<string | undefined>(
    initialAdminId ?? undefined,
  );
  const dispatch = useAppDispatch();
  const api = useAdminApi();

  const adminOptions = admins.map((admin) => ({
    key: admin.admin_id,
    value: admin.admin_id,
    label: `${admin.first_name} ${admin.last_name}`,
  }));

  useEffect(
    () => {
      dispatch(
        fetchAdmins({
          api,
          needsAuth0: false,
          page: 1,
          limit: 100,
          search: '',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (!!initialAdminId) {
      setSelectedAdmin(initialAdminId);
      handleSubmit(initialAdminId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.admin_id, initialAdminId]);

  return (
    <Select
      showSearch
      loading={isAdminsLoading}
      options={adminOptions}
      value={selectedAdmin || undefined}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().indexOf(input?.toLowerCase()) !== -1
      }
      onChange={(adminId) => {
        setSelectedAdmin(adminId);
        handleSubmit(adminId);
      }}
      size="large"
    />
  );
};

export default SelectAdmin;
