import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Drawer from 'antd/es/drawer';
import React, { useEffect, useState } from 'react';
import Button from '../../../common/button';
import Div from '../../../common/div';
import AssetEditRow from '../asset-editor-drawer/edit-row';
import AssetEditorDrawer from '../asset-editor-drawer/edit-single-asset-drawer';
import AssetViewerDrawer from '../asset-viewer-drawer';
import HideAssetModal from './hide-asset-modal';
const MultiAssetsDrawer = ({ open, onClose, metas, isEdit, handleSaveAsset, records, owners, activeMetaId, suggestedAssets, handleAddSuggestedAsset, suggestedAssetsSectionTitle, }) => {
    const [singleFileOpen, setSingleFileOpen] = useState(false);
    const [selectedMeta, setSelectedMeta] = useState();
    const [openHideModal, setOpenHideModal] = useState(false);
    const handleNewFile = () => openSingleDrawer(undefined);
    const openSingleDrawer = (meta) => {
        setSelectedMeta(meta);
        setSingleFileOpen(true);
    };
    const closeSingleDrawer = () => {
        setSelectedMeta(undefined);
        setSingleFileOpen(false);
    };
    const showHideModal = (meta) => {
        setSelectedMeta(meta);
        setOpenHideModal(true);
    };
    const closeHideModal = () => {
        setSelectedMeta(undefined);
        setOpenHideModal(false);
    };
    useEffect(() => {
        if (activeMetaId === null || activeMetaId === void 0 ? void 0 : activeMetaId.length) {
            openSingleDrawer(metas[activeMetaId]);
        }
    }, [activeMetaId]);
    return (_jsxs(_Fragment, { children: [_jsxs(Drawer, { title: "Files", size: "default", placement: "right", closable: true, open: open, onClose: onClose, extra: _jsx(Button, { size: "circle", fitContent: true, onClick: handleNewFile, transparent: true, children: _jsx(AddNew, { size: 24 }) }), children: [_jsx(Div, { flex: "column", gap: 8, children: React.Children.toArray(Object.values(metas).map((meta) => (_jsx(AssetEditRow, { editAsset: openSingleDrawer, meta: meta, openHideModal: () => showHideModal(meta) })))) }), isEdit ? (_jsx(AssetEditorDrawer, { onClose: closeSingleDrawer, open: singleFileOpen, meta: selectedMeta, handleSaveAsset: handleSaveAsset, records: records, owners: owners, suggestedAssets: suggestedAssets, handleAddSuggestedAsset: handleAddSuggestedAsset, suggestedAssetsSectionTitle: suggestedAssetsSectionTitle })) : (_jsx(AssetViewerDrawer, { onClose: closeSingleDrawer, open: singleFileOpen, meta: selectedMeta }))] }), _jsx(HideAssetModal, { open: openHideModal, onClose: closeHideModal, meta: selectedMeta })] }));
};
export default MultiAssetsDrawer;
