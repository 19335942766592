const validateWebsite = ({ message, isAdmin, isRequired, }) => (_, value) => {
    if (!isRequired)
        return Promise.resolve();
    if ((!value || (value === null || value === void 0 ? void 0 : value.length) === 0) && isAdmin)
        return Promise.resolve();
    const urlRegex = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
    return urlRegex.test(value)
        ? Promise.resolve()
        : Promise.reject(new Error(message));
};
export default validateWebsite;
