import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ImagePlus from '@hellodarwin/icons/dist/icons/ImagePlus';
import { useState } from 'react';
import { useTheme } from '../../../../../../plugins/styled';
import Button from '../../../../../common/button';
import Div from '../../../../../common/div';
import PortfolioAssetModal from './asset-modal';
import PortfolioAssetsList from './assets-list';
const PortfolioAssets = ({ assets, setAssets, portfolio_id, }) => {
    const [assetModalOpen, setAssetModalOpen] = useState(false);
    const theme = useTheme();
    return (_jsxs(Div, { children: [_jsx(Button, { defaultStyle: theme.colors.purple_1, onClick: (e) => {
                    e.preventDefault();
                    setAssetModalOpen(true);
                }, size: "small", style: { width: '100%', borderRadius: 6 }, children: _jsx(ImagePlus, { size: 100 }) }), !!(assets === null || assets === void 0 ? void 0 : assets.length) && (_jsx(PortfolioAssetsList, { assets: assets, setAssets: setAssets })), _jsx(PortfolioAssetModal, { open: assetModalOpen, handleClose: () => setAssetModalOpen(false), portfolio_id: portfolio_id, assets: assets, setAssets: setAssets })] }));
};
export default PortfolioAssets;
