import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import Button from '../../../common/button';
import Div from '../../../common/div';
import ContactAdvisorModal from '../contact-advisor-modal';
const ProgramEmptyStateFooter = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const handleModal = () => setModalOpen(!modalOpen);
    return (_jsxs(_Fragment, { children: [_jsx(Div, { flex: "column", gap: 32, align: "center", style: { maxWidth: 400 }, children: _jsx(Button, { defaultStyle: theme.colors.green_1, fitContent: true, tablet: { fullWidth: true }, style: { height: 40 }, onClick: handleModal, children: t('propulsion|empty.cta') }) }), _jsx(ContactAdvisorModal, { open: modalOpen, handleCancel: handleModal })] }));
};
export default ProgramEmptyStateFooter;
