import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FacebookLogo from '@hellodarwin/icons/dist/icons/FacebookLogo';
import InstagramLogo from '@hellodarwin/icons/dist/icons/InstagramLogo';
import LinkedinLogo from '@hellodarwin/icons/dist/icons/LinkedinLogo';
import PinterestLogo from '@hellodarwin/icons/dist/icons/PinterestLogo';
import TiktokLogo from '@hellodarwin/icons/dist/icons/TiktokLogo';
import TwitterLogo from '@hellodarwin/icons/dist/icons/TwitterLogoOutline';
import YoutubeLogo from '@hellodarwin/icons/dist/icons/YoutubeLogo';
import { SocialsContainer } from './styles';
const ProfileContactSocials = ({ facebook, twitter, instagram, linked_in, tiktok, pinterest, youtube, }) => {
    if (!facebook &&
        !twitter &&
        !instagram &&
        !linked_in &&
        !tiktok &&
        !pinterest &&
        !youtube)
        return _jsx(_Fragment, {});
    return (_jsxs(SocialsContainer, { flex: "row", gap: 8, justify: "flex-end", style: { marginTop: 20 }, children: [!!linked_in && (_jsx("a", { target: "__blank", rel: "nofollow", href: linked_in, children: _jsx(LinkedinLogo, { size: 16 }) })), !!facebook && (_jsx("a", { target: "__blank", rel: "nofollow", href: facebook, style: { textDecoration: 'none' }, children: _jsx(FacebookLogo, { size: 16 }) })), !!instagram && (_jsx("a", { target: "__blank", rel: "nofollow", href: instagram, children: _jsx(InstagramLogo, { size: 16 }) })), !!twitter && (_jsx("a", { target: "__blank", rel: "nofollow", href: twitter, children: _jsx(TwitterLogo, { size: 16 }) })), !!tiktok && (_jsx("a", { target: "__blank", rel: "nofollow", href: tiktok, children: _jsx(TiktokLogo, { size: 16 }) })), !!youtube && (_jsx("a", { target: "__blank", rel: "nofollow", href: youtube, children: _jsx(YoutubeLogo, { width: 16, height: 10 }) })), !!pinterest && (_jsx("a", { target: "__blank", rel: "nofollow", href: pinterest, children: _jsx(PinterestLogo, { width: 16, height: 10 }) }))] }));
};
export default ProfileContactSocials;
