import {
  ApiError,
  ApiErrorInitialState,
  Step,
  StepAsset,
} from '@hellodarwin/core/lib/features/entities';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app';
import { showErrorNotification } from '../../utils';
import AdminApi from '../admin-api';
import AdminApiClient from '../admin-api-client';

const stepsAdapter = createEntityAdapter({
  selectId: (model: Step) => model.step_id,
});

export interface GinStepsState {
  status: 'idle' | 'pending';
  error: ApiError;
  ginSteps: EntityState<Step, string>;
  loadingMap: Record<string, boolean>;
}

const initialState: GinStepsState = {
  status: 'pending',
  error: ApiErrorInitialState,
  ginSteps: stepsAdapter.getInitialState(),
  loadingMap: {},
};

export const updateSingleSelectedStep = createAsyncThunk<
  { old_id: string; updatedStep: Step },
  { api: AdminApiClient; step: Step },
  { rejectValue: ApiError }
>(
  'admin/updateSingleSelectedStep',
  async (
    { api, step }: { api: AdminApiClient; step: Step },
    { rejectWithValue },
  ) => {
    try {
      const updatedStep = await api.updateSingleSelectedStep(step);
      return { old_id: step.step_id, updatedStep };
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const createNewSteps = createAsyncThunk<
  Step,
  { api: AdminApiClient; grantId: string; step: Step },
  { rejectValue: ApiError }
>(
  'admin/createNewStep',
  async (
    {
      api,
      grantId,
      step,
    }: { api: AdminApiClient; grantId: string; step: Step },
    { rejectWithValue },
  ) => {
    try {
      return await api.createNewStep(grantId, step);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteSingleSelectedStep = createAsyncThunk<
  Step[],
  { api: AdminApiClient; grant_id: string; step_id: string },
  { rejectValue: ApiError }
>(
  'admin/deleteSelectStep',
  async (
    {
      api,
      grant_id,
      step_id,
    }: { api: AdminApiClient; grant_id: string; step_id: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.deleteSingleSelectedStep(grant_id, step_id);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchGinsSteps = createAsyncThunk<
  Step[],
  { api: AdminApiClient; grant_id: string },
  { rejectValue: ApiError }
>(
  'admin/fetchGinsSteps',
  async (
    { api, grant_id }: { api: AdminApiClient; grant_id: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchGinsSteps(grant_id);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateStepsOrder = createAsyncThunk<
  Step[],
  { api: AdminApiClient; grantId: string; steps: Step[] },
  { rejectValue: ApiError }
>(
  'admin/updateStepsOrder',
  async (
    {
      api,
      grantId,
      steps,
    }: { api: AdminApiClient; grantId: string; steps: Step[] },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateStepsOrder(grantId, steps);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const createStepAsset = createAsyncThunk<
  StepAsset,
  { api: AdminApi; asset: StepAsset; stepID: string },
  { rejectValue: ApiError }
>(
  'admin/CreateStepAsset',
  async (
    { api, asset, stepID }: { api: AdminApi; asset: StepAsset; stepID: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post<StepAsset>(
        `/gin/step/asset/${stepID}`,
        asset,
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteStepAsset = createAsyncThunk<
  { stepID: string; assetID: string },
  { api: AdminApi; assetID: string; stepID: string },
  { rejectValue: ApiError }
>(
  'admin/DeleteStepAsset',
  async (
    {
      api,
      assetID,
      stepID,
    }: { api: AdminApi; assetID: string; stepID: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.delete<string>(
        `/gin/step/asset/delete/${assetID}`,
      );
      return { stepID: stepID, assetID: response.data };
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateStepAsset = createAsyncThunk<
  StepAsset,
  { api: AdminApi; asset: StepAsset },
  { rejectValue: ApiError }
>(
  'admin/updateStepAsset',
  async (
    { api, asset }: { api: AdminApi; asset: StepAsset },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.put<StepAsset>('/gin/step/asset', asset);
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchProgramStep = createAsyncThunk<
  Step[],
  { api: AdminApi; program_id: string },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/fetchProgramStep',
  async (
    { api, program_id }: { api: AdminApi; program_id: string },
    { rejectWithValue },
  ) => {
    try {
      const result = (await api.get<Step[]>(`/programs/step/${program_id}`))
        .data;
      return result;
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateProgramStep = createAsyncThunk<
  string,
  { api: AdminApi; program_id: string; step_id: string },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/updateProgramStep',
  async (
    {
      api,
      program_id,
      step_id,
    }: { api: AdminApi; program_id: string; step_id: string },
    { rejectWithValue },
  ) => {
    try {
      const result = (
        await api.post<string>(`/programs/step/${program_id}?stepId=${step_id}`)
      ).data;
      return result;
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const translateStepSection = createAsyncThunk<
  { stepID: string; section: keyof Step; newContent: string },
  {
    api: AdminApi;
    stepID: string;
    targetLocale: string;
    otherContent: string;
    section: keyof Step;
  },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/translateStepSection',
  async (
    {
      api,
      stepID,
      targetLocale,
      otherContent,
      section,
    }: {
      api: AdminApi;
      stepID: string;
      targetLocale: string;
      otherContent: string;
      section: keyof Step;
    },
    { rejectWithValue },
  ) => {
    try {
      const newContent = (
        await api.put<string>(
          `/chats/translate/${targetLocale}?section=${section}`,
          otherContent,
        )
      ).data;
      return { stepID, section, newContent };
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

const ginsStepsSlice = createSlice({
  name: 'ginsStep',
  initialState,
  reducers: {
    clearSteps: (state) => {
      stepsAdapter.removeAll(state.ginSteps);
      state.status = 'pending';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNewSteps.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createNewSteps.fulfilled, (state, { payload }) => {
      stepsAdapter.upsertOne(state.ginSteps, payload);
      state.status = 'idle';
    });
    builder.addCase(createNewSteps.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchGinsSteps.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchGinsSteps.fulfilled, (state, { payload }) => {
      stepsAdapter.upsertMany(state.ginSteps, payload ? payload : []);
      state.status = 'idle';
    });
    builder.addCase(fetchGinsSteps.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateSingleSelectedStep.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      updateSingleSelectedStep.fulfilled,
      (state, { payload }) => {
        const ids = state.ginSteps.ids as string[];
        const index = ids.indexOf(payload.old_id);

        if (index !== -1) {
          state.ginSteps.entities[payload.updatedStep.step_id] =
            payload.updatedStep;

          if (payload.old_id !== payload.updatedStep.step_id) {
            state.ginSteps.ids[index] = payload.updatedStep.step_id;
            delete state.ginSteps.entities[payload.old_id];
          }
        }
        state.status = 'idle';
      },
    );
    builder.addCase(updateSingleSelectedStep.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(deleteSingleSelectedStep.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      deleteSingleSelectedStep.fulfilled,
      (state, { payload }) => {
        stepsAdapter.setAll(state.ginSteps, payload ? payload : []);
        state.status = 'idle';
      },
    );
    builder.addCase(deleteSingleSelectedStep.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateStepsOrder.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateStepsOrder.fulfilled, (state, { payload }) => {
      stepsAdapter.setAll(state.ginSteps, payload ? payload : []);
      state.status = 'idle';
    });
    builder.addCase(updateStepsOrder.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(createStepAsset.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(createStepAsset.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createStepAsset.fulfilled, (state, { payload }) => {
      state.status = 'idle';
      if (state.ginSteps.entities[payload.step_id]) {
        stepsAdapter.updateOne(state.ginSteps, {
          id: payload.step_id,
          changes: {
            assets: state.ginSteps.entities[payload.step_id].assets
              ? [...state.ginSteps.entities[payload.step_id].assets, payload]
              : [payload],
          },
        });
      }
    });
    builder.addCase(deleteStepAsset.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(deleteStepAsset.rejected, (state, { payload }) => {
      state.status = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(deleteStepAsset.fulfilled, (state, { payload }) => {
      state.status = 'idle';
      if (state.ginSteps.entities[payload.stepID]) {
        stepsAdapter.updateOne(state.ginSteps, {
          id: payload.stepID,
          changes: {
            assets: state.ginSteps.entities[payload.stepID].assets.filter(
              (asset) => asset.step_asset_id !== payload.assetID,
            ),
          },
        });
      }
    });
    builder.addCase(updateStepAsset.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateStepAsset.rejected, (state, { payload }) => {
      state.status = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(updateStepAsset.fulfilled, (state, { payload }) => {
      state.status = 'idle';
      if (state.ginSteps.entities[payload.step_id]) {
        stepsAdapter.updateOne(state.ginSteps, {
          id: payload.step_id,
          changes: {
            assets: state.ginSteps.entities[payload.step_id].assets.map(
              (asset) =>
                asset.step_asset_id === payload.step_asset_id ? payload : asset,
            ),
          },
        });
      }
    });
    builder.addCase(fetchProgramStep.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchProgramStep.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchProgramStep.fulfilled, (state, { payload }) => {
      state.status = 'idle';
      stepsAdapter.upsertMany(state.ginSteps, payload ? payload : []);
    });
    builder.addCase(translateStepSection.pending, (state, action) => {
      state.status = 'pending';
      state.loadingMap[action.meta.arg.section] = true;
    });
    builder.addCase(translateStepSection.rejected, (state, action) => {
      state.error = action.payload ?? ApiErrorInitialState;
      state.status = 'idle';
      state.loadingMap[action.meta.arg.section] = false;
    });
    builder.addCase(translateStepSection.fulfilled, (state, { payload }) => {
      state.status = 'idle';
      if (state.ginSteps.entities[payload.stepID]) {
        stepsAdapter.updateOne(state.ginSteps, {
          id: payload.stepID,
          changes: {
            [payload.section]: payload.newContent,
          },
        });
      }
      state.loadingMap[payload.section] = false;
    });
  },
});
export const selectStepsIsLoading = (state: RootState) =>
  state.ginSteps.status === 'pending';

export const { selectAll: selectAllSteps } = stepsAdapter.getSelectors(
  (state: RootState) => state.ginSteps.ginSteps,
);

export const selectIsLoadingSolo = createSelector(
  [
    (state: RootState, _: string) => state.ginSteps.loadingMap,
    (_: RootState, sectionName: string) => sectionName,
  ],
  (loadingMap, sectionName) => loadingMap[sectionName],
);

export const { clearSteps } = ginsStepsSlice.actions;

export const ginStepsReducer = ginsStepsSlice.reducer;
