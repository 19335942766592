import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Form from 'antd/es/form';
import notification from 'antd/es/notification';
import { useEffect, useMemo, useState } from 'react';
import { InitialTeamMember, } from '../../../../../features/entities/core-entities';
import handleDispatchResponse from '../../../../../features/helpers/handle-dispatch-response';
import useMediaQuery from '../../../../../features/helpers/use-media-query';
import { useCompanyInfos } from '../../../../../features/providers/company-infos-provider';
import { useProfileEdition } from '../../../../../features/providers/profile-edition-provider';
import { useTranslation } from '../../../../../plugins/i18n';
import { useTheme } from '../../../../../plugins/styled';
import createBorderStyle from '../../../../../theme/utils/create-border-style';
import Div from '../../../../common/div';
import { Tablet } from '../../../../common/Media';
import TeamList from '../../../../provider/profile/profile-team/team-list';
import ProfileEditModalTeamFooter from './footer';
import TeamMemberForm from './team-member-form';
const TeamEdit = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { provider } = useCompanyInfos();
    const { handleFooter, functions } = useProfileEdition();
    const { activeMemberId, teamMembers, handleCancel, setTeamMembers } = props;
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [selectedMemberId, setSelectedMemberId] = useState(activeMemberId);
    const selectedMember = useMemo(() => {
        var _a;
        return (_a = teamMembers.find((m) => m.provider_team_member_id === selectedMemberId)) !== null && _a !== void 0 ? _a : InitialTeamMember;
    }, [selectedMemberId]);
    useEffect(() => {
        setSelectedMemberId(activeMemberId);
    }, [activeMemberId]);
    const handleSelectMember = (member) => {
        setSelectedMemberId(member.provider_team_member_id);
    };
    useEffect(() => {
        handleFooter(undefined);
    }, []);
    const handleSubmit = () => {
        form
            .validateFields()
            .then(async (values) => {
            const request = {
                name: values.name,
                role: values.role,
                linked_in: values.linked_in,
                provider_id: provider.provider_id,
                order_team: teamMembers.length,
            };
            const data = new FormData();
            data.append('file1', fileList[0] !== undefined ? fileList[0].originFileObj : '');
            if (selectedMember.provider_team_member_id) {
                const order = selectedMember.order_team;
                request.order_team = order;
            }
            data.append('request', JSON.stringify(request));
            if (selectedMember.provider_team_member_id) {
                const teamMemberId = selectedMember.provider_team_member_id;
                !!functions.editTeamMember &&
                    (await functions
                        .editTeamMember({ data, teamMemberId })
                        .then((res) => handleDispatchResponse(res, t('notification.saved'), t('notification.saving_error'))));
            }
            else {
                !!functions.submitTeamMember &&
                    (await functions
                        .submitTeamMember({ data })
                        .then((res) => handleDispatchResponse(res, t('notification.saved'), t('notification.saving_error'))));
            }
            if (isTablet)
                handleCancel();
        })
            .catch((error) => notification.error({
            message: error.errorFields[0].errors[0],
            placement: 'top',
        }));
    };
    return (_jsxs(Div, { flex: "row", align: "stretch", style: { maxHeight: 440 }, children: [_jsx("div", { style: { width: '100%' }, children: _jsxs("div", { style: { position: 'sticky', top: 0 }, children: [_jsx("div", { style: { paddingInline: 24, paddingBlock: 16 }, children: _jsx(TeamMemberForm, { form: form, teamMember: selectedMember, fileList: fileList, setFileList: setFileList, handleSubmit: handleSubmit }) }), _jsx("div", { style: Object.assign({ paddingInline: 24, paddingBlock: 8, marginTop: 8 }, createBorderStyle({ color: theme.colors.grey_1, side: 'top' })), children: _jsx(ProfileEditModalTeamFooter, Object.assign({}, props, { selectedMember: selectedMember, handleSubmit: handleSubmit, handleSelectMember: handleSelectMember })) })] }) }), _jsx(Tablet, { direction: "up", children: _jsx("div", { style: { overflow: 'auto', width: '100%' }, children: _jsx(TeamList, { selectedMember: selectedMember, setSelectedMember: handleSelectMember, teamMembers: teamMembers, isEditable: true, setMembers: setTeamMembers, functions: functions, provider: provider }) }) })] }));
};
export default TeamEdit;
