'use client';
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTheme } from 'styled-components';
import Spin from '../spin';
import HdArrow from './arrow';
import { ButtonContainer } from './styles';
import useButtonProps from './use-button-props';
const Button = memo((props) => {
    const theme = useTheme();
    const { currentProps, currentStyle, defaultProps } = useButtonProps(props);
    const { className, children, headingIcon, trailingIcon, withArrowLeft, withArrowRight, disabled, size, loading, handleClick } = defaultProps, buttonProps = __rest(defaultProps, ["className", "children", "headingIcon", "trailingIcon", "withArrowLeft", "withArrowRight", "disabled", "size", "loading", "handleClick"]);
    const classes = classNames('hd-button', className);
    return (_jsxs(ButtonContainer, Object.assign({}, currentProps, { className: classes, onClick: props.onClick ? handleClick : undefined, style: currentStyle, disabled: loading || disabled, "$withArrow": withArrowLeft || withArrowRight, "$size": size }, buttonProps, { children: [!!headingIcon && _jsx("span", { children: headingIcon }), withArrowLeft && _jsx(HdArrow, { dir: "left" }), !!children && _jsx("span", { children: children }), loading ? (_jsx(Spin, { color: theme.colors.white_1, size: "small" })) : (_jsxs(_Fragment, { children: [withArrowRight && _jsx(HdArrow, { dir: "right" }), trailingIcon && _jsx("span", { children: trailingIcon })] }))] })));
});
Button.displayName = 'Button';
export default Button;
