import { CompanyGrant } from 'src/@types/company-types';
import addParamToTranslation from '../../../utils/add-params-to-translation';
import formatDollarValue from '../../../utils/format-dollar-value';
import { GamePlanTranslations } from '../types';

const getCompanyGrantFundingAmount = (
  grant: CompanyGrant,
  locale: string,
  translations: GamePlanTranslations,
) => {
  const { funding_min_amount, funding_max_amount, percentage_funding } = grant;
  let value = '';

  if (!!percentage_funding) {
    if (!!funding_min_amount && !!funding_max_amount) {
      value += `${percentage_funding}% `;
    } else if (!!funding_min_amount || !!funding_max_amount) {
      value += `${percentage_funding}% ${translations.upTo} `;
    } else {
      value += addParamToTranslation(translations.projectCostPercent, {
        percent: `${percentage_funding}`,
      });
    }
  }

  if (!!funding_min_amount && !!funding_max_amount) {
    value += addParamToTranslation(translations.fromMinToMax, {
      min: `${formatDollarValue(funding_min_amount, locale, true, true)}`,
      max: `${formatDollarValue(funding_max_amount, locale, true, true)}`,
    });
  } else if (!!funding_min_amount) {
    value += formatDollarValue(funding_min_amount, locale, true, true);
  } else if (!!funding_max_amount) {
    value += formatDollarValue(funding_max_amount, locale, true, true);
  }

  if (!value?.length) return ' - ';
  return value;
};

export default getCompanyGrantFundingAmount;
