import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import SubitemsListDropdown from '../../navigation/menu-item/subitems-list-dropdown';
import useHandleLinkClick from '../../navigation/utils/use-handle-link-click';
import UserMenuButton from './user-menu-button';
const DesktopUserMenu = ({ user, links, app, notifs }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleDropdownOpen = () => setIsOpen(!isOpen);
    const handleLinkClick = useHandleLinkClick({ selectedKeys: [] });
    return (_jsxs("div", { style: { position: 'relative' }, children: [_jsx(UserMenuButton, { user: user, withArrow: true, onClick: handleDropdownOpen, app: app, notifs: notifs }), _jsx(SubitemsListDropdown, { isActive: isOpen, position: "bottom", items: links, app: 'client', handleDropdownMouseLeave: handleDropdownOpen, isDropdown: true, handleLinkClick: handleLinkClick, size: "large", removeBorder: true, style: { width: '100%', paddingTop: 8, top: 'calc(100% + 8px)' } })] }));
};
export default DesktopUserMenu;
