export const InitialProjectState = {
    project_id: '',
    company_id: '',
    title: '',
};
export const InitialProjectGrantState = {
    project_id: '',
    grant_id: '',
};
export const InitialProjectProgramState = {
    project_id: '',
    program_id: '',
    order: -1,
};
