import Button from '@hellodarwin/core/lib/components/common/button';
import {
  AdminGrantResult,
  HdChatPromptRequest,
} from '@hellodarwin/core/lib/features/entities';
import { FormInstance } from 'antd/es/form/Form';
import { Col, Row } from 'antd/es/grid';
import message from 'antd/es/message';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  generateSingleGrantContentInfo,
  selectIsLoadingSectionsGen,
  translateSingleGrantContentInfo,
} from '../../../features/api/slices/grants-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';
import theme from '../../../theme';
import { default as GrantPromptContentSection } from '../grant-prompt-content-section';
import { GrantFormValues, transformSectionIntoLabel } from './grant-form';
import GrantFormTranslateButtons from './grant-form-translate-buttons';

type GrantFormSectionsProps = {
  grant?: AdminGrantResult;
  onSave?: () => Promise<void>;
  setSelectedPrompt: Dispatch<SetStateAction<HdChatPromptRequest>>;
  setAddNewPromptDrawer: Dispatch<SetStateAction<boolean>>;
  form: FormInstance<GrantFormValues>;
  formValues: GrantFormValues;
  prompts: HdChatPromptRequest[];
  isGeneratingRemaining?: boolean;
  isGeneratingAll?: boolean;
};

// The boolean value determines if it is generated when generating all
export const grantPromptSectionTitles = new Map<keyof GrantFormValues, boolean>(
  [
    ['gameplan_description_en', false],
    ['gameplan_description_fr', false],
    ['eligibility_criteria_short_en', true],
    ['eligibility_criteria_short_fr', true],
    ['eligibility_criteria_en', true],
    ['eligibility_criteria_fr', true],
    ['who_can_apply_en', true],
    ['who_can_apply_fr', true],
    ['who_cannot_apply_en', true],
    ['who_cannot_apply_fr', true],
    ['project_activity_en', true],
    ['project_activity_fr', true],
    ['zone_en', true],
    ['zone_fr', true],
    ['eligible_expenses_en', true],
    ['eligible_expenses_fr', true],
    ['selection_criteria_en', true],
    ['selection_criteria_fr', true],
    ['steps_how_to_apply_en', true],
    ['steps_how_to_apply_fr', true],
    ['funding_terms_and_conditions_en', true],
    ['funding_terms_and_conditions_fr', true],
    ['additional_information_en', true],
    ['additional_information_fr', true],
    ['expanded_description_en', false],
    ['expanded_description_fr', false],
  ],
);

const GrantFormSections = ({
  grant,
  onSave,
  setSelectedPrompt,
  setAddNewPromptDrawer,
  form,
  formValues,
  prompts,
  isGeneratingRemaining,
  isGeneratingAll,
}: GrantFormSectionsProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const accessScrapeContext = () => {
    window.open(`/grants/scrape-context/${grant?.grant_id}`, '_blank');
  };

  const isLoadingSections = useAppSelector((state) =>
    selectIsLoadingSectionsGen(state),
  );

  useEffect(() => {
    if (isGeneratingRemaining) {
      for (const section of Array.from(grantPromptSectionTitles.keys())) {
        const formValue = form.getFieldValue(section);
        if (
          formValue === '' ||
          formValue === null ||
          formValue === undefined ||
          formValue === '<p><br></p>' ||
          formValue === '<h2><br></h2>'
        ) {
          generateSection(section);
        }
      }
    }
    if (isGeneratingAll) {
      generateAllSections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGeneratingRemaining, isGeneratingAll]);

  const generateSection = async (section: keyof GrantFormValues) => {
    try {
      const sectionText = await dispatch(
        generateSingleGrantContentInfo({
          api,
          grantId: grant!.grant_id,
          sectionName: section,
          industrySectorsIDs: [],
        }),
      ).unwrap();
      const titleLabel = transformSectionIntoLabel(section);
      if (sectionText === '') {
        message.info(
          `No answer for ${titleLabel}. Either try again, change the prompt or add some context.`,
        );
      } else {
        form.setFieldValue(section, sectionText);

        message.success(
          `Section ${titleLabel} generated! Don't forget to save.`,
        );
      }
    } catch (e: any) {
      message.error('Error !');
      message.error(e.cause);
    }
  };

  const generateSectionFromTranslation = async (
    sections: (keyof GrantFormValues)[],
    otherContents: string[],
  ) => {
    try {
      if (sections.length === otherContents.length) {
        for (var i = 0; i < sections.length; i++) {
          const sectionText = await dispatch(
            translateSingleGrantContentInfo({
              api,
              sectionName: sections[i],
              otherContent: otherContents[i],
            }),
          ).unwrap();
          const titleLabel = transformSectionIntoLabel(sections[i]);
          if (sectionText === '') {
            message.info(
              `No answer for ${titleLabel}. Either try again, change the prompt or add some context.`,
            );
          } else {
            form.setFieldValue(sections[i], sectionText);

            message.success(
              `Section ${titleLabel} translated! Don't forget to save.`,
            );
          }
        }
      }
    } catch (e: any) {
      message.error('Error !');
      message.error(e.cause);
    }
  };

  const generateAllSections = async () => {
    grantPromptSectionTitles.forEach((canGenerate, sectionTitle) => {
      if (canGenerate) {
        generateSection(sectionTitle);
      }
    });
  };

  const sectionKeys = Array.from(grantPromptSectionTitles.keys());

  const displayGrantSections = () => {
    const sections: any[] = [];
    for (const key of sectionKeys) {
      sections.push(
        <Col span={11} key={key}>
          <GrantPromptContentSection
            section={key}
            formValues={formValues}
            setSelectedPrompt={setSelectedPrompt}
            setAddNewPromptDrawer={setAddNewPromptDrawer}
            onSave={onSave}
            grant={grant}
            prompts={prompts}
            isTextArea={false}
            generateSection={generateSection}
          />
        </Col>,
      );
    }
    const rows = [];
    for (let i = 0; i < sections.length; i += 2) {
      rows.push(
        <Row gutter={12} key={i}>
          {sections[i]}
          <GrantFormTranslateButtons
            generateSectionFromTranslation={(sections, otherContents) => {
              generateSectionFromTranslation(
                sections as (keyof GrantFormValues)[],
                otherContents,
              );
            }}
            contentEn={[formValues[sectionKeys[i]]]}
            contentFr={[formValues[sectionKeys[i + 1]]]}
            sectionEn={[sectionKeys[i]]}
            sectionFr={[sectionKeys[i + 1]]}
          />
          {sections[i + 1]}
        </Row>,
      );
    }

    return rows;
  };

  return (
    <>
      {grant?.grant_id !== '' && (
        <Row
          gutter={12}
          justify="space-between"
          style={{ marginBottom: '32px' }}
        >
          <Button
            defaultStyle={theme.colors.purple_2}
            onClick={() => {
              generateAllSections();
            }}
            loading={isLoadingSections}
            disabled={isLoadingSections}
          >
            Generate All
          </Button>
          <Button
            defaultStyle={theme.colors.grey_1}
            onClick={accessScrapeContext}
          >
            See scrape context
          </Button>
        </Row>
      )}
      {displayGrantSections()}
    </>
  );
};

export default GrantFormSections;
