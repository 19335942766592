import Table from 'antd/es/table';
import styled from '../../../plugins/styled';
import theme from '../../../theme';
export const TableStyled = styled(Table) `
  .ant-table-thead > tr > th.ant-table-cell {
    font-size: ${theme.fonts.overline};
    font-weight: ${theme.fontWeights.medium};
    tex-transform: uppercase;
    line-height: ${theme.fontsLineHeights.overline};
    letter-spacing: ${theme.letterSpacing.overline};
    text-transform: uppercase;
    border-color: ${({ theme }) => theme.colors.grey_3};
    padding: 8px 16px;
  }
  .actions {
    backdrop-filter: blur(6px);
    background: rgba(255, 255, 255, 0);
  }
`;
