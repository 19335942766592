import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Warning = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.44273 23.9075C1.18273 26.1675 2.76273 28.9975 5.28273 28.9975H26.7227C29.2427 28.9975 30.8227 26.1675 29.5627 23.9075L18.8427 4.6975C17.5827 2.4375 14.4227 2.4375 13.1627 4.6975L2.44273 23.9075Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.84 25.5C15.33 25.5 14.9 25.32 14.54 24.96C14.18 24.6 14 24.16 14 23.66C14 23.16 14.18 22.72 14.54 22.36C14.9 22 15.34 21.82 15.84 21.82C16.34 21.82 16.78 22 17.14 22.36C17.5 22.72 17.68 23.16 17.68 23.66C17.68 24.16 17.5 24.6 17.14 24.96C16.78 25.32 16.34 25.5 15.84 25.5ZM14.36 19.89L14.15 9H17.52L17.31 19.89H14.36Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Warning;
