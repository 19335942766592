import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
const FormPageContentButtons = ({ isFirstPage, isEndPage, isOpeningPage, }) => {
    const theme = useTheme();
    const { t } = useTranslations();
    if (isEndPage)
        return _jsx(_Fragment, {});
    return (_jsxs(Div, { flex: "row", gap: 16, justify: isOpeningPage ? 'center' : 'flex-end', style: { padding: 24 }, children: [!isFirstPage && (_jsx(Button, { defaultStyle: theme.colors.white_1, size: "small", children: t('button|previous') })), _jsx(Button, { defaultStyle: isOpeningPage ? theme.colors.yellow_1 : theme.colors.white_1, size: isOpeningPage ? 'large' : 'small', children: isOpeningPage ? t('button|startNow') : t('button|next') })] }));
};
export default FormPageContentButtons;
