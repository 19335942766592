import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { GinHistory } from '@hellodarwin/core/lib/features/entities';
import { getStringDateAndTime } from '@hellodarwin/core/lib/features/helpers/get-formatted-date';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import User from '@hellodarwin/icons/dist/icons/User';
import React, { useEffect } from 'react';
import CardInfoRowWithLabel from '../sidebars/cards/card-info-row-with-label';
import { GinHistoryCard } from './styles';
interface GinHistoryProps {
  setContent: (newContent: string) => void;
  history?: GinHistory[];
  setSelectedGin: (newContent: string) => void;
  selectedGin: string;
}

const GinHistoryContent: React.FC<GinHistoryProps> = ({
  history,
  setContent,
  setSelectedGin,
  selectedGin,
}) => {
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();
  useEffect(() => {
    if (!!history && history.length > 0) {
      setSelectedGin(history[0].grant_internal_notes_id);
      setContent(history[0].content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
  return (
    <Div flex="column" gap={10}>
      {history && history.length > 0 ? (
        history.map((entry) => (
          <GinHistoryCard
            key={entry.grant_internal_notes_id}
            selected={entry.grant_internal_notes_id === selectedGin}
            onClick={() => {
              setContent(entry.content);
              setSelectedGin(entry.grant_internal_notes_id);
            }}
          >
            <CardInfoRowWithLabel
              label={t('gin_history|by')}
              Icon={User}
              value={entry.first_name + ' ' + entry.last_name}
            />
            <CardInfoRowWithLabel
              label={t('gin_history|historyCreatedAt')}
              Icon={Timeline}
              value={
                !entry.created_at
                  ? '-'
                  : getStringDateAndTime(entry.created_at, selectedLocale)
              }
            />
          </GinHistoryCard>
        ))
      ) : (
        <Typography elementTheme="body1">
          {t(`gin_history|emptyList`)}
        </Typography>
      )}
    </Div>
  );
};

export default GinHistoryContent;
