var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ContainerIcons } from '@hellodarwin/icons/dist/backgrounds/icon-entities';
import { useEffect } from 'react';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../div';
import AppHeader from '../app-layout/app-header';
import PageContainer from './PageContainer';
import PageHeading from './PageHeading';
const PageLayout = (_a) => {
    var { children, className, tabTitle, isLoading, styles, noGap, noPadding, defaultNavCollapsed = false, title, subtitle, actions, footnote, status, handleBack, noFooter, goBackTo } = _a, appHeaderProps = __rest(_a, ["children", "className", "tabTitle", "isLoading", "styles", "noGap", "noPadding", "defaultNavCollapsed", "title", "subtitle", "actions", "footnote", "status", "handleBack", "noFooter", "goBackTo"]);
    const { withoutPaddingRight, withoutPaddingHorizontal, withoutPaddingLeft, app, } = appHeaderProps;
    const { t } = useTranslations();
    const theme = useTheme();
    useEffect(() => {
        if (typeof document !== 'undefined') {
            if (!!tabTitle) {
                document.title = `${tabTitle} | ${t(`appsNames|${app}`)}`;
            }
            else if ((typeof title === 'string' || title instanceof String) &&
                !!title) {
                document.title = `${title} | ${t(`appsNames|${app}`)}`;
            }
            else {
                document.title = t(`appsNames|${app}`);
            }
        }
    }, [tabTitle]);
    if (isLoading)
        return _jsx(_Fragment, {});
    const containerProps = {
        className,
        cap: app === 'admin' ? theme.caps.xl : theme.caps.normal,
        withoutPaddingRight,
        withoutPaddingHorizontal,
        styles: Object.assign(Object.assign({}, styles), { container: Object.assign(Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.container), { position: 'relative', paddingTop: app === 'admin' ? 16 : undefined }) }),
        noPadding,
        app,
        noGap,
    };
    const headingProps = {
        title,
        subtitle,
        actions,
        footnote,
        status,
        handleBack,
        withoutPaddingHorizontal,
        withoutPaddingLeft,
        withoutPaddingRight,
        goBackTo,
        styles: styles === null || styles === void 0 ? void 0 : styles.heading,
    };
    return (_jsxs(_Fragment, { children: [app === 'admin' && (_jsx(AppHeader, Object.assign({}, appHeaderProps, { style: styles === null || styles === void 0 ? void 0 : styles.header }))), _jsxs(PageContainer, Object.assign({}, containerProps, { icons: [
                    {
                        side: 'right',
                        name: ContainerIcons.Group1Filled,
                        color: theme.colors.beige_1,
                        top: 300,
                        margin: -300,
                        width: 900,
                        height: 800,
                    },
                ], noFooter: noFooter, children: [app !== 'admin' && (_jsx(AppHeader, Object.assign({}, appHeaderProps, { style: styles === null || styles === void 0 ? void 0 : styles.header }))), (title || actions) && _jsx(PageHeading, Object.assign({}, headingProps)), _jsx(Div, { flex: "column", style: Object.assign({}, styles === null || styles === void 0 ? void 0 : styles.body), gap: 32, fullHeight: true, children: children })] }))] }));
};
export default PageLayout;
